export const menuValues = [
  { key: 0, title: `Disabled` },
  { key: 1, title: 'Enabled' },
  { key: 2, title: 'View All' },
];

export const distributionCenterTypes = [
  { key: 1, title: `Warehouse` },
  { key: 2, title: 'DropShip' },
  { key: 3, title: 'RetailerStore' },
];