import React from 'react';
import { Input, Select, notification } from 'antd';
import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import Spacer from '../../components/common/Spacer';
import { FormLabel } from '../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  HTTP_STATUS_OK,
  LOADING_ICON_SIZE1,
} from '../../constants/config';
import Products from '../../services/products';
import SubStyleSelector from './SubStyleSelector';
//import { trimCodeLiteral } from '.';

export type StyleCodeDict = {
  colorPatternList: string[];
  colorPatternDescList: string[];
  lengthCodeList: string[];
  lengthDescList: string[];
  sizeCodeList: string[];
  sizeDescList: string[];
  widthCodeList: string[];
  widthDescList: string[];
};

type Props = {
  inputProduct?: string;
  itemType?: string;
  onInputProduct?: Function;
  onSelectDone: Function;
  onSelectSubStyle?: Function;
  productName: string;
  products?: StringKAnyVPair[];
  setSubStyleProducts?: Function;
  styleClass?: string;
  styleCode: string;
  styleCodeDict: StyleCodeDict;
  styleOption: StringKAnyVPair;
  subStyleCode?: string;
  subStyleCodes?: StringKAnyVPair[];
  type: string;
  classInfo?: any;
};

let styleCodeData: StringKAnyVPair | null = null;

const StyleCodeSelector = (props: Props) => {
  const { useState } = React;
  const { Option } = Select;
  const [cpCodeList, setCpCodeList] = useState<StringKAnyVPair[]>([]);
  const [currentSubStyle, setCurrentSubStyle] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lengthCodeList, setLengthCodeList] = useState<StringKAnyVPair[]>([]);
  const [needSubStyle, setNeedSubStyle] = useState(false);
  const [sizeCodeList, setSizeCodeList] = useState<StringKAnyVPair[]>([]);
  const [selectedCPCodes, setSelectedCPCodes] = useState<string[]>(props.styleCodeDict.colorPatternDescList);
  const [selectedLengthCodes, setSelectedLengthCodes] = useState<string[]>(props.styleCodeDict.lengthDescList);
  const [selectedSizeCodes, setSelectedSizeCodes] = useState<string[]>(props.styleCodeDict.sizeDescList);
  const [selectedWidthCodes, setSelectedWidthCodes] = useState<string[]>(props.styleCodeDict.widthDescList);
  const [styleCodes, setStyleCodes] = useState<string[]>([]);
  const [widthCodeList, setWidthCodeList] = useState<StringKAnyVPair[]>([]);

  const codeOptions = (options: StringKAnyVPair[], optionType: string) => {
    const classInfo = props.classInfo || {};
    const filterCodes: any[] = classInfo[optionType] || [];
    // use class info to filter size, length codes
    return options.filter(e => {
      if (!filterCodes.length) return true;
      return filterCodes.includes(e.id);
    })
    .map((e, i) => (
      //<Option key={`${e.code}_${i}`} value={e.description} label={e.code}>
      <Option key={`${e.code}_${i}`} value={e.uuid} label={e.code}>
        <span>{e.code}</span>
        <span className="code-desc">{` (${e.description})`}</span>
      </Option>
    ));
  };

  // const colorPatternOptions = (options: StringKAnyVPair[]) => {
  //   return options.map((e, i) => (
  //     //<Option key={`${e.code}_${i}`} value={e.description} label={e.code}>
  //     <Option key={`${e.code}_${i}`} value={e.uuid} label={e.code}>
  //       <span>{e.code}</span>
  //       <span className="code-desc">{` (${e.description})`}</span>
  //     </Option>
  //   ));
  // };

  // eslint-disable-next-line
  const codeToDescList = (codes: string[], options: StringKAnyVPair[]) => {
    const ret: string[] = [];

    options.filter(e => codes.indexOf(e.code) > -1)
      .map(e => e.description)
      .forEach(e => {
        if (ret.indexOf(e) < 0) ret.push(e);
      });

    return ret;
  };

  // eslint-disable-next-line
  const descToCodeList = (desc: string[], options: StringKAnyVPair[]) => {
    const ret: string[] = [];

    options.filter(e => desc.indexOf(e.description) > -1)
      .map(e => e.code)
      .forEach(e => {
        if (ret.indexOf(e) < 0) ret.push(e);
      });

    return ret;
  };

  // eslint-disable-next-line
  const extractStyleCodes = () => {
    const codes: string[] = [];
    const {
      SubStyleCodePatternElements,
      ProductPatternElements,
    } = props.styleOption;

    [
      SubStyleCodePatternElements,
      ProductPatternElements,
    ].forEach(s => {
      if (Array.isArray(s)) {
        s.forEach(e => {
          if (codes.indexOf(e) < 0) {
            codes.push(e);
          }
        });
      }
    });

    setNeedSubStyle(
      Array.isArray(SubStyleCodePatternElements)
      && SubStyleCodePatternElements.length > 0
    );

    console.log('--->', codes);
    setStyleCodes(codes);
  };

  const filterCPCodeList = (cpList: StringKAnyVPair[]) => {
    if (Array.isArray(cpList)) {
      //const list = cpList.filter((cp: StringKAnyVPair) => cp.classCode === props.styleClass);
      const list = [...cpList];

      if (props.type === 'subStep') {
        const subCodes: string[] = [];

        if (Array.isArray(props.subStyleCodes)) {
          props.subStyleCodes.forEach(e => {
            const cp = e.subStyleCode.replace(`${props.styleCode}-`, '');
            if (subCodes.indexOf(cp) < 0) {
              subCodes.push(cp);
            }
          });
        }
        //console.log('lst: ', cpList);
        //console.log('eco: ', subCodes);
        //console.log('opt: ', props.subStyleCodes);
        return list.filter((cp: StringKAnyVPair) => {
            return subCodes.indexOf(cp.code) < 0;
          });
      }

      return list;
    }

    return cpList;
  };

  // eslint-disable-next-line
  const getCodeListByDesc = (desc: string[], options: StringKAnyVPair[]) => {
    const ret: string[] = [];

    options.filter(e => desc.indexOf(e.description) > -1 || desc.indexOf(e.code) > -1)
      .map(e => e.code)
      .forEach(e => {
        if (ret.indexOf(e) < 0) ret.push(e);
      });

    return ret;
  };

  const getCodeListByUuid = (uuids: string[], options: StringKAnyVPair[]) => {
    const ret: string[] = [];

    options.filter(e => uuids.indexOf(e.uuid) > -1 /*|| desc.indexOf(e.code) > -1*/)
      .map(e => e.code)
      .forEach(e => {
        if (ret.indexOf(e) < 0) ret.push(e);
      });

    return ret;
  };

  const isPatternCodeEnable = (ck: string) => {
    //return isProductPatternValue() && styleCodes.indexOf('WidthCode') > -1;
    return isProductPatternValue() && styleCodes.indexOf(ck) > -1;
  };

  const isProductInSubStyle = () => {
    return isProductType() && props.styleOption && props.styleOption.GenerateSubStyleCode === 1;
  };

  const isProductPatternValue = () => {
    return props.styleOption && props.styleOption.ProductPatternValue > 0;
  };

  const isProductType = () => {
    return props.itemType === 'product';
  };

  const isStyleStep = () => {
    return props.type === 'styleStep';
  };

  const isSubStyleCodesNeeded = () => {
    return (isSubStyleType() || isProductType()) && isSubStyleStep() && props.styleOption.GenerateSubStyleCode === 1;
  };

  const isSubStyleSelectorEnabled = () => {
    const { styleOption: opt } = props;

    return !!props.subStyleCodes && props.subStyleCodes.length > 0 && opt && ((opt.GenerateSubStyleCode && isSubStyleStep()) || (!opt.GenerateSubStyleCode && isStyleStep()));
  };

  const isSubStyleStep = () => {
    return props.type === 'subStep';
  };

  const isSubStyleType = () => {
    return props.itemType === 'sub';
  };

  const isStyleCodeNeeded = () => {
    return !!(props.styleOption && props.styleOption.GenerateStyleCode);
  };

  const loadStyleVariations = async () => {
    setIsLoading(true);

    try {
      if (!styleCodeData) {
        const res = await Products.fetchStyleVariations2();

        if (res && typeof res === 'object') {
          if (res.status === HTTP_STATUS_OK) {
            styleCodeData = res.data.styleVariation;

            if (!styleCodeData) {
              const { messages } = res.data;

              if (Array.isArray(messages) && messages.length > 0) {
                const { Message } = messages[0];

                throw Message || 'no data returned';
              }
            }
          }
        }
      }

      if (styleCodeData) {
        const { colorPatternCode, lengthCode, sizeCode, widthCode } = styleCodeData;
        const cpArr = colorPatternCode || [];
        const lengthArr = lengthCode || [];
        const sizeArr = sizeCode || [];
        const widthArr = widthCode || [];

        cpArr.sort(sortByCode);
        lengthArr.sort(sortByCode);
        sizeArr.sort(sortByCode);
        widthArr.sort(sortByCode);
        // console.log('-->', colorPatternCode, lengthCode, sizeCode);
        //setCpCodeList((colorPatternCode || []).sort(sortByCode));
        setCpCodeList(filterCPCodeList(cpArr));
        setLengthCodeList(lengthArr);
        setSizeCodeList(sizeArr);
        setWidthCodeList(widthArr)
        //setSelectedCPCodes(codeToDescList(props.styleCodeDict.colorPatternList, cpArr));
        //setSelectedLengthCodes(codeToDescList(props.styleCodeDict.lengthCodeList, lengthArr));
        //setSelectedSizeCodes(codeToDescList(props.styleCodeDict.sizeCodeList, sizeArr));
        //setSelectedWidthCodes(codeToDescList(props.styleCodeDict.widthCodeList, widthArr));
      }
    } catch(e) {
      notification.error({
        message: `Load style variation error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadSubStyleInfo = async (code: string) => {
    console.log('sub', code);
    setIsLoading(true);

    try {
      const {
        //classCode,
        //productId,
        products,
        subStyleCode,
      } = await Products.getSubStyleInfoByCode(code);

      if (subStyleCode) {
        console.log('res', products);
        //onSelectCPCodes
        if (props.onSelectSubStyle) props.onSelectSubStyle(subStyleCode);

        if (props.setSubStyleProducts) props.setSubStyleProducts(products);
      }
    } catch(e) {
      notification.error({
        message: `Fetch Substyle information: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onInputProductCode = (evt: any) => {
    if (typeof props.onInputProduct === 'function') {
      props.onInputProduct(evt.target.value.trim());
    }
  };

  const onSelectCPCodes = (value: string[]) => {
    // console.log('-->', value);
    /*const selDone = value.length > 0
        && selectedLengthCodes.length > 0
        && selectedSizeCodes.length > 0;*/
    //const cList = getCodeListByDesc(value, cpCodeList);
    let cList = getCodeListByUuid(value, cpCodeList);

    if (isProductType() && props.subStyleCode) {
      cList = [ ...value ];
    }

    //console.log('scc', value, cList);
    setSelectedCPCodes(value);
    //props.onSelectDone(selDone);
    props.onSelectDone({
      colorPatternList: cList,
      colorPatternDescList: value,
      lengthCodeList: uuidToCodeList(selectedLengthCodes, lengthCodeList),
      lengthDescList: selectedLengthCodes,
      sizeCodeList: uuidToCodeList(selectedSizeCodes, sizeCodeList),
      sizeDescList: selectedSizeCodes,
      widthCodeList: uuidToCodeList(selectedWidthCodes, widthCodeList),
      widthDescList: selectedWidthCodes,
    });
  };

  const onSelectLengthCodes = (value: string[]) => {
    /*const selDone = value.length > 0
        && selectedCPCodes.length > 0
        && selectedSizeCodes.length > 0;*/
    //const cList = getCodeListByDesc(value, lengthCodeList);
    const cList = getCodeListByUuid(value, lengthCodeList);

    //console.log('slc', value, cList);
    setSelectedLengthCodes(value);
    //setSelectedLengthCodes(cList);
    //props.onSelectDone(selDone);
    props.onSelectDone({
      colorPatternDescList: selectedCPCodes,
      colorPatternList: uuidToCodeList(selectedCPCodes, cpCodeList),
      lengthCodeList: cList,
      lengthDescList: value,
      sizeCodeList: uuidToCodeList(selectedSizeCodes, sizeCodeList),
      sizeDescList: selectedSizeCodes,
      widthCodeList: uuidToCodeList(selectedWidthCodes, widthCodeList),
      widthDescList: selectedWidthCodes,
    });
  };

  const onSelectSizeCodes = (value: string[]) => {
    /*const selDone = value.length > 0
        && selectedCPCodes.length > 0
        && selectedLengthCodes.length > 0;*/
    //const cList = getCodeListByDesc(value, sizeCodeList);
    const cList = getCodeListByUuid(value, sizeCodeList);

    //console.log('ssc', value, cList);
    setSelectedSizeCodes(value);
    //setSelectedSizeCodes(cList);
    //props.onSelectDone(selDone);
    props.onSelectDone({
      colorPatternList: uuidToCodeList(selectedCPCodes, cpCodeList),
      colorPatternDescList: selectedCPCodes,
      lengthCodeList: uuidToCodeList(selectedLengthCodes, lengthCodeList),
      lengthDescList: selectedLengthCodes,
      sizeCodeList: cList,
      sizeDescList: value,
      widthCodeList: uuidToCodeList(selectedWidthCodes, widthCodeList),
      widthDescList: selectedWidthCodes,
    });
  };

  const onSelectWidthCodes = (value: string[]) => {
    /*const selDone = value.length > 0
        && selectedCPCodes.length > 0
        && selectedLengthCodes.length > 0;*/
    //const cList = getCodeListByDesc(value, widthCodeList);
    const cList = getCodeListByUuid(value, widthCodeList);

    //console.log('swc', value, cList);
    setSelectedWidthCodes(value);
    //setSelectedWidthCodes(cList);
    //props.onSelectDone(selDone);
    props.onSelectDone({
      colorPatternDescList: selectedCPCodes,
      colorPatternList: uuidToCodeList(selectedCPCodes, cpCodeList),
      lengthCodeList: uuidToCodeList(selectedLengthCodes, lengthCodeList),
      lengthDescList: selectedLengthCodes,
      sizeCodeList: uuidToCodeList(selectedSizeCodes, sizeCodeList),
      sizeDescList: selectedSizeCodes,
      widthCodeList: cList,
      widthDescList: value,
    });
  };

  const onSubStyleChange = (sty: StringKAnyVPair) => {
    if (currentSubStyle.productId !== sty.productId) {
      //console.log('ch', sty, sty.colorPatternCode);
      setCurrentSubStyle(sty);
      onSelectCPCodes([sty.colorPatternCode]);
      loadSubStyleInfo(sty.subStyleCode);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = () => {
    loadStyleVariations();
  };

  const sortByCode = (a: any, b: any): number => {
    const strA = a.code ? a.code.toUpperCase() : ''; // ignore upper and lowercase
    const strB = b.code ? b.code.toUpperCase() : ''; // ignore upper and lowercase
    /*if (!strA || !strB) {
      return 0;
    }*/

    if (strA < strB) {
      return -1;
    }

    if (strA > strB) {
      return 1;
    }

    return 0;
  };

  // eslint-disable-next-line
  const trySetSubStyleCode = () => {
    if (props.subStyleCode && props.subStyleCodes) {
      const fs = props.subStyleCodes.filter(sty => {
        return props.subStyleCode && props.subStyleCode === sty.subStyleCode;
      });

      if (fs.length > 0) {
        setCurrentSubStyle(fs[0]);
      }
    }
  };

  // eslint-disable-next-line
  const uuidToCodeList = (uuids: string[], options: StringKAnyVPair[]) => {
    const ret: string[] = [];

    options.filter(e => uuids.indexOf(e.uuid) > -1)
      .map(e => e.code)
      .forEach(e => {
        if (ret.indexOf(e) < 0) ret.push(e);
      });

    if (ret.length === 0) {
      if (isProductType() && props.subStyleCode && uuids[0]) {
        if (props.subStyleCode.indexOf(uuids[0]) > -1) {
          uuids.forEach(e => ret.push(e));
        }
      }
    }

    return ret;
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitialData();
      extractStyleCodes();
      trySetSubStyleCode();
      setInited(true);
      console.log('opt ->', props.styleOption);
    }
  }, [
    extractStyleCodes,
    inited,
    loadInitialData,
    props,
    trySetSubStyleCode,
  ]);

  return (<>
    <div className="style-info-form code-selector">
      {isStyleCodeNeeded() && (<>
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Style</FormLabel>
          <span className="style-format">{props.styleCode}</span>
        </div>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Product Name</FormLabel>
          <span className="style-format">{props.productName}</span>
        </div>
        <Spacer />
      </>)}
      <div className="style-code-form-row middle">
        <FormLabel className="form-label">Class</FormLabel>
        <span className="style-format">{props.styleClass}</span>
      </div>
      {isSubStyleCodesNeeded() && Array.isArray(props.subStyleCodes) && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Exist Substyle</FormLabel>
          <span className="style-format">{props.subStyleCodes.map(e => e.subStyleCode).join(', ')}</span>
        </div>
      </>)}
      {!isProductType() && styleCodes.indexOf('ColorPatternCode') > -1 && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Color/Pattern Codes</FormLabel>
          <Select
            allowClear
            disabled={needSubStyle && isStyleStep()}
            onChange={onSelectCPCodes}
            optionFilterProp="label"
            mode="multiple"
            value={selectedCPCodes}
          >
            {codeOptions(cpCodeList, 'colors')}
          </Select>
        </div>
      </>)}
      {isStyleStep() && isProductInSubStyle() && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Substyle</FormLabel>
          <span className="style-format">{props.subStyleCode}</span>
        </div>
      </>)}
      {Array.isArray(props.products) && props.products.length > 0 && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Exist products</FormLabel>
          <span className="style-format">{props.products.map(e => e.sku).join(', ')}</span>
        </div>
      </>)}
      {isProductType() && isSubStyleSelectorEnabled() /*&& styleCodes.indexOf('ColorPatternCode') > -1*/ && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Substyle</FormLabel>
          <SubStyleSelector
            className="ctn-selector"
            current={currentSubStyle}
            onChange={onSubStyleChange}
            options={props.subStyleCodes || []}
            styleCode={props.styleCode}
          />
        </div>
      </>)}
      {isStyleStep() && isPatternCodeEnable('SizeCode') && (<>
        <Spacer />
          <div className="style-code-form-row middle">
            <FormLabel className="form-label">Size Codes</FormLabel>
            <Select
              allowClear
              onChange={onSelectSizeCodes}
              optionFilterProp="label"
              mode="multiple"
              value={selectedSizeCodes}
            >
              {codeOptions(sizeCodeList, 'sizes')}
            </Select>
        </div>
      </>)}
      {isStyleStep() && isPatternCodeEnable('LengthCode') && (<>
      <Spacer />
      <div className="style-code-form-row middle">
        <FormLabel className="form-label">Length Codes</FormLabel>
        <Select
          allowClear
          onChange={onSelectLengthCodes}
          optionFilterProp="label"
          mode="multiple"
          value={selectedLengthCodes}
        >
          {codeOptions(lengthCodeList, 'lengths')}
        </Select>
      </div>
      </>)}
      {isStyleStep() && isPatternCodeEnable('WidthCode') && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Width Codes</FormLabel>
          <Select
            allowClear
            onChange={onSelectWidthCodes}
            optionFilterProp="label"
            mode="multiple"
            value={selectedWidthCodes}
          >
            {codeOptions(widthCodeList, 'widths')}
          </Select>
        </div>
      </>)}
      {isStyleStep() && !isProductPatternValue() && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Product</FormLabel>
          <Input
            defaultValue={props.inputProduct}
            onChange={onInputProductCode}
            style={{width: '70%'}}
          />
        </div>
      </>)}
      {needSubStyle && isSubStyleStep() && (<>
        <Spacer />
        <div className="style-code-form-row middle">
          <FormLabel className="form-label">Substyle Format</FormLabel>
          <span className="style-format">{props.styleOption.SubStyleCodePattern}</span>
        </div>
      </>)}
      {isStyleStep() && isProductPatternValue() && (<>
      <Spacer />
      <div className="style-code-form-row middle">
        <FormLabel className="form-label">SKU Format</FormLabel>
        <span className="style-format">{props.styleOption.ProductPattern}</span>
      </div>
      </>)}
    </div>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default StyleCodeSelector;
