import usStatesJson from './us-states.json';

export type USState = {
  /**
   * ISO 3166-2 code
   */
  key: string;
  /**
   * ISO 3166-2 code
   */
  value: string;
  /**
   * Name of the state
   */
  label: string;
};

export const USStates: USState[] = Object.keys(usStatesJson).map((code) => {
  const mapping: { [key: string]: string } = usStatesJson;

  return ({
    key: code,
    value: code,
    label: mapping[code],
  });
});

/**
 * Mapping of {code: name}
 */
export const USStatesMap: {[key: string]: string} = usStatesJson;
