// eslint-disable-next-line
( function(module) {
    // private function
    // internal URI spitter method - direct from RFC 3986
    const splitUri = (uri) => {
      // eslint-disable-next-line
      const splitted = uri.match(/(?:([^:\/?#]+):)?(?:\/\/([^\/?#]*))?([^?#]*)(?:\?([^#]*))?(?:#(.*))?/);
      return splitted;
    };

    function isIri(value) {
        if (!value) {
            return;
        }

        // check for illegal characters
        // eslint-disable-next-line
        if (/[^a-z0-9\:\/\?\#\[\]\@\!\$\&\'\(\)\*\+\,\;\=\.\-\_\~\%]/i.test(value)) return;

        // check for hex escapes that aren't complete
        if (/%[^0-9a-f]/i.test(value)) return;
        if (/%[0-9a-f](:?[^0-9a-f]|$)/i.test(value)) return;

        let splitted = [];
        let scheme = '';
        let authority = '';
        let path = '';
        let query = '';
        let fragment = '';
        let out = '';

        // from RFC 3986
        splitted = splitUri(value);
        // eslint-disable-next-line
        scheme = splitted[1]; 
        // eslint-disable-next-line
        authority = splitted[2];
        // eslint-disable-next-line
        path = splitted[3];
        // eslint-disable-next-line
        query = splitted[4];
        // eslint-disable-next-line
        fragment = splitted[5];

        // scheme and path are required, though the path can be empty
        if (!(scheme && scheme.length && path.length >= 0)) return;

        // if authority is present, the path must be empty or begin with a /
        if (authority && authority.length) {
            if (!(path.length === 0 || /^\//.test(path))) return;
        } else {
            // if authority is not present, the path must not start with //
            if (/^\/\//.test(path)) return;
        }

        // scheme must begin with a letter, then consist of letters, digits, +, ., or -
        // eslint-disable-next-line
        if (!/^[a-z][a-z0-9\+\-\.]*$/.test(scheme.toLowerCase()))  return;

        // re-assemble the URL per section 5.3 in RFC 3986
        // eslint-disable-next-line
        out += scheme + ':';
        if (authority && authority.length) {
            // eslint-disable-next-line
            out += '//' + authority;
        }

        // eslint-disable-next-line
        out += path;

        if (query && query.length) {
            // eslint-disable-next-line
            out += '?' + query;
        }

        if (fragment && fragment.length) {
            // eslint-disable-next-line
            out += '#' + fragment;
        }

        return out;
    }

    function isHttpIri(value, allowHttps) {
        if (!isIri(value)) {
            return;
        }

        let splitted = [];
        let scheme = '';
        let authority = '';
        let path = '';
        let port = '';
        let query = '';
        let fragment = '';
        let out = '';

        // from RFC 3986
        splitted = splitUri(value);
        // eslint-disable-next-line
        scheme = splitted[1]; 
        // eslint-disable-next-line
        authority = splitted[2];
        // eslint-disable-next-line
        path = splitted[3];
        // eslint-disable-next-line
        query = splitted[4];
        // eslint-disable-next-line
        fragment = splitted[5];

        if (!scheme)  return;

        if (allowHttps) {
            if (scheme.toLowerCase() !== 'https') return;
        } else {
            if (scheme.toLowerCase() !== 'http') return;
        }

        // fully-qualified URIs must have an authority section that is
        // a valid host
        if (!authority) {
            return;
        }

        // enable port component
        if (/:(\d+)$/.test(authority)) {
            // eslint-disable-next-line
            port = authority.match(/:(\d+)$/)[0];
            authority = authority.replace(/:\d+$/, '');
        }

        // eslint-disable-next-line
        out += scheme + ':';
        // eslint-disable-next-line
        out += '//' + authority;
        
        if (port) {
            // eslint-disable-next-line
            out += port;
        }
        
        // eslint-disable-next-line
        out += path;
        
        if (query && query.length){
            // eslint-disable-next-line
            out += '?' + query;
        }

        if (fragment && fragment.length){
            // eslint-disable-next-line
            out += '#' + fragment;
        }
        
        return out;
    }

    function isHttpsIri(value) {
      return isHttpIri(value, true);
    }

    function isWebIri(value) {
      //return (isHttpIri(value) || isHttpsIri(value));
      return (isHttpsIri(value) || isHttpIri(value));
    }

    // eslint-disable-next-line
    module.exports.isUri = isIri;
    // eslint-disable-next-line
    module.exports.isHttpUri = isHttpIri;
    // eslint-disable-next-line
    module.exports.isHttpsUri = isHttpsIri;
    // eslint-disable-next-line
    module.exports.isWebUri = isWebIri;
})(module);
