import { dropshipAPI } from './http';

export interface SupplierConfigCreatePayload {
  supplierConfig: {
    listPreference: number;
    enabledAsSupplier: number;
    inventoryWarehousePublishRule: number;
  }
  warehouseStates: {
    state: string;
  }[];
  requiredTaxStates: {
    state: string;
  }[];
  company: {
    websiteUrl: string;
  };
}

export const getSupplierConfig = async (): Promise<Entities.SupplierConfigItem> => {
  const { data } = await dropshipAPI.get('/supplierConfig');
  return data;
};

export const createSupplierConfig = (data: SupplierConfigCreatePayload): Promise<void> => dropshipAPI.post('/supplierConfig', data);

export const updateSupplierConfig = (data: SupplierConfigCreatePayload): Promise<any> => dropshipAPI.patch('/supplierConfig', data);
