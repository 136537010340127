import React from 'react';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import Heading from "../components/common/Heading";

function Refund() {
  return (
    <ContentLayout>
      <SiteContent>
        <Heading title="Refund" />
      </SiteContent>
    </ContentLayout>
  );
}

export default Refund;
