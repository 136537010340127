import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  message,
  notification,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
// import CSVLink, { CSVColumns } from '../../../../../components/common/CSVLink';
import { DataGrid } from "../../../../components/common/datagrid/DataGrid3";
import Heading from "../../../../components/common/Heading";
import SearchBar, {
  SearchField,
} from "../../../../components/common/SearchBar";
import Spacer from "../../../../components/common/Spacer";
import ContentLayout from "../../../../components/ContentLayout";
import SiteContent from "../../../../components/SiteContent";
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from "../../../../constants/config";
// import ExprotModal from "./ExportModal";
import AddEditModal from "./AddEditModal";
import SelectContentSourceModal from "./SelectContentSourceModal";
import {
  delMediaFields,
  getMediaFields,
} from "../../../../services/essentialInfo";
import { fetchMediaGroups } from "../../../../services/products";

type ProductFeatureField = {
  rowNum: number;
  fieldName: string;
  dataType: number;
  attributeId: string;
  source: string;
  editable: boolean;
  displaySequence: number;
  // rows: number;
  columns: number;
  groupNum: number;
};

const ElementDataTypeEnum: { [key: string]: string } = {
  "0": "Place holder",
  "1": "string",
  "2": "Integer",
  "3": "Decimal",
  "4": "DataTime",
  "5": "ImageURL",
  "6": "Price",
  "7": "ImageUrlList",
  "8": "VideoURL",
  "9": "Virtual",
  "10": "Metafield",
  "11": "PageUrl",
};
const SourceEnum: { [key: string]: string } = {
  "0": "Custom",
  "1": "ERP",
  "2": "PIM",
};
const AddButton = styled(Button) <{ $hasPermission: boolean }>`
  display: ${(props) => (props.$hasPermission ? "initial" : "none")};
  min-height: 40px;
`;

const searchFields: Array<SearchField<ProductFeatureField> | string> = [
  "fieldName",
];

const PageContent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [sourceData, setSourceData] = useState<ProductFeatureField[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  // const [exportModalVisible, setExportModalVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [groupDict, setGroupDict] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [
    selectContentSourceModalVisible,
    setSelectContentSourceModalVisible,
  ] = useState(false);

  const getSourceData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getMediaFields();
      setLoading(false);
      if (res) {
        setSourceData(res.data);
        setFilterData(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const loadGroups = async () => {
    try {
      //setLoadingGroup(true);
      const res = await fetchMediaGroups();
      //setLoadingGroup(false);
      if (Array.isArray(res.data)) {
        const dict: StringKAnyVPair = {};
        res.data.forEach((e: StringKAnyVPair) => {
          if (!dict[e.groupNum]) {
            dict[e.groupNum] = e.groupName;
          }
        });
        console.log("res", dict);
        setGroupDict(dict);
      }
    } catch (e) {
      notification.error({
        message: `Loaded groups error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }
  };

  const deleteField = useCallback(
    async (data: ProductFeatureField) => {
      try {
        const res = await delMediaFields(data.rowNum);

        if (res) {
          message.success("delete successfully");
          getSourceData();
        }
      } catch (error) { }
    },
    [getSourceData]
  );

  const columns = [
    {
      header: "Field Name",
      name: "fieldName",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Data Type",
      name: "dataType",
      defaultFlex: 1,
      editable: false,
      render({ data }: any) {
        return data.dataType
          ? ElementDataTypeEnum[data.dataType]
          : "Place holder";
      },
    },
    {
      header: "Group",
      name: "groupNum",
      defaultFlex: 1,
      editable: false,
      render(row: any) {
        const { data } = row;

        return groupDict[data.groupNum] || "";
      },
    },
    {
      header: "Editable",
      name: "editable",
      defaultFlex: 1,
      editable: false,
      render({ data }: any) {
        return data.editable ? "Enable" : "Disable";
      },
    },
    {
      header: "Columns(%)",
      name: "columns",
      defaultFlex: 1,
      editable: false,
    },
    {
      name: "rowNum",
      header: "Action",
      defaultFlex: 3,
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      editable: false,
      render({ data }: any) {
        return (
          <Row justify="end" gutter={14}>
            <Col>
              <Button
                size="small"
                //disabled={!data.editable}
                onClick={() => {
                  console.log('data = ', data);
                  setCurrent(data);
                  setAddEditDialogVisible(true);
                }}
              >
                <EditOutlined style={{ color: "#006dff" }} />
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: "Do you want to delete this field?",
                    icon: <ExclamationCircleOutlined />,
                    content: `Field Name: ${data.fieldName}`,
                    onOk: async () => {
                      deleteField(data);
                    },
                    onCancel() { },
                  });
                }}
              >
                <DeleteOutlined style={{ color: "#c13939" }} />
                Delete
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (!inited) {
      loadGroups();
      getSourceData();
      setInited(true);
    }
  }, [getSourceData, inited]);

  return (
    <ContentLayout>
      <Heading
        title="Essential Info > Media > Fields"
        actions={
          <Space>
            <AddButton
              type="primary"
              $hasPermission={true}
              onClick={() => {
                setSelectContentSourceModalVisible(true);
              }}
            >
              <PlusCircleOutlined />
              Add
            </AddButton>
          </Space>
        }
      />
      <Spacer />
      <SiteContent flexGrow>
        <Spin spinning={loading} wrapperClassName="ant-spin-flex">
          <Row justify="space-between">
            <Col xs={20} md={12}>
              <SearchBar
                reference="rowNum"
                data={sourceData}
                onResult={setFilterData}
                fields={searchFields}
                disabled={false}
              />
            </Col>
            {/* <Button
              type="primary"
              disabled={sourceData.length === 0}
              onClick={() => setExportModalVisible(true)}
            >
              Export
            </Button> */}
          </Row>
          <Spacer height={14} />
          <DataGrid
            idProperty="rowNum"
            rowHeight={35}
            columns={columns}
            dataSource={filterData}
            loading={loading}
            pagination
          />
          {/* {exportModalVisible && (
            <ExprotModal
              fileName="Product-features-fields"
              data={sourceData}
              selectedRows={filterData}
              columns={columns}
              filterArr={["Action"]}
              formatColumns={{
                groupNum: groupDict,
                dataType: ElementDataTypeEnum,
                source: SourceEnum,
              }}
              onClose={() => setExportModalVisible(false)}
            />
          )} */}
          {addEditDialogVisible && (
            <AddEditModal
              mode={current?.rowNum ? "Edit" : "New"}
              // SourceEnum={SourceEnum}
              field={current}
              onClose={() => setAddEditDialogVisible(false)}
              onRefresh={() => {
                setAddEditDialogVisible(false);
                getSourceData();
              }}
              visible
            />
          )}
          {selectContentSourceModalVisible && (
            <SelectContentSourceModal
              visible
              SourceEnum={SourceEnum}
              onHide={() => {
                setSelectContentSourceModalVisible(false);
              }}
              onRefresh={(data) => {
                setCurrent({
                  ...data,
                  isPlaceHolder: data?.isPlaceHolder ? true : false,
                });
                setSelectContentSourceModalVisible(false);
                setAddEditDialogVisible(true);
              }}
            />
          )}
        </Spin>
      </SiteContent>
    </ContentLayout>
  );
};

export default PageContent;
