import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import { addMetafield } from "../../services/Metafield";
type AddDialogProps = {
  visible: boolean;
  onRefresh: Function;
  onClose: Function;
  typeList: any[];
  channels: any[];
  definitionNum: number;
};

const AddDialog = (props: AddDialogProps) => {
  const {
    visible,
    onClose,
    onRefresh,
    typeList,
    channels,
    definitionNum,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<any>();

  const [form] = Form.useForm();

  const saveField = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const { options, validation } = selectedType;
      const newOptions = options.map((i: any) => {
        return {
          ...i,
          value:
            i.type === "checkbox" || i.type === "switch"
              ? params[i.key]
                ? 1
                : 0
              : params[i.key],
        };
      });
      const newValidation = validation.map((i: any) => {
        return {
          ...i,
          value:
            i.type === "checkbox" || i.type === "switch"
              ? params[i.key]
                ? 1
                : 0
              : params[i.key],
        };
      });
      const res = await addMetafield({
        MetaFieldName: params.MetaFieldName,
        ChannelNum: params.ChannelNum,
        ChannelAccountNum: 0,
        DefinitionNum: definitionNum,
        TypeNum: params.TypeNum,
        IsCollectionSet: params.IsCollectionSet ? 1 : 0,
        Description: params.Description,
        ValidationJsonValue: newValidation,
        OptionsJsonValue: newOptions,
      });
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const formatItem = useCallback((item) => {
    if (item.validationJsonFormat) {
      try {
        item.validation = JSON.parse(item.validationJsonFormat);
      } catch (error) {
        item.validation = [];
      }
    }
    if (item.optionsJsonFormat) {
      try {
        item.options = JSON.parse(item.optionsJsonFormat);
      } catch (error) {
        item.options = [];
      }
    }
    return item;
  }, []);

  useEffect(() => {
    if (typeList && typeList.length > 0) {
      setSelectedType(formatItem(typeList[0]));
    }
  }, [typeList, formatItem]);

  const rendeItem = useCallback((item: any) => {
    switch (item.type) {
      case "text":
        return (
          <Form.Item
            key={item.key}
            label={item.label}
            name={item.key}
            initialValue={item.value}
          >
            <Input />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            // label={item.label}
            key={item.key}
            wrapperCol={{ span: 14, offset: 8 }}
            name={item.key}
            valuePropName="checked"
            initialValue={item.value === "1"}
          >
            <Checkbox>{item.label}</Checkbox>
          </Form.Item>
        );
      case "switch":
        return (
          <Form.Item
            key={item.key}
            label={item.label}
            name={item.key}
            valuePropName="checked"
            initialValue={item.value === "1"}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        );
      case "dropdown":
        return (
          <Form.Item
            key={item.key}
            label={item.label}
            name={item.key}
            initialValue={item.value}
          >
            <Select>
              {item.options.map((i: string) => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={item.key}
            label={item.label}
            name={item.key}
            initialValue={item.value}
          >
            <Input />
          </Form.Item>
        );
    }
  }, []);

  return (
    <Modal
      title="Add Metafield"
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} requiredMark={false}>
          <Form.Item
            label="Channel"
            name="ChannelNum"
            rules={[{ required: true, message: "channel is required" }]}
          >
            <Select
              placeholder="Channel"
              style={{ width: "100%" }}
              filterOption={(input: string, option: any) => {
                return ((option.children ||
                  option.label ||
                  option.value) as string)
                  .toLowerCase()
                  .startsWith(input.toLowerCase());
              }}
              showSearch
            >
              {channels.map((c: any) => (
                <Select.Option key={c.channelNum} value={c.channelNum}>
                  {c.channelName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Definiton Name"
            name="MetaFieldName"
            rules={[{ required: true, message: "Definiton name is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="Description">
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item
            label="Type"
            name="TypeNum"
            initialValue={typeList.length > 0 ? typeList[0].rowNum : undefined}
            rules={[{ required: true, message: " Type is required" }]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                const temp = typeList.filter((i) => i.rowNum === value);
                if (temp.length > 0) {
                  const tempType = formatItem(temp[0]);
                  console.log(tempType);
                  setSelectedType(tempType);
                }
              }}
            >
              {typeList.map((c: any) => (
                <Select.Option key={c.rowNum} value={c.rowNum}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Is List of Values"
            name="IsCollectionSet"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>

          {selectedType &&
            selectedType.validation.map((item: any) => rendeItem(item))}
          {selectedType &&
            selectedType.options.map((item: any) => rendeItem(item))}
        </Form>
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="end">
              <Button type="primary" onClick={saveField}>
                Save
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default AddDialog;
