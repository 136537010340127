import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Card, Col, notification, Row, Steps } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import { DragOutlined } from '@ant-design/icons';
import { Actions, ProductDetailScreenContext } from '../context';
import FormElement, { Type } from '../../../components/common/FormElement';
import {
  ExistentStyleMasterItemsWrapper,
  ListItem,
  MasterCodesWrapper,
  SKUFormatterTitle,
  SortableListContainer,
  SortableListItem,
  StepContentWrapper,
  StepsActions,
  StepsContent,
} from '../styledComponents';
import TableComponent from '../../../components/TableComponent';
import { objectToArray } from '../../../util';
import SearchBar from '../../../components/common/SearchBar';

// const sectionsVisibilityInitState = {
//   custom: false,
//   classification: false,
//   channels: {},
// };

// interface SectionsVisibilityType {
//   custom: boolean,
//   classification: boolean,
//   channels: { [key: string]: boolean },
// }

const GenerateSKUs: React.FC = () => {
  const [state] = useContext(ProductDetailScreenContext);
  const { generateSKUsColumns, generateSKUsValues } = state || {};
  return (
    <Row>
      <Col span={24}>
        <TableComponent
          columns={generateSKUsColumns}
          dataSource={generateSKUsValues}
          pagination={false}
          loadingStatus={false}
        />
      </Col>
    </Row>
  );
};

const EditReview: React.FC = () => {
  const [state] = useContext(ProductDetailScreenContext);
  const { editReviewColumns, editReviewValues } = state || {};

  return (
    <Row>
      <Col span={24}>
        <TableComponent
          columns={editReviewColumns}
          dataSource={editReviewValues}
          pagination={false}
          loadingStatus={false}
        />
      </Col>
    </Row>
  );
};

// const AttributeList: React.FC<{ attributes: Array<object> }> = ({ attributes }) => {
//
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   if (!state || !dispatch) {
//     return null;
//   }
//
//   const { editMode, booleanMapStyleMaster  } = state;
//
//   return (
//     <>
//       {attributes.map((at: any) => (
//         <Col key={at.AttributeId} span={12} style={{ paddingLeft: '15px' }}>
//           <Form.Item
//             hidden={!booleanMapStyleMaster[`${at.AttributeId}`]}
//             label={at.AttributeName}
//             name={['styleMaster', 'attributes', at.AttributeId]}
//             valuePropName="checked"
//             wrapperCol={{
//               span: 4,
//             }}
//             labelCol={{
//               span: 20,
//             }}
//           >
//             <Checkbox
//               disabled={!editMode}
//               name={at.AttributeName}
//             />
//           </Form.Item>
//         </Col>
//       ))}
//     </>
//   );
// };

// const ChannelsAttributes: React.FC<{ show: { [key: string]: boolean }, parentAttributesSelected: Array<string> }> = ({ show, parentAttributesSelected }) => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   if (!state || !dispatch) {
//     return null;
//   }
//
//   const { attributes, attributesChannels  } = state;
//
//   return (
//     <div>
//       {
//         attributesChannels.map((c: any) => {
//           const attributesChannelFiltered = attributes.filter((af: any) => af.AttributeChannelNum === c.channelNum && af.AttributeOptionList.length > 0)
//             .filter((f: Entities.ProductAttribute) => parentAttributesSelected.indexOf(f.AttributeId) === -1);
//           return (
//             <div
//               key={c.channelNum}
//               style={{ display: attributesChannelFiltered.length !== 0 && show[c.channelNum] ? '' : 'none' }}
//             >
//               <Row>
//                 <Col span={24} style={{ marginBottom: '45px' }}>
//                   <h3 style={{ marginBottom: '2px' }}>{c.channelName}</h3>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col
//                   span={24}
//                   style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}
//                 >
//                   <Row>
//                     <AttributeList attributes={attributesChannelFiltered} />
//                   </Row>
//                 </Col>
//               </Row>
//             </div>
//           );
//         })
//       }
//     </div>
//   );
// };
//
// const CustomAttributes: React.FC<{ show: boolean, parentAttributesSelected: Array<string> }> = ({ show, parentAttributesSelected }) => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   if (!state || !dispatch) {
//     return null;
//   }
//
//   const { attributes  } = state;
//
//   const attributesCustomFiltered = attributes?.filter((f: any) => f.AttributeChannelNum === 0 && f.AttributeOptionList.length > 0)
//     .filter((f: Entities.ProductAttribute) => parentAttributesSelected.indexOf(f.AttributeId) === -1);
//
//   return (
//     <div style={{ display: attributesCustomFiltered.length !== 0 && show ? '' : 'none' }}>
//       <Row>
//         <Col span={24} style={{ marginBottom: '45px' }}>
//           <h3 style={{ marginBottom: '2px' }}>Custom Attributes</h3>
//         </Col>
//       </Row>
//       <Row>
//         <Col span={24} style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}>
//           <Row>
//             <AttributeList attributes={attributesCustomFiltered} />
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// };
//
// const ClassificationAttributes: React.FC<{ show: boolean, parentAttributesSelected: Array<string>  }> = ({ show, parentAttributesSelected }) => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   if (!state || !dispatch) {
//     return null;
//   }
//   const { classifications, basicInfoForm, attributes  } = state;
//
//   const classification = basicInfoForm?.getFieldValue?.('ClassificationNum');
//
//   if (classification === 0 || classification === undefined) return null;
//
//   const classificationName = classifications.filter((f: any) => f.ClassificationNum === classification)[0]?.ClassificationName;
//   const attributesCustomFiltered = attributes.filter((f: Entities.ProductAttribute) => f.AttributeChannelNum === 0 && f.AttributeOptionList.length > 0)
//     .filter((f: Entities.ProductAttribute) => parentAttributesSelected.indexOf(f.AttributeId) === -1);
//
//   return (
//     <FullWidthDiv style={{ display: attributesCustomFiltered.length !== 0 || show ? '' : 'none' }}>
//       <Row>
//         <Col span={24} style={{ marginBottom: '45px' }}>
//           <h3 style={{ marginBottom: '2px' }}>{classificationName}</h3>
//         </Col>
//       </Row>
//       <Row>
//
//         <Col span={24} style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}>
//           <Row>
//             {
//               attributesCustomFiltered.length > 0 ? (
//                 <AttributeList attributes={attributesCustomFiltered} />
//               ) : (<h4 style={{ textAlign: 'center' }}>This classification has no attributes yet</h4>)
//             }
//           </Row>
//         </Col>
//       </Row>
//     </FullWidthDiv>
//   );
// };

// const AttributeListing: React.FC<{ sectionsVisibility: SectionsVisibilityType }> = ({ sectionsVisibility }) => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   const { attributesForm } = state || {};
//   if (!state || !dispatch) {
//     return null;
//   }
//   const parentAttributesSelected = Object.entries(attributesForm?.getFieldsValue()).map((a: any) => {
//     if (a[1] !== undefined) {
//       return a[0];
//     }
//     return null;
//   }).filter((f: any) => (f?.Value !== '' && f !== null));
//
//
//   return (
//     <FullWidthDiv>
//       <Spacer />
//       <ClassificationAttributes show={sectionsVisibility.classification} parentAttributesSelected={parentAttributesSelected} />
//       <Spacer />
//       <CustomAttributes show={sectionsVisibility.custom} parentAttributesSelected={parentAttributesSelected} />
//       <Spacer />
//       <ChannelsAttributes show={sectionsVisibility.channels} parentAttributesSelected={parentAttributesSelected} />
//     </FullWidthDiv>
//   );
// };

// const Attributes: React.FC = () => {
//
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   const [sectionsVisibility, setSectionsVisibility] = useState<SectionsVisibilityType>(sectionsVisibilityInitState);
//
//   const { attributeFilterForm, attributesChannels, groups1, groups2, originalAttributes, basicInfoForm, stylesFormatterItemsOrder } = state || {};
//
//   const classification = basicInfoForm?.getFieldValue?.('ClassificationNum');
//
//   const filterAttributes = useCallback(() => {
//     if (!originalAttributes || !attributeFilterForm || !dispatch) {
//       return;
//     }
//     const attributesFiltered = [...originalAttributes];
//     const filterValue = attributeFilterForm?.getFieldValue?.('Filter');
//     const channelValue = attributeFilterForm?.getFieldValue?.('Channel');
//     const group1Value = attributeFilterForm?.getFieldValue?.('Group1');
//     const group2Value = attributeFilterForm?.getFieldValue?.('Group2');
//     const newBooleanMap = attributesFiltered.reduce((p: any, c: Entities.ProductAttribute) => {
//       const r = { ...p };
//       r[c?.AttributeId || 0] = true;
//       return r;
//     }, {});
//
//     if (classification > 0) {
//       attributesFiltered.map((af: any) => {
//         if (!newBooleanMap[af.AttributeId]) {
//           return null;
//         }
//         newBooleanMap[af.AttributeId] = af.AttributeClassificationProfiles?.filter((acf: any) => acf.ClassificationNum === classification).length !== 0;
//         return null;
//       });
//     }
//
//     if (filterValue !== undefined && filterValue !== '') {
//       attributesFiltered.map((af: any) => {
//         if (!newBooleanMap[af.AttributeId]) {
//           return null;
//         }
//         newBooleanMap[af.AttributeId] = af.AttributeName.toLowerCase().includes(filterValue.toLowerCase());
//         return null;
//       });
//     }
//
//     if (channelValue !== undefined && channelValue !== '') {
//       attributesFiltered.map((af: any) => {
//         if (!newBooleanMap[af.AttributeId]) {
//           return null;
//         }
//
//         newBooleanMap[af.AttributeId] = af.AttributeChannelNum === channelValue;
//         return null;
//       });
//     }
//
//     if (group1Value !== undefined && group1Value !== '') {
//       attributesFiltered.map((af: any) => {
//         if (!newBooleanMap[af.AttributeId]) {
//           return null;
//         }
//         newBooleanMap[af.AttributeId] =  af.Group1.toLowerCase() === group1Value.toLowerCase();
//         return null;
//       });
//     }
//
//     if (group2Value !== undefined && group2Value !== '') {
//       attributesFiltered.map((af: any) => {
//         if (!newBooleanMap[af.AttributeId]) {
//           return null;
//         }
//
//         newBooleanMap[af.AttributeId] = af.Group2.toLowerCase() === group2Value.toLowerCase();
//         return null;
//       });
//     }
//
//     dispatch({ type: Actions.SET_ATTRIBUTE_FILTER_BOOLS_STYLE_MASTER, params: newBooleanMap });
//
//     const sections = {
//       custom: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 1 && f.AttributeChannelNum === 0).length > 0,
//       classification: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 2 && f.AttributeChannelNum === 0).length > 0,
//       channels: {},
//     };
//     attributesChannels?.map((ac: any) => {
//       sections.channels = {
//         ...sections.channels,
//         [ac.channelNum]: originalAttributes.filter((oa: any) => newBooleanMap[oa.AttributeId] && oa.AttributeChannelNum === ac.channelNum).length > 0,
//       };
//       return null;
//     });
//     setSectionsVisibility(sections);
//   }, [originalAttributes, attributeFilterForm, dispatch, classification, attributesChannels]);
//
//   const clear = () => {
//     if (!originalAttributes || !attributeFilterForm || !dispatch) {
//       return;
//     }
//     const attributesFiltered = [...originalAttributes];
//     const newBooleanMap = attributesFiltered.reduce((p: any, c: Entities.ProductAttribute) => {
//       const r = { ...p };
//       r[c?.AttributeId || 0] = true;
//       return r;
//     }, {});
//     dispatch({ type: Actions.SET_ATTRIBUTE_FILTER_BOOLS_STYLE_MASTER, params: newBooleanMap });
//
//     const sections = {
//       custom: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 1 && f.AttributeChannelNum === 0).length > 0,
//       classification: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 2 && f.AttributeChannelNum === 0).length > 0,
//       channels: {},
//     };
//     attributesChannels?.map((ac: any) => {
//       sections.channels = {
//         ...sections.channels,
//         [ac.channelNum]: originalAttributes.filter((oa: any) => newBooleanMap[oa.AttributeId] && oa.AttributeChannelNum === ac.channelNum).length > 0,
//       };
//       return null;
//     });
//     setSectionsVisibility(sections);
//     attributeFilterForm.resetFields();
//   };
//
//   if (!attributeFilterForm) {
//     return null;
//   }
//
//   return (
//     <ProductTabContainer>
//       <Row style={{ backgroundColor: '#e3e2e2', padding: '15px' }}>
//         <Col span={24}>
//           <Row>
//             <Col span={24} style={{ marginBottom: '45px' }}>
//               <h3 style={{ marginBottom: '2px' }}>Filter Attributes</h3>
//             </Col>
//           </Row>
//           <Row>
//             <Col span={24} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
//               <AttributeFilters
//                 attributeFilterForm={attributeFilterForm}
//                 channels={attributesChannels}
//                 groups1={groups1}
//                 groups2={groups2}
//                 filterAttributesFunc={filterAttributes}
//                 clearFiltersFunc={clear}
//               />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//       <Row>
//         <h3 style={{ margin: '10px auto' }}>
//           Add the custom attributes that relate to:
//           {' '}
//           { stylesFormatterItemsOrder?.filter((f: string) => !f.includes('Separator') && f !== 'STD').map((c: string) => c).join(' - ') }
//         </h3>
//       </Row>
//       <AttributeListing sectionsVisibility={sectionsVisibility} />
//     </ProductTabContainer>
//   );
// };

const MasterCodes: React.FC = () => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  if (!state || !dispatch) {
    return null;
  }

  const disabledVar = state?.productStyleMasterSelectedCodes && Object.keys(state?.productStyleMasterSelectedCodes).length > 0;

  return (
    <>
      <MasterCodesWrapper>
        {state.stylesVariations?.useSizeCode && (
          <Card title="Size Code" style={{ width: '50%', margin: '20px' }}>
            {state.stylesVariations?.sizeCode.map?.((sc: any) => (
              <FormElement
                key={sc.code}
                formItemProperties={{
                  name: ['styleMaster', 'sizeCode', sc.code],
                  label: sc.description,
                  labelCol: {
                    span: 6,
                  },
                  wrapperCol: {
                    span: 2,
                  },
                  valuePropName: 'checked',
                }}
                inputProperties={{
                  disabled: disabledVar ? state?.productStyleMasterSelectedCodes?.sizeCode.has(sc.code) : false,
                }}
                inputType={Type.CHECKBOX}
              />
            ))}
          </Card>
        )}
        {state.stylesVariations?.useColorPatternCode && (
          <Card title="Color Code" style={{ width: '50%', margin: '20px' }}>
            {state.stylesVariations.colorCode.map((sc: any) => (
              <FormElement
                key={sc.code}
                formItemProperties={{
                  name: ['styleMaster', 'colorCode', sc.code],
                  label: sc.description,
                  labelCol: {
                    span: 6,
                  },
                  wrapperCol: {
                    span: 2,
                  },
                  valuePropName: 'checked',
                }}
                inputProperties={{
                  disabled: disabledVar ? state?.productStyleMasterSelectedCodes?.colorCode.has(sc.code) : false,
                }}
                inputType={Type.CHECKBOX}
              />
            ))}
          </Card>
        )}
      </MasterCodesWrapper>
      <MasterCodesWrapper>
        {state.stylesVariations?.useLengthCode && (
          <Card title="Length Code" style={{ width: '50%', margin: '20px' }}>
            {state.stylesVariations.lengthCode.map((sc: any) => (
              <FormElement
                key={sc.code}
                formItemProperties={{
                  name: ['styleMaster', 'lengthCode', sc.code],
                  label: sc.description,
                  labelCol: {
                    span: 6,
                  },
                  wrapperCol: {
                    span: 2,
                  },
                  valuePropName: 'checked',
                }}
                inputProperties={{
                  disabled: disabledVar ? state?.productStyleMasterSelectedCodes?.lengthCode.has(sc.code) : false,
                }}
                inputType={Type.CHECKBOX}
              />
            ))}
          </Card>
        )}
        {state.stylesVariations?.useWidthCode && (
          <Card title="Width Code" style={{ width: '50%', margin: '20px' }}>
            {state.stylesVariations.widthCode.map((sc: any) => (
              <FormElement
                key={sc.code}
                formItemProperties={{
                  name: ['styleMaster', 'widthCode', sc.code],
                  label: sc.description,
                  labelCol: {
                    span: 6,
                  },
                  wrapperCol: {
                    span: 2,
                  },
                  valuePropName: 'checked',
                }}
                inputProperties={{
                  disabled:  disabledVar ? state?.productStyleMasterSelectedCodes?.widthCode.has(sc.code) : false,
                }}
                inputType={Type.CHECKBOX}
              />
            ))}
          </Card>
        )}
      </MasterCodesWrapper>
    </>
  );
};

const swapItems = (items: Array<string>, oldIndex: number, newIndex: number) => {
  const newItems = _.cloneDeep(items);
  const item = newItems.splice(oldIndex, 1);
  newItems.splice(newIndex, 0, ...item);

  return newItems.map((i: string) => i);
};

const Formatter: React.FC<{ isCreating: boolean }> = ({ isCreating }) => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  const { stylesFormatterItemsOrder = [] } = state || {};

  const onSortEnd = useCallback(({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (!dispatch) {
      return;
    }

    const newItems = swapItems(stylesFormatterItemsOrder, oldIndex, newIndex);
    dispatch({ type: Actions.SET_STYLE_FORMATTER_ITEMS_ORDER, params: newItems });

  }, [dispatch, stylesFormatterItemsOrder]);

  if (!state || !dispatch) {
    return null;
  }

  // eslint-disable-next-line react/no-unused-prop-types
  const SortableItem = SortableElement( ({ value }: { value: string }) => {
    if (value.includes('Separator')) {
      return (
        <SortableListItem>
          <DragOutlined style={{ marginRight: '5px' }} />
          <FormElement
            inputType={Type.INPUT}
            formItemProperties={{
              name: ['styleMaster', 'formatter', 'separators', value],
              style: { marginBottom: '0px', width: '50px', marginLeft: '10px' },
              initialValue: '-',
            }}
            inputProperties={{
              style: {  width: '100%' },
              maxLength: 1,
              disabled: !isCreating,
            }}
          />
        </SortableListItem>
      );
    }

    return (
      <SortableListItem>
        <DragOutlined style={{ marginRight: '5px' }} />
        <ListItem>{value}</ListItem>
      </SortableListItem>
    );
  });

  // eslint-disable-next-line react/no-unused-prop-types
  const SortableList = SortableContainer(({ items }: { items: Array<string> }) => (
    <SortableListContainer>
      <SKUFormatterTitle>SKU Formatter: </SKUFormatterTitle>
      <SortableListItem>
        <ListItem style={{ marginRight: '10px' }}>MEN-SHIRT-</ListItem>
      </SortableListItem>
      {items.map((value: string, index: number) => {
        if (index + 1 === items.length && value.includes('Separator')) {
          return null;
        }

        return (
          <SortableItem disabled={!isCreating} key={`item-${value}`} index={index} value={value} />
        );
      })}
      <FormElement
        formItemProperties={{
          name: ['styleMaster', 'formatter', 'allCaps'],
          label: 'All Caps',
          style: { marginBottom: '0px' },
          labelCol: {
            span: 18,
          },
          wrapperCol: {
            span: 2,
          },
          valuePropName: 'checked',
          initialValue: false,

        }}
        inputProperties={{
          disabled: !isCreating,
        }}
        inputType={Type.CHECKBOX}
      />
    </SortableListContainer>
  ));

  return (
    <>
      <SortableList axis="x" items={state.stylesFormatterItemsOrder} onSortEnd={onSortEnd} />
    </>
  );
};

const StyleMasterScreen: React.FC<{ isCreating: boolean }> = ({ isCreating }) => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  const { productStyleMasterValuesFiltered, productStyleMasterValues = [], productStyleMasterColumns, stylesFormatterItemsOrder = [], styleMasterCurrentView = 0, editMode, basicInfoForm, showSteps, productStyleMasterSelectedCodes, stylesVariations = {}  } = state || {};
  const next = useCallback(() => {
    if (!dispatch) {
      return;
    }
    dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: styleMasterCurrentView + 1 });
  }, [dispatch, styleMasterCurrentView]);


  const prev = useCallback(() => {
    if (!dispatch) {
      return;
    }

    dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: styleMasterCurrentView - 1 });
  }, [dispatch, styleMasterCurrentView]);

  const codes = stylesFormatterItemsOrder.filter((f: string) => !f.includes('Separator') && f !== 'STD');
  const styleValues = basicInfoForm?.getFieldValue('styleMaster');
  const isStyleCode = styleValues?.styleCode !== undefined;
  let newCodes = codes;
  if (!isCreating) {
    newCodes = stylesVariations && Object.keys(productStyleMasterSelectedCodes).length ? codes.filter((c: any) => stylesVariations[c].length !== productStyleMasterSelectedCodes[c].size ) : codes;
  }
  const masterNotification =  newCodes.map((c: string) => styleValues[c] ? objectToArray(styleValues[c]).filter((f: any) => f).length > 0 : false);
  const masterValuesNotification = masterNotification.indexOf(false) > -1;
  // const parentAttributesVerification = Object.entries(attributesForm?.getFieldsValue()).map((a: any) => {
  //   if (a[1] !== undefined) {
  //     return a[0];
  //   }
  //   return null;
  // }).filter((f: any) => (f?.Value !== '' && f !== null));

  useEffect(() => {
    if (!dispatch) {
      return;
    }
    switch (styleMasterCurrentView) {
      case 1:
        if (masterValuesNotification) {
          notification.error({ message: 'Please select at least 1 option for each code', duration: 3 });
          dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
        } else if (!isStyleCode) {
          notification.error({ message: 'Please fill Style field first on the Basic Info tab to continue', duration: 3 });
          dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
        } else {
          dispatch({ type: Actions.RECALCULATE_VARIATIONS });
        }
        // if (parentAttributesVerification.length === 0) {
        //   notification.error({ message: 'Please select at least 1 parent attribute (On Attributes tab) first to continue', duration: 3 });
        //   dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
        // }

        break;
      case 2:
        dispatch({ type: Actions.GENERATE_SKUS });
        break;
      default:
    }
  }, [newCodes.length, dispatch, isStyleCode, masterValuesNotification, styleMasterCurrentView]);

  const onAdd = useCallback(() => {
    if (!dispatch) {
      return;
    }
    dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: true });
  }, [dispatch]);
  const onCancel = useCallback(() => {
    if (!dispatch) {
      return;
    }
    basicInfoForm?.setFieldsValue({
      ...basicInfoForm.getFieldsValue(),
      styleMaster: {
        ...styleValues,
        sizeCode: null,
        colorCode: null,
        lengthCode: null,
        widthCode: null,
        editReviewValues: [],
      },
    });

    dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
    dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: false });
  }, [basicInfoForm, dispatch, styleValues]);

  const onSearchResult = useCallback((results: any) => {
    if (!dispatch) {
      return;
    }
    dispatch({ type: Actions.FILTER_PRODUCTSTYLEMASTERVALUES, params: results });
  }, [dispatch]);


  return (
    <>
      { !isCreating && (
        <Row>
          <Col offset={14} span={10}>
            { !showSteps ? (
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={onAdd}
                disabled={!editMode}
              >
                Add
              </Button>
            ) : (
              <Button
                style={{ float: 'right' }}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </Col>
        </Row>
      )}
      { !isCreating && !showSteps ? (
        <ExistentStyleMasterItemsWrapper>
          <Row>
            <Col span={14}>
              <SearchBar
                reference="sku"
                data={productStyleMasterValues}
                placeholder="Search SKU"
                onResult={onSearchResult}
                fields={['sku']}
              />
            </Col>
            <Col span={24}>
              <TableComponent
                columns={productStyleMasterColumns}
                dataSource={productStyleMasterValuesFiltered}
                pagination={false}
                loadingStatus={false}
                scroll={{ x: 'max-content' }}
              />
            </Col>
          </Row>
        </ExistentStyleMasterItemsWrapper>
      ) : (
        <>
          <Steps current={styleMasterCurrentView}>
            <Steps.Step title="Select Style Master Codes" />
            {/* { isCreating && (<Steps.Step title="Select Attributes" />)} */}
            <Steps.Step title="Edit/Review" />
            <Steps.Step title="Generate SKUs" />
          </Steps>
          <StepsActions>
            {styleMasterCurrentView < 3 - 1 && (
              <Button
                type="primary"
                onClick={next}
              >
                Next
              </Button>
            )}

            {styleMasterCurrentView > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={prev}>
                Previous
              </Button>
            )}
          </StepsActions>
          <StepsContent>
            <StepContentWrapper show={styleMasterCurrentView === 0}>
              <MasterCodes />
              <Formatter isCreating={isCreating} />
            </StepContentWrapper>
            {/* { isCreating && ( */}
            {/*  <StepContentWrapper show={styleMasterCurrentView === 1}> */}
            {/*    <Attributes /> */}
            {/*  </StepContentWrapper> */}
            {/* )} */}
            <StepContentWrapper show={styleMasterCurrentView === 1}>
              <EditReview />
            </StepContentWrapper>
            <StepContentWrapper show={styleMasterCurrentView === 2}>
              <GenerateSKUs />
            </StepContentWrapper>
          </StepsContent>
        </>
      )}

    </>
  );
};

export default StyleMasterScreen;
