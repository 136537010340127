import React from 'react';
import { Row, Table, Button } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  selectedSourceName: string;
  maskScreen: Function;
  resultList: any[];
};

const AttributeSelector = (props: Props) => {
  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Attribute',
    },
    {
      dataIndex: 'elementName',
      title: 'Content Resources',
    },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Row align="middle" className="section-bar">
          <span
            className="section-title"
            style={{ color: 'green' }}
          >{`The following fields were updated with the value from ${props.selectedSourceName}`}</span>
        </Row>
        <div className="section" style={{ marginTop: 0 }}>
          <Table
            columns={productBasicColumns}
            dataSource={props.resultList}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
        <Row justify="center" style={{ marginTop: 32 }}>
          <Link to="/file-import">
            <Button type="primary">Go to "Import Center" to check out the result</Button>
          </Link>
        </Row>
      </div>
    </>
  );
};

export default AttributeSelector;
