import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
};

const SectionWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1010;
`;

const FullScreenSection = (props: Props) => {
  return (<>
    <SectionWrapper>
      {props.children}
    </SectionWrapper>
  </>);
};

export default FullScreenSection;
