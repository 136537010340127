import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Spin, Switch } from "antd";
import { OverwriteRule } from ".";
import styled from "styled-components";
import { listProfileChannelAccounts } from "../../services/channels";
import { createOverwriteRule, updateOverwriteRule } from "../../services/qtyOverwriteRule";

type RuleEditDialogProps = {
  mode: 'New' | 'Edit';
  visible: boolean;
  rule?: OverwriteRule;
  channelAccountList?: Entities.ProfileChannelAccount[];
  onRefresh: Function;
  onClose: Function;
}

const SubFormLabel = styled.span`
  font-weight: 550;
  font-size: 14px;
  color: #546279;
`

const RuleEditDialog = (props: RuleEditDialogProps) => {
  const { mode = 'New', rule = {}, visible, onRefresh, onClose, channelAccountList } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Entities.ProfileChannelAccount[]>([]);
  const [ruleType, setRuleType] = useState<number>(1);
  const [lessVal, setLessVal] = useState<number>();
  const [greaterVal, setGreaterVal] = useState<number>();
  const [thisValue, setThisValue] = useState<string>();
  const [userOriginValue, setUserOriginValue] = useState<number>(1);
  const [thisValueError, setThisValueError] = useState<boolean>(false);

  const [mainForm] = Form.useForm();
  const [subForm] = Form.useForm();

  const getChannelAccountList = async () => {
    setAccounts([]);
    setLoading(true);
    try {
      const res = await listProfileChannelAccounts();
      if (res) {
        setAccounts(res);
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  }

  const saveRule = async () => {
    setLoading(true);
    try {
      const mainValue = await mainForm.validateFields();
      const subValue = await subForm.validateFields();

      if (ruleType !== 1 && userOriginValue === 0 && !thisValue) throw setThisValueError(true);

      const params = {
        ...mainValue,
        RuleNum: mode === 'New' ? 0 : rule.ruleNum,
        RuleExpression: {
          ...subValue,
          TreatZero: typeof subValue.TreatZero === 'boolean' ? (subValue.TreatZero ? 1 : 0) : undefined
        },
        RuleStatus: mode === 'New' ? 1 : rule.ruleStatus,
      }

      if ([2, 3].includes(ruleType)) {
        params.RuleExpression.UseOriginalValue = userOriginValue;
        if (userOriginValue === 0) params.RuleExpression.UserSetValue = thisValue;
      }
      console.log('params = ', params);

      mode === 'New' ? await createOverwriteRule(params) : await updateOverwriteRule(rule.ruleNum, params);
      onRefresh();

    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  }

  const initData = () => {
    if (mode === 'Edit' && rule.ruleNum) {
      const { channelAccountNum, ruleName, ruleType, ruleExpression } = rule;
      const { GreaterValue, LessValue, TreatZero: tz, UseOriginalValue, UserSetValue } = ruleExpression;
      mainForm.setFieldsValue({
        ChannelAccountNum: channelAccountNum,
        RuleName: ruleName,
        RuleType: ruleType,
      })
      setRuleType(ruleType)
      setUserOriginValue(UseOriginalValue);
      setLessVal(LessValue);
      setGreaterVal(GreaterValue);
      setThisValue(UserSetValue);
      subForm.setFieldsValue({
        TreatZero: tz === 1 ? true : false,
        GreaterValue,
        LessValue,
        UserSetValue,
      })
    }
  }

  useEffect(() => {
    channelAccountList ? setAccounts(channelAccountList) : getChannelAccountList();
    initData();
    // eslint-disable-next-line
  }, [])

  return (
    <Modal
      title={`${mode} Qty Overwrite Rule`}
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form form={mainForm} requiredMark={false}>
          <Form.Item label="Rule Name" name="RuleName" rules={[{ required: true, message: 'Rule name is required' }]}>
            <Input placeholder="Please input rule name" />
          </Form.Item>
          <Form.Item label="Channel Account" name="ChannelAccountNum" rules={[{ required: true, message: 'Channel account is required' }]}>
            <Select
              showSearch
              disabled={mode === 'Edit'}
              optionFilterProp="label"
              options={accounts.map(account => {
                return {
                  key: account.ChannelAccountNum,
                  value: account.ChannelAccountNum,
                  label: account.ChannelAccountName
                }
              })}
            />
          </Form.Item>
          <Form.Item label="Rule Type" name="RuleType" initialValue={1}>
            <Radio.Group
              disabled={mode === 'Edit'}
              onChange={(e) => {
                setRuleType(e.target.value);
                subForm.resetFields();
                setThisValue('');
                setThisValueError(false);
              }}
            >
              <Radio value={1}>Absolute Value</Radio>
              <Radio value={2}>At Least</Radio>
              <Radio value={3}>Lower to Zero</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Card>
          <Form form={subForm} requiredMark={false}>
            {
              [1, 2].includes(ruleType) && (
                <Form.Item name="TreatZero" label={<SubFormLabel>Treat no quantity as zero</SubFormLabel>} valuePropName="checked" initialValue={true}>
                  <Switch />
                </Form.Item>
              )
            }
            {
              ruleType === 1 && (
                <Form.Item name="UserSetValue" label={<SubFormLabel>Always use this value</SubFormLabel>} rules={[{ required: true, message: 'Please input' }]}>
                  <Input type="number" min={0} placeholder="Please input" allowClear />
                </Form.Item>
              )
            }
            {
              [2, 3].includes(ruleType) && (<>
                <Form.Item name="LessValue" rules={[{ required: true, message: 'Please input' }]}>
                  <Space>
                    <SubFormLabel>If the quantity is less than</SubFormLabel>
                    <Form.Item name="LessValue" noStyle>
                      {
                        ruleType === 3 ? (
                          <InputNumber
                            style={{ width: 60 }}
                            max={greaterVal}
                            onChange={e => setLessVal(e)}
                          />
                        ) : (
                          <InputNumber
                            style={{ width: 60 }}
                            min={0}
                            max={greaterVal}
                            onChange={e => setLessVal(e)}
                          />
                        )
                      }
                    </Form.Item>
                    <SubFormLabel>, {`${ruleType === 2 ? 'increase the value to match.' : 'use zero.'}`}</SubFormLabel>
                  </Space>
                </Form.Item>
                <Form.Item name="GreaterValue" rules={[{ required: true, message: 'Please input' }]}>
                  <Space>
                    <SubFormLabel>If the quantity is greater than</SubFormLabel>
                    <Form.Item name="GreaterValue" noStyle>
                      <InputNumber
                        // parser={(val: any) => val.replace(/\D+/g, '')}
                        // formatter={(val: any) => val.replace(/\D+/g, '')}
                        style={{ width: 60 }}
                        min={lessVal}
                        onChange={e => setGreaterVal(e)}
                      />
                    </Form.Item>
                    <SubFormLabel>:</SubFormLabel>
                  </Space>
                </Form.Item>
                <Form.Item name="UseOriginalValue" style={{ paddingLeft: 30 }}>
                  <Radio.Group value={userOriginValue} onChange={e => { setUserOriginValue(e.target.value); setThisValue('') }}>
                    <div><Radio value={1}>use original value</Radio></div>
                    <div><Radio value={0}>use this value</Radio>
                      {
                        ruleType === 3 ? (
                          <Input
                            value={thisValue}
                            type="number"
                            style={{ width: 60 }}
                            disabled={userOriginValue === 1}
                            onChange={e => {
                              setThisValue(e.target.value);
                              setThisValueError(false);
                            }}
                          />
                        ) : (
                          <Input
                            value={thisValue}
                            type="number"
                            min={0}
                            style={{ width: 60 }}
                            disabled={userOriginValue === 1}
                            onChange={e => {
                              setThisValue(e.target.value);
                              setThisValueError(false);
                            }}
                          />
                        )
                      }
                    </div>
                  </Radio.Group>
                  {thisValueError && <span style={{ color: '#BC0000', marginLeft: 8 }}>Please input value</span>}
                </Form.Item>
              </>)
            }
          </Form>
          <Row justify="center">
            <Col span={24}>
              <Row style={{ width: '100%' }} justify="end">
                <Button
                  type="primary"
                  onClick={saveRule}
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Modal >
  )
}

export default RuleEditDialog;