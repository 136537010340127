import { settingAPI } from './http';

type AddFieldParams = {
  rowNum?: number,  // product feature need
  fieldName: string,
  attributeId: number, // while placeholder,attributeId=0
  dataType: number,   // while placeholder,dataType=0
  groupNum: number,
  editable: number,
  rows: number,
  displaySequence: number,
  columns: number
  fieldNum?: number // media need
};


export const getGroups = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/ProductFeatureGroups');


export const getFields = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/ProductFeatureFields');

export const AddFields = async (params: AddFieldParams): Promise<any> => {
  const json = JSON.stringify(params);
  return settingAPI.post('/api/EssentialInfo/ProductFeatureFields', json)
}

export const EditFields = async (params: AddFieldParams): Promise<any> => {
  const json = JSON.stringify(params);
  return settingAPI.patch(`/api/EssentialInfo/ProductFeatureFields/${params.rowNum}`, json)
}

export const delFields = async (rowNum: number): Promise<any> => settingAPI.delete(`/api/EssentialInfo/ProductFeatureFields/${rowNum}`);


export const getCopywritingElements = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/CopywritingElements');

export const getMediaFields = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/media/field');

export const addMediaFields = async (params: AddFieldParams): Promise<any> => {
  const json = JSON.stringify(params);
  return settingAPI.post('/api/EssentialInfo/media/field', json);
}

export const EditMediaFields = async (params: AddFieldParams): Promise<any> => {
  const json = JSON.stringify(params);
  return settingAPI.patch(`/api/EssentialInfo/media/field/${params.rowNum}`, json)
}

export const delMediaFields = async (rowNum: number): Promise<any> => settingAPI.delete(`/api/EssentialInfo/media/field/${rowNum}`);