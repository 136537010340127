import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import styled from 'styled-components';
import { Actions, ProductDetailScreenContext } from '../context';
import { ProductTabContainer } from '../styledComponents';
import Spacer from '../../../components/common/Spacer';
import FormItemEnabler from '../../ProductBulkUpdate/FormItemEnabler';
import { useDebounce } from '../../../util';
import AttributeFormElement from '../AttributeFormElements';
import { Type } from '../../../components/common/FormElement';
import ConfirmationModal from '../../../components/ConfirmationModal';

const { Search } = Input;
const { Option } = Select;

export const FullWidthDiv = styled.div`
  width: 100%;
`;

const sectionsVisibilityInitState = {
  custom: false,
  classification: false,
  channels: {},
};

interface SectionsVisibilityType {
  custom: boolean,
  classification: boolean,
  channels: { [key: string]: boolean },
}

interface AttributesTabProps {
  isFormUpdated: boolean;
  dispatch: any;
  groups1: Array<string>;
  groups2: Array<string>;
  channels: Array<Entities.AttributesChannels>;
  attributesForm: FormInstance;
  attributeFilterForm: FormInstance;
  originalAttributes: Array<Entities.ProductAttribute>;
  classification: any;
  editMode: boolean,
  attributes: Array<Entities.ProductAttribute>,
  attributesChannels: Array<Entities.AttributesChannels>,
  classifications: Array<Entities.ProductClassification>,
  filterAttributesFunc: any,
  clearFunc: any,
  sectionsVisibility: SectionsVisibilityType;
}

const AttributeList: React.FC<{
  classification: any,
  attributes: any,
  editMode: any,
  attributesForm?: FormInstance,
  enabler: boolean,
  onCheckChange: Function,
}> = ({
  classification,
  attributes,
  editMode,
  // uncomment to bring default values back
  // attributesForm,
  enabler,
  onCheckChange,
}) => attributes.map((at: any) => {
  // uncomment to bring default values back
  // const defaultValue = '';
  let optionList = [];
  if (at.Classifications !== null) {
    const attClass = at.Classifications.filter((f: any) => f.ClassificationNum === classification);
    optionList = attClass[0]?.ClassificationOptionList || [];
    // uncomment to bring default values back
    // defaultValue = attributesForm?.getFieldValue(at.AttributeId) ? attributesForm?.getFieldValue(at.AttributeId) : attClass[0]?.DefaultValue;
  } else {
    optionList = at.AttributeOptionList;
    // uncomment to bring default values back
    // defaultValue = attributesForm?.getFieldValue(at.AttributeId) ? attributesForm?.getFieldValue(at.AttributeId) : at.DefaultValue;
  }

  const AttributeInput = enabler ? FormItemEnabler : AttributeFormElement;

  return (
    <Col key={at.AttributeId} span={12} style={{ paddingLeft: '15px' }}>
      {optionList.length > 0 ? (
        <AttributeInput
          inputProperties={{
            placeholder: at.AttributeName,
            showSearch: true,
            disabled: !editMode,
            // uncomment to bring default values back
            // defaultValue: enabler ? '' : defaultValue,
            style: { width: '100%' },
            options: optionList.map((aol: any) => ({
              label: aol.OptionValue,
              value: aol.OptionValue,
            })),
          }}
          inputType={Type.SELECT}
          formItemProperties={{
            label: at.AttributeName,
            name: at.AttributeId,
          }}
          onCheckChange={onCheckChange}
        />
      ) : (
        <AttributeInput
          formItemProperties={{
            label: at.AttributeName,
            name: at.AttributeId,
          }}
          inputProperties={{
            style: { width: '100%' },
            disabled: !editMode,
            // uncomment to bring default values back
            // defaultValue: enabler ? '' : at.DefaultValue,
          }}
          onCheckChange={onCheckChange}
        />
      )}
    </Col>
  );
});

export const ChannelsAttributes: React.FC<{
  channels: Array<Entities.AttributesChannels>,
  classification: number,
  attributes: Array<Entities.ProductAttribute>,
  editMode: boolean,
  attributesForm?: FormInstance
  enabler: boolean,
  onCheckChange?: Function,
  show: { [key: string]: boolean },
}> = ({
  channels,
  classification,
  attributes,
  editMode,
  attributesForm,
  enabler,
  onCheckChange = () => {},
  show,
}) => (
  <div>
    {
        channels.map((c: any) => {
          const attributesChannelFiltered = attributes.filter((af: any) => af.AttributeChannelNum === c.channelNum);
          return (
            <div key={c.channelNum} style={{ display: attributesChannelFiltered.length !== 0 && show[c.channelNum] ? '' : 'none' }}>
              <Row>
                <Col span={24} style={{ marginBottom: '45px' }}>
                  <h3 style={{ marginBottom: '2px' }}>{c.channelName}</h3>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}>
                  <Row>
                    <AttributeList
                      enabler={enabler}
                      classification={classification}
                      attributes={attributesChannelFiltered}
                      editMode={editMode}
                      attributesForm={attributesForm}
                      onCheckChange={onCheckChange}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })
      }
  </div>
);

export const CustomAttributes: React.FC<{
  enabler: boolean,
  editMode: boolean,
  classification: number,
  attributesForm?: FormInstance,
  attributes: Array<Entities.ProductAttribute>,
  onCheckChange?: Function,
  show: boolean,
}> = ({
  enabler,
  editMode,
  classification,
  attributesForm,
  attributes,
  onCheckChange = () => {},
  show,
}) => {
  const attributesCustomFiltered = attributes.filter((f: any) => f.AttributeChannelNum === 0);
  return (
    <div style={{ display: attributesCustomFiltered.length !== 0 && show ? '' : 'none' }}>
      <Row>
        <Col span={24} style={{ marginBottom: '45px' }}>
          <h3 style={{ marginBottom: '2px' }}>Custom Attributes</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}>
          <Row>
            <AttributeList
              enabler={enabler}
              classification={classification}
              attributes={attributesCustomFiltered}
              editMode={editMode}
              attributesForm={attributesForm}
              onCheckChange={onCheckChange}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const ClassificationAttributes: React.FC<{
  classification: number,
  attributes: Array<Entities.ProductAttribute>,
  editMode: boolean,
  attributesForm?: FormInstance,
  classifications: Array<Entities.ProductClassification>,
  enabler: boolean,
  onCheckChange?: Function,
  show: boolean,
}> = ({
  classification,
  attributes,
  editMode,
  attributesForm,
  classifications,
  enabler,
  onCheckChange = () => {},
  show,
}) => {

  if (classification === 0) return null;

  const classificationName = classifications.filter((f: any) => f.ClassificationNum === classification)[0]?.ClassificationName;
  const attributesCustomFiltered = attributes.filter((f: any) => f.AttributeChannelNum === 0);

  return (
    <FullWidthDiv style={{ display: attributesCustomFiltered.length !== 0 || show ? '' : 'none' }}>
      <Row>
        <Col span={24} style={{ marginBottom: '45px' }}>
          <h3 style={{ marginBottom: '2px' }}>{classificationName}</h3>
        </Col>
      </Row>
      <Row>

        <Col span={24} style={{ borderBottom: 'solid 1px #016dff', paddingBottom: '20px', marginBottom: '20px' }}>
          <Row>
            {
              attributesCustomFiltered.length > 0 ? (
                <AttributeList
                  enabler={enabler}
                  classification={classification}
                  attributes={attributesCustomFiltered}
                  editMode={editMode}
                  attributesForm={attributesForm}
                  onCheckChange={onCheckChange}
                />
              ) : (<h4 style={{ textAlign: 'center' }}>This classification has no attributes yet</h4>)
          }
          </Row>
        </Col>
      </Row>
    </FullWidthDiv>
  );
};

export const AttributeFilters: React.FC<{
  attributeFilterForm: FormInstance,
  channels: any,
  groups1: any,
  groups2: any,
  clearFiltersFunc: any,
  filterAttributesFunc: any,
}> = ({
  attributeFilterForm,
  channels,
  groups1,
  groups2,
  clearFiltersFunc,
  filterAttributesFunc,
}) => {

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  useEffect(filterAttributesFunc, [debouncedQuery, filterAttributesFunc]);
  return (
    <Form
      layout="inline"
      form={attributeFilterForm}
      size="large"
    >
      <Form.Item
        name="Filter"
      >
        <Search
          style={{ width: 200, marginRight: '5px', borderBottom: 'solid 1px #a7acb4', borderRadius: '0px' }}
          name="Filter"
          size="large"
          placeholder="Filter"
          onChange={(e: any) => setQuery(e.target.value)}
          bordered={false}
          value={query}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="Channel"
      >
        <Select
          placeholder="Channel"
          onChange={filterAttributesFunc}
          showSearch
          style={{ width: 200, marginRight: '5px' }}
        >
          {channels.map((g: any) => (<Option key={g.channelNum} value={g.channelNum}>{g.channelName}</Option>))}
        </Select>
      </Form.Item>
      <Form.Item
        name="Group1"
      >
        <Select
          placeholder="Group 1"
          showSearch
          onChange={filterAttributesFunc}
          style={{ width: 200, marginRight: '5px' }}
        >
          {groups1.map((g: any) => (<Option key={g} value={g}>{g}</Option>))}
        </Select>
      </Form.Item>
      <Form.Item
        name="Group2"
      >
        <Select
          placeholder="Group 2"
          showSearch
          onChange={filterAttributesFunc}
          style={{ width: 200, marginRight: '5px' }}
        >
          {groups2.map((g: any) => (<Option key={g} value={g}>{g}</Option>))}
        </Select>
      </Form.Item>
      <Form.Item
        name="Reset"
      >
        <Button type="link" size="small" danger onClick={clearFiltersFunc}>
          Clear filters
        </Button>
      </Form.Item>
    </Form>
  );
};

const AttributesTab: React.FC<AttributesTabProps> = ({
  editMode,
  attributes,
  attributesChannels,
  classifications,
  dispatch,
  isFormUpdated,
  groups1,
  groups2,
  channels,
  attributeFilterForm,
  attributesForm,
  classification,
  filterAttributesFunc,
  clearFunc,
  sectionsVisibility,
}) => {

  const [state] = useContext(ProductDetailScreenContext);
  const { basicInfoForm } = state || {};
  const [showConfirm, setShowConfirm] = useState(false);
  const attributesCustom = attributes.filter((f: any) => f.AttributeType === 1);
  const attributesClassification = attributes.filter((f: any) => f.AttributeType === 2);

  const resetStyleMaster = () => {
    const getStyleMasterValues = basicInfoForm?.getFieldValue('styleMaster');
    const getSelectedStyleMasterAttributes = Object.keys(getStyleMasterValues.attributes)
      .filter((element: string) => getStyleMasterValues.attributes[element])
      .map((element: string) => [element, false]);
    const editReviewValuesArr = getStyleMasterValues.editReviewValues;
    basicInfoForm?.setFieldsValue({
      ...basicInfoForm.getFieldsValue(),
      styleMaster: {
        ...getStyleMasterValues,
        attributes: {
          ...Object.fromEntries(getSelectedStyleMasterAttributes),
        },
        editReviewValues: editReviewValuesArr.map((element: object) => ({
          ...element,
          attributeList: [],
        })),
      },
    });
    dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 1 });
  };

  return (
    <ProductTabContainer>
      <ConfirmationModal
        handleCancel={() => setShowConfirm(false)}
        handleConfirm={resetStyleMaster}
        visible={showConfirm}
        title="Confirmation"
        confirmText="Yes"
        bodyText="You have Style Master SKUs generated, if you add more parent attributes you will have to re-select Style master attributes, are you sure you want to continue?"
        cancelText="No"
      />
      <Row style={{ backgroundColor: '#e3e2e2', padding: '15px' }}>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ marginBottom: '45px' }}>
              <h3 style={{ marginBottom: '2px' }}>Filter Attributes</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
              <AttributeFilters
                attributeFilterForm={attributeFilterForm}
                channels={channels}
                groups1={groups1}
                groups2={groups2}
                filterAttributesFunc={filterAttributesFunc}
                clearFiltersFunc={clearFunc}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Provider onFormChange={() => {
        if (!isFormUpdated) {
          dispatch({ type: Actions.SET_FORMUPDATED, params: true });
        }
      }}
      >
        <Form
          form={attributesForm}
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
        >
          {
            attributes.length > 0 ? (
              <FullWidthDiv>
                <Spacer />
                <ClassificationAttributes
                  classification={classification}
                  attributes={attributesClassification}
                  attributesForm={attributesForm}
                  classifications={classifications}
                  editMode={editMode}
                  enabler={false}
                  show={sectionsVisibility.classification}
                />
                <Spacer />
                <CustomAttributes
                  classification={classification}
                  attributes={attributesCustom}
                  attributesForm={attributesForm}
                  editMode={editMode}
                  enabler={false}
                  show={sectionsVisibility.custom}
                />
                <Spacer />
                <ChannelsAttributes
                  classification={classification}
                  channels={attributesChannels}
                  attributes={attributes}
                  attributesForm={attributesForm}
                  editMode={editMode}
                  enabler={false}
                  show={sectionsVisibility.channels}
                />
              </FullWidthDiv>
            ) : (<h4 style={{ textAlign: 'center' }}>No data found</h4>)
          }
        </Form>
      </Form.Provider>
    </ProductTabContainer>
  );
};

const Attributes: React.FC = () => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  const classification = state?.basicInfoForm?.getFieldValue?.('ClassificationNum');
  const { originalAttributes = null, attributeFilterForm = null, attributesChannels = [] } = state || {};
  const [sectionsVisibility, setSectionsVisibility] = useState<SectionsVisibilityType>(sectionsVisibilityInitState);

  const filterAttributes = useCallback(() => {
    if (!originalAttributes || !attributeFilterForm || !dispatch) {
      return;
    }
    const attributesFiltered = [...originalAttributes];
    const filterValue = attributeFilterForm?.getFieldValue?.('Filter');
    const channelValue = attributeFilterForm?.getFieldValue?.('Channel');
    const group1Value = attributeFilterForm?.getFieldValue?.('Group1');
    const group2Value = attributeFilterForm?.getFieldValue?.('Group2');
    const newBooleanMap = attributesFiltered.reduce((p: any, c: Entities.ProductAttribute) => {
      const r = { ...p };
      r[c?.AttributeId || 0] = true;
      return r;
    }, {});

    if (classification > 0) {
      attributesFiltered.map((af: any) => {
        if (!newBooleanMap[af.AttributeId]) {
          return null;
        }
        newBooleanMap[af.AttributeId] = af.AttributeClassificationProfiles?.filter((acf: any) => acf.ClassificationNum === classification).length !== 0;
        return null;
      });
    }

    if (filterValue !== undefined && filterValue !== '') {
      attributesFiltered.map((af: any) => {
        if (!newBooleanMap[af.AttributeId]) {
          return null;
        }
        newBooleanMap[af.AttributeId] = af.AttributeName.toLowerCase().includes(filterValue.toLowerCase());
        return null;
      });
    }

    if (channelValue !== undefined && channelValue !== '') {
      attributesFiltered.map((af: any) => {
        if (!newBooleanMap[af.AttributeId]) {
          return null;
        }

        newBooleanMap[af.AttributeId] = af.AttributeChannelNum === channelValue;
        return null;
      });
    }

    if (group1Value !== undefined && group1Value !== '') {
      attributesFiltered.map((af: any) => {
        if (!newBooleanMap[af.AttributeId]) {
          return null;
        }
        newBooleanMap[af.AttributeId] =  af.Group1.toLowerCase() === group1Value.toLowerCase();
        return null;
      });
    }

    if (group2Value !== undefined && group2Value !== '') {
      attributesFiltered.map((af: any) => {
        if (!newBooleanMap[af.AttributeId]) {
          return null;
        }

        newBooleanMap[af.AttributeId] = af.Group2.toLowerCase() === group2Value.toLowerCase();
        return null;
      });
    }

    dispatch({ type: Actions.SET_ATTRIBUTE_FILTER_BOOLS, params: newBooleanMap });

    const sections = {
      custom: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 1 && f.AttributeChannelNum === 0).length > 0,
      classification: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 2 && f.AttributeChannelNum === 0).length > 0,
      channels: {},
    };
    attributesChannels.map((ac: any) => {
      sections.channels = {
        ...sections.channels,
        [ac.channelNum]: originalAttributes.filter((oa: any) => newBooleanMap[oa.AttributeId] && oa.AttributeChannelNum === ac.channelNum).length > 0,
      };
      return null;
    });
    setSectionsVisibility(sections);
  }, [originalAttributes, attributeFilterForm, dispatch, classification, attributesChannels]);

  if (!state || !dispatch) {
    return null;
  }

  const clear = () => {
    if (!originalAttributes || !attributeFilterForm || !dispatch) {
      return;
    }
    const attributesFiltered = [...originalAttributes];
    const newBooleanMap = attributesFiltered.reduce((p: any, c: Entities.ProductAttribute) => {
      const r = { ...p };
      r[c?.AttributeId || 0] = true;
      return r;
    }, {});
    dispatch({ type: Actions.SET_ATTRIBUTE_FILTER_BOOLS, params: newBooleanMap });

    const sections = {
      custom: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 1 && f.AttributeChannelNum === 0).length > 0,
      classification: originalAttributes.filter((f: any) => newBooleanMap[f.AttributeId] && f.AttributeType === 2 && f.AttributeChannelNum === 0).length > 0,
      channels: {},
    };
    attributesChannels.map((ac: any) => {
      sections.channels = {
        ...sections.channels,
        [ac.channelNum]: originalAttributes.filter((oa: any) => newBooleanMap[oa.AttributeId] && oa.AttributeChannelNum === ac.channelNum).length > 0,
      };
      return null;
    });
    setSectionsVisibility(sections);
    attributeFilterForm.resetFields();
  };

  return (
    <AttributesTab
      isFormUpdated={state.isFormUpdated}
      dispatch={dispatch}
      originalAttributes={state.originalAttributes}
      groups1={state.groups1}
      groups2={state.groups2}
      channels={state.attributesChannels}
      attributeFilterForm={state.attributeFilterForm}
      attributesForm={state.attributesForm}
      classification={classification}
      editMode={state.editMode}
      attributes={state.attributes}
      attributesChannels={state.attributesChannels}
      classifications={state.classifications}
      filterAttributesFunc={filterAttributes}
      clearFunc={clear}
      sectionsVisibility={sectionsVisibility}
    />
  );
};


export default Attributes;
