import React, { useState } from 'react';
import { Col, Image, Input, Modal, Row, Space, Switch } from 'antd';
import { patchProductImage } from  '../../services/products'
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';

type Props = {
  attributeName: string;
  imageUrl: string;
  productId: string;
  imageAttributeNum: number;
  onClose: () => void;
  onSuccess: (url:string) => void;
  productType: number;
  defaultOverwrite: boolean;
};

const PreviewImageDialog = (props: Props) => {
  const { attributeName, imageUrl, onClose, onSuccess,productId,imageAttributeNum, productType } = props;
  const [editImageUrl, setEditImageUrl] = React.useState(imageUrl);
  const [editAble, setEditAble] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false)
  const [overwrite, setOverwrite] = useState(props.defaultOverwrite ? true : false);

  const onSaveImage = async() => {
    try {
      setLoading(true)
      const json = JSON.stringify([{imageAttributeNum,imageURL:editImageUrl}])
      await patchProductImage(productId,json, {params: {isApplyChild: productType !==1 ? overwrite : false,}})
      setLoading(false)
      onSuccess(editImageUrl);
    } catch (error) {
      setLoading(false)

    }
  };

  return (
    <Modal
      centered
      className="fullscreen-modal"
      confirmLoading={loading}
      okText={editAble ? 'Save' : 'Edit'}
      onCancel={onClose}
      onOk={()=>{
        if (!editAble) {
          setEditAble(true);
          return;
        }
        if(!editImageUrl.trim()){
          Modal.confirm({
            title:'Are you sure you want to save it as null?',
            onOk:()=>{
              onSaveImage()
            },
            onCancel:()=>{}
          })
        } else {
          onSaveImage()
        }
      }}
      style={{ paddingBottom: 0 }}
      title={imageUrl ? `View ${attributeName}` : `Edit ${attributeName}`}
      visible
      width={600}
      bodyStyle={{
        paddingTop:4
      }}
    >
      {productType !==1 && <Row justify='start' style={{marginBottom: 4}}>
        <Space>
          <span style={{marginRight: 4}}>Overwrite children's value</span>
          <Switch
            checkedChildren="Yes"
            onChange={setOverwrite}
            unCheckedChildren="No"
            checked={overwrite}
          />
        </Space>
      </Row>}
      <Row>
        <Col span={12} >
          {editImageUrl && (
            <Row align="middle" justify="center" style={{ border: '1px solid #D9D9D9', height: '100%', overflowY: 'auto' }}>
              <Image fallback={ImagePlaceholder} src={editImageUrl}/>
            </Row>
          )}
        </Col>
        <Col span={12} style={{ padding: '0 6px' }}>
          <Input.TextArea disabled={!editAble} onChange={(e) => setEditImageUrl(e.target.value)} placeholder="Please Input Image Url At Here" rows={8} value={editImageUrl} />
        </Col>
      </Row>
    </Modal>
  );
};

export default PreviewImageDialog;
