import React from 'react';
import styled from 'styled-components';
import Inbox from '../../assets/icons/inbox';

const Message = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: var(--Regulartext);
`;
const EmptySection = styled.div`
  height: 100px;
`;

function NoResultsMessage (props: any) {
  return !props.loading ? (
    <>
      <Inbox />
      <Message>No results found</Message>
    </>
  ) : (<EmptySection />)
}

export default NoResultsMessage;