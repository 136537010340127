import React, { useCallback, useEffect, useState } from "react";
import { CheckCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Row, Button, Space, Modal, Switch, Col, Typography, Card } from "antd";
import {
  useExportContentResourceFilterMutation,
  useExportContentResourceFileMutation,
} from "../../redux/api/exports";
import { useHistory } from "react-router-dom";

// use to hide based on env

const ExportSearchButton: React.FC<{
  includeERP: boolean;
  includeVirtual: boolean;
  mappingType: number;
  disabled: boolean;
  onCallback?: () => void;
  getSearchOptions?: any; // get search options fn
  exportAs?: string;
  param?: any;
}> = ({
  includeERP,
  includeVirtual,
  mappingType,
  onCallback,
  disabled,
  getSearchOptions,
  exportAs,
  param,
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportContentResourceFilterMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback();
  }, [onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      handleSuccess();
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const payload = {
    fileOperation: 20004,
    exportFile: {
      channelNum: 0,
      channelAccountNum: 0,
      mappingType,
      reason: "Content Resources",
      isForAllSku: false,
      isFileOperation: true,
      operationSetNum: 0,
      TemplateName: "",
      exportAs,
    },
    forSeller: 1,
    exportQuery: getSearchOptions?.call(null) || {},
    param: { ...param, includeVirtual, includeERP },
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(payload);
      }}
    >
      Export Search Result
      <CloudDownloadOutlined />
    </Button>
  );
};

const ExportButton: React.FC<{
  disabled: boolean;
  onCallback?: () => void;
  exportAs?: string;
  isForAllSku: boolean;
  CentralProductNumList?: any[];
  title: string;
  includeERP: boolean;
  includeVirtual: boolean;
}> = ({
  onCallback,
  disabled,
  exportAs,
  isForAllSku,
  CentralProductNumList,
  title,
  includeERP,
  includeVirtual,
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportContentResourceFileMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback();
  }, [onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      handleSuccess();
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const body = {
    fileOperation: 20004,
    channelNum: 0,
    channelAccountNum: 0,
    mappingType: 8,
    reason: "export Content Resources for seller",
    isForAllSku,
    isFileOperation: true,
    operationSetNum: 0,
    forSeller: 1,
    exportAs,
    param: JSON.stringify({
      groups: [],
      views: [],
      tags: [],
      assigns: [],
      includeERP,
      includeVirtual,
    }),
    CentralProductNumList,
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(body);
      }}
    >
      {title}
      <CloudDownloadOutlined />
    </Button>
  );
};

type ExportProps = {
  selectedRows: Array<any>;
  data: Array<any>;
  columns: Array<StringKAnyVPair>;
  fileName: string;
  filterArr: string[];
  onClose: () => void;
  getSearchOptions: any;
  param?: any;
};
export default function ExportCSV(props: ExportProps): JSX.Element {
  const { data, selectedRows, onClose, getSearchOptions, param } = props;
  const [exportCsv, setExportCsv] = useState(false);
  const [includeErp, setIncludeErp] = useState(false);
  const [includeVirtual, setIncludeVirtual] = useState(false);
  const history = useHistory();

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onClose();
  }, [onClose]);

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={handleHide}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title=" Export Content Resource for Seller "
      centered
      visible
      width={900}
      destroyOnClose
      onCancel={handleHide}
      footer={<Footer />}
    >
      <Row>
        <Col span={8} style={{ padding: "5px" }}>
          <Space>
            <Typography.Text strong>Include ERP</Typography.Text>
            <Switch
              checked={includeErp}
              onChange={setIncludeErp}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Space>
        </Col>
        <Col span={8} style={{ padding: "5px" }}>
          <Space>
            <Typography.Text strong>Include Virtual</Typography.Text>
            <Switch
              checked={includeVirtual}
              onChange={setIncludeVirtual}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Space>
        </Col>
        <Col span={8} style={{ padding: "5px" }}>
          <Space>
            <Typography.Text strong>Export As</Typography.Text>
            <Switch
              checked={exportCsv}
              onChange={setExportCsv}
              checkedChildren="CSV"
              unCheckedChildren="XLSX"
              style={{
                backgroundColor: "#006dff",
              }}
            />
          </Space>
        </Col>
      </Row>

      <Card
        size="small"
        bodyStyle={{
          alignItems: "center",
          display: "flex",
          justifyContent: "start",
          gap: 15,
        }}
      >
        {" "}
        <ExportButton
          includeERP={includeErp}
          includeVirtual={includeVirtual}
          disabled={selectedRows.length === 0}
          isForAllSku={false}
          title="Export Selected"
          CentralProductNumList={selectedRows.map((i) => i.ProductId)}
          onCallback={() =>
            Modal.confirm({
              icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
              content: `Data is scheduled to export. It may take some time.`,
              okText: "Close",
              okType: "primary",
              cancelText: "Check Out Export Result",
              onCancel: () => {
                history.push("/file-export");
              },
            })
          }
          exportAs={exportCsv ? "csv" : "xlsx"}
        />
        <ExportButton
          includeERP={includeErp}
          includeVirtual={includeVirtual}
          disabled={data.length === 0}
          isForAllSku={false}
          title="Export Current Page"
          CentralProductNumList={data.map((i) => i.ProductId)}
          onCallback={() =>
            Modal.confirm({
              icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
              content: `Data is scheduled to export. It may take some time.`,
              okText: "Close",
              okType: "primary",
              cancelText: "Check Out Export Result",
              onCancel: () => {
                history.push("/file-export");
              },
            })
          }
          exportAs={exportCsv ? "csv" : "xlsx"}
        />
        <ExportSearchButton
          includeERP={includeErp}
          includeVirtual={includeVirtual}
          disabled={false}
          mappingType={8}
          getSearchOptions={getSearchOptions}
          param={param}
          onCallback={() =>
            Modal.confirm({
              icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
              content: `Data is scheduled to export. It may take some time.`,
              okText: "Close",
              okType: "primary",
              cancelText: "Check Out Export Result",
              onCancel: () => {
                history.push("/file-export");
              },
            })
          }
          exportAs={exportCsv ? "csv" : "xlsx"}
        />
        <ExportButton
          includeERP={includeErp}
          includeVirtual={includeVirtual}
          disabled={false}
          isForAllSku={true}
          title="Export All"
          onCallback={() =>
            Modal.confirm({
              icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
              content: `Data is scheduled to export. It may take some time.`,
              okText: "Close",
              okType: "primary",
              cancelText: "Check Out Export Result",
              onCancel: () => {
                history.push("/file-export");
              },
            })
          }
          exportAs={exportCsv ? "csv" : "xlsx"}
        />
        {/* <Button
          type="primary"
          loading={exportLoading}
          onClick={() => {
            setExportLoading(true);
            queueFileExport({
              fileOperation: 20004,
              // channelAcctJsonFileId: `${templateInfo?.productMappingNum}`,
              channelNum: 0,
              channelAccountNum: 0,
              mappingType: 8,
              reason: 'Content Resources export',
              isForAllSku: true,
              isFileOperation: true,
              operationSetNum: 0,
              exportAs: exportCsv ? 'csv' : 'xlsx',
              param: JSON.stringify({...param}),
            });
          }}
        >
          Export All
        </Button> */}
      </Card>
    </Modal>
  );
}
