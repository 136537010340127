import { Modal } from 'antd';
import React, { useCallback, useRef } from 'react';
import { SearchBarComponent } from '../../../../components/common/SearchBar';
import { ModalTitle } from '../../../../components/common/styledComponents';
import ModalContent from './ModalContent';

interface WarehouseSelectionModalProps {
  disabled: boolean;
  onApply?: () => void;
  onCancel?: (distributionCenters: Entities.DistributionCenterWarehouseMapping[]) => void;
  modalVisible: boolean;
  setModalVisible: Function;
  distributionCenters?: any[];
  setDistributionCenters?: Function;
  loading: boolean;
  inventoryWarehouseTableColumns: any[];
  onlyTable?: boolean;
}

const WarehouseSelectionModal: React.FC<WarehouseSelectionModalProps> = (props: WarehouseSelectionModalProps) => {
  const {
    onCancel,
    modalVisible,
    setModalVisible,
    distributionCenters = [],
    setDistributionCenters,
    loading,
    inventoryWarehouseTableColumns,
    onlyTable = false,
    onApply
  } = props;
  const searchRef = useRef<SearchBarComponent>(null);

  const clearSearch = useCallback(() => {
    searchRef.current?.clear?.();
  }, [searchRef]);

  const onModalClose = useCallback(() => {
    clearSearch();
    setModalVisible(false);
    onCancel?.(distributionCenters);
    // eslint-disable-next-line
  }, [onCancel, distributionCenters, clearSearch]);

  return (
    <Modal
      centered
      style={{ minWidth: '980px' }}
      visible={modalVisible}
      onCancel={onModalClose}
      maskClosable={false}
      title={<ModalTitle>Warehouse</ModalTitle>}
      closable={true}
      footer={null}
      forceRender
    >
      <ModalContent
        onApply={onApply}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        distributionCenters={distributionCenters}
        setDistributionCenters={setDistributionCenters}
        loading={loading}
        inventoryWarehouseTableColumns={inventoryWarehouseTableColumns}
        onlyTable={onlyTable}
        onModalClose={onModalClose}
      />
    </Modal>
  );
};

export default WarehouseSelectionModal;