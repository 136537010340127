export const getProfilesState = () => {
  // eslint-disable-next-line global-require
  const store = require('./store').default;
  return store.getState().profiles;
};

export const getProfileSettings = () => {
  const store = require('./store').default;
  return store.getState().profileSettings;
}

export default getProfilesState;
