import { IntegrationsActions, IntegrationsActionTypes } from '../types/integrations';

export interface IntegrationsState {
  isActivated: boolean;
  loading: boolean;
  pageTitle: string;
  groupedChannels: Entities.ChannelProfile[][];
  channels: Entities.ChannelProfile[];
  channelFields: Entities.Platform[];
  oAuthURL?: string,
  verifyConnection: boolean;
  channelAccount?: Entities.ChannelAccountObj;
  profileSettings?: FormModels.IntegrationSettings;
  retailers: Entities.ChannelProfile[];
  editMode: boolean;
  formIsDirty: boolean;
  settingsFormIsDirty: boolean;
  savingSettings: boolean;
  formHasErrors: boolean;
  settingsFormHasErrors: boolean;
  globalEnabled: Entities.ChannelProfile[];
}

const initialState: IntegrationsState = {
  isActivated: false,
  loading: false,
  pageTitle: 'Channel Integration - New Connection',
  groupedChannels: [],
  channels: [],
  retailers: [],
  channelFields: [],
  oAuthURL: '',
  verifyConnection: false,
  channelAccount: undefined,
  profileSettings: undefined,
  editMode: false,
  formIsDirty: false,
  settingsFormIsDirty: false,
  savingSettings: false,
  formHasErrors: false,
  settingsFormHasErrors: false,
  globalEnabled:[],
};

const setGeneralData = (state: IntegrationsState, {
  channels,
  retailers,
  platforms,
  groupedChannels,
  globalEnabled,
}: { channels: Entities.ChannelProfile[], retailers: Entities.ChannelProfile[], platforms: Entities.Platform[], groupedChannels: Entities.ChannelProfile[][], globalEnabled: Entities.ChannelProfile[] }): IntegrationsState => ({
  ...state,
  channels,
  retailers,
  groupedChannels,
  channelFields: platforms,
  globalEnabled,
});

const profiles = (state = initialState, action: IntegrationsActionTypes) => {
  switch (action.type) {
    case IntegrationsActions.SET_GLOBALENABLED:
      return { ...state, globalEnabled: action.value };
    case IntegrationsActions.SET_RETAILERS:
      return { ...state, retailers: action.value };
    case IntegrationsActions.SET_FORMS:
      return { ...state, form: action.value.form, settingsForm: action.value.settingsForm };
    case IntegrationsActions.SET_PROFILE_SETTINGS:
      return { ...state, profileSettings: action.value };
    case IntegrationsActions.SET_VERIFY_CONNECTION:
      return { ...state, verifyConnection: action.value };
    case IntegrationsActions.SET_ISACTIVATED:
      return { ...state, isActivated: action.value };
    case IntegrationsActions.SET_FORM:
      return { ...state, form: action.value };
    case IntegrationsActions.SET_LOADING:
      return { ...state, loading: action.value };
    case IntegrationsActions.SET_OAUTHURL:
      return { ...state, oAuthURL: action.value };
    case IntegrationsActions.SET_CHANNELFIELDS:
      return { ...state, channelFields: action.value };
    case IntegrationsActions.SET_CHANNELS:
      return { ...state, groupedChannels: action.groupedChannels, channels: action.channels };
    case IntegrationsActions.SET_PAGETITLE:
      return { ...state, pageTitle: `Channel Integration - ${action.value} - New Connection` };
    case IntegrationsActions.SET_CHANNEL_ACCOUNT:
      return { ...state, channelAccount: action.value };
    case IntegrationsActions.SET_GENERAL_DATA:
      return setGeneralData(state, action.value);
    case IntegrationsActions.CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        editMode: action.value.editMode || false,
        channelAccount: undefined,
        isActivated: false,
        oAuthURL: undefined,
        verifyConnection: false,
        formIsDirty: false,
        settingsFormIsDirty: false,
      };
    case IntegrationsActions.SET_EDIT_MODE:
      return { ...state, editMode: action.value };
    case IntegrationsActions.CANCEL_EDITION:
      return { ...state, editMode: false, formIsDirty: false, settingsFormIsDirty: false };
    case IntegrationsActions.REMOVE_FORMS:
      return { ...state, form: undefined, settingsForm: undefined };
    case IntegrationsActions.SET_FORM_DIRTY_STATUS:
      return { ...state, formIsDirty: action.value };
    case IntegrationsActions.SET_SAVING_SETTINGS:
      return { ...state, savingSettings: action.value };
    case IntegrationsActions.SET_FORM_HAS_ERRORS:
      return { ...state, formHasErrors: action.value };
    case IntegrationsActions.SET_SETTINGS_FORM_HAS_ERRORS:
      return { ...state, settingsFormHasErrors: action.value };
    case IntegrationsActions.RESET_ERROR_STATUS:
      return { ...state, formHasErrors: false, settingsFormHasErrors: false };
    default:
      return state;
  }
};

export default profiles;
