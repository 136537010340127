import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Space, Spin, Tabs } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';
import React, { useCallback, useEffect, useMemo, useState } from 'react'; // eslint-disable-line
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FormButtons from '../components/common/FormButtons';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { Permissions } from '../constants/enums/permissions';
import { createCustomer, deleteCustomer, fetchCustomer, saveCustomer } from '../services/customers';
import { CustomerAddressType, CustomerStatusType, CustomerType } from '../types/enums';
import { enumToObjects } from '../util/enums';
import { useExecutePromise } from '../util/hooks';
import { validateEmail, validatePhoneFaxNumber, validateURL, validateZipcode } from '../util/validators';

const StyledInput = styled(Input)`
  padding: 0;
  font-size: 1.5rem;
  font-weight: 400;
  cursor: default;
`;

const StyledSpan = styled.span`
  font-size: 18pt;
  font-weight: 400;
`;
const StyledDiv = styled.div`
  border-bottom: 1px grey solid;
  font-size: 18pt;
  font-weight: 400;
  padding-top:10px;
  margin-bottom:15px;
`;
const StyledForm = styled(Form)`
  margin: 20px;
`;
const defaultFormBody = () => (
  {
    digit_seller_id: '',
    customerUuid: '',
    customerCode: '',
    customerName: '',
    contact: '',
    contact2: '',
    contact3: '',
    phone1: '',
    phone2: '',
    phone3: '',
    Phone4: '',
    email: '',
    website: '',
    businessType: '',
    priceRule: '',
    firstDate: '', // date
    currency: '',
    shippingCarrier: '',
    shippingClass: '',
    shippingAccount: '',
    priority: '',
    area: '',
    region: '',
    districtn: '',
    zone: '',
    taxId: '',
    resaleLicense: '',
    classCode: '',
    departmentCode: '',
    divisionCode: '',
    sourceCode: '',
    terms: '',
    updateDateUtc: '', // $date-time
    enterBy: '',
    updateBy: '',
    customerAddress: [
      {
        enterDateUtc: '',
        addressUuid: '',
        addressCode: '',
        description: '',
        name: '',
        firstName: '',
        lastName: '',
        suffix: '',
        company: '',
        companyJobTitle: '',
        attention: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        stateFullName: '',
        postalCode: '',
        postalCodeExt: '',
        county: '',
        country: '',
        email: '',
        daytimePhone: '',
        nightPhone: '',
        updateDateUtc: '',
        enterBy: '',
        updateBy: '',
      },
    ],
  }
);

interface AddressFormProps {
  fields: FormListFieldData[];
  operations: FormListOperation;
  editingDisabled: boolean;
}

const AddressForm: React.FC<AddressFormProps> = (
  {
    fields,
    operations,
    editingDisabled,
  },
) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const addAddress = useCallback(() => {
    const addressCode = `New ${fields.length + 1}`;
    operations.add({ addressCode });
    setSelectedIndex(fields.length);
  }, [operations, fields, setSelectedIndex]);
  const field = fields[selectedIndex];

  return (
    <Col>
      <Form.Item
        name="rowNum"
        noStyle
        hidden
      >
        <Input disabled />
      </Form.Item>
      <Space>
        <Col>Address List</Col>
        <Space>
          <Select value={selectedIndex} onChange={setSelectedIndex}>
            {fields.map((f, index) => (
              <Select.Option key={f.fieldKey} value={index}>
                <Form.Item name={[f.name, 'addressCode']} noStyle>
                  <Input
                    id={`select_${f.name}_addressCode`}
                    placeholder="New"
                    readOnly
                    tabIndex={-1}
                    bordered={false}
                  />
                </Form.Item>
              </Select.Option>
            ))}
          </Select>
          <Button disabled={editingDisabled} onClick={addAddress}>
            Add
            <PlusOutlined />
          </Button>
        </Space>
      </Space>
      <StyledDiv>
        <Space>
          <StyledSpan>Address -</StyledSpan>
          <Form.Item name={[field.name, 'addressCode']} noStyle>
            <StyledInput readOnly tabIndex={-1} bordered={false} id={`title_${field.name}_addressCode`} />
          </Form.Item>
        </Space>
      </StyledDiv>
      <Row justify="space-between" gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Code"
            name={[field.name, 'addressCode']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Type"
            name={[field.name, 'addressType']}
          >
            <Select disabled={editingDisabled}>
              {
                enumToObjects({ choices: CustomerAddressType }).map((c) => (
                  <Select.Option key={c.id} value={c.value} title={c.label}>
                    {c.label}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Description"
            name={[field.name, 'description']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Name"
            name={[field.name, 'name']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="First Name"
            name={[field.name, 'firstName']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={[field.name, 'lastName']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Suffix"
            name={[field.name, 'suffix']}
          >
            <Select disabled={editingDisabled}>
              {
                (['Mr', 'Mrs', 'Ms']).map((c) => (
                  <Select.Option key={c} value={c} title={c}>
                    {c}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Company"
            name={[field.name, 'company']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Title"
            name={[field.name, 'companyJobTitle']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Email"
            name={[field.name, 'email']}
            rules={[{ validator: validateEmail }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Daytime Phone"
            name={[field.name, 'daytimePhone']}
            rules={[{ validator: validatePhoneFaxNumber }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Nighttime Phone"
            name={[field.name, 'nightPhone']}
            rules={[{ validator: validatePhoneFaxNumber }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Address 1"
            name={[field.name, 'addressLine1']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Address 2"
            name={[field.name, 'addressLine2']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Address 3"
            name={[field.name, 'addressLine3']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="City"
            name={[field.name, 'city']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="State"
            name={[field.name, 'state']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Postal Code"
            name={[field.name, 'postalCode']}
            rules={[{ validator: validateZipcode }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="County"
            name={[field.name, 'county']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Country"
            name={[field.name, 'country']}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>

        </Col>
      </Row>
    </Col>
  );
};

const MasterForm: React.FC = () => {
  const { id: customerCode } = useParams<{ id: string }>();
  const history = useHistory();
  const creatingEntry = !customerCode;
  const [form] = Form.useForm<Entities.CustomerFormBody>();
  const getCustomer = useCallback(async (): Promise<Entities.CustomerFormBody> => {
    if (!customerCode) {
      return defaultFormBody();
    }
    const res = await fetchCustomer(customerCode);
    const CustomerAddress = (res.customerAddress.length > 0 ? res.customerAddress : defaultFormBody().customerAddress);
    return ({
      ...res.customer,
      customerAddress: CustomerAddress,
    });

  }, [customerCode]);
  const [data, loading, setReload] = useExecutePromise(getCustomer, defaultFormBody());
  const [editMode, setEditMode] = useState(creatingEntry);
  const [processing, setProcessing] = useState(false);
  const customerNamePlaceholder = useMemo(() => {
    if (!customerCode) {
      return 'New';
    }

    if (!data) {
      return '';
    }

    return data.customerName || data.customerCode;
  }, [customerCode, data]);
  const loadingData = useMemo(() => loading, [loading]);
  const deleteDisabled = useMemo(() => {
    if (editMode) {
      return false;
    }
    return !customerCode;
  }, [customerCode, editMode]);

  const onFinished = useCallback(async () => {
    const success = await form.validateFields()
      .then(() => // success
        true,
      )
      .catch(() => // error
        false,
      );
    if (!success) return;

    setProcessing(true);
    const formData = form.getFieldsValue();
    const { customerAddress, ...customer } = formData;
    const customerData: Entities.CustomerPostBody = {
      customer,
      customerAddress,
      customerAttributes: {},
    };
    // fetch request
    // creating/saving and entry and save back the retrieved results to the data
    if (creatingEntry) {
      try {
        await createCustomer({ customer: customerData });
        setReload(true);
        setEditMode(false);
        history.push(`/customer-details/${customer.customerCode}`);
      } catch (e) {
        notification.error({ message: `There was an error creating the customer. ${e}` });
      }
    } else {
      try {
        await saveCustomer({ customer: customerData });
        setReload(true);
        setEditMode(false);
      } catch (e) {
        notification.error({ message: `There was an error saving the customer. ${e}` });
      }
    }
    setProcessing(false);
  }, [creatingEntry, form, history, setReload]);

  const onDeleteClicked = useCallback(() => {
    if (!customerCode) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this entry?',
      content: 'This action cannot be reversed.',
      onOk: async () => {
        try {
          await deleteCustomer(customerCode);
          history.push('/buy-customers/');
        } catch (e) {
          notification.error({ message: `There was an error deleting the customer. ${e}` });
        }
      },
    });
  }, [customerCode, history]);

  const onEditClicked = useCallback(() => {
    setEditMode(true);
  }, []);

  const onCancelClicked = useCallback(() => {
    // return back if creating
    if (creatingEntry) {
      history.push('/buy-customers/');
      return;
    }
    // else reset
    setEditMode(false);
    if (data) {
      form.resetFields();
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [form, data, creatingEntry, history]);

  const editingDisabled = useMemo(() => processing || !editMode, [editMode, processing]);

  useEffect(() => {
    if (!data) {
      return;
    }

    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Spin spinning={loadingData}>
      <StyledForm
        form={form}
        labelAlign="right"
        labelCol={{ sm: { span: 24, offset: 0 }, lg: { span: 12, offset: 0 } }}
        onFinish={onFinished}
      >
        <Heading
          title={(
            <Space>
              <StyledSpan>
                Customer -
              </StyledSpan>
              <Form.Item name="customerName" noStyle>
                <StyledInput
                  id="heading_customerName"
                  placeholder={customerNamePlaceholder}
                  readOnly
                  tabIndex={-1}
                  bordered={false}
                />
              </Form.Item>
            </Space>
          )}
        />
        <Tabs
          tabBarExtraContent={(
            <FormButtons
              onDelete={onDeleteClicked}
              onCancel={onCancelClicked}
              onEdit={onEditClicked}
              permissionNumber={Permissions.MANAGE_DISTRIBUTION_CENTERS}
              editingMode={editMode}
              disableDelete={deleteDisabled}
              saving={processing}
            />
          )}
        >
          <Tabs.TabPane
            tab="Basic Info"
            key="basic"
          >
            <Form.Item
              name="rowNum"
              noStyle
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Row justify="space-between" gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Code"
                  name="customerCode"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="customerName"
                  rules={[{ required: true, message: 'This value is required' }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="customerType"
                >
                  <Select disabled={editingDisabled}>
                    {
                      enumToObjects({ choices: CustomerType }).map((c) => (
                        <Select.Option key={c.id} value={c.value} title={c.label}>
                          {c.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ validator: validateEmail }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Website"
                  name="webSite"
                  rules={[{ validator: validateURL }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="customerStatus"
                >
                  <Select disabled={editingDisabled}>
                    {
                      enumToObjects({ choices: CustomerStatusType }).map((c) => (
                        <Select.Option key={c.id} value={c.value} title={c.label}>
                          {c.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Contact 1"
                  name="contact"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Contact 2"
                  name="contact2"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Contact 3"
                  name="contact3"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Phone 1"
                  name="phone1"
                  rules={[{ validator: validatePhoneFaxNumber }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Phone 2"
                  name="phone2"
                  rules={[{ validator: validatePhoneFaxNumber }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Phone 3"
                  name="phone3"
                  rules={[{ validator: validatePhoneFaxNumber }]}
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <StyledDiv>Finance</StyledDiv>
            <Row justify="space-between" gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Business Type"
                  name="businessType"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['Dropship', 'Ecommerce']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Currency"
                  name="currency"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['US Dollar', 'Chinese Yuan']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Credit Limit"
                  name="creditLimit"
                >
                  <InputNumber disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Resale License"
                  name="resaleLicense"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Price Rule"
                  name="priceRule"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['First', 'Second']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Tax Rate"
                  name="taxRate"
                >
                  <Select disabled={editingDisabled}>
                    {
                      ([5, 6, 7, 8]).map((c) => (
                        <Select.Option key={c} value={c} title={c.toString()}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Discount Rate"
                  name="discountRate"
                >
                  <InputNumber disabled={editingDisabled} />
                </Form.Item>
                <Form.Item
                  label="Tax ID"
                  name="taxId"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Terms"
                  name="terms"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['Monthly', 'Annually']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Terms Days"
                  name="termsDays"
                >
                  <InputNumber disabled={editingDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <StyledDiv>Shipping</StyledDiv>
            <Row justify="space-between" gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Carrier"
                  name="shippingCarrier"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['UPS', 'FEDEX']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Class"
                  name="shippingClass"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['First', 'Second']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Priority"
                  name="priority"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['Priority', 'Standard']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Account"
                  name="shippingAccount"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Area"
                  name="area"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['First', 'Secondary', 'Third']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Region"
                  name="region"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['First', 'Secondary', 'Third']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="District"
                  name="districtn"
                >
                  <Select disabled={editingDisabled}>
                    {
                      (['First', 'Secondary', 'Third']).map((c) => (
                        <Select.Option key={c} value={c} title={c}>
                          {c}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Zone"
                  name="zone"
                >
                  <Input disabled={editingDisabled} />
                </Form.Item>
              </Col>
            </Row>

          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Addresses"
            key="addresses"
          >
            <Form.List name="customerAddress">
              {(fields, operations) => (
                <AddressForm
                  fields={fields}
                  operations={operations}
                  editingDisabled={editingDisabled}
                />
              )}
            </Form.List>
          </Tabs.TabPane>
        </Tabs>
      </StyledForm>
    </Spin>
  );
};

const CustomerDetails: React.FC = () => (
  <ContentLayout>
    <Spacer />
    <SiteContent>
      <MasterForm />
    </SiteContent>
  </ContentLayout>
);

export default CustomerDetails;
