import { notification, Spin, Tabs } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // eslint-disable-line
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';
import Drag from '../assets/icons/drag';
import FormButtons from '../components/common/FormButtons';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { Permissions } from '../constants/enums/permissions';
import {
  fetchDistributionCentersRoutingRules,
  saveDistributionCentersRoutingRules,
} from '../services/distributionCenter';
import { useExecutePromise } from '../util/hooks';

const DraggableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 503px;
  border-radius: 4px;
  border: solid 1px #d8dde6;
  background-color: #fff;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: normal;
  color: #546279;
  padding: 12px 10px;
  margin-bottom: 14px;
  cursor: grab;
`;

const DraggableItemContent = styled.div`
  display: block;
`;

const DraggableItemTitle = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4px;
`;

const DraggableItemSubtitle = styled.h5`
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const InstructionText = styled.p`
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  color: #2d3f5d;
  margin: 15px 0;
`;

const SortableItem = SortableElement(({ value }: { value: Entities.DistributionCenter }) => (
  <DraggableItem>
    <DraggableItemContent>
      <DraggableItemTitle>
        {value.DistributionCenterName}
      </DraggableItemTitle>
      {
        value.City && (
          <DraggableItemSubtitle>
            {value.City}
          </DraggableItemSubtitle>
        )
      }
    </DraggableItemContent>
    <Drag height={24} width={24} />
  </DraggableItem>
));

const SortableList = SortableContainer(({ items }: { items: Entities.DistributionCenter[] }) => (
  <ul style={{ padding: '0' }}>
    {items.map((value: Entities.DistributionCenter, index: number) => (
      <SortableItem key={`item-${value.DistributionCenterID}`} index={index} value={value} />
    ))}
  </ul>
));

// TODO: ask if swapping is the right behavior instead of a proper re-sort
const swapItems = (items: Entities.DistributionCenter[], oldIndex: number, newIndex: number) => {
  const newItems = _.cloneDeep(items);
  const item = newItems.splice(oldIndex, 1);
  newItems.splice(newIndex, 0, ...item);

  return newItems.map((dc, index) => ({ ...dc, Priority: index }));
};

/**
 * Retrieves the list of DCs and sorts them on ascending manner.
 */
const fetchSortedDistributionCenterRoutingRules = async () => {
  const res = await fetchDistributionCentersRoutingRules();
  return res.sort((a, b) => a.Priority - b.Priority);
};

/**
 * Distribution center priority modifications.
 *
 * This view takes care of modifying the priority of DCs, which in this case follows the
 * next rules (according to a discussion with Van on July 12th):
 *
 * - Priority is ascending, the lower the number, the highest priority it is
 *    - i.e.: DC A[Priority=2] and DC B[Priority=100], DC A has the highest priority
 * - The default priority for any DC is 0 (highest)
 *
 * @param isModal
 * @constructor
 */
function DistributionCenterRoutingRules({ isModal }: any) {
  const history = useHistory();
  const [data, loading] = useExecutePromise<Entities.DistributionCenter[]>(fetchSortedDistributionCenterRoutingRules, []);
  const [saving, setSaving] = useState(false);
  const [localData, setLocalData] = useState<Entities.DistributionCenter[]>([]);

  const onCancelClick = useCallback(() => {
    history.push('/settings-distribution-center');
  }, [history]);

  const onSaveClick = useCallback(async () => {
    const params = localData.map(dc => ({ DistributionCenterID: dc.DistributionCenterID, Priority: dc.Priority }));
    setSaving(true);
    try {
      await saveDistributionCentersRoutingRules(params);
      notification.success({
        message: 'Rules saved successfully',
      });
    } catch (e) {
      notification.error({
        message: 'There was an error processing your request',
      });
    } finally {
      setSaving(false);
    }
  }, [localData]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const newItems = swapItems(localData, oldIndex, newIndex);
    setLocalData(newItems);
  }, [localData]);

  useEffect(() => setLocalData([...data]), [data]);

  return (
    <ContentLayout isModal={isModal}>
      <Heading
        title="Order Routing"
      />
      <Spacer />
      <Spin spinning={loading}>
        <SiteContent>
          <Tabs
            tabBarExtraContent={(
              <FormButtons
                permissionNumber={Permissions.MANAGE_DISTRIBUTION_CENTERS}
                onCancel={onCancelClick}
                onSave={onSaveClick}
                saving={saving}
                editingMode
                hideDelete
              />
            )}
          >
            <Tabs.TabPane
              tab="Order Routing Priority"
            >
              <InstructionText>
                Drag order routings by distribution center priority
              </InstructionText>
              <SortableList
                items={localData}
                onSortEnd={onSortEnd}
              />
            </Tabs.TabPane>
          </Tabs>
        </SiteContent>
      </Spin>
    </ContentLayout>
  );
}

export default DistributionCenterRoutingRules;
