import { digitBridgeApi } from './http';

export const fetchCarrierAndServices = async ():Promise<any> => {
  const response = await digitBridgeApi.get('/v1/shipping/carriersAndServices');
  return response;
};

export const editCarrierAndServices = async (params: any):Promise<any> => {
  const response = await digitBridgeApi.patch(`/v1/shipping/carriersAndServices/${params.carrierService}`, params);
  return response;
};

export const deleteCarrierAndServices = async (carrierService: any):Promise<any> => {
  const response = await digitBridgeApi.delete(`/v1/shipping/carriersAndServices/${carrierService}`);
  return response;
};

export const addMethods = async (params: any):Promise<any> => {
  const response = await digitBridgeApi.post(`/v1/shipping/outboundMappings/`, params);
  return response;
};


export const fetchMethods = async (channel: number): Promise<any> => {
  const response = await digitBridgeApi.get(`/v1/shipping/outboundMappings?channelNum=${channel}`);
  return response;
};

export const editMethods = async (params: any): Promise<any> => {
  const response = await digitBridgeApi.patch(`/v1/shipping/outboundMappings/${params.UserOutboundMappingNum}`, params);
  return response;
};

export const deleteMethods = async (params: any): Promise<any> => {
  const response = await digitBridgeApi.delete(`/v1/shipping/outboundMappings/${params}`);
  return response;
};