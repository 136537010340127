// import { PlusOutlined } from '@ant-design/icons';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { Link } from 'react-router-dom';
import CSVLink from '../components/common/CSVLink';
import { DataGrid } from '../components/common/datagrid/DataGrid';
import Heading from '../components/common/Heading';
import SearchBar, { SearchField } from '../components/common/SearchBar';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { fetchDistributionCenters } from '../services/distributionCenter';
import { DistributionCenterType } from '../types/enums';
import { enumNameToLabel } from '../util/strings';


const columns = [
  {
    header: 'Name',
    name: 'DistributionCenterName',
    defaultFlex: 1,
    render({ value, data }: { data: Entities.DistributionCenter, value: string }) {
      return (
        <Link to={`/settings-distribution-center-details/${data.DistributionCenterID}`}>
          {value}
        </Link>
      );
    },
  },
  {
    header: 'Code',
    name: 'DistributionCenterCode',
    defaultFlex: 1,
  },
  {
    header: 'Type',
    name: 'DistributionCenterType',
    defaultFlex: 1,
    render({ value }: { value: number }) {
      return enumNameToLabel(DistributionCenterType[value]);
    },
  },
  {
    header: 'Status',
    name: 'DistributionCenterStatus',
    defaultFlex: 1,
    render({ value }: { value: number }) {
      return value ? 'Enabled' : 'Disabled';
    },
    filterEditor: BoolFilter,
  },
  {
    header: 'Is default',
    name: 'IsDefault',
    render({ value }: { value: number }) {
      return value ? 'Default' : 'No Default';
    },
    defaultFlex: 1,
  },
  {
    header: 'City',
    name: 'City',
    defaultFlex: 1,
  },
  {
    header: 'State',
    name: 'State',
    defaultFlex: 1,
  },
  {
    header: 'Contact',
    name: 'ContactName',
    defaultFlex: 1,
  },
  {
    header: 'Phone',
    name: 'ContactPhone',
    defaultFlex: 1,
    userSelect: true,
  },
];

const searchFields: Array<SearchField<Entities.DistributionCenter> | string> = [
  'DistributionCenterName',
  'DistributionCenterCode',
  {
    fieldName: 'DistributionCenterType',
    attributes: {
      extractor(dc) {
        return enumNameToLabel(DistributionCenterType[dc.DistributionCenterType]);
      },
    },
  },
  {
    fieldName: 'DistributionCenterStatus',
    attributes: {
      extractor(dc): string {
        return dc.DistributionCenterStatus === 1 ? 'Enabled' : 'Disabled';
      },
    },
  },
  'City',
  'State',
  {
    fieldName: 'IsDefault',
    attributes: {
      extractor(dc): string {
        return dc.IsDefault === 1 ? 'Default' : 'No Default';
      },
    },
  },
  'ContactName',
  'ContactPhone',
];


const useDataLoad = (): [Entities.DistributionCenter[], boolean] => {
  const [data, setData] = useState<Entities.DistributionCenter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchDistributionCenters()
      .then((res: Entities.DistributionCenter[]) => setData(res))
      .finally(() => setLoading(false));
  }, []);

  return [data, loading];
};


function DistributionCenter(props: any) {
  const { isModal } = props;
  const [data, loading] = useDataLoad();
  const [filteredData, setFilteredData] = useState<Entities.DistributionCenter[]>(data);

  // const handleRoutingRule = () => {
  //   props.history.push('/settings-distribution-center-routing-rules');
  // };

  return (
    <ContentLayout isModal={isModal}>
      <Heading
        title="Distribution Centers"
      // actions={(
      //   <Row gutter={12}>
      //     <Col>
      //       <Link to="/settings-distribution-center/create/">
      //         <Button type="primary">
      //           <PlusOutlined />
      //           Add
      //         </Button>
      //       </Link>
      //     </Col>
      //   </Row>
      // )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row gutter={12} justify="space-between">
          <Col xs={24} lg={12}>
            <SearchBar
              reference="DistributionCenterID"
              data={data}
              onResult={setFilteredData}
              fields={searchFields}
            />
          </Col>
          <Col>
            <CSVLink filename="distribution-centers.csv" data={filteredData} />
          </Col>
        </Row>
        <Spacer />
        <DataGrid
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          pagination
        />
      </SiteContent>
    </ContentLayout>
  );
}

export default DistributionCenter;
