import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  RightOutlined,
  ArrowLeftOutlined,
  // ExclamationCircleOutlined,
  EditOutlined,
  // DeleteOutlined,
  // DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  List,
  Typography,
  Card,
  Badge,
  Button,
  Space,
  Row,
  Col,
  // Dropdown,
  // Menu,
  // Modal,
} from "antd";
import ContentLayout from "../../components/ContentLayout";
import Heading from "../../components/common/Heading";
import SiteContent from "../../components/SiteContent";
import Spacer from "../../components/common/Spacer";
import {
  getMetafieldDefinitions,
  getMetafields,
  getMetaTypes,
} from "../../services/Metafield";
import { fetchChannels } from "../../services/channels";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import SearchBar, { SearchField } from "../../components/common/SearchBar";
// import CSVLink, {CSVColumns} from '../../components/common/CSVLink';
import { DataGrid } from "../../components/common/datagrid/DataGrid";
import AddDialog from "./AddDialog";
import EditDialog from "./EditDialog";

type DefinitionItem = {
  definitionNum: number;
  description: string;
  icon: string;
  metafieldCount: number;
  name: string;
};

type MetafieldItem = {
  metafieldNum: number;
  metafieldName: string;
  definionNum: number;
  definitionName: string;
  description: string;
  channelNum: number;
  channelName: string;
  productCount: number;
  typeNum: number;
  typeName: string;
};

type TypeItem = {
  rowNum: number;
  name: string;
  groupName: string;
  rank: number;
  isPopular: number;
  icon: any;
  validationJsonFormat: any;
  optionsJsonFormat: any;
};

const PageContent: React.FC = () => {
  const [sourceData, setSourceData] = useState<DefinitionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [
    selectedDefinition,
    setSelectedDefinition,
  ] = useState<DefinitionItem>();
  const [fieldsData, setFieldsData] = useState<MetafieldItem[]>([]);
  const [localData, setLocalData] = useState<MetafieldItem[]>([]);
  const [current, setCurrent] = useState<MetafieldItem>();
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [channels, setChannels] = useState<any>([]);

  const [typeList, setTypeList] = useState<TypeItem[]>([]);

  const searchFields = useMemo(
    (): Array<SearchField<Entities.CopywritingElement> | string> => [
      {
        fieldName: "metafieldName",
      },
    ],
    []
  );

  const columns: TypeColumn[] = [
    {
      name: "metafieldName",
      header: "Definiton Name",
      defaultFlex: 2,
    },
    {
      name: "typeName",
      header: "Type",
      defaultFlex: 1,
    },
    {
      name: "channelName",
      header: "Channel",
      defaultFlex: 1,
    },

    {
      name: "action",
      header: "Action",
      defaultFlex: 3,
      minWidth: 230,
      maxWidth: 230,
      sortable: false,
      editable: false,
      render({ data /**rowIndex, rowId, columnId, columnIndex */ }: any) {
        return (
          <Row justify="start">
            <Col>
              <Button
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setCurrent(data);
                  setEditDialogVisible(true);
                }}
              >
                Edit
              </Button>
            </Col>
            {/* <Col>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu
                    id="grid_more"
                    onClick={(e) => {
                      if (e.key === "1") {
                        Modal.confirm({
                          title: `Do you want to delete this metafield`,
                          icon: <ExclamationCircleOutlined />,
                          content: `Definiton Name: ${data?.metafieldName}`,
                          onOk: () => {},
                          onCancel() {},
                        });
                      }
                    }}
                  >
                    <Menu.Item
                      key="1"
                      id="menu1"
                      disabled={data.source}
                      icon={<DeleteOutlined style={{ color: "#c13939" }} />}
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size="small">
                  <Space>
                    More
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col> */}
          </Row>
        );
      },
    },
  ];

  const fetchTypes = useCallback(async () => {
    try {
      const res = await getMetaTypes();
      setTypeList(res);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getChannels = useCallback(async () => {
    try {
      const res = await fetchChannels();
      setChannels(res);
    } catch (error) {}
  }, []);

  const fetchDefinitions = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getMetafieldDefinitions();
      setLoading(false);
      setSourceData(res);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const fetchMetafields = useCallback(async (item: DefinitionItem) => {
    try {
      setLoading(true);
      const res = await getMetafields(item.definitionNum);
      setLoading(false);
      setFieldsData(res);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedDefinition) {
      fetchMetafields(selectedDefinition);
    } else {
      fetchDefinitions();
    }
  }, [fetchDefinitions, fetchMetafields, selectedDefinition]);

  useEffect(() => {
    fetchTypes();
    getChannels();
  }, [fetchTypes, getChannels]);

  return (
    <ContentLayout>
      {selectedDefinition ? (
        <>
          <Heading
            title={
              <Space>
                <Button
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() => {
                    setSelectedDefinition(undefined);
                  }}
                >
                  <ArrowLeftOutlined />
                </Button>

                <Typography.Text
                  style={{
                    fontSize: 26,
                    fontWeight: 900,
                    margin: 0,
                  }}
                >
                  Product metafield definitons
                </Typography.Text>
              </Space>
            }
          />
          <Typography.Paragraph type="secondary" style={{ paddingLeft: 12 }}>
            Add a custom piece of data to a specific part of your store.
          </Typography.Paragraph>
          <SiteContent noPadding flexGrow transparent>
            <Card>
              <Row justify="space-between">
                <Col xs={24} md={12}>
                  <SearchBar<any>
                    reference="metafieldNum"
                    data={fieldsData}
                    onResult={setLocalData}
                    fields={searchFields}
                  />
                </Col>
                <Space>
                  <Button onClick={() => setAddDialogVisible(true)}>
                    Add Metafield
                    <PlusOutlined />
                  </Button>
                </Space>
              </Row>
              <Spacer height={14} />
              <DataGrid
                style={{ minHeight: 500 }}
                columns={columns}
                dataSource={localData}
                loading={loading}
                inlineEdit={false}
                pagination
              />
            </Card>
          </SiteContent>
        </>
      ) : (
        <>
          <Heading title="Metafield definitions" />
          <Typography.Paragraph type="secondary">
            Add a custom piece of data to a specific part of your store.
          </Typography.Paragraph>
          <SiteContent noPadding flexGrow transparent>
            <Card style={{ maxWidth: 1200 }}>
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={sourceData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.name}
                      description={item.description}
                    />
                    <Space>
                      <Badge
                        showZero
                        style={{ backgroundColor: "#52c41a" }}
                        count={item.metafieldCount}
                      />
                      <Button
                        type="link"
                        onClick={() => {
                          setSelectedDefinition(item);
                        }}
                        icon={<RightOutlined />}
                      />
                    </Space>
                  </List.Item>
                )}
              />
            </Card>
          </SiteContent>
        </>
      )}
      {addDialogVisible && (
        <AddDialog
          visible
          onClose={() => {
            setAddDialogVisible(false);
          }}
          onRefresh={() => {
            setAddDialogVisible(false);
            if (selectedDefinition) {
              fetchMetafields(selectedDefinition);
            }
          }}
          typeList={typeList}
          channels={channels}
          definitionNum={selectedDefinition?.definitionNum || 0}
        />
      )}
      {editDialogVisible && current && (
        <EditDialog
          visible
          metafield={current}
          onClose={() => {
            setEditDialogVisible(false);
          }}
          onRefresh={() => {
            setEditDialogVisible(false);
            if (selectedDefinition) {
              fetchMetafields(selectedDefinition);
            }
          }}
          // typeList={typeList}
        />
      )}
    </ContentLayout>
  );
};

export default PageContent;
