import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Spacer from '../../../components/common/Spacer';
import TableComponent from '../../../components/TableComponent';
import NotificationActions from '../../../redux/actions/notifications';
import Quickbooks, { ChannelAccountSettings, SimpleElement } from '../../../services/quickbooks';
import { enumToObjects } from '../../../util/enums';
import { LetterCasingMode } from '../../../util/strings';
import {
  Actions,
  CustomerRecordCreation,
  ExportOptions,
  formInitialValues,
  InvoiceImport,
  ItemCreationRule,
  onFormSubmit,
  PageState,
  QuickbooksSettingsScreenContext,
  SalesReceipt,
  SalesTaxExport,
} from '../context';

const PageSection = styled.div`
border-top: 1px solid #d8dde6;
padding-top: 4px;
`;

const PageSectionTitle = styled.h4`
margin-bottom: 12px;
font-weight: bold;
`;

const simpleElementToOption = (entry: SimpleElement): { label: string, value: any } => ({
  label: entry.name,
  value: entry.id,
});

const shippingCouponsExportOptionFlags = [ExportOptions.DAILY_SUMMARY_SALES_RECEIPT, ExportOptions.DAILY_SUMMARY_INVOICE];
const findField = (fields: FieldData[], fieldPath: string) => fields.find(i => (i.name as Array<string>).join('.') === fieldPath);

const DisconnectButton: React.FC = () => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useContext(QuickbooksSettingsScreenContext);

  const onDisconnectClick = useCallback(() => {
    const notifications = bindActionCreators(NotificationActions, reduxDispatch);

    Quickbooks.disconnectUser()
      .then(() => {
        notifications.setNotification('success', 'Your account has been disconnected. This page will refresh in 5 seconds.');
        dispatch?.({ type: Actions.SET_PAGE_STATE, params: PageState.FETCHING_INFO });
        setTimeout(() => window.location.reload(), 5000);
      })
      .catch(() => {
        notifications.setNotification('error', 'There was an error disconnecting your account');
      });
  }, [dispatch, reduxDispatch]);

  if (state?.settings?.integrationSetting?.qboSettingStatus === 1 && state.pageState !== PageState.FETCHING_INFO) {
    return (
      <Button
        type="primary"
        danger
        onClick={onDisconnectClick}
      >
        Disconnect
      </Button>
    );
  } else {
    return null;
  }
};

const CustomerDropdownCell: React.FC<{ record: ChannelAccountSettings, index: number }> = ({ index }) => {
  const [state] = useContext(QuickbooksSettingsScreenContext);

  if (!state) {
    return null;
  }

  const customers = state.userData.customers.map(simpleElementToOption);
  return (
    <>
      <Form.Item
        name={['chnlAccSettings', index, 'channelQboCustomerId']}
        rules={[{ required: true, message: 'You must select an option' }]}
      >
        <Select
          options={customers}
          onChange={(_: any, option: any) => {
            const chnlAccSettings = [...state.form.getFieldValue(['chnlAccSettings'])];
            chnlAccSettings[index].channelQboCustomerName = option.label;

            state.form.setFieldsValue({ chnlAccSettings });
          }}
        />
      </Form.Item>
      <Form.Item
        name={['chnlAccSettings', index, 'channelQboCustomerName']}
        noStyle
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};

const QuickbooksFeeAccountCell: React.FC<{ record: ChannelAccountSettings, index: number }> = ({ index, record }) => {
  const [state] = useContext(QuickbooksSettingsScreenContext);

  if (!state) {
    return null;
  }

  const showDropdown = record.channelName && record.channelName.toLowerCase().indexOf('ebay') >= 0;
  let text;
  if (!showDropdown) {
    text = record.channelQboFeeAcountId === 0 ? 'Default Digit Bridge Fee Account' : record.channelQboFeeAcountName;
  }

  // TBD: render dropdown if channel is eBay.
  return (
    <Form.Item name={['chnlAccSettings', index, 'channelQboFeeAccountId']}>
      {
        showDropdown ?
          (
            <Select />
          ) :
          (
            <div>
              {text}
            </div>
          )
      }
    </Form.Item>
  );
};

/**
 * Renders the channel name and extra hidden form fields required.
 *
 * @constructor
 */
const ChannelNameCell: React.FC<{ record: ChannelAccountSettings, index: number }> = ({ index, record }) => (
  <>
    {record.channelName}
    <Form.Item
      name={['chnlAccSettings', index, 'masterAccountNum']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['chnlAccSettings', index, 'profileNum']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['chnlAccSettings', index, 'channelAccountName']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['chnlAccSettings', index, 'channelAccountNum']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['chnlAccSettings', index, 'channelName']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['chnlAccSettings', index, 'channelNum']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
  </>
);

const columns = [
  {
    key: '1',
    title: 'Channel Name',
    dataIndex: 'channelName',
    render: function CellWrapper(_: string, record: ChannelAccountSettings, index: number) {
      return <ChannelNameCell record={record} index={index} />;
    },
  },
  {
    key: '2',
    title: 'Channel Account Name',
    dataIndex: 'channelAccountName',
  },
  {
    key: '3',
    title: 'Quickbooks Customer',
    dataIndex: 'channelQboCustomerId',
    render: function CellWrapper(_: string, record: ChannelAccountSettings, index: number) {
      return (
        <CustomerDropdownCell
          key={index}
          record={record}
          index={index}
        />
      );
    },
  },
  {
    key: '4',
    title: 'Quickbooks Fee Account',
    dataIndex: 'channelQboFeeAccountId',
    render: function CellWrapper(_: string, record: ChannelAccountSettings, index: number) {
      return (
        <QuickbooksFeeAccountCell
          key={index}
          record={record}
          index={index}
        />
      );
    },
  },
];

const BasicInfo: React.FC = () => {
  const selectedProfile = useSelector((state: any) => state.profiles.selectedIndex);
  const profile = useSelector((state: any) => state.profiles.profiles[selectedProfile]);
  const notifications = bindActionCreators(NotificationActions, useDispatch());
  const [state, dispatch] = useContext(QuickbooksSettingsScreenContext);
  const [defaultItemSelected, setDefaultItemSelected] = useState(!!state?.form?.getFieldValue(['integrationSetting', 'qboDefaultItemId']));
  const [defaultItemDisabled, setDefaultItemDisabled] = useState(state?.form?.getFieldValue(['integrationSetting', 'qboItemCreateRule']) !== ItemCreationRule.USE_DEFAULT_ITEM_FOR_ALL_SALES);
  const [shippingCouponsDisabled, setShippingCouponsDisabled] = useState(
    shippingCouponsExportOptionFlags.indexOf(state?.form?.getFieldValue(['integrationSetting', 'exportOrderAs'])) < 0,
  );
  const [defaultTaxItemSelected, setDefaultTaxItemSelected] = useState(state?.form?.getFieldValue(['integrationSetting', 'qboSalesTaxItemId']) === 0);
  const [salesTaxItemDisabled, setSalesTaxItemDisabled] = useState((state?.form?.getFieldValue(['integrationSetting', 'salesTaxExportRule']) || SalesTaxExport.DO_NOT_EXPORT_TAX) === SalesTaxExport.DO_NOT_EXPORT_TAX);

  if (!state || !dispatch) {
    return null; // Mostly to make the compiler/linter happy
  }

  const gutters = { lg: 20 };
  const inventoryItems: Array<{ label: string, value: any }> = state.userData.inventoryItems.map(simpleElementToOption);
  const nonInventoryItems: Array<{ label: string, value: any }> = state.userData.nonInventoryItems.map(simpleElementToOption);
  const liabilityAccounts: Array<{ label: string, value: any }> = state.userData.otherCurrentLiabilitiesAccounts.map(simpleElementToOption);
  const settingsStatus = state.settings && state.settings.integrationSetting ? state.settings.integrationSetting.qboSettingStatus : -1;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFieldsChange = (changedFields: FieldData[], _: FieldData[]) => {
    const itemCreateRule = findField(changedFields, 'integrationSetting.qboItemCreateRule');
    if (itemCreateRule) {
      setDefaultItemDisabled(itemCreateRule.value !== ItemCreationRule.USE_DEFAULT_ITEM_FOR_ALL_SALES);
    }

    const exportOrder = findField(changedFields, 'integrationSetting.exportOrderAs');
    if (exportOrder) {
      setShippingCouponsDisabled(shippingCouponsExportOptionFlags.indexOf(exportOrder.value) < 0);
    }

    const taxExportRule = findField(changedFields, 'integrationSetting.salesTaxExportRule');
    if (taxExportRule) {
      setSalesTaxItemDisabled(taxExportRule.value === SalesTaxExport.DO_NOT_EXPORT_TAX);
    }

    const salesTaxItem = findField(changedFields, 'integrationSetting.qboSalesTaxItemId');
    if (salesTaxItem) {
      setDefaultTaxItemSelected(salesTaxItem.value === 0);
    }
  };

  return (
    <Form
      initialValues={formInitialValues(profile.MasterAccountNum, profile.ProfileNum)}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 16 }}
      form={state.form}
      onFinish={async (values) => {
        try {
          await onFormSubmit(state, dispatch, values);
          notifications.setNotification('success', 'Settings saved successfully');
        } catch (_) {
          notifications.setNotification('error', 'There was an error saving your settings');
        }
      }}
      onFieldsChange={onFieldsChange}
    >
      {/* Hidden fields */}
      <Form.Item
        name={['integrationSetting', 'masterAccountNum']}
        noStyle
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={['integrationSetting', 'profileNum']}
        noStyle
      >
        <Input type="hidden" />
      </Form.Item>
      {/* General form */}
      <Row gutter={gutters}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Company Name"
          >
            <Input
              readOnly
              disabled
              defaultValue={state?.userData?.userCompanyInfo?.companyName}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <DisconnectButton />
        </Col>
      </Row>
      <PageSection>
        <Row gutter={gutters}>
          <Col xs={24} lg={12}>
            <PageSectionTitle>
              Export Orders From Digit Bridge to Quickbooks
            </PageSectionTitle>
            <Form.Item
              label="Export as"
              name={['integrationSetting', 'exportOrderAs']}
              rules={[{ required: true, message: 'Select an option.' }]}
            >
              <Select>
                {enumToObjects({ choices: ExportOptions }).map(o => (
                  <Select.Option value={o.value} key={o.key}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Export orders starting from date"
              name="exportOrderDateRange"
              rules={[{ required: true, message: 'Enter a date' }]}
            >
              <DatePicker.RangePicker disabled={state.settings?.integrationSetting?.qboSettingStatus === 1} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <PageSectionTitle>
              Customers
            </PageSectionTitle>
            <Form.Item
              label="Create Customer Record"
              name={['integrationSetting', 'qboCustomerCreateRule']}
              rules={[{ required: true, message: 'Select an option.' }]}
            >
              <Radio.Group>
                {enumToObjects({ choices: CustomerRecordCreation, mode: LetterCasingMode.CAPITALIZE }).map(choice => (
                  <Radio
                    key={choice.key}
                    value={choice.value}
                    disabled={choice.value === CustomerRecordCreation.ONE_PER_ORDER}
                  >
                    {choice.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </PageSection>
      <Spacer />
      <PageSection>
        <PageSectionTitle>
          Inventory
        </PageSectionTitle>
        <Row gutter={gutters}>
          <Col xs={24} xl={12}>
            <Form.Item
              name={['integrationSetting', 'qboItemCreateRule']}
              rules={[{ required: defaultItemSelected, message: 'Select an option.' }]}
            >
              <Radio.Group>
                {enumToObjects({ choices: ItemCreationRule, mode: LetterCasingMode.CAPITALIZE }).map(choice => (
                  <Radio key={choice.key} value={choice.value} style={{ display: 'block' }}>
                    {choice.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Default Inventory Item"
              tooltip="Hello there"
              name={['integrationSetting', 'qboDefaultItemId']}
            >
              <Select
                allowClear
                options={inventoryItems}
                onChange={(v) => setDefaultItemSelected(!!v)}
                disabled={defaultItemDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </PageSection>
      <Spacer />
      <PageSection>
        <PageSectionTitle>
          Shipping, Tax & Discount Items
        </PageSectionTitle>
        <Row gutter={gutters}>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Summary shipping and handling item"
              name={['integrationSetting', 'qboShippingItemId']}
              rules={[{ required: !shippingCouponsDisabled, message: 'Select an option.' }]}
            >
              <Select options={nonInventoryItems} disabled={shippingCouponsDisabled} />
            </Form.Item>
            <Form.Item
              label="Summary coupons and discount"
              name={['integrationSetting', 'qboDiscountItemId']}
              rules={[{ required: !shippingCouponsDisabled, message: 'Select an option.' }]}
            >
              <Select options={nonInventoryItems} disabled={shippingCouponsDisabled} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Tax export rule"
              name={['integrationSetting', 'salesTaxExportRule']}
            >
              <Radio.Group>
                {enumToObjects({ choices: SalesTaxExport }).map(choice => (
                  <Radio key={choice.key} value={choice.value} style={{ display: 'block' }}>
                    {choice.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            {
              !salesTaxItemDisabled && (
                <Form.Item
                  label="Sales tax item"
                  name={['integrationSetting', 'qboSalesTaxItemId']}
                >
                  <Select
                    options={nonInventoryItems}
                    disabled={salesTaxItemDisabled}
                    allowClear
                  />
                </Form.Item>
              )
            }
            {
              !salesTaxItemDisabled && defaultTaxItemSelected && (
                <Form.Item
                  label="Sales tax liability account"
                  name={['integrationSetting', 'qboSalesTaxAccId']}
                >
                  <Select
                    options={liabilityAccounts}
                  />
                </Form.Item>
              )
            }
          </Col>
        </Row>
      </PageSection>
      <Spacer />
      <PageSection>
        <TableComponent
          rowKey="channelAccountNum"
          dataSource={state.form?.getFieldValue(['chnlAccSettings'])}
          columns={columns}
          loadingStatus={false}
        />
      </PageSection>
      <Spacer />
      <PageSection>
        <PageSectionTitle>
          Import Orders From Quickbooks Online To Digit Bridge
        </PageSectionTitle>
        <Row gutter={gutters}>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Invoices"
              name={['integrationSetting', 'qboInvoiceImportRule']}
            >
              <Select>
                {enumToObjects({ choices: InvoiceImport }).map(choice => (
                  <Select.Option key={choice.key} value={choice.value}>
                    {choice.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Sales Receipt"
              name={['integrationSetting', 'qboSalesOrderImportRule']}
            >
              <Select>
                {enumToObjects({ choices: SalesReceipt }).map(choice => (
                  <Select.Option key={choice.key} value={choice.value}>
                    {choice.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Import orders modified after"
              name="qboImportOrderAfterUpdateDate"
              rules={[{ required: true, message: 'Please enter a value.' }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={gutters} justify="end">
          <Col>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                {settingsStatus === 1 ? 'Update Quickbooks Online Setup' : 'Configure Quickbooks Online Setup'}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="default"
              onClick={async () => {
                dispatch({ type: Actions.SET_LOADING, params: true });
                try {
                  const userData = await Quickbooks.getInitialData();
                  dispatch({ type: Actions.SET_USER_DATA, params: userData });
                } catch (_) {
                  notifications.setNotification('error', 'There was an error refreshing the data');
                } finally {
                  dispatch({ type: Actions.SET_LOADING, params: false });
                }
              }}
            >
              Refresh Quickbooks Online Initial Data
            </Button>
          </Col>
        </Row>
      </PageSection>
    </Form>
  );
};

export default BasicInfo;
