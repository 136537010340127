import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Moment } from 'moment';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { useListChannelsQuery } from '../../redux/api/channels';
import { ListOrdersParams } from '../../redux/api/orders';
import { OrderStatus } from '../../types/enums';
import { enumToObjects } from '../../util/enums';
import { convertToUtcTime } from '../../util/utcTime'


interface FilterParams extends ListOrdersParams {
  orderDate?: Moment[];
}

const ColFilters = styled(Col)`
padding-right: 10px;
`;

const ShippingBillingWrapper = styled.div`
  border: 1px #ededed solid;
  padding: 10px;
`;

const ApplyButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Filters: React.FC<{ onFilter: (params: ListOrdersParams) => void }> = ({ onFilter }) => {
  const { data = [], isFetching } = useListChannelsQuery();
  const [form] = Form.useForm();
  const onFiltersChanged = useCallback((filterParams: FilterParams) => {
    const filters = { ...filterParams };

    if (filterParams.orderDate) {
      filters.orderDateFrom = convertToUtcTime(filterParams.orderDate?.[0]?.startOf('day').format());
      filters.orderDateTo = convertToUtcTime(filterParams.orderDate?.[1]?.endOf('day').format());
      delete filters.orderDate;
    }

    onFilter(filters);
  }, [onFilter]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };
  return (
    <Form
      {...formItemLayout}
      // labelCol={{ xs: 24, md: 8, lg: 8 }}
      form={form}
      // wrapperCol={{ xs: 24, md: 16, lg: 16 }}
      onFinish={onFiltersChanged}
    >
      <Row>
        <Col span={22}>
          <Row>
            <ColFilters span={8}>
              <Form.Item name="channelOrderID" label="Channel Order ID">
                <Input allowClear />
              </Form.Item>
            </ColFilters>
            <ColFilters span={8}>
              <Form.Item name="centralOrderNum" label="Channel Order #">
                <Input allowClear onChange={e => {
                  form.setFieldsValue({
                    centralOrderNum: e.target.value.replace(/\D/g, '')
                  });
                }} />
              </Form.Item>
            </ColFilters>
            <ColFilters span={8}>
              <Form.Item name="trackingNumber" label="Tracking number(s)">
                <Select mode="tags" allowClear open={false} />
              </Form.Item>
            </ColFilters>
          </Row>
          <Row>
            <ColFilters span={6}>
              <Form.Item name="sku" label="SKU(s)">
                <Select mode="tags" allowClear open={false} />
              </Form.Item>
            </ColFilters>
            <ColFilters span={6}>
              <Form.Item name="orderStatus" label="Status">
                <Select mode="tags" allowClear>
                  {enumToObjects({ choices: OrderStatus }).map(choice => (
                    <Select.Option key={choice.key} value={choice.value.toString()}>
                      {choice.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ColFilters>
            <ColFilters span={6}>
              <Form.Item name="channelNum" label="Channel">
                <Select loading={isFetching} allowClear>
                  {data.map((c: Entities.ChannelProfile) => (
                    <Select.Option key={c.channelNum} value={c.channelNum}>
                      {c.channelName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ColFilters>
            <ColFilters span={6}>
              <Form.Item name="orderDate" label="Order date">
                <DatePicker.RangePicker />
              </Form.Item>
            </ColFilters>
          </Row>
          <Row>
            <ColFilters span={12}>
              <ShippingBillingWrapper>
                <h3>Shipping</h3>
                <Form.Item name="shippingFirstName" label="First Name">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="shippingLastName" label="Last Name">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="shippingZipCode" label="Zip Code">
                  <Input allowClear />
                </Form.Item>
              </ShippingBillingWrapper>
            </ColFilters>
            <ColFilters span={12}>
              <ShippingBillingWrapper>
                <h3>Billing</h3>
                <Form.Item name="billingFirstName" label="First Name">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="billingLastName" label="Last Name">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="billingZipCode" label="Zip Code">
                  <Input allowClear />
                </Form.Item>
              </ShippingBillingWrapper>
            </ColFilters>
          </Row>
        </Col>
        <Col span={2}>
          <ApplyButtonContainer>
            <Button htmlType="submit" type="primary">
              <SearchOutlined />
              Search
            </Button>
          </ApplyButtonContainer>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
