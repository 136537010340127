import React, { useEffect, useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import { Button, Col, Form, Input, Menu, Row, Select, Tag, Modal, Space, Typography } from 'antd';
import SiteContent from '../../components/SiteContent';
import notificationActions from '../../redux/actions/notifications';
import SearchIcon from '../../assets/icons/search';
import LabelIcon from '../../assets/icons/label';
import MapIcon from '../../assets/icons/map';
import VerifiedIcon from '../../assets/icons/verified';
import ThumbsUpIcon from '../../assets/icons/thumbsup';
import BlockIcon from '../../assets/icons/block';
import TableComponent from '../../components/TableComponent';
import CheckMark from '../../assets/icons/checkmark';
import LocationIcon from '../../assets/icons/location';
import {
  fetchHelp,
  fetchChannelMappingAttributes,
  //fetchMappingAttributes,
  patchProductMapping,
  productAutoMapping,
} from '../../services/products';
import { templateMapping } from '../../services/template';
import { sortDataTable } from '../../util';
import EditMappingModal from '../../components/ProductMapping/EditMappingModal';
import ConfirmationModal from '../../components/ProductMapping/ConfirmationModal';
import TextField from '../../assets/icons/textfield';
// import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import { MapperType } from '../../constants/enums/mapping';
import { Permissions } from '../../constants/enums/permissions';
// import MappingTemplateSelect from '../../components/ChannelIntegration/MappingTemplateSelect';
const { Option } = Select;
const { Title } = Typography;

const { SubMenu } = Menu;

const LabelFilter = styled.p`
  font-size: 14px;
  margin: 0;
`;

const ElementNameTitle = styled.p`
  color: #297bff;
  cursor: pointer;
  margin: 0;
`;

interface IButton {
  hasPermission: boolean;
}

// const InputTitle = styled.p`
//   margin: 0;
//   color: #546279;
// `;

const SaveButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: auto;
  display: ${(props: IButton) => (props.hasPermission ? 'inherit' : 'none')};
`;

const DiscardButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: 5px;
  display: ${(props: IButton) => (props.hasPermission ? 'inherit' : 'none')};
`;

interface IConditionalMappersElement {
  Mapper: {
    MapperType: number;
    AttributeMappers?: any;
  };
  ConditionType: number;
}

interface IElement {
  ElementName: string;
  ElementDisplayName?: string;
  MappedStatus: number;
  Requirement?: number;
  HelpNum?: number;
  IsArray?: number;
  Mapper: {
    MapperType: number;
    AttributeMappers: any;
    MapperLookup: number;
  };
  ConditionalMappers?: IConditionalMappersElement[] | null;
}

interface ISelectedMapping {
  SectionName: string;
  SectionDisplayName: string;
  SectionFlag: number;
  Elements: IElement[];
}

interface ISections {
  ProductMappingNum: number;
  Sections: ISelectedMapping[];
}

const sectionsInitialState = {
  ProductMappingNum: 0,
  Sections: [
    {
      SectionName: '',
      SectionDisplayName: '',
      SectionFlag: 1,
      Elements: [],
    },
  ],
};

const initialFormValues = {
  ElementDisplayName: '',
  HelpText: '',
  MapperType: 1,
  MappedValue: '',
  Attributes: [] as any,
  ConditionAttributes: [] as any,
  ConditionType: 2,
  ConditionalMapperType: 1,
  ConditionalMappedValue: '',
  ElementName: '',
  MapperLookup: 0,
  Suffix: '',
};

let backUpData: any = {};
const attributeHash = new Map();

const MappingModal = (props: any) => {
  const { onCancel } = props;
  const [data, setData] = useState<IElement[]>([]);
  const [dataToSave, setDataToSave] = useState<Array<any>>([]);
  const [attributes, setAttributes] = useState({});
  const [showEditModal, setShowEditModal] = useState<Boolean>(false);
  const [productMappingNum] = useState<number>(props.productMappingNum || 0);
  const [productMappingName] = useState<string>(props.productMappingName || '');
  const [showConfirmationModal, setShowConfirmationModal] = useState<Boolean>(false);
  const [showConfirmationDiscardMappingData, setShowConfirmationDiscardMappingData] = useState<Boolean>(false);
  const [showConfirmationDiscardMappingDataCondition, setShowConfirmationDiscardMappingDataCondition] = useState<Boolean>(false);
  const [showConfirmationDiscardModal, setShowConfirmationDiscardModal] = useState<Boolean>(false);
  const [sections, setSections] = useState<Array<any>>([]);
  const [help, setHelp] = useState<Array<any>>([]);
  const [isMultiple, setIsMultiple] = useState<number>(0);
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [fetchedData, setFetchedData] = useState<ISections>({ ...sectionsInitialState });
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredInfo, setFilteredInfo] = useState({
    Requirement: 0,
    MappedStatus: 0,
  });
  const profilesEntity = useSelector((state: any) => state.profiles);
  const dispatch = useDispatch();
  const actions = bindActionCreators(notificationActions, dispatch);
  // const { channelNum, channelAccountNum }: { channelNum: string; channelAccountNum: string } = props;
  const [form] = Form.useForm();
  const handleModalClose = () => setShowEditModal(false);
  const handleDiscard = () => setShowConfirmationDiscardModal(true);
  const handleConfirmationClose = () => setShowConfirmationModal(false);
  const handleClearValues = () => setFormValues({ ...initialFormValues });
  const handleSidebarSelect = (selected: string) => {
    setSelectedSection(selected);
    setData(fetchedData.Sections.filter((s: any) => s.SectionName === selected)[0].Elements);
  };
  // const handleMappingTemplateSelect = (value: number) => setProductMappingNum(value);
  const discardChanges = () => {
    setDataToSave([]);
    setShowConfirmationDiscardModal(false);
    const dataResponse = JSON.parse(JSON.stringify(backUpData));
    setFetchedData(dataResponse);
    setData(dataResponse.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements);
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilteredInfo(filters);
    const sortedData = sortDataTable(data, sorter);
    setData(sortedData);
  };

  const handleSearchCategoriesInput = (e: any) => {
    if (e.target.value !== '') {
      setSections(fetchedData.Sections.filter((s: any) => s.SectionDisplayName.toLowerCase().includes(e.target.value.toLowerCase().trim())));
    } else {
      setSections(fetchedData.Sections);
    }
  };
  const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
    if (permissions && permissions.size === 0) return false;
    return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
  };

  const handleChannelFieldSearchText = (e: any) => {
    if (e.target.value !== '') {
      setData(
        fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements.filter((d: any) => {
          if (d.ElementDisplayName === '') return false;

          return d.ElementDisplayName.toLowerCase().includes(e.target.value.toLowerCase().trim());
        })
      );
    } else {
      setData(fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements);
    }
  };

  const handleAutomapping = async () => {
    try {
      setLoading(true);
      await productAutoMapping(fetchedData.ProductMappingNum);
      actions.setNotification('success', 'Automap succeded!');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      actions.setNotification('error', 'Error occurred');
      console.log(e);
    }
  };

  const handleDiscardMappingData = () => {
    setFormValues({
      ...formValues,
      MapperType: 1,
      ConditionType: 0,
      Attributes: [],
      ConditionalMappedValue: '',
      ConditionAttributes: [],
      MapperLookup: 0,
      Suffix: '',
    });
    form.setFieldsValue({
      ...formValues,
      MapperType: 1,
      ConditionType: 0,
      ConditionalMappedValue: '',
      Attributes: [],
      ConditionAttributes: [],
      MapperLookup: false,
      Suffix: '',
    });
  };

  const handleDiscardMappingDataCondition = () => {
    setFormValues({
      ...formValues,
      ConditionalMapperType: 2,
      MappedValue: '',
      ConditionAttributes: [],
      Suffix: '',
    });
    form.setFieldsValue({
      ...formValues,
      MappedValue: '',
      ConditionalMapperType: 2,
      ConditionAttributes: [],
      Suffix: '',
    });
  };

  const handleModalChange = (e: any, type: string) => {
    switch (type) {
      case 'ConditionType':
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.checked ? 1 : 0,
        });
        break;
      case 'ConditionalMapperType':
        if (e.target.value === 2) {
          if (formValues.ConditionAttributes.length > 0) {
            setShowConfirmationDiscardMappingDataCondition(true);
          } else {
            setFormValues({
              ...formValues,
              [e.target.name]: e.target.value,
            });
          }
        } else {
          setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
          });
        }
        break;

      case 'MapperType':
        if (e.target.value === 1) {
          if (formValues.Attributes.length > 0 || formValues.ConditionAttributes.length > 0) {
            setShowConfirmationDiscardMappingData(true);
          } else {
            setFormValues({
              ...formValues,
              MapperType: e.target.value,
              ConditionType: 0,
            });
          }
        } else {
          setFormValues({
            ...formValues,
            MapperType: e.target.value,
            ConditionType: 0,
          });
        }

        break;
      case 'Attributes':
        setFormValues({
          ...formValues,
          Attributes: e,
        });
        break;

      case 'ConditionAttributes':
        setFormValues({
          ...formValues,
          ConditionAttributes: e,
        });
        break;

      case 'text':
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
        });
        break;
      default:
        setFormValues({
          ...formValues,
          [type]: e,
        });
        break;
    }
  };

  const handleModifiedData = (isCleaning = false) => {
    const existentData = [...dataToSave];
    const indexValue = existentData.findIndex((e: any) => e.ElementName === formValues.ElementName);
    if (indexValue !== -1) existentData.splice(indexValue, 1);
    let payloadElement: IElement = {
      ElementName: formValues.ElementName,
      MappedStatus: 0,
      Mapper: {
        MapperType: 1,
        AttributeMappers: [],
        MapperLookup: 0,
      },
      ConditionalMappers: [],
    };

    if (!isCleaning) {
      form.resetFields();
      let MapperTypeV;
      if (formValues.MapperType === 1) {
        MapperTypeV = 1;
      } else if (Array.isArray(formValues.Attributes)) {
        MapperTypeV = 4;
      } else {
        MapperTypeV = 3;
      }
      const NormalAttributeMappers =
        MapperTypeV === 4
          ? formValues.Attributes?.map((mv: any) => ({
              MappedValue: `${mv}^${formValues.Suffix || ''}`,
              MapperDisplayName: attributeHash.get(mv),
            }))
          : [
              {
                MappedValue: MapperTypeV === 3 ? `${formValues.Attributes}^${formValues.Suffix || ''}` : `${formValues.MappedValue}^${formValues.Suffix || ''}`,
                MapperDisplayName: MapperTypeV === 3 ? attributeHash.get(formValues.Attributes) : formValues.MappedValue,
              },
            ];
      let ConditionalMappers = null;
      if (formValues.ConditionType === 1) {
        let ConditionalMapperType;
        if (formValues.ConditionalMapperType === 2) {
          ConditionalMapperType = 1;
        } else if (Array.isArray(formValues.ConditionAttributes)) {
          ConditionalMapperType = 4;
        } else {
          ConditionalMapperType = 3;
        }
        const ConditionalAttributeMappers =
          ConditionalMapperType === 4
            ? formValues.ConditionAttributes?.map((cmv: any) => ({
                MappedValue: cmv,
                MapperDisplayName: attributeHash.get(cmv),
              }))
            : [
                {
                  MappedValue: ConditionalMapperType === 3 ? formValues.ConditionAttributes : formValues.ConditionalMappedValue,
                  MapperDisplayName: ConditionalMapperType === 3 ? attributeHash.get(formValues.ConditionAttributes) : formValues.ConditionalMappedValue,
                },
              ];
        ConditionalMappers = [
          {
            ConditionType: formValues.ConditionType,
            Mapper: {
              MapperType: ConditionalMapperType,
              AttributeMappers: ConditionalAttributeMappers,
            },
          },
        ];
      }
      payloadElement = {
        ElementName: formValues.ElementName,
        MappedStatus: 1,
        Mapper: {
          MapperType: MapperTypeV,
          AttributeMappers: NormalAttributeMappers,
          MapperLookup: formValues.MapperLookup,
        },
        ConditionalMappers,
      };
    }
    existentData.push(payloadElement);
    setDataToSave(existentData);
    const editedElementDataIndex = data.findIndex((d: any) => d.ElementName === formValues.ElementName);
    const editedContent = {
      ...payloadElement,
      ElementDisplayName: data[editedElementDataIndex].ElementDisplayName,
      Requirement: data[editedElementDataIndex].Requirement,
      HelpNum: data[editedElementDataIndex].HelpNum,
      IsArray: data[editedElementDataIndex].IsArray,
    };
    const selectedSectionElements = fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements;
    const elementIndex = selectedSectionElements.findIndex((d: any) => d.ElementName === formValues.ElementName);
    const sectionIndex = fetchedData.Sections.findIndex((s: any) => s.SectionName === selectedSection);
    const fetchedDataTemp = { ...fetchedData };
    fetchedDataTemp.Sections[sectionIndex].Elements.splice(elementIndex, 1);
    fetchedDataTemp.Sections[sectionIndex].Elements.unshift(editedContent);
    setFetchedData(fetchedDataTemp);
    setData([...fetchedDataTemp.Sections[sectionIndex].Elements]);
    setShowEditModal(false);
  };

  const handleShowEditModal = (values: any) => {
    const ConditionalMappers =
      values.ConditionalMappers?.length === 0 || values.ConditionalMappers === null
        ? {
            ConditionType: 0,
            Mapper: {
              MapperType: 1,
              AttributeMappers: [],
            },
          }
        : values.ConditionalMappers[0];
    const helpText = help.find((h: any) => h.AssetInfoNum === values.HelpNum)?.AssetInfoContent;

    const suffix = values.Mapper.AttributeMappers[0]?.MappedValue.split('^')[1] || '';
    const attributeMappers = values.Mapper.AttributeMappers.map((am: any) => am.MappedValue);
    const attributesValue = attributeMappers.length > 0 ? attributeMappers[0].split('^')[0] : attributeMappers;
    const content = {
      ElementDisplayName: values.ElementDisplayName,
      HelpText: helpText === -1 ? '' : helpText,
      MapperType: values.Mapper.MapperType === MapperType.MULTIPLE ? MapperType.SINGLE : values.Mapper.MapperType,
      MappedValue: values.Mapper.MapperType === MapperType.TEXT ? values.Mapper.AttributeMappers[0]?.MapperDisplayName : '',
      Attributes: values.Mapper.MapperType === MapperType.MULTIPLE || values.Mapper.MapperType === MapperType.SINGLE ? attributesValue : [],
      ConditionType: ConditionalMappers?.ConditionType,
      ConditionalMapperType: ConditionalMappers?.Mapper.MapperType === MapperType.TEXT ? 2 : 1,
      ConditionalMappedValue: ConditionalMappers?.Mapper.MapperType === MapperType.TEXT ? ConditionalMappers.Mapper.AttributeMappers[0]?.MapperDisplayName : '',
      ConditionAttributes:
        ConditionalMappers?.Mapper.MapperType === MapperType.MULTIPLE || ConditionalMappers?.Mapper.MapperType === MapperType.SINGLE
          ? ConditionalMappers.Mapper.AttributeMappers.map((am: any) => am.MappedValue)
          : [],
      ElementName: values.ElementName,
      MapperLookup: values.Mapper.MapperLookup,
      Suffix: suffix,
    };
    setIsMultiple(values.IsArray);
    setFormValues(content);
    form.setFieldsValue(content);
    setShowEditModal(true);
  };

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'Requirement',
      align: 'center',
      key: 'Requirement',
      width: 50,
      filters: [
        {
          text: (
            <i>
              <VerifiedIcon color="#30c8ff" />
              Mandatory
            </i>
          ),
          value: 1,
        },
        {
          text: (
            <i>
              <LabelIcon color="#b56416" />
              Require If Relevant
            </i>
          ),
          value: 2,
        },
        {
          text: (
            <i>
              <ThumbsUpIcon color="#bababa" />
              Recommended
            </i>
          ),
          value: 3,
        },
      ],
      filteredValue: filteredInfo.Requirement || null,
      onFilter: (value: any, record: any) => record.Requirement.toString().includes(value),
      sorter: (a: any, b: any) => a.Requirement.length - b.Requirement.length,
      ellipsis: true,
      render: function render(value: any) {
        switch (value) {
          case 1:
            return <VerifiedIcon color="#30c8ff" />;
          case 2:
            return <LabelIcon color="#b56416" />;
          case 3:
            return <ThumbsUpIcon color="#bababa" />;
          default:
            return '';
        }
      },
    },
    {
      title: 'Channel Field',
      width: 100,
      dataIndex: 'ElementDisplayName',
      key: 'ElementDisplayName',
      sorter: (a: any, b: any) => a.ElementDisplayName - b.ElementDisplayName,
      render: function render(name: any, record: any) {
        return (
          <ElementNameTitle
            onClick={() => {
              handleShowEditModal(record);
            }}
          >
            {name}
          </ElementNameTitle>
        );
      },
    },
    {
      title: 'Mapping from your data',
      dataIndex: 'Mapper',
      width: 150,
      key: 'Mapper',
      render: function render(value: any, record: any) {
        let conditionalMappers;
        let mappers;
        let ifblank;
        if (record.MappedStatus) {
          mappers = record.Mapper.AttributeMappers.map((am: any) =>
            record.Mapper.MapperType === 1 ? (
              <i key={am.MapperDisplayName}>
                <TextField />
                {am.MapperDisplayName}
              </i>
            ) : (
              <i key={am.MapperDisplayName}>
                <LocationIcon />
                {am.MapperDisplayName}
              </i>
            )
          );
          if (record.ConditionalMappers) {
            ifblank = record.ConditionalMappers.length > 0 && <Tag color="cyan">If Blank</Tag>;
            conditionalMappers =
              record.ConditionalMappers.length > 0 &&
              record.ConditionalMappers[0].Mapper.AttributeMappers.map((cm: any) =>
                record.ConditionalMappers.ConditionType === 2 ? (
                  <div key={cm.MapperDisplayName}>
                    <i>
                      <TextField />
                      {cm.MapperDisplayName}
                    </i>
                  </div>
                ) : (
                  <div key={cm.MapperDisplayName}>
                    <i>
                      <LocationIcon />
                      {cm.MapperDisplayName}
                    </i>
                  </div>
                )
              );
          }
        }
        return (
          <>
            <p>{mappers}</p>
            <>
              <p>
                {ifblank}
                {conditionalMappers}
              </p>
            </>
          </>
        );
      },
    },
    {
      title: 'Status',
      width: 50,
      dataIndex: 'MappedStatus',
      key: 'MappedStatus',
      filters: [
        {
          text: (
            <i>
              <CheckMark color="#52c41a" />
              Mapped
            </i>
          ),
          value: 1,
        },
        {
          text: (
            <i>
              <BlockIcon />
              Unmapped
            </i>
          ),
          value: 0,
        },
      ],
      filteredValue: filteredInfo.MappedStatus || null,
      onFilter: (value: any, record: any) => record.MappedStatus.toString().includes(value),
      sorter: (a: any, b: any) => a.MappedStatus.length - b.MappedStatus.length,
      ellipsis: true,
      render: function render(value: any) {
        return value ? (
          <p style={{ color: '#52c41a', margin: '0px' }}>
            <CheckMark color="#52c41a" />
            Mapped
          </p>
        ) : (
          <p style={{ margin: '0px' }}>
            <BlockIcon />
            Unmapped
          </p>
        );
      },
    },
  ];

  const fetchMapping = async (defaultTemplateNum: number) => {
    try {
      if (defaultTemplateNum === 0) {
        return;
      }
      setLoading(true);
      const response = await templateMapping(defaultTemplateNum);
      setFetchedData({ ...response });
      backUpData = JSON.parse(JSON.stringify(response));
      setSections(response.Sections);
      let selectedPosition = 0;
      if (selectedSection !== '') {
        selectedPosition = response.Sections.findIndex((s: any) => s.SectionName === selectedSection);
      }
      if (response.Sections[selectedPosition]) {
        setData(response.Sections[selectedPosition].Elements);
        setSelectedSection(response.Sections[selectedPosition].SectionName);
      } else {
        setData(response.Sections[0].Elements);
        setSelectedSection(response.Sections[0].SectionName);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      actions.setNotification('error', 'No mappings found for this account');
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const helpResponse = await fetchHelp();
      setHelp(helpResponse);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // actions.setNotification('error', 'error');
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setShowEditModal(false);
      await patchProductMapping(backUpData.ProductMappingNum, dataToSave, Number(props.channelAccountNum));
      actions.setNotification('success', 'Mapping Updated');
      setDataToSave([]);
      await fetchData();
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const fetchAtts = async () => {
    const { channelNum } = props;
    //const atts = await fetchMappingAttributes();
    const atts = await fetchChannelMappingAttributes(channelNum);
    atts.map((a: any) => attributeHash.set(a.AttributeNumID, a.AttributeName));
    const formattedAtts = atts.reduce((r: any, a: any) => {
      const rCopy = r;
      rCopy[a.GroupBy] = rCopy[a.GroupBy] || [];
      rCopy[a.GroupBy].push(a);
      return rCopy;
    }, Object.create(null));

    setAttributes(formattedAtts);
  };
  const useFetch = (productMappingNumParam: number) => {
    useEffect(() => {
      fetchAtts();
    }, []);
    useEffect(() => {
      fetchMapping(productMappingNumParam);
    }, [productMappingNumParam]);
  };
  useFetch(productMappingNum);

  const onModalCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onCancel?.(e);
    },
    [onCancel]
  );

  return (
    <Modal
      width={1300}
      visible
      // title="Mapping Template"
      onOk={undefined}
      footer={[
        <Button type="primary" disabled={loading} onClick={onModalCancel}>
          Close
        </Button>,
      ]}
      onCancel={onModalCancel}
      maskClosable={!loading}
      closable={!loading}
    >
      <Prompt when={Boolean(dataToSave.length)} message="You have unsaved changes, are you sure you want to leave?" />
      {showEditModal && <EditMappingModal
        visible={true}
        formValues={formValues}
        submit={handleModifiedData}
        handleCancel={handleModalClose}
        handleModalChange={handleModalChange}
        attributes={attributes}
        handleClearValues={handleClearValues}
        handleCleanMapping={() => setShowConfirmationModal(true)}
        form={form}
        isMultiple={isMultiple}
      />}
      {showConfirmationModal && <ConfirmationModal name={formValues.ElementDisplayName} handleCancel={handleConfirmationClose} handleConfirm={handleModifiedData} visible={true} confirmationType="cleaning" />}
      {showConfirmationDiscardModal && <ConfirmationModal name="" handleCancel={handleConfirmationClose} handleConfirm={discardChanges} visible={true} confirmationType="discard" />}
      {showConfirmationDiscardMappingData && <ConfirmationModal
        name=""
        handleCancel={() => setShowConfirmationDiscardMappingData(false)}
        handleConfirm={handleDiscardMappingData}
        visible={true}
        confirmationType="discard"
      />}
      {showConfirmationDiscardMappingDataCondition && <ConfirmationModal
        name=""
        handleCancel={() => setShowConfirmationDiscardMappingDataCondition(false)}
        handleConfirm={handleDiscardMappingDataCondition}
        visible={true}
        confirmationType="discard"
      />}
      <SiteContent>
        <Row justify="center" align="middle" style={{ position: 'relative' }}>
          <Space size="small" align="center" style={{ position: 'absolute', left: 0 }}>
            <Title style={{ marginTop: 10 }} level={2}>
              Product Mapping Template
            </Title>
          </Space>
          <Title style={{ color: '#006dff', marginTop: 10, display: 'flex', justifyContent: 'center' }} level={2}>
            {productMappingName}
          </Title>
          <Space size="small" align="center" style={{ position: 'absolute', right: 0 }}>
            <SaveButton onClick={handleSave} disabled={dataToSave.length <= 0} hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)} type="primary">
              Save changes
            </SaveButton>
            <DiscardButton onClick={handleDiscard} disabled={dataToSave.length <= 0} hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)} type="primary" danger>
              Discard changes
            </DiscardButton>
          </Space>
        </Row>

        {/* <Heading
          title="Product Mapping Template"
          actions={[
            <Text style={{}}>{productMappingName}</Text>,
            <>
              <SaveButton onClick={handleSave} disabled={dataToSave.length <= 0} hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)} type="primary">
                Save changes
              </SaveButton>
              <DiscardButton onClick={handleDiscard} disabled={dataToSave.length <= 0} hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)} type="primary" danger>
                Discard changes
              </DiscardButton>
            </>
          ]}
        /> */}
      </SiteContent>
      <Spacer />
      <SiteContent>
        <Row>
          <Col span={5}>
            <Row>
              <Col span={24}>
                <Input placeholder="Search Categories..." onChange={handleSearchCategoriesInput} prefix={<SearchIcon />} />
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col span={24} style={{ height: '500px', overflowY: 'scroll' }}>
                <Menu defaultSelectedKeys={['0']} defaultOpenKeys={['sub1', 'sub2']} mode="inline">
                  <SubMenu key="sub1" title="All Products">
                    {sections
                      .filter((s: any) => s.SectionFlag === 1)
                      .map((section: any) => (
                        <Menu.Item onClick={() => handleSidebarSelect(section.SectionName)} key={section.SectionName}>
                          {section.SectionDisplayName}
                        </Menu.Item>
                      ))}
                  </SubMenu>
                  <SubMenu key="sub2" title="Specific Product">
                    {sections
                      .filter((s: any) => s.SectionFlag === 2)
                      .map((section: any) => (
                        <Menu.Item onClick={() => handleSidebarSelect(section.SectionName)} key={section.SectionName}>
                          {section.SectionDisplayName}
                        </Menu.Item>
                      ))}
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </Col>
          <Col span={19} style={{ padding: '0px 30px' }}>
            <Form layout="vertical">
              <Row>
                <Col xs={5} style={{ paddingRight: '15px' }}>
                  <Form.Item name="channelField" label={<LabelFilter>Channel Field</LabelFilter>}>
                    <Select defaultValue={0} style={{ width: '100%' }}>
                      <Option key={0} value={0}>
                        Contains
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={6} style={{ paddingRight: '15px' }}>
                  <Form.Item name="status" label={<LabelFilter />}>
                    <Input onChange={handleChannelFieldSearchText} placeholder="Search text..." />
                  </Form.Item>
                </Col>
                <Col offset={5} xs={5}>
                  {/* <InputTitle>Mapping Templates</InputTitle>
                  <MappingTemplateSelect
                    channel={Number(channelNum)}
                    channelAccount={Number(channelAccountNum)}
                    placeholder="Mapping Templates"
                    applyTemplate={handleMappingTemplateSelect}
                    value={productMappingNum}
                  /> */}
                </Col>
                <Col xs={3}>
                  <Button style={{ marginTop: '21px' }} onClick={handleAutomapping}>
                    Automapping
                    <MapIcon />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col span={24}>
                <TableComponent
                  className="productMappingTable"
                  loadingStatus={loading}
                  dataSource={data}
                  columns={columns}
                  onChange={handleTableChange}
                  pagination={{
                    total: data.length,
                    pageSize: data.length,
                    hideOnSinglePage: true,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </SiteContent>
    </Modal>
  );
};

export default MappingModal;
