import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Header from '../../components/ChannelIntegration/header';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import IntegrationsActions from '../../redux/actions/integrations';
import { getChannelAccountProfile } from '../../services/channels';
import { getChannelAccountVersion } from '../../services/ChannelIntegration';
import { usePageSetup } from './ChannelIntegrationDetails';
import { FormsContext } from './details/context';
import ProductMapping from './mappings/products';
import ProductMapping02 from './mappings/products02';


const useLoadChannelAccountProfile = (channelAccountNum?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!channelAccountNum) {
      return;
    }

    const actions = bindActionCreators(IntegrationsActions, dispatch);

    getChannelAccountProfile(Number(channelAccountNum))
      .then((data) => {
        actions.setChannelAccount(data);
      });
  }, [channelAccountNum, dispatch]);
};

const ChannelIntegrationMapping: React.FC = () => {
  const VERSION2 = 'Template02';
  const { useState } = React;
  const { channelNum, platformNum, channelAccountNum } = useParams<{ channelNum: string, channelAccountNum?: string, platformNum?: string }>();
  const [forms] = usePageSetup(channelNum, platformNum, channelAccountNum);
  const [channelVersion, setChannelVersion] = useState('');
  const [channelVersionLoaded, setChannelVersionLoaded] = useState(false);
  const [inited, setInited] = useState(false);

  useLoadChannelAccountProfile(channelAccountNum);

  // eslint-disable-next-line
  const loadSettings = async () => {
    //console.log('-- -->', channelNum, channelAccountNum);
    try {
      const ver = await getChannelAccountVersion(channelNum as any, channelAccountNum as any);

      //console.log('>>', ver);
      setChannelVersion(typeof ver === 'string' ? ver.trim() : ver);
      setChannelVersionLoaded(true);
    } catch(e) {
      console.log(`Loaded version error: ${e}`);
    } finally {
    }
  };

  useEffect(() => {
    if (!inited) {
      loadSettings();
      setInited(true);
    }
  }, [
    channelAccountNum,
    channelNum,
    inited,
    loadSettings,
  ]);

  return (
    <FormsContext.Provider value={forms}>
      <ContentLayout>
        <Header />
        <Spacer />
        <SiteContent>
          {channelVersionLoaded && channelVersion === '' && <ProductMapping channelNum={channelNum} channelAccountNum={channelAccountNum} />}
          {channelVersionLoaded && channelVersion === VERSION2 && <ProductMapping02 channelNum={channelNum} channelAccountNum={channelAccountNum} />}
        </SiteContent>
      </ContentLayout>
    </FormsContext.Provider>
  );
};

export default ChannelIntegrationMapping;
