import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, message, Row, Spin } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchFields } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { getOPTDetails, getOPTList } from '../../services/opt';
import { ChannelAccountEventType, ProductOptimizationImportStatus } from '../../types/enums';
import { formatDate } from '../../util';
import { useExecutePromise, useFileDownload } from '../../util/hooks';
import { enumNameToLabel } from '../../util/strings';
import { useListGroupedChannelAccountsQuery } from '../../redux/api/channels';
import { pushToChannelAccount } from '../../services/channels';
import { downloadOPT } from '../../services/products';

const ScrollableMenu = styled(Menu)`
  max-height: 80vh;
  overflow-y: auto;
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: #0070d2;

  &:hover {
    text-decoration: underline;
  }
`;

// OPT Link for column
const OptLink: React.FC<{ OPTId: number, id: string }> = (
  {
    OPTId,
    id,
    children,
  },
) => (
  <Link id={id} to={`/product-optimization-center/${OPTId}`}>
    {children}
  </Link>
);

const FileNameLabel: React.FC<{ data: Entities.OPT, id: string }> = ({ data, id }) => {
  const label = data.FileName;
  const file = useCallback(() => downloadOPT(data.SystemFileName), [data]);
  const fileName = data.FileName;
  const [download, loading] = useFileDownload(file, fileName);

  return (
    <Spin spinning={loading}>
      <LinkButton id={id} onClick={download}>
        {label}
      </LinkButton>
    </Spin>
  );
};

const ChannelMenu: React.FC<{ onAccountSelected: (account: Entities.ProfileChannelAccount) => void }> = ({ onAccountSelected }) => {
  const { data = {}, isFetching } = useListGroupedChannelAccountsQuery();

  const onItemSelect = useCallback(({ key }: { key: string }) => {
    const [channelNum, channelAccountNum] = key.split(':');
    const account = data[channelNum].accounts.find((a: Entities.ProfileChannelAccount) => a.ChannelAccountNum.toString() === channelAccountNum);
    if (!account) {
      return;
    }
    onAccountSelected(account);
  }, [data, onAccountSelected]);

  return (
    <ScrollableMenu onSelect={onItemSelect}>
      {
        isFetching && (
          <Menu.Item>
            <Spin spinning />
          </Menu.Item>
        )
      }
      {
        Object.keys(data).map(channelNum => {
          const channel: { channelName: string, accounts: Entities.ProfileChannelAccount[] } = data[channelNum];

          return (
            <Menu.ItemGroup key={channelNum} title={channel.channelName}>
              {
                channel.accounts.map(a => (
                  <Menu.Item key={`${channelNum}:${a.ChannelAccountNum}`}>
                    {a.ChannelAccountName}
                  </Menu.Item>
                ))
              }
            </Menu.ItemGroup>
          );
        })
      }
    </ScrollableMenu>
  );
};

const OPTActions: React.FC<{ opt: Entities.OPT, id: string }> = ({ opt, id }) => {
  const onAccountSelected = useCallback(async (account: Entities.ProfileChannelAccount) => {
    message.info('Processing your request...', 10);

    let skus: string[] = [];
    try {
      const details = await getOPTDetails(opt.OPTId);
      if (details.OPTDetailList.length === 0) {
        message.error('There is no information available for the optimization selected', 10);
        return;
      }
      skus = details.OPTDetailList[0].ProductList.map(p => p.SKU);
    } catch (e) {
      message.error('Could not retrieve the optimization details, please try again later');
      return;
    }

    try {
      await pushToChannelAccount(ChannelAccountEventType.CATALOG_UPDATE, account.ChannelAccountNum, account.ChannelNum, skus);
      message.success('Your task has been queued successfully', 5);
    } catch (e) {
      message.error('There was an error scheduling your task to the queue', 10);
    }
  }, [opt]);

  return (
    <Dropdown
      disabled={opt.Status !== ProductOptimizationImportStatus.COMPLETE}
      overlay={<ChannelMenu onAccountSelected={onAccountSelected} />}
      placement="bottomCenter"
      arrow
    >
      <Button size="small" id={`${id}_push_button`}>
        <CloudUploadOutlined />
        Push
      </Button>
    </Dropdown>
  );
};


const columns = [
  {
    name: 'OPTLabel',
    header: 'Label',
    defaultFlex: 2,
    render(value: any) {
      const { OPTId, OPTLabel } = value.data;

      return (
        <OptLink id={`grid_label_row_${value.rowIndex}`} OPTId={OPTId}>
          {OPTLabel}
        </OptLink>
      );
    },
  },
  {
    name: 'CreateDate',
    header: 'Create Date',
    defaultFlex: 2,
    render({ value, rowIndex }: { value: string, rowIndex: number }) {
      return (
        <span id={`grid_create_date_row_${rowIndex}`}>
          {formatDate(value, 'dateTime')}
        </span>
      );
    },
  },
  {
    name: 'FileName',
    header: 'File Name',
    defaultFlex: 2,
    render({ data, rowIndex }: { data: Entities.OPT, rowIndex: number }) {
      return <FileNameLabel id={`grid_file_name_row_${rowIndex}`} data={data} />;
    },
  },
  {
    name: 'Channel',
    header: 'Channel',
    defaultFlex: 1,
    render(value: any) {
      return (
        <span id={`grid_channel_row_${value.rowIndex}`}>
          {value.data.Channel}
        </span>
      );
    },
  },
  {
    name: 'ProductCount',
    header: 'Product Count',
    defaultFlex: 1,
    render(value: any) {
      return (
        <span id={`grid_product_count_row_${value.rowIndex}`}>
          {value.data.ProductCount}
        </span>
      );
    },
  },
  {
    name: 'Status',
    header: 'Status',
    defaultFlex: 2,
    render({ value, rowIndex }: { value: number, rowIndex: number }) {
      return <span id={`grid_status_row_${rowIndex}`}>{enumNameToLabel(ProductOptimizationImportStatus[value])}</span>;
    },
  },
  {
    name: 'OPTId',
    header: 'Action',
    render({ data, rowIndex }: { data: Entities.OPT, rowIndex: number }) {
      return <OPTActions id={`grid_action_row_${rowIndex}`} opt={data} />;
    },
    visible: false,
  },
];

const searchFields: SearchFields<Entities.OPT> = [
  'OPTLabel',
  'Channel',
  {
    fieldName: 'Status',
    attributes: {
      extractor(doc) {
        return enumNameToLabel(ProductOptimizationImportStatus[doc.Status]);
      },
    },
  },
];

// const csvColumns: CSVColumns = [
//   {
//     key: 'OPTLabel',
//     header: 'Label',
//   },
//   {
//     key: 'CreateDate',
//     header: 'Create Date',
//     transform(value: string) {
//       return formatDate(value, 'dateTime');
//     },
//   },
//   {
//     key: 'StartDate',
//     header: 'Start Date',
//     transform(value: string) {
//       return formatDate(value, 'dateTime');
//     },
//   },
//   {
//     key: 'EndDate',
//     header: 'End Date',
//     transform(value: string) {
//       return formatDate(value, 'dateTime');
//     },
//   },
//   {
//     key: 'Channel',
//     header: 'Channel',
//   },
//   {
//     key: 'ProductCount',
//     header: 'Product Count',
//   },
//   {
//     key: 'Status',
//     header: 'Status',
//     transform(value: number) {
//       return enumNameToLabel(ProductOptimizationImportStatus[value]);
//     },
//   },
// ];

const ProductOptimizationCenter: React.FC = () => {
  const [{ OPTList }, loading] = useExecutePromise(getOPTList, { TotalCount: 0, OPTList: [] });
  const [filteredData, setFilteredData] = useState<Entities.OPT[]>([]);

  return (
    <ContentLayout>
      <Heading
        title="Product Optimization Center"
        actions={(
          <Link to="/product-optimization-center/add">
            <Button id="product_optimization_center_add_button" type="primary">
              <PlusOutlined />
              Add
            </Button>
          </Link>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row justify="space-between">
          <Col xs={24} lg={12}>
            <SearchBar
              data={OPTList}
              fields={searchFields}
              reference="OPTId"
              onResult={setFilteredData}
            />
          </Col>
          {/* <Col>
            <CSVLink
              id="product_optimization_center_export_button"
              filename="product-optimization-center.csv"
              data={filteredData}
              columns={csvColumns}
            />
          </Col> */}
        </Row>
        <Spacer height={14} />
        <DataGrid
          idProperty="id"
          rowHeight={35}
          columns={columns}
          loading={loading}
          dataSource={filteredData}
          pagination
        />
      </SiteContent>
    </ContentLayout>
  );
};

export default ProductOptimizationCenter;
