import React from 'react';
import { Row, Select, notification } from 'antd';
import LoadingIcon from '../../components/common/LoadingIcon';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, HTTP_STATUS_OK } from '../../constants/config';
import Products from '../../services/products';

/**
 * When version number is zero, it's the initial version.
 */
type Props = {
  className?: string;
  disabled?: boolean;
  onChange?: Function;
  value?: string;
  classInfo?: any;
  version: number;
};

let classCodeList: StringKAnyVPair[] = [];

const ClassSelector = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [classOptions, setClassOptions] = useState<StringKAnyVPair[]>([]);
  const [currentValue, setCurrentValue] = useState<string>();
  const [currentVersion, setCurrentVersion] = useState<number>(props.version);

  const classSelectOptions = () => {
    const { Option } = Select;
    const description = (e: StringKAnyVPair) => {
      return e.description ? `(${e.description})` : '';
    };

    return classOptions.map((e, i) => {
      return (
        <Option
          key={`${e.code}_${i}`}
          value={e.code}
        >
          {`${e.code}${description(e)}`}
        </Option>
      );
    });
  };

  // eslint-disable-next-line
  const loadStyleVariations = async () => {
    let styleVariation: StringKAnyVPair | null = null

    setIsLoading(true);

    try {
      if (classCodeList.length === 0) {
        const res = await Products.fetchStyleVariations2();

        if (res && typeof res === 'object') {
          if (res.status === HTTP_STATUS_OK) {
            styleVariation = res.data.styleVariation;

            if (!styleVariation) {
              const { messages } = res.data;

              if (Array.isArray(messages) && messages.length > 0) {
                const { Message } = messages[0];

                throw Message || 'no data returned';
              }
            }
          }
        }
      }

      if (styleVariation) {
        const { classCode } = styleVariation;

        if (Array.isArray(classCode)) {
          classCodeList = classCode;
        }
      }

      if (classCodeList.length > 0) {
        setClassOptions(classCodeList);

        if (props.value && props.version === 0) {
          onChangeClass(props.value);
        }
      }
    } catch(e) {
      notification.error({
        message: `Load style variation error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const onChangeClass = (val: any) => {
    if (val !== currentValue) {
      //console.log('class selected', val);
      setCurrentValue(val);

      if (typeof props.onChange === 'function') {
        const classObject = classOptions.find((item) => item.code === val) || {};
        props.onChange({
          value: val,
          ...classObject,
        });
      }
    }
  };

  React.useEffect(() => {
    if (inited) {
      if (!isLoading && props.version > currentVersion) {
        if (props.value !== currentValue) {
          onChangeClass(props.value);
        }

        setCurrentVersion(props.version);
      }
    } else {
      if (!props.disabled) {
        loadStyleVariations();
      }

      setInited(true);
    }
  }, [
    currentValue,
    currentVersion,
    inited,
    isLoading,
    loadStyleVariations,
    onChangeClass,
    props,
  ]);

  return (<>
    <Row className={props.className || ''}>
      <Select
        disabled={props.disabled}
        onChange={onChangeClass}
        style={{
          width: isLoading ? 'calc(100% - 32px)' : '100%'
        }}
        value={currentValue}
        allowClear
      >
        {classSelectOptions()}
      </Select>
      {isLoading && <LoadingIcon />}
    </Row>
  </>);
};

export default ClassSelector;
