import React from "react";

const ArrowRight = ({ width = 25, height = 25, stroke = "white", fill = "none", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.99">
        <path
          d="M16.7022 13.6432L13.1222 17.2332L14.5322 18.6432L20.5322 12.6432L14.5322 6.64319L13.1222 8.05319L16.7022 11.6432H4.53223V13.6432H16.7022Z"
          fill="#2D3F5D"
        />
      </g>
    </svg>

  );
};
export default React.memo(ArrowRight);
