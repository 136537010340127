import React from 'react';
import {
  Button,
  Row,
  notification,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import type { DataNode, /*TreeProps*/ } from 'antd/es/tree';
import { v4 as uuid } from 'uuid';

import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../constants/config';
import {
  editControlTowerNodes,
  fetchControlTowerGroupsAndFields,
} from '../../services/controlTower';

import TowerList from './TowerList';
import TowerProps from './TowerProps';
import TowerStruct from './TowerStruct';
import { Action, StoreContext, initialState, reducer } from './model';
import { SettingWrapper } from './styles';

type Props = {
};

export const MAX_TOWER_ITEM_WIDTH = 100;
export const MIN_TOWER_ITEM_WIDTH = 1;

const TowerSetting = (props: Props) => {
  const { useReducer, useState } = React;
  const [state, dispatch] = useReducer<(state: StringKAnyVPair, action: StringKAnyVPair) => StringKAnyVPair>(reducer, initialState as any);
  //const { state, dispatch } = useContext(StoreContext) as any;
  //const [expandedKeys] = useState<string[]>([]);
  //const [gData, setGData] = useState<DataNode[]>([]);
  const [inited, setInited] = useState(false);

  const getChangedNodes = () => {
    const ns: StringKAnyVPair[] = [];
    const cns = state.changedNodes;
    let count = 0;

    state.towers.forEach((gd: StringKAnyVPair) => {
      if (count < cns.length && cns.indexOf(gd.key) > -1) {
        const g = {
          children: [] as StringKAnyVPair[],
          columns: gd.columns,
          displaySequence: gd.displaySequence,
          rowNum: gd.rowNum,
        };

        count = count + 1;

        if (count < cns.length) {
          gd.children.forEach((ed: StringKAnyVPair) => {
            if (count < cns.length && cns.indexOf(ed.key) > -1) {
              g.children.push({
                columns: ed.columns,
                displaySequence: ed.displaySequence,
                editable: ed.editable,
                rowNum: ed.rowNum,
                rows: ed.rows,
              });
              count = count + 1;
            }
          });
        }

        ns.push(g);
      }
    });

    return ns;
  };

  // eslint-disable-next-line
  const loadTowerData = async () => {
    dispatch({type: Action.SetIsLoading, payload: true});

    try {
      const gs = await fetchControlTowerGroupsAndFields();
      console.log('gs', gs);
      if (Array.isArray(gs)) {
        const tds: DataNode[] = [];

        gs.forEach(gd => {
          const g = {
            children: [] as DataNode[],
            columns: gd.columns,
            displayName: gd.controlTowerGroupName,
            displaySequence: gd.displaySequence,
            key: uuid(),
            rowNum: gd.rowNum,
            title: gd.controlTowerGroupName,
          };

          if (Array.isArray(gd.controlTowerElements)) {
            gd.controlTowerElements.forEach((ed: StringKAnyVPair) => {
              g.children.push({
                columns: ed.columns,
                displayName: ed.displayName,
                displaySequence: ed.displaySequence,
                editable: ed.editable,
                key: uuid(),
                parentKey: g.key,
                rowNum: ed.rowNum,
                rows: ed.rows,
                title: ed.displayName,
              } as DataNode);
            });
          }

          tds.push(g);
        });
        dispatch({type: Action.SetTowers, payload: tds});
      }
      //loadTowerData2();
    } catch(e) {
      notification.error({
        message: `Loaded data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      dispatch({type: Action.SetIsLoading, payload: false});
    }
  };

  const saveSetting = async () => {
    dispatch({type: Action.SetIsLoading, payload: true});

    try {
      const ns = getChangedNodes();
      console.log(state.changedNodes, ns, '<--');
      if (ns.length > 0) {
        const res = await editControlTowerNodes(ns);

        console.log('res->', res, typeof res);
        if (res) {
          dispatch({type: Action.SetChangedNodes, payload: []});
          notification.info({
            message: 'Saved successfully',
            duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
          });
        }
      }
    } catch(e) {
    } finally {
      dispatch({type: Action.SetIsLoading, payload: false});
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadTowerData();
      setInited(true);
    }
  }, [inited, loadTowerData]);

  return (<>
    <StoreContext.Provider value={{state, dispatch} as any}>
      <SettingWrapper>
        <Row className="body-section">
          <div className="structure-panel">
            <TowerStruct
            />
          </div>
          <div className="content-panel">
            <TowerList
            />
          </div>
          <div className="props-panel">
            <TowerProps />
          </div>
        </Row>
        <Row align="bottom" className="footer-section" justify="end">
          <Button
            disabled={state.changedNodes.length === 0}
            onClick={saveSetting}
            size="large"
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Row>
      </SettingWrapper>
      {state.isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </StoreContext.Provider>
  </>);
};

export default TowerSetting;

export const getTowerItemWidth = (item: StringKAnyVPair) => {
  if (!item.columns || item.columns < 0) return MAX_TOWER_ITEM_WIDTH;

  return item.columns < MIN_TOWER_ITEM_WIDTH ? MIN_TOWER_ITEM_WIDTH : (item.columns > MAX_TOWER_ITEM_WIDTH ? MAX_TOWER_ITEM_WIDTH : item.columns);
};
