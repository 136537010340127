import { UploadFile } from 'antd/es/upload/interface';
import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import { Checkbox, Col, Form, Input, message, Row, Select, Space, Spin, Table, Tabs } from 'antd';
import { RcFile } from 'antd/es/upload';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import {
  AttributesMap,
  useGetAttributesByChannelNumQuery,
} from '../../redux/api/productElements';
import { parseSpreadsheet, readSheet } from '../../util/xlsx';
import { ChannelAccount } from '../../redux/api/channels';
import FormElement from '../common/FormElement';
import { attributeKeys } from '../../constants/common';

// Component State/Context

interface TableCheckboxState {
  $addAll: boolean;
  [key: string]: boolean;
}

const FileColTable = styled(Table)`
  & .ant-form-item {
    margin-bottom: 0;
  }

  & .ant-table-cell {
    padding: 10;
  }
`;

const TableCheckboxContext = createContext<TableCheckboxState>({ $addAll: false });

enum ActionType {
  clearState = 'CLEAR_STATE',
  setAttributesInfo = 'SET_ATTRIBUTES_INFO',
  setAddAllAttributes = 'SET_ADD_ALL_ATTRIBUTES',
  updateCheckedAttribute = 'UPDATE_CHECKED_ATTRIBUTE',
}

interface CleatStateAction {
  type: typeof ActionType.clearState;
}

interface SetAttributesInfoAction {
  type: typeof ActionType.setAttributesInfo;
  value: {
    tableDataNew: IAttributesTable[],
    tableDataExisting: IAttributesTable[],
    headers: string[],
    attributes: AttributesMap,
    tableColumnsNew: ColumnsType<any>,
    tableColumnsExisting: ColumnsType<any>,
  };
}

interface SetAddAllAttributesAction {
  type: typeof ActionType.setAddAllAttributes;
  value: boolean;
}

interface UpdateCheckedAttributeAction {
  type: typeof ActionType.updateCheckedAttribute;
  value: {
    name: string,
    checked: boolean,
  };
}

interface IAttributesTable {
  id: string | number;
  name: string;
  displaySelection?: boolean;
}

type Action =
  CleatStateAction
  | SetAttributesInfoAction
  | SetAddAllAttributesAction
  | UpdateCheckedAttributeAction;

const actions = {
  clearState: (): CleatStateAction => ({ type: ActionType.clearState }),
  setAttributesInfo: (value: {
    tableDataNew: IAttributesTable[],
    tableDataExisting: IAttributesTable[],
    headers: string[],
    attributes: AttributesMap,
    tableColumnsNew: ColumnsType,
    tableColumnsExisting: ColumnsType
  }): SetAttributesInfoAction => ({
    type: ActionType.setAttributesInfo,
    value,
  }),
  setAddAllAttributes: (value: boolean): SetAddAllAttributesAction => ({
    type: ActionType.setAddAllAttributes,
    value,
  }),
  updateCheckedAttribute: (value: { name: string, checked: boolean }): UpdateCheckedAttributeAction => ({
    type: ActionType.updateCheckedAttribute,
    value,
  }),
};

type State = {
  /**
   * Headers loaded from the spreadsheet file
   */
  headers?: string[];

  /**
   * Table data is generated from reading the file headers and verifying
   * which ones are "new" (or non-existent on the attributes list)
   */
  tableDataNew: {
    id: string | number;
    name: string;
  }[];
  tableDataExisting: {
    id: string | number;
    name: string;
  }[];
  /**
   * Columns to be used on the table
   */
  tableColumnsNew: ColumnsType<any>;
  tableColumnsExisting: ColumnsType<any>;

  /**
   * This map keeps track of which attributes have been checked on the table
   * to enable/disable the Attribute Mapping selector.
   */
  checkedAttributes: TableCheckboxState;
};

const initialState: State = {
  headers: [],
  tableDataNew: [],
  tableDataExisting: [],
  tableColumnsNew: [],
  tableColumnsExisting: [],
  checkedAttributes: {
    $addAll: false,
  },
};

const clearStateReducer = (): State => ({ ...initialState });

const setAddAllAttributesReducer = (state: State, action: SetAddAllAttributesAction): State => {
  const checkedAttributes: TableCheckboxState = {
    $addAll: action.value,
  };

  state.headers?.forEach(h => {
    checkedAttributes[h] = action.value;
  });

  return {
    ...state,
    checkedAttributes,
  };
};

const updateCheckedAttributeReducer = (state: State, action: UpdateCheckedAttributeAction): State => {
  const checkedAttributes = {
    ...state.checkedAttributes,
    [action.value.name]: action.value.checked,
  };

  const allItemsChecked = Object.keys(checkedAttributes).reduce((p, c) => {
    if (c === '$addAll') {
      return p;
    }
    const value = checkedAttributes[c];

    return p && value;
  }, true);

  if (checkedAttributes.$addAll) {
    checkedAttributes.$addAll = allItemsChecked;
  }

  return {
    ...state,
    checkedAttributes,
  };
};

const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.clearState:
      return clearStateReducer();
    case ActionType.setAttributesInfo:
      return {
        ...state,
        ...action.value,
      };
    case ActionType.setAddAllAttributes:
      return setAddAllAttributesReducer(state, action);
    case ActionType.updateCheckedAttribute:
      return updateCheckedAttributeReducer(state, action);
    default:
      return state;
  }
};

// End Component State/Context

const AttributeMapCell: React.FC<{ record: { id: string, name: string, displaySelection: boolean, } }> = ({ record }) => (
  <>
    {!record.displaySelection && record.name}
    <Form.Item name={['attributeList', record.name, 'AttributeName']} hidden noStyle initialValue={record.name}>
      <Input disabled />
    </Form.Item>
    <Form.Item
      name={['attributeList', record.name, 'MappedAttributeNum']}
      hidden
      noStyle
      initialValue={record.id === record.name ? 0 : record.id}
    >
      <Input disabled />
    </Form.Item>
  </>
);

// const AttributeMapCellExisting: React.FC<{ value: string, attributeNum: number }> = ({ value, attributeNum }) => (
//   <>
//     <Form.Item
//       name={['attributeList', value, 'add']}
//       valuePropName="checked"
//       initialValue="checked"
//       hidden
//       noStyle
//     >
//       <Input disabled />
//     </Form.Item>
//     <Form.Item name={['attributeList', value, 'AttributeName']} hidden noStyle initialValue={value}>
//       <Input disabled />
//     </Form.Item>
//     <Form.Item name={['attributeList', value, 'MappedAttributeNum']} initialValue={attributeNum.toString()}>
//       <AttributeSelect
//         placeholder="Select an existing attribute"
//         allowClear
//       />
//     </Form.Item>
//   </>
// );

const AddAttributeColumnTitle: React.FC<{ onAddChange?: (e: CheckboxChangeEvent) => void, onIgnoreChange?: (e: CheckboxChangeEvent) => void }> = ({ onAddChange, onIgnoreChange }) => (
  <>
    <Row align="middle">
      <Col span={8}>
        <Form.Item name={['createAll']} style={{marginBottom: 0}} valuePropName="checked">
          <Checkbox onChange={onAddChange} defaultChecked>
            Add All
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={['ignoreAll']} style={{marginBottom: 0}} valuePropName="checked">
          <Checkbox onChange={onIgnoreChange}>
            Ignore All
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  </>
);

interface AddAttributeCellProps {
  dispatch: React.Dispatch<Action>;
  onChange?: (name: string, value: boolean) => void;
  record: { name: string, id: string, displaySelection: boolean },
}

const AddAttributeCell: React.FC<AddAttributeCellProps> = (
  {
    onChange,
    dispatch,
    record,
  },
) => {
  const onSelectChanged = useCallback((e: string) => {
    // Trigger event
    onChange?.(record.name, e === 'add');
    // Dispatch local state event
    dispatch(actions.updateCheckedAttribute({ name: record.name, checked: e === 'add' }));
  }, [onChange, record.name, dispatch]);
  if (!record.displaySelection) {
    return (
      <Form.Item
        name={['attributeList', record.name, 'add']}
        hidden
        initialValue="add"
        noStyle
      >
        <Input disabled />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={['attributeList', record.name, 'add']}
      rules={[{ required: true, message: 'This field is required' }]}
    >
      <Select
        onChange={onSelectChanged}
        placeholder="Select one"
      >
        <Select.Option value="add">Add new channel attribute</Select.Option>
        <Select.Option value="ignore">Ignore</Select.Option>
      </Select>
    </Form.Item>
  );
};

const attributeCheckColumns = (
  dispatch: React.Dispatch<Action>,
  onAddAttributeChange?: (name: string, value: boolean) => void,
  onAddAllChanged?: (e: CheckboxChangeEvent) => void,
  onIgnoreAllChanged?: (e: CheckboxChangeEvent) => void,
  hideAttributeColumn: boolean = true,
) => {
  
  const columns: any[] = [
    {
      title: 'Attribute Name',
      dataIndex: 'name',
    },
    {
      title: 'Existing Attribute',
      dataIndex: 'name',
      render(_: string, record: any) {
        return <AttributeMapCell record={record} />;
      },
    },
  ];

  if (!hideAttributeColumn) {
    columns.push(
      {
        title() {
          return (
            <AddAttributeColumnTitle
              onAddChange={onAddAllChanged}
              onIgnoreChange={onIgnoreAllChanged}
            />
          );
        },
        dataIndex: 'name',
        render(_: string, record: any) {
          return (
            <AddAttributeCell
              dispatch={dispatch}
              onChange={onAddAttributeChange}
              record={record}
            />
          );
        },
      });
  }

  return columns;
};

const useComponentState = (
  attributes?: Entities.ProductAttribute[],
  spreadsheetFile?: UploadFile,
  onAddAll?: (value: boolean) => void,
  onIgnoreAll?: (value: boolean) => void,
  onAddChanged?: (name: string, value: boolean) => void,
  ignoreFilterHeader?: (header: string) => boolean,
  hideAttributeColumn?: boolean,
): [State, React.Dispatch<Action>] => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const parseFile = async (file?: RcFile | File) => {
    if (!file) {
      throw new Error('No file specified');
    }

    const workbook = await parseSpreadsheet(file, { sheetRows: 1 });
    if (!workbook) {
      throw new Error('Workbook could not be parsed');
    }

    const parsedData = await readSheet(workbook);
    if (!parsedData) {
      throw new Error('Spreadsheet file is empty, no data could be retrieved.');
    }

    const [values] = parsedData;
    return values;
  };

  const onAddAllChanged = useCallback((e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onAddAll?.(e.target.checked);
      dispatch(actions.setAddAllAttributes(true));
    }
  }, [onAddAll, dispatch]);

  const onIgnoreAllChanged = useCallback((e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onIgnoreAll?.(e.target.checked);
      dispatch(actions.setAddAllAttributes(false));
    }
  }, [onIgnoreAll, dispatch]);

  // Once the file/attributes info is loaded, populate the state with the
  // required info
  useEffect(() => {
    let mounted = true;

    const unmount = () => {
      mounted = false;
    };

    const attributesMap: AttributesMap = {};

    if (attributes) {
      attributes.forEach(attribute => {
        attributesMap[attribute.AttributeName] = attribute;
      });
    }
    if (!spreadsheetFile || !attributesMap) {
      dispatch(actions.clearState());
      return unmount;
    }

    parseFile(spreadsheetFile.originFileObj)
      .then((headers) => {
        if (!mounted) {
          return;
        }


        const attributesInFileButNoInChannel: IAttributesTable[] = [];
        const attributesInFileAndChannel: IAttributesTable[] = [];
        const attributesFormatted: any = Object.keys(attributesMap).map((a: string) => ({
          id: Number(attributesMap[a].AttributeNum),
          name: attributesMap[a].AttributeName,
          displaySelection: false,
        }));

        headers.forEach(header => {
          //const headerFormatted = header.replace(/\s+/g, '');
          const headerFormatted = header;
          if (ignoreFilterHeader && ignoreFilterHeader(header)) return;

          if (attributesMap?.[headerFormatted]) {
            attributesInFileAndChannel.push({
              id: attributesMap?.[headerFormatted].AttributeNum || 0,
              name: headerFormatted,
              displaySelection: false,
            });
          } else {
            const id = !attributeKeys.find( ak => headerFormatted.toUpperCase() === ak.name) ? headerFormatted : 0;
            attributesInFileButNoInChannel.push({
              id,
              name: headerFormatted,
              displaySelection: true,
            });
          }

        });
        const attributesInChannelButNoInFile: IAttributesTable[] = attributesFormatted.filter((obj2: IAttributesTable) => attributesInFileAndChannel.every((obj1: any) => obj2.name !== obj1.name));
        const tableDataNew = attributesInFileAndChannel.concat(attributesInFileButNoInChannel);
        dispatch(actions.setAttributesInfo({
          tableDataNew,
          tableDataExisting: attributesInChannelButNoInFile,
          headers,
          attributes: attributesMap,
          tableColumnsNew: attributeCheckColumns(dispatch, onAddChanged, onAddAllChanged, onIgnoreAllChanged, hideAttributeColumn),
          tableColumnsExisting: [
            {
              title: 'Attribute Name',
              dataIndex: 'name',
            },
          ],
        }));
      })
      .catch((e) => message.error(`There was an error loading the spreadsheet file.\n${e}`));

    return unmount;
  }, [spreadsheetFile, attributes, onAddAllChanged, onIgnoreAllChanged, onAddChanged]);

  return [state, dispatch];
};

interface AttributeCheckProps {
  file?: UploadFile;
  /**
   * Callback that executes whenever the spreadsheet data is loaded and the headers are
   * read.
   *
   * @param {string[]} entries - Array containing the headers content
   */
  onLoad?: (entries: string[]) => void;

  /**
   * Event triggered when the user clicks the "Add all" checkbox on the table header.
   *
   */
  onAddAll?: (state: boolean) => void;
  onIgnoreAll?: (state: boolean) => void;

  /**
   * Event triggered whenever a checkbox changes state.
   *
   * @param name
   * @param value
   */
  onAddChanged?: (name: string, value: boolean) => void;
  account?: ChannelAccount;
  uploadType: string;
  ignoreFilterHeader?: (header: string) => boolean;
  hideAttributeColumn?: boolean; // hide check all ignore all column
}

const AttributesCheckTable: React.FC<AttributeCheckProps> = ({ file, onLoad, onAddAll, onIgnoreAll, onAddChanged, account = { ChannelNum: 0 }, uploadType, ignoreFilterHeader, hideAttributeColumn }) => {
  const { data, isLoading } = useGetAttributesByChannelNumQuery({ channelNum: account.ChannelNum });
  const [state] = useComponentState(data, file, onAddAll, onIgnoreAll, onAddChanged, ignoreFilterHeader, hideAttributeColumn);
  const { headers, checkedAttributes } = state;
  // Notify new headers are available
  useEffect(() => {
    onLoad?.(headers || []);
  }, [headers, onLoad]);

  if (state.tableDataNew.length === 0 && state.tableDataExisting.length === 0) {
    return null;
  }

  const tableDataNew = state.tableDataNew.filter(f => !attributeKeys.find( ak => f.name.toUpperCase() === ak.name));
  return (
    <Spin spinning={isLoading}>
      {attributeKeys.filter(f => f.type === uploadType).map(m => {
        const attribute = state.tableDataNew.filter(f => f.name === m.name);
        return (
          <>
            <FormElement
              inputProperties={{
                disabled: true,
              }}
              formItemProperties={{
                name: ['attributeList', m.name, 'MappedAttributeNum'],
                hidden: true,
                noStyle: true,
                initialValue: attribute.length === 0 ? 0 : attribute[0]?.id,
              }}
            />
            <FormElement
              inputProperties={{
                disabled: true,
              }}
              formItemProperties={{
                name: ['attributeList', m.name, 'AttributeName'],
                hidden: true,
                noStyle: true,
                initialValue: m.name,
              }}
            />
            <FormElement
              inputProperties={{
                disabled: true,
              }}
              formItemProperties={{
                name: ['attributeList', m.name, 'add'],
                hidden: true,
                noStyle: true,
                initialValue: 'add',
              }}
            />
          </>
        );
      })}
      <Tabs defaultActiveKey="new">
        <Tabs.TabPane
          tab="Attributes in File"
          key="new"
          forceRender
        >
          <p>
            {`Attributes in file found: ${tableDataNew.length}`}
          </p>
          <Space />
          <TableCheckboxContext.Provider value={checkedAttributes}>
            <FileColTable
              rowKey="id"
              columns={state.tableColumnsNew}
              dataSource={tableDataNew}
              scroll={{ y: '300px' }}
              pagination={false}
            />
          </TableCheckboxContext.Provider>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Missing Attributes"
          key="existing"
          forceRender
        >
          <p>
            {`Missing attributes found: ${state.tableDataExisting.length}`}
          </p>
          <Space />
          <TableCheckboxContext.Provider value={checkedAttributes}>
            <Table
              rowKey="id"
              columns={state.tableColumnsExisting}
              dataSource={state.tableDataExisting}
              scroll={{ y: '300px' }}
              pagination={false}
            />
          </TableCheckboxContext.Provider>
        </Tabs.TabPane>
      </Tabs>
    </Spin>
  );
};

export default AttributesCheckTable;
