import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  message,
} from "antd";

import { CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE } from '../../../constants/config';
import {
  addControlTowerGroup,
  editControlTowerGroup,
} from "../../../services/controlTower";
import EditChannelDataFieldDialog from './EditChannelDataFieldDialog';
import ChannelDataFieldMappingDialog from './ChannelDataFieldMappingDialog';


type AddEditDialogProps = {
  mode: "New" | "Edit";
  visible: boolean;
  group: any;
  onRefresh: Function;
  onClose: Function;
};

const AddEditDialog = (props: AddEditDialogProps) => {
  const { mode = "New", group, visible, onRefresh, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [editChannelDataFieldDialogVisible, setEditChannelDataFieldDialogVisible] = useState(false);
  const [channelDataFieldMappingVisible, setChannelDataFieldMappingVisible] = useState(false);

  const [form] = Form.useForm();

  const closeChannelDataFieldMappingDialog = () => {
    setChannelDataFieldMappingVisible(false);
  };

  const closeEditChannelDataFieldDialog = () => {
    setEditChannelDataFieldDialogVisible(false);
  };

  const openChannelDataFieldMappingDialog = () => {
    setChannelDataFieldMappingVisible(true);
  };

  const openEditChannelDataFieldDialog = () => {
    setEditChannelDataFieldDialogVisible(true);
  };

  const isChannelDataGroup = () => {
    return props.group && props.group.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE;
  };

  const saveField = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const res =
        mode === "New"
          ? await addControlTowerGroup(params)
          : editControlTowerGroup(group.rowNum, params);
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  return (<>
    <Modal
      title={`${mode} Control Tower Group`}
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} requiredMark={false}>
          <Form.Item
            label="Group Name"
            name="controlTowerGroupName"
            initialValue={group?.controlTowerGroupName}
            rules={[{ required: true, message: "Group name is required" }]}
          >
            <Input placeholder="Please input group name" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="controlTowerGroupDescription"
            initialValue={group?.controlTowerGroupDescription}
            // rules={[{ required: true, message: "Display name is required" }]}
          >
            <Input placeholder="Please input display name" />
          </Form.Item>

          <Form.Item
            label="Sequence"
            name="displaySequence"
            initialValue={group?.displaySequence}
            rules={[{ required: true, message: "Sequence is required" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              placeholder="Please input Sequence"
            />
          </Form.Item>

          <Form.Item
            label="Columns"
            name="columns"
            hidden={isChannelDataGroup()}
            initialValue={group?.columns}
            rules={[{ required: true, message: "Columns is required" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              placeholder="Please input columns"
            />
          </Form.Item>
        </Form>
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="space-between">
              <Space>
                {isChannelDataGroup() && (<>
                  <Button
                    onClick={openEditChannelDataFieldDialog}
                  >
                    Edit Field
                  </Button>
                  <Button
                    onClick={openChannelDataFieldMappingDialog}
                  >
                    Edit Mapping
                  </Button>
                </>)}
              </Space>
              <Button type="primary" onClick={saveField}>
                Save
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
    {editChannelDataFieldDialogVisible && (
      <EditChannelDataFieldDialog
        group={props.group as StringKAnyVPair}
        onClose={closeEditChannelDataFieldDialog}
        visible={editChannelDataFieldDialogVisible}
      />
    )}
    {channelDataFieldMappingVisible && (
      <ChannelDataFieldMappingDialog
        group={props.group as StringKAnyVPair}
        onClose={closeChannelDataFieldMappingDialog}
        visible={channelDataFieldMappingVisible}
      />
    )}
  </>);
};

export default AddEditDialog;
