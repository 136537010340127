import React from 'react';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Space,
  // Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  FilterTwoTone,
} from '@ant-design/icons';
import styled from 'styled-components';

import { DataGrid } from '../../../../components/common/datagrid/DataGrid2';
import { HoverBgButton, Label } from '../../../../components/common/styledComponents';
import Loading from '../../../../components/common/Loading';
import ScreenMask from '../../../../components/common/ScreenMask';
import SpaceCell from '../../../../components/common/SpaceCell';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../../constants/config';
import {
  deleteTemplate,
  fetchChannelAccountCategoryName,
  getChannelAccountTemplate,
  setMappingTemplateStatus,
} from '../../../../services/ChannelIntegration';
//import { saveSyncTemplate } from '../../../../services/channels';
import { downloadOrignalTemplate } from '../../../../services/template';
import UploadModal from '../../../Templates/UploadModal3';

type Props = {
  channelAccountNum: string;
  channelNum: string;
  platformNum: string;
};

const CtnWrapper = styled.div`
  & .InovuaReactDataGrid {
    height: 100%;
  }

  & .common-toolbar {
    margin-bottom: 12px;
  }

  & .grid-ctn {
    height: calc(100vh - 318px);
  }

  & .grid-filter .ant-input-affix-wrapper {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  & .more-action-btn {
    height: 26px;
    padding: 2px 5px 8px;
  }

  & .tc-section1 {
    margin-bottom: 12px;
  }
`;

const columns = [
  {
    flex: 1,
    name: 'templateName',
    header: 'Template Name',
    headerAlign: 'center',
    minWidth: 260,
    //textAlign: 'center',
  },
  {
    name: 'type',
    header: 'Type',
    headerAlign: 'center',
    width: 100,
    textAlign: 'center',
  },
  {
    name: 'mappingType',
    header: 'Assigned as',
    headerAlign: 'center',
    textAlign: 'center',
  },
  {
    name: 'action',
    header: 'Action',
    headerAlign: 'center',
    minWidth: 256,
  },
  {
    flex: 1,
    name: 'fullPath',
    header: 'Category',
    headerAlign: 'center',
    //textAlign: 'center',
    render(row: any) {
      const { data } = row;

      if (data && typeof data === 'object') {
        // console.log('d', data.fullPath);
        const paths = data.fullPath.split(',').map((e: string) => e.trim()).filter((e: string) => e);
        const pathStr = paths.join(', ');
        const pathTips = <span>{paths.join('<br />')}</span>;

        return  <Typography.Text style={{width: '100%'}} ellipsis={{tooltip: pathTips}}>{pathStr}</Typography.Text>;
      }

      return null;
    },
  },
  {
    flex: 1,
    name: 'uploadBy',
    header: 'Uploaded by',
    headerAlign: 'center',
    //textAlign: 'center',
  },
  {
    name: 'uploadAt',
    header: 'Uploaded at',
    headerAlign: 'center',
    //textAlign: 'center',
  },
];


const IntegrationTemplate = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryLabel, setCategoryLabel] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState<StringKAnyVPair>({});
  const [searchName, setSearchName] = useState('');
  const [searchTimer, setSearchTimer] = useState<number>();
  const [templateData, setTemplateData] = useState<StringKAnyVPair[]>([]);
  const [templateOperateMode, setTemplateOperateMode] = useState(0);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const attrSearchInputRef = React.useRef<any>(null);
  const pageRef = React.useRef<any>(null);

  const assignTemplate = async (tpl: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      //const res = await saveSyncTemplate(props.channelAccountNum, props.channelNum, tpl.productMappingNum)
      const res = await setMappingTemplateStatus(
        props.channelNum as any,
        props.channelAccountNum as any, 
        tpl.productMappingNum,
        1,
      );

      //console.log('-->', res, tpl);
      if(res){
        loadTemplateData();
      }
    } catch(e) {
      notification.error({
        message: `Assigned as cataglog template error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeUploadDialog = () => {
    setUploadModalVisible(false);
  };

  const confirmDeleteTemplate = (tpl: StringKAnyVPair) => {
    Modal.confirm({
      title: 'Do you want to delete this template?',
      icon: <ExclamationCircleOutlined />,
      content: `Template Name: ${tpl.templateName}`,
      onOk: () => removeTemplate(tpl.productMappingNum),
      onCancel() {},
    });
  };

  const exportTemplate = async (tpl: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      const res = await downloadOrignalTemplate(tpl.productMappingNum);
      // todo: save to file
      // console.log('res ->', res);
      const downloadName = filenameFromHeader(res.headers) || `Template-${tpl.templateName}.xlsx`;
      const content = res.data;
      const url = window.URL.createObjectURL(new Blob([content]));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', `Template-${name}.xlsx`);
      link.setAttribute('download', downloadName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      notification.error({
        message: `Download file error: ${error}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const filenameFromHeader = (header: StringKAnyVPair) => {
    const str = header['content-disposition'];

    if (str) {
      const fields = str.split('=');

      return fields[fields.length - 1];
    }
  };

  const gridTemplateData = (tds: StringKAnyVPair[]) => {
    return tds.filter(t => t.templateName.toLocaleLowerCase().indexOf(searchName.toLowerCase()) > -1);
  };

  const isAssignableAsCatalog = (tpl: StringKAnyVPair) => {
    return isAssignable(tpl) && !tpl.isAssignedFeedTemplate && !!tpl.category;
  };

  const isAssignable = (tpl: StringKAnyVPair) => {
    return ['Inventory Template', 'Offer Template'].indexOf(tpl.mappingType) < 0;
  };

  // eslint-disable-next-line
  const loadCategoryAttrs = async () => {
    try {
      const cas = await fetchChannelAccountCategoryName(props.channelNum as any);

      //console.log('cas', cas);
      setCategoryLabel(cas);
    } catch(e) {
      notification.error({
        message: `Loaded category attribute error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  // eslint-disable-next-line
  const loadPageData = () => {
    loadTemplateData();
    loadCategoryAttrs();
  };

  // eslint-disable-next-line
  const loadTemplateData = async () => {
    setIsLoading(true);

    try {
      const tl = await getChannelAccountTemplate(props.channelAccountNum as any, props.channelNum as any);

      if (Array.isArray(tl)) {
        setTemplateData(tl);
      }
    } catch(e) {
      notification.error({
        message: `Loaded template error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const moreActionMenus = (data: StringKAnyVPair) => {
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              //disabled={isLoading}
              key="export-tpl"
              onClick={() => exportTemplate(data)}
            >
              Export Template
            </Menu.Item>
            {isAssignableAsCatalog(data) && (
              <Menu.Item
                //disabled={isLoading}
                key="del-tpl"
                onClick={() => confirmDeleteTemplate(data)}
              >
                <Label className="label-danger">Delete</Label>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Button className="more-action-btn" size="small">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    );
  };

  const onSearchAttrKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (/*sk &&*/ typeof sk === 'string' && pageRef.current) {
      sk = sk.toLowerCase().trim();
      // console.log('sk', sk);
      setSearchName(sk);
    }
  };

  const openEditTemplateDialog = (tpl: StringKAnyVPair) => {
    setCurrentTemplate(tpl);
    setTemplateOperateMode(1);
    setUploadModalVisible(true);
  };

  const openUploadDialog = () => {
    setCurrentTemplate({});
    setTemplateOperateMode(0);
    setUploadModalVisible(true);
  };

  const removeTemplate = async (productMappingNum: number) => {
    setIsLoading(true);

    try {
      const res = await deleteTemplate(props.channelNum as any, props.channelAccountNum as any, productMappingNum);

      if (res) {
        notification.success({
          message: res,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
        //getCustomTemplates();
      }

      loadTemplateData();
    } catch(e) {
      notification.error({
        message: `Deleted template error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const templateColumns = (cols: StringKAnyVPair[]) => {
    for (let i = 0; i < cols.length; i++) {
      switch(cols[i].name) {
        case 'action': {
          cols[i].render = (row: any) => {
            const { data } = row;
            //console.log('r', row, data);
            return (<>
              <Row align="middle" justify="space-between">
                <Space>
                  {/*<Tooltip title="Export template">
                    <HoverBgButton
                      onClick={() => exportTemplate(data)}
                      size="small"
                      hovertype="primary"
                    >
                      Export
                    </HoverBgButton>
                  </Tooltip>*/}
                  {/*!data.isCategoryTemplate && !data.isAssignedFeedTemplate && (*/}
                  {isAssignableAsCatalog(data) && (
                    //<Tooltip title="Assign as catalog template">
                      <HoverBgButton
                        onClick={() => assignTemplate(data)}
                        size="small"
                        hovertype="primary"
                      >
                        Assign as Catalog Template
                      </HoverBgButton>
                    //</Tooltip>
                  )}
                  {/*!!data.isCategoryTemplate && (*/}
                  {isAssignable(data) && !!data.isAssignedFeedTemplate && (
                    //<Tooltip title="Unassign as catalog template">
                      <HoverBgButton
                        onClick={() => unassignTemplate(data)}
                        size="small"
                        hovertype="primary"
                      >
                        Unassign
                      </HoverBgButton>
                    //</Tooltip>
                  )}
                  <span>&nbsp;</span>
                </Space>
                {/*moreActionMenus(data)*/}
                {data.type === 'Custom' && moreActionMenus(data)}
              </Row>
            </>);
          };

          break;
        }

        case 'mappingType': {
          cols[i].render = (row: any) => {
            const { data } = row;

            return  data.isCategoryTemplate ?
              <Label className="label-bold label-italic">{data.mappingType}</Label> : (
              data.mappingType
            );
          };

          break;
        }

        case 'templateName': {
          cols[i].render = (row: any) => {
            const { data } = row;

            return (
              <SpaceCell
                onTextClick={() =>  data.downloadFrom === 1 && openEditTemplateDialog(data)}
                text={data.templateName}
                textIsButton={data.downloadFrom === 1}
              />
            );
          };

          break;
        }

        case 'category':
        case 'uploadBy': {
          cols[i].render = (row: any) => {
            const { data } = row;

            return (
              <SpaceCell
                text={data[cols[i].name]}
              />
            );
          };

          break;
        }
      }
    }

    return cols;
  };

  const unassignTemplate = async (tpl: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      const res = await setMappingTemplateStatus(
        props.channelNum as any,
        props.channelAccountNum as any, 
        tpl.productMappingNum,
        0,
      );

      //console.log('-->', res, tpl);
      if (res) {
        loadTemplateData();
      }
      // loadTemplateData();
    } catch(e) {
      notification.error({
        message: `Unassigned error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadPageData();
      setInited(true);
    }
  }, [
    inited,
    loadPageData,
  ]);

  return (<>
    <CtnWrapper ref={pageRef}>
      <Row align="middle" className="tc-section1" justify="space-between">
        <span>
          <Label className="label-bold label-colon">Category Attribute</Label>
          <span>{categoryLabel}</span>
        </span>
        <Button
          onClick={openUploadDialog}
          type="primary"
        >
          Import Custom Template
        </Button>
      </Row>
      <div className="common-toolbar">
        <Input.Search
          allowClear
          className="grid-filter"
          enterButton={null}
          onKeyUp={onSearchAttrKeyUp}
          onSearch={onSearchAttributes}
          placeholder="fiter by template name"
          prefix={searchName ? <FilterTwoTone /> : <FilterOutlined />}
          ref={attrSearchInputRef}
          style={{width: 300}}
        />
      </div>
      <div className="grid-ctn">
        <DataGrid
          idProperty="productMappingNum"
          rowHeight={40}
          columns={templateColumns(columns)}
          dataSource={gridTemplateData(templateData)}
          pagination={false}
        />
      </div>
    </CtnWrapper>
    {uploadModalVisible && (
      <UploadModal
        channelNum={Number(props.channelNum)}
        channelAccountNum={Number(props.channelAccountNum)}
        onCancel={closeUploadDialog}
        onOk={() => {
          closeUploadDialog();
          //loadTemplateData();
          loadPageData();
        }}
        mode={templateOperateMode}
        templateData={currentTemplate}
        templateType={4}
        title={`${templateOperateMode === 0 ? 'Upload' : 'Edit'} Custom Mapping template`}
      />
    )}
    {isLoading && <ScreenMask>
      <Loading size={LOADING_ICON_SIZE1} />
    </ScreenMask>}
  </>);
};

export default IntegrationTemplate;
