import React from 'react';
import { Checkbox, Row, Space, Table, Typography, Radio, Select } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  uploadResponse: any;
  destinationNotEmptyOverwrite: boolean;
  sourceEmptyIgnoreUpdate: boolean;
  childrenNotEmptyOverwrit: boolean;
  mappingList: any[];
  copywritingList: any[];
  fileFormats: { [key: string]: string };
  chooseMapping: string;
  onShowSaveMapping: () => void;
  childrenEmptyOverwrit: boolean;
};

const AttributeSelector = (props: Props) => {
  const {
    uploadResponse,
    destinationNotEmptyOverwrite,
    sourceEmptyIgnoreUpdate,
    childrenNotEmptyOverwrit,
    childrenEmptyOverwrit,
    mappingList,
    copywritingList,
    fileFormats,
    // chooseMapping,
    // onShowSaveMapping,
  } = props;

  const columns = [
    {
      dataIndex: 'headerName',
      title: 'Source',
    },
    {
      dataIndex: 'copywritingElementNum',
      title: 'Destination',
      render(text: string, data: any, index: number) {
        return (
          <Select
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
            value={data.copywritingElementNum}
            disabled
          >
            <Select.Option key={0} value={0}>
              Ignore
            </Select.Option>
            {copywritingList.map((i) => (
              <Select.Option key={i.copywritingElementNum} value={i.copywritingElementNum}>
                {i.elementName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      dataIndex: 'id',
      title: 'Addition Action',
      render(text: string, data: any, index: number) {
        return (
          <Space>
            <Checkbox checked={data.removeHtmlTags} disabled>
              Remove Html Tags
            </Checkbox>
            <Checkbox checked={data.removeNewLine} disabled>
              Remove New Line
            </Checkbox>
            <Checkbox checked={data.trimText} disabled>
              Trim Text
            </Checkbox>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Space direction="vertical">
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>
                File Format: <Text>{fileFormats[uploadResponse.operation]}</Text>
              </Text>
              <Text strong style={{ marginLeft: 16 }}>
                File Name: <Text>{uploadResponse.originalFileName}</Text>
              </Text>
            </Space>
          </Row>
          <Row justify="start">
            <Text strong style={{ color: '#096dd9' }}>
              Options:
              {/* <Text style={{ marginLeft: 8 }}>{chooseMapping}</Text> */}
            </Text>
            {/* <Button onClick={() => onShowSaveMapping()}>Save Current Mapping</Button> */}
          </Row>
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If source doesn't have value (empty):`}</Text>
              <Radio.Group disabled value={sourceEmptyIgnoreUpdate}>
                <Radio value={false}>Use empty value to update destination</Radio>
                <Radio value>{`Ignore(don't ip destination)`}</Radio>
              </Radio.Group>
            </Space>
          </Row>
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If destination has value (Not empty):`}</Text>
              <Radio.Group disabled value={destinationNotEmptyOverwrite}>
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>

          {/* <Row justify="start">
            <Text strong style={{ color: '#096dd9' }}>
              Children:
            </Text>
          </Row> */}
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If children has value (not empty):`}</Text>
              <Radio.Group
                disabled
                value={childrenNotEmptyOverwrit}
              >
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If children doesn't has value:`}</Text>
              <Radio.Group
                disabled
                value={childrenEmptyOverwrit}
              >
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>
          {/* {
            childrenNotEmptyOverwrit && <Row>
              <Text type="danger">Empty value from parent will be ignored. If you need to update child value to empty, you need to import at the child level separtely.</Text>
            </Row>
          } */}

          <Table
            style={{ marginTop: 32 }}
            columns={columns}
            dataSource={mappingList}
            pagination={false}
            rowKey="id"
          />
        </Space>
      </div>
    </>
  );
};

export default AttributeSelector;
