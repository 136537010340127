import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ContentLayout from '../components/ContentLayout';
import HeaderPermissions from '../components/UserManagement/HeaderPermissions';
import PermissionsForm from '../components/UserManagement/PermissionsForm';
import { editUserPermissions, fetchPermissions, fetchUserPermissions } from '../services/userManagement';
import Loop from '../assets/icons/loop';
import notificationActions from '../redux/actions/notifications';
import profileActions from '../redux/actions/profiles';
import Spacer from '../components/common/Spacer';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 `;

interface IUser {
  RowNum: number,
  Email: string,
  UserName: string,
  MasterAccountNum: number,
  ProfileNum: number,
  PermissionNum: number,
  PermissionName: string,
  PermissionLevel: number,
}

interface IPermissions {
  PermissionNum: number,
  PermissionName: string,
  Scope: string,
  MaxLevel: number,
  LevelCombination: number,
  Notes: string,
}

interface IRequestPermissions {
  Email: string,
  PermissionNum: number,
  PermissionLevel: number,
}

function UserManagementPermissions(props: any) {
  const [loading, setLoading] = useState(true);
  const {profilesEntity, history} = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [ data, setData ] = useState<IUser[]>([{
    RowNum: 0,
    Email: '',
    UserName: '',
    MasterAccountNum: 0,
    ProfileNum: 0,
    PermissionNum: 0,
    PermissionName: '',
    PermissionLevel: 0,
  },
  ]);
  const [ permissions, setPermissions ] = useState<IPermissions[]>([]);
  const [ requestPermissions, setrequestPermissions ] = useState<IRequestPermissions[]>([]);
  const [ hashValues, sethashValues ] = useState(new Map());


  const fetchData = async () => {
    try {
      const userPermissions = await fetchUserPermissions(props.match.params.email);
      const allPermissions = await fetchPermissions();
      setData(userPermissions);
      setPermissions(allPermissions);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
  const handlePermissionsChange = (originalPermissionLevel: number, PermissionNum: number, PermissionLevel: any) => {
    const body = {
      originalPermissionLevel,
      PermissionLevel,
      PermissionNum,
    };
    hashValues.set(PermissionNum, body);
    if (hashValues.get(PermissionNum).PermissionLevel === hashValues.get(PermissionNum).originalPermissionLevel) {
      hashValues.delete(PermissionNum);
    }


    const newHash = [...hashValues].map(([, value]) => ({
      ...value,
      Email: props.match.params.email,
    }));
    sethashValues(hashValues);
    setrequestPermissions(newHash);
  };

  const saveUserNewPermissions = async () => {
    try {
      setLoadingButton(true);
      await editUserPermissions(requestPermissions);
      setLoadingButton(false);
      sethashValues(new Map());
      props.actions.setNotification('success', 'Permissions saved');
      const loggedUserEmail = props.profilesEntity.permissions.values().next().value.Email;
      if (props.match.params.email === loggedUserEmail) {
        const permissions2 = await fetchUserPermissions(loggedUserEmail);
        props.actionsProfiles.setPermissions(permissions2);
      }
      window.location.reload();
    } catch (e) {
      setLoadingButton(false);
      throw e;
    }
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData();
    }, []);
  };

  useFetch();

  const { profiles, selectedIndex } = profilesEntity;
  const hasProfiles = profiles.length;

  return (
    <ContentLayout>
      {
        loading ? (
          <LoadingWrapper>
            <Loop
              height={40}
              width={40}
              style={{
                transform: 'rotate(45deg)',
                animation: 'antRotate 1.2s infinite reverse linear',
              }}
            />
          </LoadingWrapper>
        ) : (
          <>
            <HeaderPermissions
              data={{
                name: data[0]?.UserName,
                email: data[0]?.Email,
                profileName: hasProfiles && profiles[selectedIndex].DisplayName,
              }}
              history={history}
              saveUserPermissions={saveUserNewPermissions}
              loadingButton={loadingButton}
              isDisabled={hashValues.size}
            />
            <Spacer />
            <PermissionsForm handlePermissionsChange={handlePermissionsChange} permissions={permissions} userPermissions={data} />
          </>
        )
      }
    </ContentLayout>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActions, dispatch),
  actionsProfiles: bindActionCreators(profileActions, dispatch),
});

const mapStateToProps = (state: any) => ({
  profilesEntity: state.profiles,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementPermissions);
