import { OrderDetailsActions, OrderDetailsActionTypes } from '../types/orderDetails';

interface OrderDetailsState {
  order: Entities.OrderDetails | null;
  loading: boolean;
  editMode: boolean,
  isFormUpdated: boolean,
  selectedPendingItems: Array<string>,
  showPendingErrorMessage: boolean,
  pendingErrorMessage: string,
  shippingTotal: string,
  carriers: Entities.OrderCarriers | null,
}

const initialState: OrderDetailsState = {
  order: null,
  loading: false,
  editMode: false,
  isFormUpdated: false,
  selectedPendingItems: [],
  showPendingErrorMessage: false,
  pendingErrorMessage: '',
  shippingTotal: '$0.00',
  carriers: null,
};

const profiles = (state = initialState, action: OrderDetailsActionTypes) => {
  switch (action.type) {
    case OrderDetailsActions.SET_CARRIERS:
      return { ...state, carriers: action.value };
    case OrderDetailsActions.SET_SHIPPINGTOTAL:
      return { ...state, shippingTotal: `$${action.value}` };
    case OrderDetailsActions.SET_PENDINGERRORMESSAGE:
      return { ...state, pendingErrorMessage: action.value };
    case OrderDetailsActions.SET_SHOWPENDINGERRORMESSAGE:
      return { ...state, showPendingErrorMessage: action.value };
    case OrderDetailsActions.SET_SELECTEDPENDINGITEMS:
      return { ...state, selectedPendingItems: action.value };
    case OrderDetailsActions.SET_ISFORMUPDATED:
      return { ...state, isFormUpdated: action.value };
    case OrderDetailsActions.SET_EDITMODE:
      return { ...state, editMode: action.value };
    case OrderDetailsActions.SET_LOADING:
      return { ...state, loading: action.value };
    case OrderDetailsActions.SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case OrderDetailsActions.CLEAR_PAGE:
      return initialState;
    default:
      return state;
  }
};

export default profiles;
