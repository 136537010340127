import { digitBridgeApi } from './http';

export interface SimpleElement {
  id: number;
  name: string;
}

// Empty interfaces in case there's new attributes added

interface Customer extends SimpleElement {
}

interface Inventory extends SimpleElement {
}

interface NonInventoryItems extends SimpleElement {
}

interface LiabilityAccount extends SimpleElement {
}

export interface UserData {
  customers: Array<Customer>;
  inventoryItems: Array<Inventory>;
  nonInventoryItems: Array<NonInventoryItems>;
  otherCurrentLiabilitiesAccounts: Array<LiabilityAccount>;
  userCompanyInfo: {
    companyName: string;
  };
}

interface IntegrationSettingsInput {
  masterAccountNum: number;
  profileNum: number;
  exportOrderAs: number;
  // Datetime string
  exportOrderFromDate: string;
  // Datetime string
  exportOrderToDate: string;
  salesTaxExportRule: number;
  qboCustomerCreateRule: number;
  qboItemCreateRule: number;
  qboInvoiceImportRule: number;
  qboSalesOrderImportRule: number;
  // Datetime string
  qboImportOrderAfterUpdateDate: string;
  qboSalesTaxAccId?: number;
  qboSalesTaxAccName?: string;
  exportOrderDateType?: number;
  qboDefaultItemName?: string;
  qboDefaultItemId?: number;
  qboSalesTaxItemName?: string;
  qboSalesTaxItemId?: number;
  qboDiscountItemName?: string;
  qboDiscountItemId?: number;
  qboShippingItemName?: string;
  qboShippingItemId?: number;
}

interface IntegrationSettings extends IntegrationSettingsInput {
  integrationSettingNum: number;
  exportByOrderStatus: number;
  dailySummaryOrderDateType: number;
  qboEndCustomerPoNumCustFieldName: string;
  qboEndCustomerPoNumCustFieldId: number;
  qboChnlOrderIdCustFieldName: string;
  qboChnlOrderIdCustFieldId: number;
  qbo2ndChnlOrderIdCustFieldName: string;
  qbo2ndChnlOrderIdCustFieldId: number;
  qboHandlingServiceItemName: string;
  qboHandlingServiceItemId: number;
  qboHandlingServiceAccName: string;
  qboHandlingServiceAccId: number;
  qboDiscountAccName: string;
  qboDiscountAccId: number;
  qboItemAssetAccName: string;
  qboItemAssetAccId: number;
  qboItemExpenseAccName: string;
  qboItemExpenseAccId: number;
  qboItemIncomeAccName: string;
  qboItemIncomeAccId: number;
  qboPostageRule: number;
  // Datetime string
  enterDate: string;
  // Datetime string
  lastUpdate: string;
  qboSettingStatus: number;
}


interface ChannelAccountSettingsInput {
  masterAccountNum: number;
  profileNum: number;
  channelAccountName: string;
  channelAccountNum: number;
  channelName: string;
  channelNum: number;
  channelQboCustomerName: string;
  channelQboCustomerId: number;
  channelQboFeeAcountName?: string;
  channelQboFeeAcountId?: number;
}

export interface ChannelAccountSettings extends ChannelAccountSettingsInput {
  // 64-bit integer
  channelAccSettingNum: string;
  channelQboBankAcountName: string;
  channelQboBankAcountId: number;
  // Datetime string
  enterDate: string;
  // Datetime string
  lastUpdate: string;
}

export interface QuickbooksSettingsInput {
  integrationSetting: IntegrationSettingsInput;
  chnlAccSettings: Array<ChannelAccountSettingsInput>;
}

export interface QuickbooksSettings {
  integrationSetting?: IntegrationSettings;
  chnlAccSettings: Array<ChannelAccountSettings>;
}

/**
 * Service to take care of Quickbooks-related API usage.
 *
 */
export default class Quickbooks {
  static basePath: string = '/v1/erp/quickbooksOnline';

  static async getOAuthUrl(): Promise<string> {
    const response: any = await digitBridgeApi.get(`${this.basePath}/oauthUrl`);
    return response.redirectUrl;
  }

  static async getTokenStatus(): Promise<{ qboOAuthTokenStatus: number }> {
    // eslint-disable-next-line no-return-await
    return await digitBridgeApi.get(`${this.basePath}/tokenStatus`);
  }

  /**
   * Used to verify if the Quickbooks account is connected.
   *
   * If it fails, it's required to prompt the user to connect their account.
   */
  static async getInitialData(): Promise<UserData | null> {
    const response: any = await digitBridgeApi.get(`${this.basePath}/userInitialData`);
    if (!response?.userCompanyInfo) {
      return null;
    }

    return response;
  }

  static async getSettings(): Promise<QuickbooksSettings> {
    return digitBridgeApi.get(`${this.basePath}/settings`);
  }

  static async createSettings(values: QuickbooksSettingsInput): Promise<string> {
    return digitBridgeApi.post(`${this.basePath}/settings`, values);
  }

  static async updateSettings(values: QuickbooksSettingsInput): Promise<string> {
    return digitBridgeApi.patch(`${this.basePath}/settings`, values);
  }

  static async disconnectUser(): Promise<string> {
    return digitBridgeApi.post(`${this.basePath}/disconnectUser`);
  }
}
