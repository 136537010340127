import React from 'react';
import {
  Button,
  Divider,
  Input,
  Select,
  Space,
  notification,
} from 'antd';
//import { PlusOutlined } from '@ant-design/icons';

import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
import LoadingIcon from '../../components/common/LoadingIcon';
//import { InfoButton } from '../../components/common/styledComponents';
import { loadStyleVariations } from '../../screens/DetailProduct/helper';

type Props = {
  //key?: string;
  version?: number;
  onBrandChange?: Function;
};

const BrandSelector = (props: Props) => {
  const { useState } = React;
  const [brandInputVersion, setBrandInputVersion] = useState(0);
  const [inited, setInited] = useState(false);
  // eslint-disable-next-line
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [optionList, setOptionList] = useState<any[]>([]);

  const brandInputRef = React.useRef<any>(null);

  /*const options: StringKAnyVPair[] = [
    { label: 'Brand 1', value: 'Brand 1', },
    { label: 'Brand 2', value: 'Brand 2', },
    { label: 'Brand 3', value: 'Brand 3', },
    { label: 'Brand 4', value: 'Brand 4', },
    { label: 'Brand 5', value: 'Brand 5', },
    { label: 'Brand 6', value: 'Brand 6', },
    { label: 'Brand 7', value: 'Brand 7', },
    { label: 'Brand 8', value: 'Brand 8', },
    { label: 'Brand 9', value: 'Brand 9', },
  ];*/

  const loadOptionList = async () => {
    setIsLoadingOptions(true);

    try {
      const sv = await loadStyleVariations();

      if (sv && typeof sv === 'object') {
        const { brand } = sv;

        if (Array.isArray(brand)) {
          const opts = brand.map(e => ({
            label: e.code,
            value: e.code,
          }));

          setOptionList(opts);
        }
      }
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const onAddBrand = () => {
    const brand = brandInputRef.current?.input.value;

    if (brand && brand.trim()) {
      const nb = brand.trim();
      console.log('b', brand);
      const fs = optionList.filter(e => e.value === nb);

      if (fs.length > 0) {
        notification.error({
          message: 'This item already exists.',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
        brandInputRef?.current.focus();
      } else {
        optionList.push({
          label: nb,
          value: nb,
        });
        setBrandInputVersion(brandInputVersion + 1);
        setOptionList([...optionList]);
        //setSelectedBrands([...selectedBrands, nb]);
        onSelectionChange([...selectedBrands, nb]);
      }
    } else {
      notification.error({
        message: 'Please input new brand item',
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
      brandInputRef?.current.focus();
    }
  };

  const onSelectionChange = (values: string[]) => {
    setSelectedBrands([...values]);

    if (typeof props.onBrandChange === 'function') {
      props.onBrandChange(values);
    }
  };

  const renderSelectionMenu = (menu: any) => {
    return (<>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Space style={{ padding: '0 8px 4px' }}>
      <Input
        key={`custom-brand-${brandInputVersion}`}
        placeholder="Please enter brand"
        ref={brandInputRef}
        //value={name}
        //onChange={onNameChange}
      />
        <Button
          //type="text"
          //icon={<PlusOutlined />}
          onClick={onAddBrand}
        >
          Add to Search
        </Button>
      </Space>
    </>);
  };

  React.useEffect(() => {
    if (!inited) {
      loadOptionList();
      //setOptionList(options);
      setInited(true);
    }
  }, [inited]);

  return (<>
    {isLoadingOptions ? (
      <span className="loading-wrapper">
        <LoadingIcon size="small" />
      </span>
    ) : (
      <Select
        dropdownRender={renderSelectionMenu}
        //key={props.key}
        key={`brand-select-${props.version}`}
        onChange={onSelectionChange}
        options={optionList}
        mode="multiple"
        value={selectedBrands}
      />
    )}
  </>);
};

export default BrandSelector;
