import { FormInstance } from 'antd/es/form';
import React, { createContext } from 'react';
import { editLabel } from '../../services/products';

export interface ProductLabelsState {
  data: Entities.ILabel[];
  filteredData: Entities.ILabel[];
  searchDisabled: boolean;
  editingKey: string;
  selectedLabel: Entities.ILabel;
  formValues: Entities.ILabel;
  pagination: any;
  loadingData: boolean;
  addModalVisible: boolean;
  confirmationModalVisible: boolean;
  form: FormInstance;
  isSaving: boolean;
}

export enum StateActions {
  SET_DATA,
  SET_EDITING_KEY,
  SET_SELECTED_LABEL,
  SET_FORM_VALUES,
  SET_PAGINATION,
  SET_LOADING_DATA,
  SET_IS_SAVING,
  SET_ADD_MODAL_VISIBLE,
  SET_CONFIRMATION_MODAL_VISIBLE,
  SET_FILTERED_DATA,
  RESET_FORM,
  REPLACE_INLINE_EDIT,
  SET_LABEL_TO_DELETE,
  SET_SEARCH_DISABLED,
}

const formValuesInitialState: Entities.ILabel = {
  ProductLabelId: '',
  ProductLabelName: '',
  ProductLabelDesc: '',
};

export const getReducerInitialState = (form: FormInstance): ProductLabelsState => ({
  data: [],
  filteredData: [],
  editingKey: '',
  searchDisabled: false,
  selectedLabel: { ...formValuesInitialState },
  form,
  formValues: { ...formValuesInitialState },
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  },
  loadingData: false,
  addModalVisible: false,
  confirmationModalVisible: false,
  isSaving: false,
});

export type Dispatcher = React.Dispatch<Utils.DispatchAction<StateActions>>;

/**
 * Replaces the value of an inline edit with the result from the server.
 * @param state
 * @param value
 * @param rowIndex
 */
const replaceInlineEdit = (state: ProductLabelsState, {
  value,
  rowIndex,
}: { value: Entities.ILabel, rowIndex: number }): ProductLabelsState => {
  const data = [...state.data];
  data[rowIndex] = value;

  return {
    ...state,
    data,
    editingKey: '',
    searchDisabled: false,
  };
};

export function productLabelsReducer(state: ProductLabelsState, action: Utils.DispatchAction<StateActions>): ProductLabelsState {
  switch (action.type) {
    case StateActions.SET_DATA:
      return { ...state, data: action.params };
    case StateActions.SET_EDITING_KEY:
      return { ...state, editingKey: action.params };
    case StateActions.SET_SELECTED_LABEL:
      return { ...state, selectedLabel: action.params };
    case StateActions.SET_FORM_VALUES:
      return { ...state, formValues: action.params };
    case StateActions.SET_PAGINATION:
      return { ...state, pagination: action.params };
    case StateActions.SET_LOADING_DATA:
      return { ...state, loadingData: action.params };
    case StateActions.SET_ADD_MODAL_VISIBLE:
      return { ...state, addModalVisible: action.params };
    case StateActions.SET_CONFIRMATION_MODAL_VISIBLE:
      return { ...state, confirmationModalVisible: action.params };
    case StateActions.RESET_FORM:
      return { ...state, formValues: { ...formValuesInitialState } };
    case StateActions.SET_FILTERED_DATA:
      return { ...state, filteredData: action.params };
    case StateActions.REPLACE_INLINE_EDIT:
      return replaceInlineEdit(state, action.params);
    case StateActions.SET_LABEL_TO_DELETE:
      return { ...state, selectedLabel: action.params, confirmationModalVisible: true };
    case StateActions.SET_IS_SAVING:
      return { ...state, isSaving: action.params };
    case StateActions.SET_SEARCH_DISABLED:
      return { ...state, searchDisabled: action.params };
    default:
      return state;
  }
}

export const ProductLabelsScreenContext = createContext<[ProductLabelsState, React.Dispatch<Utils.DispatchAction<StateActions>>] | [undefined, undefined]>([undefined, undefined]);


export const saveLabel = (dispatch: Dispatcher, value: { ProductLabelName: string; ProductLabelDesc: string; }, id: string): Promise<void> => new Promise<void>((resolve, reject) => {
  dispatch({ type: StateActions.SET_IS_SAVING, params: true });
  editLabel(value, id)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    }).finally(() => dispatch({ type: StateActions.SET_IS_SAVING, params: false }));
});
