import moment from "moment";
import { groupByKeys, getChannelInfo, isEmpty, isString } from "./util";


export const summaryData = async (data: any) => {
  let summary: any = [];
  let summaryHour: any = [];
  let chartData: any = [];
  let hourChartData: any = [];
  let workingObj: any = {};
  let daySummary: any = {
    channelOrderCount: 0,
    channelOrderQty: 0,
    channelOrderAmt: 0,
    soCount: 0,
    soQty: 0,
    soAmount: 0,
    shipmentCount: 0,
    shipmentQty: 0,
    shipmentAmount: 0,
    invoiceCount: 0,
    invoiceQty: 0,
    invoiceAmount: 0,
    returnCount: 0,
    returnQty: 0,
    returnAmount: 0,
    poCount: 0,
    poQty: 0,
    poAmount: 0,
    poReceiveCount: 0,
    poReceiveQty: 0,
    poReceiveAmount: 0,
    ptCount: 0,
    ptQty: 0,
    ptAmount: 0,
    dateFrom: '',
  };

  let obj: any = {
    hour: 0,
    channelOrderCount: 0,
    channelOrderQty: 0,
    channelOrderAmt: 0,
    soCount: 0,
    soQty: 0,
    soAmount: 0,
    shipmentCount: 0,
    shipmentQty: 0,
    shipmentAmount: 0,
    invoiceCount: 0,
    invoiceQty: 0,
    invoiceAmount: 0,
    returnCount: 0,
    returnQty: 0,
    returnAmount: 0,
    poCount: 0,
    poQty: 0,
    poAmount: 0,
    poReceiveCount: 0,
    poReceiveQty: 0,
    poReceiveAmount: 0,
    ptCount: 0,
    ptQty: 0,
    ptAmount: 0,
  };

  // #region Channel + Channel Account Summary --------------------------------------------------------

  // Group Summary Data By ChannelNum & Channel Account Num
  let gropuByChannel = groupByKeys(data, ['channelNum', 'channelAccountNum']);
  const gropuByChannelValues = Object.values(gropuByChannel);

  // sort channel
  gropuByChannelValues.sort((a: any, b: any) => {
    if (isString(a?.[0]?.channelAccountName) && isString(b?.[0]?.channelAccountName)) {
      return a[0].channelAccountName.localeCompare(b[0].channelAccountName);
    }
    return 0;
  });

  // Calculate Summary Grouped By Channel Num & Channel Account Num
  for (let i = 0; i < Object.values(gropuByChannelValues).length; i++) {
    const data = Object.values(gropuByChannelValues)[i];
    let { channelName, channelAccountName } = await getChannelInfo(data[0].channelNum, data[0].channelAccountNum);

    workingObj = {
      ...obj,
      channelName,
      channelAccountName,
      channelNum: data[0].channelNum,
      channelAccountNum: data[0].channelAccountNum,
      dateFrom: moment(data[0].dateFrom).format('MM/DD/YYYY'),
    };
    getSummaryData(data, workingObj, summary, daySummary, chartData);
  };
  // #endregion Channe + Channel Account Summary ------------------------------------------------------

  // #region Hourly Summary ---------------------------------------------------------------------------
  // Calculate Summary Data By Hour
  for (let i = 0; i < data.length; i++) {
    const dat = data[i];
    let { channelName, channelAccountName } = await getChannelInfo(dat.channelNum, dat.channelAccountNum);

    workingObj = {
      ...obj,
      channelName,
      channelAccountName,
      channelNum: dat.channelNum,
      channelAccountNum: dat.channelAccountNum,
      dateFrom: moment(dat.dateFrom).format('MM/DD/YYYY'),
    };

    // Group Data By Hour
    let groupByhour = groupByKeys(dat.hourSummary, ['hour']);

    // Calculate Summary Data for Each Hour
    // eslint-disable-next-line
    Object.values(groupByhour).forEach((items: any) => {
      getHourlySummaryData(items, workingObj, summaryHour, [], []);
    });
  };

  // Group Hour Summary By Channel
  let tempGroupByHour = groupByKeys(
    summaryHour,
    ['channelAccountName', 'channelName']
  );
  summaryHour = Object.values(tempGroupByHour);
  // sort channel
  summaryHour.sort((a: any, b: any) => {
    if (isString(a?.[0]?.channelAccountName) && isString(b?.[0]?.channelAccountName)) {
      return a[0].channelAccountName.localeCompare(b[0].channelAccountName);
    }
    return 0;
  });

  // Sort Data by Hour
  summaryHour.forEach((item: any) => {
    item.sort((a: any, b: any) => { return a.hour - b.hour; });
  });

  // Sort Hour Data According to Channels Order
  for (let i = 1; i < summary.lenth; i++) {
    let hourIndex = summaryHour.findIndex((item: any) => {
      return (item.channelAccountName === summary[i].channelAccountName && item.channelNum === summary[i].channelNum);
    });
    if (hourIndex !== i - 1) {
      let temp = summaryHour[hourIndex];
      summaryHour[hourIndex] = summaryHour[i - 1];
      summaryHour[i - 1] = temp;
    }
  }

  // Create Count Summary Chart Data For Each Hour
  summaryHour.forEach((item: any) => {
    let tempChar: any = [];
    item.forEach((hourData: any) => {
      tempChar.push(createCountListData(hourData));
    });
    hourChartData.push(tempChar);
  });
  //#endregion Hourly Summary -----------------------------------------------------------------------------------

  // Add Total Day Summary List and Chart in the front of each list 
  summary.unshift(daySummary);
  chartData.unshift(createCountListData(daySummary));
  return [summary, chartData, summaryHour, hourChartData];
}

// Calculate Total Summary Data by Channel
const getSummaryData = (channelSummaryData: any, obj: any, summary: any, daySummary: any, chartData: any) => {
  let elem: any = { ...obj };

  channelSummaryData.forEach((item: any) => {
    item.hourSummary.forEach((item: any) => {
      calculateSummary(item, elem, daySummary);
    });
  });
  summary.push(elem);
  chartData.push(createCountListData(elem));
}

// Obtain Hourly Summary Data By Channel 
const getHourlySummaryData = (hourlySummaryData: any, obj: any, summary: any, daySummary: any, chartData: any) => {
  let elem: any = { ...obj };

  hourlySummaryData.forEach((item: any) => {
    calculateSummary(item, elem, daySummary);
  });
  summary.push(elem);
}


const calculateSummary = (item: any, elem: any, daySummary: any) => {
  let { type, hour, cnt, qty, amt } = item;

  switch (type) {
    case 'SalesOrder':
      elem.soCount += cnt;
      elem.soQty += qty;
      elem.soAmount = elem.soAmount + amt;
      elem.hour = hour;
      // if channel account num is 0, then means no channel, no count as channel order
      if (!(elem.channelAccountNum === 0 && elem.channelNum === 0)) {
        elem.channelOrderCount += cnt;
        elem.channelOrderQty += qty;
        elem.channelOrderAmt = elem.channelOrderAmt + amt;
        if (!isEmpty(daySummary)) {
          daySummary.channelOrderCount += cnt;
          daySummary.channelOrderQty += qty;
          daySummary.channelOrderAmt = daySummary.channelOrderAmt + amt
        }
      }

      daySummary.soCount += cnt;
      daySummary.soQty += qty;
      daySummary.soAmount = daySummary.soAmount + amt;
      break;
    case 'Invoice':
      elem.invoiceCount += cnt;
      elem.invoiceQty += qty;
      elem.invoiceAmount = elem.invoiceAmount + amt;
      elem.hour = hour;

      daySummary.invoiceCount += cnt;
      daySummary.invoiceQty += qty;
      daySummary.invoiceAmount = daySummary.invoiceAmount + amt;
      break;
    case 'Shipment':
      elem.shipmentCount += cnt;
      elem.shipmentQty += qty;
      elem.shipmentAmount = elem.shipmentAmount + amt;
      elem.hour = hour;

      daySummary.shipmentCount += cnt;
      daySummary.shipmentQty += qty;
      daySummary.shipmentAmount = daySummary.shipmentAmount + amt;
      break;
    case 'Fulfillment':
      elem.ptCount += cnt;
      elem.ptQty += qty;
      elem.ptAmount = elem.ptAmount + amt;
      elem.hour = hour;

      daySummary.ptCount += cnt;
      daySummary.ptQty += qty;
      daySummary.ptAmount = daySummary.ptAmount + amt;
      break;

    case 'PurchaseOrder':
      elem.poCount += cnt;
      elem.poQty += qty;
      elem.poAmount = elem.poAmount + amt;
      elem.hour = hour;

      daySummary.poCount += cnt;
      daySummary.poQty += qty;
      daySummary.poAmount = daySummary.poAmount + amt;
      break;
    case 'PoReceive':
      elem.poReceiveCount += cnt;
      elem.poReceiveQty += qty;
      elem.poReceiveAmount = elem.poReceiveAmount + amt;
      elem.hour = hour;

      daySummary.poReceiveCount += cnt;
      daySummary.poReceiveQty += qty;
      daySummary.poReceiveAmount = daySummary.poReceiveAmount + amt;
      break;
    case 'Return':
      elem.returnCount += cnt;
      elem.returnQty += qty;
      elem.returnAmount = elem.returnAmount + amt;
      elem.hour = hour;

      daySummary.returnCount += cnt;
      daySummary.returnQty += qty;
      daySummary.returnAmount = daySummary.returnAmount + amt;
      break;
    default:
      break;
  }
}


// Create Chart Data
const createCountListData = (data: any) => {
  let {
    channelOrderCount,
    soCount,
    shipmentCount,
    invoiceCount,
    returnCount,
    poCount,
    poReceiveCount,
    ptCount
  } = data;


  return ([
    ['Lifecycle', 'Channel Order', 'S/O', 'Pick Ticket', 'Shipment', 'Invoice', 'Return', 'P/O', 'P/O Receive'],
    ['Channel Order', channelOrderCount, 0, 0, 0, 0, 0, 0, 0],
    ['S/O', 0, soCount, 0, 0, 0, 0, 0, 0],
    ['Pick Ticket', 0, 0, ptCount, 0, 0, 0, 0, 0],
    ['Shipment', 0, 0, 0, shipmentCount, 0, 0, 0, 0],
    ['Invoice', 0, 0, 0, 0, invoiceCount, 0, 0, 0],
    ['Return', 0, 0, 0, 0, 0, returnCount, 0, 0],
    ['P/O', 0, 0, 0, 0, 0, 0, poCount, 0],
    ['P/O Receive', 0, 0, 0, 0, 0, 0, 0, poReceiveCount],
  ]);
}