const setFormFilter = (filter: any) => ({
  type: 'SET_FILTER',
  filter,
});

const clearFilterByKey = (key: string, value: any) => ({
  type: 'CLEAR_FILTER_BY_KEY',
  key,
  value
});

const clearAllFilter = () => ({
  type: 'CLEAR_ALL_FILTER'
});

const setViewFilter = (viewFilter: string) => ({
  type: 'SET_VIEW_FILTER',
  viewFilter,
});

export default {
  setFormFilter,
  clearFilterByKey,
  clearAllFilter,
  setViewFilter,
};
