import { CloudDownloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Spin,
  Button,
  Modal,
  Card,
  Typography,
  Space,
  Switch,
  Col,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { getChannelExportViewInfo } from "../../services/channels";
import { useExecutePromise } from "../../util/hooks";
import {
  useExportChannelAccountFileMutation,
  useExportChannelAccountFilterMutation,
} from "../../redux/api/exports";
// import moment from "moment";
// import { downloadXlsxFromCsv } from "../../util/files";

const { Text, Title } = Typography;
// const { TabPane } = Tabs;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

type ExportItemType = {
  productMappingNum: number;
  productMappingName: string;
  mappingContentId: string;
  isMapped: boolean;
  type?: string;
};

// const ItemRow = styled(Row)`
//   padding-left: 24px;
//   margin-top: 8px;
//   align-items: center;
// `;

// const ItemText = styled(Text)`
//   width: 200px;
//   padding-left: 24px;
// `;

const CardSpace = styled(Space)`
  width: 850px;
`;

// use to hide based on env
// const isDevEnv = () => {
//   const devEnvs = ['development', 'local'];

//   return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
// };

const ExportSearchButton: React.FC<{
  data?: ExportItemType;
  account: ChannelAccount;
  mappingType: number;
  disabled: boolean;
  templateName: string;
  templateNum: any;
  onCallback?: (data: any) => void;
  getSearchOptions?: any; // get search options fn
  exportAs?: string;
}> = ({
  data,
  account,
  mappingType,
  onCallback,
  disabled,
  templateName,
  getSearchOptions,
  templateNum,
  exportAs,
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportChannelAccountFilterMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback(data);
  }, [data, onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      if (typeof operationResponse === "object") {
        handleSuccess();
      }
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const payload = {
    fileOperation: 20004,
    channelAcctJsonFileId: data&&templateNum ? `${data.productMappingNum}` : undefined,
    exportFile: {
      channelNum: account.ChannelNum,
      channelAccountNum: account.ChannelAccountNum,
      mappingType,
      reason: "",
      isForAllSku: true,
      isFileOperation: false,
      operationSetNum: 0,
      TemplateName: templateNum ? templateName : '',
    },
    forSeller:1,
    exportQuery: getSearchOptions?.call(null) || {},
    exportAs,
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(payload);
      }}
    >
      Export Search Result
      <CloudDownloadOutlined />
    </Button>
  );
};

const ExportButton: React.FC<{
  data?: ExportItemType;
  account: ChannelAccount;
  mappingType: number;
  disabled: boolean;
  templateName: string;
  templateNum: any;
  onCallback?: (data: any) => void;
  exportAs?: string;
  isForAllSku: boolean;
  CentralProductNumList?: any[];
  title: string;
}> = ({
  data,
  account,
  mappingType,
  onCallback,
  disabled,
  templateName,
  templateNum,
  exportAs,
  isForAllSku,
  CentralProductNumList,
  title
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportChannelAccountFileMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback(data);
  }, [data, onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      if (typeof operationResponse === "object") {
        handleSuccess();
      }
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const body = {
    fileOperation: 20004,
    channelAcctJsonFileId: data&&templateNum ? `${data.productMappingNum}` : undefined,
    channelNum: account.ChannelNum,
    channelAccountNum: account.ChannelAccountNum,
    mappingType,
    reason: "",
    isForAllSku,
    isFileOperation: false,
    forSeller:1,
    operationSetNum: 0,
    TemplateName: templateNum ? templateName : '',
    exportAs,
    CentralProductNumList,
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(body);
      }}
    >
      {title}
      <CloudDownloadOutlined />
    </Button>
  );
};

// TODO: export api
const ExportDialog: React.FC<{
  account: ChannelAccount;
  allData: any[];
  columns: any[];
  selectedRows: any[];
  fileName: string;
  filterArr: string[];
  onClose: (data?: ExportItemType) => void;
  getSearchOptions: any; // get search options fn
  templateName: string;
}> = (props) => {
  const {
    allData,
    selectedRows,
    account,
    onClose,
    getSearchOptions,
    templateName,
  } = props;

  const getPayloadInfo = useCallback(async (): Promise<{
    mappedProductTemplates: ExportItemType[];
    mappedInventoryTemplates: ExportItemType[];
  }> => {
    if (!account || !account.ChannelAccountNum) {
      return { mappedProductTemplates: [], mappedInventoryTemplates: [] };
    }
    const res = await getChannelExportViewInfo(account.ChannelAccountNum);
    return res
      ? res
      : { mappedProductTemplates: [], mappedInventoryTemplates: [] };
  }, [account]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, loading] = useExecutePromise(getPayloadInfo, {
    mappedProductTemplates: [],
    mappedInventoryTemplates: [],
  });
  const loadingData = useMemo(() => loading, [loading]);
  // const [selectedRadio, setSelectedRadio] = useState<any>();
  const [templateNum, setTemplateNum] = useState<any>(0)
  const [exportCsv, setExportCsv] = useState(false);
  const history = useHistory();



  return (
    <Modal
      visible
      width={900}
      title={
        <Row justify="center">
          <Title level={4}>Export Vendor Channel Attributes for Seller</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
    >
      <Spin spinning={loadingData}>
        <CardSpace direction="vertical">
          <Row>
            <Col style={{ marginRight: "40px" }}>
              <Text strong>Channel Account:</Text>
              <Text style={{marginLeft: 6}}>{account.ChannelAccountName}</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Text strong>{`Template Name: ${templateName}`}</Text> */}
              <Text strong>Template Name:</Text>
              <Select style={{width: 200, marginLeft: 6}} dropdownMatchSelectWidth={false} value={templateNum} onChange={setTemplateNum}>
                <Select.Option value={0}>All</Select.Option>
                {
                  templateName && <Select.Option value={templateName}>{templateName}</Select.Option>
                }
              </Select>
            </Col>
          </Row>
          {/* <Tabs type="editable-card" hideAdd>
            <TabPane tab="For Bulk Edit" key="Attributes" closable={false}> */}
          <Col style={{ padding: "5px" }}>
            <Space>
              <Text strong>Export As</Text>
              <Switch
              checked={exportCsv}
              onChange={setExportCsv}
              checkedChildren="CSV"
              unCheckedChildren="XLSX"
              style={{
                backgroundColor: "#006dff",
              }}
            />
            </Space>
          </Col>
          <Card
            size="small"
            bodyStyle={{
              alignItems: "center",
              display: "flex",
              justifyContent: "start",
              gap: 15,
            }}
          >
            <ExportButton
              disabled={selectedRows.length === 0}
              account={account}
              mappingType={64}
              templateName={templateName}
              templateNum={templateNum}
              isForAllSku={false}
              title="Export Selected"
              CentralProductNumList={selectedRows.map(i=>i.ProductId)}
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
            <ExportButton
              disabled={allData.length === 0}
              account={account}
              mappingType={64}
              templateName={templateName}
              templateNum={templateNum}
              isForAllSku={false}
              title="Export Current Page"
              CentralProductNumList={allData.map(i=>i.ProductId)}
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
            {/* <Button
              type="primary"
              disabled={selectedRows.length === 0 || templateNum === 0}
              onClick={() => handleDownload(selectedRows, filterArr, fileName)}
            >
              Export Selected
            </Button>
            <Button
              type="primary"
              disabled={allData.length === 0 || templateNum === 0}
              onClick={() => handleDownload(allData, filterArr, fileName)}
            >
              Export Current Page
            </Button> */}
            <ExportSearchButton
              disabled={false}
              account={account}
              mappingType={64}
              getSearchOptions={getSearchOptions}
              templateName={templateName}
              templateNum={templateNum}
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
            <ExportButton
              disabled={false}
              account={account}
              mappingType={64}
              templateName={templateName}
              templateNum={templateNum}
              isForAllSku={true}
              title="Export All"
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
          </Card>
          <Row justify="end">
            <Button  onClick={() => onClose()}>
              Cancel
            </Button>
          </Row>
        </CardSpace>
      </Spin>
    </Modal>
  );
};

export default ExportDialog;
