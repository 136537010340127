import {
  Modal,
  Spin,
  Row,
  Col,
  message,
  Table,
  Space,
  Button,
  Popconfirm,
} from "antd";
// import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import {
  fetchContentSourceType,
  sortContentSourceType,
  deleteContentSourceType,
} from "../../services/copywriting";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import AddEditDialog from "./AddEditGroupDialog";

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  formValues: any;
  visible: boolean;
}

const HoverDangerButton = styled(Button)`
  &:hover {
    color: red;
  }
`;

function ModalForm({ visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [current, setCurrent] = useState<any>();
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);

  const columns = [
    {
      title: "Group Name",
      dataIndex: "enumName",
      editable: false,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: false,
    },
    {
      title: "Action",
      render: (value: string, record: any, index: number) => {
        if (index > 3)
          return (
            <Space>
              <Button
                type="link"
                disabled={record.isSystem}
                onClick={() => {
                  setCurrent(record);
                  setAddEditDialogVisible(true);
                }}
              >
                Edit
              </Button>
              {record.isSystem ? (
                <HoverDangerButton type="link" disabled={record.isSystem}>
                  Delete
                </HoverDangerButton>
              ) : (
                <Popconfirm
                  title={`Are you sure you want to delete this group ?`}
                  onConfirm={() => deleteGroup(record)}
                >
                  <HoverDangerButton type="link">Delete</HoverDangerButton>
                </Popconfirm>
              )}
            </Space>
          );
      },
    },
    {
      render: (value: string, row: any, index: number) => {
        if (index > 3) {
          return (
            <Space>
              <CaretUpOutlined
                onClick={() => {
                  reorderList(row, true);
                }}
              />
              <CaretDownOutlined
                onClick={() => {
                  reorderList(row, false);
                }}
              />
            </Space>
          );
        }
      },
    },
  ];

  const deleteGroup = async (record: any) => {
    try {
      const { isSuccess, message: resMsg = "" } = await deleteContentSourceType(
        record.rowNum
      );
      if (isSuccess) {
        message.success("Delete Successfully!");
        getGroupList();
      } else {
        message.error(resMsg || "Delete group failed!");
      }
    } catch (error) {
      message.error(`There was an error: ${error}`);
    }
  };

  const getGroupList = async () => {
    setProcessing(true);
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = "",
      } = await fetchContentSourceType();
      if (isSuccess) {
        setList(data);
      } else {
        message.error(resMsg || "No results found");
      }
    } catch (error) {
      console.log("error", error);
    }
    setProcessing(false);
  };

  const reorderList = async (record: any, ascend: boolean) => {
    try {
      const { isSuccess, message: resMsg = "" } = await sortContentSourceType(
        record,
        ascend
      );
      if (isSuccess) {
        getGroupList();
      } else {
        message.error(resMsg || "Reorder failed");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={
        <Row justify="space-between" align="middle">
          <Col>{`Content Resources - Group`}</Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setCurrent(undefined);
                setAddEditDialogVisible(true);
              }}
            >
              <PlusOutlined />
              Add
            </Button>
          </Col>
        </Row>
      }
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={1000}
      footer={
        <Button
          onClick={() => {
            onSuccess();
            onClose();
          }}
        >
          <CloseOutlined />
          Close
        </Button>
      }
    >
      <Spin spinning={processing}>
        <Table
          size="middle"
          columns={columns}
          dataSource={list}
          pagination={false}
          style={{ height: 400, overflowY: "auto" }}
          rowKey="rowNum"
        />
      </Spin>
      {addEditDialogVisible && (
        <AddEditDialog
          visible
          field={current}
          mode={current ? "Edit" : "New"}
          onClose={() => setAddEditDialogVisible(false)}
          onRefresh={() => {
            setAddEditDialogVisible(false);
            getGroupList();
          }}
        />
      )}
    </Modal>
  );
}

export default ModalForm;
