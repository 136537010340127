import React from 'react';
import { Row, Table } from 'antd';

type Props = {
  selectedSourceName: string;
  maskScreen: Function;
  resultList: any[];
};

const AttributeSelector = (props: Props) => {
  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Attribute',
    },
    {
      dataIndex: 'copywritingElementName',
      title: 'Content Resources',
    },
    // {
    //   align: 'center' as 'center',
    //   title: '',
    //   render() {
    //     return (
    //       <Button className="primary-text-btn" type="text">
    //         View
    //       </Button>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Row align="middle" className="section-bar">
            <span
              className="section-title"
              style={{ color: 'green' }}
            >{`The following fields were updated with the value from ${props.selectedSourceName}`}</span>
        </Row>
        <div className="section" style={{marginTop: 0}}>
          <Table
            columns={productBasicColumns}
            dataSource={props.resultList}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
      </div>
    </>
  );
};

export default AttributeSelector;
