import { Form, Input, InputNumber, Modal, notification, Select, Spin } from 'antd';
import { ModalProps } from 'antd/es/modal';
import React, { useCallback, useState } from 'react';
import { Permissions } from '../../constants/enums/permissions';
import { addMedia } from '../../services/products';
import { MediaType } from '../../types/enums';
import { enumToObjects } from '../../util/enums';
import FormButtons from '../common/FormButtons';
import { ModalTitle } from '../common/styledComponents';

interface ModalFormProps extends ModalProps {
  onOk?: (e?: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void;
  onError?: () => void;
}

const ModalForm: React.FC<ModalFormProps> = (
  {
    onOk,
    onCancel,
    onError,
    ...modalProps
  },
) => {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  const onCancelOverride = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e);
  };

  const submit = useCallback(async (values: any) => {
    setProcessing(true);
    try {
      await addMedia(values);
      form.resetFields();
      notification.success({ message: 'Media placement saved successfully' });
      onOk?.(values);
    } catch (e) {
      notification.error({ message: 'There was an error processing your request' });
      onError?.();
    } finally {
      setProcessing(false);
    }
  }, [form, onOk, onError]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={submit}
    >
      <Modal
        {...modalProps}
        title={(
          <ModalTitle>
            Product Media Placement
          </ModalTitle>
        )}
        style={{ minWidth: 400 }}
        footer={(
          <FormButtons
            onCancel={onCancel}
            permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
            editingMode
            onSave={form.submit}
            style={{justifyContent: 'flex-end'}}
          />
        )}
        maskClosable={false}
        closable={false}
        onCancel={onCancelOverride}
        forceRender
      >
        {/* eslint-enable react/jsx-props-no-spreading */}
        <Spin spinning={processing}>
          <Form.Item
            name="MediaPlacementName"
            label="Name"
            rules={[
              { required: true, message: 'This field is required' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="DispSequenceNum"
            label="Display Sequence"
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'number', required: true, min: 0, message: 'Value must be equal or greater than 0' },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="MediaType"
            label="Media Type"
            rules={[
              { required: true, message: 'This field is required' },
            ]}
          >
            <Select>
              {
                enumToObjects({ choices: MediaType }).map((choice) => (
                  <Select.Option
                    key={choice.key}
                    value={choice.value}
                  >
                    {choice.label}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

        </Spin>
      </Modal>
    </Form>
  );
};

export default ModalForm;
