import React from 'react';
import { Checkbox, Row, Space, Table, Typography, Radio, Select } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  uploadResponse: any;
  destinationNotEmptyOverwrite: boolean;
  childrenNotEmptyOverwrit: boolean;
  sourceEmptyIgnoreUpdate: boolean;
  mappingList: any[];
  setMappingList: (data: any[]) => void;
  setDestinationNotEmptyOverwrite: (data: boolean) => void;
  setSourceEmptyIgnoreUpdate: (data: boolean) => void;
  setChildrenNotEmptyOverwrit: (data: boolean) => void;
  setChildrenEmptyOverwrit:(data: boolean) => void;
  childrenEmptyOverwrit: boolean;
  copywritingList: any[];
  fileFormats: { [key: string]: string };
  onShowChooseMapping: () => void;
  chooseMapping: string;
};

const AttributeSelector = (props: Props) => {
  const {
    uploadResponse,
    destinationNotEmptyOverwrite,
    childrenNotEmptyOverwrit,
    sourceEmptyIgnoreUpdate,
    mappingList,
    childrenEmptyOverwrit,
    setMappingList,
    setDestinationNotEmptyOverwrite,
    setSourceEmptyIgnoreUpdate,
    setChildrenNotEmptyOverwrit,
    setChildrenEmptyOverwrit,
    copywritingList,
    fileFormats,
    // onShowChooseMapping,
    // chooseMapping,
  } = props;
  // copywritingElementNum: 10001
  // defaultValue: "default title"
  // displaySeq: 1
  // elementDataType: 0
  // elementName: "Title"
  // elementType: 0
  // masterAccountNum: 10001
  // note: "note"
  // optionList: ""
  // pattern: ""
  // profileNum: 10001
  // relationships: []
  // tag: "tag"
  // updateBy: ""
  // updateOn: "0001-01-01T00:00:00"

  const columns = [
    {
      dataIndex: 'headerName',
      title: 'Source',
    },
    {
      dataIndex: 'copywritingElementNum',
      title: 'Destination',
      render(text: string, data: any, index: number) {
        return (
          <Select
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
            value={data.copywritingElementNum}
            onChange={(value) => {
              setMappingList(
                [...mappingList].map((i: any) =>
                  i.id === data.id ? { ...i, copywritingElementNum: value } : i,
                ),
              );
            }}
          >
            <Select.Option key={0} value={0}>
              Ignore
            </Select.Option>
            {copywritingList.map((i) => (
              <Select.Option key={i.copywritingElementNum} value={i.copywritingElementNum}>
                {i.elementName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      dataIndex: 'id',
      title: 'Addition Action',
      render(text: string, data: any, index: number) {
        return (
          <Space>
            <Checkbox
              checked={data.removeHtmlTags}
              onChange={(e) => {
                setMappingList(
                  [...mappingList].map((i: any) =>
                    i.id === data.id ? { ...i, removeHtmlTags: e.target.checked } : i,
                  ),
                );
              }}
            >
              Remove Html Tags
            </Checkbox>
            <Checkbox
              checked={data.removeNewLine}
              onChange={(e) => {
                setMappingList(
                  [...mappingList].map((i: any) =>
                    i.id === data.id ? { ...i, removeNewLine: e.target.checked } : i,
                  ),
                );
              }}
            >
              Remove New Line
            </Checkbox>
            <Checkbox
              checked={data.trimText}
              onChange={(e) => {
                setMappingList(
                  [...mappingList].map((i: any) =>
                    i.id === data.id ? { ...i, trimText: e.target.checked } : i,
                  ),
                );
              }}
            >
              Trim Text
            </Checkbox>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Space direction="vertical">
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>
                File Format: <Text>{fileFormats[uploadResponse.operation]}</Text>
              </Text>
              <Text strong style={{ marginLeft: 16 }}>
                File Name: <Text>{uploadResponse.originalFileName}</Text>
              </Text>
            </Space>
          </Row>
          <Row justify="start">
            <Text strong style={{ color: '#096dd9' }}>
              Options:
              {/* {<Text>{chooseMapping}</Text>} */}
            </Text>
            {/* <Space>
              <Button disabled>Use Auto-mapping</Button>
              <Button onClick={() => onShowChooseMapping()}>Use Existing Mapping</Button>
            </Space> */}
          </Row>
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If source doesn't have value (empty):`}</Text>
              <Radio.Group
                onChange={(e) => setSourceEmptyIgnoreUpdate(e.target.value)}
                value={sourceEmptyIgnoreUpdate}
              >
                <Radio value={false}>Use empty value to update destination</Radio>
                <Radio value>{`Ignore (don't update destination)`}</Radio>
              </Radio.Group>
            </Space>
          </Row>
          <Row>
            <Space style={{ width: '100%' }}>
              <Text strong>{`If destination has value (Not empty):`}</Text>
              <Radio.Group
                onChange={(e) => setDestinationNotEmptyOverwrite(e.target.value)}
                value={destinationNotEmptyOverwrite}
              >
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>

          {/* <Row justify="start">
            <Text strong style={{ color: '#096dd9' }}>
              Children:
            </Text>
          </Row> */}
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If children has value (not empty):`}</Text>
              <Radio.Group
                onChange={(e) => setChildrenNotEmptyOverwrit(e.target.value)}
                value={childrenNotEmptyOverwrit}
              >
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>
          <Row>
            <Space style={{ width: '100%' }} align="start">
              <Text strong>{`If children doesn't has value:`}</Text>
              <Radio.Group
                onChange={(e) => setChildrenEmptyOverwrit(e.target.value)}
                value={childrenEmptyOverwrit}
              >
                <Radio value={true}>Overwrite</Radio>
                <Radio value={false}>Don't Overwrite</Radio>
              </Radio.Group>
            </Space>
          </Row>
          {/* {
            childrenNotEmptyOverwrit && <Row>
              <Text type="danger">Empty value from parent will be ignored. If you need to update child value to empty, you need to import at the child level separtely.</Text>
            </Row>
          } */}

          <Table
            style={{ marginTop: 32 }}
            columns={columns}
            dataSource={mappingList}
            pagination={false}
            rowKey="id"
          />
        </Space>
      </div>
    </>
  );
};

export default AttributeSelector;
