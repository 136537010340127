import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Typography } from "antd";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { CloseOutlined } from "@ant-design/icons";

import ModalDialog from "../../../../components/common/ModalDialog";
import { getCopywritingElements } from "../../../../services/essentialInfo";

interface WhiteListProps {
  SourceEnum:any;
  visible: boolean;
  onHide: () => void;
  onRefresh: (data: any) => void;
}

const SelectContentSourceComponent = (props: WhiteListProps) => {
  const { visible, onHide, onRefresh, SourceEnum } = props;
  const initialGCStyle = { height: "calc(100vh - 260px)", maxHeight: 600 };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState({});
  const [seletedRow, setSelectedRow] = useState<any[]>([]);
  const [gridCtnStyle, setGridCtnStyle] = useState<StringKAnyVPair>(
    initialGCStyle
  );

  const gridCtnRef = React.useRef<any>(null);

  const getAttributeData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getCopywritingElements();
      setLoading(false);
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const filterValue = [
    { name: "fieldName", operator: "contains", type: "string", value: "" },
    { name: "source", operator: "contains", type: "string", value: "" },
  ];

  const columns = [
    {
      name: "fieldName",
      header: "Name",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'source',
      header: "Source",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
      render({ data }: { data: any }) {
        if (data.source >= 0) {
          return SourceEnum[data.source]
        }
  
        return '';
      },
    }
  ];

  const handleNext = async () => {
    if (seletedRow.length > 0) {
      onRefresh(seletedRow[0]);
    }
  };

  const handleAddPlaceHolder = async () => {
    onRefresh({
      isPlaceHolder: true,
    });
  };

  const getDialogDom = () => {
    const gridCtn = gridCtnRef?.current;

    if (gridCtn) {
      return gridCtn.parentNode;
    }

    return null;
  };

  const onFullscreen = (full: boolean) => {
    const dialogBody = getDialogDom();

    if (!dialogBody) return;
    //console.log('full', full, dialogBody, dialogBody.parentNode);
    if (full) {
      setGridCtnStyle({
        height: "calc(100vh - 144px)",
        maxHeight: "",
      });
      // maybe there need to detect the window environment
      dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
        window.innerWidth - 16
      }px`;
      setTimeout(() => {
        dialogBody.style.height = "auto";
        dialogBody.parentNode.style.height = "auto";
        //dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
      }, 0);
      setTimeout(() => {
        dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
          window.innerWidth - 2
        }px`;
      }, 200);
    } else {
      setGridCtnStyle(initialGCStyle);
    }
  };

  const onSelectionChange = useCallback(({ data, selected }) => {
    setSelected(selected);
    setSelectedRow(data);
  }, []);

  useEffect(() => {
    getAttributeData();
  }, [getAttributeData]);

  return (
    <ModalDialog
      title="Add Product Features Field"
      centered
      closable={false}
      closeButton
      fullscreen={false}
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      //onCancel={() => onHide()}
      onClose={() => onHide()}
      onFullscreen={onFullscreen}
      getContainer={false}
      footer={
        <>
          <Row justify="center">
            <Space>
              <Typography.Text>
                Selected Row :{" "}
                <Typography.Text>
                  {seletedRow.length > 0 ? `${seletedRow[0].fieldName}` : ""}
                </Typography.Text>
              </Typography.Text>
            </Space>
          </Row>
          <Row justify="space-between">
            <Space>
              <Button
                key="submit"
                type="primary"
                onClick={handleAddPlaceHolder}
              >
                Add Placeholder
              </Button>
            </Space>

            <Space>
              <Button
                key="submit"
                type="primary"
                onClick={handleNext}
                disabled={seletedRow.length === 0}
              >
                Next
              </Button>
              <Button onClick={() => onHide()} icon={<CloseOutlined />}>
                Cancel
              </Button>
            </Space>
          </Row>
        </>
      }
    >
      <div ref={gridCtnRef} style={gridCtnStyle}>
        <ReactDataGrid
          dataSource={data}
          columns={columns}
          loading={loading}
          defaultFilterValue={filterValue}
          onSelectionChange={onSelectionChange}
          selected={selected}
          idProperty="attributeId"
          style={{
            height: "100%",
          }}
          virtualized
        />
      </div>
    </ModalDialog>
  );
};

export default SelectContentSourceComponent;
