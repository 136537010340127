import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Space, Table, Typography, } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SearchBar, { SearchBarComponent, SearchFields } from '../../../../components/common/SearchBar';
import Spacer from '../../../../components/common/Spacer';
import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import { FormsContext } from '../../details/context';

const DCTableWrapper = styled.div`
  & .ant-form-item {
    margin-bottom: 0;
  }

  & .ant-table-cell {
    height: 46px;
    padding: 0px 10px;
  }
`;

const NoteBox = styled.div`
  border: 1px solid #d9d9d9;
  border-top: 6px solid #d9d9d9;
  border-radius: 4px;
  color: ${theme['@danger-color']};
  padding: 10px;
`

const dcSearchFields: SearchFields<Entities.DistributionCenterWarehouseMapping> = [
  'Name',
  'Code',
  'City',
];

type TableDCMapping = Entities.DistributionCenterWarehouseMapping & { id: string };

type ModalContentProps = {
  onApply?: () => void;
  modalVisible: boolean;
  setModalVisible: Function;
  distributionCenters?: any[];
  setDistributionCenters?: Function;
  loading: boolean;
  inventoryWarehouseTableColumns: any[];
  onlyTable?: boolean;
  onModalClose?: Function;
}

const ModalContent = (props: ModalContentProps) => {
  const {
    onApply,
    setModalVisible,
    distributionCenters = [],
    setDistributionCenters,
    loading,
    inventoryWarehouseTableColumns,
    onlyTable = false,
    onModalClose,
  } = props;
  const searchRef = useRef<SearchBarComponent>(null);
  const form = useContext(FormsContext)
  const [filteredData, setFilteredData] = useState(distributionCenters);
  const [seletedRows, setSeletedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSeletedRowKeys] = useState<React.Key[]>([]);

  const clearSearch = useCallback(() => {
    searchRef.current?.clear?.();
  }, [searchRef]);

  const onModalApply = useCallback(() => {

    if (typeof setDistributionCenters === 'function') {
      const mappings = form?.getFieldsValue()?.settings?.ScheduleSetting?.InventorySettings?.DistributionCenterWarehouseMappings || {};
      const temp: any[] = [];
      for (const key in mappings) {
        if (seletedRows.find((row: any) => `${row.DistributionCenterNum}` === key)) {
          mappings[key] = {
            ...mappings[key],
            IsSelected: 1
          }
        } else {
          mappings[key] = {
            ...mappings[key],
            IsSelected: 0
          }
        }
      }
      distributionCenters.forEach((dc: any) => {
        if (selectedRowKeys.includes(dc.Code)) {
          temp.push({
            ...dc,
            IsSelected: 1
          })
        } else {
          temp.push({
            ...dc,
            IsSelected: 0
          })
        }
      })
      setDistributionCenters(temp);
      form?.setFieldsValue({
        settings: {
          ScheduleSetting: {
            InventorySettings: {
              DistributionCenterWarehouseMappings: mappings,
            } as any,
          },
        },
      });
    }

    clearSearch();
    setModalVisible(false);
    onApply?.();
    // eslint-disable-next-line
  }, [onApply, clearSearch, distributionCenters, selectedRowKeys]);

  const renderTable = (dataSource: any[]) => {
    const disabledColumns = [...inventoryWarehouseTableColumns].map((col: any) => {
      if (col.dataIndex === 'ChannelAccountWarehouseCodes') {
        return {
          ...col,
          render(text: string, record: TableDCMapping) {
            const baseName = [
              'settings',
              'ScheduleSetting',
              'InventorySettings',
              'DistributionCenterWarehouseMappings',
              record.DistributionCenterNum.toString(),
            ];
            return (
              <>
                <Form.Item
                  name={[...baseName, 'DistributionCenterNum']}
                  noStyle
                  hidden
                >
                  <Input hidden id={`disabled_DistributionCenterNum_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item name={[...baseName, 'Name']} noStyle hidden>
                  <Input hidden id={`disabled_DistributionCenterName_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item name={[...baseName, 'Code']} noStyle hidden>
                  <Input hidden id={`disabled_DistributionCenterCode_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item name={[...baseName, 'City']} noStyle hidden>
                  <Input hidden id={`disabled_DistributionCenterCity_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item name={[...baseName, 'IsSelected']} noStyle hidden>
                  <Input hidden id={`disabled_DistributionCenterIsSelected_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item
                  // initialValue={record.ChannelAccountWarehouseCode}
                  name={[...baseName, 'ChannelAccountWarehouseCode']}
                  hidden
                  noStyle
                >
                  <Input hidden id={`disabled_ChannelAccountWarehouseCode_input_record${record.Code}`} />
                </Form.Item>
                <Form.Item
                  // initialValue={record.ChannelAccountWarehouseCode}
                  name={[...baseName, 'ChannelAccountWarehouseCode']}
                  valuePropName="children"
                >
                  <Typography.Text />
                </Form.Item>
              </>
            );
          },
        }
      }
      return { ...col };
    })

    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSeletedRowKeys(selectedRowKeys);
        setSeletedRows(selectedRows)
      },
      selectedRowKeys,
      seletedRows,
    };

    const seletedSource = dataSource.filter((dc: any) => dc.IsSelected === 1);

    if (onlyTable && seletedSource.length === 0) {
      return (
        <DCTableWrapper>
          <Typography.Title level={4} style={{ color: theme['@danger-color'] }}>You must assign warehouse for this channel</Typography.Title>
        </DCTableWrapper>
      )
    }

    return (
      <DCTableWrapper>
        <Table
          columns={onlyTable ? disabledColumns : inventoryWarehouseTableColumns}
          dataSource={onlyTable ? seletedSource : dataSource}
          loading={loading}
          rowKey="Code"
          // scroll={{ y: 400 }}
          style={{ maxHeight: 400, overflow: 'auto' }}
          pagination={false}
          rowSelection={onlyTable ? undefined : rowSelection}
        />
        <Spacer />
      </DCTableWrapper >
    )
  }

  useEffect(() => {
    if (distributionCenters.length > 0) {
      const keys: any[] = [];
      const rows: string[] = [];
      distributionCenters.forEach((dc: any) => {
        if (dc.IsSelected === 1) {
          keys.push(dc.Code);
          rows.push(dc);
        }
      })
      setSeletedRowKeys(keys);
      setSeletedRows(rows);
    }
  }, [distributionCenters])

  useEffect(() => {
    if (seletedRows.length > 0) {
      const mappings = form?.getFieldsValue()?.settings?.ScheduleSetting?.InventorySettings?.DistributionCenterWarehouseMappings || {};
      seletedRows.forEach(row => {
        const target = mappings[row.DistributionCenterNum];
        if (target && !target.ChannelAccountWarehouseCode) {
          mappings[row.DistributionCenterNum] = {
            ...target,
            ChannelAccountWarehouseCode: row.Code
          }
        }
      })
      form?.setFieldsValue({
        settings: {
          ScheduleSetting: {
            InventorySettings: {
              DistributionCenterWarehouseMappings: { ...mappings }
            }
          } as any
        }
      })
    }
  }, [seletedRows, form])

  if (onlyTable) {
    return renderTable(distributionCenters);
  }

  return (
    <>
      <Row wrap={false}>
        <Col span={18}>
          <Row gutter={12} justify="space-between" wrap style={{ width: '100%' }}>
            <Col span={24}>
              <SearchBar
                reference="id"
                elementRef={searchRef}
                data={distributionCenters}
                onResult={setFilteredData}
                fields={dcSearchFields}
              />
            </Col>
          </Row>
          <Spacer />
          {renderTable(filteredData)}
        </Col>
        <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col flex={'auto'}>
          <NoteBox>
            Note: If different warehouses are mapped to the same channel warehouse code, the
            in-stock quantity will be combined and sent to this channel.
          </NoteBox>
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: 10 }}>
        <Col>
          <Space>
            <Button disabled={seletedRows.length === 0} onClick={onModalApply} type="primary">
              <PlusOutlined />
              Select
            </Button>
            <Button onClick={() => {
              if (typeof onModalClose === 'function') onModalClose();
            }}>
              <CloseOutlined />
              Cancel
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default ModalContent;