import React from 'react';
import { Button, Typography } from 'antd';
import Styled from 'styled-components';
const { Text } = Typography;

type CellProps = {
  value: string;
  onButtonClick: () => void;
};

const CellBox = Styled.div`
  width: 130px;
  height: 35px;
  &:hover {
    width: 130px;
  }
`;

export default function ExportCSV(props: CellProps): JSX.Element {
  const { value, onButtonClick } = props;
  const [showButton, setShowButton] = React.useState<boolean>(false);
  const divRef = React.useRef<any>();

  React.useEffect(() => {
    let interval: any = undefined;
    if (showButton) {
      interval = setInterval(() => {
        if (showButton && divRef.current && divRef.current.matches(':hover') === false) {
          setShowButton(false);
        }
      }, 800);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showButton]);

  return (
    <CellBox
      ref={divRef}
      onMouseEnter={() => {
        setShowButton(true);
      }}
      onMouseLeave={() => {
        setShowButton(false);
      }}
    >
      {!showButton && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Text>{value}</Text>
        </div>
      )}
      {showButton && (
        <div style={{ width: 130, height: 35 }}>
          <Button
            type="link"
            style={{ width: '100%', height: '100%' }}
            onClick={() => onButtonClick()}
          >
            {value ? 'Edit' : 'Add'}
          </Button>
        </div>
      )}
    </CellBox>
  );
}
