import { Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ProductContent from './../product';
import { fetchChannels, listProfileChannelAccounts, getChannelControlFlag } from '../../../services/channels';
import { useExecutePromise } from '../../../util/hooks';
import ContentLayout from '../../../components/ContentLayout';

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

type AccountMap = {
  /**
   * Retail accounts grouped by PlatformNum
   */
  retail: { [key: number]: ChannelAccount[] };
  /**
   * Non-retail accounts grouped by ChannelNum
   */
  nonRetail: { [key: number]: ChannelAccount[] };
};

const loadMappedAccounts = async () => {
  const [accounts, platforms] = await Promise.all([listProfileChannelAccounts(), fetchChannels()]);

  const platformsMap: { [key: number]: Entities.ChannelProfile } = {};
  const mappedAccounts: AccountMap = {
    retail: {},
    nonRetail: {},
  };

  // Generate channels map for easier access.
  platforms.forEach((c: any) => {
    platformsMap[c.channelNum] = c;
  });

  accounts.forEach((a: any) => {
    if (a.PlatformNum === 0) {
      if (!mappedAccounts.nonRetail[a.ChannelNum]) {
        mappedAccounts.nonRetail[a.ChannelNum] = [];
      }

      mappedAccounts.nonRetail[a.ChannelNum].push(a);
      return;
    }

    const entry = {
      ...a,
      platform: platformsMap[a.ChannelNum],
    };

    if (!mappedAccounts.retail[a.PlatformNum]) {
      mappedAccounts.retail[a.PlatformNum] = [];
    }

    mappedAccounts.retail[a.PlatformNum].push(entry);
  });

  // console.log(mappedAccounts)

  return mappedAccounts;
};

// TODO: streamline links here and sidebar
const ToDropshipper: React.FC = () => {
  const [data, loading] = useExecutePromise(loadMappedAccounts, { retail: [], nonRetail: [] });
  const [selectedChannel, setSelectedChannel] = useState<ChannelAccount>();
  const [loadingFlagNum, setLoadingFlagNum] = useState<boolean>(false);

  const getControlFlag = async (channelAccount: ChannelAccount) => {
    try {
      const CHNLCtrlFlagNum = await getChannelControlFlag(channelAccount.ChannelAccountNum);
      setLoadingFlagNum(false);
      setSelectedChannel({
        ...channelAccount,
        CHNLCtrlFlagNum,
      });
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingFlagNum(false);
    }
  };

  useEffect(() => {
    if (Object.keys(data.nonRetail).length > 0) {
      if (!data.nonRetail[102]) {
        message.error('Channel: To Dropshipper is not found');
        return;
      }
      const channelAccount = data.nonRetail[102][0];
      if (channelAccount) {
        getControlFlag(channelAccount);
      }
    }
    // eslint-disable-next-line
  }, [data])

  return selectedChannel ? (
    <ProductContent selectedChannel={selectedChannel} onCallback={() => setSelectedChannel(undefined)} from="To Dropshipper" />
  ) : (
    <ContentLayout>
      <Spin spinning={loading || loadingFlagNum}></Spin>
    </ContentLayout>
  )
};

export default ToDropshipper;
