import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Modal, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
// import CSVLink, { CSVColumns } from '../../../components/common/CSVLink';
import { DataGrid } from "../../../components/common/datagrid/DataGrid3";
import Heading from "../../../components/common/Heading";
import Spacer from "../../../components/common/Spacer";
import ContentLayout from "../../../components/ContentLayout";
import SiteContent from "../../../components/SiteContent";
import { CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE } from '../../../constants/config';
import AddEditModal from "./AddEditModal";
import {
  fetchControlTowerGroups,
  upControlTowerGroupWeight,
  downControlTowerGroupWeight,
  deleteControlTowerGroup,
} from "../../../services/controlTower";

type ControlTowerGroupElement = {
  rowNum: number;
  controlTowerGroupName: string;
  controlTowerGroupDescription: string;
  displaySequence: number;
  columns: number;
};

const AddButton = styled(Button)<{ $hasPermission: boolean }>`
  display: ${(props) => (props.$hasPermission ? "initial" : "none")};
  min-height: 40px;
`;

//   const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
//     if (permissions && permissions.size === 0) return false;
//     return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
//   };

const PageContent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [sourceData, setSourceData] = useState<ControlTowerGroupElement[]>([]);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [current, setCurrent] = useState<ControlTowerGroupElement>();
  const [saveLoading, setSaveLoading] = useState(false);

  const getSourceData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchControlTowerGroups();
      setLoading(false);
      if (res) {
        setSourceData(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const deleteGroup = useCallback(
    async (data: ControlTowerGroupElement) => {
      try {
        await deleteControlTowerGroup(data.rowNum);
        message.success("delete successfully");
        getSourceData();
      } catch (error) {}
    },
    [getSourceData]
  );

  const upSequence = useCallback(async (rowNum: number) => {
    try {
      setSaveLoading(true);
      const res = await upControlTowerGroupWeight(rowNum);
      setSaveLoading(false);
      if (res) {
        setSourceData(res);
        message.info("update successfully");
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, []);

  const downSequence = useCallback(async (rowNum: number) => {
    try {
      setSaveLoading(true);
      const res = await downControlTowerGroupWeight(rowNum);
      setSaveLoading(false);
      if (res) {
        setSourceData(res);
        message.info("update successfully");
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, []);

  const columns = [
    {
      header: "Group Name",
      name: "controlTowerGroupName",
      defaultFlex: 1,
      editable: false,
    },
    {
      header: "Description",
      name: "controlTowerGroupDescription",
      defaultFlex: 1,
      editable: false,
    },
    {
      name: "rowNum",
      header: "Action",
      minWidth: 300,
      maxWidth: 300,
      sortable: false,
      editable: false,
      render({ data, rowIndex }: any) {
        return (
          <Row justify="end" gutter={14}>
            <Col>
              <Button
                size="small"
                disabled={data.isDefault}
                onClick={() => {
                  setCurrent(data);
                  setAddEditDialogVisible(true);
                }}
              >
                <EditOutlined style={{ color: "#006dff" }} />
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                disabled={data.isDefault || data.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE}
                onClick={() => {
                  Modal.confirm({
                    title: "Do you want to delete this group?",
                    icon: <ExclamationCircleOutlined />,
                    content: `Group Name: ${data.controlTowerGroupName}`,
                    onOk: async () => {
                      deleteGroup(data);
                    },
                    onCancel() {},
                  });
                }}
              >
                <DeleteOutlined style={{ color: "#c13939" }} />
                Delete
              </Button>
            </Col>
            <Col>
              <Space>
                <Button
                  disabled={rowIndex === 0 || saveLoading}
                  icon={<UpOutlined />}
                  onClick={() => upSequence(data.rowNum)}
                />
                <Button
                  disabled={saveLoading}
                  icon={<DownOutlined />}
                  onClick={() => downSequence(data.rowNum)}
                />
              </Space>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    getSourceData();
  }, [getSourceData]);

  return (
    <ContentLayout>
      <Heading
        title="Control Tower Group"
        actions={
          <Space>
            <AddButton
              type="primary"
              $hasPermission={true}
              onClick={() => {
                setCurrent(undefined);
                setAddEditDialogVisible(true);
              }}
            >
              <PlusCircleOutlined />
              Add
            </AddButton>
          </Space>
        }
      />
      <Spacer />
      <SiteContent flexGrow>
        <DataGrid
          idProperty="rowNum"
          rowHeight={35}
          columns={columns}
          dataSource={sourceData}
          loading={loading}
          pagination
        />
      </SiteContent>
      {addEditDialogVisible && (
        <AddEditModal
          mode={current?.rowNum ? "Edit" : "New"}
          group={current}
          onClose={() => setAddEditDialogVisible(false)}
          onRefresh={() => {
            setAddEditDialogVisible(false);
            getSourceData();
          }}
          visible
        />
      )}
    </ContentLayout>
  );
};

export default PageContent;
