import { NotificationActions, NotificationsActionTypes } from '../types/notifications';

interface NotificationState {
  notificationType: string | null;
  message: string | null;
}

const initialState: NotificationState = {
  notificationType: null,
  message: null,
};

const profiles = (state = initialState, action: NotificationsActionTypes) => {
  switch (action.type) {
    case NotificationActions.SET_NOTIFICATION:
      return {
        ...state,
        notificationType: action.notificationType,
        message: action.message,
      };
    case NotificationActions.CLEAR:
      return {notificationType: null, message: null};
    default:
      return state;
  }
};

export default profiles;