import React, {useState} from 'react';
import {
  Alert,
  Checkbox,
  Radio,
  Row,
  Space,
  Switch,
  Typography
} from 'antd';

import TableFilter from '../../../components/common/TableFilter'

type Props = {
  focused: boolean;
  mode: string;
  attrList: StringKAnyVPair[];
  setSelectedAttributes: Function;
  overwrite?: boolean;
  setOverwrite?: Function;
  useChildLevel?: boolean;
  setUseChildLevel?: Function;
};


const DestinationSelector = (props: Props) => {
  const { attrList } = props
  const [filteredData, setFilteredData] = useState<StringKAnyVPair[]>([])
  //const [productBasicList, setProductBasicList] = useState<StringKAnyVPair[]>([]);
  const [selectedAttrs, setSelectedAttrs] = useState<number[]>([]);
  const destinationWrapperRef = React.useRef<any>(null);
  const overwriteOptions = [
    {label: 'Overwrite', value: true,},
    {label: "Don't Overwrite", value: false,},
  ];
  const style = {
    display: props.focused ? '' : 'none',
  };

  const onOverwriteChange = (evt: any) => {
    if (typeof props.setOverwrite === 'function') {
      props.setOverwrite(evt.target.value);
    }
  };

  const onChildLevelChange = (chk: boolean) => {
    if (typeof props.setUseChildLevel === 'function') {
      props.setUseChildLevel(!!chk);
    }
  };

  const onSelectAttribute = (attr: StringKAnyVPair) => {
    const idx = selectedAttrs.indexOf(attr.copywritingElementNum);
    //console.log('sel', attr);
    if (idx > -1) {
      selectedAttrs.splice(idx, 1);
    } else {
      selectedAttrs.push(attr.copywritingElementNum);
    }

    setSelectedAttrs([...selectedAttrs]);

    if (typeof props.setSelectedAttributes === 'function') {
      props.setSelectedAttributes([...selectedAttrs]);
    }
  };


  return (<>
    <div className="dest-step-wrapper" ref={destinationWrapperRef} style={style}>
      {props.mode === 'review' && <Alert
        message="The value of the attributes will be replaced with the value from Content Resources"
        type="error"
      />}
      {props.mode === 'finish' && <Alert
        message="The value of following fields is schedule to be updated with the value from Content Resources"
        type="success"
      />}
      <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
        <span
          className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
        >
          If destination has value
        </span>
        <Radio.Group
          disabled={props.mode !== 'destination'}
          onChange={onOverwriteChange}
          options={overwriteOptions}
          value={!!props.overwrite}
        />
      </Space>
      <Row>
        <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
          <span
            className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
          >
            Copy value at child level
          </span>
          <Switch
            disabled={props.mode !== 'destination'}
            checkedChildren="Yes"
            onChange={onChildLevelChange}
            unCheckedChildren="No"
            checked={props.useChildLevel}
          />
          {/* <Checkbox disabled={props.mode !== 'destination'} checked={props.useChildLevel} onChange={onChildLevelChange}/> */}
        </Space>
      </Row>

      <Row>
        <Space>
        <TableFilter size="middle" columns={[{name:'elementName'}]} dataSource={attrList} setFilteredData={setFilteredData}   />
        </Space>
      </Row>
      {filteredData.map(e => (
        <span
          className="attr-cell label"
          key={e.copywritingElementNum}
        >
          <span
            className="attr-label full-width form-label"
            onClick={() => onSelectAttribute(e)}
          >
            <Checkbox
              //disabled={!e.copywritingValue}
              value={e.copywritingElementNum}
              checked={selectedAttrs.indexOf(e.copywritingElementNum) > -1}
            />
            <Typography.Text ellipsis={{tooltip: e.elementName}}>
              {e.elementName}
            </Typography.Text>
          </span>
        </span>
      ))}
      </div>
  </>);
};

export default DestinationSelector;
