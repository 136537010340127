export enum Permissions {
  MANAGE_ORDERS = 10001,
  MANAGE_DISTRIBUTION_CENTERS = 10002,
  MANAGE_PROFILE_USERS = 10003,
  USER_PERMISSION = 10004,
  CHANNELS = 10005,
  MANAGE_CHANNEL_ACCOUNT = 10010,
  MANAGE_CONTROL_TOWER = 10014,
  MANAGE_EXPORT = 10007,
  MANAGE_IMPORT = 10011,
  MANAGE_PRODUCT_ELEMENTS = 10008,
  MANAGE_PRODUCTS = 10009,
  MANAGE_SHIPPING_SETTINGS = 10006,
  MANAGE_WAREHOUSES = 40001,
  PIM_FILE_MANAGEMENT = 10013
}

export enum ViewLevel {
  VIEW = 1,
}
