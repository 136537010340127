import React from 'react';// eslint-disable-line
import { Button, Modal } from 'antd';
import styled from 'styled-components';

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

function ConfirmationModal(props: any) {
  const { visible, handleCancel, confirmationType, name } = props;
  const handleSubmit = () => {
    props.handleConfirm(props.confirmationType === 'cleaning');
    props.handleCancel();
  };

  return (
    <Modal
      key={Math.random()}
      visible={visible}
      style={{ top: 30}}
      title={
        confirmationType === 'discard' ? <TitleHeader>Are you sure you want to discard changes?</TitleHeader> :
          (
            <TitleHeader>
              {`Are you sure you want to clean ${name}?`}
            </TitleHeader>
          )
      }
      onCancel={handleCancel}
      width={600}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleSubmit}>
          {confirmationType === 'discard' ? 'Discard' : 'Delete'}
        </Button>,
      ]}
    >
      <p>This action cannot be undone and you will be unable to recovery any data.</p>
    </Modal>
  );
}

export default ConfirmationModal;
