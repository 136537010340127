import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Card,
  message,
} from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  getAllQtyOverwrites,
  addProductQtyOverwriteRule,
} from "../../../../services/products";
import { fetchRuleById } from "../../../../services/qtyOverwriteRule";

interface CreateBrandProps {
  visible: boolean;
  productId: string;
  sku: string;
  centralProductNum: number;
  onHide: () => void;
  onRefresh: () => void;
  isOverwrite: boolean;
  productType: number;
}
const UserComponent = (props: CreateBrandProps) => {
  const { visible, onHide, onRefresh, sku, isOverwrite, productType, centralProductNum } = props;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [sourceRules, setSourceRules] = useState<any[]>([]);
  const [channelList, setChannelList] = useState<any[]>([]);
  const [filterRules, setFilterRules] = useState<any[]>([]);
  const [selectedChanel, setSelectedChannel] = useState<any>();
  const [selectedRule, setSelectedRule] = useState<any>();
  const [ruleInfo, setRuleInfo] = useState<any>();
  const [loadingInfo, setLoadingInfo] = useState(false);

  const [form] = Form.useForm();

  const getRuleInfo = useCallback(async (id: number) => {
    try {
      setLoadingInfo(true);
      const res = await fetchRuleById(id);
      setLoadingInfo(false);
      if (res) {
        const { ruleType, ruleExpression } = res;
        const {
          GreaterValue,
          LessValue,
          TreatZero: tz,
          UseOriginalValue,
          UserSetValue,
        } = JSON.parse(ruleExpression);
        setRuleInfo({
          ruleType,
          GreaterValue,
          LessValue,
          TreatZero: tz === 1 ? true : false,
          UseOriginalValue,
          UserSetValue,
        });
      }
    } catch (error) {
      setLoadingInfo(false);
    }
  }, []);

  const loadRules = useCallback(async () => {
    setLoading1(true);
    try {
      const res = await getAllQtyOverwrites();
      setLoading1(false);
      if (res) {
        const temp: number[] = [];
        const tempChl: any[] = [];
        res.map((i: any) => {
          if (temp.indexOf(i.channelAccountNum) === -1) {
            temp.push(i.channelAccountNum);
            tempChl.push({
              channelAccountNum: i.channelAccountNum,
              channelAccountName: i.channelAccountName,
            });
          }
          return true;
        });
        setSourceRules(res);
        setChannelList(tempChl);
      }
    } catch (error) {
      setLoading1(false);
    }
  }, []);

  const handleCreate = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const json = JSON.stringify({
        ChannelAccountNum: params.channelAccountNum,
        RuleNum: params.ruleNum,
        CentralProductNum: centralProductNum,
      });
      const res = await addProductQtyOverwriteRule(sku, productType !== 1 ? isOverwrite ? 1 : 0 : 0, json);
      setLoading(false);
      if (res) {
        message.success("Save successfully!");
        onRefresh();
      }
    } catch (err) {
      setLoading(false);
      setLoading(false);
    }
  };

  const handleCancel = useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: "Leave / Refresh Page?",
        okText: "Leave",
        cancelText: "Cancel",
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  useEffect(() => {
    loadRules();
  }, [loadRules]);

  useEffect(() => {
    if (selectedChanel) {
      setFilterRules(
        sourceRules.filter((i) => i.channelAccountNum === selectedChanel)
      );
    }
  }, [selectedChanel, sourceRules]);

  useEffect(() => {
    if (selectedRule) {
      getRuleInfo(selectedRule);
    } else {
      setRuleInfo(undefined);
    }
  }, [selectedRule, getRuleInfo]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Add Qty Overwrite Rule"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreate}
              loading={loading}
              icon={loading ? <LoadingOutlined /> : <SaveOutlined />}
            >
              {loading ? "Saving" : "Save"}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading1}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="channelAccountNum"
            label="Channel Account"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              onChange={(value) => {
                setSelectedChannel(value);
                form.setFieldsValue({ ruleNum: undefined });
                setSelectedRule(undefined);
              }}
            >
              {channelList.map((i) => (
                <Select.Option
                  key={i.channelAccountNum}
                  value={i.channelAccountNum}
                >
                  {i.channelAccountName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="ruleNum"
            label="Rule"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select disabled={!selectedChanel} onChange={setSelectedRule}>
              {filterRules.map((i) => (
                <Select.Option key={i.ruleNum} value={i.ruleNum}>
                  {i.ruleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        {selectedRule && (
          <Card loading={loadingInfo}>
            <p>{`Rule Type:  ${
              ["", "Absolute Value", "At Least", "Lower to Zero"][
                ruleInfo?.ruleType || 0
              ]
            }`}</p>
            {[1, 2].includes(ruleInfo?.ruleType) && (
              <p>{`Treat no quantity as zero: ${
                ruleInfo?.TreatZero ? "Yes" : "No"
              }`}</p>
            )}
            {ruleInfo?.ruleType === 1 && (
              <p>{`Always use ${ruleInfo?.UserSetValue}`}</p>
            )}
            {[2, 3].includes(ruleInfo?.ruleType) && (
              <>
                <p>{`If the quantity is less than ${ruleInfo?.LessValue}, ${ruleInfo?.ruleType === 2 ? 'increase the value to match.' : 'use zero.'}`}</p>
                <p>{`If the quantity is greater than ${
                  ruleInfo?.GreaterValue
                }, use ${
                  ruleInfo?.userOriginValue === 1
                    ? "original value"
                    : ruleInfo?.UserSetValue
                }`}</p>
              </>
            )}
          </Card>
        )}
      </Spin>
    </Modal>
  );
};

export default UserComponent;
