import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import { getMetafieldById, editMetafield } from "../../services/Metafield";
type EditDialogProps = {
  visible: boolean;
  onRefresh: Function;
  onClose: Function;
  // typeList: any[];
  metafield: any;
};

const EditDialog = (props: EditDialogProps) => {
  const { visible, onClose, onRefresh, metafield } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<any>();

  const [form] = Form.useForm();

  const saveField = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const { options, validation } = info;
      const newOptions = options.map((i: any) => {
        return {
          ...i,
          value:
            (i.type || i.Type) === "checkbox" || (i.type || i.Type) === "switch"
              ? params[i.key || i.Key]
                ? 1
                : 0
              : params[i.key || i.Key],
        };
      });
      const newValidation = validation.map((i: any) => {
        return {
          ...i,
          value:
            (i.type || i.Type) === "checkbox" || (i.type || i.Type) === "switch"
              ? params[i.key || i.Key]
                ? 1
                : 0
              : params[i.key || i.Key],
        };
      });
      const res = await editMetafield(info.metafieldNum, info.channelNum, {
        Description: params.Description,
        ValidationJsonValue: newValidation,
        OptionsJsonValue: newOptions,
      });
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const formatItem = useCallback((item) => {
    if (item.validationJsonValue) {
      try {
        item.validation = JSON.parse(item.validationJsonValue);
      } catch (error) {
        item.validation = [];
      }
    }
    if (item.optionsJsonValue) {
      try {
        item.options = JSON.parse(item.optionsJsonValue);
      } catch (error) {
        item.options = [];
      }
    }
    return item;
  }, []);

  const getInfo = useCallback(
    async (metafieldNum: number, channelNum: number) => {
      try {
        setLoading(true);
        const res = await getMetafieldById(metafieldNum, channelNum);
        setLoading(false);
        setInfo(formatItem(res));
      } catch (error) {
        setLoading(false);
      }
    },
    [formatItem]
  );

  useEffect(() => {
    if (metafield) {
      getInfo(metafield.metafieldNum, metafield.channelNum);
    }
  }, [metafield, getInfo]);

  const rendeItem = useCallback((item: any) => {
    switch (item.type || item.Type) {
      case "text":
        return (
          <Form.Item
            key={item.key || item.Key}
            label={item.label || item.Label}
            name={item.key || item.Key}
            initialValue={item.value || item.Value}
          >
            <Input />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            // label={item.label}
            key={item.key || item.Key}
            wrapperCol={{ span: 14, offset: 8 }}
            name={item.key || item.Key}
            valuePropName="checked"
            initialValue={(item.value || item.Value) === "1"}
          >
            <Checkbox>{item.label || item.Label}</Checkbox>
          </Form.Item>
        );
      case "switch":
        return (
          <Form.Item
            key={item.key || item.Key}
            label={item.label || item.Label}
            name={item.key || item.Key}
            valuePropName="checked"
            initialValue={(item.value || item.Value) === "1"}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        );
      case "dropdown":
        return (
          <Form.Item
            key={item.key || item.Key}
            label={item.label || item.Label}
            name={item.key || item.Key}
            initialValue={item.value || item.Value}
          >
            <Select>
              {(item.options || item.Options).map((i: string) => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={item.key || item.Key}
            label={item.label || item.Label}
            name={item.key || item.Key}
            initialValue={item.value || item.Value}
          >
            <Input />
          </Form.Item>
        );
    }
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title="Edit Metafield"
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        {info && (
          <Form {...layout} form={form} requiredMark={false}>
            <Form.Item label="Channel">
              <Input defaultValue={metafield.channelName} disabled />
            </Form.Item>

            <Form.Item label="Definiton Name">
              <Input defaultValue={metafield.metafieldName} disabled />
            </Form.Item>

            <Form.Item
              label="Description"
              name="Description"
              initialValue={info?.description}
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            <Form.Item label="Type">
              <Input defaultValue={metafield.typeName} disabled />
            </Form.Item>

            <Form.Item label="Is List of Values">
              <Switch
                disabled
                defaultChecked={!!info.isCollectionSet}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Form.Item>

            {info && info.validation.map((item: any) => rendeItem(item))}
            {info && info.options.map((item: any) => rendeItem(item))}
          </Form>
        )}
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="end">
              <Button type="primary" onClick={saveField}>
                Save
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default EditDialog;
