import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Typography } from "antd";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { CloseOutlined } from "@ant-design/icons";

import ModalDialog from '../../../components/common/ModalDialog';
import { fetchControlTowerAttribute } from '../../../services/controlTower';
import { getAllContentResources } from '../../../services/copywriting'
interface WhiteListProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: (addtype: number, data: any) => void;
}

const SelectAtrributeComponent = (props: WhiteListProps) => {
  const { visible, onHide, onRefresh } = props;
  const [addType, setAddType] = useState<1|2|undefined>();
  const initialGCStyle = {height: 'calc(100vh - 260px)', maxHeight: 600};
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState({});
  const [seletedRow, setSelectedRow] = useState<any[]>([]);
  const [gridCtnStyle,setGridCtnStyle] = useState<StringKAnyVPair>(initialGCStyle);

  const gridCtnRef = React.useRef<any>(null);

  const getAttributeData = useCallback(async () => {
    try {
      setLoading(true);
      const req = addType === 1 ? fetchControlTowerAttribute : getAllContentResources;
      const res = await req();
      setLoading(false);
      if (res) {
        const SourceEnum: { [key: number]: string } = {0:'User Defined',1:'ERP',2:'PIM'}
        if(addType === 2){
          const temp = res.data.map((i:any)=> {return {...i, sourceName: SourceEnum[i.source]}})
          setData(temp)
        } else {
          setData(res);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }, [addType]);

  const filterValue = [
    { name: "attributeName", operator: "contains", type: "string", value: "" },
    { name: "groupName", operator: "contains", type: "string", value: "" },
  ];

  const filterValue1 = [
    { name: 'elementName', operator: 'contains', type: 'string', value: '' },
    { name: 'sourceName', operator: 'contains', type: 'string', value: '' },
  ];

  const columns = [
    {
      name: "attributeName",
      header: "Name",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: "groupName",
      header: "Source",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
  ];

  const columns1 = [
    {
      name: 'elementName',
      header: 'Name',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'sourceName',
      header: 'Source',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
  ];

  const handleNext = async () => {
    if (seletedRow.length > 0 && addType) {
      onRefresh(addType, seletedRow[0]);
    }
  };

  const getDialogDom = () => {
    const gridCtn = gridCtnRef?.current;

    if (gridCtn) {
      return gridCtn.parentNode;
    }

    return null;
  };

  const onFullscreen = (full: boolean) => {
    const dialogBody = getDialogDom();

    if (!dialogBody) return;
    //console.log('full', full, dialogBody, dialogBody.parentNode);
    if (full) {
      setGridCtnStyle({
        height: 'calc(100vh - 144px)',
        maxHeight: '',
      });
      // maybe there need to detect the window environment
      dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 16}px`;
      setTimeout(() => {
        dialogBody.style.height = 'auto';
        dialogBody.parentNode.style.height = 'auto';
        //dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
      }, 0);
      setTimeout(() => {
        dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
      }, 200);
    } else {
      setGridCtnStyle(initialGCStyle);
    }
  };

  const onSelectionChange = useCallback(({ data, selected }) => {
    console.log(data);
    setSelected(selected);
    setSelectedRow(data);
  }, []);

  useEffect(() => {
    if(addType){
      getAttributeData();
    }
  }, [getAttributeData, addType]);

  return (
    <ModalDialog
      title={addType?`Add Control Tower Field from ${addType===1? 'Channel Attributes' : 'Content Resources'}` : 'Add Control Tower Field'}
      centered
      closable={false}
      closeButton
      fullscreen
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      //onCancel={() => onHide()}
      onClose={() => onHide()}
      onFullscreen={onFullscreen}
      getContainer={false}
      footer={
        <Row justify={addType?"space-between":'end'}>
          {addType&&<Space>
            <Typography.Text>
              Selected Row :{" "}
              <Typography.Text>
                {seletedRow.length > 0
                  ? addType ===1 ? `${seletedRow[0].attributeName} [${seletedRow[0].groupName}]` : `${seletedRow[0].elementName} [${seletedRow[0].sourceName}]`
                  : ""}
              </Typography.Text>
            </Typography.Text>
          </Space>}
          <Space>
            {addType&&<Button
              key="submit"
              type="primary"
              onClick={handleNext}
              disabled={seletedRow.length === 0}
            >
              Next
            </Button>}
            <Button onClick={() => onHide()} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <div ref={gridCtnRef} style={gridCtnStyle}>
        {addType ? <ReactDataGrid
          dataSource={data}
          columns={addType === 1 ? columns: columns1}
          loading={loading}
          defaultFilterValue={addType===1?filterValue: filterValue1}
          onSelectionChange={onSelectionChange}
          selected={selected}
          idProperty={addType===1?"attributeNum":"copywritingElementNum"}
          style={{
            height: "100%",
          }}
          virtualized
        />: <Row justify="center"  style={{marginTop: 26}}>
          <Space direction="vertical" size="large">
          <Button type="primary" onClick={()=> setAddType(1)}>Choose from Channel Attributes</Button>
          <Button type="primary" onClick={()=> setAddType(2)}>Choose from Content Resources</Button>
          </Space>
          </Row>}
      </div>
    </ModalDialog>
  );
};

export default SelectAtrributeComponent;
