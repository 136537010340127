import {Form} from 'antd';
import styled from 'styled-components';
// eslint-disable-next-line import/prefer-default-export
export const WrapperForm = styled(Form)`
 width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: flex-end;
`;
export const Wrapper = styled.div`
 width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: flex-end;
`;
