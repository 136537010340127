/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  // Dropdown,
  // Menu,
  // Row,
  // notification,
} from 'antd';
import {
  FolderAddOutlined,
  LinkOutlined,
  // MenuOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/Modal';
// import theme from '../../assets/styles/theme';
import ModalDialog from '../../components/common/ModalDialog';
import {
  ProductDetailDialogBodyWrapper,
} from '../../components/common/styledComponents';
import Loading from '../../components/common/Loading';
// import LoadingIcon from '../../components/common/LoadingIcon';
// import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
// import Products from '../../services/products';
import ProductDetail from '../DetailProduct/DialogBody';
import { clearCachedData } from '../DetailProduct/helper';
import ProductDetailTitle from './ProductDetailTitle';

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  product: StringKAnyVPair;
};

export const getRealProductId = (ids: string) => {
  //console.log('ids ->', ids);
  const fs = ids.split('/').map(e => e.trim()).filter(e => e);

  return fs[fs.length - 1] || '';
};

const ProductDetailDialog = (props: Props) => {
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>(props.product);
  const [currentProductId, setCurrentProductId] = useState(getRealProductId(props.product.ProductId));
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);
  const [detailDialogTitleType, setDetailDialogTitleType] = useState('');
  const [erpLinkParamType, setErpLinkParamType] = useState('sku');
  const [erpLinkType, setErpLinkType] = useState('InventoryManager');
  const [isSwitching, setIsSwitching] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const titleRef = useRef<any>();

  const closeProductDetailDialog = () => {
    if (titleRef.current.refresh) titleRef.current.refresh();
    props.onClose();
  };

  const detailDialogWidth = useCallback((
    isFullscreen = detailDialogIsFullscreen,
  ) => {
    let ret = isFullscreen ?
      window.innerWidth - 4 :
      window.innerWidth > 1440 ? window.innerWidth * 0.8 : 1260;

    if (ret > window.innerWidth) {
      ret = window.innerWidth - 100;
    }

    return ret;
  }, [detailDialogIsFullscreen]);

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const refreshProductDetail = () => {
    setIsSwitching(true);
    setTimeout(() => {
      clearCachedData();
      setIsSwitching(false);
    }, 66);
  };

  const setTitleType = (prdType: number) => {
    switch (prdType) {
      case 1:
        setDetailDialogTitleType('Product -');
        setErpLinkParamType('sku');
        setErpLinkType('InventoryManager');
        break;

      case 2:
        setDetailDialogTitleType('Style -');
        setErpLinkParamType('productStyleCode');
        setErpLinkType('StyleCodesManager');
        break;

      case 3:
        setDetailDialogTitleType('Substyle -');
        setErpLinkParamType('productStyleCode');
        setErpLinkType('StyleCodesManager');
        break;

      default:
        setDetailDialogTitleType('');
        break;
    }
  };

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton
        footer={null}
        fullscreen
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={
          <ProductDetailTitle 
            product={props.product}
            setDetailDialogTitleType={setDetailDialogTitleType}
            currentProduct={currentProduct}
            setCurrentProduct={setCurrentProduct}
            currentProductId={currentProductId}
            setCurrentProductId={setCurrentProductId}
            detailDialogTitleType={detailDialogTitleType}
            isSwitching={isSwitching}
            setIsSwitching={setIsSwitching}
            innerRef={titleRef}
          />
        }
        titleRightCrumb={<>
          <Button onClick={refreshProductDetail} ref={refreshBtnRef}>
            <RedoOutlined style={{transform: 'rotate(270deg)'}} />
            Refresh
          </Button>
          <Button
            type="link"
            href={`${process.env.ERP_LINK}Inventory/${erpLinkType}?${erpLinkParamType}=${currentProduct.SKU}`}
            target="_blank"
            style={{ border: '1px solid #D9D9d9', color: '#000' }}
          >
            <LinkOutlined />
            ERP
          </Button>
          <Button
            type="link"
            href={`/product-detail/${currentProductId}`}
            target="_blank"
            style={{ border: '1px solid #D9D9d9', color: '#000' }}
          >
            <FolderAddOutlined />
            Open As a New Tab
          </Button>
        </>}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        {isSwitching ?
          <ProductDetailDialogBodyWrapper
            className={detailDialogIsFullscreen ? 'fullscreen-mode2' : ''}
          >
            <Loading />
          </ProductDetailDialogBodyWrapper> :
          <ProductDetailDialogBodyWrapper
            className={detailDialogIsFullscreen ? 'fullscreen-mode2' : ''}
          >
            <ProductDetail
              isCreating={false}
              isFullscreen={detailDialogIsFullscreen}
              productId={currentProductId}
              refreshDialog={refreshProductDetail}
              setTitleType={setTitleType}
            />
          </ProductDetailDialogBodyWrapper>}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
