import React from 'react';
import ContentLayout from '../components/ContentLayout';

function Sell() {
  return (
    <ContentLayout>
      <h1>Sell</h1>
    </ContentLayout>
  );
}

export default Sell;
