import React from 'react';
import {
  Button,
  Checkbox,
  Input,
  Row,
  Space,
} from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';

import { DataGrid } from '../../../components/common/datagrid/DataGrid2';
import ModalDialog from '../../../components/common/ModalDialog';
import OverwriteOptionList from '../OverwriteOptionList';
import { SalesChannelGridWrapper } from '../styledComponents';

type Props = {
  channels: StringKAnyVPair[];
  dispatch?: Function;
  onClose: Function;
  onSave: Function;
  state: StringKAnyVPair;
  visible: boolean;
};

const catalogDict: StringKAnyVPair = {
  '0': 'No',
  //'1': 'Prepare Catalog',
  //'2': 'Sync Catalog',
  '2': 'Prepare Catalog',
  '1': 'Sync Catalog',
};

const catalogEditData = [
  { id: 0, label: 'No' },
  //{ id: 1, label: 'Prepare Catalog' },
  //{ id: 2, label: 'Sync Catalog' },
  { id: 2, label: 'Prepare Catalog' },
  { id: 1, label: 'Sync Catalog' },
];

const inventoryDict: StringKAnyVPair = {
  '0': 'No',
  '1': 'Feed Inventory by Rule',
  '2': 'Feed Inventory as Zero',
};

const inventoryEditData = [
  { id: 0, label: 'No' },
  { id: 1, label: 'Feed Inventory By Rule' },
  { id: 2, label: 'Feed Inventory as Zero' },
];

const SalesChannelDialog = (props: Props) => {
  const { useCallback, useState } = React;
  const [gridRef, setGridRef] = useState<any>(null);
  const [inited, setInited] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [channels, setChannels] = useState<StringKAnyVPair[]>([]);
  const [displayVisible, setDisplayVisible] = useState(false);
  const [lastSelectedIds, setLastSelectedIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchTimer, setSearchTimer] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState<StringKAnyVPair>({});
  const searchInputRef = React.useRef<any>(null);

  const cellDOMProps = (cellProps: StringKAnyVPair) => {
    return {
      onClick: () => {
        gridRef.current.startEdit({ columnId: cellProps.id, rowIndex: cellProps.rowIndex })
      }
    }
  };

  const cellSelectorValue = (value: any) => {
    const label = typeof value === 'string' ? value : '';

    return (<Row align="middle" justify="space-between">
      {label}
      <span className="cell-right-icon">
        <EditOutlined />
      </span>
    </Row>);
  };

  const columns = [
    {
      defaultFlex: 1,
      name: 'channel',
      header: 'Channel',
      headerAlign: 'center' as 'center',
      showColumnMenuTool: false,
    },
    {
      editor: SelectEditor,
      editorProps: {
        idProperty: 'id',
        dataSource: inventoryEditData,
        collapseOnSelect: true,
        clearIcon: null,
      },
      name: 'inventory',
      header: 'Inventory',
      headerAlign: 'center' as 'center',
      minWidth: 190,
      render: (data: any) => cellSelectorValue(inventoryDict[data.value]),
      showColumnMenuTool: false,
      cellDOMProps,
    },
    {
      editor: SelectEditor,
      editorProps: {
        idProperty: 'id',
        dataSource: catalogEditData,
        collapseOnSelect: true,
        clearIcon: null,
      },
      name: 'catalog',
      header: 'Catalog',
      headerAlign: 'center' as 'center',
      minWidth: 170,
      render: (data: any) => cellSelectorValue(catalogDict[data.value]),
      showColumnMenuTool: false,
      cellDOMProps,
    },
  ];

  const dialogTitle = () => {
    return 'Sales Channel';
  };

  const getChannels = () => {
    //const t = searchInputRef.current?.input.value;
    //const chn = typeof t === 'string' && t.trim() ? t : '';
    const chn = searchText.toLowerCase();

    return channels.filter(e => {
      if (chn) {
        return e.channel.toLowerCase().indexOf(chn) > -1;
      } else {
        return true;
      }
    }).filter(e => {
      if (displayVisible) {
        return !!selectedChannel[e.chnId];
      } else {
        return true;
      }
    });
  };

  // eslint-disable-next-line
  const initSourceData = () => {
    const selected: StringKAnyVPair = {};
    const idList: string[] = [];
    //console.log(props.channels);
    setChannels(props.channels.map(e => {
      const d = {
        chnId: e.ProductCHNLCtrlFlagId,
        channel: e.CHNLCtrlFlagDesc || e.CHNLCtrlFlag || e.ProductCHNLCtrlFlagId,
        inventory: e.sendInventory,
        catalog: e.sendContent,
      };

      if (e.selected) {
        selected[e.ProductCHNLCtrlFlagId] = d;
        idList.push(e.ProductCHNLCtrlFlagId);
      }

      return d;
    }));
    //console.log('sel', selected);
    setLastSelectedIds(idList);
    setSelectedChannel(selected);
  };

  const onCloseDialog = () => {
    props.onClose();
  };

  const onDisplayVisibleChange = (evt: any) => {
    setDisplayVisible(evt.target.checked);
  };

  const onEditComplete = useCallback(({ value, columnId, rowId }) => {
    const data = [ ...channels ];

    //console.log('dd-->', data, value, columnId, rowId);
    for (let i = 0; i < data.length; i++) {
      if (data[i].chnId === rowId) {
        if (data[i][columnId] !== value) {
          //console.log(data[i][columnId], value, '<--');
          data[i][columnId] = value;

          if (selectedChannel[rowId]) {
            selectedChannel[rowId][columnId] = value;
            setIsChanged(true);
            console.log('set');
            break;
          }
        }
      }
    }

    //console.log('d', data, selectedChannel);
    setChannels(data);
    setSelectedChannel({...selectedChannel});
  }, [channels, selectedChannel]);

  const onFullscreen = (isFullscreen: boolean) => {
    setIsFullscreen(isFullscreen);
  };

  const onInputSearchText = (evt: any) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
      //setSearchTimer(0);
    }

    const timer = setTimeout(onSearchChannel, 1000);

    setSearchTimer(timer);
  };

  const onSearchChannel = () => {
    const t = searchInputRef.current?.input.value;
    const chn = typeof t === 'string' && t.trim() ? t : '';
    //console.log('evt', searchInputRef.current.input.value, chn);

    setSearchText(chn);

    if (searchTimer) {
      setSearchTimer(0);
    }
  };

  const onSelectionChange = useCallback(({ selected }) => {
    const idList: string[] = Object.keys(selected);

    idList.forEach(k => {
      let isZero = false;

      if (lastSelectedIds.indexOf(k) < 0) {
        if (selected[k].catalog === 0) {
          //console.log('s', selectedChannel, channels);
          selected[k].catalog = 1;
          isZero = true;
        }
      }

      if (isZero) {
        for (let i = 0; i < channels.length; i++) {
          if (channels[i].chnId === k) {
            channels[i].catalog = 1;
            break;
          }
        }
      }
    });
    setChannels([...channels]);
    setLastSelectedIds(idList);
    setSelectedChannel(selected);
    setIsChanged(true);
  }, [channels, lastSelectedIds]);

  const saveValues = () => {
    //console.log('sel', selectedChannel);
    props.onSave(selectedChannel);
    onCloseDialog();
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
      initSourceData();
    }
  }, [initSourceData, inited]);

  return (<>
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton
      footer={null}
      fullscreen
      //okText="Save"
      onClose={onCloseDialog}
      onFullscreen={onFullscreen}
      //onOk={onSaveImage}
      style={{ paddingBottom: 0 }}
      title={dialogTitle()}
      visible={props.visible}
      //width={isChatEnable ? 1260 : 700}
      width={780}
    >
      {props.dispatch && props.state.productType !== 1 && (
        <Row
          align="middle"
          justify="end"
          style={{marginBottom: 8}}
        >
          <OverwriteOptionList
            dispatch={props.dispatch ? props.dispatch : () => void(0)}
            state={props.state ? props.state : {}}
          />
        </Row>
      )}
      <Row align="middle" style={{ marginBottom: 10 }}>
        <Space>
          <Input.Search
            allowClear
            onKeyUp={onInputSearchText}
            onSearch={onSearchChannel}
            placeholder="Input search text"
            ref={searchInputRef}
            style={{ width: 400, }}
          />
          <Checkbox
            checked={displayVisible}
            onChange={onDisplayVisibleChange}
          >
            Display Selected Channel Only
          </Checkbox>
        </Space>
      </Row>
      <SalesChannelGridWrapper
        style={{
          height: isFullscreen ? 'calc(100vh - 180px)' : 400,
          marginBottom: 12,
        }}
      >
        <DataGrid
          idProperty="chnId"
          checkboxColumn
          checkboxOnlyRowSelect={true}
          columns={columns}
          dataSource={getChannels()}
          editable={true}
          enableKeyboardNavigation={false}
          onSelectionChange={onSelectionChange}
          onEditComplete={onEditComplete}
          onReady={setGridRef}
          rowHeight={35}
          selected={selectedChannel}
          style={{height: '100%'}}
        />
      </SalesChannelGridWrapper>
      <Row justify="end">
        <Space>
          <Button
            onClick={onCloseDialog}
          >
            <CloseOutlined />
            Cancel
          </Button>
          <Button
            disabled={!isChanged}
            onClick={saveValues}
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Space>
      </Row>
    </ModalDialog>
  </>);
};

export default SalesChannelDialog;
