import React from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Col, FormInstance, message, Row } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { bindActionCreators } from 'redux';
import { toMoney } from '../../util/strings';
import { Type } from '../../components/common/FormElement';
import { Paragraph } from './styledComponents';
import FileCopy from '../../assets/icons/file_copy';
import OrderDetailsActions from '../../redux/actions/orderDetails';
import { fetchOrderDetail, fetchSystemCarriersAndServices } from '../../services/orders';

export const productColumns = [
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    width: 100,
    render: function render(url: any) {
      return url !== '' ? (
        <img src={url} alt="" style={{ width: '100%' }} />
      ) : (
        <PictureOutlined style={{ fontSize: '35px', marginBottom: '15px' }} />
      );
    },
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 150,
  },
  {
    title: 'Description',
    dataIndex: 'itemTitle',
    width: 450,
    key: 'itemTitle',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    width: 150,
    key: 'brand',
  },
  {
    title: 'Qty',
    dataIndex: 'orderQty',
    width: 100,
    key: 'orderQty',
  },
  {
    title: 'Price',
    dataIndex: 'unitPrice',
    width: 100,
    key: 'unitPrice',
    className: 'moneyColumn',
    render: function render(value: string) {
      return true ? '' : toMoney(value);
    },
  },
  {
    title: 'Discount',
    dataIndex: 'discountAmount',
    width: 100,
    key: 'discountAmount',
    className: 'moneyColumn',
    render: function render(value: string) {
      return true ? '' : toMoney(value);
    },
  },
  {
    title: 'Tax',
    dataIndex: 'taxAmount',
    width: 100,
    key: 'taxAmount',
    className: 'moneyColumn',
    render: function render(value: string) {
      return true ? '' : toMoney(value);
    },
  },
  {
    title: 'Item Shipping',
    dataIndex: 'shippingAmount',
    width: 100,
    key: 'shippingAmount',
    className: 'moneyColumn',
    render: function render(value: string) {
      return true ? '' : toMoney(value);
    },
  },
  {
    title: 'Item Total',
    dataIndex: 'itemTotalAmount',
    width: 100,
    key: 'itemTotalAmount',
    className: 'moneyColumn',
    render: function render(value: string) {
      return true ? '' : toMoney(value);
    },
  },
  {
    title: 'Status',
    width: 150,
    dataIndex: 'orderItemStatusName',
    key: 'orderItemStatusName',
  },
];

const contactFieldFormInputStyle1 = { display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' };
const contactFieldFormInputStyle2 = { display: 'inline-block', width: '50%', paddingLeft: '10px' };
const contactFieldStyle = { background: 'white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' };
export const contactFields  = {
  data1: [
    {
      formItemProperties: {
        name: 'name',
        label: 'Name',
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'company',
        label: 'Company',
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'address1',
        label: 'Address',
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'address2',
        wrapperCol: {
          sm: { span: 13 },
          lg: { span: 12 },
          xl: { span: 20, offset: 4 },
        },
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
  ],
  data2: [
    {
      formItemProperties: {
        name: 'city',
        label: 'City',
        style: contactFieldFormInputStyle1,
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'state',
        label: 'State',
        style: contactFieldFormInputStyle1,
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'postalCode',
        label: 'Zip Code',
        style: contactFieldFormInputStyle1,
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
  ],
  data3: [
    {
      formItemProperties: {
        name: 'email',
        label: 'Email',
        style: contactFieldFormInputStyle2,
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
    {
      formItemProperties: {
        name: 'phone',
        label: 'Phone',
        style: contactFieldFormInputStyle2,
      },
      inputProperties: {
        style: contactFieldStyle,
        disabled: true,
      },
      inputType: Type.INPUT,
    },
  ],
};

export const InfoValue: React.FC<{ floatValue?: string, info: string, value: string | number, clipboard?: boolean, infoSpan?: number, valueSpan?: number }> = (
  {
    info,
    value,
    clipboard,
    infoSpan = 10,
    valueSpan = 14,
    floatValue = 'left',
  },
) => {
  const displayValue = value?.toString().length >= 25 ? `${value?.toString().substr(0, 25)}...` : value?.toString().substr(0, 25);
  return (
    <Row>
      <Col span={infoSpan}>
        <Paragraph color="#787878">{info}</Paragraph>
      </Col>
      <Col span={valueSpan}>
        <Paragraph bold color="#2d3f5d" float={floatValue}>{displayValue}</Paragraph>
        {clipboard && (
          <CopyToClipboard text={value?.toString()} onCopy={() => message.success(`${info} Copied to clipboard`)}>
            <span style={{ cursor: 'pointer' }}>
              <FileCopy width={24} height={24} style={{ marginLeft: '15px' }} />
            </span>
          </CopyToClipboard>
        )}
      </Col>
    </Row>
  );
};

export type FormsContextType = { pendingForm?: FormInstance, shippingViewForm?: FormInstance, shippingForm?: FormInstance, billingForm?: FormInstance };

export const FormsContext = React.createContext<FormsContextType>({});


export const setOrderDetailspage = (id: string | undefined, dispatch: any, billingForm: FormInstance, shippingForm: FormInstance, pendingForm: FormInstance) => {
  const handler = async () => {
    if (!id) {
      return Promise.reject();
    }

    const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);
    orderDetailsActions.setLoading(true);
    const order = await fetchOrderDetail(id);
    orderDetailsActions.setOrderDetails(order);
    const carriers = await fetchSystemCarriersAndServices();
    orderDetailsActions.setCarriers(carriers);

    pendingForm.setFieldsValue({
      Pending: order.detail.items.map((i: any) => ({
        'OrderDCAssignmentLineNum': i.orderDCAssignmentLineNum,
        'SKU': i.sku,
        'ShippedQty': i.orderQty,
        'CentralOrderLineNum': i.centralOrderLineNum,
        'UnitPrice': i.unitPrice,
      })),
    });

    return Promise.resolve();
  };
  handler()
    .catch(() => {

    })
    .finally(() => {
      const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);
      orderDetailsActions.setLoading(false);
    });
};
