export default new Map([
  ['/distribution-center', 10002],
  ['/settings-distribution-center-details/:id', 10002],
  ['/settings-distribution-center-routing-rules', 10002],
  ['/channel-orders', 10001],
  ['/export', 10001],
  ['/order-detail/:id', 10001],
  ['/administration-user-management', 10004],
  ['/administration-user-management-permissions/:email', 10004],
  ['/export', 10007],
  ['/shipping', 10006],
]);
