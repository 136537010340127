import React from 'react';
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Switch,
  notification,
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  SaveOutlined,
  UpOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import {
  addControlTowerSubGroup,
  deleteControlTowerSubGroup,
  fetchControlTowerCfgUrc,
  fetchControlTowerSubGroupById,
  setControlTowerCfgUrc,
  updateControlTowerSubGroups,
} from '../../../services/controlTower';
import EditChannelDataFieldName from './EditChannelDataFieldName';

type Props = {
  group: StringKAnyVPair;
  onClose: Function;
  visible: boolean;
};

const DialogBody = styled(Col)`
  width: 100%;

  & .bottom-bar {
    margin-top: 16px;
  }

  & .field-col.center {
    text-align: center;
  }

  & .field-col,
  & .field-col.header {
    width: 33%;
  }

  & .field-col.header {
    margin-top: 16px;
  }

  & .field-col.header,
  & .label-title1 {
    font-size: 14px;
    font-weight: 500;
  }

  & .field-col.name {
    padding-left: 8px;
  }

  & .field-row {
    height: 32px;
  }

  & .field-row:nth-child(even) {
    background-color: #ECECEC;
  }

  & .label-title1:after {
    content: ': ';
    margin-right: 12px;
  }
`;

const EditChannelDataFieldDialog = (props: Props) => {
  const { useState } = React;
  const [channelFieldNameVisible, setChannelFieldNameVisible] = useState(false);
  const [editDict, setEditDict] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [urcValue, setUrcValue] = useState(false);

  const addField = async (name: string) => {
    setIsLoading(true);

    try {
      const item = {
        ControlTowerSubGroupName: name,
        ControlTowerSubGroupDescription: '',
	    DisplaySequence: fields.length,
      };

      const res = await addControlTowerSubGroup(props.group.rowNum, item);

      if (res && typeof res === 'object') {
        loadFields();
      }
    } catch(e) {
      notification.error({
        message: `Add field error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeChannelFieldNameDialog = () => {
    setChannelFieldNameVisible(false);
  };

  const isFieldDataChanged = () => {
    return Object.keys(editDict).length > 0;
  };

  // eslint-disable-next-line
  const loadFields = async () => {
    setIsLoading(true);

    try {
      const urc = await fetchControlTowerCfgUrc();
      const res = await fetchControlTowerSubGroupById(props.group.rowNum);

      console.log('res', res);
      setUrcValue(!!urc);

      if (Array.isArray(res)) {
        sortFieldList(res);
        setFields(res);
      }
    } catch(e) {
      notification.error({
        message: `Load fields error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    props.onClose();
  };

  const onEditFieldName = (name: string) => {
    addField(name);
  };

  const onItemEnableChange = (
    item: StringKAnyVPair,
    checked: boolean,
  ) => {
    if (checked) {
      const fel = fields.filter(f => f.enable).length;
      //console.log(fel, '<--');
      item.displaySequence = fel;
      sortFieldList(fields);
    }

    item.enable = checked;
    setFields([...fields]);
    editDict[item.rowNum] = true;
    setEditDict({...editDict});
  };

  // eslint-disable-next-line
  const onUrcChange = async (checked: boolean) => {
    setIsLoading(true);

    try {
      const res = await setControlTowerCfgUrc(checked ? 1 : 0);

      if (res) setUrcValue(checked);
    } catch(e) {
      notification.error({
        message: `Set report code to identify channel error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const openAddFieldDialog = () => {
    openChannelFieldNameDialog();
  };

  const openChannelFieldNameDialog = () => {
    setChannelFieldNameVisible(true);
  };

  const removeField = async (field: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      const res = await deleteControlTowerSubGroup(field.rowNum);

      //console.log('res--->', res);
      if (res) {
        for (let i = fields.length - 1; i >= 0; i--) {
          if (fields[i].rowNum === field.rowNum) {
            fields.splice(i, 1);
            setFields([...fields]);
            notification.info({
              message: `Deleted field '${field.controlTowerSubGroupName}' successfully`,
              duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
            });
            break;
          }
        }
      }
    } catch(e) {
      notification.error({
        message: `Deleted field error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderFooter = () => {
    return (
      <Row justify="end">
        <Button onClick={onCancel}>
          <CloseOutlined />
          Close
        </Button>
        <Button
          disabled={!isFieldDataChanged()}
          onClick={saveChangedFields}
          type="primary"
        >
          <SaveOutlined />
          Save
        </Button>
      </Row>
    );
  };

  const renderFieldList = () => {
    return fields.map((f, i) => (
      <Row
        align="middle"
        className="field-row"
        key={`field-${f.rowNum}`}
      >
        <div className="field-col name">{f.controlTowerSubGroupName}</div>
        <div className="field-col">
          <Switch
            checked={f.enable}
            checkedChildren="Yes"
            onChange={checked => onItemEnableChange(f, checked)}
            unCheckedChildren="No"
          />
        </div>
        <Row className="field-col" justify="space-between">
          <Space>
          {f.enable && (<>
          <Button
            disabled={i <= 0}
            onClick={() => sortField(f, -1)}
            size="small"
            type="text"
          >
            <UpOutlined />
          </Button>
          <Button
            disabled={i >= fields.filter(f => f.enable).length - 1}
            onClick={() => sortField(f, 1)}
            size="small"
            type="text"
          >
            <DownOutlined />
          </Button>
          </>)}
          </Space>
          <Button
            disabled={!f.canDelete}
            onClick={() => tryRemoveField(f)}
            size="small"
          >
            <DeleteOutlined />
          </Button>
        </Row>
      </Row>
    ));
  };

  const saveChangedFields = async () => {
    const cds: StringKAnyVPair[] = [];

    fields.forEach(f => {
      if (editDict[f.rowNum]) {
        cds.push(f);
      }
    });

    if (cds.length === 0) return;

    console.log('cds', cds, editDict);
    setIsLoading(true);

    try {
      const res = await updateControlTowerSubGroups(props.group.rowNum, cds);

      if (Array.isArray(res)) {
        notification.info({
          message: `Saved fields successfully`,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
        setEditDict({});
      }
    } catch(e) {
      notification.error({
        message: `Saved fields error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sortField = (field: StringKAnyVPair, inc: number) => {
    let idx = -1;

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].rowNum === field.rowNum) {
        idx = i;
        break;
      }
    }

    if (idx > -1) {
      let ti = idx + inc;

      if (ti < 0) ti = 0;
      if (ti > fields.length - 1) ti = fields.length - 1;

      if (idx !== ti) {
        const ms = fields.splice(idx > ti ? idx : ti, 1);

        if (idx > ti) {
          fields.splice(ti, 0, ms[0]);
        } else {
          fields.splice(idx, 0, ms[0]);
        }

        fields.forEach((f, i) => {
          f.displaySequence = i;
          editDict[f.rowNum] = true;
        });
        setEditDict({...editDict});
        setFields([...fields]);
      }
    }
  };

  const sortFieldList = (fl: StringKAnyVPair[]) => {
    const list: StringKAnyVPair[] = [];

    fl.sort((e: StringKAnyVPair, f: StringKAnyVPair) => e.enable ? (e.displaySequence - f.displaySequence) : -1);

    for (let i = fl.length - 1; i > -1; i--) {
      if (fl[i].enable) {
        const f = fl.pop();

        list.push(f as StringKAnyVPair);
      }
    }

    for (let i = list.length; i > 0; i--) {
      const f = list.shift();

      fl.unshift(f as StringKAnyVPair);
    }
  };

  const tryRemoveField = (field: StringKAnyVPair) => {
    console.log('fff', field);
    Modal.confirm({
      title: 'Are you sure',
      content: `Do you want to delete '${field.controlTowerSubGroupName}'`,
      onOk: () => {
        removeField(field);
      },
    });
  };

  React.useEffect(() => {
    if (!inited) {
      loadFields();
      setInited(true);
    }
  }, [inited, loadFields]);

  return (<>
    <Modal
      centered
      destroyOnClose
      footer={renderFooter()}
      maskClosable={false}
      onCancel={onCancel}
      title="Channel Data Fields"
      visible={props.visible}
      width={600}
    >
      <DialogBody>
        {/*<Row align="middle">
          <span className="label-title1">Use Report Code to Identify Channel</span>
          <Switch
            checkedChildren="Yes"
            onChange={onUrcChange}
            unCheckedChildren="No"
            checked={urcValue}
          />
        </Row>*/}
        {fields.length > 0 && (<>
          <Row>
            <div className="field-col header">Field</div>
            <div className="field-col header">Enabled</div>
          </Row>
          {renderFieldList()}
        </>)}
        <Row className="bottom-bar">
          <Button
            onClick={openAddFieldDialog}
          >
            Add More Field
          </Button>
        </Row>
      </DialogBody>
    </Modal>
    {channelFieldNameVisible && (
      <EditChannelDataFieldName
        onClose={closeChannelFieldNameDialog}
        onEditName={onEditFieldName}
        visible={channelFieldNameVisible}
      />
    )}
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default EditChannelDataFieldDialog;
