import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Tabs, Form, Row, Col, Input, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import TableComponent from '../../components/TableComponent';
import CheckMark from '../../assets/icons/checkmark';
import EditIcon from '../../assets/icons/edit';
import CancelIcon from '../../assets/icons/clear';
import DeleteIcon from '../../assets/icons/delete';
import {
  activateInvitation,
  deleteProfile,
  editUser, fetchProfiles,
  fetchuserManagement, fetchUserPermissions,
  registerNewProfile,
  resendInvitation,
} from '../../services/userManagement';
import EditableCell from '../../components/UserManagement/EditableCell';
import AddUserModal from '../../components/UserManagement/AddUserModal';
import ConfirmationModal from '../../components/UserManagement/ConfirmationModal';
import notificationActions from '../../redux/actions/notifications';
import profileActions from '../../redux/actions/profiles';
import { leftTrim, useDebounce } from '../../util';
import Heading, { Title } from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import PermissionDialog from './PermissionDialog';

const { TabPane } = Tabs;


const AddButton = styled(Button)`
  width: 140px;
  height: 40px;
`;

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;


const GrayTableButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #f5f6fa;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #006dff;
  border-width: 0px;
`;

const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left;
`;

export interface Item {
  key: string;
  UserName: string;
  Email: string;
  RowNum: string;
  Status: number;
}

function UserManagement(props: any) {
  const [ data, setData ] = useState<Item[]>([]);
  const [ filteredDataCurrentUsers, setFilteredDataCurrentUsers ] = useState<Item[]>([]);
  const [ filteredDataOpenInvitation, setFilteredDataOpenInvitation ] = useState<Item[]>([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [permissionDialogVisible, setPermissionDialogVisible] = useState(false);
  const [activateUserConfirmation, setActivateUserConfirmation] = useState({
    show: false,
    status: '',
    message: '',
  });
  const [permissionUser, setPermissionUser] = useState<StringKAnyVPair>({});
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelecedUser] = useState({
    key: '',
    UserName: '',
    Email: '',
    RowNum: '',
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleShowModal = () => setShowModal(true);
  const handleCancel = () => {
    setShowModal(false);
    setShowConfirmation(false);
  };
  const handleConfirmationModal = (record: Item) => {
    setSelecedUser(record);
    setShowConfirmation(true);
  };

  const isEditing = (record: Item) => record.RowNum === editingKey;

  const edit = (record: Item) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.RowNum);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const closePermissionDialog = () => {
    setPermissionUser({});
    setPermissionDialogVisible(false);
  };

  const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
    if (permissions && permissions.size === 0) return false;
    return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      if (!row.UserName.trim()) {
        form.setFieldsValue({ ...row, UserName: leftTrim(row.UserName) });
        return;
      }
      const newData = [...data];
      const index = newData.findIndex(item => key === item.RowNum);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setFilteredDataCurrentUsers(newData.filter(d => d.Status));
        setFilteredDataOpenInvitation(newData.filter(d => !d.Status));
        setEditingKey('');
        await editUser({
          Email: item.Email,
          body: {
            ChangedUserName: row.UserName,
          },
        });
        props.actions.setNotification('success', 'User updated');
        if (props.profilesEntity.profiles[0]?.Email === item?.Email) {
          const response = await fetchProfiles();
          const permissions = await fetchUserPermissions(response[0].Email);
          props.actionsProfile.setProfiles(response);
          props.actionsProfile.setPermissions(permissions);
        }
      } else {
        newData.push(row);
        setData(newData);
        setFilteredDataCurrentUsers(newData.filter(d => d.Status));
        setFilteredDataOpenInvitation(newData.filter(d => !d.Status));
        setEditingKey('');
      }
    } catch (errInfo) {
      throw errInfo;
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'UserName',
      key: 'UserName',
      editable: true,
      width: '30%',
      sorter: (a: any, b: any) => a.UserName.localeCompare(b.UserName),
      render: (text: string, record: Item, index: number) => (<span id={`current_users_grid_name_row_${index}`}>{text}</span>)
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      width: '30%',
      sorter: (a: any, b: any) => a.Email.localeCompare(b.Email),
      render: (text: string, record: Item, index: number) => (<span id={`current_users_grid_email_row_${index}`}>{text}</span>)
    },
    {
      title: '',
      dataIndex: 'RowNum',
      render: (text: string, record: Item, index:number) => (
        <GrayTableButton
          id={`current_users_grid_action_manage_permissions_row_${index}`}
          onClick={() => handlePermissionsRedirect(record)}
          style={{
            display: Number(text) === Number(editingKey) ? 'none' : '',
          }}
        >
          Manage Permissions
        </GrayTableButton>
      ),
      key: 'RowNum',
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '15%',
      render: (_: any, record: Item, index: number) => {
        const viewPermissionLevel = 1;
        const manageProfileUsersPermissionNum = 10003;
        const editable = isEditing(record);
        return editable ? (
          <div>
            <TableButtonWrapper id={`current_users_grid_action_save_row_${index}`} onClick={() => save(record.RowNum)} style={{ marginRight: 8 }}>
              <CheckMark height={25} width={25} style={{ fill: '#02a800' }} />
            </TableButtonWrapper>
            <TableButtonWrapper id={`current_users_grid_action_cancel_row_${index}`} onClick={() => cancel()} style={{ marginRight: 8 }}>
              <CancelIcon height={25} width={25} style={{ fill: '#2d3f5d' }} />
            </TableButtonWrapper>
          </div>
        ) : hasPermissions(props.profilesEntity.permissions, manageProfileUsersPermissionNum, viewPermissionLevel) && (
          <>
            <TableButtonWrapper id={`current_users_grid_action_edit_row_${index}`} onClick={() => edit(record)}>
              <EditIcon height={25} width={25} style={{ fill: '#006dff' }} />
            </TableButtonWrapper>
            <TableButtonWrapper id={`current_users_grid_action_delete_row_${index}`} onClick={() => handleConfirmationModal(record)} style={{ marginLeft: 10 }}>
              <DeleteIcon height={25} width={25} style={{ fill: '#c13939' }} />
            </TableButtonWrapper>
          </>
        );
      },
    },
  ];

  const columnsOpenInvitations = [
    {
      title: 'Name',
      dataIndex: 'UserName',
      key: 'UserName',
      editable: true,
      width: '30%',
      render: (text: string, record: Item, index: number) => (<span id={`open_invitation_grid_name_row_${index}`}>{text}</span>)
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      width: '30%',
      render: (text: string, record: Item, index: number) => (<span id={`open_invitation_grid_email_row_${index}`}>{text}</span>)
    },
    {
      title: '',
      dataIndex: 'RowNum',
      render: (text: string, record: Item, index: number) => (
        <Row gutter={[4, 0]}>
          <Col span={12}>
            <GrayTableButton id={`open_invitation_grid_action_resend_row_${index}`} onClick={() => handleResendInvitation(record.Email)}>Resend</GrayTableButton>
          </Col>
          <Col span={12}>
            <GrayTableButton id={`open_invitation_grid_action_active_row_${index}`} onClick={() => handleActivateUser(record.Email)}>Activate</GrayTableButton>
          </Col>
        </Row>
      ),
      key: 'RowNum',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '10%',
      render: (_: any, record: Item, index: number) => (
        <>
          <TableButtonWrapper id={`open_invitation_grid_action_delete_row_${index}`}  onClick={() => handleConfirmationModal(record)} style={{ marginLeft: 10 }}>
            <DeleteIcon height={25} width={25} style={{ fill: '#c13939' }} />
          </TableButtonWrapper>
        </>
      ),
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchuserManagement();
      setLoading(false);
      setData(response);
      setFilteredDataCurrentUsers(response.filter((d: Item) => d.Status));
      setFilteredDataOpenInvitation(response.filter((d: Item) => !d.Status));
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const addNewProfile = async (params: any) => {
    try {
      await registerNewProfile({ ...params, Email: params.Email.trim() });
      fetchData();
      props.actions.setNotification('success', 'User created');
    } catch (e) {
      throw e;
    }
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData();
    }, []);
  };

  useFetch();

  const handleConfirmation = async  () => {
    try {
      await deleteProfile(selectedUser.Email);
      fetchData();
      props.actions.setNotification('success', 'User deleted');
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      const filtered = data.filter(f => f.UserName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) || f.Email.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
      setFilteredDataCurrentUsers(filtered.filter(d => d.Status));
      setFilteredDataOpenInvitation(filtered.filter(d => !d.Status));
    } else {
      setFilteredDataCurrentUsers(data.filter(d => d.Status));
      setFilteredDataOpenInvitation(data.filter(d => !d.Status));
    }
  }, [data, debouncedSearchTerm]);

  const handleResendInvitation = async  (email: string) => {
    try {
      await resendInvitation(email);
      props.actions.setNotification('success', 'Invitation sent');
    } catch (e) {
      throw e;
    }
  };

  const handleActivateUser = async  (email: string) => {
    try {
      await activateInvitation(email);
      setActivateUserConfirmation({
        show: true,
        status: 'Success',
        message: `Email ${email} was activated successfully`,
      });
      fetchData();
    } catch (e) {
      setActivateUserConfirmation({
        show: true,
        status: 'Error',
        message: `Could not activate ${email}, please try again later`,
      });
      throw e;
    }
  };

  const handlePermissionsRedirect = (record: Item) => {
    // props.history.push(`/administration-user-management-permissions/${record.Email}`);
    setPermissionUser(record as StringKAnyVPair);
    setPermissionDialogVisible(true);
  };

  const handleActivateUserModalConfirmationClose = () => {
    setActivateUserConfirmation({ show: false, status: '', message: '' });
  };

  return (
    <>
      <ContentLayout>
        <AddUserModal addUser={addNewProfile} handleCancel={handleCancel} visible={showModal} />
        <ConfirmationModal
          visible={showConfirmation}
          data={selectedUser}
          handleCancel={handleCancel}
          handleConfirm={handleConfirmation}
        />
        <Modal
          onCancel={handleActivateUserModalConfirmationClose}
          visible={activateUserConfirmation.show}
          title={<TitleHeader  id="users_active_user_modal_title">{activateUserConfirmation.status}</TitleHeader>}
          footer={(<Button id="users_active_user_modal_close_button" onClick={handleActivateUserModalConfirmationClose}>Close</Button>)}
        >
          <p>{activateUserConfirmation.message}</p>
        </Modal>
        <SiteContent>
          <Heading
            actions={
              <AddButton id="add_new_user_button" type="primary" onClick={handleShowModal}>Add new user</AddButton>
          }
          >
            <Row>
              <Title id="user_page_title">Users</Title>
            </Row>
          </Heading>
        </SiteContent>
        <Spacer />
        <SiteContent>
          <Row>
            <Col span={8}>
              <Input id="user_page_filter_input" prefix={<SearchOutlined />} allowClear onChange={(e: ChangeEvent<HTMLInputElement>) => {setSearchTerm(e.target.value);}} />
            </Col>
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Current Users" key="1">
                  <Form form={form} component={false}>
                    <TableComponent
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      rowKey="RowNum"
                      rowClassName={() => 'editable-row'}
                      loadingStatus={loading}
                      dataSource={filteredDataCurrentUsers}
                      pagination={{ showSizeChanger: true }}
                      columns={mergedColumns}
                    />
                  </Form>
                </TabPane>
                <TabPane tab="Open Invitation" key="2">
                  <TableComponent
                    loadingStatus={loading}
                    dataSource={filteredDataOpenInvitation}
                    pagination={{ showSizeChanger: true }}
                    columns={columnsOpenInvitations}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </SiteContent>
      </ContentLayout>
      {permissionDialogVisible && (
      <PermissionDialog
        onClose={closePermissionDialog}
        user={permissionUser}
        visible={permissionDialogVisible}
      />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({ profilesEntity: state.profiles });

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActions, dispatch),
  actionsProfile: bindActionCreators(profileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
