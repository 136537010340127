import { FormInstance } from 'antd';
import { IntegrationsActions, IntegrationsActionTypes } from '../types/integrations';

const setForms = ({ form, settingsForm }: { form: FormInstance, settingsForm: FormInstance }) => ({
  type: IntegrationsActions.SET_FORMS,
  value: { form, settingsForm },
});

const removeForms = () => ({ type: IntegrationsActions.REMOVE_FORMS });

const setLoading = (value: boolean): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_LOADING,
  value,
});

const setPageTitle = (value: string): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_PAGETITLE,
  value,
});

const setChannels = (groupedChannels: Entities.ChannelProfile[][], channels: Entities.ChannelProfile[]): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_CHANNELS,
  groupedChannels,
  channels,
});

const setChannelFields = (value: any): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_CHANNELFIELDS,
  value,
});

const setOAuthURL = (value?: string): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_OAUTHURL,
  value,
});

const setForm = (value: FormInstance): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_FORM,
  value,
});

const setIsActivated = (value: boolean): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_ISACTIVATED,
  value,
});

const setVerifyConnection = (value: boolean): IntegrationsActionTypes => ({
  type: IntegrationsActions.SET_VERIFY_CONNECTION,
  value,
});

const setChannelAccount = (value?: Entities.ChannelAccountObj | null) => ({
  type: IntegrationsActions.SET_CHANNEL_ACCOUNT,
  value,
});

const setProfileSettings = (value?: FormModels.IntegrationSettings) => ({
  type: IntegrationsActions.SET_PROFILE_SETTINGS,
  value,
});

const setRetailers = (value?: Entities.ChannelProfile[]) => ({
  type: IntegrationsActions.SET_RETAILERS,
  value,
});

const setGlobalEnabled = (value?: Entities.ChannelProfile[]) => ({
  type: IntegrationsActions.SET_GLOBALENABLED,
  value,
});

const setGeneralData = (value: { channels: Entities.ChannelProfile[], retailers: Entities.ChannelProfile[], platforms: Entities.Platform[], groupedChannels: Entities.ChannelProfile[][], globalEnabled: Entities.ChannelProfile[]}) => ({
  type: IntegrationsActions.SET_GENERAL_DATA,
  value,
});

const clearCurrentProfile = ({ editMode = false }: { editMode?: boolean } = {}) => ({
  type: IntegrationsActions.CLEAR_CURRENT_PROFILE,
  value: { editMode },
});

const setEditMode = (value: boolean) => ({ type: IntegrationsActions.SET_EDIT_MODE, value });

const cancelEdition = () => ({ type: IntegrationsActions.CANCEL_EDITION });

const setFormDirtyStatus = (value: boolean) => ({
  type: IntegrationsActions.SET_FORM_DIRTY_STATUS,
  value,
});

const setSavingSettings = (value: boolean) => ({ type: IntegrationsActions.SET_SAVING_SETTINGS, value });

const setFormHasErrors = (value: boolean) => ({ type: IntegrationsActions.SET_FORM_HAS_ERRORS, value });

const setSettingsFormHasErrors = (value: boolean) => ({
  type: IntegrationsActions.SET_SETTINGS_FORM_HAS_ERRORS,
  value,
});

const resetErrorStatus = () => ({ type: IntegrationsActions.RESET_ERROR_STATUS });

const actions = {
  setForms,
  setProfileSettings,
  setLoading,
  setPageTitle,
  setRetailers,
  setChannels,
  setChannelFields,
  setOAuthURL,
  setIsActivated,
  setVerifyConnection,
  setForm,
  setChannelAccount,
  setGeneralData,
  clearCurrentProfile,
  setEditMode,
  cancelEdition,
  removeForms,
  setFormDirtyStatus,
  setSavingSettings,
  setFormHasErrors,
  setSettingsFormHasErrors,
  resetErrorStatus,
  setGlobalEnabled,
};

export default actions;
