import React, { useState } from "react";
import { Button, Badge, Modal, List, Typography, Space, Row } from "antd";
import { WarningOutlined, ClearOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { connect } from "react-redux";
import errorMessageActions from "../../redux/actions/errorMessage";
import { MsgItem } from "../../redux/reducers/errorMessage";
import { bindActionCreators } from "redux";
import moment from "moment";

const ButtonSpacer = styled(Button)`
  margin-right: 16px;
  display: flex;
  padding: 0px;
`;

const ErrorMessageBox = (props: any) => {
  const [boxVisible, setBoxVisible] = useState(false);

  return (
    <>
      {props.errorMessage.count ? (
        <ButtonSpacer
          className="header-btn1"
          ghost
          onClick={(e) => {
            setBoxVisible(true);
          }}
        >
          <Badge
            count={props.errorMessage.count}
            size="small"
            status="error"
            offset={[0, 4]}
          >
            <WarningOutlined style={{ fontSize: 24 }} />
          </Badge>
        </ButtonSpacer>
      ) : null}
      {boxVisible && (
        <Modal
          title="Error Message Box"
          centered
          visible={true}
          maskClosable={false}
          width={900}
          bodyStyle={{
            maxHeight: 600,
            overflowY: "auto",
          }}
          destroyOnClose={true}
          onCancel={() => {
            props.actions.closeBox();
            setBoxVisible(false);
          }}
          getContainer={false}
          footer={null}
        >
          <List
            size="small"
            bordered
            header={
              <Row justify="space-between">
                <Typography.Text>{`Total: ${props.errorMessage.msgs.length}`}</Typography.Text>
                <Button
                  size="small"
                  icon={<ClearOutlined />}
                  onClick={() => {
                    props.actions.clearAll();
                    setBoxVisible(false);
                  }}
                >
                  Clear All
                </Button>
              </Row>
            }
            dataSource={props.errorMessage.msgs}
            renderItem={(item: MsgItem) => (
              <List.Item
                onClick={() => {
                  props.actions.setIsRead(item.id);
                }}
              >
                <List.Item.Meta
                  title={
                    <Space size="small">
                      <Typography.Text>
                        [
                        <Typography.Text mark>
                          {item.statusCode}
                        </Typography.Text>
                        ]
                      </Typography.Text>
                      <Typography.Text>
                        [
                        <Typography.Text
                          mark
                        >{`${item.method}`}</Typography.Text>
                        ]
                      </Typography.Text>
                      <Typography.Text
                        style={{ width: 520 }}
                        ellipsis
                        copyable={{ text: `${item.url}\n${item.response}` }}
                      >
                        {item.url}
                      </Typography.Text>
                    </Space>
                  }
                  description={
                    <Typography.Paragraph
                      ellipsis={{ rows: 3 }}
                      type="secondary"
                    >
                      {item.response}
                    </Typography.Paragraph>
                  }
                ></List.Item.Meta>
                <Space size="small">
                  <Badge dot={!item.isRead}>
                    {moment(item.time).format("MM/DD/YYYY HH:mm:ss")}
                  </Badge>
                  <Button
                    style={{ padding: 0 }}
                    type="text"
                    icon={<ClearOutlined />}
                    onClick={(e) => {
                      props.actions.clearOne(item.id);
                      e.preventDefault();
                    }}
                  />
                </Space>
              </List.Item>
            )}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(errorMessageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageBox);
