import React, { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Col, Form, FormInstance, Row, Spin } from 'antd';
import moment from 'moment';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from '../../components/ContentLayout';
import Spacer from '../../components/common/Spacer';
import SiteContent from '../../components/SiteContent';
import Heading from '../../components/common/Heading';
import SalesOrderDetailsActions from '../../redux/actions/salesOrderDetails';
import { Permissions } from '../../constants/enums/permissions';
import { TopHeaderButtonsWrapper } from '../ProductDetail/styledComponents';
import { HeaderButton } from '../../components/common/styledComponents';
import Info from '../../assets/icons/info';
import ShippingIcon from '../../assets/icons/shipping';
import Money from '../../assets/icons/money';
// eslint-disable-next-line import/no-cycle
import Details from './Tabs/Details';
import {
  createSalesOrder,
  deleteSalesOrder,
  fetchCustomers,
  fetchSalesOrderDetail,
  saveSalesOrder,
} from '../../services/salesOrders';
import { ControlsWrapper } from '../OrderDetails/styledComponents';
import ConfirmationModal from '../../components/ConfirmationModal';
import FormButtons from '../../components/common/FormButtons';
import notificationActions from '../../redux/actions/notifications';
import { sumObjectArrayValues } from '../../util/strings';
import { calculateTotals, FormsContext, FormsContextType } from './common';
import { InfoValue } from '../OrderDetails/common';

const setSalesOrder = (detailsForm: FormInstance, shippingForm: FormInstance, billingForm: FormInstance, shippingProductsTableForm: FormInstance, order: Entities.SalesOrderDetails, dispatch: any) => {
  const discountType = order.salesOrderHeader.discountRate > 0 ? 'discountRate' : 'discountAmount';
  const discount = order.salesOrderHeader.discountRate > 0 ? order.salesOrderHeader.discountRate : order.salesOrderHeader.discountAmount;
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  detailsForm.setFieldsValue({
    rowNumHeader: order.salesOrderHeader.rowNum,
    rowNumHeaderInfo: order.salesOrderHeaderInfo.rowNum,
    customerName: order.salesOrderHeader.customerName,
    customerCode: order.salesOrderHeader.customerCode,
    orderType: order.salesOrderHeader.orderType,
    discountRate: order.salesOrderHeader.discountRate,
    discountAmnt: order.salesOrderHeader.discountAmount,
    shippingAmount: order.salesOrderHeader.shippingAmount,
    terms: order.salesOrderHeader.terms,
    miscAmount: order.salesOrderHeader.miscAmount,
    termsDays: order.salesOrderHeader.termsDays,
    chargeAndAllowanceAmount: order.salesOrderHeader.chargeAndAllowanceAmount,
    orderDate: moment(order.salesOrderHeader.orderDate),
    refNum: order.salesOrderHeaderInfo.refNum,
    customerPoNum: order.salesOrderHeaderInfo.customerPoNum,
    notes: order.salesOrderHeaderInfo.notes,
    discountType,
    discount,
  });
  shippingProductsTableForm.setFieldsValue({
    ShippingProducts: order.salesOrderItems,
  });
  shippingForm.setFieldsValue({
    name: order.salesOrderHeaderInfo.shipToName,
    company: order.salesOrderHeaderInfo.shipToCompany,
    address1: order.salesOrderHeaderInfo.shipToAddressLine1,
    address2: order.salesOrderHeaderInfo.shipToAddressLine2,
    city: order.salesOrderHeaderInfo.shipToCity,
    state: order.salesOrderHeaderInfo.shipToState,
    postalCode: order.salesOrderHeaderInfo.shipToPostalCode,
    email: order.salesOrderHeaderInfo.shipToEmail,
    phone: order.salesOrderHeaderInfo.shipToDaytimePhone,
  });
  billingForm.setFieldsValue({
    name: order.salesOrderHeaderInfo.billToName,
    company: order.salesOrderHeaderInfo.billToCompany,
    address1: order.salesOrderHeaderInfo.billToAddressLine1,
    address2: order.salesOrderHeaderInfo.billToAddressLine2,
    city: order.salesOrderHeaderInfo.billToCity,
    state: order.salesOrderHeaderInfo.billToState,
    postalCode: order.salesOrderHeaderInfo.billToPostalCode,
    email: order.salesOrderHeaderInfo.billToEmail,
    phone: order.salesOrderHeaderInfo.billToDaytimePhone,
  });
  salesOrderDetailsActions.setDetailsProducts(order.salesOrderItems);
  calculateTotals(
    discount,
    order.salesOrderHeader.shippingAmount,
    sumObjectArrayValues(order.salesOrderItems, 'itemTotalAmount'),
    discountType,
    sumObjectArrayValues(order.salesOrderItems, 'taxAmount'),
    order.salesOrderHeader.miscAmount,
    dispatch,
  );
};

const useSalesOrderDetailsPageLoad = (id: string): FormsContextType => {
  const dispatch = useDispatch();
  const [shippingForm] = Form.useForm();
  const [billingForm] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [shippingProductsTableForm] = Form.useForm();
  const [forms, setForms] = useState<FormsContextType>({});

  useEffect(() => {
    setForms({
      shippingForm,
      billingForm,
      detailsForm,
      shippingProductsTableForm,
    });
  }, [shippingForm, billingForm, detailsForm, shippingProductsTableForm]);

  useEffect(() => {
    const handler = async () => {
      const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
      salesOrderDetailsActions.setLoading(true);
      const customers = await fetchCustomers({
        $top: 0,
        $skip: 0,
        $count: true,
        $loadAll: true,
      });
      const keys = ['customerUuid'];
      const filteredDuplicates = customers.filter(
        ((s: any) => (o: any) =>
          (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|'))
        )(new Set()),
      );

      salesOrderDetailsActions.setSalesOrdersCustomers(filteredDuplicates);
      if (id) {
        const salesOrder = await fetchSalesOrderDetail(id);
        salesOrderDetailsActions.setSalesOrderDetails(salesOrder);
        setSalesOrder(detailsForm, shippingForm, billingForm, shippingProductsTableForm, salesOrder, dispatch);
      } else {
        detailsForm.setFieldsValue({
          shippingAmount: 0,
          miscAmount: 0,
          discount: 0,
          discountType: 'discountRate',
        });
        shippingForm.setFieldsValue({});
        billingForm.setFieldsValue({});
        shippingProductsTableForm.setFieldsValue({
          ShippingProducts: [],
        });
      }

      return Promise.resolve();
    };
    handler()
      .catch(() => {

      })
      .finally(() => {
        const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
        salesOrderDetailsActions.setLoading(false);
      });
  }, [id, dispatch, shippingForm, billingForm, detailsForm, shippingProductsTableForm]);

  return forms;
};

const PageHeading: React.FC = () => {
  const orderNum = useSelector((state: any) => state.salesOrderDetails.order?.detail?.summary?.centralOrderNum);
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  const activeView = useSelector((state: any) => state.salesOrderDetails.activeView);
  return (
    <Heading
      title={`Sales Order - ${orderNum || 'New'}`}
      actions={(
        <TopHeaderButtonsWrapper>
          <HeaderButton
            $hasPermission
            type={activeView === 'details' ? 'primary' : 'default'}
            onClick={() => salesOrderDetailsActions.setActiveView('details')}
          >
            Details
            <Info
              width={22}
              height={22}
              pathColor={activeView === 'details' ? '#fff' : ''}
            />
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'shipping' ? 'primary' : 'default'}
            onClick={() => salesOrderDetailsActions.setActiveView('shipping')}
          >
            Shipping
            <ShippingIcon
              width={22}
              height={22}
              pathColor={activeView === 'shipping' ? '#fff' : ''}
            />
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'invoice' ? 'primary' : 'default'}
            onClick={() => salesOrderDetailsActions.setActiveView('invoice')}
          >
            Invoice
            <Money
              width={22}
              height={22}
              pathColor={activeView === 'invoice' ? '#fff' : ''}
            />
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'refund' ? 'primary' : 'default'}
            onClick={() => salesOrderDetailsActions.setActiveView('refund')}
          >
            Refund
            <Money
              width={22}
              height={22}
              pathColor={activeView === 'refund' ? '#fff' : ''}
            />
          </HeaderButton>
        </TopHeaderButtonsWrapper>
      )}
    />
  );
};

const HeaderInfo: React.FC = () => {
  const salesOrder = useSelector((state: any) => state.salesOrderDetails.salesOrder);
  return (
    <Row>
      <Col span={9}>
        <InfoValue info="Order #" value={salesOrder?.salesOrderHeader?.orderNumber} clipboard infoSpan={9} valueSpan={15} />
        <InfoValue info="Channel Order #" value={salesOrder?.salesOrderHeaderInfo?.channelOrderID} clipboard infoSpan={9} valueSpan={15} />
        <InfoValue info="Customer Code" clipboard infoSpan={9} valueSpan={15} value={salesOrder?.salesOrderHeader?.customerCode} />
      </Col>
      <Col span={8}>
        <InfoValue info="Channel" infoSpan={9} valueSpan={15} value="" />
        <InfoValue info="Import Date" value="May 20 2021" />
        <InfoValue info="Ship Date" value="May 20 2021" />
      </Col>
      <Col span={7}>
        <InfoValue info="Shipping Status" value="Shipped" />
        <InfoValue info="Payment Status" value="Paid" />
      </Col>
    </Row>
  );
};

const MainView: React.FC = () => {
  const activeView = useSelector((state: any) => state.salesOrderDetails.activeView);
  switch (activeView) {
    case 'details':
      return (<Details />);
    default: return null;
  }
};

export const Controls: React.FC = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const editMode = useSelector((state: any) => state.salesOrderDetails.editMode);
  const isFormUpdated = useSelector((state: any) => state.salesOrderDetails.isFormUpdated);
  const forms = useContext(FormsContext);
  const { shippingForm, billingForm, detailsForm, shippingProductsTableForm } = forms;
  const order = useSelector((state: any) => state.salesOrderDetails.salesOrder);
  const detailsProducts = useSelector((state: any) => state.salesOrderDetails.detailsProducts);
  const totals = useSelector((state: any) => state.salesOrderDetails.totals);
  const dispatch = useDispatch();
  const notifications = bindActionCreators(notificationActions, dispatch);
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  if (!shippingForm || !billingForm || !detailsForm || !shippingProductsTableForm) {
    return null;
  }

  const saveSaleOrder = async () => {
    salesOrderDetailsActions.setLoading(true);
    try {
      const detailsFormData = detailsForm.getFieldsValue();
      const shippingFormData = shippingForm.getFieldsValue();
      const billingFormData = billingForm.getFieldsValue();
      const payload = {
        salesOrder: {
          salesOrderHeader: {
            orderType: detailsFormData.orderType,
            rowNum: detailsFormData.rowNumHeader,
            orderNumber: id || null,
            customerName: detailsFormData.customerName,
            customerCode: detailsFormData.customerCode,
            terms: detailsFormData.terms,
            termsDays: detailsFormData.termsDays,
            orderDate: detailsFormData.orderDate,
            discountRate: totals.discountRate,
            discountAmount: totals.discountAmount,
            shippingAmount: totals.shippingAmount,
            miscAmount: totals.miscAmount,
            subTotalAmount: totals.subTotalAmount,
            totalAmount: totals.totalAmount,
            taxAmount: totals.taxAmount,
            chargeAndAllowanceAmount: detailsFormData.chargeAndAllowanceAmount,
          },
          salesOrderHeaderInfo: {
            rowNum: detailsFormData.rowNumHeaderInfo,
            shippingCarrier: detailsFormData.shippingCarrier,
            notes: detailsFormData.notes,
            refNum: detailsFormData.refNum,
            customerPoNum: detailsFormData.customerPoNum,
            billToName: billingFormData.name,
            billToCompany: billingFormData.company,
            billToAddressLine1: billingFormData.address1,
            billToAddressLine2: billingFormData.address2,
            billToCity: billingFormData.city,
            billToState: billingFormData.state,
            billToPostalCode: billingFormData.postalCode,
            billToEmail: billingFormData.email,
            billToDaytimePhone: billingFormData.phone,
            shipToName: shippingFormData.name,
            shipToCompany: shippingFormData.company,
            shipToAddressLine1: shippingFormData.address1,
            shipToAddressLine2: shippingFormData.address2,
            shipToCity: shippingFormData.city,
            shipToState: shippingFormData.state,
            shipToPostalCode: shippingFormData.postalCode,
            shipToEmail: shippingFormData.email,
            shipToDaytimePhone: shippingFormData.phone,
          },
          salesOrderItems: detailsProducts,
        },
      };
      if (id) {
        await saveSalesOrder(payload);
      } else {
        await createSalesOrder(payload);
      }

      return await Promise.resolve();
    } catch (e) {
      salesOrderDetailsActions.setLoading(false);
      notifications.setNotification('error', `There was an error saving the sale. ${e}`);
      return await Promise.reject(e);
    } finally {
      salesOrderDetailsActions.setLoading(false);
      salesOrderDetailsActions.setEditMode(false);
    }
  };

  const onSave = () => {
    saveSaleOrder()
      .then(() => notifications.setNotification('success', 'Order saved'))
      .catch(() => notifications.setNotification('error', 'There was an error'))
      .finally(() => {
        if (!id) {
          history.push('/sales-orders');
        }
      });
  };

  const resetOrder = () => {
    salesOrderDetailsActions.setEditMode(false);
    salesOrderDetailsActions.setIsFormUpdated(false);
    setSalesOrder(detailsForm, shippingForm, billingForm, shippingProductsTableForm, order, dispatch);
    setShowConfirm(false);
  };

  const deleteOrder = async () => {
    try {
      await deleteSalesOrder(order.salesOrderHeader.rowNum);
      salesOrderDetailsActions.setLoading(false);
      return await Promise.resolve();
    } catch (e) {
      salesOrderDetailsActions.setLoading(false);
      return await Promise.reject(e);
    } finally {
      history.push('/sales-orders');
    }
  };
  return (
    <ControlsWrapper float="right">
      <ConfirmationModal
        handleCancel={() => setShowConfirm(false)}
        handleConfirm={resetOrder}
        visible={showConfirm}
        title="Are you sure you want to cancel?"
        confirmText="Yes"
        cancelText="No"
      />
      <ConfirmationModal
        handleCancel={() => setShowConfirmDelete(false)}
        handleConfirm={deleteOrder}
        visible={showConfirmDelete}
        title="Are you sure you want to delete this order?"
        confirmText="Yes"
        cancelText="No"
      />
      <FormButtons
        editingMode={editMode}
        onDelete={() => setShowConfirmDelete(true)}
        permissionNumber={Permissions.MANAGE_ORDERS}
        onEdit={() => {
          salesOrderDetailsActions.setEditMode(true);
        }}
        onSave={onSave}
        onCancel={() => {
          if (isFormUpdated) {
            setShowConfirm(true);
          } else {
            salesOrderDetailsActions.setEditMode(false);
          }
        }}
        disableSave={editMode && !isFormUpdated}
      />
    </ControlsWrapper>
  );
};

const SalesOrderDetailsScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const loading = useSelector((state: any) => state.salesOrderDetails.loading);
  const isFormUpdated = useSelector((state: any) => state.salesOrderDetails.isFormUpdated);
  const forms =  useSalesOrderDetailsPageLoad(id);
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  if (!id) {
    salesOrderDetailsActions.setEditMode(true);
  }
  return (
    <FormsContext.Provider value={forms}>
      <ContentLayout>
        {id && (
          <Prompt
            when={isFormUpdated}
            message="You have unsaved changes, are you sure you want to exit?"
          />
        )}
        <Spin spinning={loading}>
          <PageHeading />
          <Spacer />
          <SiteContent>
            <HeaderInfo key="header" />
          </SiteContent>
          <Spacer />
          <SiteContent>
            <Row>
              <Col span={24}>
                <Controls />
              </Col>
            </Row>
            <MainView />
          </SiteContent>
        </Spin>
      </ContentLayout>
    </FormsContext.Provider>
  );
};

export default SalesOrderDetailsScreen;
