import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { digitBridgeApi, erpAPI } from './http';

// Used for RTK Query
export const digitBridgeBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<{
  url: string,
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
  params?: AxiosRequestConfig['params'],
  headers?: AxiosRequestConfig['headers'],
}, unknown, unknown> => async ({ url, method, data, params, headers }) => {
  try {
    const response = await digitBridgeApi({ url: baseUrl + url, method, data, params, headers });
    return { data: response };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const erpBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<{
  url: string,
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
  params?: AxiosRequestConfig['params'],
}, unknown, unknown> => async ({ url, method, data, params }) => {
  try {
    const response = await erpAPI({ url: baseUrl + url, method, data, params });
    return { data: response };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};
