import { erpAPI } from './http';

interface FetchCustomersResponse {
  customerUuids?: string[];
  customerList: Entities.Customer[];
  customerListCount: number;
  $top?: number;
  $skip?: number;
  $count?: boolean;
  $loadAll?: boolean;
  success?: boolean;
}

// fetch customers
export const fetchCustomers = async (): Promise<FetchCustomersResponse> => {
  const { data } = await erpAPI.post('/customers/find', { $count: true, $loadAll: true });
  return {
    ...data,
    customerList: data.customerList || [],
  };
};

// fetch one customer
export const fetchCustomer = async (customerCode: string): Promise<Entities.CustomerPostBody> => {
  const { data: { customer } } = await erpAPI.get(`/customers/${customerCode}`);

  return customer;
};

// create a new customer
export const createCustomer = async (data: { customer:Entities.CustomerPostBody }): Promise<Entities.CustomerPostBody> => {
  const { data: { customer, success, messages } } = await erpAPI.post('/customers', data);
  if (!success) {
    throw (messages[0].message);
  }
  return customer;
};

// make changes to existing customer
export const saveCustomer = async (data: { customer:Entities.CustomerPostBody }): Promise<Entities.CustomerPostBody> => {
  const { data: { customer, success, messages } } = await erpAPI.patch('/customers', data);
  if (!success) {
    throw (messages[0].message);
  }
  return customer;
};

// delete customer
export const deleteCustomer = async (customerCode: string): Promise<Entities.CustomerPostBody> => {
  const { data: { customer, success, messages } } = await erpAPI.delete(`/customers/${customerCode}`);
  if (!success) {
    throw (messages[0].message);
  }
  return customer;
};
