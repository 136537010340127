import React, { useCallback, useState } from "react";
import {
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Typography, Popover, message, Row, Image } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import Styled from "styled-components";
import ImagePlaceholder from "../../assets/images/preview_image.jpeg";

const { Text, Paragraph, Link } = Typography;

type CellProps = {
  value: string;
  valueType: string;
  editable: boolean;
  onButtonClick: (value: string, valueType: string) => void;
  elementDataType?: number,
};

const CellBox = Styled.div`
  width: 130px;
  height: 35px;
  &:hover {
    width: 130px;
  }
`;

export default function ValueCell(props: CellProps): JSX.Element {
  const { value, onButtonClick, valueType, elementDataType } = props;
  const [isHovering, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const formatToPrice = (v: string) => {
    return parseFloat(v).toFixed(2);
  };

  const downloadFile = (fileUrl: string, fileName = "test.xlsx") => {
    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
      });
  };

  const renderContent = useCallback(() => {
    switch (valueType) {
      case "imageUrl":
        return (
          <>
            <Row align="middle" justify="center" style={{ marginBottom: 8 }}>
              <Image width={250} fallback={ImagePlaceholder} src={value} />
            </Row>
            <ul
              className="ant-card-actions"
              style={{
                background: "transparent",
                borderTopColor: "#f5f5f5",
                height: 24,
              }}
            >
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    window.open(value);
                  }}
                  style={{ minWidth: 20 }}
                >
                  <span style={{ fontSize: 12, color: "#fafafa" }}>
                    <LinkOutlined style={{ marginRight: 2 }} key="openTab" />
                    Open
                  </span>
                </span>
              </li>
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                {props.editable && (
                  <span
                    onClick={() => {
                      onButtonClick(value, valueType);
                      setOpen(false);
                    }}
                    style={{ minWidth: 20 }}
                  >
                    <span style={{ fontSize: 12, color: "#fafafa" }}>
                      <EditOutlined style={{ marginRight: 2 }} key="edit" />
                      Edit
                    </span>
                  </span>
                )}
              </li>
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                <CopyToClipboard
                  text={value}
                  key="copy"
                  options={{
                    format: "text/plain",
                  }}
                  onCopy={() => message.info({ content: "Copied" })}
                >
                  <span style={{ minWidth: 20 }}>
                    <span
                      style={{ fontSize: 12, color: "#fafafa" }}
                      onClick={() => {}}
                    >
                      <CopyOutlined
                        onClick={() => {}}
                        style={{ marginRight: 2 }}
                      />
                      Copy
                    </span>
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </>
        );
      case "fileUrl":
        return (
          <>
            <Paragraph
              style={{ color: "#fff", maxHeight: 300, overflowY: "auto" }}
            >
              {value}
            </Paragraph>
            <ul
              className="ant-card-actions"
              style={{
                background: "transparent",
                borderTopColor: "#f5f5f5",
                height: 24,
              }}
            >
              <li style={{ width: "40%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    downloadFile(value);
                  }}
                  style={{ minWidth: 20 }}
                >
                  <span style={{ fontSize: 12, color: "#fafafa" }}>
                    <DownloadOutlined
                      style={{ marginRight: 2 }}
                      key="download"
                    />
                    Download
                  </span>
                </span>
              </li>
              <li style={{ width: "30%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    onButtonClick(value, valueType);
                    setOpen(false);
                  }}
                  style={{ minWidth: 20 }}
                >
                  {props.editable && (
                    <span style={{ fontSize: 12, color: "#fafafa" }}>
                      <EditOutlined style={{ marginRight: 2 }} key="edit" />
                      Edit
                    </span>
                  )}
                </span>
              </li>
              <li style={{ width: "30%", marginTop: 6, marginBottom: 6 }}>
                <CopyToClipboard
                  text={value}
                  key="copy"
                  options={{
                    format: "text/plain",
                  }}
                  onCopy={() => message.info({ content: "Copied" })}
                >
                  <span style={{ minWidth: 20 }}>
                    <span
                      style={{ fontSize: 12, color: "#fafafa" }}
                      onClick={() => {}}
                    >
                      <CopyOutlined
                        onClick={() => {}}
                        style={{ marginRight: 2 }}
                      />
                      Copy
                    </span>
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </>
        );
      case "pageUrl":
        return (
          <>
            <Paragraph
              style={{ color: "#fff", maxHeight: 300, overflowY: "auto" }}
            >
              {value}
            </Paragraph>
            <ul
              className="ant-card-actions"
              style={{
                background: "transparent",
                borderTopColor: "#f5f5f5",
                height: 24,
              }}
            >
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    window.open(value);
                  }}
                  style={{ minWidth: 20 }}
                >
                  <span style={{ fontSize: 12, color: "#fafafa" }}>
                    <LinkOutlined style={{ marginRight: 2 }} key="openTab" />
                    Open
                  </span>
                </span>
              </li>
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    onButtonClick(value, valueType);
                    setOpen(false);
                  }}
                  style={{ minWidth: 20 }}
                >
                  <span style={{ fontSize: 12, color: "#fafafa" }}>
                    <EditOutlined style={{ marginRight: 2 }} key="edit" />
                    Edit
                  </span>
                </span>
              </li>
              <li style={{ width: "33.3%", marginTop: 6, marginBottom: 6 }}>
                <CopyToClipboard
                  text={value}
                  key="copy"
                  options={{
                    format: "text/plain",
                  }}
                  onCopy={() => message.info({ content: "Copied" })}
                >
                  <span style={{ minWidth: 20 }}>
                    <span
                      style={{ fontSize: 12, color: "#fafafa" }}
                      onClick={() => {}}
                    >
                      <CopyOutlined
                        onClick={() => {}}
                        style={{ marginRight: 2 }}
                      />
                      Copy
                    </span>
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </>
        );
      default:
        return (
          <>
            <Paragraph
              style={{ color: "#fff", maxHeight: 400, overflowY: "auto" }}
            >
              {elementDataType === 6 ? formatToPrice(value) : value}
            </Paragraph>
            <ul
              className="ant-card-actions"
              style={{
                background: "transparent",
                borderTopColor: "#f5f5f5",
                height: 24,
              }}
            >
              <li style={{ width: "50%", marginTop: 6, marginBottom: 6 }}>
                <span
                  onClick={() => {
                    onButtonClick(elementDataType === 6 ? formatToPrice(value) : value, valueType);
                    setOpen(false);
                  }}
                  style={{ minWidth: 20 }}
                >
                  {props.editable && (
                    <span style={{ fontSize: 12, color: "#fafafa" }}>
                      <EditOutlined style={{ marginRight: 2 }} key="edit" />
                      Edit
                    </span>
                  )}
                </span>
              </li>
              <li style={{ width: "50%", marginTop: 6, marginBottom: 6 }}>
                <CopyToClipboard
                  text={elementDataType === 6 ? formatToPrice(value) : value}
                  key="copy"
                  options={{
                    format: "text/plain",
                  }}
                  onCopy={() => message.info({ content: "Copied" })}
                >
                  <span style={{ minWidth: 20 }}>
                    <span
                      style={{ fontSize: 12, color: "#fafafa" }}
                      onClick={() => {}}
                    >
                      <CopyOutlined style={{ marginRight: 2 }} />
                      Copy
                    </span>
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </>
        );
    }
  }, [onButtonClick, props, value, valueType, elementDataType]);

  return (
    <CellBox
      style={{
        width: "100%",
        height: 35,
        display: "flex",
        alignItems: "center",
      }}
    >
      {`${value}` ? (
        <Popover
          placement="topLeft"
          content={renderContent()}
          trigger="hover"
          visible={open}
          onVisibleChange={setOpen}
          color="#000000bf"
          mouseEnterDelay={0.5}
          overlayStyle={{
            maxWidth: 300,
            minWidth:
              ["pageUrl", "fileUrl"].indexOf(valueType) > -1 ? 200 : 130,
          }}
        >
          {valueType === "string" ? (
            <Text
              onClick={() => onButtonClick(value, valueType)}
              style={{ width: "100%", display: "inline-block" }}
              ellipsis={true}
            >
              {(elementDataType === 6 ? formatToPrice(value) : value) || ""}
            </Text>
          ) : (
            <Text
              onClick={() => onButtonClick(value, valueType)}
              style={{ width: "100%", display: "inline-block" }}
              ellipsis={true}
            >
              {(elementDataType === 6 ? formatToPrice(value) : value) || ""}
            </Text>
          )}
        </Popover>
      ) : props.editable ? (
        <div
          style={{ width: "100%" }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Link
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "center",
            }}
            onClick={() => onButtonClick(value, valueType)}
          >
            {isHovering ? "Edit" : ""}&nbsp;
          </Link>
        </div>
      ) : null}
    </CellBox>
  );
}
