import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import {
  addContentSourceType,
  editContentSourceType,
} from "../../services/copywriting";
import { trimValues } from "../../util/validators";

type AddEditDialogProps = {
  mode: "New" | "Edit";
  visible: boolean;
  field: any;
  onRefresh: Function;
  onClose: Function;
};

const AddEditDialog = (props: AddEditDialogProps) => {
  const { mode = "New", field, visible, onClose, onRefresh } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const saveField = async () => {
    const v = await form.validateFields();
    if (v.errorFields) return;
    const formValues = trimValues(v);
    const params = {
      EnumName: formValues.enumName,
      Description: formValues.description,
    };
    try {
      setLoading(true);
      const res =
        mode === "New"
          ? await addContentSourceType(params)
          : await editContentSourceType(field.rowNum, params);
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={`${mode} Content Resources Group`}
      visible={visible}
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} requiredMark={false}>
          <Form.Item
            label="Group Name"
            name="enumName"
            initialValue={field?.enumName}
            rules={[{ required: true, message: "Group Name is required" }]}
          >
            <Input placeholder="Please input" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            //initialValue={field.displayName}
            initialValue={field?.description}
          >
            <Input placeholder="Please input" />
          </Form.Item>
        </Form>
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="end">
              <Space>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type="primary" onClick={saveField}>
                  Save
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default AddEditDialog;
