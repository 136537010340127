import React from 'react';// eslint-disable-line
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import Heading from '../components/common/Heading';

function CustomService() {
  return (
    <ContentLayout>
      <SiteContent>
        <Heading title="Custom Service" />
      </SiteContent>
    </ContentLayout>
  );
}

export default CustomService;
