import React, { createContext } from 'react';

interface PageState {
  loading: boolean;
  fetchData: boolean;
  data: Entities.ProductAttribute[];
  channels: Map<number, Entities.ChannelProfile>;
  searchDisabled: boolean;
  modalFormVisible: boolean;
  editingEntityId?: string | null;
  addEditDialogVisible: boolean;
  current?: Entities.ProductAttribute;
}

export enum Actions {
  SET_LOADING,
  SET_DATA,
  FETCH_DATA,
  DATA_FETCHED,
  SET_MODAL_FORM_VISIBLE,
  CLOSE_MODAL_FORM,
  EDIT_ENTITY,
  ADD_ENTITY,
  SET_ADD_EDIT_DIALOG_VISIBLE,
  SET_CURRENT,
}

export function initialState(): PageState {
  return {
    loading: false,
    fetchData: true,
    searchDisabled: false,
    modalFormVisible: false,
    editingEntityId: null,
    data: [],
    channels: new Map<number, Entities.ChannelProfile>(),
    addEditDialogVisible: false,
    current: undefined,
  };
}

const onDataFetched = (state: PageState, {
  attributes,
  channels,
}: { attributes: Entities.ProductAttribute[], channels: Map<number, Entities.ChannelProfile> }) => ({
  ...state,
  loading: false,
  data: attributes,
  channels,
});

export function reducer(state: PageState, action: Utils.DispatchAction<Actions>) {
  switch (action.type) {
    case Actions.DATA_FETCHED:
      return onDataFetched(state, action.params);
    case Actions.FETCH_DATA:
      return { ...state, loading: true, fetchData: false };
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_DATA:
      return { ...state, data: action.params };
    case Actions.SET_MODAL_FORM_VISIBLE:
      return { ...state, modalFormVisible: action.params };
    case Actions.CLOSE_MODAL_FORM:
      return { ...state, editingEntityId: null, modalFormVisible: false };
    case Actions.ADD_ENTITY:
      return { ...state, editingEntityId: null, modalFormVisible: true };
    case Actions.EDIT_ENTITY:
      return { ...state, editingEntityId: action.params, modalFormVisible: true };
    case Actions.SET_ADD_EDIT_DIALOG_VISIBLE:
      return { ...state, addEditDialogVisible: action.params };
    case Actions.SET_CURRENT:
      return { ...state, current: action.params };
    default:
      return state;
  }
}

export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [PageState, Dispatcher] | [undefined, undefined];
export const PageContext = createContext<ContextType>([undefined, undefined]);
