import { Card, Col, Form, Image, Input, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import ImagePlaceholder from '../../../assets/images/preview_image.jpeg';
import Spacer from '../../../components/common/Spacer';
import Products from '../../../services/products';
import { ProductDetailScreenContext } from '../context';
import { ProductTabContainer } from '../styledComponents';


const mediaTypes: { [key: number]: string } = {
  1: 'Image',
  2: 'Video',
  100: 'Other',
};


const useLoadMedia = (): [Entities.MediaPlacement[], boolean] => {
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState<Entities.MediaPlacement[]>([]);
  useEffect(() => {
    let fetching = false;

    const asyncFunc = async () => {
      if (fetching) {
        return;
      }

      setLoading(true);
      const data = await Products.getMediaPlacements();
      setMedia(data);
      fetching = false;
    };
    asyncFunc()
      .finally(() => {
        setLoading(false);
      });

  }, [setMedia, setLoading]);

  return [media, loading];
};

const MediaGridItem: React.FC<{ field: FormListFieldData, placements: Entities.MediaPlacement[], media?: Entities.ProductMedia }> = (
  {
    placements,
    field,
  },
) => {
  const [state] = useContext(ProductDetailScreenContext);
  const [loading, setLoading] = useState<boolean>(false);
  const mediaPlacementId = state?.basicInfoForm?.getFieldValue(['MediaList', field.name, 'MediaPlacementId']);
  const mediaPlacementName = useMemo((): string => placements.find(p => p.MediaPlacementId === mediaPlacementId)?.MediaPlacementName || 'Unknown', [mediaPlacementId, placements]);


  if (!state) {
    return null;
  }

  // TODO: review that the URL is valid, maybe piggyback on the onLoad event.
  //  Flag field as required accordingly.
  return (
    <Col xs={24} sm={12} md={12} lg={8}>
      <Card title={(
        <div>
          {mediaPlacementName}
        </div>
      )}
      >
        <Spin spinning={loading}>
          <Form.Item
            name={[field.name, 'MediaURL']}
            valuePropName="src"
            wrapperCol={{ span: 24 }}
          >
            <Image
              fallback={ImagePlaceholder}
              onError={() => setLoading(false)}
              onLoad={() => setLoading(false)}
            />
          </Form.Item>
        </Spin>
        <Spacer />
        <Form.Item
          name={[field.name, 'MediaURL']}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea
            placeholder="Image URL"
            allowClear
            disabled={!state.editMode}
          />
        </Form.Item>
      </Card>
    </Col>
  );
};

interface ProductImagesTabContentProps {
  editMode: boolean;
  basicInfoForm: FormInstance;
  dispatch: any;
}

const ProductImagesTabContent: React.FC<ProductImagesTabContentProps> = ({ basicInfoForm, editMode }) => {
  const [media, loading] = useLoadMedia();

  return (
    <ProductTabContainer>
      <Form.List name={['MediaList']}>
        {(fields, { add, remove }) => {
          const selectedMediaPlacements: Array<Entities.ProductMedia> = basicInfoForm.getFieldValue(['MediaList']);
          const selectedValues: Array<string> = selectedMediaPlacements?.map(mp => mp.MediaPlacementId) || [];

          return (
            <>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please select"
                loading={loading}
                value={selectedValues}
                disabled={!editMode}
                onChange={(value: Array<string>) => {
                  if (selectedValues.length > value.length) {
                    // Removed
                    const item = selectedValues.filter(mp => !value.includes(mp))[0];
                    const index = selectedValues.findIndex(mp => mp === item);
                    remove(index);
                  } else {
                    // Added
                    const mediaPlacementId = value.filter(mp => !selectedValues.includes(mp))[0];
                    const placement = media.find(mp => mp.MediaPlacementId === mediaPlacementId);
                    add({
                      MediaPlacementId: mediaPlacementId,
                      MediaPlacementName: placement?.MediaPlacementName,
                    });
                  }
                }}
                allowClear
              >
                {media.map(m => (
                  <Select.Option
                    key={m.MediaPlacementId}
                    value={m.MediaPlacementId}
                  >
                    {`${m.MediaPlacementName} (${mediaTypes[m.MediaType]})`}
                  </Select.Option>
                ))}
              </Select>
              <Spacer />
              <Row gutter={[16, 16]}>
                {
                  fields.map(f => (<MediaGridItem key={f.key} field={f} placements={media} />))
                }
              </Row>
            </>
          );
        }}
      </Form.List>
    </ProductTabContainer>
  );
};

const ProductImagesTabContentMemo = memo<ProductImagesTabContentProps>(ProductImagesTabContent);

const ProductImagesTab: React.FC = () => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  if (!state || !dispatch) {
    return null;
  }

  return (
    <ProductImagesTabContentMemo
      dispatch={dispatch}
      editMode={state.editMode}
      basicInfoForm={state.basicInfoForm}
    />
  );
};

export default ProductImagesTab;
