import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
  Space,
  notification,
} from 'antd';
import {
  CaretDownOutlined,
  DownOutlined,
  //DoubleLeftOutlined,
  //DoubleRightOutlined,
} from '@ant-design/icons';
import Loading from '../../../components/common/Loading';
import LoadingIcon from '../../../components/common/LoadingIcon';
import ModalDialog from '../../../components/common/ModalDialog';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import {
  fetchProductCopywritingElements,
  fetchViewList,
} from '../../../services/copywriting';
import { fetchFieldsByCustomView } from '../../../services/products';
import { isDevEnv } from '../../../util';
import {
  CW_VIEW_ALL,
  CW_VIEW_CUSTOM,
  CW_VIEW_GROUP,
} from '../../Copywriting';
import CopywritingSteps, {
  //SOURCE_TYPE_ERP,
  //SOURCE_TYPE_SYS,
} from '../CopywritingSteps';
import PullCopywritingSteps from '../PullCopywrtingSteps';
import AttrList from '../CopywritingSteps/AttrList';
import AttrGroupList from '../CopywritingSteps/AttrGroupList';
//import ImagePanel from '../ImagePanel';
import { getProductType } from '../helper';
import { CopywritingWrapper } from '../styledComponents';
import { HoverBgButton } from '../../../components/common/styledComponents';

type Props = {
  dispatch?: Function;
  focused: boolean;
  isFullscreen?: boolean;
  productId: string;
  productType: number;
  state: StringKAnyVPair;
};

const Copywriting = (props: Props) => {
  const [attrList, setAttrList] = useState<StringKAnyVPair[]>([]);
  const [attrListVersion, setAttrListVersion] = useState(0);
  const [attrLoaded, setAttrLoaded] = useState(false);
  const [currentAttrList, setCurrentAttrList] = useState<StringKAnyVPair[]>([]);
  const [currentProductId, setProductId] = useState(props.productId);
  const [displayType, setDisplayType] = useState(CW_VIEW_ALL);
  const [displayViewCustomItems, setDisplayViewCustomItems] = useState<StringKAnyVPair[]>([]);
  const [displayViewInited, setDisplayViewInited] = useState(false);
  const [displayViewLoading, setDisplayViewLoading] = useState(false);
  const [displayViewOptions, setDisplayViewOptions] = useState<StringKAnyVPair[]>([]);
  const [displayViewSelected, setDisplayViewSelected] = useState<any[]>([]);
  const [displayViewVersion, setDisplayViewVersion] = useState(0);
  const [filterValue, setFilterValue] = useState<string>('');
  // const [filterGroup, setFilterGroup] = useState<any[]>([]);
  const [imagePanelExpanded, setImagePanelExpanded] = useState(false);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pushDialogVisible, setPushDialogVisible] = useState(false);
  const [pullDialogVisible, setPullDialogVisible] = useState(false);
  // const [groupList, setGroupList] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [tiledDisplay, setTiledDisplay] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const [form] = Form.useForm();

  const attrListHeight = React.useMemo(() => {
    let hd = props.isFullscreen ? 180 : 320;

    if (typeof props.isFullscreen === 'undefined') hd = 240;

    return window.innerHeight - hd;
  }, [props]);

  const profileSettings = useSelector((state: any) => state.profileSettings.profileSettings);

  const clearDisplayViewSelected = () => {
    setDisplayViewSelected([]);
    setDisplayViewCustomItems([]);
    setDisplayViewVersion(displayViewVersion + 1);
  };

  const closePushDialog = () => {
    setPushDialogVisible(false);
  };

  const closePullDialog = () => {
    setPullDialogVisible(false);
  };

  const filterAttrList = useCallback(() => {
    let temp = [...attrList];

    setTiledDisplay(false);

    if (displayType === CW_VIEW_CUSTOM) {
      if (displayViewCustomItems.length > 0) {
        const ids = displayViewCustomItems.map(e => e.copywritingElementNum);

        temp = temp.filter(e => ids.indexOf(e.copywritingElementNum) > -1);
      }

      setTiledDisplay(displayViewCustomItems.length > 0);
    }

    if ( filterValue ){
      const key = filterValue.toLowerCase();
      temp = temp.filter(i=> i.elementName.toLowerCase().indexOf(key) > -1)
    }

    if (selectedTags.length > 0) {
      temp = temp.filter(i=> selectedTags.indexOf(i.tag) > -1)
    }
    // if (filterGroup.length > 0) {
    //   temp = temp.filter(i=> filterGroup.indexOf(i.contentSourceGroup) > -1)
    // }

    setCurrentAttrList(temp)
    setAttrListVersion(prev => prev + 1);
  }, [displayViewCustomItems, displayType, selectedTags, filterValue, attrList]);

  const getAttributePanelClassName = () => {
    const cls = ['form-ctn'];

    if (imagePanelExpanded) cls.push('shrink');

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const getImagePanelClassName = () => {
    const cls = ['image-ctn'];

    if (imagePanelExpanded) cls.push('expand');

    return cls.join(' ');
  };

  const getProfileSettingValue = (key: string, defaultValue = '') => {
    const ps = profileSettings;

    if (Array.isArray(ps)) {
      const fs = ps.filter(e => e.SettingCode === key);

      if (fs.length === 1) {
        return fs[0].SettingValue;
      }
    }

    return defaultValue;
  };

  const isCurrentAttrsNoValue = () => {
    return currentAttrList.every(e => !e.copywritingValue);
  };

  const loadCustomViewItems = async (vid: number) => {
    setIsLoading(true);

    try {
      const { code, data } = await fetchFieldsByCustomView(vid);

      if (code === 200 && Array.isArray(data)) {
        //console.log('cf', data, currentAttrList);
        setDisplayViewCustomItems(data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadDisplayViewOptions = async () => {
    setDisplayViewLoading(true);

    try {
      const opts = await fetchViewList();
      //console.log('opts', opts);
      if (opts) {
        setDisplayViewOptions(
          opts.map((e: StringKAnyVPair) => ({
            label: e.name,
            value: e.contentResourceViewNum,
          }))
        );
      }
    } catch(e) {
      notification.error({
        message: `Fetch content resource view list error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setDisplayViewLoading(false);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);

    try {
      const isLegacy = getProfileSettingValue('LegacyExperience', '0') === '1';
      const res = await fetchProductCopywritingElements(props.productId);

      console.log('e-->', res);
      if (res && typeof res === 'object') {
        const { data } = res;

        if (Array.isArray(data)) {
          const ts: string[] = [];
          // const gps: string[] = [];
          let ds: StringKAnyVPair[] = data.filter(e => isLegacy ? [0].indexOf(e.source) > -1 : true);

          ds.forEach(d => {
            let l: string[] = [];

            if (d.optionList.trim()) {
              try {
                l = d.optionList.trim().split('|');
              } catch(e) {}
            }

            d.optionList = l;
          });
          //console.log('d-->', ds);
          setAttrList(ds);
          setCurrentAttrList(ds);
          setAttrListVersion(attrListVersion + 1);
          ds.forEach(attr => {
            if (attr.tag && ts.indexOf(attr.tag) < 0) ts.push(attr.tag);
            // if (attr.contentSourceGroup && gps.indexOf(attr.contentSourceGroup) < 0) gps.push(attr.contentSourceGroup)
          });
          setTags(ts);
          // setGroupList(gps);
        }
      }
    } catch(e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch content resources attributes error: ${e}`,
      });
    } finally {
      setAttrLoaded(true);
      setIsLoading(false);
    }
  };

  const onDisplayTypeChange = (val: number) => {
    setDisplayType(val);
    clearDisplayViewSelected();

    if (val === CW_VIEW_CUSTOM && !displayViewInited) {
      loadDisplayViewOptions();
      setDisplayViewInited(true);
    }
  };

  const onDisplayViewChange = (val: any) => {
    if (val) {
      if (Array.isArray(val)) setDisplayViewSelected(val);
      else setDisplayViewSelected([val]);

      //console.log('v', val);
      if (displayType === CW_VIEW_CUSTOM) {
        loadCustomViewItems(val);
      }
    } else {
      clearDisplayViewSelected();
    }
  };

  const onSelectTags = (ts: string[]) => {
    //console.log('sel ->', ts);
    setSelectedTags(ts);
  };

  // const onSelectGroups = (ts: string[]) => {
  //   //console.log('sel ->', ts);
  //   setFilterGroup(ts);
  // };

  const openPushDialog = () => {
    if (isCurrentAttrsNoValue()) {
      notification.info({
        message: 'Please edit at least one attribute',
        duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
      });
      return;
    } else {
      setPushDialogVisible(true);
    }
  };

  const openPullDialog = () => {
    setPullDialogVisible(true);
  };

  const moreDropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => openPullDialog()}>
        Pull
      </Menu.Item>
    </Menu>
  );

  const pushDialogWidth = () => {
    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  const searchDisplayViewCardClassName = () => {
    const cls = [ 'view-filter-card' ];

    //if (displayType === CW_VIEW_CUSTOM) {
    if (displayViewSelected.length > 0) {
      cls.push('custom-view');
    }

    return cls.join(' ');
  };

  const searchFilterCardClassName = () => {
    const cls = [ 'view-filter-card' ];

    if (filterValue || selectedTags.length > 0) {
      cls.push('custom-view');
    }

    return cls.join(' ');
  };

  const searchDisplayViewOptions = () => {
    if (displayType === CW_VIEW_CUSTOM) return displayViewOptions;

    /*if (displayType === CW_VIEW_GROUP) {
      return groupList.map(g => ({
        label: g.enumName,
        value: g.enumValue,
      })) as any;
    }*/

    return [];
  };

  const tagOptions = () => {
    return tags.map(t => (
      <Select.Option
        key={t}
        value={t}
      >
        {t}
      </Select.Option>
    ));
  };

  // const groupOptions = () => {
  //   return groupList.map(t => (
  //     <Select.Option
  //       key={t}
  //       value={t}
  //     >
  //       {t}
  //     </Select.Option>
  //   ));
  // };

  // eslint-disable-next-line
  const toggleImagePanel = () => {
    setImagePanelExpanded(!imagePanelExpanded);
  };

  React.useEffect(() => {
    if (props.focused && props.productId && (!inited || currentProductId !== props.productId)) {
      loadInitialData();
      setInited(true);
      setProductId(props.productId);
    }
  }, [currentProductId, inited, loadInitialData, props]);

  useEffect(()=>{
    filterAttrList();
  },[filterValue, selectedTags, filterAttrList])

  return (
    <>
      <CopywritingWrapper>
        <Col className="left-sider">
          <Space className="left-sider-bar" direction="vertical">
            <Card
              className={searchDisplayViewCardClassName()}
            >
              <Space direction="vertical" style={{width: '100%'}}>
                <Select
                  defaultValue={CW_VIEW_ALL}
                  options={[{label: 'View all', value: CW_VIEW_ALL}, {label: 'Custom view', value: CW_VIEW_CUSTOM}]}
                  onChange={onDisplayTypeChange}
                  style={{width: '100%'}}
                />
                <Select
                  allowClear
                  disabled={displayType === CW_VIEW_ALL}
                  key={`display-view${displayViewVersion}`}
                  options={searchDisplayViewOptions() as any}
                  onChange={onDisplayViewChange}
                  mode={displayType === CW_VIEW_GROUP ? 'multiple' : undefined}
                  style={{width: '100%'}}
                  suffixIcon={displayType === CW_VIEW_CUSTOM && displayViewLoading ? <LoadingIcon size="mini" /> : <DownOutlined />}
                />
              </Space>
            </Card>
            <Form form={form}>
              <Card
                className={searchFilterCardClassName()}
              >
                <Space direction="vertical">
                  <Form.Item noStyle name="Name">
                    <Input.Search
                      allowClear
                      name="Filter"
                      placeholder="Filter"
                      // onChange={onSearchTextChange}
                      onSearch={(value) => setFilterValue(value)}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="Tag">
                    <Select
                      mode="tags"
                      onChange={onSelectTags}
                      placeholder="Please Select Tags"
                      style={{ width: '100%' }}
                    >
                      {tagOptions()}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item noStyle name="Group">
                    <Select
                      mode="tags"
                      onChange={onSelectGroups}
                      placeholder="Please Select groups"
                      style={{ width: '100%' }}
                    >
                      {groupOptions()}
                    </Select>
                  </Form.Item> */}
                  <HoverBgButton
                    hovertype="danger"
                    onClick={() => {
                      form.resetFields();
                      setSelectedTags([]);
                      // setFilterGroup([]);
                      setFilterValue('');
                    }}
                  >
                    Clear Filters
                  </HoverBgButton>
                </Space>
              </Card>
            </Form>
            <Button
              disabled={currentAttrList.length === 0}
              onClick={openPushDialog}
              type="primary"
              style={{ width: '100%' }}
            >
              Push
            </Button>
            <Dropdown overlay={moreDropdownMenu} trigger={['click']}>
              <Button style={{ width: '100%' }}>
                More
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Col>
        <Col className={getAttributePanelClassName()} style={{ height: attrListHeight }}>
          {attrLoaded && attrList.length === 0 && (
            <Alert message="No attribute found" type="error" />
          )}
          {isDevEnv() || true ? (
            <AttrGroupList
              dispatch={props.dispatch}
              editable
              filtered={currentAttrList.length !== attrList.length}
              list={currentAttrList}
              productId={props.productId}
              state={props.state}
              tiled={tiledDisplay}
              version={attrListVersion}
            />
          ) : (
            <AttrList
              editable
              list={currentAttrList}
              productId={props.productId}
              state={props.state}
              version={attrListVersion}
            />
          )}
        </Col>
        {/*<Col className={getImagePanelClassName()}>
        <span
          className="left-expand-icon"
          onClick={toggleImagePanel}
        >
          {imagePanelExpanded ?
            <DoubleRightOutlined /> :
            <DoubleLeftOutlined />
          }
        </span>
        <div
          className="image-panel-ctn"
          style={{display: imagePanelExpanded ? '': 'none'}}
        >
          <ImagePanel
            focused={imagePanelExpanded}
            productId={props.productId}
          />
        </div>
      </Col>*/}
      </CopywritingWrapper>
      {pushDialogVisible && (
        <ModalDialog
          bodyHeight={window.innerHeight - 200}
          closable={false}
          closeButton
          footer={null}
          fullscreen
          maskClosable={false}
          onClose={closePushDialog}
          title={`Push Content Resources (${getProductType(props.productType)})`}
          visible={pushDialogVisible}
          width={pushDialogWidth()}
        >
          <CopywritingSteps onCancel={closePushDialog} productId={props.productId} state={props.state} />
        </ModalDialog>
      )}
      {pullDialogVisible && (
        <ModalDialog
          bodyHeight={window.innerHeight - 200}
          closable={false}
          closeButton
          footer={null}
          fullscreen
          maskClosable={false}
          onClose={closePullDialog}
          title={`Pull Content Resources (${getProductType(props.productType)})`}
          visible={pullDialogVisible}
          width={pushDialogWidth()}
        >
          <PullCopywritingSteps onCancel={closePullDialog} productId={props.productId} />
        </ModalDialog>
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default React.memo(Copywriting);
