import { SearchOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import AttributeFilterModal from "./AttributeFilterModal";

const ConditionListWrapper = styled.div`
  .condition-row {
    margin-bottom: 6px;
  }
`

const SmallPaddingButton = styled(Button)`
  padding: 4px 8px;
  width: 100%;
`

export type ConditionItem = {
  attribute: any;
  searchType: 1 | 2 | 3 | 4 | 5,  // 1:= 2:like 3:> 4:< 5 <>
  value: string;
}

type NewConditionProps = {
  conditionList: ConditionItem[];
  updateConditionList: (index: number, key: string, value: any) => void;
  deleteCondition: (index: number) => void;
}

const NewCondition = (props: NewConditionProps) => {
  const { conditionList = [], updateConditionList, deleteCondition } = props;
  const [newConditionModalVisible, setNewConditionModalVisible] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();

  const onAttributeSelect = (attribute: any) => {
    if (currentIndex === undefined) return;
    updateConditionList(currentIndex, 'attribute', attribute);
  }

  return (
    <ConditionListWrapper>
      {
        conditionList.map((condition, index) => {
          return (
            <Row gutter={6} className="condition-row" key={`condition-row-${index}`} wrap={false}>
              <Col span={7}>
                {
                  condition.attribute ? (
                    <SmallPaddingButton onClick={() => {
                      setCurrentIndex(index);
                      setNewConditionModalVisible(true);
                    }}>
                      <SwapOutlined />
                      <Typography.Text style={{ maxWidth: 100, width: 100 }} ellipsis={{ tooltip: condition.attribute.AttributeName }}>{condition.attribute.AttributeName}</Typography.Text>
                    </SmallPaddingButton>
                  ) : (
                    <SmallPaddingButton onClick={() => {
                      setCurrentIndex(index);
                      setNewConditionModalVisible(true);
                    }}>
                      <SearchOutlined />
                      Select Attribute
                    </SmallPaddingButton>
                  )
                }
              </Col>
              <Col span={7}>
                <Select
                  value={condition.searchType}
                  onChange={e => updateConditionList(index, 'searchType', e)}
                  options={[
                    { label: 'Contains', value: 2 },
                    { label: `Doesn't contain`, value: 5 },
                    { label: 'Equals', value: 1 },
                  ]}
                />
              </Col>
              <Col span={6}>
                <Input
                  value={condition.value}
                  onChange={e => updateConditionList(index, 'value', e.target.value)}
                />
              </Col>
              <Col span={4}>
                <SmallPaddingButton onClick={() => deleteCondition(index)}>
                  Remove
                </SmallPaddingButton>
              </Col>
            </Row>
          )
        })
      }
      <AttributeFilterModal
        attrModalVisible={newConditionModalVisible}
        onHide={() => setNewConditionModalVisible(false)}
        onAttributeSelect={(attribute: any) => onAttributeSelect(attribute)}
      />
    </ConditionListWrapper>
  )
}

export default NewCondition;