import React from 'react';
import { Row, Select } from 'antd';

type Props = {
  className?: string;
  current?: StringKAnyVPair;
  onChange?: Function;
  options: StringKAnyVPair[];
  styleCode: string;
};

const SubStyleSelector = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<string>();

  const onChange = (val: any) => {
    console.log('v', val);
    const opts = props.options.filter(e => e.productId === val);

    if (opts.length > 0) {
      if (typeof props.onChange === 'function') {
        const sty = {
          ...opts[0],
          colorPatternCode: splitColorPatternBySubStyleCode(opts[0].subStyleCode),
        };
        props.onChange(sty);
      }
    }
  };

  const codeOptions = () => {
    const existedIds: string[] = [];

    return props.options.map(e => {
      const { subStyleCode: ssCode } = e;
      const exist = existedIds.indexOf(e.productId) > -1;
      const cpCode = ssCode.indexOf(props.styleCode) === 0 && splitColorPatternBySubStyleCode(ssCode);

      if (!exist) existedIds.push(e.productId);

      return (exist || !cpCode) ? null : (
        <Select.Option
          key={`${e.productId}`}
          value={e.productId}
        >
          {ssCode}
        </Select.Option>
      );
    });
  };

  const splitColorPatternBySubStyleCode = (ssCode: string) => {
    return ssCode.replace(`${props.styleCode}-`, '').trim();
  };

  React.useEffect(() => {
    if (props.current) {
      const { productId } = props.current;

      if (productId !== currentValue) {
        setCurrentValue(productId);
      }
    }
  }, [currentValue, props]);

  return (<>
    <Row className={props.className || ''}>
      <Select
        onChange={onChange}
        style={{
          width: '100%',
        }}
        value={currentValue}
      >
        {codeOptions()}
      </Select>
    </Row>
  </>);
};

export default SubStyleSelector;
