import React from 'react';
import { Button, Radio, Row } from 'antd';
import {
  CloseOutlined,
  CodeOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import ModalDialog from '../../../components/common/ModalDialog';

interface Props extends ModalProps {
  attr: StringKAnyVPair;
  onClose: Function;
  visible: boolean;
};

const ViewerBody = styled.div`
  height: 300px;
  width: 600px;
  overflow-y: auto;

  &.fullscreen {
    width: 100%;
    height: calc(100vh - 144px);
  }

  & .pattern-title-bar {
    background-color: #ECECEC;
    padding: 2px 0px 2px 6px;
  }

  & .pattern-label {
    font-size: 16px;
    font-weight: 550;
  }

  & .pattern-label::after {
    content: ': ';
  }

  & .section-divider {
    background-color: #80CBC4;
    border: none;
    height: 1px;
  }
`;

const VirtualPatternViewer = (props: Props) => {
  const { useState } = React;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [viewMode, setViewMode] = useState('vision');
  const bodyRef = React.useRef<any>(null);

  const onFullscreen = (full: boolean) => {
    const body = bodyRef.current;

    setIsFullscreen(full);

    setTimeout(() => {
      if (full) {
        const ctn = body.parentNode.parentNode;

        ctn.style.height = 'min-content';
      }
    }, 0);
  };

  const onViewChange = (evt: any) => {
    setViewMode(evt.target.value);
  };

  return (<>
    <ModalDialog
      centered
      closable={false}
      closeButton
      footer={
        <Row justify="end">
          <Button
            onClick={() => props.onClose()}
          >
            <CloseOutlined />
            Close
          </Button>
        </Row>
      }
      fullscreen
      //onCancel={() => props.onClose()}
      onClose={() => props.onClose()}
      onFullscreen={onFullscreen}
      maskClosable={false}
      title={props.attr.elementName || 'Virtual'}
      titleRightCrumb={<></>}
      visible={props.visible}
      width={'auto'}
    >
      <ViewerBody className={isFullscreen ? 'fullscreen' : undefined} ref={bodyRef}>
        <div className="pattern-title-bar">
          <label className="pattern-label">Pattern</label>
        </div>
        <div>{props.attr.pattern}</div>
        <hr className="section-divider" />
        <Row align="middle" className="pattern-title-bar" justify="space-between">
          <label className="pattern-label">Value</label>
          <Radio.Group defaultValue={viewMode} buttonStyle="solid" onChange={onViewChange}>
            <Radio.Button value="code"><CodeOutlined /></Radio.Button>
            <Radio.Button value="vision"><EyeOutlined /></Radio.Button>
          </Radio.Group>
        </Row>
        {viewMode === 'vision' && <div dangerouslySetInnerHTML={{__html: props.attr.copywritingValue}} />}
        {viewMode === 'code' && <div>{props.attr.copywritingValue}</div>}
      </ViewerBody>
    </ModalDialog>
  </>);
};

export default VirtualPatternViewer;
