import React from 'react';
import { Col, Image, Row, Modal, Card, Space, Button } from 'antd';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import message from '../../components/common/message';
import EditImageDialog from './EditImageDialog';

type ImageUrlItem = {
  imageSrc: string;
  position: number;
};

type Props = {
  imageUrls: string;
  onClose: Function;
  onSave: Function;
  title?: string;
  visible: boolean;
};

const EditImageListDialog = (props: Props) => {
  const { useState, useEffect } = React;
  const [imagesData, setImagesData] = useState<string[]>([]);
  const [current, setCurrent] = useState<ImageUrlItem>();
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);

  const onCloseDialog = () => {
    props.onClose();
  };

  const onUpPosition = (index: number) => {
    const temp = [...imagesData];
    temp.splice(index, 1, ...temp.splice(index - 1, 1, temp[index]));
    setImagesData(temp);
  };

  const onDownPosition = (index: number) => {
    const temp = [...imagesData];
    temp.splice(index, 1, ...temp.splice(index + 1, 1, temp[index]));
    setImagesData(temp);
  };

  const removePostion = (index: number) => {
    const temp = [...imagesData];
    temp.splice(index, 1);
    setImagesData(temp);
  };

  const onSaveImages = async () => {
    const data = imagesData.map((i, index) => {
      return {
        imageSrc: i,
        position: index + 1,
      };
    });
    props.onSave(JSON.stringify(data));
  };
  useEffect(() => {
    if (props.imageUrls && typeof props.imageUrls === 'string') {
      try {
        const json = JSON.parse(props.imageUrls);
        const data = json.map((i: ImageUrlItem) => i.imageSrc);
        setImagesData(data);
      } catch (error) {
        message.error('Malformed json string');
        setImagesData([]);
      }
    }
  }, [props.imageUrls]);

  return (
    <Modal
      centered
      className="fullscreen-modal"
      okText="Save"
      onCancel={onCloseDialog}
      onOk={onSaveImages}
      style={{ paddingBottom: 0 }}
      title={props.title || 'Edit Images'}
      visible={props.visible}
      width={900}
    >
      <Row style={{ marginBottom: 8 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrent(undefined);
            setEditImageDialogVisible(true);
          }}
        >
          Add Image
        </Button>
      </Row>
      <Row gutter={16} style={{ height: 300, overflowY: 'auto' }}>
        {imagesData.map((item: string, index: number) => (
          <Col span={8}>
            <Card
              size="small"
              style={{ width: 240, marginBottom: 8 }}
              cover={
                <Image
                  style={{ height: 100, width: 'auto', margin: '0 auto' }}
                  fallback={ImagePlaceholder}
                  src={item}
                />
              }
            >
              <Card.Meta
                description={
                  <Row justify="center">
                    <Space>
                      <Button
                        disabled={index === 0}
                        size="small"
                        icon={<LeftCircleOutlined />}
                        onClick={() => onUpPosition(index)}
                      />
                      <Button
                        disabled={index === imagesData.length - 1}
                        size="small"
                        icon={<RightCircleOutlined />}
                        onClick={() => onDownPosition(index)}
                      />
                      <Button
                        size="small"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setCurrent({
                            imageSrc: item,
                            position: index,
                          });
                          setEditImageDialogVisible(true);
                        }}
                      />
                      <Button
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => removePostion(index)}
                      />
                    </Space>
                  </Row>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      {editImageDialogVisible && (
        <EditImageDialog
          imageUrl={current?.imageSrc || ''}
          onClose={() => {
            setEditImageDialogVisible(false);
            setCurrent(undefined);
          }}
          onSave={(str: string) => {
            const temp = [...imagesData];
            if (current) {
              temp[current.position] = str;
            } else {
              temp.push(str);
            }
            setCurrent(undefined);
            setImagesData(temp);
            setEditImageDialogVisible(false);
          }}
          title={current ? 'Edit Image' : 'Add Image'}
          visible={editImageDialogVisible}
        />
      )}
    </Modal>
  );
};

export default EditImageListDialog;
