import React from 'react';
import { Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import theme from '../../assets/styles/theme';

type Props = {
  flatten?: true;
  onSearch?: Function;
  style?: StringKAnyVPair;
};


const FlattenWarpper = styled.span`
  width: ${(p) => p.theme.width || '317px'};

  & .ant-input-wrapper.ant-input-group > .ant-input-group-addon:first-child {
    border: 1px solid #D9D9D9 !important;
    border-right: none !important;
    padding: 3px 8px;
  }

  & .ant-input-wrapper.ant-input-group > .ant-input-group-addon:last-child {
    display: none !important;
  }

  & .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
  }
`;

const SearchWarpper = styled.span`
  &.search-input-wrapper {
    margin-left: 4px;
    overflow: hidden;
    padding: 0;
    -webkit-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    transform: scale(0.75);
    transform-origin: left;
    width: 46px;
  }

  &.search-input-wrapper .ant-input {
    font-size: 18px;
    height: 34px;
  }

  &.search-input-wrapper .ant-input-group-addon:first-child {
    border: 1px solid #D9D9D9 !important;
    border-radius: 21px;
    font-size: 22px;
    padding: 0 10px;
    padding-top: 4px;
  }

  &.search-input-wrapper .ant-input-affix-wrapper {
    border: 1px solid transparent;
    border-left: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 23px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 23px;
  }

  &.search-input-wrapper .search-icon-box {
    cursor: pointer;
  }

  &.search-input-wrapper.active {
    width: ${(p) => p.theme.width || '317px'};
  }

  &.search-input-wrapper.active .ant-input-affix-wrapper {
    border: 1px solid #D9D9D9;
  }

  &.search-input-wrapper.active .ant-input-group-addon:first-child {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: ${theme['@primary-color']};
  }
`;

const ExpandableSearch = (props: Props) => {
  const { useState } = React;
  const [displaySearcher, setDisplaySearcher] = useState(false);
  const [searchInputVersion, setSearchInputVersion] = useState(0);
  const [searchTimer, setSearchTimer] = useState<number>();

  const attrSearchInputRef = React.useRef<any>(null);
  const comRef = React.useRef<any>(null);

  const filterAddonBefore = () => {
    return props.flatten ? <FilterOutlined /> : (
      <span
        className="search-icon-box"
        onClick={toggleSearcher}
      >
        <FilterOutlined />
      </span>
    );
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (typeof sk === 'string' && comRef.current) {
      sk = sk.toLowerCase().trim();

      if (props.onSearch) props.onSearch(sk);
    }
  };

  const onSearchboxKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const searchFieldInputWrapperClass = () => {
    const cls = ['search-input-wrapper'];

    if (displaySearcher) {
      cls.push('active');
    }

    return cls.join(' ');
  };

  const toggleSearcher = () => {
    if (displaySearcher) {
      if (props.onSearch) props.onSearch('');

      setSearchInputVersion(searchInputVersion + 1);
    } else {
      setTimeout(() => {
        if (attrSearchInputRef.current) {
          attrSearchInputRef.current.input.focus();
        }
      }, 600);
    }

    setDisplaySearcher(!displaySearcher);
  };

  return (<>
    {props.flatten ?
    <FlattenWarpper ref={comRef}>
      <Input.Search
        enterButton={null}
        addonBefore={filterAddonBefore()}
        allowClear
        key={`field-filter-input${searchInputVersion}`}
        onKeyUp={onSearchboxKeyUp}
        onSearch={onSearchAttributes}
        ref={attrSearchInputRef}
        style={props.style}
      />
    </FlattenWarpper>
    :
    <SearchWarpper
      className={searchFieldInputWrapperClass()}
      ref={comRef}
    >
      <Input.Search
        enterButton={null}
        addonBefore={filterAddonBefore()}
        allowClear
        key={`field-filter-input${searchInputVersion}`}
        onKeyUp={onSearchboxKeyUp}
        onSearch={onSearchAttributes}
        ref={attrSearchInputRef}
        style={props.style}
      />
    </SearchWarpper>
    }
  </>);
};

export default ExpandableSearch;
