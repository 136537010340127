import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Radio,
  Row,
  Spin,
  Tooltip,
  Typography,
  Input,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IPermissionGroup, IRolePermissionsModal } from './types';
import { useGetPermissionsQuery } from '../../redux/api/userManagement';
import { groupByKey } from '../../util/strings';
import { objectToArray } from '../../util';
import { PermissionsLevels } from '../../types/enums';
import {
  GroupMainRow,
  GroupWrapper,
  PermissionsGroupsWrapper,
  PermissionsWrapper,
  GroupItem,
  GroupBody,
} from './styledComponents';
import hasPermission from '../../util/permissions';
import { Permissions } from '../../constants/enums/permissions';
import debounce from 'lodash.debounce';

const PermissionGroup: React.FC<IPermissionGroup> = ({ items, name, onCheckAll, editMode, id }) => {
  const [none, setNone] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [viewEdit, setViewEdit] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);
  useEffect(() => {
    setNone(false);
    setView(false);
    setViewEdit(false);
    setAdmin(false);
  }, [editMode]);
  return (
    <GroupWrapper id={id} key={id}>
      <Row>
        <Col span={24}>
          <GroupMainRow>
            <Row>
              <Col span={12}>
                <h3 id={`${id}_main_row_name`}>{name}</h3>
              </Col>
              <Col span={3}>
                <Checkbox
                  id={`${id}_main_row_none_checkbox`}
                  disabled={!editMode}
                  onClick={() => {
                    onCheckAll(PermissionsLevels.NONE, items[0].Scope);
                    setNone(true);
                    setView(false);
                    setViewEdit(false);
                    setAdmin(false);
                  }}
                  checked={none}
                >
                  None
                </Checkbox>
              </Col>
              <Col span={3}>
                {PermissionsLevels.VIEW <= items[0].MaxLevel && (
                  <Checkbox
                    id={`${id}_main_row_edit_checkbox`}
                    disabled={!editMode}
                    onClick={() => {
                      onCheckAll(PermissionsLevels.VIEW, items[0].Scope);
                      setNone(false);
                      setView(true);
                      setViewEdit(false);
                      setAdmin(false);
                    }}
                    checked={view}
                  >
                    View
                  </Checkbox>
                )}
              </Col>
              <Col span={3}>
                {PermissionsLevels.VIEW_EDIT <= items[0].MaxLevel && (
                  <Checkbox
                    id={`${id}_main_row_view_and_edit_checkbox`}
                    disabled={!editMode}
                    onClick={() => {
                      onCheckAll(PermissionsLevels.VIEW_EDIT, items[0].Scope);
                      setNone(false);
                      setView(false);
                      setViewEdit(true);
                      setAdmin(false);
                    }}
                    checked={viewEdit}
                  >
                    View & Edit
                  </Checkbox>
                )}
              </Col>
              <Col span={3}>
                {PermissionsLevels.ADMIN <= items[0].MaxLevel && (
                  <Checkbox
                    id={`${id}_main_row_admin_checkbox`}
                    disabled={!editMode}
                    onClick={() => {
                      onCheckAll(PermissionsLevels.ADMIN, items[0].Scope);
                      setNone(false);
                      setView(false);
                      setViewEdit(false);
                      setAdmin(true);
                    }}
                    checked={admin}
                  >
                    Admin
                  </Checkbox>
                )}
              </Col>
            </Row>
          </GroupMainRow>
          <GroupBody>
            {items.map((i: Entities.IPermission, index: number) => (
              <GroupItem
                key={i.PermissionNum}
                className={index % 2 ? 'gray-stripe' : 'white-stripe'}
              >
                <Row>
                  <Col span={12} style={{ textAlign: 'right', paddingRight: '90px' }}>
                    <h3 id={`${id}_row_${index}_permission_name`}>{i.PermissionName}</h3>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['permissions', i.PermissionNum]}
                      style={{ marginBottom: '0px' }}
                      initialValue={PermissionsLevels.NONE}
                    >
                      <Radio.Group
                        disabled={!editMode}
                        name={i.PermissionNum.toString()}
                        style={{ width: '100%' }}
                        defaultValue={PermissionsLevels.NONE}
                      >
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col span={6}>
                                <Radio
                                  id={`${id}_row_${index}_none_radio`}
                                  value={PermissionsLevels.NONE}
                                />
                              </Col>
                              <Col span={6}>
                                {PermissionsLevels.VIEW <= i.MaxLevel && (
                                  <Radio
                                    id={`${id}_row_${index}_view_radio`}
                                    value={PermissionsLevels.VIEW}
                                  />
                                )}
                              </Col>
                              <Col span={6}>
                                {PermissionsLevels.VIEW_EDIT <= i.MaxLevel && (
                                  <Radio
                                    id={`${id}_row_${index}_view_edit_radio`}
                                    value={PermissionsLevels.VIEW_EDIT}
                                  />
                                )}
                              </Col>
                              <Col span={6}>
                                {PermissionsLevels.ADMIN <= i.MaxLevel && (
                                  <Radio
                                    id={`${id}_row_${index}_admin_radio`}
                                    value={PermissionsLevels.ADMIN}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </GroupItem>
            ))}
          </GroupBody>
        </Col>
      </Row>
    </GroupWrapper>
  );
};

const RolePermissionsModal: React.FC<IRolePermissionsModal> = ({
  role,
  show,
  onClose,
  loading,
  onAllSelected,
  onSave,
  isSaving,
  formUpdated,
  resetPermissions,
  editMode,
  enableEditMode,
}) => {
  const [dialogIsFullscreen, setDialogIsFullscreen] = useState<boolean>(false);
  const { data: permissions = [], isFetching: isFetchingPermissions } = useGetPermissionsQuery();
  const [permissionsGrouped, setPermissionsGrouped] = useState<any[]>([]);
  const profiles = useSelector((state: any) => state.profiles);
  const onSelectAll = useCallback(
    (level: PermissionsLevels, scope: string) => {
      onAllSelected(permissions, level, scope);
    },
    [onAllSelected, permissions],
  );

  const [filterList, setFilterList] = useState<any[]>(permissionsGrouped);
  const [filterValue, setFilterValue] = useState<string>('');

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const toggleDialogFullscreen = () => {
    setDialogIsFullscreen(!dialogIsFullscreen);
  };

  const dialogWidth = () => {
    if (dialogIsFullscreen) {
      return window.innerWidth;
    }

    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };
  const loadingModal = loading || isFetchingPermissions || isSaving;
  const modalTitle = (
    <>
      <Row align="middle" justify="space-between">
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Typography.Title id="role_permissions_title" level={4}>
                Role Permissions
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button id="role_permissions_fullscreen_button" onClick={toggleDialogFullscreen}>
            {dialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            {dialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
          </Button>
          <Tooltip placement="bottom" title="Close">
            <Button
              id="role_permissions_close_icon_button"
              type="text"
              onClick={onClose}
              style={{ padding: '0 8px' }}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  );

  React.useEffect(() => {
    if (filterValue) {
      const temp = permissionsGrouped
        .map((i: any) => {
          const tp = i.filter(
            (k: any) =>
              k.PermissionName.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1,
          );
          return [...tp];
        })
        .filter((l) => l.length > 0);
      setFilterList([...temp]);
    } else {
      setFilterList([...permissionsGrouped]);
    }
  }, [filterValue, permissionsGrouped]);

  useEffect(() => {
    if (permissions.length > 0) {
      setPermissionsGrouped(objectToArray(groupByKey(permissions, 'Scope')));
    }
  }, [permissions]);

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      centered
      className="fullscreen-modal"
      width={dialogWidth()}
      closable={false}
      footer={false}
      onCancel={onClose}
      style={{ padding: 0 }}
      visible={show}
      onOk={onSave}
      title={modalTitle}
    >
      <Spin spinning={loadingModal}>
        <PermissionsWrapper className={dialogIsFullscreen ? 'fullscreen-mode' : ''}>
          <Row>
            <Col span={21}>
              <Typography.Text strong style={{ fontSize: '18px' }}>
                {loadingModal ? 'Loading...' : `Add or edit permission for ${role.roleName}`}
              </Typography.Text>
            </Col>
            {editMode ? (
              <>
                <Col span={2} style={{ textAlign: 'right', paddingRight: '5px' }}>
                  <Button
                    id="role_permissions_cancel_button"
                    loading={loadingModal}
                    onClick={() => {
                      resetPermissions();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={1}>
                  <Button
                    id="role_permissions_save_button"
                    disabled={!formUpdated}
                    loading={loadingModal}
                    onClick={onSave}
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </>
            ) : (
              <Col span={3} style={{ textAlign: 'right', paddingRight: '5px' }}>
                <Button
                  id="role_permissions_edit_button"
                  disabled={
                    !hasPermission(
                      profiles.permissions,
                      Permissions.USER_PERMISSION,
                      PermissionsLevels.VIEW,
                    )
                  }
                  onClick={enableEditMode}
                >
                  Edit
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Input
              prefix={<SearchOutlined />}
              style={{ width: 400 }}
              placeholder="Filter"
              allowClear
              onChange={(e) => {
                debounceFn(e.target.value);
              }}
            />
          </Row>
          <PermissionsGroupsWrapper>
            {filterList.map((permission: Array<any>, index: number) => (
              <PermissionGroup
                editMode={editMode}
                onCheckAll={onSelectAll}
                key={permission[0].Scope}
                name={permission[0].Scope}
                items={permission}
                id={`permissions_group_${index}`}
              />
            ))}
          </PermissionsGroupsWrapper>
        </PermissionsWrapper>
      </Spin>
    </Modal>
  );
};

export default RolePermissionsModal;
