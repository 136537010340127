import { FormInstance } from 'antd';
import { createContext } from 'react';

export type IntegrationForm = {
  account: Entities.ChannelAccountObj & { ChannelIntegrations: number };
  settings: FormModels.IntegrationSettings;
  catalogSettings?: any;
  catalogSettingsIsForceUsing?: boolean;
  catalogSettingsSelectedTemplates?: any;
  pimProfileSettings?: any;
  standardTemplates?: any;
  sourceStandardTemplates?: any;
  sourceCatalogSettings?: any;
};

export const FormsContext = createContext<FormInstance<IntegrationForm> | undefined>(undefined);
