import React from 'react';
import {
  Col,
  Input,
  Row,
  Space,
} from 'antd';
import { FilterOutlined, FilterTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
//import ImagePlaceholder from '../../../assets/images/preview_image.jpeg';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import Spacer from '../../../components/common/Spacer';
import { FormLabel } from '../../../components/common/styledComponents';
import message from '../../../components/common/message';
import {
  HTTP_STATUS_CREATED,
  HTTP_STATUS_OK,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import FeatFormItems from '../FeatFormItems';
import { saveCopywritingBulkElements } from '../../../services/copywriting';
import Products from '../../../services/products';
import BasicAttrEditor, {
  // eslint-disable-next-line
  ATTR_CATEGORY_BASIC,
  // eslint-disable-next-line
  BASIC_ATTR_TYPE_DEMICAL,
  // BASIC_ATTR_TYPE_NUMBER,
  // BASIC_ATTR_TYPE_PRICE,
  // eslint-disable-next-line
  BASIC_ATTR_TYPE_SELECT,
  BASIC_ATTR_TYPE_STRING,
  BASIC_ATTR_TYPE_TEXT,
} from '../BasicAttrEditor';
import CategoryPathEditor from '../BasicAttrEditor/CategoryPathEditor';
import CommonSelectEditor from '../BasicAttrEditor/CommonSelectEditor';
import ReadonlyCell from '../BasicAttrEditor/ReadonlyCell';
import BasicImageCarousel from '../BasicImageCarousel';
import EditImageDialog from '../EditImageDialog';
import { loadProductBasicAttrs, loadStyleVariations } from '../helper';
import { TabPanelContainer } from '../styledComponents';

type Props = {
  dispatch?: Function;
  editOnly?: boolean;
  editSave?: number;
  isCreating: boolean;
  loadChannelControlFlags: Function;
  loadClassifications: Function;
  loadLabels: Function;
  onContentChanged?: Function;
  onSaved?: Function;
  productId?: string;
  state?: StringKAnyVPair;
};

const BasicInfo = (props: Props) => {
  const FORM_ITEM_CLS = 'form-item';
  const FORM_LABEL_CLS = 'form-label';
  const FORM_SECTION_CLS = 'form-section';
  const { useState } = React;
  const [attrDict, setAttrDict] = useState<StringKAnyVPair>({});
  //const [attrIdDict, setAttrIdDict] = useState<StringKAnyVPair>({});
  const [basicInfo, setBasicInfo] = useState<StringKAnyVPair>({});
  const [basicInfoReady, setBasicInfoReady] = useState(false);
  const [currentCategoryRoot, setCurrentCategoryRoot] = useState<any>();
  const [currentProductId, setProductId] = useState(props.productId);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [saveCount, setSaveCount] = useState(props.editSave || 0);
  const [searchTimer, setSearchTimer] = useState<number>();
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const attrSearchInputRef = React.useRef<any>(null);
  const pageRef = React.useRef<any>(null);
  //const [isLoadingProductData, setIsLoadingProductData] = useState(false);

  const profileSettings = useSelector((state: any) => state.profileSettings.profileSettings);

  /*const getStyleCodeBySku = (sku: string = '') => {
    const fields = sku.split('-');

    return fields[0] || '';
  };*/

  const getProfileSettingValue = (key: string, defaultValue = '') => {
    const ps = profileSettings;

    if (Array.isArray(ps)) {
      const fs = ps.filter(e => e.SettingCode === key);

      if (fs.length === 1) {
        return fs[0].SettingValue;
      }
    }

    return defaultValue;
  };
   
  const enableLegacyExperience = getProfileSettingValue('LegacyExperience', '0') === '1';

  const attrValue = (key: string) => {
    if (attrDict[key] && typeof attrDict[key] === 'object') {
      switch (key) {
        case 'ColorPatternCode':
          //console.log('basic', basicInfo.ErpInfoLite);
          return basicInfo.ErpInfoLite.colorPatternCode;

        case 'LengthCode':
          return basicInfo.ErpInfoLite.lengthCode;

        case 'NRFColorCode':
          return basicInfo.ErpInfoLite.colorNRF;

        case 'NRFSizeCode':
          return basicInfo.ErpInfoLite.sizeNRF;

        case 'ProductClassifications':
          return isNaN(parseInt(attrDict[key].value)) ? undefined : parseInt(attrDict[key].value);

        case 'SizeCode':
          return basicInfo.ErpInfoLite.sizeCode;

        case 'WidthCode':
          return basicInfo.ErpInfoLite.widthCode;

        default:
          /*if (['WidthCode', 'LengthCode'].indexOf(key) > -1) {
            console.log('attr dict', key, attrDict[key], attrDict[key].value);
          }*/
          return attrDict[key].value;
      }
    }
  };

  const closeEditImageDialog = () => {
    setEditImageDialogVisible(false);
  };

  // eslint-disable-next-line
  const enterEditMode = () => {
    setIsEditMode(true);
  };

  // eslint-disable-next-line
  const getNRFColorOptions = (colorCode: string) => {
    //console.log(styleVariation.colorPatternCode, '<-');
    //console.log('-->', styleVariation.colorPatternCode.filter((e: StringKAnyVPair) => e.code === colorCode));
    return (styleVariation?.colorPatternCode || [])
      .filter((e: StringKAnyVPair) => e.code === colorCode)
      .map((e: StringKAnyVPair) => ({
        classCode: e.classCode,
        code: e.nrfColorCode,
        description: e.nrfColorDescription,
        id: e.id,
        uuid: e.uuid,
      }));
  };

  // eslint-disable-next-line
  const getNRFSizeOptions = (sizeCode: string) => {
    return (styleVariation?.sizeCode || [])
      .filter((e: StringKAnyVPair) => e.code === sizeCode)
      .map((e: StringKAnyVPair) => ({
        classCode: e.classCode,
        code: e.nrfSizeCode,
        description: e.nrfSizeDescription,
        id: e.id,
        uuid: e.uuid,
      }));
  };

  // eslint-disable-next-line
  const isSomeValueChanged = () => {
    let ret = false;

    for (let k in attrDict) {
      if (attrDict[k].valueChanged) {
        ret = true;
        break;
      }
    }

    return ret;
  };

  const loadAttrData = async (
    productId: string,
    aDict: StringKAnyVPair,
  ) => {
    try {
      const res = await Products.getStyleMaster2(productId);

      if (res && typeof res === 'object') {
        const {
          channelControlFlagIdList,
          erpInfo,
          labelIdList,
          productBasic,
          productBasicInfoAttributeList,
        } = res;
        let info: StringKAnyVPair = {};
        console.log('attr data ->', res);
        if (productBasic && typeof productBasic === 'object') {
          console.log('pdb->', productBasic);
          info = { ...productBasic };
        }

        if (Array.isArray(productBasicInfoAttributeList)) {
          productBasicInfoAttributeList.forEach(e => {
            const k = e.attributeName?.trim();

            if (k && aDict[k]) {
              aDict[k].value = e.value;
              //console.log(k, aDict[k]);
            }
          });
        }

        info.ErpInfo = erpInfo || {};
        info.ErpInfoLite = res.erpInfoLite || {};
        info.LabelList = Array.isArray(labelIdList) ? labelIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        info.FlagList = Array.isArray(channelControlFlagIdList) ? channelControlFlagIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        //console.log('-->', labelIdList, basicInfo);
        setBasicInfo({
          ...basicInfo,
          ...info,
        });
        console.log('info - ', info);
      }
    } catch (e) {
      message.error(`Fetch product style master data error: ${e}`);
    } finally {
    }
  };

  const loadBasicAttrs = async () => {
    setIsLoading(true);

    const attrs = await loadProductBasicAttrs();

    //console.log('attrs ->', attrs);
    if (Array.isArray(attrs)) {
      const dict: StringKAnyVPair = {};
      //const dict2: StringKAnyVPair = {};

      //attrs.forEach(e => dict[e.basicAttributeId] = e);
      //attrs.forEach(e => dict[e.basicAttributeName] = e);
      attrs.forEach(e => {
        e.fieldName = e.fieldName.trim();
        dict[e.fieldName] = e;
        //dict2[e.basicAttributeNum] = e;
      });

      if (props.productId) {
        await loadAttrData(props.productId, dict);
      }

      console.log('attr dict', dict, /*dict.NRFColorCode*/);
      setAttrDict(dict);
      //setAttrIdDict(dict2);

      if (dict.ProductClassifications.value) {
        if (typeof dict.ProductClassifications.value === 'string') {
          setCurrentCategoryRoot(parseInt(dict.ProductClassifications.value));
        }
      }
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line
  const loadProductData = async (productId: string) => {
    setIsLoading(true);
    //setIsLoadingProductData(true);

    try {
      const pd = await Products.fetchSingleProductById(productId);

      console.log('pd -->', pd);
      if (pd && typeof pd === 'object') {
        if (pd.ProductBasic && typeof pd.ProductBasic === 'object') {
          const { /*FlagList,*/ ProductBasic } = pd;
          const info = ProductBasic as StringKAnyVPair;

          if (Array.isArray(basicInfo.FlagList)) {
            info.FlagList = basicInfo.FlagList;
          }

          if (Array.isArray(basicInfo.LabelList)) {
            info.LabelList = basicInfo.LabelList;
          }

          //setBasicInfo(pd.ProductBasic);
          setBasicInfo({ ...info });
          console.log('info ->', info);
          setBasicInfoReady(true);
        }
      } else {
        message.error(`Product data error: ${pd}`);
      }
    } catch (e) {
      message.error(`Fetch product data error: ${e}`);
    } finally {
      setIsLoading(false);
      //setIsLoadingProductData(false);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);

    const sv = await loadStyleVariations();

    await loadBasicAttrs();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    if (props.productId) {
      //await loadProductData(props.productId);
    }

    setBasicInfoReady(true);
  };

  const onSearchAttrKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (/*sk &&*/ typeof sk === 'string' && pageRef.current) {
      const sections = pageRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      sk = sk.toLowerCase().trim();
      setIsSearchEmpty(sk === '');

      for (let i = 0; i < sections.length; i++) {
        const sec = sections[i];
        const items = sec.querySelectorAll(`.${FORM_ITEM_CLS}`);
        let showCount = 0;

        if (i === 0 && items.length === 0) {
          const labelDoms = sec.querySelectorAll(`.${FORM_LABEL_CLS}`);

          for (let k = 0; k < labelDoms.length; k++) {
            const labelDom = labelDoms[k];
            const label = labelDom.textContent.trim();

            if (label && typeof label === 'string') {
              const match = label.toLowerCase().indexOf(sk) > -1;
              const item = labelDom.parentNode.parentNode;

              item.style.display = match ? '' : 'none';
            }
          }
        }

        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          const labelDom = item.querySelector(`.${FORM_LABEL_CLS}`);

          if (labelDom) {
            const label = labelDom.textContent.trim();

            if (label && typeof label === 'string') {
              const match = label.toLowerCase().indexOf(sk) > -1;

              showCount += (match ? 1 : 0);
              item.style.display = match ? '' : 'none';
            }
          }
        }

        if (i > 0 && items.length > 0) {
          sec.style.display = showCount > 0 ? '' : 'none';
        }
      }
    }
  };

  // eslint-disable-next-line
  const onSelectOperationOption = (
    val: any,
    pn: string,
    isOperation = false,
  ) => {
    const noneOpes: string[] = [
      'warranty',
    ];

    if (attrDict[pn] === undefined) {
      attrDict[pn] = { fieldName: pn };
    }

    attrDict[pn].editValue = val;
    attrDict[pn].isOperation = noneOpes.indexOf(pn) > -1 ? false : isOperation;
    attrDict[pn].valueChanged = true;
    setAttrDict({ ...attrDict });
  };

  /*const openEditImageDialog = () => {
    setEditImageDialogVisible(true);
  };*/

  // eslint-disable-next-line
  const refreshStyleVariations = async () => {
    const sv = await loadStyleVariations(true);

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }
  };

  const resetAttrState = () => {
    setAttrDict({ ...attrDict });
  };

  const saveFormData = async () => {
    setIsLoading(true);

    try {
      const basic: StringKAnyVPair = {};
      const data: StringKAnyVPair = {
        ApplyToAllRelevantSubStyleAndSKU: props.state?.overwriteChildren || false,
        styleCode: basicInfo.sku,
        productBasic: basic,
      };
      const skd: StringKAnyVPair = {};
      const cpes: StringKAnyVPair[] = [];
      console.log('-->', data);
      for (let k in attrDict) {
        const attr = attrDict[k];

        if (attr.valueChanged) {
          console.log(k, attr);
          if ('source' in attr) {
            cpes.push({
              CopywritingElementNum: attr.attributeId,
              //CopywritingElementValue: currentValue,
              CopywritingValue: attr.editValue,
              overwrite: props.state?.overwriteChildren || false,
            });
          }

          if (attr.basicAttributeNum > 999) {
            if (!Array.isArray(data.productBasicInfoAttributeList)) data.productBasicInfoAttributeList = [];

            data.productBasicInfoAttributeList.push({
              AttributeId: attr.basicAttributeId,
              Value: attr.editValue,
              editType: 1,
            });
          } else {
            basic[attr.fieldName] = attr.editValue;
          }

          skd[k] = attr;
        }
      }
      console.log('pdata', data);
      if (props.productId) {
        if (cpes.length > 0) {
          console.log('cpes', cpes);
          await saveCopywritingBulkElements(
            props.productId,
            basicInfo.sku,
            props.state?.overwriteChildren || false,
            cpes,
          );
        }

        await Products.editSimpleStyleMaster(props.productId, data);
        // set attr status at here
        for (let k in skd) {
          skd[k].valueChanged = false;
        }
        console.log('saved', skd);
        setAttrDict({ ...attrDict });
        message.success('Saved successfully');

        if (typeof props.onSaved === 'function') {
          props.onSaved(true);
        }
      }
    } catch (e) {
      message.error(`Saved basic info error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const saveImages = async (imgs: StringKAnyVPair[]) => {
    try {
      if (props.productId) {
        const res = await Products.editProductImages(props.productId, imgs) as StringKAnyVPair;

        if (res && typeof res === 'object') {
          //console.log('r->', res);
          return [HTTP_STATUS_CREATED, HTTP_STATUS_OK].indexOf(res.status) > -1;
        } else {
          message.error(`Save image failed: ${res}`);
        }
      }
    } catch (e) {
      message.error(`Save image error: ${e}`);
    }
  };

  const saveMainImage = async (imgUrl: string) => {
    const mainImage = attrDict.MainImage;

    if (mainImage) {
      setIsLoading(true);
      const res = await saveImages([{
        imageAttributeNum: mainImage.basicAttributeNum,
        imageURL: imgUrl,
      }]);
      setIsLoading(false);

      if (res) {
        mainImage.value = imgUrl;
        setAttrDict({ ...attrDict });
        message.success(`Edit ${mainImage.basicAttributeName} successfully`);
      }

      return res;
    } else {
      message.error(`Main image is lost`);
    }
  };

  // eslint-disable-next-line
  const trySaveFormData = () => {
    if (isSomeValueChanged()) {
      saveFormData();
    } else {
      message.info('No data changed');
    }
  };

  React.useEffect(() => {
    if (props.productId && (!inited || currentProductId !== props.productId)) {
      loadInitialData();
      setInited(true);
      setProductId(props.productId);
    }

    if (typeof props.editOnly === 'boolean') {
      if (isEditMode !== props.editOnly) {
        setIsEditMode(props.editOnly);
      }
    }

    if (props.editOnly) {
      if (typeof props.editSave === 'number') {
        if (saveCount !== props.editSave) {
          setSaveCount(props.editSave);
          console.log('ready save', props.editSave);
          trySaveFormData();
        }
      }

      if (typeof props.onContentChanged === 'function') {
        props.onContentChanged(isSomeValueChanged());
      }
    }
  }, [
    inited,
    isEditMode,
    isSomeValueChanged,
    loadInitialData,
    props,
    saveCount,
    trySaveFormData,
    currentProductId,
    props.productId,
  ]);

  return (<>
    {basicInfoReady && Object.keys(basicInfo).length > 0 && <TabPanelContainer ref={pageRef}>
      {/*<Row justify="end">
        {isEditMode && (
          <Button
            disabled={!isSomeValueChanged()}
            onClick={saveFormData}
            type="primary"
          >
            Save
            <SaveOutlined />
          </Button>
        )}
        {!isEditMode && (
          <Button type="primary" onClick={enterEditMode}>
            Edit
            <EditOutlined />
          </Button>
        )}
      </Row>
      <Spacer />*/}
      <Row className="form-section" justify="space-between">
        <div className="form-field-area-1">
          <Space direction="vertical" className="full-width">
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Style</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<Input value={basicInfo.styleCode|| ''} disabled />*/}
                    <ReadonlyCell
                      value={basicInfo.styleCode || ''}
                    />
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label required-1">SKU</FormLabel>
                  <span className="form-input-wrapper input-2">
                    <ReadonlyCell
                      value={basicInfo.sku || ''}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
            <div>
              <Row align="middle">
                <FormLabel className="form-label label-2">Product Name</FormLabel>
                <span className="form-input-wrapper input-4">
                  {/*<BasicAttrEditor
                    attr={attrDict['Subtitle']}
                    editOnly={isEditMode}
                    label="Product Name"
                    name="Subtitle"
                    productId={props.productId}
                    refreshFormState={resetAttrState}
                    styleCode={basicInfo.sku}
                    type={BASIC_ATTR_TYPE_STRING}
                    value={basicInfo.subtitle || ''}
                  />*/}
                  <ReadonlyCell
                    value={basicInfo.subtitle || ''}
                  />
                </span>
              </Row>
            </div>
          </Space>
          <hr className="full-width" style={{display: isSearchEmpty ? '' : 'none'}} />
          <Space direction="vertical" className="full-width box-ctn-2">
            {enableLegacyExperience && (<>
            <div>
              <Row align="middle" style={{ width: '100%' }}>
                <FormLabel className="form-label label-2">Product Classifications</FormLabel>
                <span className="form-input-wrapper input-4">
                  <CommonSelectEditor
                    attr={attrDict['ProductClassifications']}
                    editOnly={isEditMode}
                    onSelect={setCurrentCategoryRoot}
                    productId={props.productId}
                    refreshFormState={resetAttrState}
                    state={props.state}
                    styleCode={basicInfo.sku}
                    type="productClassifications"
                    value={attrValue('ProductClassifications')}
                  />
                </span>
              </Row>
            </div>
            <div>
              <Row align="middle" style={{ width: '100%' }}>
                <FormLabel className="form-label label-2">Product Category</FormLabel>
                <span className="form-input-wrapper input-4">
                  <CategoryPathEditor
                    attr={attrDict['ProductCategoryPath']}
                    classification={currentCategoryRoot}
                    editOnly={isEditMode}
                    name="productCategoryPath"
                    productId={props.productId}
                    refreshFormState={resetAttrState}
                    state={props.state}
                    styleCode={basicInfo.sku}
                    value={attrValue('ProductCategoryPath')}
                  />
                </span>
              </Row>
            </div>
            </>)}
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Brand</FormLabel>
                  <span className="form-input-wrapper input-4">
                    <CommonSelectEditor
                      attr={attrDict['Brand']}
                      editOnly={isEditMode}
                      options={styleVariation.brand}
                      name="brand"
                      productId={props.productId}
                      readOnly
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type="brand"
                      value={basicInfo.brand || undefined}
                    />
                    {/*<ReadonlyCell
                      value={basicInfo.brand || ''}
                    />*/}
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Manufacturer</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<CommonSelectEditor
                      attr={attrDict['Manufacturer']}
                      editOnly={isEditMode}
                      options={styleVariation.manufacturer}
                      name="manufacturer"
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.SKU}
                      type="manufacturer"
                      value={basicInfo.manufacturer || undefined}
                    />*/}
                    <ReadonlyCell
                      value={basicInfo.manufacturer || ''}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Gender</FormLabel>
                  <span className="form-input-wrapper input-4">
                    <CommonSelectEditor
                      attr={attrDict['Gender']}
                      editOnly={isEditMode}
                      options={styleVariation.gender}
                      productId={props.productId}
                      readOnly
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type="gender"
                      value={attrValue('Gender')}
                    />
                    {/*<ReadonlyCell
                      value={attrValue('Gender')}
                    />*/}
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Warranty</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*basicInfoReady && <BasicAttrEditor
                      category={ATTR_CATEGORY_BASIC}
                      editOnly={isEditMode}
                      label="Warranty"
                      name="warranty"
                      onChange={onSelectOperationOption}
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_STRING}
                      value={basicInfo.warranty || ''}
                    />*/}
                    {basicInfoReady && <ReadonlyCell
                      value={basicInfo.warranty || ''}
                    />}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Age Group</FormLabel>
                  <span className="form-input-wrapper input-4">
                    <CommonSelectEditor
                      attr={attrDict['AgeGroup']}
                      editOnly={isEditMode}
                      options={styleVariation.ageGroup}
                      productId={props.productId}
                      readOnly
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type="ageGroup"
                      value={attrDict.AgeGroup.value || undefined}
                    />
                    {/*<ReadonlyCell
                      value={attrDict.AgeGroup.value || ''}
                    />*/}
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">NRF Department</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<BasicAttrEditor
                      attr={attrDict['NRFDepartment']}
                      label="NRF Department"
                      name="NRFDepartment"
                      productId={props.productId}
                      styleCode={basicInfo.SKU}
                      type={BASIC_ATTR_TYPE_SELECT}
                      value={undefined}
                    />*/}
                    <ReadonlyCell
                      value={undefined}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Condition</FormLabel>
                  <span className="form-input-wrapper input-4">
                    <BasicAttrEditor
                      attr={attrDict['Condition']}
                      editOnly={isEditMode}
                      label="Condition"
                      name="condition"
                      productId={props.productId}
                      readOnly={true}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_SELECT}
                      value={basicInfo.condition}
                    />
                    {/*<ReadonlyCell
                      value={basicInfo.condition}
                    />*/}
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Country of Origin</FormLabel>
                  <span className="form-input-wrapper input-4">
                    <CommonSelectEditor
                      attr={attrDict['CountryofOrigin']}
                      editOnly={isEditMode}
                      options={styleVariation.countryOfOrigin}
                      productId={props.productId}
                      readOnly
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type="countryOfOrigin"
                      value={attrValue('CountryofOrigin')}
                    />
                    {/*<ReadonlyCell
                      value={attrValue('CountryofOrigin')}
                    />*/}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Create By</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<BasicAttrEditor
                      attr={attrDict['createBy']}
                      editOnly={isEditMode}
                      label="Create By"
                      name="createBy"
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_STRING}
                      value={basicInfo.createBy}
                    />*/}
                    <ReadonlyCell
                      value={basicInfo.createBy || ''}
                    />
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Create Date</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<BasicAttrEditor
                      attr={attrDict['createDate']}
                      editOnly={isEditMode}
                      label="Create Date"
                      name="createDate"
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_STRING}
                      value={basicInfo.createDate}
                    />*/}
                    <ReadonlyCell
                      value={basicInfo.createDate || ''}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Update By</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<BasicAttrEditor
                      attr={attrDict['updateBy']}
                      editOnly={isEditMode}
                      label="Update By"
                      name="updateBy"
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_STRING}
                      value={basicInfo.updateBy}
                    />*/}
                    <ReadonlyCell
                      value={basicInfo.updateBy || ''}
                    />
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="middle">
                  <FormLabel className="form-label label-2">Update Date</FormLabel>
                  <span className="form-input-wrapper input-4">
                    {/*<BasicAttrEditor
                      attr={attrDict['updateDate']}
                      editOnly={isEditMode}
                      label="Update Date"
                      name="updateDate"
                      productId={props.productId}
                      refreshFormState={resetAttrState}
                      styleCode={basicInfo.sku}
                      type={BASIC_ATTR_TYPE_STRING}
                      value={basicInfo.updateDate}
                    />*/}
                    <ReadonlyCell
                      value={basicInfo.updateDate || ''}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
          </Space>
        </div>
        <div className="prd-main-img-wrapper">
          {/*<Row align="middle" className="img-area" justify="center">
            
            <Image
              src={attrDict.MainImage?.value || ImagePlaceholder}
            />
          </Row>*/}
          <div className="basic-search-toolbar">
            <Input.Search
              allowClear
              className="filter-inputbar"
              enterButton={null}
              onKeyUp={onSearchAttrKeyUp}
              onSearch={onSearchAttributes}
              ref={attrSearchInputRef}
              prefix={!isSearchEmpty ? <FilterTwoTone /> : <FilterOutlined />}
            />
          </div>
          <BasicImageCarousel
            dispatch={props.dispatch}
            productId={props.productId || ''}
            state={props.state || {}}
            style={{ height: 'calc(100% - 40px)' }}
          />
          {/*<div className="img-area-toolbar">
            <Button onClick={openEditImageDialog}>
              <EditOutlined />
            </Button>
          </div>*/}
        </div>
      </Row>
      <Spacer />
      <div className="form-section">
        <span className="section-title">Pricing</span>
        <hr />
        <div className="form-item item-3">
          <Row align="middle">
            <FormLabel className="form-label label-5">Wholesale Price</FormLabel>
            <span className="form-input-wrapper input-7">
              {/* <BasicAttrEditor
                attr={attrDict['Price']}
                editOnly={isEditMode}
                label="Wholesale Price"
                name="price"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_PRICE}
                value={basicInfo.price}
              /> */}
              <ReadonlyCell value={`$${Number(basicInfo.price).toFixed(2)}`} />
            </span>
          </Row>
        </div>
        <div className="form-item item-3">
          <Row align="middle">
            <FormLabel className="form-label label-1">MSRP</FormLabel>
            <span className="form-input-wrapper input-1">
              {/* <BasicAttrEditor
                attr={attrDict['MSRP']}
                editOnly={isEditMode}
                label="MSRP"
                name="msrp"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_PRICE}
                value={basicInfo.msrp}
              /> */}
              <ReadonlyCell value={`$${Number(basicInfo.msrp).toFixed(2)}`} />
            </span>
          </Row>
        </div>
        <div className="form-item item-3">
          <Row align="middle">
            <FormLabel className="form-label label-1">MAP</FormLabel>
            <span className="form-input-wrapper input-1">
              {/* <BasicAttrEditor
                attr={attrDict['MAPPrice']}
                editOnly={isEditMode}
                label="MAP"
                name="mapPrice"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_PRICE}
                value={basicInfo.mapPrice}
              /> */}
              <ReadonlyCell value={`$${Number(basicInfo.mapPrice).toFixed(2)}`} />
            </span>
          </Row>
        </div>
        {/*<div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">Sale Price</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                attr={attrDict['Price']}
                editOnly={isEditMode}
                label="Sale Price"
                name="price"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={basicInfo.price}
              />
            </span>
          </Row>
        </div>*/}
        <div className="form-item item-3">
          <Row align="middle">
            <FormLabel className="form-label label-1">Currency</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['Currency']}
                label="Currency"
                name="currency"
                productId={props.productId}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={basicInfo.Currency}
              />*/}
              <span className="cell-box cell-value cell-disabled2">{basicInfo.ErpInfo.currency || ''}</span>
            </span>
          </Row>
        </div>
      </div>
      <Spacer />
      <div className="form-section">
        <span className="section-title">Specifications</span>
        <hr />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Color</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<CommonSelectEditor
                attr={attrDict['ColorPatternCode']}
                options={styleVariation.colorPatternCode}
                name="colorPatternCode"
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="colorPatternCode"
                value={undefined}
              />*/}
              <CommonSelectEditor
                attr={attrDict['ColorPatternCode']}
                editOnly={isEditMode}
                name="colorPatternCode"
                //options={styleVariation.colorPatternCode}
                options={[{code: basicInfo.ErpInfoLite.colorPatternCode, description: basicInfo.ErpInfoLite.colorDesc}]}
                productId={props.productId}
                readOnly
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="colorPatternCode"
                value={attrValue('ColorPatternCode')}
              />
              {/*<ReadonlyCell
                value={basicInfo.ErpInfo.colorPatternCode || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Size</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<CommonSelectEditor
                attr={attrDict['SizeCode']}
                name="sizeCode"
                options={styleVariation.sizeCode}
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="sizeCode"
                value={undefined}
              />*/}
              <CommonSelectEditor
                attr={attrDict['SizeCode']}
                editOnly={isEditMode}
                name="sizeCode"
                //options={styleVariation.colorPatternCode}
                options={[{code: basicInfo.ErpInfoLite.sizeCode, description: basicInfo.ErpInfoLite.sizeDesc}]}
                productId={props.productId}
                readOnly
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="sizeCode"
                value={attrValue('SizeCode')}
              />
              {/*<ReadonlyCell
                value={basicInfo.ErpInfo.sizeCode || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Width</FormLabel>
            <span className="form-input-wrapper input-7">
              <CommonSelectEditor
                attr={attrDict['WidthCode']}
                name="widthCode"
                //options={styleVariation.widthCode}
                options={[{code: basicInfo.ErpInfoLite.widthCode, description: basicInfo.ErpInfoLite.widthDesc}]}
                readOnly
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="widthCode"
                value={attrValue('WidthCode')}
              />
              {/*<ReadonlyCell
                value={basicInfo.ErpInfo.widthCode || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Length</FormLabel>
            <span className="form-input-wrapper input-1">
              <CommonSelectEditor
                attr={attrDict['LengthCode']}
                name="lengthCode"
                //options={styleVariation.lengthCode}
                options={[{code: basicInfo.ErpInfoLite.lengthCode, description: basicInfo.ErpInfoLite.lengthDesc}]}
                readOnly
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="lengthCode"
                value={attrValue('LengthCode')}
              />
              {/*<ReadonlyCell
                value={basicInfo.ErpInfo.lengthCode || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Color NRF
            </FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<CommonSelectEditor
                attr={attrDict['NRFColorCode']}
                editOnly={isEditMode}
                name="NRFColorCode"
                //options={styleVariation.colorPatternCode}
                //options={getNRFColorOptions(basicInfo.ErpInfo.colorPatternCode)}
                options={[{code: basicInfo.ErpInfoLite.colorNRF, description: ''}]}
                productId={props.productId}
                readOnly
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="NRFColorCode"
                value={attrValue('NRFColorCode')}
              />*/}
              <ReadonlyCell
                value={attrValue('NRFColorCode')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Size NRF</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<CommonSelectEditor
                attr={attrDict['NRFSizeCode']}
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="NRFSizeCode"
                value={undefined}
              />*/}
              {/*<CommonSelectEditor
                attr={attrDict['NRFSizeCode']}
                editOnly={isEditMode}
                name="NRFSizeCode"
                //options={styleVariation.colorPatternCode}
                //options={getNRFSizeOptions(basicInfo.ErpInfo.sizeCode)}
                options={[{code: basicInfo.ErpInfoLite.sizeNRF, description: ''}]}
                productId={props.productId}
                readOnly
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="NRFSizeCode"
                value={attrValue('NRFSizeCode')}
              />*/}
              <ReadonlyCell
                value={attrValue('NRFSizeCode')}
              />
            </span>
          </Row>
        </div>
        <hr style={{display: isSearchEmpty ? '' : 'none'}} />
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">Length</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['ProductLength']}
                editOnly={isEditMode}
                label="Length"
                name="productLength"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.productLength}
              />*/}
              <ReadonlyCell
                value={basicInfo.productLength}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Shipping Length</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['BoxLength']}
                editOnly={isEditMode}
                label="Shipping Length"
                name="boxLength"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.boxLength}
              />*/}
              <ReadonlyCell
                value={basicInfo.boxLength}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Gross Weight</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['GrossWeight']}
                editOnly={isEditMode}
                label="Gross Weight"
                name="grossWeight"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.grossWeight}
              />*/}
              <ReadonlyCell
                value={basicInfo.grossWeight}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">Width</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['ProductWidth']}
                editOnly={isEditMode}
                label="Width"
                name="productWidth"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.productWidth}
              />*/}
              <ReadonlyCell
                value={basicInfo.productWidth}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Shipping Width</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['BoxWidth']}
                editOnly={isEditMode}
                label="Shipping Width"
                name="boxWidth"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.boxWidth}
              />*/}
              <ReadonlyCell
                value={basicInfo.boxWidth}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Net Weight</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['NetWeight']}
                editOnly={isEditMode}
                label="Net Weight"
                name="netWeight"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.netWeight}
              />*/}
              <ReadonlyCell
                value={basicInfo.netWeight}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">Height</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['ProductHeight']}
                editOnly={isEditMode}
                label="Height"
                name="productHeight"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.productHeight}
              />*/}
              <ReadonlyCell
                value={basicInfo.productHeight}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Shipping Height</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['BoxHeight']}
                editOnly={isEditMode}
                label="Shipping Height"
                name="boxHeight"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_DEMICAL}
                value={basicInfo.boxHeight}
              />*/}
              <ReadonlyCell
                value={basicInfo.boxHeight}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">Weight Unit</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['WeightUnit']}
                editOnly={isEditMode}
                label="Weight Unit"
                name="weightUnit"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_SELECT}
                value={basicInfo.weightUnit}
              />*/}
              <ReadonlyCell
                value={basicInfo.weightUnit}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">Dimension Unit</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['DimensionUnit']}
                editOnly={isEditMode}
                label="Dimension Unit"
                name="dimensionUnit"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_SELECT}
                value={basicInfo.dimensionUnit}
              />*/}
              <ReadonlyCell
                value={basicInfo.dimensionUnit}
              />
            </span>
          </Row>
        </div>
        <hr style={{display: isSearchEmpty ? '' : 'none'}} />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Harmonized Code US</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                attr={attrDict['HarmonizedCode']}
                editOnly={isEditMode}
                label="Harmonized Code US"
                name="harmonizedCode"
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.harmonizedCode}
              />
              {/*<ReadonlyCell
                value={basicInfo.harmonizedCode}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-4">Harmonized Code Canada</FormLabel>
            <span className="form-input-wrapper input-6">
              <BasicAttrEditor
                attr={attrDict['HarmonizedCodeCanada']}
                editOnly={isEditMode}
                label="Harmonized Code Canada"
                name="harmonizedCodeCanada"
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('HarmonizedCodeCanada')}
              />
            </span>
          </Row>
        </div>
        <hr style={{display: isSearchEmpty ? '' : 'none'}} />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Materials & Content</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<CommonSelectEditor
                attr={attrDict['MaterialsContent']}
                editOnly={isEditMode}
                name="materialContent"
                options={styleVariation.materialsContent}
                productId={props.productId}
                refreshFormState={resetAttrState}
                readOnly={true}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="materialsContent"
                value={attrValue('MaterialsContent')}
              />*/}
              <ReadonlyCell
                //value={attrValue('MaterialsContent')}
                value={basicInfo.ErpInfoLite.materialsContentDesc}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-4">Care Instructions</FormLabel>
            <span className="form-input-wrapper input-6">
              {/*<CommonSelectEditor
                attr={attrDict['CareInstructions']}
                editOnly={isEditMode}
                name="careInstructions"
                options={styleVariation.careInstructions}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type="careInstructions"
                value={attrValue('CareInstructions')}
              />*/}
              <ReadonlyCell
                //value={attrValue('CareInstructions')}
                value={basicInfo.ErpInfoLite.careInstructionsDesc}
              />
            </span>
          </Row>
        </div>
      </div>
      <Spacer />
      <div className="form-section">
        <span className="section-title">Identity</span>
        <hr />
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">UPC</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['UPC']}
                editOnly={isEditMode}
                label="UPC"
                name="UPC"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.upc}
              />*/}
              <ReadonlyCell
                value={basicInfo.upc}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">EAN</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['EAN']}
                editOnly={isEditMode}
                label="EAN"
                name="EAN"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.ean}
              />*/}
              <ReadonlyCell
                value={basicInfo.ean}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">ISBN</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*<BasicAttrEditor
                attr={attrDict['ISBN']}
                editOnly={isEditMode}
                label="ISBN"
                name="ISBN"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.isbn}
              />*/}
              <ReadonlyCell
                value={basicInfo.isbn}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-5">MPN</FormLabel>
            <span className="form-input-wrapper input-7">
              {/*<BasicAttrEditor
                attr={attrDict['MPN']}
                editOnly={isEditMode}
                label="MPN"
                name="MPN"
                productId={props.productId}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.mpn}
              />*/}
              <ReadonlyCell
                value={basicInfo.mpn}
              />
            </span>
          </Row>
        </div>
        {/*<div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">ASIN</FormLabel>
            <span className="form-input-wrapper input-1">
              <BasicAttrEditor
                attr={attrDict['ASIN']}
                label="ASIN"
                name="ASIN"
                productId={props.productId}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.asin}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-2">
          <Row align="middle">
            <FormLabel className="form-label label-1">FNSku</FormLabel>
            <span className="form-input-wrapper input-1">
              <BasicAttrEditor
                attr={attrDict['FNSku']}
                label="FNSku"
                name="FNSku"
                productId={props.productId}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.fnSku}
              />
            </span>
          </Row>
        </div>*/}
      </div>
      <Spacer />
      {enableLegacyExperience && (<>
      <div className="form-section">
        <span className="section-title">Product Features</span>
        <hr />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Product Title</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['ProductTitle']}
                editOnly={isEditMode}
                label="Product Title"
                name="productTitle"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.productTitle || ''}
              />
              {/*<ReadonlyCell
                value={basicInfo.productTitle || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Short Description</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['ShortDescription']}
                editOnly={isEditMode}
                label="Short Description"
                name="shortDescription"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={basicInfo.shortDescription || ''}
              />
              {/*<ReadonlyCell
                value={basicInfo.shortDescription || ''}
              />*/}
            </span>
          </Row>
        </div>
        <div>
          <div className="form-item item-1">
            <Row align="middle">
              <FormLabel className="form-label label-5">Long Description</FormLabel>
              <span className="form-input-wrapper input-7">
                <BasicAttrEditor
                  allowClear={true}
                  attr={attrDict['LongDescription']}
                  editOnly={isEditMode}
                  label="Product Description"
                  name="longDescription"
                  productId={props.productId}
                  refreshFormState={resetAttrState}
                  state={props.state}
                  styleCode={basicInfo.sku}
                  type={BASIC_ATTR_TYPE_TEXT}
                  value={basicInfo.longDescription || ''}
                />
              </span>
            </Row>
          </div>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Key Features 1</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['KeyFeatures1']}
                editOnly={isEditMode}
                label="Key Features 1"
                name="keyFeatures1"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('KeyFeatures1')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Key Features 2</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['KeyFeatures2']}
                editOnly={isEditMode}
                label="Key Features 2"
                name="keyFeatures2"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('KeyFeatures2')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Key Features 3</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['KeyFeatures3']}
                editOnly={isEditMode}
                label="Key Features 3"
                name="keyFeatures3"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('KeyFeatures3')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Key Features 4</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['KeyFeatures4']}
                editOnly={isEditMode}
                label="Key Features 4"
                name="keyFeatures4"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('KeyFeatures4')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Key Features 5</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['KeyFeatures5']}
                editOnly={isEditMode}
                label="Key Features 5"
                name="keyFeatures5"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('KeyFeatures5')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Custom Label 0</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['custom_label_0']}
                editOnly={isEditMode}
                label="Custom Label 0"
                name="custom_label_0"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('custom_label_0')}
              />
              {/*<ReadonlyCell
                value={attrValue('custom_label_0')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Custom Label 1</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['custom_label_1']}
                editOnly={isEditMode}
                label="Custom Label 1"
                name="custom_label_1"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('custom_label_1')}
              />
              {/*<ReadonlyCell
                value={attrValue('custom_label_1')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Custom Label 2</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['custom_label_2']}
                editOnly={isEditMode}
                label="Custom Label 2"
                name="custom_label_2"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('custom_label_2')}
              />
              {/*<ReadonlyCell
                value={attrValue('custom_label_2')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Custom Label 3</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['custom_label_3']}
                editOnly={isEditMode}
                label="Custom Label 3"
                name="custom_label_3"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('custom_label_3')}
              />
              {/*<ReadonlyCell
                value={attrValue('custom_label_3')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Custom Label 4</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['custom_label_4']}
                editOnly={isEditMode}
                label="Custom Label 4"
                name="custom_label_4"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('custom_label_4')}
              />
              {/*<ReadonlyCell
                value={attrValue('custom_label_4')}
              />*/}
            </span>
          </Row>
        </div>
      </div>
      <Spacer />
      <div className="form-section">
        <span className="section-title">SEO</span>
        <hr />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Meta Title</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['MetaTitle']}
                editOnly={isEditMode}
                label="Meta Title"
                name="metaTitle"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={attrValue('MetaTitle')}
              />
              {/*<ReadonlyCell
                value={attrValue('MetaTitle')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1" />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Meta Description</FormLabel>
            <span className="form-input-wrapper input-7">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['MetaDescription']}
                editOnly={isEditMode}
                label="Meta Description"
                name="metaDescription"
                productId={props.productId}
                refreshFormState={resetAttrState}
                state={props.state}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('MetaDescription')}
              />
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-6">Meta Keywords</FormLabel>
            <span className="form-input-wrapper input-8">
              <BasicAttrEditor
                allowClear={true}
                attr={attrDict['MetaKeywords']}
                editOnly={isEditMode}
                label="Meta Keywords"
                name="metaKeywords"
                productId={props.productId}
                state={props.state}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_TEXT}
                value={attrValue('MetaKeywords')}
              />
            </span>
          </Row>
        </div>
      </div>
      </>)}
      {!enableLegacyExperience && (<>
        <FeatFormItems
          //attrDict={attrIdDict}
          attrDict={attrDict}
          ignoreTrailingSpacer={true}
          isEditMode={isEditMode}
          productId={props.productId || ''}
          refreshFormState={resetAttrState}
          setAttrDict={setAttrDict}
          state={props.state || {}}
          styleCode={basicInfo.sku}
        />
      </>)}
    </TabPanelContainer>}
    {editImageDialogVisible && (
      <EditImageDialog
        imageUrl={attrDict.MainImage?.value || ''}
        onClose={closeEditImageDialog}
        onSave={saveMainImage}
        visible={editImageDialogVisible}
      />
    )}
    {(isLoading /*|| isLoadingProductData*/) && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default BasicInfo;
