import React, { useEffect, useState } from "react";
import ContentLayout from "../../../../components/ContentLayout";
import Heading from "../../../../components/common/Heading";
import Spacer from "../../../../components/common/Spacer";
import SiteContent from "../../../../components/SiteContent";
import { DataGrid } from "../../../../components/common/datagrid/DataGrid";
import { addMediaGroups, deleteMediaGroups, editMediaGroups, fetchMediaGroups, sortMediaGroups } from "../../../../services/products";
import { Button, Form, Input, Modal, Row, Space, message } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";

type MediaGroupsProps = {

}

type MediaGroup = {
  groupNum: number,
  groupName: string,
  groupDescription: string,
  displaySequence: number,
  columns: number,
  labelPosition: number,
  elements: any
}

const MediaGroups = (props: MediaGroupsProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<MediaGroup[]>([]);
  const [current, setCurrent] = useState<MediaGroup>();
  const [modalType, setModalType] = useState<'Add' | 'Edit'>('Add');
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  const columns = [
    {
      header: 'Group Name',
      name: 'groupName',
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Description',
      name: 'groupDescription',
      sortable: true,
      defaultFlex: 1,
    },
    // {
    //   header: 'Rows',
    //   // name: 'groupName',
    //   sortable: true,
    //   defaultFlex: 1,
    // },
    {
      header: 'Actions',
      sortable: true,
      defaultFlex: 2,
      render: (row: any) => {
        const { data, rowIndex } = row;
        return (
          <Row justify="space-between">
            <Space>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setCurrent(data);
                  form.setFieldsValue({
                    groupName: data.groupName,
                    groupDescription: data.groupDescription,
                    displaySequence: data.displaySequence
                  })
                  setModalType('Edit');
                  setEditModalVisible(true);
                }}
              >
                Edit
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this group?',
                    content: `Group Name: ${data.groupName}`,
                    onOk: () => deleteGroup(data),
                    onCancel: () => { },
                  })
                }}
              >
                Delete
              </Button>
            </Space>
            <Space>
              <Button
                onClick={() => uploadSequance(data, 'down')}
                icon={<DownOutlined />}
                disabled={rowIndex === groups.length - 1}
              />
              <Button
                onClick={() => uploadSequance(data, 'up')}
                icon={<UpOutlined />}
                disabled={rowIndex === 0}
              />
            </Space>
          </Row>
        )
      }
    },
  ]


  const addGroup = async () => {
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      setLoading(true);
      await addMediaGroups(
        {
          ...current,
          ...params
        }
      );
      message.success('Add group successfully!');
      setEditModalVisible(false);
      getGroups();
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const uploadSequance = async (rowData: MediaGroup, option: 'up' | 'down') => {
    try {
      setLoading(true);
      await sortMediaGroups(
        rowData.groupNum,
        option
      );
      getGroups();
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const editGroup = async () => {
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      if (!current) return;
      setLoading(true);
      await editMediaGroups(
        current.groupNum,
        {
          ...current,
          ...params
        }
      );
      message.success('Update successfully!');
      setEditModalVisible(false);
      getGroups();
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const deleteGroup = async (rowData: MediaGroup) => {
    try {
      setLoading(true);
      await deleteMediaGroups(rowData.groupNum);
      message.success('Delete group successfully!');
      getGroups();
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const getGroups = async () => {
    try {
      setLoading(true);
      const { data = [] } = await fetchMediaGroups();
      setGroups(data);
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getGroups();
  }, [])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <ContentLayout isModal={false}>
      <Heading
        title="Essential Info > Media > Groups"
        actions={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              form.resetFields();
              setModalType('Add');
              setEditModalVisible(true);
            }}
          >
            Add
          </Button>
        }
      />
      <Spacer />
      <SiteContent flexGrow>

        <DataGrid
          loading={loading}
          columns={columns}
          dataSource={groups}
        />

        {editModalVisible && (
          <Modal
            centered
            destroyOnClose
            footer={
              <Row justify="end">
                <Space>
                  <Button onClick={() => setEditModalVisible(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      modalType === 'Edit' ? editGroup() : addGroup()
                    }}
                    type="primary"
                  >
                    Save
                  </Button>
                </Space>
              </Row>
            }
            maskClosable={false}
            onCancel={() => setEditModalVisible(false)}
            // onOk={handleUploadFile}
            title={`${modalType} Media Group`}
            visible={editModalVisible}
          >
            <Form {...layout} form={form} >
              <Form.Item
                label="Group Name"
                name="groupName"
                rules={[{ required: true, message: 'Group Name is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="groupDescription">
                <Input.TextArea autoSize={{ minRows: 1 }} />
              </Form.Item>
              <Form.Item
                label="Sequence"
                name="displaySequence"
              // rules={[{ required: true, message: 'Sequence is required' }]}
              >
                <Input
                  type="number"
                  step={1}
                  min={1}
                  onBlur={e => {
                    const { value } = e.target;
                    const num = Number(value);
                    if (!isNaN(num)) {
                      form.setFieldsValue({
                        displaySequence: Math.floor(num)
                      })
                    } else {
                      form.setFieldsValue({
                        displaySequence: ''
                      })
                    }
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </SiteContent>
    </ContentLayout>
  )
}

export default MediaGroups;