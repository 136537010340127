import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Row } from 'antd';
import styled from 'styled-components';
import SiteContent from '../SiteContent';
import Spacer from '../common/Spacer';

const Breadcrumb = styled.span `
  width: 52px;
  height: 17px;
  font-family: Lato;
  font-size: 14px;
  color: ${ (props: { isSelected?: boolean }) => props.isSelected ? '#006DFF' : '#54698d' };
  margin-right: 7px;
`;

const Title = styled.h1`
  height: 34px;
  font-family: Lato;
  font-size: 28px;
  font-weight: 900;
  color: #2d3f5d;
  margin: 0;
`;

const Subtitle = styled.h2`
  height: 19px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: #2d3f5d;
  margin-top: 20px;
`;

const HeaderDetailsContainer = styled.div`
  display: flex;
`;

const Divider2 = styled(Divider)`
  width: 1px;
  height: 25px;
  border: solid .5px #d8dde6;
  margin: 0 25px;
  margin-top: 20px;
`;

const SaveButton = styled(Button)`
  width: 140px;
  height: 40px;
`;

const CancelButton = styled(Button)`
  background-color: #f5f6fa;
  color: #006dff;
  height: 40px;
  width: 140px;
  border: none;
`;

function HeaderPermissions(props: any) {
  const { data, isDisabled, loadingButton, saveUserPermissions, history } = props;
  return (
    <SiteContent>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Link to="/administration-user-management">User Management</Link>
          </Breadcrumb>
          <Breadcrumb>
            &gt;
          </Breadcrumb>
          <Breadcrumb>User Permissions</Breadcrumb>
        </Col>
      </Row>
      <Spacer />
      <Title>{data.name}</Title>
      <Spacer />
      <HeaderDetailsContainer>
        <Subtitle>{data.email}</Subtitle>
        <Divider2 type="vertical" />
        <Subtitle>{data.profileName}</Subtitle>
        <CancelButton
          style={{ marginLeft: 'auto', marginRight: '16px' }}
          onClick={() => history.push('/administration-user-management/')}
          type="primary"
        >
          Cancel
        </CancelButton>
        <SaveButton
          type="primary"
          disabled={!isDisabled}
          loading={loadingButton}
          onClick={saveUserPermissions}
        >
          Save
        </SaveButton>
      </HeaderDetailsContainer>
    </SiteContent>
  );
}

export default HeaderPermissions;
