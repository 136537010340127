import React from 'react';
import {
  Button,
  Row,
  Space,
  Steps,
  notification,
} from 'antd';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { saveProductDestinationAttributes } from '../../../services/copywriting';
import Products from '../../../services/products';
import { CopywritingStepWrapper } from '../styledComponents';
import DestinationSelector from './DestinationSelector';
import FieldSelector from './FieldSelector';

type Props = {
  onCancel?: Function;
  productId: string;
  state?: StringKAnyVPair;
};

const CopywritingSteps = (props: Props) => {
  const { useState } = React;
  const { Step } = Steps;
  const { productId } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [inited, setInited] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productBasicInfo, setProductBasicInfo] = useState<StringKAnyVPair>({});
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<StringKAnyVPair[]>([]);

  const closeDialog = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const isNextButtonEnabled = () => {
    let ret = false;

    if (currentStep === 0) {
      ret = selectedAttributes.length > 0;
    } else if (currentStep === 1) {
      ret = selectedDestinations.length > 0;
    }

    return ret;
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);

    try {
      const { subStyleCode, productBasic } = await Products.getStyleMaster2(productId);

      if (productBasic && typeof productBasic === 'object') {
        setProductBasicInfo({
          ...productBasic,
          subStyleCode,
        });
        setInitialDataLoaded(true);
      } else {
        // eslint-disable-next-line
        throw 'No product info found';
      }
    } catch(e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch product info error: ${e}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const onCancel = () => {
    // maybe need to prompt
    closeDialog();
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const pushCopywriting = async () => {
    const list: StringKAnyVPair[] = [];
    //console.log('push', selectedDestinations);
    selectedDestinations.forEach(g => {
      g.dests.forEach((e: StringKAnyVPair) => {
        if (e.selected) list.push(e);
      });
    });
    //console.log('push ->', list);
    if (list.length > 0) {
      const pd = list.map(e => ({
        attributeNum: e.attributeNum,
        displaySeq: e.displaySeq,
        copywritingValue: e.copywritingValue,
      }));
      //console.log('pd ->', pd);
      setIsLoading(true);
      try {
        await saveProductDestinationAttributes(props.productId, pd);
        setCurrentStep(currentStep + 1);
      } catch(e) {
        notification.error({
          message: `Saved destination error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      notification.error({
        message: 'Please select at least one destination',
        duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
      });
    }
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData]);

  return (<>
    <CopywritingStepWrapper>
      <Row align="middle" className="title-bar">
        <span className="title-item-label">Style</span>
        &nbsp;
        <span className="title-item">{productBasicInfo.styleCode || ''}</span>
        {productBasicInfo.subStyleCode && <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title-item-label">Substyle</span>
          &nbsp;
          <span className="title-item">{productBasicInfo.subStyleCode}</span>
        </>}
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="title-item-label">SKU</span>
        &nbsp;
        <span className="title-item">{productBasicInfo.sku || ''}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="title-item-label">Title</span>
        &nbsp;
        <span className="title-item">{productBasicInfo.productTitle || ''}</span>
        &nbsp;&nbsp;
      </Row>
      <div className="step-ctn">
        <div className="step-wrapper">
          <div className="step-sider">
            <Steps current={currentStep} direction="vertical">
              <Step title="Select Fields" />
              <Step title="Select Destination" />
              <Step title="Review" />
              <Step title="Finish" />
            </Steps>
          </div>
          <div className="step-board">
            {initialDataLoaded && <FieldSelector
              focused={currentStep === 0}
              maskScreen={setIsLoading}
              productId={props.productId}
              setSelectedAttributes={setSelectedAttributes}
              state={props.state}
            />}
            <DestinationSelector
              focused={currentStep === 1}
              maskScreen={setIsLoading}
              mode="destination"
              productId={props.productId}
              selectedAttributes={selectedAttributes}
              setSelectedDestinations={setSelectedDestinations}
            />
            <DestinationSelector
              focused={currentStep === 2}
              maskScreen={setIsLoading}
              mode="review"
              productId={props.productId}
              selectedAttributes={selectedAttributes}
              selectedDestinations={selectedDestinations}
              //setSelectedDestinations={setSelectedDestinations}
            />
            <DestinationSelector
              focused={currentStep === 3}
              maskScreen={setIsLoading}
              mode="finish"
              productId={props.productId}
              selectedAttributes={selectedAttributes}
              selectedDestinations={selectedDestinations}
              //setSelectedDestinations={setSelectedDestinations}
            />
          </div>
        </div>
      </div>
      {/*<Row align="middle" justify={currentStep === 0 ? 'end' : 'space-between'}>*/}
      <Row align="middle" className="left-bottom-btn-block" justify="space-between">
        <Space>
          <Button
            onClick={previousStep}
            style={{visibility: currentStep > 0 && currentStep < 3 ? 'unset' : 'hidden'}}
          >
            Back
          </Button>
          {currentStep < 2 && <Button
            disabled={!isNextButtonEnabled()}
            onClick={nextStep}
            type="primary"
          >
            Next
          </Button>}
          {currentStep === 2 && <Button
            onClick={pushCopywriting}
            type="primary"
          >
            Submit
          </Button>}
          {currentStep === 3 && <Button
            onClick={closeDialog}
            type="primary"
          >
            Close
          </Button>}
        </Space>
        <Space>
          {currentStep < 3 && <Button onClick={onCancel}>
            Cancel
          </Button>}
        </Space>
      </Row>
    </CopywritingStepWrapper>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default CopywritingSteps;
//export const SOURCE_TYPE_COMMON = 0;
export const SOURCE_TYPE_ERP = 1;
export const SOURCE_TYPE_SYS = 2;
