import orders from './orders';
import profiles from './profiles';
import profileSettings from './profileSettings';
import notifications from './notifications';
import routes from './routes';
import shipments from './shipments';
import exports from './exports';
import imports from './imports';
import sidebar from './sidebar';
import orderDetails from './orderDetails';
import salesOrderDetails from './salesOrderDetails';
import integrations from './integrations';
import productsBulkUpdate from './productsBulkUpdate';
import errorMessage from './errorMessage'
import { apiReducers } from '../api';

const rootReducer = {
  orders,
  orderDetails,
  salesOrderDetails,
  productsBulkUpdate,
  profiles,
  profileSettings,
  notifications,
  routes,
  shipments,
  exports,
  imports,
  sidebar,
  integrations,
  errorMessage,
  ...apiReducers,
};

export default rootReducer;
