import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
  message,
} from "antd";
import {
  getGroups,
  AddFields,
  EditFields,
} from "../../../../services/essentialInfo";
import Text from "antd/lib/typography/Text";
type AddEditDialogProps = {
  mode: "New" | "Edit";
  visible: boolean;
  field: any;
  onRefresh: Function;
  onClose: Function;
  SourceEnum: any;
};

const AddEditDialog = (props: AddEditDialogProps) => {
  const { mode = "New", field, visible, onClose, onRefresh, SourceEnum } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [groupList, setGroupList] = useState<any[]>([]);

  const [form] = Form.useForm();

  const saveField = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const res =
        mode === "New"
          ? await AddFields({
              ...params,
              attributeId: field.attributeId ? field.attributeId : 0,
              dataType: field.attributeId ? params.dataType : 0,
              editable: params.editable ? 1 : 0,
              rowNum: 0,
            })
          : await EditFields({
              ...params,
              editable: params.editable ? 1 : 0,
              attributeId: field.attributeId,
              rowNum: field.rowNum,
            });
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const getGroupList = useCallback(async () => {
    try {
      setLoadingGroup(true);
      const res = await getGroups();
      setLoadingGroup(false);
      if (res) {
        setGroupList(res.data);
        // if (mode === "New") {
        //   const temp = res.filter(
        //     (i: any) => i.controlTowerGroupName === "General" && i.isDefault
        //   );
        //   if (temp.length > 0) {
        //     form.setFieldsValue({
        //       groupId: temp[0].rowNum,
        //     });
        //   }
        // }
      }
    } catch (error) {
      setLoadingGroup(false);
    }
  }, []);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={`${mode} Product Feature Field ${
        field.isPlaceHolder ? "(Place holder)" : ""
      }`}
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} requiredMark={false}>
          <Form.Item
            label="Field Name"
            name="fieldName"
            initialValue={field.fieldName}
            rules={[{ required: true, message: "Field name is required" }]}
          >
            <Input
              maxLength={40}
              disabled={field.isPlaceHolder ? false : true}
              placeholder="Please input origin name"
            />
          </Form.Item>

          <Form.Item
            label="Data type"
            name="dataType"
            initialValue={field.dataType}
            // rules={[{ required: true, message: "Data type is required" }]}
          >
            {!field.dataType ? (
              <Typography.Text>Placeholder</Typography.Text>
            ) : (
              <Select style={{ width: "100%" }} disabled={true}>
                <Select.Option value={1}>String</Select.Option>
                <Select.Option value={2}>Integer</Select.Option>
                <Select.Option value={3}>Decimal</Select.Option>
                <Select.Option value={4}>DateTime</Select.Option>
                <Select.Option value={5}>ImageUrl</Select.Option>
                <Select.Option value={6}>Price</Select.Option>
                <Select.Option value={7}>ImageUrlList</Select.Option>
                <Select.Option value={8}>VideoUrl</Select.Option>
                <Select.Option value={9}>Virtual</Select.Option>
                <Select.Option value={10}>Metafield</Select.Option>
                <Select.Option value={11}>PageUrl</Select.Option>
              </Select>
            )}
          </Form.Item>

          {field && field.source >=0 &&<Form.Item
            label="Source"
          >
            <Text>{SourceEnum[field.source]}</Text>
          </Form.Item>}

          <Form.Item
            label="Group"
            name="groupNum"
            initialValue={field.groupNum}
            rules={[{ required: true, message: "Group is required" }]}
          >
            <Select loading={loadingGroup}>
              {groupList.map((item: any) => (
                <Select.Option key={item.groupNum} value={item.groupNum}>
                  {item.groupName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Editable"
            name="editable"
            initialValue={field.editable}
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>

          <Form.Item
            label="Rows"
            name="rows"
            initialValue={typeof field.rows === "undefined" ? 1 : field.rows}
            rules={[{ required: true, message: "Rows is required" }]}
          >
            <Input type="number" min={0} placeholder="Please input Rows" />
          </Form.Item>

          <Form.Item
            label="Column"
            name="columns"
            initialValue={
              typeof field.columns === "undefined" ? 100 : field.columns
            }
            rules={[{ required: true, message: "Columns is required" }]}
          >
            <Input
              addonAfter="%"
              type="number"
              min={0}
              placeholder="Please input Columns"
            />
          </Form.Item>

          <Form.Item
            label="Sequence"
            name="displaySequence"
            initialValue={
              typeof field.displaySequence === "undefined"
                ? 1
                : field.displaySequence
            }
            rules={[{ required: true, message: "Sequence is required" }]}
          >
            <Input type="number" min={0} placeholder="Please input Sequence" />
          </Form.Item>
        </Form>
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="end">
              <Button type="primary" onClick={saveField}>
                Save
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default AddEditDialog;
