import React from 'react';
import { InputNumber, Radio, Switch } from 'antd';

import { MAX_TOWER_ITEM_WIDTH, MIN_TOWER_ITEM_WIDTH, getTowerItemWidth } from './FeatLayout';
import { Action, StoreContext, findNodeByKey } from './model';
import { TowerPropsContainer } from './styles';
import { LABEL_POSITION_LEFT } from '.';

type Props = {
};

const FeatProps = (props: Props) => {
  const { useContext, /*useState*/ } = React;
  const { state, dispatch } = useContext(StoreContext) as any;

  const isGroup = () => {
    const item = state.currentItem;

    return Array.isArray(item.children);
    //return Array.isArray(item.children) && item.children.length > 0;
  };

  const isItemSelected = () => {
    return state.currentItem.key;
  };

  const markChangedNodeFootprint = (node: StringKAnyVPair) => {
    const changedNodes = [...state.changedNodes];
    const key = node.key;

    if (node.parentKey && changedNodes.indexOf(node.parentKey) < 0) {
      changedNodes.push(node.parentKey);
    }

    if (changedNodes.indexOf(key) < 0) {
      changedNodes.push(key);
    }

    dispatch({type: Action.SetChangedNodes, payload: changedNodes});
  };

  const onGroupColumnChange = (val: number) => {
    const item = state.currentItem;
    //console.log('v', val);
    item.columns = val;
    state.towers.forEach((g: StringKAnyVPair) => {
      if (g.key === item.key) {
        g.columns = val;
        markChangedNodeFootprint(g);
      }
    });
    const data = [ ...state.towers ];
    dispatch({type: Action.SetTowers, payload: data});
  };

  const onGroupLabelPositionChange = (evt: any) => {
    const item = state.currentItem;
    const val = evt.target.value;
    //console.log('v', val, typeof val);
    item.labelPosition = val;
    state.towers.forEach((g: StringKAnyVPair) => {
      if (g.key === item.key) {
        g.labelPosition = val;
        markChangedNodeFootprint(g);
      }
    });
    const data = [ ...state.towers ];
    dispatch({type: Action.SetTowers, payload: data});
  };

  //const onItemColumnNumberChange = (evt: any) => {
  const onItemColumnNumberChange = (val: number) => {
    const item = state.currentItem;
    const node = findNodeByKey(state.towers, item.key);

    if (node) {
      const data = [ ...state.towers ];
      //const val = evt.target.value;
      //console.log('n', node);
      item.columns = val;
      node.columns = val;
      markChangedNodeFootprint(node);
      dispatch({type: Action.SetTowers, payload: data});
    }
  };

  const onItemEditableChange = (val: boolean) => {
    const item = state.currentItem;
    const node = findNodeByKey(state.towers, item.key);

    if (node) {
      const data = [ ...state.towers ];
      const ev = val ? 1 : 0;

      //console.log('n', node);
      item.editable = ev;
      node.editable = ev;
      markChangedNodeFootprint(node);
      dispatch({type: Action.SetTowers, payload: data});
    }
  };

  const onItemRowNumberChange = (val: number) => {
    const item = state.currentItem;
    const node = findNodeByKey(state.towers, item.key);

    if (node) {
      const data = [ ...state.towers ];
      console.log('n', node);
      item.rows = val;
      node.rows = val;
      markChangedNodeFootprint(node);
      dispatch({type: Action.SetTowers, payload: data});
    }
  };

  const renderGroupColumnNumberEditor = () => {
    const item = state.currentItem;

    return (<>
      <div className="prop-wrapper">
        <label className="title">Columns</label>
        <InputNumber
          max={6}
          min={1}
          onChange={onGroupColumnChange}
          value={item.columns}
        />
      </div>
    </>);
  };

  const renderGroupLabelPositionEditor = () => {
    const item = state.currentItem;

    return (<>
      <div className="prop-wrapper">
        <label className="title">Label Position</label>
        <Radio.Group
          onChange={onGroupLabelPositionChange}
          value={item.labelPosition || LABEL_POSITION_LEFT}
        >
          <Radio value={1}>Top</Radio>
          <Radio value={2}>Left</Radio>
        </Radio.Group>
      </div>
    </>);
  };

  const renderItemColumnNumberEditor = () => {
    const item = state.currentItem;

    return (<>
      <div className="prop-wrapper">
        <label className="title">Columns</label>
        <InputNumber
          //addonAfter="%"
          max={MAX_TOWER_ITEM_WIDTH}
          min={MIN_TOWER_ITEM_WIDTH}
          onChange={onItemColumnNumberChange}
          type="number"
          value={getTowerItemWidth(item)}
        />
      </div>
    </>);
  };

  const renderItemEditableEditor = () => {
    const item = state.currentItem;

    return (<>
      <div className="prop-wrapper">
        <label className="title">Editable</label>
        &nbsp;&nbsp;
        <Switch
          checked={item.editable}
          checkedChildren="Yes"
          onChange={onItemEditableChange}
          unCheckedChildren="No"
        />
      </div>
    </>);
  };

  const renderItemRowNumberEditor = () => {
    const item = state.currentItem;

    return (<>
      <div className="prop-wrapper">
        <label className="title">Rows</label>
        <InputNumber
          max={10}
          min={1}
          onChange={onItemRowNumberChange}
          value={item.rows}
        />
      </div>
    </>);
  };

  const renderNodeName = () => {
    const item = state.currentItem;

    return (
      <div className="node-name-banner">
        <label>{item.displayName}</label>
      </div>
    );
  };

  return (<>
    {isItemSelected() && (
      <TowerPropsContainer>
        {renderNodeName()}
        {isGroup() && (renderGroupColumnNumberEditor())}
        {isGroup() && (renderGroupLabelPositionEditor())}
        {!isGroup() && (<>
          {renderItemRowNumberEditor()}
          {renderItemColumnNumberEditor()}
          {'editable' in state.currentItem && renderItemEditableEditor()}
        </>)}
      </TowerPropsContainer>
    )}
  </>);
};

export default FeatProps;
