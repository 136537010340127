import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Button,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  notification,
} from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import ScreenMask from '../../components/common/ScreenMask';
import Loading from '../../components/common/Loading';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  HTTP_STATUS_OK,
} from '../../constants/config';
import {
  uploadFile, 
  downloadAttributeTemplate,
} from '../../services/imports';
import { ModalTitle } from '../../components/common/styledComponents';
import { loadAttributesChannels } from '../DetailProduct/helper';
import {
  downloadFile,
} from '../../util/files';
const FileUploadModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileUploadTitle = styled.div`
  flex: 1;
  flex-grow: 1;
`;

const FileUploadButtons = styled.div`
  flex: none;
  margin-right: 30px;
`;

const DownloadTemplateButton = styled(Button)`
`;

const TemplateDownloadButton: React.FC<any> = (props: any) => {
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  
  
  const onClick = useCallback(async () => {

    let template;
    try {
      setLoadingTemplate(true);
      const { data: fileBlob, status } = await downloadAttributeTemplate();

      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = fileBlob;
      }
      
    } catch (e) {
      setLoadingTemplate(false);
    } finally {
      setLoadingTemplate(false);
    }

    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }
    
    downloadFile(
      'attributes-template.xlsx',
      template,
    );

  }, [
  ]);
  
  return (
    <DownloadTemplateButton onClick={onClick} loading={loadingTemplate} type="primary" ghost>
      Download Template
      <CloudDownloadOutlined />
    </DownloadTemplateButton>
  );
};

type Props = {
  onClose: Function;
  onFileImported: Function;
  visible: boolean;
};

const ImportDialog = (props: Props) => {
  const { useState } = React;
  const [files, setFiles] = useState<UploadFile[]>([]);
  //const [files, setFiles] = useState<Blob[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [channelList, setChannelList] = useState<any[]>([]);
  const [channelNum, setChannelNum] = useState(0);

  const loadSysAttributesChannels = async () => {
    const ac = await loadAttributesChannels();
    
    if (Array.isArray(ac)) {
      setChannelList([
        {
          channelNum: 0,
          channelName: 'None',
        },
        ...ac,
      ]);
    }
  };

  const channelOptions = useMemo(() => {
    return channelList.map(e => (
      {
        value: e.channelNum,
        label: e.channelName,
      }
    ));
  }, [channelList]);

  const onSelectFile = (evt: any) => {
    //console.log('e', evt);
    setFiles(evt.fileList.slice(-1));
  };

  const uploadAttributeFile = async () => {
    setIsLoading(true);

    try {
      const form = new FormData();

      form.append('FileOperation', '1004');
      //form.append('files', files[0]?.originFileObj || '');
      files.forEach(f => {
        form.append('files', f.originFileObj || '');
      });
      // get channelName from options
      const channelName = channelOptions.find((item) => item.value === (channelNum || 0))?.label;
      const { msg } = await uploadFile(form, channelNum || 0, undefined, undefined, undefined, channelName) as StringKAnyVPair;

      //console.log('res', res);
      props.onFileImported();
      props.onClose();

      if (msg) {
        notification.success({
          message: msg,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
      }
    } catch(e) {
      notification.error({
        message: `Upload file error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    loadSysAttributesChannels();
  }, []);

  return (<>
    <Modal
      centered
      onCancel={() => props.onClose()}
      footer={<Row justify="end">
        <Space>
          <Button onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            disabled={files.length === 0}
            onClick={uploadAttributeFile}
            type="primary"
          >
            Ok
          </Button>
        </Space>
      </Row>}
      title={(
        <FileUploadModalTitle>
          <FileUploadTitle>
            <ModalTitle>Import Attributes</ModalTitle>
          </FileUploadTitle>
          <FileUploadButtons>
            <Row>
              <span
                style={{
                  padding: '5px',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                Channel
              </span>
              <Select
                {...{
                  name: 'channelSelectList',
                  filterOption: (input: string, option: any) => {
                    return ((option.label || option.value) as string).toLowerCase().startsWith(input.toLowerCase());
                  },
                }}
                allowClear
                style={{
                  width: '160px',
                }}
                placeholder="Channel"
                onChange={setChannelNum}
                showSearch
                value={channelNum || 0}
                defaultValue={0}
                options={channelOptions}
              />
              <TemplateDownloadButton />
            </Row>
          </FileUploadButtons>
        </FileUploadModalTitle>
      )}
      visible={props.visible}
      width={800}
    >
      <Upload.Dragger
        accept=".xls,.xlsx"
        beforeUpload={() => false}
        fileList={files}
        onChange={onSelectFile}
      >
        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
    </Modal>
    {isLoading && <ScreenMask>
      <Loading size={LOADING_ICON_SIZE1} />
    </ScreenMask>}
  </>);
};

export default ImportDialog;
