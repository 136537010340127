import React from 'react';

const setCurrentKey = (currentKey: Array<React.Key>) => ({
  type: 'SET_CURRENT_KEY',
  currentKey,
});

const setCurrentOpenedKey = (currentOpenedKey: Array<React.Key>) => ({
  type: 'SET_CURRENT_OPENED_KEY',
  currentOpenedKey,
});

export default {
  setCurrentKey,
  setCurrentOpenedKey,
};
