import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const SettingWrapper = styled.div`
  /*background-color: #FFF;*/
  border-radius: ${theme['@border-radius-base']};
  height: 100%;
  width: 100%;

  & label {
    font-weight: 550;
  }

  & label.title::after {
    content: ': ';
  }

  & .body-section {
    height: calc(100% - 52px);
  }

  & .content-panel {
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    width: calc(100% - 500px);
  }

  & .content-panel.one-col {
    padding-left: 0;
    padding-right: 0px;
    width: 100%;
  }

  & .footer-section {
    height: 52px;
  }

  & .props-panel,
  & .structure-panel {
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    height: 100%;
    overflow-y: auto;
    width: 250px;
  }
`;

export const TowerListContainer = styled.div`
  & .grp-title {
    font-size: 14px;
    font-weight: 600;
  }

  & .grp-titlebar {
    background-color: #EDF1F4;
    border-bottom: 1px solid #80CBC4;
    padding: 4px 6px;
    width: 100%;
  }

  & .grp-titlebar.selected {
    background-color: #A3D7FF;
  }

  & .tower-item {
    padding: 6px;
  }

  & .tower-item .ant-typography {
    margin-bottom: unset;
  }

  & .tower-item .form-item-disabled {
    background-color: #D9D9d9;
    cursor: not-allowed;
  }

  & .tower-item.selected {
    background-color: #E6F4FF;
    border-radius: 4px;
  }

  & .tower-grp {
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    margin: 8px 0;
  }

  & .tower-grp:first-child {
    margin-top: 0;
  }

  & .tower-grp:last-child {
    margin-bottom: 0;
  }
`;


export const TowerPropsContainer = styled.div`
  & .node-name-banner {
    background-color: #DCDCDC;
    padding: 6px 8px;
  }

  & .prop-wrapper {
    padding: 6px;
  }

  & .prop-wrapper .ant-input-number {
    width: 100%;
  }
`;
