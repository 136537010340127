import { NotificationActions, NotificationsActionTypes } from '../types/notifications';

const setNotification = (notificationType: string, message: string): NotificationsActionTypes => ({
  type: NotificationActions.SET_NOTIFICATION,
  notificationType,
  message,
});

const clearNotification = (): NotificationsActionTypes => ({type: NotificationActions.CLEAR});

const actions = {
  setNotification,
  clearNotification,
};

export default actions;