import { dropshipAPI } from './http';

export interface SellerConfigCreatePayload {
  sellerConfig: {
    listPreference: number;
    enabledAsSeller: number;
  }
  taxIds: {
    id: string;
    state: string;
  }[];
  company: {
    websiteUrl: string;
  }
}

export const getSellerConfig = async (): Promise<Entities.SellerConfigItem> => {
  const { data } = await dropshipAPI.get('/sellerConfig');
  return data;
};

export const createSellerConfig = (data: SellerConfigCreatePayload): Promise<void> => dropshipAPI.post('/sellerConfig', data);

export const updateSellerConfig = (data: SellerConfigCreatePayload): Promise<void> => dropshipAPI.patch('/sellerConfig', data);
