import React from 'react';
import {
  Alert,
  Card,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
  Col,
} from 'antd';

type Props = {
  focused: boolean;
  mode: string;
  fromAttr:StringKAnyVPair[];
  toAttr:StringKAnyVPair[];
  overwrite?: boolean;
  setOverwrite?: Function;
  useChildLevel?: boolean;
  setUseChildLevel?: Function;
};


const DestinationSelector = (props: Props) => {
  const {fromAttr, toAttr} = props
  const destinationWrapperRef = React.useRef<any>(null);
  const overwriteOptions = [
    {label: 'Overwrite', value: true,},
    {label: "Don't Overwrite", value: false,},
  ];
  const style = {
    display: props.focused ? '' : 'none',
  };

  const onOverwriteChange = (evt: any) => {
    if (typeof props.setOverwrite === 'function') {
      props.setOverwrite(evt.target.value);
    }
  };

  const onChildLevelChange = (chk: boolean) => {
    if (typeof props.setUseChildLevel === 'function') {
      props.setUseChildLevel(!!chk);
    }
  };

  return (<>
    <div className="dest-step-wrapper" ref={destinationWrapperRef} style={style}>
      {props.mode === 'review' && <Alert
        message="The value of the copy to fields will be replaced with the value of copy from fields"
        type="error"
      />}
      {props.mode === 'finish' && <Alert
        message="The value of following fields are scheduled to be updated."
        type="success"
      />}
      <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
        <span
          className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
        >
          If destination has value
        </span>
        <Radio.Group
          disabled={props.mode !== 'destination'}
          onChange={onOverwriteChange}
          options={overwriteOptions}
          value={!!props.overwrite}
        />
      </Space>
      <Row>
        <Space className={props.mode !== 'destination' ? 'space-line' : ''}>
          <span
            className={`title-item-label ${props.mode === 'destination' ? 'danger-color' : ''}`}
          >
            Copy value at child level
          </span>
          <Switch
            disabled={props.mode !== 'destination'}
            checkedChildren="Yes"
            onChange={onChildLevelChange}
            unCheckedChildren="No"
            checked={props.useChildLevel}
          />
          {/* <Checkbox disabled={props.mode !== 'destination'} checked={props.useChildLevel} onChange={onChildLevelChange}/> */}
        </Space>
      </Row>
      <Card style={{marginTop: 8}}>
        <Card type="inner" title="Copy from">
          <Row>
            <Col span={8}>
              <Typography.Text>{fromAttr.length > 0 ? fromAttr[0].ElementName : ''}</Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card type="inner" title="Copy to" style={{marginTop: 8}}>
          <Row>
            {toAttr.map(i=><Col span={8} key={i.CopywritingElementNum}>
              <Typography.Text>{i.ElementName}</Typography.Text>
            </Col>)}
          </Row>
        </Card>
      </Card>



      </div>
  </>);
};

export default DestinationSelector;
