import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../assets/styles/settings.less';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { fetchChannels, listProfileChannelAccounts } from '../services/channels';
import { useExecutePromise } from '../util/hooks';

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const SectionTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

const SectionHeading = styled(Row)`
  margin-bottom: 0.4rem;
`;

const SectionWrapper = styled.div``;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.6rem;

  ${SectionHeading} {
    margin-top: 1rem;
  }
`;

const CustomLink = styled(Link)`
  h3 {
    color: #006dff !important;
  }
`;

const Section: React.FC<{ title: string, action?: JSX.Element }> = ({ title, action, children }) => (
  <SectionWrapper>
    <SectionHeading align="top" gutter={12}>
      <Col>
        <SectionTitle>
          {title}
        </SectionTitle>
      </Col>
      {
        action && (
          <Col>
            {action}
          </Col>
        )
      }
    </SectionHeading>
    <SectionContent>
      {children}
    </SectionContent>
  </SectionWrapper>
);

const AddIntegrationLink: React.FC = () => (
  <Link to="/integrations/add">
    <Button type="primary">
      <PlusOutlined />
      Add
    </Button>
  </Link>
);


const ChannelIntegration: React.FC<{ accounts: ChannelAccount[] }> = (
  {
    accounts,
  },
) => {
  const channelName = useMemo(() => {
    const entry = accounts[0];

    if (entry.platform) {
      return entry.platform.platformName;
    }

    return entry.ChannelName;
  }, [accounts]);

  return (
    <Section title={channelName}>
      <div>
        {
          accounts.map(p => (
            <Link
              key={p.ChannelAccountNum}
              to={`/integrations/${p.ChannelNum}-${p.PlatformNum}/details/${p.ChannelAccountNum}`}
            >
              <div>
                {p.ChannelAccountName}
              </div>
            </Link>
          ))
        }
      </div>
    </Section>
  );
};

type AccountMap = {
  /**
   * Retail accounts grouped by PlatformNum
   */
  retail: { [key: number]: ChannelAccount[] },
  /**
   * Non-retail accounts grouped by ChannelNum
   */
  nonRetail: { [key: number]: ChannelAccount[] },
};

const loadMappedAccounts = async () => {
  const [accounts, platforms] = await Promise.all([listProfileChannelAccounts(), fetchChannels()]);

  const platformsMap: { [key: number]: Entities.ChannelProfile } = {};
  const mappedAccounts: AccountMap = {
    retail: {},
    nonRetail: {},
  };

  // Generate channels map for easier access.
  platforms.forEach(c => {
    platformsMap[c.channelNum] = c;
  });

  accounts.forEach(a => {
    if (a.PlatformNum === 0) {
      if (!mappedAccounts.nonRetail[a.ChannelNum]) {
        mappedAccounts.nonRetail[a.ChannelNum] = [];
      }

      mappedAccounts.nonRetail[a.ChannelNum].push(a);
      return;
    }

    const entry = {
      ...a,
      platform: platformsMap[a.ChannelNum],
    };

    if (!mappedAccounts.retail[a.PlatformNum]) {
      mappedAccounts.retail[a.PlatformNum] = [];
    }

    mappedAccounts.retail[a.PlatformNum].push(entry);
  });

  return mappedAccounts;
};

// TODO: streamline links here and sidebar
const Settings: React.FC = () => {
  const [data, loading] = useExecutePromise(loadMappedAccounts, { retail: [], nonRetail: [] });

  return (
    <ContentLayout>
      <Heading title="Settings Summary" />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <Row gutter={24} justify="space-between">
          <Col span={12} xs={24} lg={8}>
            <Section
              title="Channel Integrations"
              action={<AddIntegrationLink />}
            >
              <Link to="/products-control-flags">
                Channel Control Flags
              </Link>
              <Spin spinning={loading}>
                {Object.keys(data.nonRetail).map(channelNum => (
                  <ChannelIntegration
                    key={channelNum}
                    accounts={data.nonRetail[Number(channelNum)]}
                  />
                ))}
                {Object.keys(data.retail).map(channelNum => (
                  <ChannelIntegration
                    key={channelNum}
                    accounts={data.retail[Number(channelNum)]}
                  />
                ))}
              </Spin>
            </Section>
            <Spacer height={24} />
          </Col>
          <Col span={12} xs={24} lg={8}>
            <Section title="Warehouse/Fulfillment">
              <Link to="/settings-distribution-center">
                Distribution Centers
              </Link>
            </Section>
            <Spacer />
            <Section title="Products">
              <Link to="/settings-products-attributes">
                Attributes
              </Link>
              <Link to="/settings-products-tags">
                Tags
              </Link>
              <Link to="/settings-channels-control-flags">
                Channel Control Flag
              </Link>
            </Section>
            <Spacer />
            <CustomLink to="/settings-output-templates">
              <Section title="Output Templates" />
            </CustomLink>
          </Col>
        </Row>
      </SiteContent>
    </ContentLayout>
  );
};

export default Settings;
