import { FormInstance } from 'antd/es/form';
import React, { createContext } from 'react';
// import { editProductAttributeSet } from '../../services/products';

export interface AttributeSetsState {
  data: Entities.CustomView[];
  filteredData: Entities.CustomView[];
  searchDisabled: boolean;
  editingKey: string;
  selectedProductAttributeSet: Entities.CustomView;
  formValues: Entities.CustomView;
  pagination: any;
  loadingData: boolean;
  addModalVisible: boolean;
  confirmationModalVisible: boolean;
  form: FormInstance;
  isSaving: boolean;
  manageModalVisible: boolean;
  dialogIsFullscreen: boolean;
}

export enum StateActions {
  SET_DATA,
  SET_EDITING_KEY,
  SET_SELECTED_ROW,
  SET_FORM_VALUES,
  SET_PAGINATION,
  SET_LOADING_DATA,
  SET_IS_SAVING,
  SET_ADD_MODAL_VISIBLE,
  SET_CONFIRMATION_MODAL_VISIBLE,
  SET_FILTERED_DATA,
  RESET_FORM,
  REPLACE_INLINE_EDIT,
  SET_ROW_TO_DELETE,
  SET_SEARCH_DISABLED,
  SET_MANAGE_MODAL_VISIBLE,
  SET_DIALOG_IS_FULL_SCREEN,
}

const formValuesInitialState: Entities.CustomView = {
  name: '',
  description: '',
};

export const getReducerInitialState = (form: FormInstance): AttributeSetsState => ({
  data: [],
  filteredData: [],
  editingKey: '',
  searchDisabled: false,
  selectedProductAttributeSet: { ...formValuesInitialState },
  form,
  formValues: { ...formValuesInitialState },
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  },
  loadingData: false,
  addModalVisible: false,
  confirmationModalVisible: false,
  isSaving: false,
  manageModalVisible: false,
  dialogIsFullscreen: false,
});

export type Dispatcher = React.Dispatch<Utils.DispatchAction<StateActions>>;

/**
 * Replaces the value of an inline edit with the result from the server.
 * @param state
 * @param value
 * @param rowIndex
 */
const replaceInlineEdit = (state: AttributeSetsState, {
  value,
  rowIndex,
}: { value: Entities.CustomView, rowIndex: number }): AttributeSetsState => {
  const data = [...state.data];
  data[rowIndex] = value;

  return {
    ...state,
    data,
    editingKey: '',
    searchDisabled: false,
  };
};

export function attributeSetsReducer(state: AttributeSetsState, action: Utils.DispatchAction<StateActions>): AttributeSetsState {
  switch (action.type) {
    case StateActions.SET_DATA:
      return { ...state, data: action.params };
    case StateActions.SET_EDITING_KEY:
      return { ...state, editingKey: action.params };
    case StateActions.SET_SELECTED_ROW:
      return { ...state, selectedProductAttributeSet: action.params };
    case StateActions.SET_FORM_VALUES:
      return { ...state, formValues: action.params };
    case StateActions.SET_PAGINATION:
      return { ...state, pagination: action.params };
    case StateActions.SET_LOADING_DATA:
      return { ...state, loadingData: action.params };
    case StateActions.SET_ADD_MODAL_VISIBLE:
      return { ...state, addModalVisible: action.params };
    case StateActions.SET_CONFIRMATION_MODAL_VISIBLE:
      return { ...state, confirmationModalVisible: action.params };
    case StateActions.RESET_FORM:
      return { ...state, formValues: { ...formValuesInitialState } };
    case StateActions.SET_FILTERED_DATA:
      return { ...state, filteredData: action.params };
    case StateActions.REPLACE_INLINE_EDIT:
      return replaceInlineEdit(state, action.params);
    case StateActions.SET_ROW_TO_DELETE:
      return { ...state, selectedProductAttributeSet: action.params, confirmationModalVisible: true };
    case StateActions.SET_IS_SAVING:
      return { ...state, isSaving: action.params };
    case StateActions.SET_SEARCH_DISABLED:
      return { ...state, searchDisabled: action.params };
    case StateActions.SET_MANAGE_MODAL_VISIBLE:
      return { ...state, manageModalVisible: action.params };
    case StateActions.SET_DIALOG_IS_FULL_SCREEN:
      return { ...state, dialogIsFullscreen: action.params };
    default:
      return state;
  }
}

export const AttributeSetsScreenContext = createContext<[AttributeSetsState, React.Dispatch<Utils.DispatchAction<StateActions>>] | [undefined, undefined]>([undefined, undefined]);


// export const saveAttributeSet = (dispatch: Dispatcher, value: Entities.CustomView, rowIndex: number): Promise<void> => new Promise<void>((resolve, reject) => {
//   dispatch({ type: StateActions.SET_IS_SAVING, params: true });
//   editProductAttributeSet(value, `${value.rowNum}`)
//     .then(() => {
//       dispatch({ type: StateActions.REPLACE_INLINE_EDIT, params: { rowIndex, value } });
//       resolve();
//     })
//     .catch(() => {
//       reject();
//     }).finally(() => dispatch({ type: StateActions.SET_IS_SAVING, params: false }));
// });
