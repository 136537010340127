import { createTransform } from 'redux-persist';
import { ProfilesState } from './types';

interface SerializedProfilesState extends Omit<ProfilesState, 'permissions'> {
  permissions: [number, Entities.ProfileUserPermission][];
}

const MapTransform = createTransform(
  (inbound: ProfilesState) => ({
    ...inbound,
    permissions: Array.from(inbound.permissions),
  }),
  (outbound: SerializedProfilesState) => ({
    ...outbound,
    permissions: new Map(outbound.permissions),
  }),
  { whitelist: ['profiles'] },
);

export default MapTransform;
