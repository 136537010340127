import React from 'react';
import { Input, TreeSelect } from 'antd';
import LoadingIcon from '../../../components/common/LoadingIcon';
import message from '../../../components/common/message';
import { fetchCategoryChildrenById } from '../../../services/channels';
import Products from '../../../services/products';
import { randomString } from '../../../util';
import { EditorWrapper } from '../styledComponents';

type Props = {
  attr?: StringKAnyVPair;
  className?: string;
  //classification?: number | string;
  classification?: number;
  editOnly?: boolean;
  name?: string;
  productId?: string;
  refreshFormState?: Function;
  state?: StringKAnyVPair;
  styleCode?: string;
  value: any;
};

const CategoryPathEditor = (props: Props) => {
  const { useState } = React;
  // eslint-disable-next-line
  const [currentRoot, setCurrentRoot] = useState<number>();
  //const [currentValue, setCurrentValue] = useState<any>(props.value);
  const [currentValue, setCurrentValue] = useState<any>();
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<number[]>();
  const [treeValue, setTreeValue] = useState<any>();
  //const [lastValue, setLastValue] = useState<any>(props.value);
  const [lastValue, setLastValue] = useState<any>();
  const [inited, setInited] = useState(false);
  // eslint-disable-next-line
  const [isDataReady, setIsDataReady] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [treeData, setTreeData] = useState<StringKAnyVPair[]>([]);
  const [treeKey, setTreeKey] = useState<string>();
  const [treeId, setTreeId] = useState('');

  const attrOpeIcon = () => {
    return (<>
      {isLoading && (
        <LoadingIcon />
      )}
      {!isLoading && (
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={enterEditMode}
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? '💾' : '🖊️'}
        </span>
      )}
    </>);
  };

  const enterEditMode = () => {
    if (isEditable) {
      //if (currentValue === lastValue) {
      if (isValueChanged()) {
        saveValues();
      } else {
        setIsEditable(false);
      }
    } else {
      setIsEditable(true);

      /*if (!isDataReady) {
        setIsLoading(true);
      }*/
    }
  };

  const findNodePath = (nid: number, path: number[], ns = treeData) => {
    const nodes = ns.filter(e => e.id === nid);

    if (nodes.length === 1) {
      const pid = nodes[0].pId;

      path.splice(0, 0, nid);

      if (nodes[0].pId > 0) findNodePath(pid, path, ns);
      console.log(nodes[0], path);
    }
  };

  const genTreeNode = (data: StringKAnyVPair) => {
    return {
      childLoaded: false,
      id: data.rowNum,
      pId: data.parentRowNum,
      value: data.rowNum,
      title: data.categoryName,
      //isLeaf: data.isRoot ? false : true,
      isLeaf: data.childCount > 0 ? false : true,
      data,
    };
  }; 

  // eslint-disable-next-line
  const getInitialPids = () => {
    //const ret = [0];
    const ret = [];

    if (props.classification) {
      ret.push(props.classification);
    }

    //if (Array.isArray(props.value)) {
    if (props.value) {
      try {
        //const pids = props.value;
        const pids = JSON.parse(props.value);

        console.log('pids ->', props.classification, pids);
        if (Array.isArray(pids)) {
          if (pids.length > 1) {
            const ids = pids.slice(0, pids.length - 1);

            //console.log('ids', ids);
            setDefaultExpandedKeys(ids);
            ret.push(...ids);
          }
        }
      } catch(e) {}
    }

    //console.log('ret', ret);
    return ret;
  };

  const isValueChanged = () => {
    return JSON.stringify(currentValue) !== JSON.stringify(lastValue);
  };

  // eslint-disable-next-line
  const loadCategoryNodes = async (pids: number[], tid = treeId) => {
    const children = await loadChildNodes(pids, tid);

    setCurrentValue([]);
    setDefaultExpandedKeys([]);
    //setLastValue([]);
    setTreeKey(randomString());
    setTreeValue(null);

    if (Array.isArray(children)) setTreeData(children);

    if (isValueChanged()) {
      saveValues();
    }
  };

  // eslint-disable-next-line
  const loadChildNodes = async (pids: number[], tid = treeId) => {
    setIsLoading(true);

    try {
      const nodes = await fetchCategoryChildrenById(pids.join(':'));

      if (Array.isArray(nodes)) {
        const children = nodes.map(genTreeNode);

        children.sort(sortNodeTitle);
        children.forEach(e => {
          if (pids.indexOf(e.id) > -1) {
            e.childLoaded = true;
          }
        });
        //console.log(children);
        if (pids[0] === 0) {
          children.sort(sortNodeParentId);
          //console.log(children);
          setTreeData(children);

          if (Array.isArray(props.value)) {
            const ns = props.value;

            if (ns.length > 0) {
              const nv = ns[ns.length - 1];

              setTreeValue(nv);
              setTimeout(() => {
                setPathByNode(nv, children, tid);
              }, 0);
            }
          }
        }

        return children;
      }
    } catch(e) {
      message.error(`Fetch category nodes error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const loadInitialNodes = async (pids: number[], tid = treeId) => {
    console.log('load -->', pids);
    if (props.classification && pids.length > 0) {
      let children = await loadChildNodes(pids, tid);

      if (Array.isArray(children)) {
        let pidFound = false;

        children.sort(sortNodeParentId);

        try {
          const ns = JSON.parse(props.value);
          const pidList: number[] = [];

          //console.log('ns->', ns, children);
          if (Array.isArray(ns)) {
            if (ns.length > 0) {
              const nv = ns[ns.length - 1];

              children.forEach(e => {
                if (ns.indexOf(e.id) > -1 && pidList.indexOf(e.pId) < 0) {
                  pidList.push(e.pId);
                }
              });
              pidFound = pidList.indexOf(props.classification || -1) > -1;
              console.log('pids->', pidFound, pidList);
              if (pidFound) {
                setCurrentValue([...ns]);
                setLastValue([...ns]);
                setTreeValue(nv);
                setTimeout(() => {
                  setPathByNode(nv, children, tid);
                }, 0);
              } else {
                console.log('child len:', children.length);
                //children = children.filter(e => ns.indexOf(e.pId) < 0);
                children = children.filter(e => e.pId === props.classification);
              }
            }
          }
        } catch(e) {
          //console.log(`Set initial category path error: ${e}`);
        }

        console.log('pid found', pidFound, children.length);
        setTreeData(children);
      }
    }
  };

  const nodeIdListToPath = (nidList: number[], ns = treeData) => {
    const path = nidList.map(e => {
      const nodes = ns.filter(t => t.id === e);

      //console.log('ns->', e, nodes);
      return nodes[0] ? nodes[0].title : '';
    });

    //if (path[0] !== '') path.splice(0, 0, '');

    return path.join(' / ');
  };

  const onLoadTreeNodes = async (node: StringKAnyVPair) => {
    //console.log('n->', node);
    if (!node.childLoaded) {
      const children = await loadChildNodes([node.id]);

      node.childLoaded = true;

      if (Array.isArray(children)) {
        //console.log('child ->', children);
        setTreeData(treeData.concat(children));
        //console.log('tree data', treeData);
      }
    }
  };

  const onSelectChange = (value: number) => {
    setPathByNode(value);
    setTreeValue(value);
  };

  const onSelectBlur = () => {
    if (isValueChanged()) {
      if (props.editOnly) {
        if (props.attr) {
          props.attr.editValue = JSON.stringify(currentValue);
          props.attr.valueChanged = true;

          if (typeof props.refreshFormState === 'function') {
            props.refreshFormState();
          }
        }
      } else {
        saveValues();
      }
    } else {
      setIsEditable(false);
    }
    // compare lastPath and currentPath to save data
  };

  const saveValues = async () => {
    console.log('blur->', lastValue, currentValue, props.attr);
    const attrLabel = props.attr ? props.attr.basicAttributeName : '';

    setIsLoading(true);

    try {
      //const basic: StringKAnyVPair = {};
      const data: StringKAnyVPair = {
        //ApplyToAllRelevantSubStyleAndSKU: true,
        ApplyToAllRelevantSubStyleAndSKU: props.state?.overwriteChildren || false,
        styleCode: props.styleCode,
        //productBasic: basic,
      };

      //if (props.name) basic[props.name] = currentValue;

      if (props.attr && props.attr.basicAttributeNum > 999) {
        const { basicAttributeId } = props.attr;

        //console.log('ccc->', currentValue, lastValue);
        data.productBasicInfoAttributeList = [{
          AttributeId: basicAttributeId,
          //Value: currentValue,
          Value: JSON.stringify(currentValue),
          //editType: isLastValueNull() ? 0 : 1,
          editType: 1,
        }];
        data.productBasic = undefined;
      }

      //setSpecialSaveData(data);
      //console.log('pdata ->', data, props.attr);
      if (props.productId) {
        console.log('pdata ->', data, props.attr);
        const res = await Products.editSimpleStyleMaster(props.productId, data);
        console.log('res ->', res);
        setLastValue(currentValue);
        setIsEditable(false);
        message.success(`Saved '${attrLabel}' successfully`);
      } else {
        message.info(`Not enough conditions to save '${attrLabel}'`);
      }
    } catch(e) {
      message.error(`Saved '${attrLabel}' failed`);
    } finally {
      setIsLoading(false);
    }
  };

  const selectorClassName = () => {
    //const ret = [ 'common-sel-box' ];
    let ret = [
      props.editOnly ? 'common-sel-editor' : 'common-sel-box',
    ];

    //if (!isEditable && !isLoading) {
    if (!isEditable && !props.editOnly) {
      ret.push('visual-ctn');
    }

    if (props.editOnly && isLoading) {
      ret = [ 'common-sel-box' ];
    }

    return ret.join(' ');
  };

  // eslint-disable-next-line
  const setInitialData = () => {
    const tid = randomString();

    setTreeId(tid);
    setTreeKey(randomString());

    return tid;
  };

  const setPathByNode = (
    nodeNum: number,
    ns = treeData,
    tid = treeId,
  ) => {
    const path: number[] = [];

    findNodePath(nodeNum, path, ns);
    setCurrentValue(path);
    //console.log('path ->', path);
    // must use setTimeout here
    setTimeout(() => {
      showPath(path, ns, tid);
    }, 0);
  };

  const showPath = (
    nidList: number[],
    td = treeData,
    tid = treeId,
  ) => {
    const tree = document.getElementById(tid);

    //console.log('tree->', tree, ':', tid);
    if (tree) {
      const pathBoard = tree.querySelector('.ant-select-selection-item');

      //console.log('pboard->', pathBoard);
      if (pathBoard) {
        const path = nodeIdListToPath(nidList, td);

        //console.log('path->', path);
        pathBoard.innerHTML = path;
        pathBoard.setAttribute('title', path);
      }
    }
  };

  const sortNodeParentId = (
    a: StringKAnyVPair,
    b: StringKAnyVPair,
  ) => {
    if (a.pId > b.pId) return 1;
    else if (a.pId < b.pId) return -1;

    return 0;
  };

  const sortNodeTitle = (
    a: StringKAnyVPair,
    b: StringKAnyVPair,
  ) => {
    const ta = a.title.toUpperCase();
    const tb = b.title.toUpperCase();

    if (ta > tb) return 1;
    else if (ta < tb) return -1;

    return 0;
  };

  React.useEffect(() => {
    if (!inited) {
      const tid = setInitialData();
      //loadChildNodes([0, 3052]);
      loadInitialNodes(getInitialPids(), tid);
      //console.log('value ->', props.classification, props.value);
      setInited(true);
    }

    if (currentRoot !== props.classification) {
      if (inited && props.classification) {
        loadCategoryNodes([props.classification]);
      }

      setCurrentRoot(props.classification);
    }
  }, [
    currentRoot,
    getInitialPids,
    loadCategoryNodes,
    loadInitialNodes,
    inited,
    props,
    setInitialData,
  ]);

  return (<>
    <EditorWrapper id={treeId} className={props.className || ''}>
      <Input.Group compact>
        <TreeSelect
          className={selectorClassName()}
          disabled={props.editOnly ? false : !isEditable}
          key={treeKey}
          loadData={onLoadTreeNodes}
          onBlur={onSelectBlur}
          onChange={onSelectChange}
          treeData={treeData}
          treeDataSimpleMode
          treeDefaultExpandedKeys={defaultExpandedKeys}
          value={treeValue}
        />
        {!props.editOnly && attrOpeIcon()}
        {props.editOnly && isLoading && attrOpeIcon()}
      </Input.Group>
    </EditorWrapper>
  </>);
};

export default CategoryPathEditor;
