export enum OrderDetailsActions {
  SET_ORDER = 'order/setOrderDetails',
  SET_LOADING = 'order/setLoading',
  SET_EDITMODE = 'order/setEditMode',
  SET_ISFORMUPDATED = 'order/setIsFormUpdated',
  SET_SELECTEDPENDINGITEMS = 'order/setSelectedPendingItems',
  SET_SHOWPENDINGERRORMESSAGE = 'order/setShowPendingErrorMessage',
  SET_PENDINGERRORMESSAGE = 'order/setPendingErrorMessage',
  SET_SHIPPINGTOTAL = 'order/setShippingTotal',
  SET_CARRIERS = 'order/setCarriers',
  CLEAR_PAGE = 'order/clearPage',
}

interface SetOrderDetailsAction {
  type: typeof OrderDetailsActions.SET_ORDER,
  order: Entities.OrderDetails
}

interface SetLoading {
  type: typeof OrderDetailsActions.SET_LOADING
  value: boolean,
}

interface SetEditMode {
  type: typeof OrderDetailsActions.SET_EDITMODE
  value: boolean,
}

interface SetIsFormUpdated {
  type: typeof OrderDetailsActions.SET_ISFORMUPDATED
  value: boolean,
}

interface SetSelectedPendingItems {
  type: typeof OrderDetailsActions.SET_SELECTEDPENDINGITEMS
  value: Array<string>,
}

interface SetShowPendingErrorMessage {
  type: typeof OrderDetailsActions.SET_SHOWPENDINGERRORMESSAGE
  value: boolean,
}

interface SetPendingErrorMessage {
  type: typeof OrderDetailsActions.SET_PENDINGERRORMESSAGE
  value: string,
}

interface SetShippingTotal {
  type: typeof OrderDetailsActions.SET_SHIPPINGTOTAL
  value: string,
}

interface SetCarriers {
  type: typeof OrderDetailsActions.SET_CARRIERS
  value: Entities.OrderCarriers,
}

interface ClearPage {
  type: typeof OrderDetailsActions.CLEAR_PAGE;
}

export type OrderDetailsActionTypes =
  SetCarriers
  | SetShippingTotal
  | SetOrderDetailsAction
  | SetLoading
  | SetEditMode
  | SetIsFormUpdated
  | SetSelectedPendingItems
  | SetShowPendingErrorMessage
  | SetPendingErrorMessage
  | ClearPage;
