import React from 'react';
import {
  Card,
  Collapse,
  Image,
  Row,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import LoadingIcon from '../../components/common/LoadingIcon';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  HTTP_STATUS_CREATED,
  HTTP_STATUS_OK,
} from '../../constants/config';
import Products from '../../services/products';
import EditImageDialog from './EditImageDialog';
import { SectionTitle } from './styledComponents';

const PanelCtn = styled.div`
  position: relative;
  height: 100%;

  & .ant-card-body {
    padding: 2px;
  }

  & .ant-card-body .img-ctn {
    height: 150px;
  }

  & .ant-card-head {
    min-height: 32px;
    padding: 0 8px;
  }

  & .ant-card-head-title {
    padding: 2px 0;
  }

  & .ant-collapse-content-box {
    padding: 0;
  }

  & .ant-image-img {
    max-height: 150px;
    object-fit: cover;
  }

  & .img-card-title-text-ctn {
    max-width: calc(100% - 32px);
  }

  & .image-wrapper,
  & .mask-panel {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  & .image-wrapper {
    overflow-y: auto;
  }

  & .img-card-1 {
    width: 100%;
  }
`;

type Props = {
  focused: boolean;
  productId: string;
};

const ImagePanel = (props: Props) => {
  const { useState } = React;
  const { Text } = Typography;
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [attrImgList, setAttrImgList] = useState<StringKAnyVPair[]>([]);
  const [basicImgList, setBasicImgList] = useState<StringKAnyVPair[]>([]);
  const [currentImg, setCurrentImg] = useState<StringKAnyVPair>({});
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const isLoadingData = React.useMemo(() => {
    return isLoading || isLoadingImages;
  }, [
    isLoading,
    isLoadingImages,
  ]);

  const closeEditImageDialog = () => {
    setEditImageDialogVisible(false);
    setCurrentImg({});
    //setEditImageUrl('');
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    if (props.productId) {
      loadProductImages(props.productId);
    }
  };

  const loadProductImages = async (productId: string) => {
    setIsLoadingImages(true);

    try {
      const list = await Products.getProductImages(productId);

      if (Array.isArray(list)) {
        const attrDict: StringKAnyVPair = {};
        const basic: StringKAnyVPair[] = [];
        const channelList: string[] = [];
        // console.log('l->', list);
        list.forEach(e => {
          if (e.channelName) {
            if (!attrDict[e.channelName]) {
              attrDict[e.channelName] = [];
              channelList.push(e.channelName);
            }

            attrDict[e.channelName].push(e);
          } else {
            e.imageAttributeName = (e.imageAttributeName || '').replace('Image', '');
            basic.push(e)
          }
        });

        setBasicImgList(basic);
        setAttrImgList(channelList.map(e => ({
          channel: e,
          images: attrDict[e] || [],
        })));
      } else {
        notification.error({
          message: 'Product images is not an array',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch(e) {
      notification.error({
        message: `Fetch product images error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoadingImages(false);
    }
  };

  const onCollapseChange = (keys: any) => {
    const activeKeys = keys as string[];
    // console.log('collapse->', keys);
    setActivePanels(activeKeys);

    //if (getImageList().length === activeKeys.length) {
    if (attrImgList.length === activeKeys.length) {
      //setAllImageSectionExpanded(true);
    } else {
      //setAllImageSectionExpanded(false);
    }
  };

  const openEditImageDialog = (image: StringKAnyVPair) => {
    setCurrentImg(image);
    setEditImageDialogVisible(true);
  };

  const renderAttrImageCardList = (imageList: StringKAnyVPair[]) => {
    //const columnNum = getImageColumnNum();
    const columnNum = 1;

    return imageList.map((e, i) => {
      // const url = getImageUrl(e.AttributeId) || ImagePlaceholder;
      const url = e.imageURL || ImagePlaceholder;

      return (
        <Card
          className={`img-card-${columnNum}`}
          key={`${e.imageAttributeNum}-${i}`}
          title={<Row align="middle" justify="space-between">
            <span className="img-card-title-text-ctn">
              <Text ellipsis={{tooltip: e.imageAttributeName}}>
                {e.imageAttributeName}
              </Text>
            </span>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-icon"
                onClick={() => openEditImageDialog(e)}
              />
            </Tooltip>
          </Row>}
        >
          <Row align="middle" className="img-ctn" justify="center">
            <Image
              fallback={ImagePlaceholder}
              src={url}
            />
          </Row>
        </Card>
      );
    });
  };

  const renderBasicImageCardList = (imageList: StringKAnyVPair[]) => {
    //const columnNum = getImageColumnNum();
    const columnNum = 1;

    return imageList.map((e, i) => {
      // const url = getImageUrl(e.basicAttributeId) || ImagePlaceholder;
      const url = e.imageURL || ImagePlaceholder;

      return (
        <Card
          className={`img-card-${columnNum}`}
          key={`${e.imageAttributeNum}-${i}`}
          title={<Row align="middle" justify="space-between">
            <span className="img-card-title-text-ctn">
              <Text ellipsis={{tooltip: e.imageAttributeName}}>
                {e.imageAttributeName}
              </Text>
            </span>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-icon"
                onClick={() => openEditImageDialog(e)}
              />
            </Tooltip>
          </Row>}
        >
          <Row align="middle" className="img-ctn" justify="center">
            <Image
              fallback={ImagePlaceholder}
              src={url}
            />
          </Row>
        </Card>
      );
    });
  };

  const renderPanelList = () => {
    //return getImageList().map(e => {
    return attrImgList.map((e, i) => {
      const { channel, images } = e;

      return (
        <Collapse.Panel
          key={`${channel}`}
          header={<SectionTitle>{channel}</SectionTitle>}
        >
          <Row align="top">
            {renderAttrImageCardList(images)}
          </Row>
        </Collapse.Panel>
      );
    });
  };

  const saveImage = async (imgUrl: string) => {
    //const mainImage = attrDict.MainImage;

    //console.log('cc->', currentImg);
    if (currentImg.imageAttributeNum) {
      setIsLoading(true);
      // only close the image editing dialog
      // but not clear the currentImg state at here
      setEditImageDialogVisible(false);
      const res = await saveImages([{
        imageAttributeNum: currentImg.imageAttributeNum,
        imageURL: imgUrl,
      }]);
      setIsLoading(false);

      if (res) {
        //mainImage.value = imgUrl;
        currentImg.imageURL = imgUrl;
        //setAttrDict({...attrDict});
        setBasicImgList([...basicImgList]);
        notification.success({
          message: `Edit ${currentImg.imageAttributeName} successfully`,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
      }

      return res;
    } else {
      notification.error({
        message: 'Image is lost',
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const saveImages = async (imgs: StringKAnyVPair[]) => {
    try {
      if (props.productId) {
        const res = await Products.editProductImages(props.productId, imgs) as StringKAnyVPair;

        if (res && typeof res === 'object') {
          //console.log('r->', res);
          return [HTTP_STATUS_CREATED, HTTP_STATUS_OK].indexOf(res.status) > -1;
        } else {
          notification.error({
            message: `Save image failed: ${res}`,
            duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
          });
        }
      }
    } catch(e) {
      notification.error({
        message: `Save image error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  React.useEffect(() => {
    if (props.focused && !inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData, props]);

  return (<>
    <PanelCtn>
      <div className="image-wrapper">
        {basicImgList.length > 0 && (
          renderBasicImageCardList(basicImgList)
        )}
        {attrImgList.length > 0 && (<>
          <Collapse
            activeKey={activePanels}
            onChange={onCollapseChange}
            style={{ width: '100%' }}
          >
            {renderPanelList()}
          </Collapse>
        </>)}
      </div>
      {editImageDialogVisible && (
        <EditImageDialog
          imageUrl={currentImg.imageURL || ''}
          onClose={closeEditImageDialog}
          onSave={saveImage}
          title={`Edit ${currentImg.imageAttributeName}`}
          visible={editImageDialogVisible}
        />
      )}
      {isLoadingData && <div className="mask-panel">
        <Row
          align="middle"
          justify="center"
          style={{backgroundColor: 'rgba(0, 0 ,0, .3)', height: '100%'}}
        >
          <LoadingIcon />
        </Row>
      </div>}
    </PanelCtn>
  </>);
};

export default ImagePanel;
