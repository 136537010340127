import styled from 'styled-components';

export const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

export const DraggableItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 503px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #d8dde6;
  background-color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: #546279;
  padding: 12px 10px;
  margin-bottom: 14px;
  cursor: grab;
  z-index: 9999;
`;

export const SubtitleHeader = styled.p`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: #2d3f5d;
`;

export const ErrorMessage = styled.p`
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #bc0000;
  display: none;
`;
