import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

interface ModalFormProps {
  visible: boolean;
  formValues: any;
  submit: () => Promise<void>;
  handleCancel: any;
  handleChange: any;
}

const ModalForm: React.FC<ModalFormProps> = ({
 formValues,
 submit,
 visible,
 handleCancel,
 handleChange,
}: ModalFormProps) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleBeforeSubmit = async () => {
    setProcessing(true);

    try {
      await submit();
      form.resetFields();
    } catch (e) {
      // TODO: focus or something
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={
        <TitleHeader>Add Channel Control Flag</TitleHeader>
      }
      closable={!processing}
      maskClosable={!processing}
      onCancel={handleCancel}
      width={600}
      footer={[
        <Form key="form" form={form} onFinish={handleBeforeSubmit}>
          <Row justify="end">
            <Col span={5} style={{ padding: '0 5px' }}>
              <Form.Item>
                <Button block key="back" onClick={handleCancel} disabled={processing}>
                  <CloseOutlined />
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={5} style={{ padding: '0 5px' }}>
              <Form.Item>
                <Button block key="submit" type="primary" htmlType="submit" disabled={processing}>
                  <SaveOutlined />
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>,
      ]}
    >
      <Spin spinning={processing}>
        <Form
          form={form}
          onFinish={handleBeforeSubmit}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ size: 'middle' }}
        >
          <Form.Item
            label="Flag Name"
            name="ProductLabelName"
            rules={[{ required: true, message: 'Flag name is required.' }]}
          >
            <Input
              onChange={handleChange}
              value={formValues.CHNLCtrlFlag}
              name="CHNLCtrlFlag"
              placeholder="Name"
              maxLength={50}
            />
          </Form.Item>

          <Form.Item
            label="Description"
          >
            <TextArea
              onChange={handleChange}
              value={formValues.CHNLCtrlFlagDesc}
              name="CHNLCtrlFlagDesc"
              placeholder="Description"
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalForm;
