import React from 'react';
import { Row, Col, Button, notification } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DesignService from '../assets/icons/design_service';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../constants/config';
import profileActions from '../redux/actions/profiles';
import { fetchUserPermissions } from '../services/userManagement';
import { fetchSiteConfig, isLoggingOut, logout } from '../util';
import { clearCachedData } from './DetailProduct/helper';

const WelcomeWrapper = styled.div`
  width: 100%;
  background-color: #f5f6fa;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .welcome-logo {
    margin-bottom 36px;
  }
`;

const SelectProfileWrapper = styled.div`
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const WelcomeText = styled.p`
  font-family: Lato;
  font-size: 28px;
  font-weight: normal;
  color: #2d3f5d;
`;

const InnerWrapper = styled.div`
  max-width: 336px;
  margin: 0;
  width: 100%;
`;

const Title = styled.h1`
  font-family: Lato;
  font-size: 28px;
  font-weight: 900;
  color: #2d3f5d;
`;

const Description = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: #7d8ca6;
  margin: 0;
`;

const Profile = styled.div`
  width: 100%;
  height: 54px;
  border-radius: 4px;
  border-color: ${(props: { isSelected?: boolean }) => props.isSelected ? '#006DFF' : '#f5f6fa'};
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
  padding-left: 11px;
  margin: 15px 0;
  cursor: pointer;
`;

const ProfileNameText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #546279;
  margin: 0;
  margin-left: 10px;
`;

const CustomColumn = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContinue = styled(Button)`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
`;

function SelectProfile(props: any) {
  const { useState } = React;
  const { profilesEntity } = props;
  const [inited, setInited] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [siteConfig, setSiteConfig] = useState<StringKAnyVPair>({});

  // eslint-disable-next-line
  const getSiteInfo = async () => {
    //const cfg = await fetchSiteConfig();
    const cfg = fetchSiteConfig();

    setSiteConfig(cfg);
  };

  const handleSelectProfile = (index: number) => {
    props.actions.setSelectedIndex(index);
  };

  const handleContinue = async () => {
    clearCachedData();
    //await setInitialData();
    props.actions.setIsProfileSelected(true);
    window.location.reload();
  };

  const handleLogout = () => {
    logout();
  };

  // eslint-disable-next-line
  const setInitialData = async () => {
    const { profiles, selectedIndex } = profilesEntity;
    let ret = false;

    if (!Array.isArray(profiles) || typeof selectedIndex !== 'number') return;

    setIsLoading(true);

    const profile = profiles[selectedIndex];

    if (!profile || typeof profile !== 'object') return;

    try {
      const permissions = await fetchUserPermissions(profile.Email);

      props.actions.setPermissions(permissions);
      ret = true;
    } catch (e) {
      notification.error({
        message: `Fetch initial data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }

    return ret;
  };

  React.useEffect(() => {
    if (!inited) {
      getSiteInfo();
      setInited(true);
    }
  }, [
    getSiteInfo,
    inited,
  ]);

  return (
    <>
      {
        !isLoggingOut ? (
          <div>
            <Row>
              <Col span={12}>
                <WelcomeWrapper>
                  <img className="welcome-logo" alt="Company Logo" src={siteConfig.logo185} />
                  <WelcomeText>
                    Welcome
                    {siteConfig.name ? ` to ${siteConfig.name}` : ''}
                  </WelcomeText>
                </WelcomeWrapper>
              </Col>
              <Col span={12}>
                <SelectProfileWrapper>
                  {
                    profilesEntity.profiles.length ? (
                      <InnerWrapper>
                        <Title>Select your profile</Title>
                        <Description>There are multiple profiles associated with your login.</Description>
                        <Description>Please choose one and click continue.</Description>
                        {
                          profilesEntity.profiles.map((p: any, index: number) => (
                            <Profile key={`p${index}`} isSelected={profilesEntity.selectedIndex === index} onClick={() => handleSelectProfile(index)}>
                              <Row id={p.ProfileNum} data-type="profile-item" style={{ width: '100%' }}>
                                <CustomColumn span={2}>
                                  <DesignService color="#006dff" />
                                </CustomColumn>
                                <Col span={22}>
                                  <ProfileNameText>
                                    {p.DisplayName}
                                  </ProfileNameText>
                                </Col>
                              </Row>
                            </Profile>
                          ))
                        }
                        <ButtonContinue data-type="select-profile-button" type="primary" size="large" style={{ width: '100%' }} onClick={handleContinue}>Continue</ButtonContinue>
                      </InnerWrapper>
                    ) : (
                        <InnerWrapper>
                          <Title>You don’t have a profile yet. Please contact your company admin to invite you.</Title>
                          <ButtonContinue type="primary" size="large" style={{ width: '100%' }} onClick={handleLogout}>Log out</ButtonContinue>
                        </InnerWrapper>
                      )
                  }
                </SelectProfileWrapper>
              </Col>
            </Row>
          </div>
        ) : (
            <></>
          )
      }
    </>
  );
}

const mapStateToProps = (state: any) => ({
  profilesEntity: state.profiles,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(profileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProfile);
