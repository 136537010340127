import React from 'react';
import { Row, Space, Table, Alert } from 'antd';

type Props = {
  maskScreen: Function;
  selectedSourceName: string;
  selectedAttribute: any[];
};

const AttributeSelector = (props: Props) => {
  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Attribute',
    },
    {
      dataIndex: 'elementName',
      title: 'Content Resources Field',
    },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Alert message="The value of content resources will be replaced with the value from corresponding attributes" type="error"/>
        <div className="section" style={{ marginTop: 10 }}>
          <Row align="middle" className="section-bar">
            <Space>
              <span className="section-title">{props.selectedSourceName}</span>
            </Space>
          </Row>
          <Table
            columns={productBasicColumns}
            dataSource={props.selectedAttribute}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
      </div>
    </>
  );
};

export default AttributeSelector;
