import React from 'react';
import {
  Input,
  InputNumber,
  Select,
  Typography,
  notification,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
//import styled from 'styled-components';
import LoadingIcon from '../../../components/common/LoadingIcon';
import message from '../../../components/common/message';
import { DEFAULT_SUCCESS_MSG_DISPLAY_DURATION } from '../../../constants/config';
import { saveCopywritingSingleElement } from '../../../services/copywriting';
import Products from '../../../services/products';
import { EditorWrapper } from '../styledComponents';
import { loadBasicEnumDef } from '../helper';

export const ATTR_CATEGORY_BASIC = 1;
export const ATTR_CATEGORY_OPERATION = 2;
export const BASIC_ATTR_TYPE_STRING = 1;
export const BASIC_ATTR_TYPE_NUMBER = 2;
export const BASIC_ATTR_TYPE_TEXT = 3;
export const BASIC_ATTR_TYPE_SELECT = 4;
export const BASIC_ATTR_TYPE_PRICE = 5;
export const BASIC_ATTR_TYPE_DEMICAL = 6;

const ATTR_CONDITION_FIELD_NAME = 'Condition';
const ATTR_DIMENSION_UNIT_FILED_NAME = 'DimensionUnit';
const ATTR_WEIGHT_UNIT_FIELD_NAME = 'WeightUnit';
//const ATTR_CONDITION_ID = '301-106';
const TEXTAREA_ROWS = 3;

type Props = {
  allowClear?: boolean;
  attr?: StringKAnyVPair;
  category?: number;
  className?: string;
  editOnly?: boolean;
  label?: string;
  name: string;
  onChange?: Function;
  productId?: string;
  readOnly?: boolean;
  refreshFormState?: Function;
  rows?: number;
  state?: StringKAnyVPair;
  styleCode?: string;
  type: number;
  value: any;
};

const operationStatusOptions = [
  { code: 0, description: 'Active', },
  { code: 1, description: 'Inactive', },
  { code: 2, description: 'Discontinued', },
  { code: 3, description: 'Closeout', },
  { code: 4, description: 'Liquidation', },
  { code: 5, description: 'Prelimnry', },
  { code: 11, description: 'New', },
  { code: 12, description: 'Promotional', },
  //{ code: 13, description: 'UsedGood', },
  //{ code: 14, description: 'UsedFair', },
  { code: 15, description: 'Hold', },
];

const BasicAttrEditor = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<any>(props.value);
  const [lastValue, setLastValue] = useState<any>(props.value);
  const [inited, setInited] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [optionList, setOptionList] = useState<StringKAnyVPair[]>([]);

  const attrCommonOptionsReadonlyView = () => {
    //const fs = conditionOptions.filter(e => e.value === currentValue);
    const fs = optionList.filter(e => e.value === currentValue);

    if (fs.length > 0) {
      return fs[0].label;
    }

    return currentValue;
  };

  const attrEditableField = () => {
    const editorStyle = {
      width: props.editOnly ? '100%' :
        `calc(100% - ${isCopyable() ? 64 : 32}px)`,
    };

    switch (props.type) {
      case BASIC_ATTR_TYPE_NUMBER:
        return (<>
          <InputNumber
            defaultValue={currentValue}
            disabled={!(isEditable || props.editOnly) || props.readOnly}
            onBlur={onInputBlur}
            onChange={onNumberInputChange}
            style={editorStyle}
          />
        </>);

      case BASIC_ATTR_TYPE_SELECT:
        return (
          <Select
            className="common-sel-box"
            disabled={isEditorDisabled()}
            onBlur={onInputBlur}
            onChange={onSelectorChange}
            value={currentValue}
            style={editorStyle}
          >
            {selectorOptions()}
          </Select>
        );

      case BASIC_ATTR_TYPE_TEXT:
        return (<>
          <Input.TextArea
            allowClear={props.allowClear}
            defaultValue={currentValue}
            //disabled={!(isEditable || props.editOnly) || props.readOnly}
            disabled={isEditorDisabled()}
            onBlur={onInputBlur}
            onChange={onInputChange}
            rows={getTextareaRows()}
            style={editorStyle}
          />
        </>);

      case BASIC_ATTR_TYPE_PRICE:
        return (
          <>
            <Input
              defaultValue={currentValue}
              value={currentValue}
              disabled={!(isEditable || props.editOnly)}
              onBlur={onInputBlur}
              onChange={onNumberChange}
              prefix={'$'}
              style={editorStyle}
            />
          </>
        )

      case BASIC_ATTR_TYPE_DEMICAL:
        return (
          <>
            <Input
              defaultValue={currentValue}
              value={currentValue}
              disabled={!(isEditable || props.editOnly)}
              onBlur={onInputBlur}
              onChange={onNumberChange}
              style={editorStyle}
            />
          </>
        )

      default:
        return (<>
          <Input
            allowClear={props.allowClear}
            defaultValue={currentValue}
            disabled={!(isEditable || props.editOnly) || props.readOnly}
            onBlur={onInputBlur}
            onChange={onInputChange}
            style={editorStyle}
          />
        </>);
    }
  };

  const attrReadonlyField = () => {
    const textInputCls = () => {
      const cls = ['cell-value'];

      //if (props.readOnly) cls.push('visual-ctn');
      if (!isOpeIconEnable()) cls.push('visual-ctn');
      else cls.push('visual-ctn2');

      return cls.join(' ');
    };

    switch (props.type) {
      case BASIC_ATTR_TYPE_TEXT:
        return (
          <textarea
            className={textInputCls()}
            disabled
            rows={getTextareaRows()}
            style={{ height: 'auto' }}
            //value={currentValue}
            value={currentValue === undefined ? '' : `${currentValue}`}
          >
            {/*currentValue*/}
          </textarea>
        );

      default:
        //console.log('-->', props.name, props.type);
        return (
          <span className={getReadonlyVisualCtnClassName()}>
            <Typography.Text ellipsis={{ tooltip: currentValue }}>
              {/*currentValue*/}
              {attrReadonlyView()}
            </Typography.Text>
          </span>
        );
    }
  };

  const attrReadonlyView = () => {
    if (props.type === BASIC_ATTR_TYPE_SELECT) {
      if (props.attr && props.attr.basicAttributeId) {
        //switch (props.attr.basicAttributeId) {
        switch (props.attr.fieldName) {
          case ATTR_CONDITION_FIELD_NAME:
          case ATTR_DIMENSION_UNIT_FILED_NAME:
          case ATTR_WEIGHT_UNIT_FIELD_NAME:
            return attrCommonOptionsReadonlyView();
        }
      }
    }

    if (props.type === BASIC_ATTR_TYPE_PRICE) {
      return `$${parseFloat(currentValue).toFixed(2)}`;
    }

    if (props.type === BASIC_ATTR_TYPE_DEMICAL) {
      return parseFloat(currentValue).toFixed(1);
    }

    //console.log('-->', currentValue);
    return currentValue;
  };

  const attrOpeIcon = () => {
    return (<>
      {isCopyable() && <CopyToClipboard
        text={copyValue()}
        onCopy={() => notifyCopyMsg()}
      >
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          title="Copy value to clipboard"
        >
          <CopyOutlined />
        </span>
      </CopyToClipboard>}
      {isSaving && (
        <LoadingIcon />
      )}
      {/*!props.readOnly && !isSaving && (*/}
      {isEditIconEnable() && (
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={enterEditMode}
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? '💾' : '🖊️'}
        </span>
      )}
    </>);
  };

  const copyValue = () => {
    return currentValue === null || currentValue === undefined ? '' : `${currentValue}`.trim();
  };

  const enterEditMode = () => {
    if (isEditable) {
      if (currentValue === lastValue) {
        setIsEditable(false);
      }
    } else {
      setIsEditable(true);
    }
  };

  const getReadonlyVisualCtnClassName = () => {
    let cls = ['cell-value'];

    if (props.readOnly) {
      if (isCopyable()) {
        cls.push('visual-ctn');
      } else {
        cls.push('visual-ctn0');
      }
    } else {
      if (isCopyable()) {
        cls.push('visual-ctn2');
      } else {
        cls.push('visual-ctn');
      }
    }

    return cls.join(' ');
  };

  const getTextareaRows = () => {
    return props.rows ? props.rows : TEXTAREA_ROWS;
  };

  const isCopyable = (typ = props.type) => {
    const ts = [BASIC_ATTR_TYPE_STRING, BASIC_ATTR_TYPE_TEXT];

    return ts.indexOf(typ) > -1;
  };

  const isEditIconEnable = () => {
    let enable = !props.readOnly && !isSaving;

    if (enable) {
      if (props.attr && 'editable' in props.attr) {
        if (props.attr.editable === 0) enable = false;
      }
    }

    return enable;
  };
  
  const isEditorDisabled = () => {
    if (props.attr && 'editable' in props.attr) {
      if (props.attr.editable === 0) return true;
    }

    return !(isEditable || props.editOnly) || props.readOnly;
  };

  const isOpeIconEnable = () => {
    if (props.attr && 'editable' in props.attr) {
      if (props.attr.editable === 0) return false;
    }

    return !props.editOnly;
  };

  const notifyCopyMsg = () => {
    copyValue() ?
      notification.success({ message: 'Attribute value has been copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION }) :
      notification.info({ message: 'No value can be copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION })
  };

  const onInputBlur = (e: any) => {
    if (currentValue === null && lastValue === null) return;
    if (isEditable && currentValue !== lastValue) {
      saveAttribute();
    } else if (props.editOnly) {
      if (props.attr) {
        props.attr.editValue = currentValue || '';
        props.attr.valueChanged = true;

        if (typeof props.refreshFormState === 'function') {
          props.refreshFormState();
        }
      } else {
        if (typeof props.onChange === 'function') {
          if (currentValue !== lastValue) {
            props.onChange(currentValue, props.name, true);
          }
        }
      }
    }
    if (props.type === BASIC_ATTR_TYPE_PRICE) {
      setCurrentValue(parseFloat(currentValue).toFixed(2));
    }
    if (props.type === BASIC_ATTR_TYPE_DEMICAL) {
      setCurrentValue(parseFloat(currentValue).toFixed(1));
    }
  };

  const onInputChange = (evt: any) => {
    setCurrentValue(evt.target.value);
  };

  const onNumberChange = (evt: any) => {
    const { value = 0 } = evt.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) || value === '' || value === '-') {
      setCurrentValue(value);
    }
  };

  const onNumberInputChange = (value: any) => {
    // console.log('evt->', value);
    setCurrentValue(value);
  };

  const onSelectorChange = (value: any) => {
    setCurrentValue(value);
  };

  const saveAttribute = async () => {
    const attrLabel = props.attr ?
      (props.attr.basicAttributeName || props.attr.title) : (props.label || '');
    console.log('ready save', currentValue, props.attr);
    setIsSaving(true);

    try {
      //const basic: StringKAnyVPair = {};
      //basic['productTitle'] = currentValue;
      const overwrite = props.state?.overwriteChildren;
      const basic: StringKAnyVPair = {
        /*sku: props.styleCode,
        styleCode: props.styleCode,*/
      };
      const data: StringKAnyVPair = {
        ApplyToAllRelevantSubStyleAndSKU: overwrite ? true : false,
        applyWithBlank: overwrite === 2 ? 1 : 0,
        styleCode: props.styleCode,
        productBasic: basic,
      };
      basic[props.name] = currentValue;

      if (props.attr && props.attr.basicAttributeNum > 999) {
        const { basicAttributeId } = props.attr;

        data.productBasicInfoAttributeList = [{
          AttributeId: basicAttributeId,
          Value: currentValue,
          //editType: 0,
          editType: 1,
        }];
        data.productBasic = undefined;
      }

      if (props.category) {
        if (props.category === ATTR_CATEGORY_OPERATION) {
          data.productOperation = basic;
          data.productBasic = undefined;
        }
      }

      //console.log('pdata ->', data, props.attr);
      if (props.productId) {
        if (props.attr && 'source' in props.attr) {
          if (props.styleCode) {
            const pd = {
              CopywritingElementNum: props.attr.attributeId,
              //CopywritingElementValue: currentValue,
              CopywritingValue: currentValue,
              applyWithBlank: overwrite === 2 ? 1 : 0,
              overwrite: overwrite ? true : false,
            };
            console.log('pd save', pd);
            const res = await saveCopywritingSingleElement(props.productId, props.styleCode, props.attr.attributeId, pd);
            console.log('res ->', res);
          }
        } else {
          console.log('pdata ->', data, props.attr);
          const res = await Products.editSimpleStyleMaster(props.productId, data);
          console.log('res ->', res);
        }
      }

      setLastValue(currentValue);
      setIsEditable(false);
      message.success(`Saved '${attrLabel}' successfully`);
    } catch (e) {
      message.error(`Saved '${attrLabel}' failed`);
    } finally {
      setIsSaving(false);
    }
  };

  const selectorOptions = () => {
    return optionList.map(e => (
      <Select.Option
        key={e.value}
        value={e.value}
      >
        {e.label}
      </Select.Option>
    ));
  };

  // eslint-disable-next-line
  const setSelectorOptions = async () => {
    if (props.attr) {
      //switch(props.attr.basicAttributeId) {
      switch (props.attr.fieldName) {
        case ATTR_CONDITION_FIELD_NAME:
        case ATTR_DIMENSION_UNIT_FILED_NAME:
        case ATTR_WEIGHT_UNIT_FIELD_NAME:
          const enumDef = await loadBasicEnumDef();

          //console.log('dd->', enumDef);
          if (Array.isArray(enumDef.weightEnum) && Array.isArray(enumDef.dimensionEnum)) {
            const kd: StringKAnyVPair = {
              [ATTR_CONDITION_FIELD_NAME]: 'conditionEnum',
              [ATTR_DIMENSION_UNIT_FILED_NAME]: 'dimensionEnum',
              [ATTR_WEIGHT_UNIT_FIELD_NAME]: 'weightEnum',
            };
            const opts = enumDef[kd[props.attr.fieldName]].map((e: StringKAnyVPair) => ({
              label: e.name,
              value: e.id,
            }));
            //console.log('opts->', kd, opts);
            setOptionList(opts);
          }
          break;
      }
    }
  };

  React.useEffect(() => {
    if (!inited) {
      if (props.type === BASIC_ATTR_TYPE_SELECT) {
        setSelectorOptions();
      }

      setInited(true);
    }
  }, [inited, props, setSelectorOptions]);

  return (<>
    <EditorWrapper className={props.className || ''}>
      <Input.Group compact>
        {isEditable || props.editOnly ? attrEditableField() : attrReadonlyField()}
        {!props.editOnly && attrOpeIcon()}
        {/*isOpeIconEnable() && attrOpeIcon()*/}
      </Input.Group>
    </EditorWrapper>
  </>);
};

export default BasicAttrEditor;

export const getOperationStatusDesc = (
  code: number,
  returnCode = false,
) => {
  let ret: string | number = returnCode ? code : '';
  const fs = operationStatusOptions.filter(e => e.code === code);

  if (fs.length > 0) ret = fs[0].description;

  return ret;
};

export const getOperationStatusOptions = () => operationStatusOptions;
