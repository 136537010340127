import { ApiOutlined, PlusOutlined, ToolOutlined } from '@ant-design/icons/lib';
import {
  Badge,
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { CopyOutlined } from '@ant-design/icons';
import Header from '../../components/ChannelIntegration/header';
import FormButtons from '../../components/common/FormButtons';
import Loading from '../../components/common/Loading';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { Permissions } from '../../constants/enums/permissions';
import { countries } from '../../constants/common';
import { LOADING_ICON_SIZE1 } from '../../constants/config';
import IntegrationsActions from '../../redux/actions/integrations';
import { useGetChannelProfileSettingsQuery } from '../../redux/api/channel';
import { IntegrationsState } from '../../redux/reducers/integrations';
import { AppState } from '../../redux/types';
import {
  // createChannelAccountNum,
  deleteChannelAccount, fetchCentralEnabled,
  fetchChannels,
  fetchChannelsFields,
  getChannelAccountProfile,
  // getChannelAuthPath,
  getConfirmationActivation,
  updateChannelAccountNum,
  updateChannelAccountProfileSettings,
  fetchGlobalEnabled,
  // updateCatalogSetting,
  saveSelectedTemplates,
  saveProfileSettings,
  saveSelectedStandardTemplates,
  // saveSyncTemplate,
  generateFeedUrl,
  channelsLoginFromEdit,
  channelsLoginFromAdd,
  createChannelAccount
} from '../../services/channels';
import { getCategoryVersion, getChannelAccountVersion } from '../../services/ChannelIntegration';
import { fetchActiveChannelControlFlags } from '../../services/products';
import { objectToArray } from '../../util';
import { useExecutePromise } from '../../util/hooks';
import { groupByKey } from '../../util/strings';
import { FormsContext, IntegrationForm } from './details/context';
import { ChannelIntegrationRoutes } from './index';
import SchedulingTab from './tabs/Scheduling';
// import CatalogTab from './tabs/Catalog/index';
import ChannelCategory from './tabs/ChannelCategory';
import IntegrationTemplate from './tabs/IntegrationTemplate';
import IntegrationTemplate02 from './tabs/IntegrationTemplate/index02';
// import CustomTab from './tabs/CustomTemplate/index';
// import StandardTemplate from './tabs/StandardTemplate';
// import ProfileSettingTab from './tabs/ProfileSetting'

type PageParams = {
  channelNum?: string;
  platformNum?: string;
  channelAccountNum?: string;
};


/**
 * Takes the settings object and shapes required data to fit the form.
 *
 * Currently it only transforms datetime strings into Moment objects
 * @param settings
 */
export const replaceFormDates = (settings: any): any => {
  const obj: any = _.cloneDeep(settings);

  Object.keys(settings.ScheduleSetting).forEach(scheduleKey => {
    const { Start, End } = settings.ScheduleSetting[scheduleKey] || {};

    if (Start) {
      obj.ScheduleSetting[scheduleKey].Start = moment(Start);
    }

    if (End) {
      obj.ScheduleSetting[scheduleKey].End = moment(End);
    }
  });

  return obj;
};

/**
 * Takes the settings object and shapes the DCWarehouse into an object form
 *
 * Currently it only transforms DCWarehouse from list to object
 * @param settings
 */
const settingsObjToFormObj = (settings: Entities.ChannelAccountProfileSetting): FormModels.IntegrationSettings => {
  const obj: any = _.cloneDeep(settings);
  const dcObj: any = {};

  Object.keys(settings.ScheduleSetting).forEach(scheduleKey => {
    if (scheduleKey === 'InventorySettings') {
      const { SendPercentageTotalQty, MaxQty, LessQty, ProjectedQtySetting = {} } = obj.ScheduleSetting.InventorySettings;
      const {
        SendPercentageTotalQty: projectedSendPercentageTotalQty,
        MaxQty: projetedMaxQty,
        LessQty: projetedLessQty,
        SendUnits: projectedSendUnits
      } = ProjectedQtySetting || {};

      // Define bools for easier management of the UI
      obj.ScheduleSetting[scheduleKey].bools = {
        SendPercentageTotalQty: Number(SendPercentageTotalQty) > 0,
        MaxQty: Number(MaxQty) > 0,
        LessQty: Number(LessQty) > 0,
        ProjectedQtySetting: {
          SendPercentageTotalQty: Number(projectedSendPercentageTotalQty) > 0,
          MaxQty: Number(projetedMaxQty) > 0,
          LessQty: Number(projetedLessQty) > 0,
          SendUnits: Number(projectedSendUnits) > 0,
        }

      };
      if (obj.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings) {
        obj.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings = settings.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings.reduce<{ [key: string]: Entities.DistributionCenterWarehouseMapping }>((p, c) => {
          const dcNum = c.DistributionCenterNum.toString();
          // eslint-disable-next-line no-param-reassign
          p[dcNum] = c;
          return p;
        }, {});
      } else {
        obj.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings = {};
      }
    }
  });

  const DC = settings.ScheduleSetting.InventorySettings?.DistributionCenterWarehouseMappings || [];
  for (let i = 0; i < DC.length; i += 1) {
    dcObj[DC[i].DistributionCenterNum] = DC[i];
  }
  obj.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings = dcObj;
  return obj;
};


const useReduxActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(IntegrationsActions, dispatch), [dispatch]);
};

/**
 * Custom hook that's used on both versions of this page.
 *
 * This loads:
 * - Available channels
 * - Available form fields
 * - Other related things
 * - Form initialization
 */
export const usePageSetup = (channelNum?: string, platformNum?: string, channelAccountNum?: string): [FormInstance<IntegrationForm>, boolean, boolean] => {
  const [loading, setLoading] = useState(false);
  const [isETSY, setIsETSY] = useState<boolean>(false);
  const [form] = Form.useForm<IntegrationForm>();
  const actions = useReduxActions();
  const channelAccount = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelAccount);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const autoFill = searchParams.get('isAutoFill') === '1';

  // Load profileSettings
  const {
    data,
    isFetching,
  } = useGetChannelProfileSettingsQuery({ channelAccountNum: channelAccount?.ChannelAccountNum?.toString() }, { skip: !channelAccount });

  const loadData = useCallback(async (mounted: boolean) => {
    try {
      const [channels, retailers, platforms, globalEnabled]: [Entities.ChannelProfile[], Entities.ChannelProfile[], Entities.Platform[], Entities.ChannelProfile[],] = await Promise.all([fetchCentralEnabled(), fetchChannels(true), fetchChannelsFields(), fetchGlobalEnabled()]);
      let settings;

      // add etsy
      platforms.forEach(pf => {
        if ((pf.MetaDataList || []).find(md => md.SettingName === 'OAuth')) {
          setIsETSY(true);
        }
      })

      if (channelAccountNum) {
        settings = await getChannelAccountProfile(Number(channelAccountNum));

        if (settings) {
          actions.setChannelAccount(settings);
        } else {
          return;
        }
      } else {
        actions.setChannelAccount();
      }

      const groupedChannels = objectToArray(groupByKey(channels, 'category'));
      if (!mounted) {
        return;
      }
      actions.setGeneralData({
        // Retailers channelNum converted to string for easier manipulation within the form
        retailers: retailers.map((r: any) => ({ ...r, channelNum: r.channelNum.toString() })),
        channels,
        platforms,
        groupedChannels,
        globalEnabled: globalEnabled.map((r: any) => ({ ...r, channelNum: r.channelNum.toString() })),
      });
    } catch (e) {
      notification.error({ message: 'There was an error loading the data', duration: 5 });
    }
  }, [actions, channelAccountNum]);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    loadData(mounted)
      .finally(() => {
        if (!mounted) {
          return;
        }

        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [loadData]);

  // Hook that clears the redux state
  useEffect(() => {
    if (!form) {
      return;
    }

    if (channelAccountNum) {
      actions.setEditMode(false);
      actions.setVerifyConnection(true);
    } else {
      // Enable edit mode because it's on creation mode
      actions.clearCurrentProfile({ editMode: true });
      form?.resetFields();
    }
  }, [form, channelAccountNum, actions]);

  useEffect(() => {
    if (!form) {
      return;
    }

    form.resetFields();

    if (!channelAccount) {
      actions.setProfileSettings();
      return;
    }

    form.setFieldsValue({ account: { MetaDataList: channelAccount.MetaDataList.filter(field => field.RetrievedByUI === 1).sort((a, b) => a.Order - b.Order) } });
    if (data) {
      const formData = settingsObjToFormObj(data);
      actions.setProfileSettings(formData);
      form.setFieldsValue({ settings: replaceFormDates(formData) });
    }

    if (autoFill) {
      const { MetaDataList = [] } = form.getFieldsValue().account;
      const tempArr = MetaDataList.map(md => {
        const urlParam = searchParams.get(md.SettingName.replace(/\s/g, '')) || '';
        if (urlParam) {
          return {
            ...md,
            SettingValue: urlParam
          }
        }
        return md;
      });
      form.setFieldsValue({ account: { MetaDataList: tempArr } });
      actions.setEditMode(true);
      actions.setFormDirtyStatus(true);
    }
    // eslint-disable-next-line
  }, [form, data, channelAccount, actions]);

  return [form, loading || isFetching, isETSY];
};

/**
 * Custom hook used to verify if the current channelAccountNum is activated.
 *
 * @param channelNum
 * @param channelAccountNum
 * @return An array, first value is true/false for activation (null if not available), second value is the indicator of the request (true if inflight)
 */
const useVerifyChannelConnection = (channelNum?: string, channelAccountNum?: string): [boolean | null, boolean] => {
  const actions = useReduxActions();
  const verify = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.verifyConnection);

  const checkActivation = useCallback(async (): Promise<boolean | null> => {
    if (!channelAccountNum || !verify) {
      return null;
    }

    return getConfirmationActivation(Number(channelAccountNum));
  }, [channelAccountNum, verify]);

  const [isActive, processing] = useExecutePromise(checkActivation, null);

  useEffect(() => {
    if (isActive === null) {
      return;
    }

    if (!isActive) {
      notification.warning({ message: 'This integration is not active', duration: 3 });
    } else {
      notification.success({ message: 'Integration is active', duration: 3 });
    }
    actions.setIsActivated(isActive);
    actions.setVerifyConnection(false);
  }, [isActive, actions]);

  return [isActive, processing];
};


/**
 * This hooks handles the oAuth window event
 * Triggers the Channel Account Activation verification after the window regains focus
 */
const useOAuthConnection = (channelNum?: string, channelAccountNum?: string) => {
  const actions = useReduxActions();
  const oAuthURL = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.oAuthURL);

  const windowListener = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    actions.setOAuthURL(undefined);
    actions.setVerifyConnection(true);
  }, [actions]);

  useEffect(() => {
    if (!oAuthURL || !channelNum || !channelAccountNum) {
      return () => {
      };
    }

    actions.setVerifyConnection(false);
    document.addEventListener('visibilitychange', windowListener);
    window.open(oAuthURL, '_blank');

    return () => {
      document.removeEventListener('visibilitychange', windowListener);
    };
  }, [windowListener, actions, oAuthURL, channelNum, channelAccountNum]);
};

const saveUpdateChannelIntegration = async (
  formValues: Entities.ChannelAccountObj & { ChannelIntegrations: number },
  profileSelected: any,
  selectedChannel: Entities.ChannelProfile,
  selectedChannelFields: Entities.PlatformMetadata[],
  channelAccountNum?: number,
  actions?: any
): Promise<number> => {
  const metaDataList = formValues
    .MetaDataList
    .filter(metadata => {
      // If the integration is being created for the first time, omit the next operations.
      if (!channelAccountNum) {
        return true;
      }

      // If the field is not password, allow it.
      if (!metadata.SettingName.toLowerCase().match(/password/)) {
        return true;
      }

      // Only allow the password field if it actually has a value.
      const entry = formValues.MetaDataList.find(fm => fm.ChannelMetaDataNum === metadata.ChannelMetaDataNum);
      return !!entry?.SettingValue || false;
    });
  const NickNameMetaDataNum = selectedChannelFields.find((md: Entities.PlatformMetadata) => md.SettingName === 'NickName' || md.SettingName === 'Nick Name')?.ChannelMetaDataNum;
  const NickNameValue = metaDataList?.find((md: Entities.PlatformMetadata) => md.ChannelMetaDataNum === NickNameMetaDataNum)?.SettingValue;
  const isThirdPlatform = selectedChannel?.category === 'ThirdPartyPlatform';

  let getIdPayload: any = {
    platformNum: 0,
    channelNum: formValues.ChannelIntegrations,
    channelName: selectedChannel.channelName,
    channelCompanyName: NickNameValue,
    channelAccountName: NickNameValue,
    metaDataList,
  };

  if (isThirdPlatform) {
    const RetailerMetaDataNum = selectedChannelFields.find((md: Entities.PlatformMetadata) => md.SettingName === 'Retailer')?.ChannelMetaDataNum;
    const RetailerValue = metaDataList.find((md: Entities.PlatformMetadata) => md.ChannelMetaDataNum === RetailerMetaDataNum)?.SettingValue;
    console.log('cc2', RetailerValue, RetailerMetaDataNum);
    getIdPayload = {
      ...getIdPayload,
      channelNum: RetailerValue,
      platformNum: formValues.ChannelIntegrations,
    };
  }

  let newChannelAccountNum: number;
  if (!channelAccountNum) {
    // const res = await createChannelAccount({ ...getIdPayload, reportCode: formValues.ReportCode });
    const res = await createChannelAccount(getIdPayload)
    newChannelAccountNum = res.ChannelAccountNum;
  } else {
    newChannelAccountNum = channelAccountNum;
    const res = await updateChannelAccountNum(channelAccountNum, selectedChannel.channelNum, getIdPayload);
    actions.setChannelAccount(res);
  }

  return newChannelAccountNum;
};

const IntegrationSelect: React.FC<SelectProps<number>> = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { onChange } = props;
  const form = useContext(FormsContext);
  const channels: Entities.ChannelProfile[] = useSelector(({ integrations }: AppState) => integrations.channels);
  const groupedIntegrations: Entities.ChannelProfile[][] = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.groupedChannels);
  const channelFields = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelFields);

  const onChannelChanged = useCallback((value: number) => {
    const isThirdPartyPlatform = channels.find(c => c.channelNum === value)?.category === 'ThirdPartyPlatform' || false;

    const params = {
      ...match.params,
      channelNum: isThirdPartyPlatform ? 0 : value,
      platformNum: isThirdPartyPlatform ? value : 0,
    };

    const path = generatePath(ChannelIntegrationRoutes.add, params);
    history.replace(path);
  }, [channels, history, match]);

  const onSelectionChange = useCallback((value: number, options: any) => {
    if (form) {
      const definition = channelFields.find(f => f.ChannelNum === Number(value) || f.PlatformNum === Number(value));
      if (definition) {
        form.setFieldsValue({ account: { MetaDataList: definition.MetaDataList.sort((a, b) => a.Order - b.Order) } });
      }
    }

    onChannelChanged(value);
    onChange?.(value, options);
  }, [onChange, form, channelFields, onChannelChanged]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Select
      {...props}
      placeholder="Select an Integration"
      onChange={onSelectionChange}
    >
      {/* eslint-enable */}
      {
        groupedIntegrations.map(
          (integrations: Entities.ChannelProfile[]) => (
            <Select.OptGroup key={integrations[0].category} label={integrations[0].category}>
              {
                integrations.map(
                  (integration: Entities.ChannelProfile) => (
                    <Select.Option
                      key={integration.channelNum}
                      value={integration.channelNum}
                    >
                      {integration.channelName}
                    </Select.Option>
                  ),
                )
              }
            </Select.OptGroup>
          ),
        )
      }
    </Select>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActionButtons: React.FC = () => {
  const channelAccountObj = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelAccount);
  const [processing, setProcessing] = useState(false);
  const testConnection = useCallback(() => {
    setProcessing(true);
    setTimeout(() => {
      notification.success({ message: 'Connection established' });
      setProcessing(false);
    }, 2000);
  }, []);

  // If there's no account object, do not render the disconnect/test buttons
  if (!channelAccountObj) {
    return null;
  }

  // TODO: add back Disconnect button
  return (
    <Row gutter={8}>
      <Col>
        <Button>
          Disconnect
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={testConnection}
          loading={processing}
          disabled
        >
          <ToolOutlined />
          Test
        </Button>
      </Col>
    </Row>
  );
};

type RetailerSelectPros = SelectProps<number> & { disabled?: boolean };

const RetailerSelect: React.FC<RetailerSelectPros> = (
  {
    disabled = false,
    ...selectProps
  },
) => {
  const { onChange } = selectProps;
  const history = useHistory();
  const match = useRouteMatch();
  const routeParams = useParams<PageParams>();
  const { platformNum, channelAccountNum } = routeParams;
  const retailers = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.globalEnabled);
  const onRetailerChange = useCallback((value: number, option: any) => {
    const path = generatePath(match.path, { ...routeParams, channelNum: value });
    history.replace(path);
    onChange?.(value, option);
  }, [history, match, routeParams, onChange]);

  return (
    <Select
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...selectProps}
      placeholder="Select a Retailer"
      disabled={disabled || !!channelAccountNum}
      bordered={!channelAccountNum}
      showArrow={!channelAccountNum}
      onChange={onRetailerChange}
    >
      {
        retailers
          .filter(p => p.platformNum === Number(platformNum))
          .map((c: Entities.ChannelProfile) => (
            <Select.Option key={c.channelNum} value={c.channelNum}>{c.channelName}</Select.Option>
          ))
      }
    </Select>
  );
};


interface ChannelAttributeFieldProps {
  definition: Entities.PlatformMetadata;
  index: number;
  disabled: boolean;
  channelNum?: string;
  channelAccountNum?: string;
  creating?: boolean;
}

const ChannelAttributeField: React.FC<ChannelAttributeFieldProps> = (
  {
    definition,
    index,
    disabled,
    channelNum,
    channelAccountNum,
    creating = false,
  },
) => {
  const { SettingName, SettingValue, DataType } = definition;
  // Check if the field is a password field. There's no other way but checking if the name
  // contains the word "password"
  const isPasswordField = SettingName.toLocaleLowerCase().match(/password/) !== null;
  const isChannelFilePathField = SettingName.toLocaleLowerCase().match(/channelfilepath/) !== null;
  const form = useContext(FormsContext);
  const actions = useReduxActions();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const accessToken = searchParams.get('access_token') || '';
  // const refreshToken = searchParams.get('refresh_token') || '';
  const autoFill = searchParams.get('isAutoFill') === '1';

  useEffect(() => {
    if (!form) return;
    if (autoFill) {
      const { MetaDataList = [] } = form.getFieldsValue().account;
      const tempArr = MetaDataList.map(md => {
        const urlParam = searchParams.get(md.SettingName.replace(/\s/g, '')) || '';
        if (urlParam) {
          return {
            ...md,
            SettingValue: urlParam
          }
        }
        return md;
      });
      form.setFieldsValue({ account: { MetaDataList: tempArr } });
      if (!creating) {
        actions.setEditMode(true);
        actions.setFormDirtyStatus(true);
      }
    }
    // eslint-disable-next-line
  }, [form, creating, actions])

  const required = useMemo(() => {
    if (isPasswordField && !creating) {
      return false;
    }

    if (isChannelFilePathField) {
      return false;
    }
    if (definition.DataType === 'Boolean') {
      return false;
    }

    return true;
  }, [isPasswordField, isChannelFilePathField, creating, definition]);

  const getUrl = useCallback(async (mtype: number) => {
    try {
      const url = await generateFeedUrl(channelNum, channelAccountNum, mtype)
      form?.setFields([
        {
          name: ['account', 'MetaDataList', index, 'SettingValue'],
          value: url,
        },
      ]);
    } catch (error) {

    }
  }, [channelAccountNum, channelNum, form, index])

  const formItemInitialValue =
    definition.DataType === 'Boolean' ? definition.SettingValue || false
      // : definition.SettingName === 'AccessToken' ? accessToken || definition.SettingValue
      //   : definition.SettingName === 'RefreshToken' ? refreshToken || definition.SettingValue
      : definition.SettingValue

  const Widget = useMemo(() => {
    switch (SettingName) {
      case 'Country':
        return (
          <Select
            showSearch
            placeholder="Select a Country"
            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={disabled}
          >
            {
              countries
                .filter(c => c.code === 'US')
                .map(c => (
                  <Select.Option key={c.code} value={c.code}>{c.name}</Select.Option>
                ))
            }
          </Select>
        );
      case 'CatalogFeedURL':
      case 'InventoryFeedURL':
        return !creating ? (
          <Input
            type={isPasswordField ? 'password' : 'text'}
            disabled={disabled}
            readOnly={isChannelFilePathField}
            addonAfter={
              <Button disabled={disabled} onClick={() => {
                getUrl(SettingName === 'CatalogFeedURL' ? 1 : 2)
                actions.setFormDirtyStatus(true);
              }}>Re-Generate URL</Button>
            }
          />
        ) :
          (
            <Input
              type={isPasswordField ? 'password' : 'text'}
              disabled={disabled}
              readOnly={isChannelFilePathField}
            />
          )
      case 'AccessToken':
      case 'RefreshToken':
        return (
          <Input.Password
            disabled={disabled}
            readOnly={isChannelFilePathField}
          />
        )
      case 'Retailer':
        return <RetailerSelect disabled={disabled} />;
      default:
        switch (DataType) {
          case 'String':
            return (
              <Input
                type={isPasswordField ? 'password' : 'text'}
                disabled={disabled}
                readOnly={isChannelFilePathField}
              />
            )
          case 'Interger':
            return (
              <InputNumber
                disabled={disabled}
                readOnly={isChannelFilePathField}
              />
            )
          case 'ReadOnlyCopyField':
            return (
              <Typography.Text copyable>{SettingValue}</Typography.Text>
            )
          case 'Password':
            return (
              <Input
                type='password'
                disabled={disabled}
                readOnly={isChannelFilePathField}
              />
            )
          case 'Boolean':
            // if(SettingValue !== 'true') {
            //   form?.setFields([
            //     {
            //       name: ['account', 'MetaDataList', index, 'SettingValue'],
            //       value: false,
            //     },
            //   ]);
            // }
            return (
              <Switch
                checkedChildren="on"
                unCheckedChildren="off"
                disabled={disabled}
                defaultChecked={SettingValue === 'true'}
                onChange={(checked: boolean) => {
                  form?.setFields([
                    {
                      name: ['account', 'MetaDataList', index, 'SettingValue'],
                      value: checked,
                    },
                  ]);
                  actions.setFormDirtyStatus(true);
                }}
              />
            );
          default:
            return (
              <Input
                disabled={disabled}
                readOnly={isChannelFilePathField}
              />
            )
        }
    }
  }, [SettingName, disabled, isPasswordField, isChannelFilePathField, index, SettingValue, DataType, form, actions, creating, getUrl]);

  // This field must not be rendered
  if (definition.RetrievedByUI !== 1) {
    return null;
  }

  if (isChannelFilePathField && creating) {
    return null;
  }

  if (definition.SettingName === 'OAuth') {
    //OAuth default set 100 -- FE-1585
    if(form){
      const temp = form.getFieldsValue();
      if(temp&& temp.account){
        const {MetaDataList} = temp.account
        Object.assign(MetaDataList[index], { SettingValue: 100 })
        form.setFieldsValue({ ...temp })
      }
    }

    return (
      <>
        <Form.Item
          hidden
          key={definition.ChannelMetaDataNum}
          label={definition.DisplayName}
          name={['account', 'MetaDataList', index, 'SettingValue']}
          initialValue={100}
        >
          {Widget}
        </Form.Item>
        <Form.Item noStyle hidden name={['account', 'MetaDataList', index, 'SettingType']} initialValue={1}>
          <Input readOnly />
        </Form.Item>
        <Form.Item
          noStyle
          hidden
          name={['account', 'MetaDataList', index, 'ChannelMetaDataNum']}
          initialValue={definition.ChannelMetaDataNum}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          noStyle
          hidden
          name={['account', 'MetaDataList', index, 'SettingName']}
          initialValue={definition.SettingName}
        >
          <Input readOnly />
        </Form.Item>
      </>
    );;
  }

  return (
    <>
      <Form.Item
        key={definition.ChannelMetaDataNum}
        label={definition.DisplayName}
        name={['account', 'MetaDataList', index, 'SettingValue']}
        rules={[{ required, message: 'This field is required' }]}
        initialValue={formItemInitialValue}
      >
        {Widget}
      </Form.Item>
      <Form.Item noStyle hidden name={['account', 'MetaDataList', index, 'SettingType']} initialValue={1}>
        <Input readOnly />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={['account', 'MetaDataList', index, 'ChannelMetaDataNum']}
        initialValue={definition.ChannelMetaDataNum}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={['account', 'MetaDataList', index, 'SettingName']}
        initialValue={definition.SettingName}
      >
        <Input readOnly />
      </Form.Item>
    </>
  );
};


const ChannelFlagField: React.FC = () => {
  const [options, loading] = useExecutePromise(fetchActiveChannelControlFlags, []);
  const editMode = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.editMode);

  return (
    <Row gutter={12}>
      <Col xs={24} lg={12}>
        <Form.Item label="Channel Control" name={['settings', 'ChannelFlagNum']}>
          <Select disabled={!editMode} loading={loading}>
            {
              options.map(o => (
                <Select.Option key={o.CHNLCtrlFlagNum} value={o.CHNLCtrlFlagNum}>
                  {o.CHNLCtrlFlag}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Col>
      <Col>
        <Button disabled={!editMode}>
          <PlusOutlined />
          Add
        </Button>
      </Col>
    </Row>
  );
};


const saveConnectionData = async (
  fields: Entities.PlatformMetadata[],
  data: Entities.ChannelAccountObj & { ChannelIntegrations: number },
  dispatch: Dispatch<any>,
  history: any,
  channel?: Entities.ChannelProfile,
  channelAccountNum?: string,
  currentProfile?: any,
) => {
  if (!channel) {
    return;
  }

  const actions = bindActionCreators(IntegrationsActions, dispatch);

  try {
    const can: number | undefined = channelAccountNum !== undefined ? Number(channelAccountNum) : channelAccountNum;
    const createdChannelAccountNum = await saveUpdateChannelIntegration(data, currentProfile, channel, fields, can, actions);

    const channelRetailer = data.MetaDataList.find(f => f.SettingName === 'Retailer')?.SettingValue;

    // If the channel requires OAuth and it's been just created
    // if (channel.allowOauth) {
    //   const path = await getChannelAuthPath(createdChannelAccountNum, channel.channelNum);
    //   // The OAuth hook will take care of redirecting to the details page
    //   actions.setOAuthURL(path);
    // } else {
    //   actions.setVerifyConnection(true);
    // }
    
    /**04/09/2024  FE-1440 */
    actions.setVerifyConnection(true);

    let path;
    if (channelRetailer) {
      path = generatePath(ChannelIntegrationRoutes.details, {
        channelNum: channelRetailer,
        platformNum: channel.channelNum,
        channelAccountNum: createdChannelAccountNum,
      });
    } else {
      path = generatePath(ChannelIntegrationRoutes.details, {
        channelNum: channel.channelNum,
        platformNum: 0,
        channelAccountNum: createdChannelAccountNum,
      });
    }

    // Navigate to the details page
    history.push(path);
    notification.success({ message: 'Basic settings updated successfully', duration: 5, closeIcon: null });
  } catch (e) {
    notification.error({ message: 'There was an error, please try again', duration: 5 });
    // eslint-disable-next-line no-console
    console.log(e);
    return true
  }
};

const saveSettingsData = async (
  settings: FormModels.IntegrationSettings,
  data: FormModels.IntegrationSettings,
  dispatch: Dispatch<any>,
  form: FormInstance,
) => {
  if (!data) {
    return;
  }
  const actions = bindActionCreators(IntegrationsActions, dispatch);

  const formData = _.cloneDeep(data);
  // Transform the moment objects to string
  Object.keys(formData.ScheduleSetting).forEach(schedulingKey => {
    const { Start, End }: { Start: Moment, End: Moment } = (formData.ScheduleSetting[schedulingKey] as any);

    if (Start) {
      formData.ScheduleSetting[schedulingKey].Start = Start.toISOString();
    }

    if (End) {
      formData.ScheduleSetting[schedulingKey].End = End.toISOString();
    }

    // Remove DCs where its warehouse codes is empty.
    // Also transforms the Map => Array
    if (schedulingKey === 'InventorySettings' && formData.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings) {

      Object.keys(formData.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings).forEach((key) => {
        const value = formData.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings[key].ChannelAccountWarehouseCode;
        if (value) {
          return;
        }

        delete formData.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings[key];
      });
    }
  });
  //keep the “update” and “enabled” property value as the same result
  try {
    formData.ScheduleSetting.InventorySettings.Enabled = formData.ScheduleSetting.InventorySettings.Update;
    formData.ScheduleSetting.ProductSettings.Enabled = formData.ScheduleSetting.ProductSettings.Update; // added on 2023.6.26
    formData.ScheduleSetting.OfferSetting.Enabled = formData.ScheduleSetting.OfferSetting.Update; // added on 2023.7.20
    formData.ScheduleSetting.IsProjectedQtyRequired = settings.ScheduleSetting.IsProjectedQtyRequired;
  } catch (e) {
    console.log('form data error', e);
  }

  const payload: FormModels.IntegrationSettings = {
    Enabled: true,
    Update: true,
    ...settings,
    ...formData,
  };

  //console.log('payload', payload, settings, formData);
  await updateChannelAccountProfileSettings(settings.ChannelAccountNum, settings.ChannelAccountSettingNum, payload);
  actions.setProfileSettings(payload);
  form.setFieldsValue({ settings: replaceFormDates(payload) });
  notification.success({ message: 'Scheduling settings updated successfully', duration: 5, closeIcon: null });
};

interface ConnectionFormProps {
  fields: Entities.PlatformMetadata[];
  handleLogin?: Function;
  isETSY?: boolean;
  /**
   * Event handler for fields that allow copying their content.
   * @param field - Field that invoked the action
   */
  onCopyValue?: (field: Entities.PlatformMetadata) => void;
}

/**
 * This form renders the required fields for a given connection.
 * @constructor
 */
const ConnectionForm: React.FC<ConnectionFormProps> = ({ fields, onCopyValue, isETSY = false, handleLogin = () => { } }) => {
  const { channelAccountNum, channelNum } = useParams<PageParams>();
  const editMode = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.editMode);
  const loading = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.loading);
  const settings = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.profileSettings);
  const [activeAccount, setActiveAccount] = useState<boolean>(false);
  const creating = channelAccountNum === undefined;
  const form = useContext(FormsContext);
  const history = useHistory();
  const actions = useReduxActions();
  const [logining, setLogining] = useState<boolean>(false);

  const onClickLogin = async () => {
    setLogining(true);
    await handleLogin();
  }

  useEffect(()=>{
    if(settings){
      setActiveAccount(settings.BasicSetting.Active)
    }
  },[settings]);

  return (
    <div>
      {creating && (
        <Row>
          <Col xs={24} lg={12}>
            <Form.Item label="Integration" name={['account', 'ChannelIntegrations']}>
              <IntegrationSelect disabled={loading || !editMode} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {creating ? 
      fields.filter((k)=> ['NickName', 'Retailer'].indexOf(k.SettingName) > -1).map((f, i) => {
        const field = (
          <ChannelAttributeField
            key={f.ChannelMetaDataNum}
            definition={f}
            index={i}
            channelNum={channelNum}
            channelAccountNum={channelAccountNum}
            disabled={loading || !editMode}
            creating={creating}
          />
        );

        return (
          <Row key={`${f.ChannelMetaDataNum}-row`} gutter={12}>
            <Col xs={24} lg={12}>
              {field}
            </Col>
            {f.SettingName === 'NickName' && (
              <Col xs={24} lg={6}>
                {/* TODO: add <ActionButtons /> back when feature is ready */}
              </Col>
            )}
            {f.SettingName === 'AccessToken' && isETSY && (
              <Col xs={24} lg={6}>
                <Button loading={logining} onClick={onClickLogin} disabled={!editMode}>
                  Get Token
                </Button>
              </Col>
            )}
            {f.SettingName === 'ChannelFilePath' && !creating && (
              <Col>
                <Button onClick={() => onCopyValue?.(f)}>
                  <CopyOutlined />
                  Copy
                </Button>
              </Col>
            )}
          </Row>
        );
      })
      :
      fields.map((f, i) => {
        const field = (
          <ChannelAttributeField
            key={f.ChannelMetaDataNum}
            definition={f}
            index={i}
            channelNum={channelNum}
            channelAccountNum={channelAccountNum}
            disabled={loading || !editMode}
            creating={creating}
          />
        );

        return (
          <Row key={`${f.ChannelMetaDataNum}-row`} gutter={12}>
            <Col xs={24} lg={12}>
              {field}
            </Col>
            {f.SettingName === 'NickName' && (
              <Col xs={24} lg={6}>
                {/* TODO: add <ActionButtons /> back when feature is ready */}
              </Col>
            )}
            {f.SettingName === 'AccessToken' && isETSY && (
              <Col xs={24} lg={6}>
                <Button loading={logining} onClick={onClickLogin} disabled={!editMode}>
                  Get Token
                </Button>
              </Col>
            )}
            {f.SettingName === 'ChannelFilePath' && !creating && (
              <Col>
                <Button onClick={() => onCopyValue?.(f)}>
                  <CopyOutlined />
                  Copy
                </Button>
              </Col>
            )}
          </Row>
        );
      })
    }
      {/* {creating && (
        <Row gutter={12}>
          <Col xs={24} lg={12}>
            <Form.Item label="Report Code" name={['account', 'ReportCode']} rules={[{ required: true, message: 'Report Code is required' }]}>
              <Input
                style={{ width: 110 }}
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
      )} */}
      {!creating && <ChannelFlagField />}
      {!creating && (
        <Tabs>
          <Tabs.TabPane tab="Other">
            <Row gutter={12}>
              <Col xs={24} lg={12}>
                <Form.Item label="Marketplace fee rate" name={['settings', 'BasicSetting', 'Rate']}>
                  <Input
                    type="number"
                    style={{ width: 140 }}
                    min={0}
                    max={100}
                    step={0.01}
                    addonAfter="%"
                    disabled={loading || !editMode}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} lg={12}>
                <Form.Item label="Report Code" name={['settings', 'ReportSetting', 'ReportCode']} rules={[{ required: true, message: 'Report Code is required' }]}>
                  <Input
                    style={{ width: 110 }}
                    maxLength={10}
                    disabled={loading || !editMode}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['settings', 'BasicSetting', 'Active']}
                  valuePropName="checked"
                  label="Account Active"
                >
                  <Switch
                    // key={`${activeAccount}`}
                    checkedChildren="on"
                    unCheckedChildren="off"
                    disabled={loading || !editMode}
                    checked={activeAccount}
                    onChange={(checked) => {
                      if(checked){
                        setActiveAccount(checked)
                        actions.setFormDirtyStatus(true);
                        form?.setFieldsValue({
                          settings: {
                            BasicSetting: {
                              Active: checked,
                            },
                          },
                        });
                      } else {
                        Modal.confirm({
                          content: <Typography.Text type='danger' strong>Deactivating your account will disable features such as order downloading, shipment tracking, and inventory feed. Are you sure you want to proceed with this action?</Typography.Text>,
                          okText:'Yes',
                          okButtonProps:{danger:true},
                          onOk: () => {
                            setActiveAccount(prev=>!prev)
                            actions.setFormDirtyStatus(true);
                            form?.setFieldsValue({
                              settings: {
                                BasicSetting: {
                                  Active: checked,
                                },
                              },
                            });
                          },
                        });
                      }
                    }}
                  />
                  {settings && !settings.BasicSetting.Active && (
                    <Button
                      style={{ marginLeft: 16 }}
                      // type="primary"
                      // danger
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to proceed?',
                          content: 'This action cannot be undone',
                          onOk: async () => {
                            try {
                              await deleteChannelAccount(Number(channelAccountNum));
                              notification.success({
                                message: 'Integration deleted successfully',
                                duration: 5,
                              });
                              history.push('/settings-channels-integrations');
                              actions.clearCurrentProfile();
                              form?.resetFields();
                            } catch (e) {
                              notification.error({
                                message:
                                  'The integration could not be deleted, please contact the system administrator',
                                duration: 5,
                              });
                            }
                          },
                        });
                      }}
                    >
                      Delete Account
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      )}
      <Form.Item>
        {creating && (
          <Button type="primary" htmlType="submit" loading={loading}>
            <ApiOutlined />
            Connect
          </Button>
        )}
      </Form.Item>
    </div>
  );
};

const TabBarButtons: React.FC = () => {
  const history = useHistory();
  const actions = useReduxActions();
  //const { channelNum } = useParams<PageParams>();
  const form = useContext(FormsContext);
  const formIsDirty = useSelector((state: AppState) => state.integrations.formIsDirty);
  const enabled = useSelector(({ integrations }: { integrations: IntegrationsState }) => !!integrations.channelAccount);
  const editing = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.editMode);
  const loading = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.loading || integrations.savingSettings);
  const integration = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelAccount);
  const settings = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.profileSettings);

  const onEditClick = useCallback(() => {
    actions.setEditMode(true);
  }, [actions]);

  const onCancelClick = useCallback(() => {
    form?.resetFields();

    if (integration) {
      form?.setFieldsValue({ account: { MetaDataList: integration.MetaDataList.sort((a, b) => a.Order - b.Order) } });
    }

    if (settings) {
      form?.setFieldsValue?.({ settings: replaceFormDates(settings) });
    }

    actions.cancelEdition();
  }, [actions, integration, settings, form]);

  const onDeleteClicked = useCallback(() => {
    if (!integration) {
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to proceed?',
      content: 'This action cannot be undone',
      onOk: async () => {
        try {
          await deleteChannelAccount(integration.ChannelAccountNum);
          notification.success({
            message: 'Integration deleted successfully',
            duration: 5,
          });
          history.push('/settings-channels-integrations');
          actions.clearCurrentProfile();
          form?.resetFields();
        } catch (e) {
          notification.error({
            message: 'The integration could not be deleted, please contact the system administrator',
            duration: 5,
          });
        }
      },
    });
  }, [integration, history, actions, form]);

  // const onDisableAccount = () => {
  //   //console.log('cn', channelNum);
  //   Modal.confirm({
  //     title: 'Are you sure you want to disable the account?',
  //     //content: 'This action cannot be undone',
  //     onOk: async () => {
  //       if (!integration) {
  //         notification.error({
  //           message: 'No integration selected',
  //           duration: 5,
  //         });
  //         return;
  //       }

  //       try {
  //         await deleteChannelAccount(integration.ChannelAccountNum);
  //         notification.success({
  //           message: 'Integration deleted successfully',
  //           duration: 5,
  //         });
  //         history.push('/settings-summary');
  //         actions.clearCurrentProfile();
  //         form?.resetFields();
  //       } catch (e) {
  //         notification.error({
  //           message: 'The integration could not be deleted, please contact the system administrator',
  //           duration: 5,
  //         });
  //       }
  //     },
  //   });
  // };

  if (!enabled) {
    return null;
  }

  return (<>
    <Space>
      {/* <Button
      onClick={onDisableAccount}
    >
      Disable Account
    </Button> */}
      <FormButtons
        onEdit={onEditClick}
        onCancel={onCancelClick}
        onDelete={onDeleteClicked}
        disableDelete={!integration}
        editingMode={editing}
        permissionNumber={Permissions.MANAGE_PRODUCTS}
        saving={loading}
        disableSave={!formIsDirty}
        hideDelete
      />
    </Space>
  </>);
};

const ChannelIntegrationScreen: React.FC = () => {
  const VERSION2 = 'Template02';
  const actions = useReduxActions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { channelNum, platformNum, channelAccountNum } = useParams<PageParams>();
  const [activeKey, setActiveKey] = useState(channelAccountNum ? 'settings' : 'connection')
  const [channelVersion, setChannelVersion] = useState<string>();
  const [inited, setInited] = useState(false);
  const [categoryVersion, setCategoryVersion] = useState<string>('')
  const [form, loadingInitialData, isETSY] = usePageSetup(channelNum, platformNum, channelAccountNum);
  const [, checkingVerification] = useVerifyChannelConnection(channelNum, channelAccountNum);
  useOAuthConnection(channelNum, channelAccountNum);
  const creating = channelAccountNum === undefined;

  const {
    formHasErrors,
    settingsFormHasErrors,
  } = useSelector(({ integrations }: { integrations: IntegrationsState }) => ({
    formHasErrors: integrations.formHasErrors,
    settingsFormHasErrors: integrations.settingsFormHasErrors,
  }));
  const formIsDirty = useSelector((state: AppState) => state.integrations.formIsDirty);
  const definitions: Entities.Platform[] = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelFields);
  const channelAccountInfo: Entities.ChannelAccountObj | undefined = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelAccount);

  const fields: Entities.PlatformMetadata[] = useMemo(() => {

    if (!channelAccountInfo) {
      return [];
    }

    return channelAccountInfo.MetaDataList
      .filter(field => field.RetrievedByUI === 1)
      .sort((a, b) => a.Order - b.Order);
  }, [channelAccountInfo]);

  const fields1: Entities.PlatformMetadata[] = useMemo(() => {
    const filterValue: number = Number(platformNum) || Number(channelNum);
    const definition = definitions.find(f => f.ChannelNum === filterValue || f.PlatformNum === filterValue);
    if (!definition) {
      return [];
    }

    return definition.MetaDataList
      .filter(field => field.RetrievedByUI === 1)
      .sort((a, b) => a.Order - b.Order);
  }, [definitions, channelNum, platformNum]);

  // const fields: Entities.PlatformMetadata[] = useMemo(() => {
  //   const filterValue: number = Number(platformNum) || Number(channelNum);
  //   const definition = definitions.find(f => f.ChannelNum === filterValue || f.PlatformNum === filterValue);

  //   if (!definition) {
  //     return [];
  //   }

  //   return definition.MetaDataList
  //     .filter(field => field.RetrievedByUI === 1)
  //     .sort((a, b) => a.Order - b.Order);
  // }, [definitions, channelNum, platformNum]);
  const channel = useSelector((state: AppState) => {
    const filterValue = Number(platformNum) || Number(channelNum);
    return state.integrations.channels.find(c => c.channelNum === filterValue);
  });
  const settings = useSelector((state: AppState) => state.integrations.profileSettings);
  const currentProfile = useSelector((state: AppState) => {
    if (!state.profiles.profiles || typeof state.profiles.selectedIndex !== 'number') {
      return null;
    }

    return state.profiles.profiles[state.profiles.selectedIndex];
  });

  // eslint-disable-next-line
  const loadSettings = async () => {
    // console.log('-- -->', channelNum, channelAccountNum);
    if (!channelAccountNum) return; 

    try {
      const ver = await getChannelAccountVersion(channelNum as any, channelAccountNum as any);
      const d = await getCategoryVersion(channelNum ||'')
      if(d && d.success){
        setCategoryVersion(d.data)
      }

      //console.log('>>', ver);
      setChannelVersion(typeof ver === 'string' ? ver.trim() : ver);
    } catch(e) {
      console.log(`Loaded version error: ${e}`);
    } finally {
    }
  };

  const onFormChanged = useCallback(() => {
    if (formIsDirty) {
      return;
    }

    actions.setFormDirtyStatus(true);
  }, [actions, formIsDirty]);

  const onCopyValue = useCallback(async (field: Entities.PlatformMetadata) => {
    if (!form) {
      return;
    }

    const values = form.getFieldsValue();
    const value = values.account.MetaDataList.find(md => md.SettingName === field.SettingName)?.SettingValue || '';

    try {
      await navigator.clipboard.writeText(value);
      message.success('Value copied to clipboard');
    } catch (e) {
      message.error('Could not copy value to clipboard');
    }
  }, [form]);

  const onFinishFailed = useCallback((errorInfo: ValidateErrorEntity<IntegrationForm>) => {
    const accountHasErrors = errorInfo.errorFields.filter(e => e.name[0] === 'account').length > 0;
    const settingsHasErrors = errorInfo.errorFields.filter(e => e.name[0] === 'settings').length > 0;

    actions.setFormHasErrors(accountHasErrors);
    actions.setSettingsFormHasErrors(settingsHasErrors);

    notification.error({ message: 'Please verify that all fields are filled properly' });
  }, [actions]);

  const handleLogin = async (mode: 'edit' | 'create') => {
    if (!channel) return;
    if (!channelNum) return;
    if (currentProfile === null) return;
    const { channelName } = channel;
    const { ProfileNum: profileNum, MasterAccountNum: masterAccountNum } = currentProfile;
    try {
      const { url = '' } =
        mode === 'edit' ? await channelsLoginFromEdit({ profileNum, masterAccountNum, channelAccountNum, channelNum, platformNum, channelName })
          : await channelsLoginFromAdd({ profileNum, masterAccountNum, channelNum, channelName })

      if (url.indexOf('https://') > -1) {
        // console.log('url = ', url);
        window.location.href = url;
      }
    } catch (error) {
      console.log('error = ', error);
    }
  }

  const submit = useCallback(async (data: IntegrationForm) => {
    let hasErrors = false;
    let refresh = false;

    console.log('data = ', data);
    actions.resetErrorStatus();
    actions.setLoading(true);
    actions.setSavingSettings(true);

    const { account, settings: settingsData, catalogSettingsIsForceUsing, catalogSettingsSelectedTemplates, pimProfileSettings, standardTemplates, sourceCatalogSettings, sourceStandardTemplates } = data;
    const dcMappings = settingsData?.ScheduleSetting?.InventorySettings?.DistributionCenterWarehouseMappings || {};
    const inventoryChecked = settingsData?.ScheduleSetting?.InventorySettings?.Update

    if (!creating && inventoryChecked && Object.values(dcMappings).filter((dc: any) => dc.IsSelected === 1).length === 0) {
      notification.error({ message: 'You must assign warehouse for this channel.' });
      hasErrors = true;
      actions.setEditMode(true);
      actions.setSavingSettings(false);
      actions.setLoading(false);
      return;
    }

    const saveAccount = form.isFieldsTouched(['account']);
    const saveSettings = form.isFieldsTouched(['settings']);
    // const saveCatalogSettings = form.isFieldsTouched(['catalogSettings']);
    // const saveStandardTemplates = form.isFieldsTouched(['standardTemplates']);

    if (saveAccount) {
      try {
        const err = await saveConnectionData(fields1, account, dispatch, history, channel, channelAccountNum, currentProfile);

        if (err) {
          actions.setEditMode(true);
          actions.setSavingSettings(false);
          actions.setLoading(false);
          console.log('saving...');
          return
        } else {
          console.log('...', err);
          refresh = true;
          setActiveKey('settings');
        }
      } catch (e) {
        notification.error({ message: 'There was an error processing the Basic Info settings, please try again later.' });
        hasErrors = true;
      }

    }

    if (saveSettings && settings) {
      try {
        await saveSettingsData(settings, settingsData, dispatch, form);
        console.log('setting...');
      } catch (e) {
        notification.error({ message: 'There was an error processing the Scheduling settings, please try again later' });
        hasErrors = true;
      }
    }

    if (settings && sourceCatalogSettings && (typeof catalogSettingsSelectedTemplates !== 'undefined' || typeof catalogSettingsIsForceUsing !== 'undefined')) {
      try {
        const ps = {
          templates: typeof catalogSettingsSelectedTemplates === 'undefined' ? sourceCatalogSettings.selectedTemplates : catalogSettingsSelectedTemplates,
          isForceUsing: typeof catalogSettingsIsForceUsing === 'undefined' ? sourceCatalogSettings.isForceUsing : catalogSettingsIsForceUsing

        }
        // await updateCatalogSetting(settings.ChannelAccountSettingNum, catalogSettings)
        await saveSelectedTemplates(channelAccountNum || '', channelNum || '', ps)
        console.log('saving1...');
        // if (catalogSettings.assignedFeedTemplate && catalogSettings.assignedFeedTemplate !== sourceCatalogSettings.assignedFeedTemplate) {
        //   await saveSyncTemplate(channelAccountNum || '', channelNum || '', catalogSettings.assignedFeedTemplate);
        // }
        form.setFieldsValue({ sourceCatalogSettings: { selectedTemplates: ps.templates, isForceUsing: ps.isForceUsing } })
      } catch (error) {
        console.log(error)
      }
    }

    if (pimProfileSettings && settings) {
      const postParams = [];
      for (let i in pimProfileSettings) {
        const tmp = i.split('_$_$_');
        if (tmp.length === 2) {
          postParams.push({
            SettingCode: tmp[1],
            SettingValue: pimProfileSettings[i],
          });
        }
      }
      try {
        await saveProfileSettings(postParams);
        console.log('saving2...');
      } catch (error) {
        console.log(error);
      }
    }

    if (standardTemplates && settings && sourceStandardTemplates && (JSON.stringify(standardTemplates) !== JSON.stringify(sourceStandardTemplates))) {
      try {
        await saveSelectedStandardTemplates(channelAccountNum || '', channelNum || '', standardTemplates.selectedStandardTemplates)
        console.log('saving3...');
        form.setFieldsValue({ sourceStandardTemplates: { ...standardTemplates } })
      } catch (error) {
        console.log(error);
      }
    }

    actions.setSavingSettings(false);
    actions.setLoading(false);

    if (!hasErrors) {
      actions.setEditMode(false);
    }

    if (refresh) {
      window.location.reload();
    }
  }, [actions, fields1, dispatch, history, channel, channelNum, channelAccountNum, currentProfile, form, settings, creating]);

  const processing = loadingInitialData || checkingVerification;

  const tabs = channelAccountNum ? (
    <>
      <Tabs.TabPane
        tab={(
          <Badge dot={formHasErrors}>
            Basic Settings
          </Badge>
        )}
        key="settings"
        forceRender
      >
        <ConnectionForm fields={fields} onCopyValue={onCopyValue} isETSY={isETSY} handleLogin={() => handleLogin('edit')} />
      </Tabs.TabPane>
      {/* <Tabs.TabPane
        tab="Profile Settings"
        key="profileSettings"
        forceRender={false}
      >
        <ProfileSettingTab />
      </Tabs.TabPane> */}
      {categoryVersion === 'General-01' &&<Tabs.TabPane
        tab="Channel Category"
        key="channelCategory"
        forceRender={false}
      >
        <ChannelCategory channelAccountNum={channelAccountNum} channelNum={channelNum || ''}  channelName={channel?.channelName || ''}/>
      </Tabs.TabPane>}
      <Tabs.TabPane
        tab="Integration Templates"
        key="Integration Template"
        forceRender={false}
      >
        {activeKey === 'Integration Template' && channelVersion === '' && <IntegrationTemplate channelAccountNum={channelAccountNum} channelNum={channelNum || ''} platformNum={platformNum || ''} />}
        {activeKey === 'Integration Template' && channelVersion === VERSION2 && <IntegrationTemplate02 channelAccountNum={channelAccountNum} channelNum={channelNum || ''} platformNum={platformNum || ''} />}
      </Tabs.TabPane>
      {/*
      <Tabs.TabPane
        tab="Channel Attributes"
        key="catalog"
        forceRender={false}
      >
        {activeKey === 'catalog' && <CatalogTab channelAccountNum={channelAccountNum} channelNum={channelNum || ''} platformNum={platformNum || ''} />}
      </Tabs.TabPane>*/}
      <Tabs.TabPane
        tab={(
          <Badge dot={settingsFormHasErrors}>
            Advanced Settings
          </Badge>
        )}
        key="scheduling"
        forceRender
      >
        <SchedulingTab />
      </Tabs.TabPane>
      {/* <Tabs.TabPane
        tab="Custom Template"
        key="custom"
        forceRender
      >
        <CustomTab channelAccountNum={channelAccountNum} channelNum={channelNum || ''} platformNum={platformNum || ''}/>
      </Tabs.TabPane>
      <Tabs.TabPane
        tab="Standard Template"
        key="standard"
        forceRender
      >
        <StandardTemplate channelAccountNum={channelAccountNum} channelNum={channelNum || ''} platformNum={platformNum || ''}/>
      </Tabs.TabPane> */}
    </>
  ) : (
    <Tabs.TabPane tab="Create Connection" key="connection">
      <ConnectionForm fields={fields1} onCopyValue={onCopyValue} isETSY={isETSY} handleLogin={() => handleLogin('create')} />
    </Tabs.TabPane>
  );

  useEffect(() => {
    if (!inited) {
      loadSettings();
      setInited(true);
    }
  }, [inited, loadSettings]);

  return (
    <FormsContext.Provider value={form}>
      <ContentLayout>
        <Header />
        <Spin spinning={processing} indicator={<Loading size={LOADING_ICON_SIZE1} />}>
          <Spacer />
          <SiteContent>

            <Form
              form={form}
              labelCol={{
                sm: 12,
                lg: 8,
                xl: 8,
              }}
              wrapperCol={{
                sm: 12,
                lg: 16,
                xl: 16,
              }}
              initialValues={{ account: { ChannelIntegrations: Number(channelNum) || Number(platformNum) } }}
              onFinish={submit}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormChanged}
            >
              <Tabs
                tabBarExtraContent={<TabBarButtons />}
                onChange={(key) => setActiveKey(key)}
                activeKey={activeKey}
              >
                {tabs}
              </Tabs>
            </Form>
          </SiteContent>
        </Spin>
      </ContentLayout>
    </FormsContext.Provider>
  );
};

export default ChannelIntegrationScreen;
