import {  digitBridgeApi, performanceReportAPI } from './http';

type AddControlTowerGroupParams = {
  ControlTowerGroupName: string;
	ControlTowerGroupDescription: string;
	DisplaySequence: number,
	Columns: number
};

type AddControlTowerParams = {
  originalName: string;
  displayName: string;
  dataType: number;
  attributeId: string;
  source: string;
  editable: number;
  displaySequence: number;
  rows: number;
  columns: number;
  note: string;
  groupId: number;
};

export const addControlTowerSubGroup = async (
  id: number,
  params: any,
): Promise<any> => {
  const body = JSON.stringify(params);

  return digitBridgeApi.post(`/v1/controlTowerSubGroup/${id}`, body);
};

export const fetchControlTowerCfgHideField = async (): Promise<any> => digitBridgeApi.get('/v1/controlTowerSetting/HideFields');

export const fetchControlTowerCfgUrc = async (): Promise<any> => digitBridgeApi.get('/v1/controlTowerSetting/UserRptCode');

export const fetchControlTowerGroups = async (): Promise<any> => digitBridgeApi.get('/v1/controlTowerGroups');

export const fetchControlTowerGroupsAndFields = async (): Promise<any> => digitBridgeApi.get('/v1/controlTowerGroups/children');

export const fetchControlTowerGroupById = async (rowNum:number): Promise<any> => digitBridgeApi.get(`/v1/controlTowerGroup/${rowNum}`);

export const fetchControlTowerMappingBySubGroupNum = async (rowNum:number): Promise<any> => digitBridgeApi.get(`/v1/controlTowerSubGroup/${rowNum}/subrelation`);

export const fetchControlTowerSubGroupById = async (rowNum:number): Promise<any> => digitBridgeApi.get(`/v1/controlTowerSubGroups/${rowNum}`);

export const fetchControlTowerByProductNum = async (id: number): Promise<any> => digitBridgeApi.get(`/v1/controlTowers/detail/${id}`);

export const fetchControlTowerListExtraColumnData = async (params: any, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(params);

  return digitBridgeApi.post('/v1/products/controlTower/lazyLoading', body, {
    cancelToken,
  });
};

export const addControlTowerGroup = async (params: AddControlTowerGroupParams): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.post('/v1/controlTowerGroup', json);
};

export const editControlTowerGroup = async (rowNum: number, params: AddControlTowerGroupParams): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.patch(`/v1/controlTowerGroup/${rowNum}`, json);
};

export const editControlTowerNodes = async (params: StringKAnyVPair[]): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.patch('/v1/controlTowerGroups/children', json);
};

export const upControlTowerGroupWeight = async (rowNum: number): Promise<any> => digitBridgeApi.patch(`/v1/controlTowerGroup/${rowNum}/assign/up`);

export const downControlTowerGroupWeight = async (rowNum: number): Promise<any> => digitBridgeApi.patch(`/v1/controlTowerGroup/${rowNum}/assign/down`);

export const deleteControlTowerGroup = async (rowNum: number): Promise<any> => digitBridgeApi.delete(`/v1/controlTowerGroup/${rowNum}`);

export const deleteControlTowerSubGroup = async (rowNum: number): Promise<any> => digitBridgeApi.delete(`/v1/controlTowerSubGroup/${rowNum}`);

export const addControlTower = async (params: AddControlTowerParams): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.post('/v1/controlTowerElement', json);
};

export const editControlTower = async (rowNum: number, params: AddControlTowerParams): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.patch(`/v1/controlTowerElement/${rowNum}`, json);
};

export const editControlTowerValues = async (
  productId: string,
  data: any,
  params?: StringKAnyVPair,
): Promise<any> => {
  const json = JSON.stringify(data);

  return digitBridgeApi.patch(`/v1/product/${productId}/attributes`, json, { params });
};

type FetchControlTowersParams = {
  groupId?: string;
  keyword?: string;
};

export const fetchControlTowers = async (params: FetchControlTowersParams): Promise<any> => digitBridgeApi.get('/v1/controlTowerElements', { params: { groupId: params.groupId || -1, keyword: params.keyword || '' } });

export const fetchControlTowerById = async (rowNum: number): Promise<any> => digitBridgeApi.get(`/v1/controlTowerElements/${rowNum}`);

export const deleteControlTower = async (rowNum: number): Promise<any> => digitBridgeApi.delete(`/v1/controlTowerElements/${rowNum}`);

export const deleteControlTower2 = async (rowNum: number): Promise<any> => digitBridgeApi.delete(`/v1/controlTowerElement/${rowNum}`);

export const fetchControlTowerAttribute =  async (): Promise<any> => digitBridgeApi.get('/v1/controlTower/availableAttributes');

export const fetchSalesPerformance = async (
  params: StringKAnyVPair,
): Promise<any> => {
  const res: StringKAnyVPair = await performanceReportAPI.post('/api/summaryPerformanceReport/getChannelSummaryDetail', { ...params });

  if (res && typeof res === 'object' && res.code === 200) {
    if (res.data && typeof res.data === 'object') {
      return res.data;
    }
  }
};

export const fetchChannelFlagSalesPerformanceSummaryList = async (
  params: StringKAnyVPair,
): Promise<any> => {
  const res: StringKAnyVPair = await performanceReportAPI.post('/api/summaryPerformanceReport/getChannelAccountSummaryDetail', params);

  if (res && typeof res === 'object' && res.code === 200) {
    if (Array.isArray(res.data)) {
      return res.data;
    }
  }

  return res;
};

export const fetchSalesPerformanceSummaryList = async (
  params: StringKAnyVPair[],
): Promise<any> => {
  const res: StringKAnyVPair = await performanceReportAPI.post('/api/summaryPerformanceReport/getProfileSummaryDetail', [ ...params ]);

  if (res && typeof res === 'object' && res.code === 200) {
    if (res.data && typeof res.data === 'object') {
      return res.data;
    }
  }
};

export const saveControlTowerChannelDataMapping = async (
  id: number,
  params: any,
): Promise<any> => {
  const body = JSON.stringify(params);

  return digitBridgeApi.patch(`/v1/controlTowerSubRelation/${id}/bulkSave`, body);
};

export const setControlTowerCfgHideField = async (
  cfg: number,
): Promise<any> => digitBridgeApi.patch(`/v1/controlTowerSetting/HideFields/${cfg}`);

export const setControlTowerCfgUrc = async (
  urc: number,
): Promise<any> => digitBridgeApi.patch(`/v1/controlTowerSetting/UserRptCode/${urc}`);

export const updateControlTowerSubGroups = async (
  id: number,
  params: any,
): Promise<any> => {
  const body = JSON.stringify(params);

  return digitBridgeApi.patch(`/v1/controlTowerSubGroups/${id}`, body);
};
