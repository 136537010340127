import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import {
  fetchControlTowerGroups,
  editControlTower,
  addControlTower,
} from "../../../services/controlTower";

type AddEditDialogProps = {
  mode: "New" | "Edit";
  visible: boolean;
  field: any;
  onRefresh: Function;
  onClose: Function;
};

const AddEditDialog = (props: AddEditDialogProps) => {
  const { mode = "New", field, visible, onClose, onRefresh } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [groupList, setGroupList] = useState<any[]>([]);

  const [form] = Form.useForm();

  const saveField = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const res =
        mode === "New"
          ? await addControlTower({
              ...params,
              editable: params.editable ? 1 : 0,
              attributeId: field.attributeId,
              isDisplayInSearch: params.isDisplayInSearch ? 1 : 0,
              Origin: field.origin,
            })
          : await editControlTower(field.rowNum, {
              ...params,
              editable: params.editable ? 1 : 0,
              attributeId: field.attributeId,
              isDisplayInSearch: params.isDisplayInSearch ? 1 : 0,
              Origin: field.origin
            });
      setLoading(false);
      if (res) {
        message.success("save successfully");
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
    // setLoading(true);
  };

  const getDefaultDisplayName = (field: StringKAnyVPair) => {
    const source = typeof field.source === 'undefined' ? '' : `${field.source} `;

    return typeof field.displayName === 'undefined' ? `${source}${field.originalName}` : field.displayName;
  };

  const getDefaultSearchHeaderName = (field: StringKAnyVPair) => {
    //const source = typeof field.source === 'undefined' ? '' : `${field.source} `;
    const secondPart = typeof field.displayName === 'undefined' ? field.originalName : field.displayName;

    //return typeof field.searchHeaderName === 'undefined' ? `${source}${secondPart}` : field.searchHeaderName;
    return typeof field.searchHeaderName === 'undefined' ? `${secondPart}` : field.searchHeaderName;
  };

  const getGroupList = useCallback(async () => {
    try {
      setLoadingGroup(true);
      const res = await fetchControlTowerGroups();
      setLoadingGroup(false);
      if (res) {
        setGroupList(res);
        if(mode==='New'){
          const temp = res.filter((i:any)=> i.controlTowerGroupName === 'General' && i.isDefault);
          if(temp.length > 0){
            form.setFieldsValue({
              groupId: temp[0].rowNum
            })
          }
        }
      }
    } catch (error) {
      setLoadingGroup(false);
    }
  }, [form, mode]);

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={`${mode} Control Tower Field - ${field.originalName}`}
      visible={visible}
      centered
      onCancel={() => onClose()}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} requiredMark={false}>
          <Form.Item
            label="Origin Name"
            name="originalName"
            initialValue={field.originalName}
            rules={[{ required: true, message: "Original name is required" }]}
          >
            <Input disabled={true} placeholder="Please input origin name" />
          </Form.Item>

          <Form.Item
            label="Display Name"
            name="displayName"
            //initialValue={field.displayName}
            initialValue={typeof field.displayName === 'undefined' ? getDefaultDisplayName(field) : field.displayName}
            rules={[{ required: true, message: "Display name is required" }]}
          >
            <Input placeholder="Please input display name" />
          </Form.Item>

          <Form.Item
            label="Search Header Name"
            name="searchHeaderName"
            //initialValue={field.displayName}
            initialValue={typeof field.searchHeaderName === 'undefined' ? getDefaultSearchHeaderName(field) : field.searchHeaderName}
            rules={[{ required: true, message: "Search header name is required" }]}
          >
            <Input placeholder="Please input search header name" />
          </Form.Item>

          <Form.Item
            label="Data type"
            name="dataType"
            initialValue={field.dataType}
            rules={[{ required: true, message: "Data type is required" }]}
          >
            <Select style={{ width: "100%" }} disabled={true}>
              <Select.Option value={1}>String</Select.Option>
              <Select.Option value={2}>Integer</Select.Option>
              <Select.Option value={3}>Decimal</Select.Option>
              <Select.Option value={4}>DateTime</Select.Option>
              <Select.Option value={5}>ImageUrl</Select.Option>
              <Select.Option value={6}>Price</Select.Option>
              <Select.Option value={7}>ImageUrlList</Select.Option>
              <Select.Option value={8}>VideoUrl</Select.Option>
              <Select.Option value={9}>Virtual</Select.Option>
              <Select.Option value={10}>Metafield</Select.Option>
              <Select.Option value={11}>PageUrl</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Source"
            name="source"
            initialValue={field.source}
            rules={[{ required: true, message: "Source is required" }]}
          >
            <Input disabled={true} placeholder="Please input source" />
          </Form.Item>

          <Form.Item
            label="Group"
            name="groupId"
            initialValue={field.groupId}
            rules={[{ required: true, message: "Group is required" }]}
          >
            <Select loading={loadingGroup}>
              {groupList.map((item: any) => (
                <Select.Option key={item.rowNum} value={item.rowNum}>
                  {item.controlTowerGroupName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Editable"
            name="editable"
            initialValue={field.editable}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>

          <Form.Item
            label="Display in Search"
            name="isDisplayInSearch"
            initialValue={field.isDisplayInSearch}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>

          <Form.Item
            label="Sequence"
            name="displaySequence"
            //initialValue={field.displaySequence}
            initialValue={typeof field.displaySequence === 'undefined' ? 1 : field.displaySequence}
            rules={[{ required: true, message: "Sequence is required" }]}
          >
            <Input type="number" min={0} placeholder="Please input Sequence" />
          </Form.Item>

          <Form.Item
            label="Rows"
            name="rows"
            //initialValue={field.rows}
            initialValue={typeof field.rows === 'undefined' ? 1 : field.rows}
            rules={[{ required: true, message: "Rows is required" }]}
          >
            <Input type="number" min={0} placeholder="Please input Rows" />
          </Form.Item>

          <Form.Item
            label="Column"
            name="columns"
            //initialValue={field.columns}
            initialValue={typeof field.columns === 'undefined' ? 100 : field.columns}
            rules={[{ required: true, message: "Columns is required" }]}
          >
            <Input addonAfter="%" type="number" min={0} placeholder="Please input Columns" />
          </Form.Item>

          <Form.Item
            label="Note"
            name="note"
            initialValue={field.note}
            // rules={[{ required: true, message: "Note is required" }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
        <Row justify="center">
          <Col span={24}>
            <Row style={{ width: "100%" }} justify="end">
              <Button type="primary" onClick={saveField}>
                Save
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default AddEditDialog;
