import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Input, Checkbox, Form } from "antd";
import { DraggableItem, ErrorMessage, SubtitleHeader } from "../AttributeDetail/styledComponents";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Drag from '../../assets/icons/drag';

type OptionListDialogProps = {
  data: any;
  visible: boolean;
  optionList: any[];
  selOptions: any[];
  onVisibleChange: Function;
  selOptionsChange: Function;
  optionListChange: Function;
}
export const OptionListDialog = (props: OptionListDialogProps) => {
  const { data = {}, visible, onVisibleChange, optionList, selOptions, selOptionsChange, optionListChange } = props;
  const [unselOptions, setUnselOptions] = useState<any[]>([]);
  const [selOptionsTemp, setSelOptionsTemp] = useState<any[]>([...selOptions]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (selOptions.length > 0) {
      setUnselOptions(optionList.filter(opt => selOptions.findIndex(o => o.optionValue === opt.optionValue) === -1));
    } else {
      setUnselOptions([...optionList])
    }
  }, [optionList, selOptions])

  const SortableItem = SortableElement(({ value }: any) => (
    <DraggableItem>
      <Checkbox checked onChange={e => moveOption(value, 'removing', e)}>
        {value}
      </Checkbox>
      <Drag height={24} width={24} />
    </DraggableItem>
  ));

  const SortableList = SortableContainer(({ items }: any) => (
    <ul style={{ padding: '0' }}>
      {items.map((value: any, index: number) => (
        <SortableItem key={`item-${value.optionValue}`} index={index} value={value.optionValue} />
      ))}
    </ul>
  ));

  const moveOption = (value: string, action: string, e: any) => {
    e.stopPropagation();
    if (action === 'removing') {
      setUnselOptions([...unselOptions, { optionValue: value }]);
      setSelOptionsTemp(selOptionsTemp.filter(opt => opt.optionValue !== value))
    }
    if (action === 'adding') {
      setUnselOptions(unselOptions.filter(opt => opt.optionValue !== value));
      setSelOptionsTemp([...selOptionsTemp, { optionValue: value }])
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    // console.log('oldIndex= ', oldIndex);
    // console.log('neWiNDEX = ', newIndex);
  };

  const searchOptions = (e: any) => {
    setSearchInput(e.target.value);
  }

  const onAddNewOption = (e: any) => {
    const { value } = e.target;
    if (!value) return;
    const selected = selOptionsTemp.findIndex(
      (so: any) => so.optionValue === e.target.value,
    );
    const unselected = unselOptions.findIndex(
      (so: any) => so.optionValue === e.target.value,
    );
    if (unselected > -1 || selected > -1) {
      // @ts-ignore
      document.getElementById('addOptionError').style.display = 'block';
    } else {
      setSelOptionsTemp([...selOptionsTemp, { optionValue: value }])
      // @ts-ignore
      document.getElementById('addOptionError').style.display = 'none';
    }
    form.setFieldsValue({ NewOption: '' })
  };

  const handleSave = () => {
    console.log('aaa = ', [...selOptionsTemp, ...unselOptions]);
    optionListChange([...selOptionsTemp, ...unselOptions]);
    console.log('bbb = ', selOptionsTemp);
    selOptionsChange(selOptionsTemp);
    console.log('ccc');
    onVisibleChange(false);
  }

  return (
    <Modal
      // title={`Add/Update List Options for Content Resource - ${data.elementName}`}
      title={data.elementName ? `Update List Options for Content Resource - ${data.elementName}` : 'Add List Options for Content Resource'}
      maskClosable={false}
      visible={visible}
      okText="Save"
      onCancel={() => onVisibleChange(false)}
      onOk={handleSave}
      width={600}
    >
      <Row>
        <Col span={24}>
          <Input.Search
            type="text"
            style={{ width: '100%', marginBottom: '15px' }}
            value={searchInput}
            placeholder="Search options"
            onChange={searchOptions}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Options Selected</SubtitleHeader>
          <SortableList
            items={selOptionsTemp.filter(opt => opt.optionValue.toLowerCase().includes(searchInput.toLowerCase()))}
            onSortEnd={onSortEnd}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Unselected Options</SubtitleHeader>
          {unselOptions.filter(opt => opt.optionValue.toLowerCase().includes(searchInput.toLowerCase())).map((uo: any) => (
            <DraggableItem key={uo.optionValue}>
              <Checkbox checked={false} onChange={e => moveOption(uo.optionValue, 'adding', e)}>
                {uo.optionValue}
              </Checkbox>
              <Drag height={24} width={24} />
            </DraggableItem>
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Add New Options</SubtitleHeader>
          <ErrorMessage id="addOptionError">This option already exists!</ErrorMessage>
          <Form form={form}>
            <Form.Item name="NewOption">
              <Input
                type="text"
                style={{ width: '100%' }}
                name="NewOption"
                placeholder="To Add Type New Option and Hit Enter"
                onPressEnter={onAddNewOption}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default OptionListDialog;