import React from 'react';
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';

import { Action, StoreContext } from './model';

type Props = {
};

const FeatStruct = (props: Props) => {
  const { useContext, useState } = React;
  const { state, dispatch } = useContext(StoreContext) as any;
  const [draggedNode, setDraggedNode] = useState<StringKAnyVPair>({});
  //const [expandedKeys] = useState<string[]>([]);

  const allowDrop = (drop: any) => {
    const { dropNode } = drop;
    //console.log('d i', drop);
    if (dropNode && typeof dropNode === 'object') {
      let ret = (!Array.isArray(dropNode.children)) && (!Array.isArray(draggedNode.children)) && dropNode.parentKey && dropNode.parentKey === draggedNode.parentKey;

      if (!ret) {
        //console.log('d i', dropNode, draggedNode);
        ret = dropNode.key === draggedNode.parentKey;
        //console.log('ret', ret);
        if (!ret) {
          ret = Array.isArray(dropNode.children) && Array.isArray(draggedNode.children);
        }
      }

      return ret;
    }

    return false;
  };

  const appendNodeToList = (
    list: StringKAnyVPair[],
    ref: StringKAnyVPair,
    target: StringKAnyVPair,
  ) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].key === ref.key) {
        list.splice(i + 1, 0, target);
        break;
      }
    }
  };

  const onClickStructPanel = () => {
    //setSelectedKeys([]);
    dispatch({type: Action.SetCurrentItem, payload: {}});
  };

  const onClickTree = (evt: any) => {
    evt.stopPropagation();
  };

  const onDragEnter: TreeProps['onDragEnter'] = (info) => {
  };

  const onDragStart: TreeProps['onDragStart'] = (info) => {
    const { node } = info;
    console.log('i', node.key);
    setDraggedNode(node);
    // expandedKeys, set it when controlled is needed
    // setExpandedKeys(info.expandedKeys)
  };

  const onDrop: TreeProps['onDrop'] = (info) => {
    console.log('d', info);
    const data = [...state.towers];
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let dragObj: DataNode;
    let dragged = false;

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }

        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    // Find dragObject
    loop(data, dragKey, (item, index, arr) => {
      // the node was removed from the array
      arr.splice(index, 1);
      dragObj = item;
      //console.log('drag obj->', dragObj);
    });

    console.log('info', info.dropToGap, info);
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        if (dragObj) {
          //console.log('dd obj', dropKey, dragObj);
          if (Array.isArray(dragObj.children)) {
            appendNodeToList(data, item, dragObj);
          } else {
            item.children = item.children || [];
            // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
            item.children.unshift(dragObj);
          }
        }
      });
      dragged = true;
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
      dragged = true;
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
      dragged = true;
    }

    if (dragged) {
      const changedNodes = [ ...state.changedNodes ];

      if (Array.isArray(draggedNode.children)) {
        for (let i = 0; i < data.length; i++) {
          data[i].displaySequence = i + 1;

          if (changedNodes.indexOf(data[i].key) < 0) {
            changedNodes.push(data[i].key);
          }
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          const n = data[i];

          if (n.key === draggedNode.parentKey) {
            if (changedNodes.indexOf(n.key) < 0) {
              changedNodes.push(n.key);
            }

            // eslint-disable-next-line
            n.children.forEach((e: StringKAnyVPair, i: number) => {
              if (changedNodes.indexOf(e.key) < 0) changedNodes.push(e.key);

              e.displaySequence = i + 1;
            });
            break;
          }
        }
      }

      dispatch({type: Action.SetTowers, payload: data});
      dispatch({type: Action.SetChangedNodes, payload: changedNodes});
    }

    setDraggedNode({});
  };

  const onExpand = (keys: any[]) => {
    //console.log('oe', keys);
    dispatch({type: Action.SetExpandedKeys, payload: keys});
  };

  const onSelect = (sKeys: any[], evt: any) => {
    //console.log('s->', sKeys, evt);
    //setSelectedKeys(sKeys);
    dispatch({type: Action.SetCurrentItem, payload: sKeys.length > 0 ? evt.node : {}});
  };

  return (<>
    <div onClick={onClickStructPanel} style={{height: '100%'}}>
      <Tree
        allowDrop={allowDrop}
        className="draggable-tree"
        //defaultExpandedKeys={expandedKeys}
        draggable
        blockNode
        expandedKeys={state.expandedKeys}
        onClick={onClickTree}
        onDragEnter={onDragEnter}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onExpand={onExpand}
        onSelect={onSelect}
        selectedKeys={state.currentItem.key ? [state.currentItem.key] : []}
        treeData={state.towers}
        //treeData={props.data}
      />
    </div>
  </>);
};

export default FeatStruct;
