import { PlusOutlined } from '@ant-design/icons';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import { Button, Col, Dropdown, Image, Menu, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { Permissions, ViewLevel } from '../../constants/enums/permissions';
import hasPermission from '../../util/permissions';
import { useSimpleProductsQuery } from '../../redux/api/products';

const SKULink: React.FC<{ product: Entities.ProductProfile }> = ({ product }) =>
  (
    <Link to={`/product-detail/${product.ProductId}`}>
      {product.SKU}
    </Link>
  );

const NewProductLink: React.FC<{ type: string, productType: number, bundleType: number }> = ({ type, productType, bundleType }) =>
  (
    <Link to={`/new-product/${productType}/${bundleType}`}>
      {type}
    </Link>
  );

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const columns = [
  {
    name: 'MediaURL',
    header: 'Image',
    defaultFlex: 1,
    minWidth: 80,
    sortable: false,
    render({ value, data }: { value: string, data: Entities.ProductProfile }) {
      const src = value || 'https://via.placeholder.com/300';

      return (
        <ImageContainer key={data.ProductId}>
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: 'SKU',
    header: 'SKU',
    defaultFlex: 2,
    minWidth: 200,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  { name: 'ProductTitle', header: 'Title', defaultFlex: 2, minWidth: 200, },
  {
    name: 'Brand',
    header: 'Brand',
    defaultFlex: 1,
    minWidth: 150,
  },
  { name: 'Type', header: 'Type', defaultFlex: 1, minWidth: 100, },
  {
    name: 'Classification',
    header: 'Classification',
    defaultFlex: 1,
    minWidth: 140,
  },
  { name: 'Labels', header: 'Labels', defaultFlex: 1, minWidth: 130,},
  { name: 'UPC', header: 'UPC', defaultFlex: 1, minWidth: 150, },
  {
    name: 'QtyTotal',
    header: 'Quantity',
    defaultFlex: 1,
    filterEditor: NumberFilter,
    minWidth: 110,
  },
];

const searchFields: Array<SearchField<Entities.ProductProfile> | string> = [
  'SKU',
  'ProductTitle',
  'Type',
  'Classification',
  'Brand',
  'QtyTotal',
];

const csvColumns: CSVColumns = [
  { key: 'SKU', header: 'SKU' },
  { key: 'ProductTitle', header: 'Title' },
  { key: 'Type', header: 'Type' },
  { key: 'Brand', header: 'Brand' },
  { key: 'Classification', header: 'Classification' },
  { key: 'QtyTotal', header: 'Quantity' },
];

const initialProductListing = {
  ProductTotalCount: 0,
  ProductList: [],
};

const PageContent: React.FC = () => {
  const profiles = useSelector((state: any) => state.profiles);
  const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: Entities.ProductProfile } | boolean>({});
  const [filteredData, setFilteredData] = useState<Entities.ProductProfile[]>([]);
  const csvData: Entities.ProductProfile[] = useMemo(() => {
    if (typeof selectedRows !== 'boolean') {
      if (Object.keys(selectedRows).length > 0) {
        return Object.keys(selectedRows).map(k => selectedRows[k]);
      }
    }

    return filteredData;
  }, [selectedRows, filteredData]);

  const onFilterValueChange = (val: any) => {
    console.log('vv->', val);
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelectedRows(selected);
  }, []);

  const actions = useMemo(() => {
    if (!hasPermission(profiles.permissions, Permissions.MANAGE_PRODUCT_ELEMENTS, ViewLevel.VIEW)) {
      return undefined;
    }

    return (
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item>
              <NewProductLink type="Standard" productType={0} bundleType={0} />
            </Menu.Item>
            <Menu.Item>
              <NewProductLink type="Style Master" productType={2} bundleType={0} />
            </Menu.Item>
            <Menu.Item>
              <NewProductLink type="Bundle" productType={0} bundleType={1} />
            </Menu.Item>
          </Menu>
        )}
        placement="bottomCenter"
        arrow
      >
        <Button type="primary">
          Add
          <PlusOutlined />
        </Button>
      </Dropdown>
    );
  }, [profiles]);

  return (
    <ContentLayout>
      <Heading
        title="Products"
        actions={actions}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row justify="space-between">
          <Col xs={24} lg={12}>
            <SearchBar
              data={data.ProductList}
              fields={searchFields}
              reference="SKU"
              onResult={setFilteredData}
            />
          </Col>
          <Col>
            <CSVLink
              filename="products.csv"
              data={csvData}
              columns={csvColumns}
            />
          </Col>
        </Row>
        <Spacer height={14} />
        <DataGrid
          idProperty="ProductId"
          rowHeight={35}
          columns={columns}
          dataSource={filteredData}
          loading={isFetching}
          onFilterValueChange={onFilterValueChange}
          onSelectionChange={onSelectionChange}
          pagination
          selected={selectedRows}
        />
      </SiteContent>
    </ContentLayout>
  );
};

export default PageContent;
