import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type SiteContentProps = {
  flexGrow?: boolean;
  noPadding?: boolean;
  transparent?: boolean;
};

const SiteContent = styled.div<SiteContentProps>`
  display: flex;
  flex-direction: column;
  flex: ${ p => p.flexGrow ? 1 : 0 };
  padding: ${ p => p.noPadding ? '' : '14px' };
  text-align: left;
  background: ${ p => p.transparent ? 'rgba(255,255,255,0)' : '#fff' };
  border-radius: ${theme['@border-radius-base']};

  &.common-fixed-box {
    height: calc(100vh - 132px);
  }

  & .content-section {
    background-color: #FFF;
    border-radius: ${theme['@border-radius-base']};
    padding: 14px;
  }
`;

export default SiteContent;
