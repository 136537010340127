import React, { useEffect, useState } from 'react';
import { Card, Form, Typography, Row, Col, Button, message, Select, Radio } from 'antd';
import ContentLayout from '../components/ContentLayout';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import SiteContent from '../components/SiteContent';
import { fetchChannelListWithReportCode, getDashboardSettingType, updateDashboardSettingType } from '../services/channels';
import { SaveOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title } = Typography;

const RadioRow = styled(Radio)`
  margin-bottom: 8px;
  display: block;
`;

// eslint-disable-next-line
export default () => {
  const [settingType, setSettingType] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [channels, setChannels] = useState<any[]>([]);
  const [channelOpt, setChannelOpt] = useState<any[]>([]);
  const [tempParams, setTempParams] = useState<any>();
  const [form] = Form.useForm();


  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await getDashboardSettingType();
      const { settingValue = '' } = res;
      if (settingValue) {
        const { SettingType = 1, ChannelDropShipItems = [] } = JSON.parse(settingValue);
        const channelValue = ChannelDropShipItems.map((item: any) => item.ChannelAccountNum);

        form.setFieldsValue({ SettingType, ChannelDropShipItems: channelValue });

        setTempParams(res);
        setSettingType(SettingType);
      } else {
        form.setFieldsValue({ SettingType: 1, ChannelDropShipItems: [] });
        setTempParams(res);
        setSettingType(1);
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  };

  const getChannelList = async () => {
    setLoading(true);
    // eslint-disable-next-line
    const channels = await fetchChannelListWithReportCode();
    if (Array.isArray(channels)) {
      const temp = channels.map(channel => {
        const { channelAccountName, channelAccountNum } = channel;
        return { label: channelAccountName, value: channelAccountNum };
      });
      setChannels(channels);
      setChannelOpt(temp);
      getSettings();
    }
    /*try {
    } catch (error) {
      console.log('error = ', error);
    }*/
  };

  const saveSettings = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setSaveLoading(true);
    try {
      const ChannelDropShipItems = channels.filter(channel => (params.ChannelDropShipItems || []).findIndex((item: number) => item === channel.channelAccountNum) > -1).map(item => {
        return { ChannelAccountNum: item.channelAccountNum, ReportCode: item.reportCode };
      });
      const settingValue = JSON.stringify({
        SettingType: params.SettingType,
        ChannelDropShipItems,
      });
      const queryParams = {
        ...tempParams,
        settingValue,
      };
      console.log(queryParams);

      const res = await updateDashboardSettingType(queryParams);
      if (res) {
        message.success({ content: 'Saved Successfully' });
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setSaveLoading(false);
    }

  };

  /* eslint-disable */
  useEffect(() => {
    getChannelList();
  }, []);
  /* eslint-enable */
  return (
    <ContentLayout>
      <Heading
        title="Dashboard Settings"
        actions={(
          <Row gutter={12}>
            <Col>
              <Button
                type="primary"
                loading={saveLoading}
                onClick={() => saveSettings()}
              >
                <SaveOutlined />
                Save Settings
              </Button>
            </Col>
          </Row>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        {channelOpt && (
          <Form
            form={form}
            labelCol={{
              style: { width: 150, display: 'flex', justifyContent: 'start' },
            }}
            wrapperCol={{
              style: { width: 'calc(100% - 300px)' },
            }}
          >
            <Card
              style={{ width: '100%', marginTop: 16, minHeight: 500 }}
              loading={loading}
              bodyStyle={{ padding: 8 }}
            >
              <Card
                size="small"
                bordered={false}
                style={{ marginBottom: 10 }}
                title={<Title level={5}>Channels</Title>}
              >
                <div>
                  <Form.Item
                    name="SettingType"
                    label="Setting Type"
                  >
                    <Radio.Group
                      onChange={e => {
                        setSettingType(e.target.value);
                        form.setFieldsValue({ ChannelDropShipItems: [] });
                      }}
                    >
                      <RadioRow value={1}>All channels and drop ship</RadioRow>
                      <RadioRow value={2}>All channels without drop ship</RadioRow>
                      <RadioRow value={3}>Selected Channels</RadioRow>
                    </Radio.Group>
                  </Form.Item>
                  {settingType === 3 && (
                    <Form.Item
                      name="ChannelDropShipItems"
                      label="Channel"
                      rules={[{ required: true, message: 'Please select a channel' }]}
                    >
                      <Select
                        mode="multiple"
                        options={channelOpt}
                        placeholder="Please select a channel"
                        style={{ maxWidth: 600 }}
                      />
                    </Form.Item>
                  )}
                </div>
              </Card>
            </Card>
          </Form>
        )}
      </SiteContent>
    </ContentLayout>
  );
};
