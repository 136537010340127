import React from 'react';
import { Card, Col, Row } from 'antd';
import { FormLabel } from '../../components/common/styledComponents';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { Chart } from 'react-google-charts';
import { isChartEmpty } from './util';
import { CardSize } from 'antd/lib/card';

const DashboardRowStying = styled(Row)`
  margin: 0px 15px;

  .dashboard-card-item {
    padding: 0px;
    margin: 10px;
    height: ${(props: { size: CardSize }) => props.size === 'small' ? '300px' : '337px'};
    border-radius: 10px;

    .card-row {
      margin-bottom: 0.5em;
      justify-content: space-between;
    }
  }

  .dashboard-card-item:hover {
    box-shadow: 0px 0 6px 0 #e1e2e5;
  }
`

const DashboardH1 = styled.h1`
  padding: 10px 0px 0px 18px; 
  font-size: ${(props: { size: CardSize }) => props.size === 'small' ? '18px' : '24px'}; 
  font-weight: 600;
`
type DashboardRowProps = {
  title: string;
  dataSource: any;
  chartData?: any[];
  size?: CardSize;
}
const DashboardRow = (props: DashboardRowProps) => {
  const { title, dataSource, chartData = [], size = "default" } = props;

  const COUNT_CARD_ENUM: StringKAnyVPair = {
    'channelOrderCount': 'Channel Order',
    'soCount': 'S/O',
    'ptCount': 'Pick Ticket',
    'shipmentCount': 'Shipment',
    'invoiceCount': 'Invoice',
    'returnCount': 'Return',
    'poCount': 'Purchase Order',
    'poReceiveCount': 'P/O Receive'
  };

  const QUANTITY_CARD_ENUM: StringKAnyVPair = {
    'channelOrderQty': 'Channel Order',
    'soQty': 'S/O',
    'ptQty': 'Pick Ticket',
    'shipmentQty': 'Shipment',
    'invoiceQty': 'Invoice',
    'returnQty': 'Return',
    'poQty': 'Purchase Order',
    'poReceiveQty': 'P/O Receive'
  }

  // const fakeData = JSON.parse(`[["Channels","Orders","Shipped"],["Boscovs (By Logicbroker)",7,0],["AAFES (By DSCO)",3,3],["Fullbeauty (By Logicbroker)",1,0],["Fullbeauty (By Logicbroker)",1,0],["Fullbeauty (By Logicbroker)",3,4],["Fullbeauty (By Logicbroker)",1,0],["45gdsf (By Logicbroker)",5,6],["cxzcsd (By Logicbroker)",1,0],["321312 (By Logicbroker)",1,0],["dsads (By Logicbroker)",1,0]]`);

  type DashboardCardProps = {
    title: string;
    cardEnum: StringKAnyVPair;
  }
  const DashboardCard = (props: DashboardCardProps) => {
    const { title, cardEnum } = props;

    type ListItem = { labelName: string, value: string | number };
    const list: ListItem[] = [];
    Object.keys(dataSource).forEach(key => {
      if (cardEnum[key]) {
        list.push({
          labelName: cardEnum[key],
          value: dataSource[key]
        })
      }
    })

    return (
      <Card title={title} className="dashboard-card-item" size={size}>
        {list.map((item, index) => {
          const { labelName, value } = item;
          return (
            <Row className="card-row" key={`card-label-${labelName}-${index}`}>
              <FormLabel style={{ color: '#546279' }}>{labelName}</FormLabel>
              <Text>{value}</Text>
            </Row>
          )
        })}
      </Card>
    )
  }

  type DashboardChartCardProps = {
    title: string;
  }
  const DashboardChartCard = (props: DashboardChartCardProps) => {
    const { title } = props;
    return (
      <Card title={title} className="dashboard-card-item" size={size}>
        <Chart
          chartType="BarChart"
          width='100%'
          height='100%'
          // style={{ minWidth: 520 }}
          style={{ width: '100%', height: '100%' }}
          options={{
            title: 'Count',
            chartArea: { width: "70%", height: '85%' },
            isStacked: true,
            is3D: true,
            hAxis: {
              title: "",
              minValue: 0,
              format: 'short',
            },
            // bar: { groupWidth: '52%' },
            legend: { position: 'bottom' },
            colors: ['#FFD666', '#91D5FF', '#95DE64', '#FFADD2'],
            backgroundColor: 'transparent'
          }}
          data={chartData}
          legendToggle={true}
        />
      </Card>
    )
  }

  if (!dataSource) return null;

  return (
    <DashboardRowStying size={size}>
      <DashboardH1 size={size}>{title}</DashboardH1>
      <Row style={{ width: '100%' }} wrap={false}>
        <Col flex="300px">
          <DashboardCard title="Count" cardEnum={COUNT_CARD_ENUM} />
        </Col>
        <Col flex="300px">
          <DashboardCard title="Quantity" cardEnum={QUANTITY_CARD_ENUM} />
        </Col>
        {!isChartEmpty(chartData) && (
          <Col flex="auto">
            <DashboardChartCard title="Count" />
          </Col>
        )}
      </Row>
    </DashboardRowStying>
  )
}

export default DashboardRow;