import {
  Select,
  SelectProps,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
// import { useGetAvailableMappingsByChannelNumQuery } from '../../redux/api/channelIntegration';
import { enumNameToLabel } from "../../util/strings";
import { ChannelAccountTemplateMappingType } from "../../types/enums";
import {
  getAvailableMappingsByChannelNum,
} from "../../services/ChannelIntegration";

interface MappingTemplateSelectProps extends SelectProps<number> {
  channel: number;
  channelAccount: number;
  productMappingNum: number;
  defaultProductMappingNum: number;
  applyTemplate: any;
  defaultSelectMappingType?: number;
  withOut?: number;
}

const MappingTemplateSelect: React.FC<MappingTemplateSelectProps> = ({
  channel,
  channelAccount,
  productMappingNum,
  defaultProductMappingNum,
  applyTemplate,
  defaultSelectMappingType,
  withOut,
  ...selectProps
}) => {
  const [data, setData] = useState<Entities.MappingContentProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAvailableMappingsByChannelNum(channel);
      setLoading(false);
      if (res) {
        const tempData = res
          .filter(
            (m) =>
              m.ChannelNum === channel && m.ChannelAccountNum === channelAccount
          )
          .sort((a, b) => {
            if (a.MappingTypeNum !== b.MappingTypeNum) {
              return a.MappingTypeNum - b.MappingTypeNum;
            }
            return a.Name.localeCompare(b.Name);
          });
        setData(withOut ? tempData.filter(i=> i.ProductMappingNum !==withOut) : tempData);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [channel, channelAccount, withOut]);

  useEffect(() => {
    if (selectProps?.value === 0 && data.length > 0) {
      if (
        defaultProductMappingNum &&
        data.findIndex(
          (i) => i.ProductMappingNum === defaultProductMappingNum
        ) > -1 &&
        productMappingNum === 0
      ) {
        applyTemplate(defaultProductMappingNum);
      } else {
        if (defaultSelectMappingType) {
          const temp = data.filter(
            (i) => i.MappingTypeNum === defaultSelectMappingType
          );
          if (temp.length > 0) {
            applyTemplate(data[0].ProductMappingNum, {
              title:
                data[0].Name ||
                enumNameToLabel(
                  ChannelAccountTemplateMappingType[data[0].MappingTypeNum]
                ),
              name: data[0].TemplateType
            });
          } else {
            applyTemplate(0);
          }
        }
        applyTemplate(data[0].ProductMappingNum, {
          title:
            data[0].Name ||
            enumNameToLabel(
              ChannelAccountTemplateMappingType[data[0].MappingTypeNum]
            ),
          name: data[0].TemplateType
        });
      }
    }
  }, [
    data,
    applyTemplate,
    defaultProductMappingNum,
    defaultSelectMappingType,
    productMappingNum,
    selectProps,
  ]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  return (
    <Select
      style={{ width: "100%" }}
      onChange={applyTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...selectProps}
      loading={loading}
      disabled={
        defaultProductMappingNum > 0 &&
        data.findIndex(
          (i) => i.ProductMappingNum === defaultProductMappingNum
        ) > -1
      }
    >
      {data.length === 0 && (
        <Select.Option key={0} value={0}>
          &nbsp;
        </Select.Option>
      )}
      {data.map((m) => (
        <Select.Option key={m.MappingContentID} value={m.ProductMappingNum} title={m.Name ||
          enumNameToLabel(
            ChannelAccountTemplateMappingType[m.MappingTypeNum]
          )} name={m.TemplateType}>
          <Space size="small">
            {/* <Tag color="volcano">{m.TemplateType ? 'Standard': 'Catalog'}</Tag> */}
            <Typography.Text>{m.Name ||
              enumNameToLabel(
                ChannelAccountTemplateMappingType[m.MappingTypeNum]
              )
            }</Typography.Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default MappingTemplateSelect;
