import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import notificationActions from '../../redux/actions/notifications';
import { addMethods, deleteMethods, editMethods, fetchMethods } from '../../services/shipping';
import CheckMark from '../../assets/icons/checkmark';
import CancelIcon from '../../assets/icons/clear';
import EditIcon from '../../assets/icons/edit';
import DeleteIcon from '../../assets/icons/delete';
import TableComponent from '../../components/TableComponent';
import EditableCell from '../../components/UserManagement/EditableCell';
import ConfirmationModal from '../../components/UserManagement/ConfirmationModal';
import Spacer from "../common/Spacer";

const { Option } = Select;

const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left; 
`;

const Divider = styled.div`
    background-color: #fff;
    height: 1px;
    border: solid .5px #d8dde6;
    margin: 15px auto;
    width: 20px;
`;

interface Item {
    ChannelNum: number,
    UserOutboundMappingNum: any,
    CarrierCode: string,
    ServiceCode: string,
    ChannelCarrierCode: string,
    ChannelServiceCode: string
}

function MethodsSection (props: any) {
    const [form] = Form.useForm();
    const [formAddInput] = Form.useForm();
    const [editingKey, setEditingKey] = useState<String>('');
    const [shippingMethodDropDownValue, setShippingMethodDropDownValue] = useState<number>(0);
    const [data, setData] = useState<Item[]>([]);
    const [carrier, setCarrier] = useState<string>('');
    const [service, setService] = useState<string>('');
    const [loading, setLoading] = useState<Boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<Boolean>(false);
    const [selectedUser, setSelecedUser] = useState({
        UserOutboundMappingNum: 0,
        CarrierCode: '',
        ServiceCode: '',
        ChannelCarrierCode: '',
        ChannelServiceCode: ''
    });

    const columns = [
        {
            title: '',
            dataIndex: 'ChannelNum',
            key: 'ChannelNum',
            className: 'hide',
        },
        {
            title: '',
            dataIndex: 'UserOutboundMappingNum',
            key: 'UserOutboundMappingNum',
            className: 'hide',
        },
        {
            title: '',
            dataIndex: 'ServiceCode',
            key: 'ServiceCode',
            className: 'hide',
        },
        {
            title: '',
            dataIndex: 'CarrierCode',
            key: 'CarrierCode',
            render: (_: any, record: any) => `${record.CarrierCode} - ${record.ServiceCode}`,
        },
        {
            title: '',
            dataIndex: 'ChannelCarrierCode',
            key: 'ChannelCarrierCode',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'ChannelServiceCode',
            key: 'ChannelServiceCode',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: Item) => {
                const viewPermissionLevel = 1;
                const manageProfileUsersPermissionNum = 10003;
                const editable = isEditing(record);
                return editable ? (
                    <div>
                        <TableButtonWrapper onClick={() => save(record.UserOutboundMappingNum, record)} style={{marginRight: 8}}>
                            <CheckMark height={25} width={25} style={{fill: '#02a800'}} />
                        </TableButtonWrapper>
                        <TableButtonWrapper onClick={() => cancel()} style={{marginRight: 8}}>
                            <CancelIcon height={25} width={25} style={{fill: '#2d3f5d'}} />
                        </TableButtonWrapper>
                    </div>
                ) : hasPermissions(props.profilesEntity.permissions, manageProfileUsersPermissionNum, viewPermissionLevel) && (
                    <>
                        <TableButtonWrapper onClick={() => edit(record)}>
                            <EditIcon height={25} width={25} style={{fill: '#006dff'}} />
                        </TableButtonWrapper>
                        <TableButtonWrapper onClick={() => handleConfirmationModal(record)} style={{ marginLeft: 10 }}>
                            <DeleteIcon height={25} width={25} style={{fill: '#c13939'}} />
                        </TableButtonWrapper>
                    </>
                );
            }
        },
    ];

    const handleCarrier = (value: any) =>  setCarrier(value.target.value);
    const handleService = (value: any) => setService(value.target.value);
    const handleShippingMethodChange = (value: any) => setShippingMethodDropDownValue(value);
    const cancel = () => {
        setEditingKey('');
    };

    const edit = (record: Item) => {
        form.setFieldsValue({...record });
        setEditingKey(record.UserOutboundMappingNum);
    };
    const handleAdd = async (channelNum: number) => {
        if(carrier.length && service.length) {
            await addMethods({
                ChannelNum: channelNum,
                ChannelCarrierCode: carrier,
                ChannelServiceCode: service,
                CarrierCode: props.carrierServices[shippingMethodDropDownValue].CarrierCode,
                ServiceCode: props.carrierServices[shippingMethodDropDownValue].ServiceCode,
            });
            props.actions.setNotification('success', 'New method added');
            fetchData();
        }
    }
    const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
        if (permissions && permissions.size === 0) return false;
        return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
    }
    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleConfirmationModal = (record: Item) => {
        setSelecedUser(record);
        setShowConfirmation(true);
    };
    const handleConfirmation = async  () => {
        try {
            await deleteMethods(selectedUser.UserOutboundMappingNum);
            fetchData();
            props.actions.setNotification('success', 'Method deleted');
        } catch (e) {
            throw e;
        }
    };
    const isEditing = (record: Item) => record.UserOutboundMappingNum === editingKey;
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const save = async (key: React.Key, record: any) => {
        try {
            const row = (await form.validateFields()) as Item;
            if(!row.ChannelCarrierCode.trim() && !row.ChannelCarrierCode.trim()) {
                form.setFieldsValue({
                    ...row,
                    ChannelCarrierCode: row.ChannelCarrierCode.trim(),
                    ChannelServiceCode: row.ChannelServiceCode.trim(),
                });
                return;
            }
            let newData = [...data];
            const index = newData.findIndex(item => key === item.UserOutboundMappingNum);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
                await editMethods({
                    UserOutboundMappingNum: item.UserOutboundMappingNum,
                    ChannelCarrierCode: row.ChannelCarrierCode.trim(),
                    ChannelServiceCode: row.ChannelServiceCode.trim(),
                    CarrierCode: record.CarrierCode.trim(),
                    ServiceCode: record.ServiceCode.trim(),
                    ChannelNum: record.ChannelNum,
                    MasterAccountNum: props.profilesEntity.profiles[props.profilesEntity.selectedIndex].MasterAccountNum,
                    ProfileNum: props.profilesEntity.profiles[props.profilesEntity.selectedIndex].ProfileNum,
                });
                props.actions.setNotification('success', 'Method updated');
            } else {
                newData.push(row);
                setData(newData)
                setEditingKey('');
            }
        } catch (errInfo) {
            throw errInfo;
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetchMethods(props.ChannelNum);
            setData(response);
            setLoading(false);
        } catch(e) {
            setLoading(false);
            throw e;
        }
    };

    const useFetch = () => {
        useEffect(() => {
            fetchData();
        }, []);
    };

    useFetch();
    return (
        <>
            <ConfirmationModal
                visible={showConfirmation}
                data={selectedUser}
                handleCancel={handleCancel}
                handleConfirm={handleConfirmation}
            />
                <Form form={formAddInput}>
                    <Row>
                        <Col xs={8} style={{paddingRight: '15px'}}>
                            <Select
                                onChange={handleShippingMethodChange}
                                value={shippingMethodDropDownValue}
                                placeholder="Select a shipping method"
                                style={{width: '100%'}}
                            >
                                {props.carrierServices.map((c: any, index: number) => (
                                    <Option key={index} value={index}>{`${c.CarrierCode} - ${c.ServiceCode}`}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={5}>
                            <Form.Item
                                name="carrier"
                                rules={[{ required: true, message: 'Carrier needed!' }]}
                            >
                                <Input
                                    value={carrier}
                                    style={{width: '100%'}}
                                    onChange={handleCarrier}
                                    placeholder="Carrier"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={2}>
                            <Divider />
                        </Col>
                        <Col xs={5}>
                            <Form.Item
                                name="service"
                                rules={[{ required: true, message: 'Service needed!' }]}
                            >
                                <Input
                                    value={service}
                                    style={{width: '100%'}}
                                    onChange={handleService}
                                    placeholder="Service"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={4} style={{paddingLeft: '15px'}} >
                            <Form.Item>
                                <Button type="primary" onClick={() => handleAdd(props.ChannelNum)} htmlType="submit" disabled={!(carrier.length && service.length && shippingMethodDropDownValue > 0)} block>Add</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Spacer />
                <Row>
                    <Col xs={24}>
                        <Form form={form} component={false}>
                            <TableComponent
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                showHeader={false}
                                rowClassName={() => 'editable-row'}
                                loadingStatus={loading}
                                dataSource={data}
                                columns={mergedColumns}
                            />
                        </Form>
                    </Col>
                </Row>
            </>
    )
}


const mapStateToProps = (state: any) => {
    return { profilesEntity: state.profiles };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(notificationActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MethodsSection);