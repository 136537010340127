import { Form, Input, Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Permissions } from '../../constants/enums/permissions';
import { addClassification, editClassification, fetchClassification } from '../../services/products';
import { DataGrid } from '../common/datagrid/DataGrid';
import FormButtons from '../common/FormButtons';
import SearchBar, { SearchField } from '../common/SearchBar';
import Spacer from '../common/Spacer';
import { ModalTitle } from '../common/styledComponents';

const columns = [
  {
    name: 'AttributeName',
    header: 'Attribute Name',
    sortable: true,
    defaultFlex: 2,
  },
  {
    name: 'DefaultValue',
    header: 'Default Value',
    sortable: true,
    defaultFlex: 2,
  },
  {
    name: 'OptionList',
    header: 'Option List',
    sortable: true,
    defaultFlex: 2,
  },
];

const searchFields: Array<SearchField<Entities.ProductClassificationAttribute> | string> = [
  'AttributeName',
  'DefaultValue',
  {
    fieldName: 'OptionList',
    attributes: {
      extractor(e) {
        return e.OptionList?.split('|')?.join(' ') || '';
      },
    },
  },
];

const AttributesTable: React.FC<{ attributes?: Entities.ProductClassificationAttribute[] }> = ({ attributes = [] }) => {
  const [data, setData] = useState<Entities.ProductClassificationAttribute[]>([]);

  return (
    <>
      <h3>
        Attributes
      </h3>
      <hr />
      <Spacer />
      <SearchBar
        reference="ProductAttributeNum"
        data={attributes}
        onResult={setData}
        fields={searchFields}
      />
      <Spacer />
      <DataGrid
        idProperty="ProductAttributeNum"
        dataSource={data}
        columns={columns}
        pagination
      />
    </>
  );
};

AttributesTable.defaultProps = {
  attributes: [],
};

interface ModalFormProps {
  classificationId?: string | null;
  visible: boolean;
  onCancel?: () => void;
  onOk: (data: Entities.ProductClassification) => void;
  onError: (e: any) => void;
  onModalClose: () => void;
}

const ModalForm: React.FC<ModalFormProps> = ({ classificationId, visible, onModalClose, onCancel, onOk, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<Entities.ProductClassification | null>(null);

  // Data loading hook
  useEffect(() => {
    let mounted = true;

    if (!classificationId || !mounted) {
      form.resetFields();
      return undefined;
    }

    setLoading(true);
    fetchClassification(classificationId)
      .then((data: any) => {
        if (mounted) {
          form.setFieldsValue(data);
          setEntity(data);
        }
      })
      .finally(() => {
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
    };
  }, [classificationId, form, setEntity]);

  const cancel = useCallback(() => {
    onCancel?.();
    onModalClose();
    setEntity(null);
  }, [onCancel, onModalClose]);

  const formSubmit = useCallback(() => {
    const submit = async () => {
      const values = await form.validateFields();

      setLoading(true);
      const data = await (classificationId ? editClassification(values, classificationId) : addClassification(values));
      form.resetFields();
      return data;
    };

    submit()
      .then(onOk)
      .catch(onError)
      .finally(() => setLoading(false));
  }, [onOk, onError, classificationId, form]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {classificationId ? 'Editing Classification' : 'New Classification'}
        </ModalTitle>
      )}
      width={600}
      closable={false}
      maskClosable={false}
      footer={(
          <FormButtons
            permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
            editingMode
            onSave={formSubmit}
            onCancel={cancel}
            disabled={loading}
            style={{justifyContent: 'flex-end'}}
          />
      )}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
        >
          <Form.Item
            label="Classification Name"
            name="ClassificationName"
            rules={[{ required: true, message: 'This attribute is required' }]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="ClassificationDesc"
          >
            <Input.TextArea maxLength={500} />
          </Form.Item>
        </Form>
        {
          entity && (
            <>
              <Spacer />
              <AttributesTable attributes={entity?.Attributes || []} />
            </>
          )
        }
      </Spin>
    </Modal>
  );
};

ModalForm.defaultProps = {
  classificationId: null,
};

export default ModalForm;
