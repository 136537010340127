import React from 'react';

function Clear({
	style = {},
	height = 16,
	width = 16,
	viewBox = "0 0 24 24"
}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} viewBox={viewBox} width={width}>
			<path d="M0 0h24v24H0z" fill="none"/>
			<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
		</svg>
	);
}

export default Clear;