const initialState = {
  currentKey: [],
  currentOpenedKey: [],
};

const routes = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_KEY':
      return { ...state, currentKey: action.currentKey };
    case 'SET_CURRENT_OPENED_KEY':
      return { ...state, currentOpenedKey: action.currentOpenedKey };
    default:
      return state;
  }
};

export default routes;
