import ChannelIntegrationSelector from './ChannelIntegrationSelector';
import ChannelIntegrationDetails from './ChannelIntegrationDetails';
import ChannelIntegrationMapping from './ChannelIntegrationMapping';

const routes = {
  selector: '/integrations/add',
  add: '/integrations/:channelNum-:platformNum/add',
  details: '/integrations/:channelNum-:platformNum/details/:channelAccountNum',
  mapping: '/integrations/:channelNum-:platformNum/mapping/:mappingType/:channelAccountNum',
};

export {
  ChannelIntegrationSelector,
  ChannelIntegrationDetails,
  ChannelIntegrationMapping,
  routes as ChannelIntegrationRoutes,
};
