import React, { createContext } from 'react';

interface PageState {
  loading: boolean;
  fetchData: boolean;
  data: Entities.ProductAttribute[];
  channels: Map<number, Entities.ChannelProfile>;
  searchDisabled: boolean;
  modalFormVisible: boolean;
  mappingDialogVisible: boolean;
  manageGroupVisible: boolean;
  current: any;
  dialogIsFullscreen: boolean;
  importDialogVisible: boolean;
  importMappingDialogVisible: boolean;
}

export enum Actions {
  SET_LOADING,
  SET_DATA,
  FETCH_DATA,
  DATA_FETCHED,
  SET_MODAL_FORM_VISIBLE,
  SET_MANAGE_GROUP_VISIBLE,
  CLOSE_MODAL_FORM,
  CLOSE_MANAGE_GROUP,
  EDIT_ENTITY,
  ADD_ENTITY,
  SET_MAPPING_VISIBLE,
  CLOSE_MAPPING_DIALOG,
  SET_DIALOG_IS_FULL_SCREEN,
  SET_IMPORT_DIALOG_VISIBLE,
  SET_IMPORT_MAPPING_DIALOG_VISIBLE,
}

export function initialState(): PageState {
  return {
    loading: false,
    fetchData: true,
    searchDisabled: false,
    modalFormVisible: false,
    data: [],
    channels: new Map<number, Entities.ChannelProfile>(),
    mappingDialogVisible: false,
    manageGroupVisible: false,
    current: undefined,
    dialogIsFullscreen: false,
    importDialogVisible: false,
    importMappingDialogVisible: false,
  };
}

const onDataFetched = (state: PageState, {
  data
}: { data: any[]; }) => ({
  ...state,
  loading: false,
  data,
});

export function reducer(state: PageState, action: Utils.DispatchAction<Actions>) {
  switch (action.type) {
    case Actions.DATA_FETCHED:
      return onDataFetched(state, action.params);
    case Actions.FETCH_DATA:
      return { ...state, loading: true, fetchData: false };
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_DATA:
      return { ...state, data: action.params };
    case Actions.SET_MODAL_FORM_VISIBLE:
      console.log(action);
      return { ...state, modalFormVisible: true, current: action.params };
    case Actions.SET_MANAGE_GROUP_VISIBLE:
      console.log(action);
      return { ...state, manageGroupVisible: true, current: action.params };
    case Actions.CLOSE_MODAL_FORM:
      return { ...state, modalFormVisible: false, current: undefined };
    case Actions.CLOSE_MANAGE_GROUP:
      return { ...state, manageGroupVisible: false, current: undefined };
    case Actions.ADD_ENTITY:
      return { ...state, editingEntityId: null, modalFormVisible: true };
    case Actions.EDIT_ENTITY:
      return { ...state, editingEntityId: action.params, modalFormVisible: true };
    case Actions.SET_MAPPING_VISIBLE:
      return { ...state, current: action.params, mappingDialogVisible: true };
    case Actions.CLOSE_MAPPING_DIALOG:
      return { ...state, current: undefined, mappingDialogVisible: false };
    case Actions.SET_DIALOG_IS_FULL_SCREEN:
      return { ...state, dialogIsFullscreen: action.params };
    case Actions.SET_IMPORT_DIALOG_VISIBLE:
      return {...state, importDialogVisible: action.params}
    case Actions.SET_IMPORT_MAPPING_DIALOG_VISIBLE:
        return {...state, importMappingDialogVisible: action.params}
    default:
      return state;
  }
}

export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [PageState, Dispatcher] | [undefined, undefined];
export const PageContext = createContext<ContextType>([undefined, undefined]);
