import Icon from '@ant-design/icons';
import React from 'react';
import { BaseIcon } from './base';

const LocalOfferIcon: React.FC<BaseIcon> = ({
  style = {},
  height = 16,
  width = 16,
  viewBox = '0 0 24 24',
  pathColor = 'black',
  className = "",
}) => (
  <Icon
    className={className}
    component={() => (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} viewBox={viewBox} width={width}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fill={pathColor}
          d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"
        />
        <circle cx="6.5" cy="6.5" r="1.5" />
      </svg>
    )}
  />
);

export default LocalOfferIcon;