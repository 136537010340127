import React, { SVGProps } from 'react';

const WMS_BGIcon: React.FC<SVGProps<SVGAElement>> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="10" fill="#4F772D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4342 27.5341C19.4342 26.4192 20.4428 25.6622 21.3289 25.6622C22.3388 25.6622 23.165 26.4192 23.165 27.5341H24.2006C24.4637 27.5341 24.6731 27.3232 24.6731 27.0676L24.6731 21.1082M24.6731 21.1067V19.8842C24.6731 19.6286 24.4637 19.4177 24.2006 19.4177H23.7715H19.9092H18.8211C18.7506 19.4177 18.684 19.4328 18.6242 19.4599C18.4608 19.5339 18.3486 19.6971 18.3486 19.8842V26.9203V27.0676C18.3486 27.5004 18.7791 27.5341 19.3632 27.5341H19.4342M25.3596 27.0676C25.3596 27.3232 25.5691 27.5341 25.8321 27.5341H26.3266C26.3266 26.4192 27.1988 25.6622 28.0849 25.6622C28.971 25.6622 29.8432 26.4192 29.8432 27.5341H30.3376C30.6007 27.5341 30.8101 27.3232 30.8101 27.0676V23.6515C30.8101 23.5285 30.7609 23.4101 30.6725 23.3224L28.1088 20.779C28.0203 20.6913 27.8999 20.6416 27.7738 20.6416H25.8321C25.5694 20.6416 25.3602 20.852 25.3596 21.1072V27.0676ZM21.3289 26.4192C20.6511 26.4192 20.1054 26.9637 20.1054 27.6307C20.1054 28.2977 20.6511 28.8422 21.3289 28.8422C22.0067 28.8422 22.5523 28.2977 22.5523 27.6307C22.5523 26.9637 22.0067 26.4192 21.3289 26.4192ZM28.0849 26.4192C27.4071 26.4192 26.8614 26.9637 26.8614 27.6307C26.8614 28.2977 27.4071 28.8422 28.0849 28.8422C28.7627 28.8422 29.3083 28.2977 29.3083 27.6307C29.3083 26.9637 28.7627 26.4192 28.0849 26.4192Z" fill="white" />
        <path d="M15.3809 27.1388C15.0544 26.996 14.7447 26.8173 14.4577 26.6062L13.3528 26.9575C13.0834 27.0433 12.7905 26.93 12.6492 26.6855L11.5521 24.7934C11.4124 24.5487 11.4605 24.2401 11.6682 24.0494L12.5253 23.2688C12.4863 22.9152 12.4863 22.5584 12.5253 22.2048L11.6682 21.426C11.4602 21.2352 11.412 20.9262 11.5521 20.6814L12.6468 18.7881C12.7881 18.5436 13.081 18.4303 13.3504 18.5161L14.4553 18.8674C14.6021 18.7588 14.7549 18.6586 14.913 18.5671C15.0648 18.4817 15.2209 18.4043 15.3809 18.3354L15.6263 17.2053C15.6865 16.9297 15.9307 16.733 16.2133 16.7327H18.4025C18.6851 16.733 18.9294 16.9297 18.9896 17.2053L19.2373 18.0922C19.4062 18.1664 19.5707 18.2502 19.7299 18.3432C19.7916 18.3788 19.8525 18.4159 19.9124 18.4543H24.0449V18.272C24.0449 18.0675 23.9227 17.9704 23.7635 17.8111L23.7601 17.8077C23.5992 17.6468 17.5387 11.7693 17.2147 11.4453C16.8438 11.0743 16.4456 11.0457 16.0461 11.4453C15.6949 11.7965 9.7612 17.8681 9.53708 18.0922C9.31297 18.3163 9.18994 18.5671 9.18994 18.8674V27.2981C9.18994 27.8785 9.38829 28.1941 10.1236 28.1941C10.859 28.1941 15.5818 28.2137 15.5818 28.2137L15.3809 27.1388Z" fill="white" />
        <path d="M17.2856 25.4297C15.7863 25.4297 14.5708 24.2142 14.5708 22.7148C14.5708 21.2155 15.7863 20 17.2856 20V25.4297Z" fill="white" />
    </svg>
  );
};

export default WMS_BGIcon;

