import React, { useEffect } from 'react';
import { Button, Row, Space, Steps, notification, message } from 'antd';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, LOADING_ICON_SIZE1 } from '../../../constants/config';
import { SOURCE_TYPE_ERP } from '../../DetailProduct/CopywritingSteps';
import { CopywritingStepWrapper } from '../../DetailProduct/styledComponents';
import AttributeSelector from './AttributeSelector';
import SourceSelector from './SourceSelector';
import ReviewSelector from './Review';
import FilishSelector from './Finish';
import { getAllAttributes, saveBulkPullCopywritingelements } from '../../../services/copywriting';
import { getProfileSettingValue } from '../../../util'

type Props = {
  onCancel?: Function;
  blukData: any;
  onShowProducts: () => void;
};

const CopywritingSteps = (props: Props) => {
  const { useState } = React;
  const { Step } = Steps;
  const { blukData, onShowProducts } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [productBasicInfo, setProductBasicInfo] = useState<StringKAnyVPair>({});
  const [canNext, setCanNext] = useState(false);
  const [attributeList, setAttributeList] = useState<StringKAnyVPair[]>([]);
  const [selectedAtrribute, setSelectedAtrribute] = useState<any[]>([]);
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [selectedSource, setSelectedSource] = useState<string>();
  const [useChildLevel, setUseChildLevel] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [destinationNotEmptyOverwrite, setDestinationNotEmptyOverwrite] = useState(true);
  const [sourceEmptyIgnoreUpdate, setSourceEmptyIgnoreUpdate] = useState(true);
  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);
    try {
      const res = await getAllAttributes();
      const allowCommon = await getProfileSettingValue('UseCommonAttributes', '0'); // allow Common
      const overW = await getProfileSettingValue('ProductOverwriteChildrenValue', 0)
      setUseChildLevel(!!overW)
      if (res.isSuccess && Array.isArray(res.data)) {
        const data = allowCommon === '0' ? res.data.filter((i: any) => i.groupName !== 'Common Attributes') : [...res.data]
        //const tempData = data.filter((i:any)=> !i.source) // only source === 0
        const tempData = data.filter((i:any) => i.source !== SOURCE_TYPE_ERP); // only source === 0
        console.log('t d', tempData.length, data.length);
        setAttributeList(tempData);
        setSourceList(
          Array.from(
            new Set(
              tempData.map((i: any) => {
                return i.groupName;
              }),
            ),
          ),
        );
      }
    } catch (e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch data error: ${e}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = async (isSubmit?: boolean) => {
    if (isSubmit) {
      try {
        setIsSubmiting(true);
        const params = {
          // TypeNum: blukData.all,
          // Type: blukData.all ? 'All' : undefined,
          ProductFilter: blukData.searchParams
            ? blukData.searchParams
            : blukData.all < 2
            ?  blukData.searchParams
            : blukData.all === 2
            ? {
                queryFilters: [
                  {
                    filterName: 'StyleCode',
                    filterValue: '',
                    op: 1,
                  },
                ],
                queryGrouper: {
                  groupName: 'StyleCode',
                },
              }
            : {
                queryFilters: [
                  {
                    filterName: 'ColorPatternCode',
                    filterValue: '',
                    op: 1,
                  },
                ],
                queryGrouper: {
                  groupName: 'ColorPatternCode',
                },
              },
          Products:
            blukData.all
              ? null
              : blukData.products.map((i: any) => {
                  return {
                    CentralProductNum: i.CentralProductNum,
                    Sku: i.SKU,
                    Style: i.styleCode,
                    SubStyle: i.colorPatternCode,
                    Type: i.Type,
                  };
                }),
          Items: selectedAtrribute.map((i: any) => {
            return {
              CopywritingElementNum: i.copywritingElementNum,
              AttributeNum: i.attributeNum,
            };
          }),
          Overwrite: destinationNotEmptyOverwrite,
          IgnoreSource: sourceEmptyIgnoreUpdate,
          IncludeChild: blukData.isProducts ? undefined : useChildLevel,
        };

        const res = await saveBulkPullCopywritingelements(params);
        setIsSubmiting(false);
        if (res.isSuccess) {
          setCurrentStep(currentStep + 1);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        message.error('Server error');
        setIsSubmiting(false);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onCancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData]);

  useEffect(() => {
    console.log(blukData);
  }, [blukData]);

  return (
    <>
      <CopywritingStepWrapper>
        <Row align="middle" className="title-bar">
          {blukData.products.length > 0 ? (
            <Button type="link" onClick={() => onShowProducts()}>
              View Selected Items
            </Button>
          ) : blukData.all > 0 ? (
            ['ALL Products', 'All Style', 'All Substyle'][blukData.all - 1]
          ) : (
            'All Search Result'
          )}
        </Row>
        <div className="step-ctn">
          <div className="step-wrapper">
            <div className="step-sider">
              <Steps current={currentStep} direction="vertical">
                <Step title="Select Source" />
                <Step title="Select Attributes" />
                <Step title="Review" />
                <Step title="Finish" />
              </Steps>
            </div>
            <div className="step-board">
              {currentStep === 0 && (
                <SourceSelector
                  maskScreen={setIsLoading}
                  setCanNext={setCanNext}
                  sourceList={sourceList}
                  onSelectedChange={setSelectedSource}
                  defaultSelected={selectedSource}
                />
              )}
              {currentStep === 1 && (
                <AttributeSelector
                  destinationNotEmptyOverwrite={destinationNotEmptyOverwrite}
                  sourceEmptyIgnoreUpdate={sourceEmptyIgnoreUpdate}
                  setDestinationNotEmptyOverwrite={setDestinationNotEmptyOverwrite}
                  setSourceEmptyIgnoreUpdate={setSourceEmptyIgnoreUpdate}
                  selectedSourceName={selectedSource || ''}
                  sourceList={attributeList.filter((i) => i.groupName === selectedSource)}
                  maskScreen={setIsLoading}
                  setCanNext={setCanNext}
                  onSelected={(selectedRow: any[]) => setSelectedAtrribute([...selectedRow])}
                  useChildLevel={useChildLevel}
                  setUseChildLevel={setUseChildLevel}
                  enableChildLevel={!blukData.isProducts}
                />
              )}
              {currentStep === 2 && (
                <ReviewSelector
                  selectedSourceName={selectedSource || ''}
                  maskScreen={setIsLoading}
                  selectedAttribute={selectedAtrribute}
                />
              )}
              {currentStep === 3 && (
                <FilishSelector
                  selectedSourceName={selectedSource || ''}
                  maskScreen={setIsLoading}
                  resultList={selectedAtrribute}
                />
              )}
            </div>
          </div>
        </div>
        <Row align="middle" className="left-bottom-btn-block" justify="space-between">
          <Space>
            <Button
              onClick={previousStep}
              style={{ visibility: currentStep > 0 && currentStep < 3 ? 'unset' : 'hidden' }}
            >
              Back
            </Button>
            {currentStep < 2 && (
              <Button disabled={!canNext} onClick={() => nextStep()} type="primary">
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button onClick={() => nextStep(true)} loading={isSubmiting} type="primary">
                Submit
              </Button>
            )}
            {currentStep === 3 && (
              <Button onClick={onCancel} type="primary">
                Close
              </Button>
            )}
          </Space>
          <Space>{currentStep < 3 && <Button onClick={onCancel}>Cancel</Button>}</Space>
        </Row>
        {/* <Row align="middle" justify={currentStep === 0 ? 'end' : 'space-between'}>
          {currentStep > 0 && <Button onClick={previousStep}>Back</Button>}
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            {currentStep < 2 && (
              <Button onClick={()=>nextStep()} disabled={!canNext} type="primary">
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button onClick={()=>nextStep(true)} loading={isSubmiting} type="primary">
                Submit
              </Button>
            )}
          </Space>
        </Row> */}
      </CopywritingStepWrapper>
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default CopywritingSteps;
