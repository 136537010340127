import { ProfileSettingsState } from '../types';
import { ProfileSettingsAction, ProfileSettingsActionType } from '../types/profilesSettings';

const initialState: ProfileSettingsState = {
  profileSettings: [
    {
      CanBeModifiedByCustomer: 0,
      CreateBy: '',
      CreateDate: '',
      DatabaseNum: 0,
      IsVisibleByCustomer: 0,
      MasterAccountNum: 0,
      ProfileNum: 0,
      ProfileSettingNum: 0,
      SettingCode: '',
      SettingValue: '',
      UpdateBy: '',
      UpdateDate: ''
    },
  ]
};

const profileSettings = (state = initialState, action: ProfileSettingsActionType) => {
  switch (action.type) {
    case ProfileSettingsAction.SET_PROFILESETTINGS:
      return { ...state, profileSettings: action.profileSettings }
    default:
      return state;
  }
};

export default profileSettings;
