import { OrderDetailsActions, OrderDetailsActionTypes } from '../types/orderDetails';

const setOrderDetails = (order: Entities.OrderDetails): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_ORDER,
  order,
});
const setLoading = (value: boolean): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_LOADING,
  value,
});

const setEditMode = (value: boolean): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_EDITMODE,
  value,
});

const setIsFormUpdated = (value: boolean): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_ISFORMUPDATED,
  value,
});

const setSelectedPendingItems = (value: Array<string>): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_SELECTEDPENDINGITEMS,
  value,
});

const setShowPendingErrorMessage = (value: boolean): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_SHOWPENDINGERRORMESSAGE,
  value,
});

const setPendingErrorMessage = (value: string): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_PENDINGERRORMESSAGE,
  value,
});

const setShippingTotal = (value: string): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_SHIPPINGTOTAL,
  value,
});

const setCarriers = (value: Entities.OrderCarriers): OrderDetailsActionTypes => ({
  type: OrderDetailsActions.SET_CARRIERS,
  value,
});

const clearPage = (): OrderDetailsActionTypes => ({ type: OrderDetailsActions.CLEAR_PAGE });

const actions = {
  setOrderDetails,
  setLoading,
  setEditMode,
  setIsFormUpdated,
  setSelectedPendingItems,
  setShowPendingErrorMessage,
  setPendingErrorMessage,
  setCarriers,
  setShippingTotal,
  clearPage,
};

export default actions;
