import { digitBridgeApi } from './http';

export interface OPTDetailsResponse {
  TotalCount: number;
  OPTDetailList: {
    OPTId: string;
    OPTLabel: string;
    /**
     * Datetime string
     */
    StartDate: string;
    /**
     * Datetime string
     */
    EndDate: string;
    FileName: string;
    FilterList: any[];
    ProductList: {
      SKU: string;
      CentralProductId: string;
      ProductAttributeAssignedList: {
        AttributeId: string;
        AttributeName: string;
        OriginalValue: string;
        NewValue: string;
      }[];
    }[];
  }[];
}

export const downloadEnquiredValues = async (params: any):Promise<any> => digitBridgeApi.post('/v1/opt/products/getOPTEnquiredValue', {
  ...params,
}, {
  headers: {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
});

export const getOPTList = (): Promise<{ TotalCount: number, OPTList: Entities.OPT[] }> => digitBridgeApi.get('/v1/opt/products/getOPTList');

export const getOPTProductList = (): Promise<Entities.CommerceCentralOPTSKUListingObj[]> => digitBridgeApi.get('/v1/opt/products/getOPTProductList');

export const getOPTAttributes = (): Promise<Entities.CommerceCentralOPTAttribute[]> => digitBridgeApi.get('/v1/opt/products/getOPTAttributeList');

export const getOPTDetails = (optId: string): Promise<OPTDetailsResponse> => digitBridgeApi.get('/v1/opt/products/getOPTDetailList', {
  params: {
    optId,
  },
});
