import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import React, { createContext } from 'react';
import Quickbooks, { QuickbooksSettings, QuickbooksSettingsInput, UserData } from '../../services/quickbooks';

export enum PageState {
  /**
   * Used when the user just accessed the Quickbooks page and it's pulling
   * data from the server.
   */
  FETCHING_INFO,
  /**
   * This state renders a page with a button that prompts the user to link their
   * Quickbooks account in order to render the settings.
   */
  REQUIRES_AUTH,
  /**
   * This mode is used when the user has successfully linked their account and
   * there's data available coming from our servers.
   */
  RENDER_SETTINGS,
  /**
   * Used when there's an issue with the API so a warning is displayed
   */
  UNKNOWN,
}

export enum Actions {
  SET_LOADING = 'QB_SET_LOADING',
  SET_PAGE_STATE = 'QB_SET_PAGE_STATE',
  SET_SETTINGS = 'QB_SET_SETTINGS',
  SET_FORM_VALUES = 'QB_SET_FORM_VALUES',
  SET_USER_DATA = 'QB_SET_USER_DATA',
  SET_CHANNEL_ACCOUNTS = 'QB_SET_CHANNEL_ACCOUNTS',
  SET_RELOAD_DATA = 'QB_RELOAD',
}

export enum ExportOptions {
  INVOICE,
  SALES_RECEIPT,
  DAILY_SUMMARY_SALES_RECEIPT,
  DAILY_SUMMARY_INVOICE,
  DO_NOT_EXPORT_SALES_ORDER,
}

export enum CustomerRecordCreation {
  ONE_PER_CHANNEL_ACCOUNT,
  ONE_PER_ORDER,
}

export enum ItemCreationRule {
  USE_DEFAULT_ITEM_FOR_ALL_SALES,
  SKIP_ORDERS_WITH_NO_MATCHING_INVENTORY,
}

export enum SalesTaxExport {
  DO_NOT_EXPORT_TAX,
  EXPORT_TO_DEFAULT_SALES_TAX_ACCOUNT,
}

export enum InvoiceImport {
  NONE,
  ALL_INVOICES,
  PAID_INVOICES,
}

export enum SalesReceipt {
  NONE,
  ALL_SALES_RECEIPT,
}

/**
 * Form values schema.
 *
 * Non-related attributes, or attributes that require transformation will be at root level.
 */
interface FormSchema extends QuickbooksSettingsInput {
  exportOrderDateRange: moment.Moment[];
  qboImportOrderAfterUpdateDate: moment.Moment;
}

export interface QuickbooksSettingsPageState {
  loading: boolean;
  pageState: PageState;
  settings?: QuickbooksSettings;
  userData: UserData;
  form: FormInstance<FormSchema>;
  channelAccounts: Array<api.ProfileChannelAccount>;
  reload: boolean;
}

export const formInitialValues = (masterAccountNum: number, profileNum: number): FormSchema => {
  const now = moment();

  return ({
    exportOrderDateRange: [now, now],
    qboImportOrderAfterUpdateDate: now,
    integrationSetting: {
      masterAccountNum,
      profileNum,
      exportOrderAs: ExportOptions.DO_NOT_EXPORT_SALES_ORDER,
      exportOrderFromDate: now.toISOString(),
      exportOrderToDate: now.toISOString(),
      salesTaxExportRule: SalesTaxExport.DO_NOT_EXPORT_TAX,
      qboCustomerCreateRule: CustomerRecordCreation.ONE_PER_CHANNEL_ACCOUNT,
      qboItemCreateRule: ItemCreationRule.SKIP_ORDERS_WITH_NO_MATCHING_INVENTORY,
      qboInvoiceImportRule: InvoiceImport.NONE,
      qboSalesOrderImportRule: SalesReceipt.NONE,
      qboImportOrderAfterUpdateDate: now.toISOString(),
    },
    chnlAccSettings: [],
  });
};

export const initialState = ({ form }: { form: FormInstance }): QuickbooksSettingsPageState => ({
  loading: false,
  pageState: PageState.FETCHING_INFO,
  settings: undefined,
  form,
  userData: {
    inventoryItems: [],
    nonInventoryItems: [],
    customers: [],
    otherCurrentLiabilitiesAccounts: [],
    userCompanyInfo: {
      companyName: '',
    },
  },
  channelAccounts: [],
  reload: false,
});

/**
 * UserData reducer.
 *
 * This one pushes the default items that "should be" coming from the API, but whatever.
 */
const setUserData = (state: QuickbooksSettingsPageState, action: Utils.DispatchAction<Actions>): QuickbooksSettingsPageState => {
  const userData = action.params as UserData;
  const inventoryItems = [
    {
      id: 0,
      name: 'Default Digitbridge Inventory Item',
    },
    ...userData.inventoryItems,
  ];
  const nonInventoryItems = [
    {
      id: 0,
      name: 'Default Digitbridge Item',
    },
    ...userData.nonInventoryItems,
  ];
  const otherCurrentLiabilitiesAccounts = [
    {
      id: 0,
      name: 'Sales Tax Payable - Manual Adjustment',
    },
    ...userData.otherCurrentLiabilitiesAccounts,
  ];

  return {
    ...state,
    userData: {
      customers: userData.customers,
      userCompanyInfo: userData.userCompanyInfo,
      inventoryItems,
      nonInventoryItems,
      otherCurrentLiabilitiesAccounts,
    },
  };
};

export function quickbooksSettingsReducer(state: QuickbooksSettingsPageState, action: Utils.DispatchAction<Actions>) {
  switch (action.type) {
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_PAGE_STATE:
      return { ...state, pageState: action.params };
    case Actions.SET_SETTINGS:
      return { ...state, settings: action.params };
    case Actions.SET_FORM_VALUES:
      state.form.setFieldsValue(action.params);
      return state;
    case Actions.SET_USER_DATA:
      return setUserData(state, action);
    case Actions.SET_CHANNEL_ACCOUNTS:
      return { ...state, channelAccounts: action.params };
    case Actions.SET_RELOAD_DATA:
      return { ...state, reload: action.params };
    default:
      return state;
  }
}

export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [QuickbooksSettingsPageState, Dispatcher] | [undefined, undefined];
export const QuickbooksSettingsScreenContext = createContext<ContextType>([undefined, undefined]);

/**
 * Form submit callback
 *
 * @param state - Current page state
 * @param dispatch - Dispatch action from useReducer
 * @param values = Form values
 */
export const onFormSubmit = (state: QuickbooksSettingsPageState, dispatch: Dispatcher, values: FormSchema): Promise<void> => {
  const payload: QuickbooksSettingsInput = {
    ...values,
    chnlAccSettings: values.chnlAccSettings || [],
  };

  const exportOrderDateRange: Array<moment.Moment> = values.exportOrderDateRange || [];
  if (exportOrderDateRange.length === 2) {
    payload.integrationSetting.exportOrderFromDate = exportOrderDateRange[0].toISOString();
    payload.integrationSetting.exportOrderToDate = exportOrderDateRange[1].toISOString();
  }

  delete (payload as any).exportOrderDateRange;
  delete (payload as any).qboImportOrderAfterUpdateDate;

  // Default item
  if (payload.integrationSetting.qboItemCreateRule === ItemCreationRule.USE_DEFAULT_ITEM_FOR_ALL_SALES) {
    payload.integrationSetting.qboDefaultItemName = state.userData.inventoryItems.find(i => i.id === values.integrationSetting.qboDefaultItemId)?.name;
  } else {
    payload.integrationSetting.qboDefaultItemId = undefined;
    payload.integrationSetting.qboDefaultItemName = undefined;
  }

  payload.integrationSetting.qboShippingItemName = state.userData.nonInventoryItems.find(i => i.id === values.integrationSetting.qboShippingItemId)?.name;
  payload.integrationSetting.qboSalesTaxItemName = state.userData.nonInventoryItems.find(i => i.id === values.integrationSetting.qboSalesTaxItemId)?.name;
  payload.integrationSetting.qboDiscountItemName = state.userData.nonInventoryItems.find(i => i.id === values.integrationSetting.qboDiscountItemId)?.name;
  payload.integrationSetting.qboSalesTaxAccName = state.userData.otherCurrentLiabilitiesAccounts.find(i => i.id === values.integrationSetting.qboSalesTaxAccId)?.name;
  payload.integrationSetting.qboImportOrderAfterUpdateDate = values.qboImportOrderAfterUpdateDate.toISOString();


  const status = state.settings?.integrationSetting?.qboSettingStatus;

  if (status === 1) {
    delete (payload as any).integrationSetting.exportOrderFromDate;
    delete (payload as any).integrationSetting.exportOrderToDate;
  }

  dispatch({ type: Actions.SET_LOADING, params: true });

  return new Promise((resolve, reject) => {
    const result = status === 1 ? Quickbooks.updateSettings(payload) : Quickbooks.createSettings(payload);

    result
      .then(() => {
        dispatch({ type: Actions.SET_RELOAD_DATA, params: true });
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};
