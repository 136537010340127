import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/ContentLayout";
import Heading from "../../components/common/Heading";
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Select, Space, message } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Spacer from "../../components/common/Spacer";
import RuleEditDialog from "./RuleEditDialog";
import SiteContent from "../../components/SiteContent";
import { DataGrid } from "../../components/common/datagrid/DataGrid";
import { useDebounce } from "../../util";
import { deleteOverwriteRule, fetchOverwriteRuleList } from "../../services/qtyOverwriteRule";
import { listProfileChannelAccounts } from "../../services/channels";
import { convertTimeByUtc } from "../../util/utcTime";
import styled from "styled-components";
import theme from "../../assets/styles/theme";

export type OverwriteRule = any;

const HoverDangerButton = styled(Button)`
  color: ${theme['@error-color']};
  transition: all 0.3s;
  &:hover {
    background-color: ${theme['@error-color']};
    color: white;
  }
`

const QtyOverwriteRule = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ruleEditDialogVisible, setRuleEditDialogVisible] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Entities.ProfileChannelAccount[]>([]);
  const [ruleList, setRuleList] = useState<OverwriteRule[]>([]);
  const [filteredList, setFilteredList] = useState<OverwriteRule[]>([]);
  const [mode, setMode] = useState<'New' | 'Edit'>('New');
  const [current, setCurrent] = useState<OverwriteRule>();
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterChannelAccount, setFilterChannelAccount] = useState<number>();
  const debounceFilterValue = useDebounce(filterValue, 500);

  const columns = [
    {
      header: 'Rule Name',
      name: 'ruleName',
      defaultFlex: 1,
    },
    {
      header: 'Rule Type',
      name: 'ruleType',
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;
        return { 1: 'Absolute Value', 2: 'At Least', 3: 'Lower to Zero' }[data.ruleType as 1 | 2 | 3];
      }
    },
    {
      header: 'Channel Account',
      name: 'channelAccountName',
      defaultFlex: 1,
    },
    {
      header: 'Last Updated By',
      name: 'updateBy',
      defaultFlex: 1,
    },
    {
      header: 'Update Date',
      name: 'lastUpdateDate',
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;
        return convertTimeByUtc(data.lastUpdateDate);
      }
    },
    {
      header: 'Action',
      defaultFlex: 1,
      render: (row: { data: OverwriteRule; }) => {
        const { data } = row;
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setMode('Edit');
                setCurrent(data);
                setRuleEditDialogVisible(true);
              }}
            >
              Edit
            </Button>
            <Dropdown overlay={
              <Menu style={{padding: 0}}>
                <Menu.Item style={{padding: 0}}>
                  <HoverDangerButton
                    type="link"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to delete this item?',
                        onOk: () => { handleDelete(data) },
                        onCancel() { },
                      })
                    }}
                  >
                    Delete
                  </HoverDangerButton>
                </Menu.Item>
              </Menu>
            }>
              <Button>...</Button>
            </Dropdown>
          </Space>
        )
      }
    },
  ]

  const handleDelete = async (data: OverwriteRule) => {
    setLoading(true);
    try {
      await deleteOverwriteRule(data.ruleNum);
      message.success('Delete item successfully!')
      getOverwriteRuleList();
    } catch (error) {
      message.error('Delete item failed')
    } finally {
      setLoading(false);
    }
  }

  const getOverwriteRuleList = async () => {
    setLoading(true);
    try {
      const res = await fetchOverwriteRuleList();
      if (Array.isArray(res)) {
        const list = res.map(item => {
          return { ...item, ruleExpression: JSON.parse(item.ruleExpression) };
        })
        setRuleList(list);
        setFilteredList(list);
        setFilterValue('');
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  }

  const getChannelAccountList = async () => {
    setAccounts([]);
    setLoading(true);
    try {
      const res = await listProfileChannelAccounts();
      if (res) {
        setAccounts(res);
      }
    } catch (error) {
      console.log('error = ', error);
      message.error('Fetch channel account list failed!')
    } finally {
      getOverwriteRuleList();
    }
  }

  useEffect(() => {
    if (ruleList.length === 0) return;
    const upperValue = filterValue.toUpperCase();
    let temp = [...ruleList];
    setFilteredList(temp.filter(item => {
      if (filterChannelAccount !== undefined) return item.ruleName.toUpperCase().includes(upperValue) && item.channelAccountNum === filterChannelAccount;
      return item.ruleName.toUpperCase().includes(upperValue);
    }))
    // eslint-disable-next-line
  }, [debounceFilterValue, filterChannelAccount])

  useEffect(() => {
    getChannelAccountList();
    // eslint-disable-next-line
  }, [])

  return (
    <ContentLayout>
      <Heading
        title="Qty Overwrite Rule"
        actions={(
          <Row gutter={12}>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  setMode('New');
                  setRuleEditDialogVisible(true);
                }}
              >
                <PlusOutlined />
                Add New Rule
              </Button>
            </Col>
          </Row>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row gutter={8}>
          <Col xs={12} md={6}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search Filter"
              allowClear
              value={filterValue}
              onChange={e => setFilterValue(e.target.value)}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              showSearch
              allowClear
              optionFilterProp="label"
              style={{ width: '100%' }}
              placeholder="Select channel account"
              value={filterChannelAccount}
              onChange={e => setFilterChannelAccount(e)}
              options={accounts.map(account => {
                return {
                  key: account.ChannelAccountNum,
                  value: account.ChannelAccountNum,
                  label: account.ChannelAccountName
                }
              })}
            />
          </Col>
        </Row>
        <Spacer height={14} />
        <DataGrid
          loading={loading}
          dataSource={filteredList}
          columns={columns}
          pagination={{ showSizeChanger: true }}
        />
      </SiteContent>
      {
        ruleEditDialogVisible && (
          <RuleEditDialog
            mode={mode}
            rule={current}
            visible={ruleEditDialogVisible}
            channelAccountList={accounts}
            onClose={() => setRuleEditDialogVisible(false)}
            onRefresh={() => {
              setRuleEditDialogVisible(false);
              getOverwriteRuleList();
            }}
          />
        )
      }
    </ContentLayout >
  )
}

export default QtyOverwriteRule;