import { CloudDownloadOutlined } from '@ant-design/icons';
import { Row, Button, Modal, Card, Typography, Space, Switch, Col } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { downloadXlsxFromCsv } from '../../util/files';

const { Text, Title } = Typography;
// const { TabPane } = Tabs;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const CardSpace = styled(Space)`
  width: 850px;
`;

const ExportDialog: React.FC<{
  account: ChannelAccount;
  allData: any[];
  columns: any[];
  selectedRows: any[];
  fileName: string;
  filterArr: string[];
  downloadUrl: any;
  typeName: string;
  onClose: () => void;
  formatColumns?: any
}> = (props) => {
  const {
    columns,
    allData,
    selectedRows,
    onClose,
    fileName,
    account,
    filterArr,
    downloadUrl,
    typeName,
    formatColumns,
  } = props;
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const [exportCsv, setExportCsv] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const handleDownload = useCallback(
    (downloadData: Array<any>, filterArr: string[], fileName: string) => {
      const headers = columns
        .map(
          (item) =>
            item.header.replace('\n', '').replace('\r', '') ||
            item.title.replace('\n', '').replace('\r', ''),
        )
        .filter((i) => filterArr.indexOf(i) < 0);
      const lines = [] as any;
      lines.push(
        headers
          .map((headerItem) => {
            let formattedHeader = headerItem;
            if (typeof headerItem === 'string') {
              if (headerItem.includes(',')) {
                if (headerItem.includes('"')) {
                  formattedHeader = headerItem.replace(/"/g, '""');
                }
                formattedHeader = `"${headerItem}"`;
              }
            } else {
              formattedHeader = '';
            }
            return formattedHeader;
          })
          .join(','),
      );
      downloadData.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          // value = row[dataIndex];
          value = (formatColumns && formatColumns[dataIndex] && row[dataIndex]) ? formatColumns[dataIndex][`${row[dataIndex]}`] : row[dataIndex];
          if (dataIndex === 'VariationParentSKU') value = row.styleCode || row.VariationParentSKU;
          if (dataIndex === 'ProductId') value = row.colorPatternCode || '';
          if (typeof value === 'string') {
            // replace \n with \\n,
            value = value.replaceAll('\n', '\\n');
            if (value.includes(',')) {
              if (value.includes('"')) {
                value = value.replaceAll(/"/g, '""');
              }
              value = `"${value}"`;
            }
          } else if (typeof value === 'number') {
            value = `"${value}"`;
          } else {
            value = '';
          }
          items.push(value);
        });
        lines.push(items.join(','));
      });
      const csvString = lines.join('\n');
      const fileNameString = `${fileName}_${moment().format('YYYYMMDD')}`;
      if (!exportCsv) {
        // export as xlsx
        downloadXlsxFromCsv(csvString, fileNameString);
        return;
      }
      // return;
      const blob = new Blob([csvString], { type: 'application/csv' });
      const name = `${fileNameString}.csv`;
      if ('download' in document.createElement('a')) {
        // No IE Download
        const elink = document.createElement('a');
        elink.download = name;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // free url
        document.body.removeChild(elink);
      } else {
        // IE10+ download
        //navigator.msSaveBlob(blob, name);
      }
    },
    [selectedColumns, columns, exportCsv, formatColumns],
  );

  const getAllData = async () => {
    try {
      setExportLoading(true);
      const res = await downloadUrl(account.ChannelNum, account.ChannelAccountNum);
      setExportLoading(false);
      if (res) {
        const data = res.data.map((i: any) => {
          return { 
            ...i, 
            generateTime: res.time,
            localGenerateTime: moment(res.time).format('MM/DD/YYYY - hh:mm A'),
            localChannelAckDate: i.channelAckDate ? moment(i.channelAckDate).format('MM/DD/YYYY - hh:mm A') : '',
            localLastUploadDate: i.lastUploadDate ? moment(i.lastUploadDate).format('MM/DD/YYYY - hh:mm A') : '',
          };
        });
        handleDownload(data, filterArr, fileName);
      }
    } catch (error) {
      setExportLoading(false);
    }
  };

  useEffect(() => {
    const checkedList: Array<string> = [];
    columns.forEach((item: any) => {
      if (
        filterArr.indexOf(
          (item.header || '').replace('\n', '').replace('\r', '') ||
            (item.title || '').replace('\n', '').replace('\r', ''),
        ) < 0
      ) {
        checkedList.push(item.dataIndex || item.name);
      }
    });
    setSelectedColumns(checkedList);
  }, [columns, filterArr]);

  return (
    <Modal
      visible
      width={900}
      title={
        <Row justify="center">
          <Title level={4}>Channel Audit Export</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
      closable={false}
    >
      <CardSpace direction="vertical">
        <Row>
          <Col style={{ marginRight: '40px' }}>
            <Text strong>{`Channel Account: ${account.ChannelAccountName}`}</Text>
          </Col>
          <Col>
            <Text strong>{`Type: ${typeName}`}</Text>
          </Col>
        </Row>
        <Col style={{ padding: '5px' }}>
          <Space>
            <Text>Export As</Text>
            <Switch
              checked={exportCsv}
              onChange={setExportCsv}
              checkedChildren="CSV"
              unCheckedChildren="XLSX"
              style={{
                backgroundColor: '#006dff',
              }}
            />
          </Space>
        </Col>
        <Card
          size="small"
          bodyStyle={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: 15,
          }}
        >
          <Space size="large">
            <Button
              type="primary"
              disabled={selectedRows.length === 0}
              onClick={() => handleDownload(selectedRows, filterArr, fileName)}
            >
              Export Selected
            </Button>
            <Button
              type="primary"
              disabled={allData.length === 0}
              onClick={() => handleDownload(allData, filterArr, fileName)}
            >
              Export Current Page
            </Button>
            <Button
              type="primary"
              loading={exportLoading}
              onClick={() => {
                getAllData();
              }}
            >
              Export All
              <CloudDownloadOutlined />
            </Button>
          </Space>
        </Card>

        <Row justify="end">
          <Button type="primary" onClick={() => onClose()}>
            Close
          </Button>
        </Row>
      </CardSpace>
    </Modal>
  );
};

export default ExportDialog;
