import React from 'react';
import ContentLayout from '../components/ContentLayout';
import Spacer from '../components/common/Spacer';

// TODO: streamline links here and sidebar
const Templates: React.FC = () => {

  return (
    <ContentLayout>
      {/* <Heading title="Predefined Operation Sets" /> */}
      <Spacer />
      {/* <SiteContent style={{ padding: '24px' }}>
      </SiteContent> */}
    </ContentLayout>
  );
};

export default Templates;
