import React, { useReducer, createContext } from 'react';

export const Action = {
  SetChangedNodes: 'SetChangedNodes',
  SetCurrentItem: 'SetCurrentItem',
  SetExpandedKeys: 'SetExpandedKeys',
  SetHideField: 'SetHideField',
  SetIsLoading: 'SetIsLoading',
  SetTowers: 'SetTowers',
};

export const initialState = {
  changedNodes: [],
  currentItem: {},
  expandedKeys: [],
  hideField: 0,
  isLoading: false,
  towers: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Action.SetChangedNodes:
      return {
        ...state,
        changedNodes: action.payload,
      };

    case Action.SetCurrentItem:
      return {
        ...state,
        currentItem: action.payload,
      };

    case Action.SetExpandedKeys:
      return {
        ...state,
        expandedKeys: action.payload,
      };

    case Action.SetHideField:
      return {
        ...state,
        hideField: action.payload,
      };

    case Action.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };


    case Action.SetTowers:
      return {
        ...state,
        towers: action.payload,
      };

    default:
      break;
  }
  //return state;
};

export const StoreContext = createContext(initialState);

export const StoreContainer = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{state, dispatch}}>
      {children}
    </StoreContext.Provider>
  )
};

export const findNodeByKey = (nl, key) => {
  if (!key) return null;

  if (Array.isArray(nl)) {
    for (let i = 0; i < nl.length; i++) {
      if (nl[i].key === key) {
        return nl[i];
      } else if (Array.isArray(nl[i].children)) {
        const n = findNodeByKey(nl[i].children, key);

        if (n) return n;
      }
    }
  }

  return null;
};
