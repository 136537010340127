import {
  PlusCircleOutlined,
  CloseOutlined,
  EditOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Row, Spin, Modal, Space } from 'antd';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid3';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ConfirmationModal from '../../components/ConfirmationModal';
import ContentLayout from '../../components/ContentLayout';
import ModalForm from './AddAttributeSet';
import SiteContent from '../../components/SiteContent';
import { Permissions, ViewLevel } from '../../constants/enums/permissions';
import notificationActions from '../../redux/actions/notifications';
import { ProductDetailDialogBodyWrapper } from '../../components/common/styledComponents';
import {
  addProductAttributeSet,
  editProductAttributeSet,
  deleteProductAttributeSet,
  fetchProductAttributeSets,
} from '../../services/products';
import {
  getReducerInitialState,
  attributeSetsReducer,
  AttributeSetsScreenContext,
  StateActions,
} from './context';
import ManageAttributes from './ManageAttributes';

const AddButton = styled(Button)<{ $hasPermission: boolean }>`
  display: ${(props) => (props.$hasPermission ? 'initial' : 'none')};
  min-height: 40px;
`;

const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
  if (permissions && permissions.size === 0) return false;
  return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
};

const searchFields: Array<SearchField<Entities.IAttributeSet> | string> = [
  'attributeSetName',
  'attributeSetDescription',
];

const PageContent: React.FC = () => {
  const [state, dispatch] = useContext(AttributeSetsScreenContext);
  const profiles = useSelector((reduxState: any) => reduxState.profiles);
  const hasPermission = hasPermissions(
    profiles.permissions,
    Permissions.MANAGE_PRODUCT_ELEMENTS,
    ViewLevel.VIEW,
  );

  const onSearchResult = useCallback(
    (newData) => {
      if (!dispatch) {
        return;
      }
      dispatch({ type: StateActions.SET_FILTERED_DATA, params: newData });
    },
    [dispatch],
  );

  if (!state || !dispatch) {
    return null;
  }

  const onDelete = (data: any) => dispatch({ type: StateActions.SET_ROW_TO_DELETE, params: data });

  const columns = [
    {
      header: 'Name',
      name: 'attributeSetName',
      defaultFlex: 3,
      editable: false,
    },
    {
      header: 'Description',
      name: 'attributeSetDescription',
      defaultFlex: 4,
      editable: false,
    },
    {
      name: 'rowNum',
      header: '',
      defaultFlex: 3,
      minWidth: 350,
      sortable: false,
      editable: false,
      render({ data, rowIndex, rowId, columnId, columnIndex }: any) {
        return (
          <Row justify="end" gutter={14}>
            <Col>
              <Button
                size="small"
                disabled={!hasPermission}
                onClick={() => {
                  dispatch && dispatch({ type: StateActions.SET_SELECTED_ROW, params: data });
                  dispatch &&
                    dispatch({ type: StateActions.SET_MANAGE_MODAL_VISIBLE, params: true });
                }}
              >
                <EditOutlined style={{ color: '#006dff' }} />
                Manage Attributes
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                disabled={!hasPermission}
                onClick={() => {
                  dispatch({ type: StateActions.SET_FORM_VALUES, params: { ...data } });
                  dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: true });
                }}
              >
                <EditOutlined style={{ color: '#006dff' }} />
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                disabled={!hasPermission}
                onClick={() => {
                  onDelete(data);
                }}
              >
                <DeleteOutlined style={{ color: '#c13939' }} />
                Delete
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={state.isSaving} wrapperClassName="ant-spin-flex">
      <Row justify="start">
        <Col xs={24} md={12}>
          <SearchBar
            reference="rowNum"
            data={state.data}
            onResult={onSearchResult}
            fields={searchFields}
            disabled={state.searchDisabled}
          />
        </Col>
      </Row>
      <Spacer height={14} />
      <DataGrid
        idProperty="rowNum"
        rowHeight={35}
        columns={columns}
        dataSource={state.filteredData}
        loading={state.loadingData}
        pagination
        otherButtons={['Manage Attributes']}
      />
    </Spin>
  );
};

interface AttributeSetsProps {
  isModal: boolean;
}

const AttributeSets: React.FC<AttributeSetsProps> = ({ isModal }: AttributeSetsProps) => {
  const profiles = useSelector((state: any) => state.profiles);
  const reduxActions = bindActionCreators(notificationActions, useDispatch());
  const reducer = useReducer(attributeSetsReducer, getReducerInitialState(Form.useForm()[0]));
  const [state, dispatch] = reducer;

  const refreshData = () => {
    dispatch({ type: StateActions.SET_LOADING_DATA, params: true });

    const paginationParams = {
      $top: state.pagination.pageSize,
      $skip:
        state.pagination.current <= 1
          ? 0
          : state.pagination.pageSize * (state.pagination.current - 1) + 1,
    };
    const params = {
      $count: true,
      ...paginationParams,
    };

    fetchProductAttributeSets(params)
      .then((res) => dispatch({ type: StateActions.SET_DATA, params: res }))
      .finally(() => dispatch({ type: StateActions.SET_LOADING_DATA, params: false }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshData, []);

  const dialogWidth = useCallback(() => {
    return state.dialogIsFullscreen
      ? window.innerWidth
      : window.innerWidth > 1280
      ? window.innerWidth * 0.8
      : 1200;
  }, [state]);

  return (
    <AttributeSetsScreenContext.Provider value={reducer}>
      <ContentLayout isModal={isModal}>
        <ConfirmationModal
          visible={state.confirmationModalVisible}
          title={`Are you sure you want to delete ${state.selectedProductAttributeSet.attributeSetName}`}
          handleCancel={() =>
            dispatch({ type: StateActions.SET_CONFIRMATION_MODAL_VISIBLE, params: false })
          }
          handleConfirm={async () => {
            dispatch({ type: StateActions.SET_IS_SAVING, params: true });
            try {
              await deleteProductAttributeSet(`${state.selectedProductAttributeSet.rowNum}`);
              refreshData();
              reduxActions.setNotification('success', 'Operation set deleted');
              // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
              dispatch({ type: StateActions.SET_IS_SAVING, params: false });
            }
          }}
        />

        {state.addModalVisible && (
          <ModalForm
            visible
            formValues={state.formValues}
            handleChange={(e: any) => {
              // This will cause lots of re-renders
              dispatch({
                type: StateActions.SET_FORM_VALUES,
                params: { ...state.formValues, [e.target.name]: e.target.value },
              });
            }}
            handleCancel={() => {
              dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: false });
            }}
            submit={async () => {
              if (state.formValues.rowNum) {
                await editProductAttributeSet(
                  {
                    attributeSetDescription: state.formValues.attributeSetDescription,
                    attributeSetName: state.formValues.attributeSetName,
                  },
                  state.formValues.rowNum,
                );
                dispatch({ type: StateActions.RESET_FORM });
                reduxActions.setNotification('success', 'Operation set edited');
              } else {
                await addProductAttributeSet({
                  attributeSetDescription: state.formValues.attributeSetDescription,
                  attributeSetName: state.formValues.attributeSetName,
                });
                dispatch({ type: StateActions.RESET_FORM });
                reduxActions.setNotification('success', 'Operation set added');
              }
              dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: false });
              refreshData();
            }}
          />
        )}

        <Heading
          title="Operation Sets"
          actions={
            <Space>
              {/* <Link to="/predefined-attribute-sets">
                <Button style={{ height: 40 }}>Predefined Operation Sets</Button>
              </Link> */}
              <AddButton
                type="primary"
                $hasPermission={hasPermissions(
                  profiles.permissions,
                  Permissions.MANAGE_PRODUCT_ELEMENTS,
                  ViewLevel.VIEW,
                )}
                onClick={() => {
                  dispatch({ type: StateActions.RESET_FORM });
                  dispatch({ type: StateActions.SET_ADD_MODAL_VISIBLE, params: true });
                }}
              >
                <PlusCircleOutlined />
                Add Operation Set
              </AddButton>
            </Space>
          }
        />
        <Spacer />
        <SiteContent flexGrow>
          <PageContent />
        </SiteContent>
      </ContentLayout>
      {state.manageModalVisible && (
        <Modal
          bodyStyle={{ backgroundColor: '#F0F2F5', padding: 12 }}
          centered
          className="fullscreen-modal"
          wrapClassName='ant-spin-flex'
          closable={false}
          footer={
            state.dialogIsFullscreen ? (
              <Row justify="end">
                <Button
                  onClick={() =>
                    dispatch &&
                    dispatch({ type: StateActions.SET_MANAGE_MODAL_VISIBLE, params: false })
                  }
                >
                  Cancel
                </Button>
              </Row>
            ) : null
          }
          onCancel={() =>
            dispatch && dispatch({ type: StateActions.SET_MANAGE_MODAL_VISIBLE, params: false })
          }
          style={{ paddingBottom: 0 }}
          //title={currentProduct.SKU}
          title={
            <Row align="middle" justify="space-between">
              <span>Manage Attributes</span>
              <Space>
                {/* <Button
                  type="link"
                  href={`/product-detail/${currentProductId}`}
                  target="_blank"
                  style={{ border: '1px solid #D9D9d9' }}
                >
                  Open As a New Tab
                  <LinkOutlined />
                </Button> */}
                <Button
                  onClick={() =>
                    dispatch &&
                    dispatch({
                      type: StateActions.SET_DIALOG_IS_FULL_SCREEN,
                      params: !state.dialogIsFullscreen,
                    })
                  }
                  >
                  {state.dialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  {state.dialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
                </Button>
                <Button
                  onClick={() =>
                    dispatch &&
                    dispatch({ type: StateActions.SET_MANAGE_MODAL_VISIBLE, params: false })
                  }
                >
                  <CloseOutlined />
                  Close
                </Button>
              </Space>
            </Row>
          }
          visible={state.manageModalVisible}
          width={dialogWidth()}
        >
          <ProductDetailDialogBodyWrapper
            className={state.dialogIsFullscreen ? 'fullscreen-mode' : ''}
          >
            <ManageAttributes
              attribute={state.selectedProductAttributeSet}
              handleCancel={() =>
                dispatch && dispatch({ type: StateActions.SET_MANAGE_MODAL_VISIBLE, params: false })
              }
            />
          </ProductDetailDialogBodyWrapper>
        </Modal>
      )}
    </AttributeSetsScreenContext.Provider>
  );
};

export default AttributeSets;
