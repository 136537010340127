import MockAdapter from 'axios-mock-adapter';
import ordersService from '../mocks/orders.json';
import { digitBridgeApi } from './http';

function injectMockTest() {
  const mock = new MockAdapter(digitBridgeApi, { delayResponse: 300 });
  const responseOrders = ordersService.response.orders;
  mock.onGet('/orders').reply(
    200,
    responseOrders,
  );
}

if (process.env.NODE_ENV === 'test') {
  injectMockTest();
}

export interface IOrders {
  channelOrderID: string,
  channelName: string,
  endBuyerName: string,
  sku: string,
  originalOrderDateUtc: string,
  orderStatusName: string,
  type: string,
}

interface IOrderParams {
  masterAccountNum: number;
  profileNum: number;
  channelName?: string;
  orderDateFrom?: string;
  orderDateTo?: string;
  channelOrderID?: string;
  buyerEmail?: string;
  billingZipCode?: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingAddressLine1?: string;
  shippingAddressLine2?: string;
  orderStatusName?: string;
  trackingNumber?: string;
  channelItemID?: number;
  sku?: string;
  $top?: number;
  $skip?: string;
  $count?: boolean;
}

export const fetchOrders = async (params: IOrderParams): Promise<{ orders: Entities.SimpleOrder[], count: number }> => digitBridgeApi.get('/v1/orders', {
  params,
});

export const fetchOrderDetail = async (orderId: string): Promise<any> => digitBridgeApi.get(`/v1/orders/${orderId}`, {
  params: {
    masterAccountNum: 10001,
    profileNum: 10001,
  },
});

export const createShipment = async (params: Entities.Shipments): Promise<any> => {
  const { ResultMessage, ResultStatus }: any = await digitBridgeApi.post('/v1/shipments', {
    ...params,
  });
  if (ResultStatus === 0) {
    throw (ResultMessage);
  } else {
    return ResultMessage;
  }
};

export const fetchSystemCarriersAndServices = async (): Promise<any> => digitBridgeApi.get('/v1/shipping/systemCarriersAndServices');
