import styled from 'styled-components';
import { Button } from 'antd';

export interface IButton {
  $hasPermission: boolean
}

export const AddButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: auto;
  display: ${(props: IButton) => props.$hasPermission ? 'initial' : 'none'};
`;

export const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left;
`;
