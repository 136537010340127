import React, { useRef } from "react";
import {
  Button,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  notification,
} from "antd";
import { BarsOutlined, CloseOutlined, SaveOutlined, HolderOutlined } from "@ant-design/icons";
//import TextInput from '@inovua/reactdatagrid-community';
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import ExpandableSearch from "../../components/common/ExpandableSearch";
import Loading from "../../components/common/Loading";
import ModalDialog from "../../components/common/ModalDialog";
import ScreenMask from "../../components/common/ScreenMask";
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  ATTRIBUTE_DATA_TYPE_VIRTUAL,
} from "../../constants/config";
import { bulkSaveContentResource } from "../../services/copywriting";
import ImagePlaceholder from "../../assets/images/preview_image.jpeg";
import {
  InitialStateProps,
  initialState,
  reducer,
} from "../DetailProduct/context";
import OverwriteOptionList from "../DetailProduct/OverwriteOptionList";
import BulkEditColumnSelector from "./BulkEditColumnSelector";
import { GROUP_OPTION_COLOR, GROUP_OPTION_STYLE } from ".";
import { SOURCE_TYPE_ERP } from "../DetailProduct/CopywritingSteps";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import CellEditor from './BulkEditCellEditor';

type Props = {
  columns: StringKAnyVPair[];
  //dataSource: StringKAnyVPair[] | (() => Promise<any>);
  dataSource: StringKAnyVPair[];
  displayGroup: number;
  refresh?: Function;
  onClose: Function;
  eleAttrs: StringKAnyVPair[];
  visible: boolean;
};

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  > .label {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }

  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const GridWrapper = styled.div`
  margin-bottom: 12px;

  & .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding) {
    padding: 1px;
  }

  & .InovuaReactDataGrid__column-header__content {
    align-content: center;
    height: 100%;
    padding: 0;
  }

  & .ant-input {
    border-radius: 0;
  }

  & .cell-bulk-edit-value {
    color: #1F1F1F;
  }

  & .cell-bulk-edit-wrapper {
    height: 100%;
    background-color: #52C41A;
    color: #FFF;
    padding-left: 12px;
  }

  & .cell-input-wrapper {
    width: 100%;
  }

  & .grid-bulk-edit-bg-header {
    background-color: #52C41A;
    color: #FFF;
    height: 100%;
  }

  & .grid-bulk-edit-ext-header {
    z-index: 1;
    background-color: #52C41A;
    padding: 8px;
  }

  & .grid-bulk-header-edit-btn-bar {
    width: 100%;
  }

  & .grid-bulk-header-edit-btn-bar .ant-btn {
    padding: 0 6px;
  }

  & .grid-bulk-header-edit-toolbar {
    width: 100%;
  }

  & .grid-bulk-header-edit-toolbar .bulk-close-button {
    cursor: pointer;
    padding: 0 2px;
  }

  & .grid-editable-header,
  & .grid-editable-header .ant-select {
    width: 100%;
  }

  & .grid-editable-header .header-ctn {
    margin: 8px;
  }
`;

const getOptions = (opts: StringKAnyVPair[]) => {
  const ret: any[] = [];

  opts.forEach((opt) => {
    if ('value' in opt) {
      ret.push({
        label: opt.label || opt.value,
        value: opt.value,
      });
    }
  });

  return ret;
};

const BulkEditDialog = (props: Props) => {
  const HEADER_EDIT_CTN_CLASS = 'grid-editable-header';
  const { useCallback, useReducer, useState } = React;
  const [bulkEditColId, setBulkEditColId] = useState('');
  const [bulkEditColLastId, setBulkEditColLastId] = useState('');
  const [bulkEditRowSelectAllOnce, setBulkEditRowSelectAllOnce] = useState(false);
  //const [bulkEditValue, setBulkEditValue] = useState('');
  const [bulkEditVersion, setBulkEditVersion] = useState(0);
  const [bulkEditLastVersion, setBulkEditLastVersion] = useState(0);
  const [bulkInputViewVersion, setBulkInputViewVersion] = useState(0);
  const [bulkInputViewLastVersion, setBulkInputViewLastVersion] = useState(0);
  const [colDataAttrDict, setColDataAttrDict] = useState<StringKAnyVPair>({});
  const [columnSelectorVisible, setColumnSelectorVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [columns, setColumns] = useState<StringKAnyVPair[]>([]);
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [dataVisualList, setDataVisualList] = useState<StringKAnyVPair[]>([]);
  const [editDict, setEditDict] = useState<Record<string, StringKAnyVPair>>({});
  const [editLoading, setEditLoading] = useState(false);
  const [fieldSearchStr, setFieldSearchStr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gridRef, setGridRef] = useState<any>(null);
  const [selected, setSelected] = React.useState<any>({});
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showRowCheckbox, setShowRowCheckbox] = useState(false);

  const [state, dispatch] = useReducer(
    reducer,
    initialState({} as InitialStateProps)
  );

  const bulkEditColList = useRef<StringKAnyVPair[]>([]);
  const bulkEditDict = useRef<Record<string, StringKAnyVPair>>({});
  const bulkEditInputRef = useRef<any>({});
  const bulkEditValue = useRef<any>(undefined);
  const bulkInputUpdateTimer = useRef<any>(null);
  const gridVersion = useRef(0);
  const inputViewVersion = useRef(0);
  const selectedRowIdList = useRef<string[]>([]);

  const BULK_RO_COLUMNS = [
    "mediaURL",
    "VariationParentSKU",
    "subStyleCode",
    "SKU",
  ];

  const bulkEditColHeaderRender = (
    cellProps: any,
    col: StringKAnyVPair,
  ) => {
    const headerRowCls: string[] = [];
    const headerStyle: StringKAnyVPair = {};
    const rowStyle: StringKAnyVPair = {};
    let opts: StringKAnyVPair[] = [];

    if (col.dataAttr && typeof col.dataAttr === 'object') {
      const attr = getDataAttrById(col.dataAttr.attributeNum);
      if (attr && typeof attr === 'object') {
        opts = getAttrOptions(attr);
        //console.log('opts', opts);
      }
    }

    if (col.isBulkEditing) {
      //console.log('rh', col.dataAttr);
      headerRowCls.push('grid-bulk-edit-bg-header');

      rowStyle.width = col.width * 2 + 1;
      headerStyle.width = col.width;
    }

    return (<Row id={col.uuid} className={headerRowCls.join(' ')} style={rowStyle}>
      <div className={HEADER_EDIT_CTN_CLASS} style={headerStyle}>
        <div className="header-ctn">
          <div>{col.header}</div>
          <div>
          {opts.length > 0 && (
            <Select
              defaultValue={columnBulkEditDefaultValue(col)}
              onChange={onBulkSelectChange}
              onFocus={(evt) => onFieldBulkEditFocus(evt, col)}
              options={getOptions(opts)}
              ref={el => bulkEditInputRef.current[col.uuid] = el}
            />
          )}
          {opts.length === 0 && (
            <Input
              allowClear
              defaultValue={columnBulkEditDefaultValue(col)}
              onChange={onBulkInputChange}
              onFocus={(evt) => onFieldBulkEditFocus(evt, col)}
              onKeyDown={onBulkInputKeyDown}
              ref={el => bulkEditInputRef.current[col.uuid] = el}
            />
          )}
          </div>
        </div>
      </div>
      {col.isBulkEditing && (
        <Row
          align="middle"
          className="grid-bulk-edit-ext-header"
          justify="center"
          style={headerStyle}
        >
          <Row
            className="grid-bulk-header-edit-toolbar"
            justify="end"
          >
            <Tooltip title="Close bulk edit">
              <span className="bulk-close-button" onClick={exitColumnBulkEdit}><CloseOutlined /></span>
            </Tooltip>
          </Row>
          <Row
            className="grid-bulk-header-edit-btn-bar"
            justify="center"
          >
            <Space>
              <Button onClick={() => updateBulkEditValueToCell(col)}>
                Update
              </Button>
              <Button onClick={exitColumnBulkEdit}>
                Cancel
              </Button>
            </Space>
          </Row>
        </Row>
      )}
    </Row>);
  };

  const columnBulkEditDefaultValue = (col: StringKAnyVPair) => {
    //return bulkEditValue.current;
    return col.isBulkEditing && !isBulkEditValueInitial() ? bulkEditValue.current : undefined;
  };

  const clearBulkEditData = (col: StringKAnyVPair) => {
    if (col.isBulkEditing) {
      col.isBulkEditing = false;
      //setBulkEditColId('');
      bulkEditValue.current = undefined;
    }
  };

  const exitColumnBulkEdit = () => {
    const col = getBulkEditCol();

    if (col.isBulkEditing) {
      clearBulkEditData(col);
      upgradeGridVersion();
    }
  };

  const getBulkEditCol = (): StringKAnyVPair => {
    const bs = bulkEditColList.current.filter(e => e.isBulkEditing);

    return bs.length === 1 ? bs[0] : {};
  };

  // eslint-disable-next-line
  const getBulkEditColAttrNum = () => {
    const col = getBulkEditCol();

    return col.dataAttr ? col.dataAttr.attributeNum : 0;
  };

  const getBulkEditValueViewId = (row: StringKAnyVPair) => {
    return `bv-${row.uuid}`;
  };

  const getColByUuid = (uuid: string) => {
    const cs = columns.filter(col => uuid === col.uuid);

    return cs.length === 1 ? cs[0] : null;
  };

  const getDataAttrById = (id: number) => {
    const fs = props.eleAttrs.filter((e) => e.copywritingElementNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (typeof attr.optionList === "string" && attr.optionList.trim()) {
        attr.optionList
          .split("|")
          .map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const bulkUpdateCell = useCallback(
    async (from: string, to: string) => {
      if (from === to) return;
      setEditLoading(true)
      try {
        const [index1, fromName] = from.split(",");
        const [index2, toName] = to.split(",");
        if (fromName !== toName) return;
        const dict = { ...editDict };
  
        const [formIndex, toIndex] =
          Number(index1) >= Number(index2)
            ? [Number(index2), Number(index1)]
            : [Number(index1), Number(index2)];
        const value = dataSource[Number(index1)][fromName];
  
        for(let i=formIndex; i <= toIndex; i++){
          dataSource[i][fromName] = value;
          if (!dict[dataSource[i].ProductId]) dict[dataSource[i].ProductId] = {};
          dict[dataSource[i].ProductId] = { ...dict[dataSource[i].ProductId], [fromName]: value };
        }
  
        // dataSource
        //   .filter((k, index) => index >= formIndex && index <= toIndex)
        //   .forEach((col) => {
        //     col[fromName] = value;
        //     if (!dict[col.ProductId]) dict[col.ProductId] = {};
        //     dict[col.ProductId] = { ...dict[col.ProductId], [fromName]: value };
        //   });
        setDataSource([...dataSource]);
        setEditDict({ ...dict });
        setEditLoading(false)
      } catch (error) {
        setEditLoading(false)
      }
    },
    [dataSource, editDict]
  );

  const DraggableItem = ({
    index,
    value,
    cellProps,
    bulkUpdateCell,
    attributeNum,
    column,
    data,
    rowId,
  }: {
    attributeNum: number;
    index: string;
    value: string;
    cellProps: any;
    bulkUpdateCell: any;
    column: StringKAnyVPair;
    data: StringKAnyVPair;
    rowId: string;
  }) => {
    // console.log(index, value)
    const ref = useRef<any>();
    const bvId = getBulkEditValueViewId(data);
    const cellStyle: StringKAnyVPair = {};
    const rowStyle: StringKAnyVPair = {width: '100%'};
    let v = cellProps.editProps.inEdit ? cellProps.editProps.value : value;
    let attr: any = undefined;
    let opts: any = undefined;

    if (attributeNum) {
      attr = getDataAttrById(attributeNum);
      opts = getAttrOptions(attr);
    }

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: "DraggableItem",
      collect: (monitor) => {
        const res: any = monitor.getItem() || {};
        if (res.index === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            res.index < index ? ` drop-over-downward` : ` drop-over-upward`,
        };
      },
      drop: (item: any) => {
        bulkUpdateCell(item.index, index);
        upgradeGridVersion();
      },
    });

    const [, drag, preview] = useDrag({
      type: "DraggableItem",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // eslint-disable-next-line
    const checkBulkOutline = () => {
      if (column.isBulkEditing) {
        if (ref.current) {
          ref.current.style.width = 'max-content';
          ref.current.parentNode.style.overflow = 'visible';
        }
      }
    };
    
    const isBulkWritable = (column: StringKAnyVPair, rowId: string) => {
      let ret = false;

      if (column.isBulkEditing) {
        const ids: string[] = selectedRowIdList.current || [];

        ret = ids.indexOf(rowId) > -1;
      }

      return ret;
    };

    const onClick = (evt: any) => {
      if (!cellProps.inEdit) {
        //console.log('clicking on cell may need edit', cellProps, evt, evt.target ,evt.target.selectionStart, evt.target.selectionEnd);
        evt.target.focus();
      }
    };

    preview(drop(ref));
    //console.log('drag inner', attributeNum, column.isBulkEditing);
    if (column.isBulkEditing) {
      rowStyle.width = column.width * 2 + 1;
      rowStyle.zIndex = 1;
      cellStyle.width = column.width;
    }

    React.useEffect(() => {
      checkBulkOutline();
    }, [checkBulkOutline]);

    return (
      <ItemWrapper
        key={index}
        ref={ref}
        className={`${isOver ? dropClassName : ""}`}
      >
        <Row style={rowStyle} ref={drag}>
          <div className="cell-input-wrapper" style={cellStyle}>
          {attr && opts.length > 0 ? (<>
            <Select              
              value={v}
              onChange={(value)=>cellProps.editProps.onChange({target :{value}})}
              allowClear
              style={{ width: "calc(100% - 20px)" }}
              onFocus={() => cellProps.editProps.startEdit()}
              options={getOptions(opts || [])}
              onBlur={(e) => {
                cellProps.editProps.onComplete();
              }}
            />
            <Button type="text" style={{width: 20}} icon={<HolderOutlined />} />
          </>) : (
            <Input
              type="text"
              autoFocus={cellProps.inEdit}
              value={v}
              onBlur={(e) => {
                cellProps.editProps.onComplete();
              }}
              onChange={cellProps.editProps.onChange}
              onClick={onClick}
              onFocus={() => cellProps.editProps.startEdit()}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  cellProps.editProps.onCancel(e);
                }
                if (e.key === "Enter") {
                  cellProps.editProps.onComplete(e);
                }
                if (e.key === "Tab") {
                  e.preventDefault();
                  cellProps.editProps.onTabNavigation(
                    true,
                    e.shiftKey ? -1 : 1
                  );
                }
              }}
            />
          )}
          </div>
          {column.isBulkEditing && (
            <Row align="middle" className="cell-bulk-edit-wrapper" style={cellStyle}>
              <span id={bvId}>
              {isBulkWritable(column, rowId) && !isBulkEditValueInitial() ? <>← &nbsp;<span className="cell-bulk-edit-value">{bulkEditValue.current}</span></> : 'No Change'}
              </span>
            </Row>
          )}
      </Row>
      </ItemWrapper>
    );
  };
  
  const bulkEditColumns = (cols: StringKAnyVPair[]) => {
    let columns: StringKAnyVPair[] = JSON.parse(JSON.stringify(cols));

    columns = columns.filter((col) => {
      if (typeof col.dataAttr === "object") {
        if (col.dataAttr.source === SOURCE_TYPE_ERP) {
          //console.log('ec', col);
          return false;
        }

        if (col.dataAttr.elementDataType === ATTRIBUTE_DATA_TYPE_VIRTUAL) {
          //console.log('oc', col);
          return false;
        }

        return true;
      } else {
        //console.log('fc', col);
        if (BULK_RO_COLUMNS.indexOf(col.name) > -1) {
          return true;
        }
      }

      return false;
    });
    return columns.map((col) => {
      switch (col.name) {
        case "mediaURL":
          break;

        case "SKU":
          delete col.render;

          break;

        case "VariationParentSKU":
        case "subStyleCode":
          col.width = 130;
          delete col.defaultFlex;
          delete col.minWidth;

          break;

        default:
          break;
      }

      col.showColumnMenuTool = false;

      if (BULK_RO_COLUMNS.indexOf(col.name) < 0) {
        col.rendersInlineEditor = true;
        col.uuid = col.uuid === undefined ? uuid() : col.uuid;
        col.render = (
          {
            value,
            data,
            rowIndex,
          }: { value: string; data: StringKAnyVPair; rowIndex: number },
          { cellProps }: { cellProps: any }
        ) => {
          //console.log('-->', data[`${col.name}_attributeNum`], '*');
          return (
            <DraggableItem
              index={`${rowIndex},${col.name}`}
              value={value}
              cellProps={cellProps}
              column={col}
              data={data}
              bulkUpdateCell={bulkUpdateCell}
              attributeNum={data[`${col.name}_attributeNum`]}
              rowId={data.ProductId}
            />
          );
        };
        col.renderHeader = (cellProps: any) => bulkEditColHeaderRender(cellProps, col);
      } else {
        col.editable = false;

        if (col.name === "mediaURL") {
          col.render = ({
            value,
            data,
          }: {
            value: string;
            data: StringKAnyVPair;
          }) => {
            const src = value || ImagePlaceholder;
            const isTreeNode = true;

            return (
              <ImageContainer
                key={data.ProductId}
                className={isTreeNode ? "image-tree-ctn" : ""}
              >
                <Image
                  width={28}
                  height={28}
                  preview={!!value}
                  src={src}
                  fallback={ImagePlaceholder}
                />
              </ImageContainer>
            );
          };
        }
      }

      return col;
    });
  };

  const closeColumnSelector = () => {
    setColumnSelectorVisible(false);
  };

  const editableList = (
    colList = columns,
    list: StringKAnyVPair[],
    searchStr = fieldSearchStr
  ) => {
    const cols = colList.filter((col) => col.visible !== false);
    //const ds: StringKAnyVPair[] = [];

    // console.log("cl", searchStr, cols.length);
    return list.filter((row) => {
      return cols.some((col) => {
        const cn = col.name;
        const cd = row[cn];
        //console.log('name', cn, typeof cd, cd);
        //if (typeof cd === 'string') {
        if (["number", "string"].indexOf(typeof cd) > -1) {
          return `${cd}`.toLowerCase().indexOf(searchStr.toLowerCase()) > -1;
        }

        return false;
      });
    });
  };

  const getBulkEditHeaderCtnByEditor = (editor: any) => {
    let dom = editor;
    let ret: any = null;

    while (dom && dom.parentNode && dom.parentNode.tagName.toLowerCase() !== 'body') {
      const cls = dom.parentNode.getAttribute('class');

      if (cls && typeof cls === 'string') {
        if (cls.indexOf(HEADER_EDIT_CTN_CLASS) > -1) {
          ret = dom.parentNode;
          break;
        }
      }

      dom = dom.parentNode;
    }

    return ret;
  };

  // eslint-disable-next-line
  const initializeColumns = (cs: StringKAnyVPair[]) => {
    const cols = bulkEditColumns(cs);
    const cdd: StringKAnyVPair = {};

    for (let i = 4; i < cols.length; i++) {
      if (cols[i]) {
        cols[i].visible = false;
      }
    }

    cols.forEach((col) => {
      if (typeof col.dataAttr === "object") {
        cdd[col.name] = col.dataAttr;
      }
    });
    setColumns([...cols]);
    setColDataAttrDict(cdd);
    setDataVisualList(editableList(cols, dataSource));
    setColumnSelectorVisible(true)
    // console.log('dd', dataSource, cdd, cols.length);
  };

  const isBulkEditValueInitial = () => {
    return bulkEditValue.current === undefined;
  };
  
  const isOverwritable = () => {
    return (
      [GROUP_OPTION_COLOR, GROUP_OPTION_STYLE].indexOf(props.displayGroup) > -1
    );
  };

  const onBulkInputChange = (evt: any) => {
    bulkEditValue.current = evt.target.value;
    //upgradeGridVersion();
    if (bulkEditValue.current === '') {
      console.log(typeof bulkEditValue.current);
      upgradeBulkInputViewVersion();
    }
  };

  const onBulkInputKeyDown = () => {
    if (bulkEditValue.current === undefined) return;

    const timer = setTimeout(() => {
      const col = getBulkEditCol();

      if (col.name) {
        upgradeBulkInputViewVersion();
      }
    }, 600);

    if (bulkInputUpdateTimer.current) clearTimeout(bulkInputUpdateTimer.current);

    bulkInputUpdateTimer.current = timer;
  };

  const onBulkSelectChange = (val: any) => {
    //console.log('si', val);
    //setBulkEditValue(val);
    bulkEditValue.current = val;
    upgradeGridVersion();
  };

  const onCancelDialog = () => {
    if (Object.keys(editDict).length > 0) {
      Modal.confirm({
        title: "Are you sure",
        content: "Are you sure to exit without saving?",
        onOk: () => {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  // eslint-disable-next-line
  const onCellClick = (evt: any, cellProps: any) => {
    const { id, rowIndex } = cellProps;

    if (gridRef.current) {
      //console.log('click cell', rowIndex, evt, cellProps);
      gridRef.current.startEdit({ columnId: id, rowIndex });
    }
  };

  const onColumnChange = (cols: StringKAnyVPair[]) => {
    setColumns(cols);
    editableList(cols, dataSource);
  };

  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const dict = { ...editDict };
      const v: any = typeof value === "string" ? value.trim() : value;
      let changed = false;

      // please do not modify dataVisualList
      dataSource
        .filter((col) => col.ProductId === rowId)
        .forEach((col) => {
          if (col[columnId] !== v) {
            col[columnId] = v;
            changed = true;
          }
        });

      if (changed) {
        if (!dict[rowId]) dict[rowId] = {};

        dict[rowId] = { ...dict[rowId], [columnId]: v };
      }

      setDataSource([...dataSource]);
      setEditDict(dict);
    },
    [dataSource, editDict]
  );

  const onFieldBulkEditFocus = (evt: any, col: StringKAnyVPair) => {
    const ctn = getBulkEditHeaderCtnByEditor(evt.target.parentNode);

    if (ctn) {
      const rect = ctn.getBoundingClientRect();
      const attrId = col.dataAttr.attributeNum;
      const bs = bulkEditColList.current.filter(e => attrId === e.dataAttr.attributeNum);

      //console.log('focus', col.dataAttr.attributeNum, ctn);
      if (bs.length === 1 && bs[0].isBulkEditing) return;

      console.log('cols', bulkEditColList, props.dataSource, dataVisualList);
      bulkEditColList.current.forEach(e => {
        if (e.dataAttr && typeof e.dataAttr === 'object') {
          if (attrId && attrId !== e.dataAttr.attributeNum) {
            if (e.isBulkEditing) {
              //console.log('need clear');
              clearBulkEditData(e);
            }

            e.isBulkEditing = false;
          }
        }
      });
      col.isBulkEditing = true;
      col.width = rect.width;

      if (bs.length === 0) bulkEditColList.current.push(col);

      setBulkEditColId(col.uuid);
      upgradeGridVersion();
      //console.log('vv', bulkEditColList, col.dataAttr, col);
      console.log('vv', gridVersion, bulkEditColList);
    }
  };

  const onFullscreen = (isFullscreen: boolean) => {
    setIsFullscreen(isFullscreen);
  };

  const onSave = async () => {
    const dataDict: StringKAnyVPair = {};
    const items: StringKAnyVPair[] = [];

    for (let k in dataVisualList)
      dataDict[dataVisualList[k].ProductId] = dataVisualList[k];
    for (let k in editDict) {
      const row = dataDict[k];

      if (row) {
        const cs: StringKAnyVPair[] = [];

        for (let cn in editDict[k]) {
          const attr = colDataAttrDict[cn];

          if (attr) {
            const e = {
              CopywritingElementNum: attr.attributeNum,
              CopywritingValue: editDict[k][cn],
            };

            cs.push(e);
          }
        }

        // console.log('cs-->', cs, row);
        if (cs.length > 0) {
          items.push({
            ProductId: k,
            Sku: row.SKU,
            Style: row.StyleCode,
            SubStyle: row.SubStyleCode,
            ContentResources: cs,
          });
        }
      }
    }

    // console.log('items', items);
    if (items.length > 0) {
      const params = {
        applyWithBlank: 0,
        overwrite: state.overwriteChildren ? true : false,
      };

      setIsLoading(true);

      try {
        const res = await bulkSaveContentResource(items, params);

        // console.log('res ->', res);
        if (typeof res === "object") {
          if (res.code === 200) {
            setEditDict({});
            notification.info({
              message: "Succeeded",
              duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
            });

            if (props.refresh) props.refresh();

            props.onClose();
          } else if (res.code === 202) {
            setEditDict({});
            Modal.info({
              title: "Accepted",
              content:
                "The value of following fields is schedule to be updated with the value from Content Resources",
            });
            props.onClose();
          } else {
            Modal.warning({
              title: "Warning",
              content: res.message || "Maybe something went wrong",
            });
          }
        }
      } catch (e) {
        notification.error({
          message: `Saved data error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSearchFields = (str: string) => {
    setFieldSearchStr(str.trim());
    setDataVisualList(editableList(columns, dataSource, str.trim()));
  };

  // eslint-disable-next-line
  const upgradeBulkInputViewVersion = () => {
    inputViewVersion.current = inputViewVersion.current + 1;
    setBulkInputViewVersion(inputViewVersion.current);
  };

  const upgradeGridVersion = useCallback(() => {
    gridVersion.current = gridVersion.current + 1;
    setBulkEditVersion(gridVersion.current);
    // mabye need to scroll to the special position
  }, [gridVersion]);

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;

    console.log('sel', selected, data, unselected, props);
    setSelected(selected);

    if (gridRef && gridRef.current && data && data.ProductId) {
      const top1 = gridRef.current.scrollTop;

      setTimeout(() => {
        const top2 = gridRef.current.scrollTop;
        //console.log(top1, top2);
        if (top1 !== top2) {
          //gridRef?.current.scrollToId(data.ProductId, {direction: 'top', duration: 100, offset: top1});
          gridRef?.current.smoothScrollTo(top1, {orientation: 'vertical', duration: 100});
          // maybe need smoothScrollTo to scroll horizontally
        }
      }, 500);
    }

    if (selected === true && !unselected) {
      setSelectedRows(data as any);
    } else {
      if (!unselected) {
        setSelectedRows(Object.values(selected as any));
      } else {
        setSelectedRows((prev) => {
          //console.log('prev', prev);
          const exist = prev.filter((item) => item.ProductId === (data as any).ProductId);

          if (exist.length > 0) {
            return prev.filter((item) => item.ProductId !== (data as any).ProductId);
          } else {
            //console.log('d', data);
            if (data.ProductId) {
              prev.push(data);
            }
          }
          //console.log('p', prev, selectedRows);

          return [...prev];
        });
      }
    }

    upgradeGridVersion();
  }, [gridRef, upgradeGridVersion]);

  const openColumnSelector = () => {
    setColumnSelectorVisible(true);
  };

  // eslint-disable-next-line
  const showBulkEditPreviewer = () => {
    //console.log('show bulk edit layer', selectedRows, editDict);
    selectedRowIdList.current = selectedRows.map(e => e.ProductId);

    if (Object.keys(bulkEditDict.current).length > 0) {
      const dict = bulkEditDict.current;

      for (let k in dict) {
        if (editDict[k]) editDict[k] = { ...editDict[k], ...dict[k] };
        else editDict[k] = dict[k];
      }

      bulkEditDict.current = {};
      setEditDict({ ...editDict });
    }

    // can not use getBulkEditCol to fetch the column id
    if (bulkEditColId) {
      const h = document.getElementById(bulkEditColId);

      if (h) {
        const col = getColByUuid(bulkEditColId);

        (h as any).parentNode.style.overflow = 'visible';
        (h as any).parentNode.style.zIndex = 1;

        if (col && col.isBulkEditing) {
          if (bulkEditInputRef.current[bulkEditColId]) bulkEditInputRef.current[bulkEditColId].focus();

          if (!bulkEditRowSelectAllOnce) {
            if (gridRef && gridRef.current) gridRef.current.selectAll();

            setShowRowCheckbox(true);
            setBulkEditRowSelectAllOnce(true);
          }
        }
        //console.log('code is running', bulkEditInputRef.current[bulkEditColId]);
      }
    }

    if (bulkEditColId === bulkEditColLastId) return;

    if (bulkEditColLastId) {
      // not need to maintain the overflow for headers edited before
      // all attributes will be rendered in the next refreshing cycle 
    }

    setBulkEditColLastId(bulkEditColId);
  };

  const updateBulkEditValueToCell = (col: StringKAnyVPair) => {
    const rids: string[] = selectedRowIdList.current || [];
    //console.log('u', col, rids);
    if (rids.length > 0 && col.dataAttr && typeof col.dataAttr === 'object') {
      //const attr: StringKAnyVPair = col.dataAttr;
      //const attributeNum = attr.attributeNum;
      const dict: StringKAnyVPair = {};
      const rows = dataSource.filter(e => rids.indexOf(e.ProductId) > -1);

      rows.forEach(r => {
        if (!dict[r.ProductId]) dict[r.ProductId] = {};

        r[col.name] = bulkEditValue.current;
        dict[r.ProductId] = { ...dict[r.ProductId], [col.name]: bulkEditValue.current };
      });
      bulkEditDict.current = dict;
      //console.log('attr num', col, rows, dict);
      upgradeGridVersion();
    }
  };

  // eslint-disable-next-line
  const updateBulkInputView = () => {
    const col = getBulkEditCol();

    bulkInputUpdateTimer.current = null;

    if (col.name && col.isBulkEditing) {
      dataVisualList.forEach(e => {
        if (selectedRowIdList.current.indexOf(e.ProductId) > -1) {
          const bvId = getBulkEditValueViewId(e);
          const bv = document.getElementById(bvId);

          if (bv) bv.innerHTML = `← &nbsp;<span class="cell-bulk-edit-value">${bulkEditValue.current}</span>`;
        }
      });
    }
  };

  React.useEffect(() => {
    if (!inited) {
      initializeColumns(props.columns);
      setInited(true);
    }

    if (bulkEditVersion !== bulkEditLastVersion) {
      if (gridRef.current) {
        showBulkEditPreviewer();
      }

      setBulkEditLastVersion(bulkEditVersion);
    }

    if (bulkInputViewVersion !== bulkInputViewLastVersion) {
      updateBulkInputView();
      setBulkInputViewLastVersion(bulkInputViewVersion);
    }
  }, [
    bulkEditLastVersion,
    bulkEditVersion,
    bulkInputViewLastVersion,
    bulkInputViewVersion,
    gridRef,
    inited,
    initializeColumns,
    props,
    showBulkEditPreviewer,
    updateBulkInputView,
  ]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton
        footer={null}
        fullscreen
        //okText="Save"
        onClose={onCancelDialog}
        onFullscreen={onFullscreen}
        //onOk={onSaveImage}
        style={{ paddingBottom: 0 }}
        title={"Bulk Edit"}
        titleRightCrumb={
          <>
            <Button onClick={openColumnSelector}>
              <BarsOutlined style={{ rotate: "90deg" }} />
              Columns
            </Button>
          </>
        }
        visible={props.visible}
        //width={isChatEnable ? 1260 : 700}
        width={"80%"}
      >
        <Row justify="space-between" style={{ marginBottom: 12 }}>
          <ExpandableSearch
            flatten={true}
            onSearch={onSearchFields}
            style={{ width: 330 }}
          />
          <Space>
            {isOverwritable() && (
              <OverwriteOptionList dispatch={dispatch} state={state} />
            )}
          </Space>
        </Row>
        <GridWrapper
          style={{
            height: isFullscreen ? "calc(100vh - 178px)" : "70vh",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              checkboxColumn={showRowCheckbox}
              //checkboxOnlyRowSelect
              key={`dg-${bulkEditVersion}`}
              idProperty="ProductId"
              headerHeight={70}
              rowHeight={35}
              //columns={props.columns}
              columns={columns}
              loading={editLoading}
              dataSource={dataVisualList}
              //dataSource={editableList(columns, dataSource)}
              //defaultFilterValue={filterValue}
              //defaultLimit={DEFAULT_GRID_LIMIT}
              editable={true}
              enableSelection={true}
              enableFiltering={false}
              //filterTypes={gridFilterTypes}
              //limit={pageTop}
              //loadNode={loadNextLevelProducts}
              //loading={isFetching}
              //onCellClick={onCellClick}
              onEditComplete={onEditComplete}
              //onFilterValueChange={onFilterValueChange}
              //onLimitChange={onLimitChange}
              onReady={setGridRef}
              cellSelection={[]}
              onCellSelectionChange={(v: any) => {
                // don't know why. Only by adding [cellSelection], the grid can be refreshed normally.
              }}
              onSelectionChange={onSelectionChange}
              //onSkipChange={onSkipChange}
              //onSortInfoChange={onSortChange}
              //pageSizes={[20, 30, 50, 100, 200, 500]}
              pagination={false}
              //checkboxOnlyRowSelect
              //renderRowDetails={renderRowDetails}
              //rowExpandColumn={searchGroupMode}
              //rowExpandHeight={300}
              // selected={selectedRows}
              selected={selected}
              //skip={pageSkip}
              sortable={false}
              style={{ height: "100%" }}
              //treeColumn={searchTreeMode ? "mediaURL" : undefined}
            />
          </DndProvider>
        </GridWrapper>
        <Row justify="space-between">
          <Button onClick={onCancelDialog}>
            <CloseOutlined />
            Cancel
          </Button>
          <Space>
            <Button
              disabled={Object.keys(editDict).length === 0}
              type="primary"
              onClick={onSave}
            >
              <SaveOutlined />
              Save
            </Button>
          </Space>
        </Row>
      </ModalDialog>
      {columnSelectorVisible && (
        <BulkEditColumnSelector
          columns={columns}
          onClose={closeColumnSelector}
          setColumns={onColumnChange}
          visible={columnSelectorVisible}
        />
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default BulkEditDialog;
