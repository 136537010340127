import React from 'react';
import { Button, Row, Space, Table, Typography, Alert } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  selectedSourceName: string;
  selectedAttribute: any[];
  openViewDialog: (data: any) => void;
};

const AttributeSelector = (props: Props) => {
  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Name',
    },
    {
      dataIndex: 'existingAttributeValue',
      title: 'Attribute Value',
      render(text: string, data: any, index: number) {
        return (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        );
      },
    },
    {
      dataIndex: 'copywritingElementName',
      title: 'Content Resources',
    },
    {
      dataIndex: 'copywritingValue',
      title: 'Content Resources Value',
      render(text: string, data: any, index: number) {
        return (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        );
      },
    },
    {
      align: 'center' as 'center',
      title: '',
      render(data: any) {
        return (
          <Button
            className="primary-text-btn"
            type="text"
            onClick={() => props.openViewDialog(data)}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <div className="dest-step-wrapper">
        <Alert message="The value of content resources will be replaced with the value from corresponding attributes" type="error"/>
        <div className="section" style={{ marginTop: 10 }}>
          <Row align="middle" className="section-bar">
            <Space>
              <span className="section-title">{props.selectedSourceName}</span>
            </Space>
          </Row>
          <Table
            columns={productBasicColumns}
            dataSource={props.selectedAttribute}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
      </div>
    </>
  );
};

export default AttributeSelector;
