const initialState = {
  collapse: false,
};

const sidebar = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_COLLAPSE':
			return { ...state, collapse: !state.collapse };
		default:
			return state;
	}
};

export default sidebar;
