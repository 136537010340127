import React from 'react';
import { Input, Tag } from 'antd';

import LoadingIcon from '../../../components/common/LoadingIcon';
import message from '../../../components/common/message';
import Products from '../../../services/products';
import { loadChannelControlFlags } from '../helper';
import { EditorWrapper } from '../styledComponents';
import { ATTR_CATEGORY_OPERATION } from './';
import SalesChannelDialog from './SalesChannelDialog';

type Props = {
  attr?: StringKAnyVPair;
  category?: number;
  className?: string;
  dispatch?: Function;
  editOnly?: boolean;
  label?: string;
  mode?: 'multiple' | 'tags';
  name?: string;
  onSelect?: Function;
  options?: StringKAnyVPair[];
  productId?: string;
  refreshFormState?: Function;
  refreshOptions?: Function;
  state?: StringKAnyVPair;
  styleCode?: string;
  type: string;
  value: any;
  sourceValue?: any;
};

const SalesChannelEditor = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<any>(props.value || []);
  const [editorDialogVisible, setEditorDialogVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastValue, setLastValue] = useState<any>(props.value || []);
  const [options, setOptions] = useState<StringKAnyVPair[]>([]);
  //const [selectedChannel, setSelectedChannel] = useState<StringKAnyVPair>({});

  const attrEditOpeIcon = () => {
    return (
      <>
        {isLoading && <LoadingIcon />}
        {!isLoading && (
          <span
            className="icon-ctn-enabled"
            onClick={openSalesChannelDialog}
          >
            {'🖊️'}
          </span>
        )}
      </>
    );
  };

  const attrOpeIcon = () => {
    return (
      <>
        {isLoading && <LoadingIcon />}
        {!isLoading && (
          <span
            className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
            onClick={toggleEditMode}
          >
            {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
            {isEditable ? '💾' : '🖊️'}
          </span>
        )}
      </>
    );
  };

  const closeEditorDialog = () => {
    setEditorDialogVisible(false);
    setIsEditable(false);
  };

  const contentWrapperClass = () => {
    const cls = ['sale-chn-content-wrap'];

    if (props.editOnly || isEditable) {
      cls.push('editable');
    } else {
      if (isLoading) {
        cls.push('icon-1');
      } else {
        cls.push('visual-ctn');
      }
    }

    return cls.join(' ');
  };

  const isLastValueNull = () => {
    return lastValue === undefined || lastValue === null;
  };

  // eslint-disable-next-line
  const loadChannelFlags = async () => {
    setIsLoading(true);
    const cs = await loadChannelControlFlags();

    setIsLoading(false);

    if (Array.isArray(cs)) {
      const temp: StringKAnyVPair = {};
      console.log('cs', cs, props);
      const tempCs: any[] = cs.map((i) => {
        if (Array.isArray(props.sourceValue)) {
          const findValue = props.sourceValue.find(
            (item: any) => item.elementId === i.ProductCHNLCtrlFlagId,
          );
          if (findValue) {
            //temp[i.ProductCHNLCtrlFlagId] = [!!findValue.sendInventory, !!findValue.sendContent];
            temp[i.ProductCHNLCtrlFlagId] = [findValue.sendInventory, findValue.sendContent];
            return {
              ...i,
              //sendInventory: !!findValue.sendInventory,
              sendInventory: findValue.sendInventory,
              //sendContent: !!findValue.sendContent,
              sendContent: findValue.sendContent,
              selected: true,
            };
          } else {
            //temp[i.ProductCHNLCtrlFlagId] = [false, false];
            temp[i.ProductCHNLCtrlFlagId] = [0, 0];
            return { ...i, sendInventory: 0, sendContent: 0, selected: false, };
          }
        } else {
          //temp[i.ProductCHNLCtrlFlagId] = [false, false];
          temp[i.ProductCHNLCtrlFlagId] = [0, 0];

          //return { ...i, sendInventory: false, sendContent: false };
          return { ...i, sendInventory: 0, sendContent: 0, selected: false, };
        }
      });
      console.log('cs --->', temp, tempCs);
      //setSelectedChannel(temp);
      setOptions(tempCs);
      setIsDataReady(true);
    }
  };

  const listValues = (values: any[]) => {
    const dict: StringKAnyVPair = {};
    const ret: StringKAnyVPair[] = [];

    options.forEach(e => dict[e.ProductCHNLCtrlFlagId] = e);

    console.log('dict -->', dict);
    (values || []).forEach((e: string) => {
      if (dict[e]) {
        ret.push({
          ElementId: e,
          //ProductEditType: lastValue.indexOf(e) > -1 ? 3 : 0,
          SendInventory: dict[e].sendInventory,
          //SendContent: tempChecked[e][1] ? 1 : 0,
          SendContent: dict[e].sendContent,
          editType: Array.isArray(lastValue) && lastValue.indexOf(e) > -1 ? 1 : 0,
        });
      }
    });

    if (Array.isArray(lastValue)) {
      lastValue.forEach((e: string) => {
        //if (currentValue.indexOf(e) < 0) {
        if (values.indexOf(e) < 0) {
          ret.push({
            ElementId: e,
            //ProductEditType: 2,
            SendInventory: 0,
            SendContent: 0,
            editType: 2,
          });
        }
      });
    }

    return ret;
  };

  const openSalesChannelDialog = () => {
    setIsEditable(true);
    setEditorDialogVisible(true);
  };

  const saveBySelection = (selection: StringKAnyVPair) => {
    const values: string[] = [];
    console.log('save', selection);
    options.forEach(e => {
      if (selection[e.ProductCHNLCtrlFlagId]) {
        e.selected = true;
        e.sendContent = selection[e.ProductCHNLCtrlFlagId].catalog;
        e.sendInventory = selection[e.ProductCHNLCtrlFlagId].inventory;
        values.push(e.ProductCHNLCtrlFlagId);
        console.log('h', e);
      } else {
        e.selected = false;
        e.sendContent = 0;
        e.sendInventory = 0;
      }
    });
    setOptions([...options]);
    //console.log('vvv', values);
    saveValues(values);
  };

  const setSpecialSaveData = (data: StringKAnyVPair, values: any) => {
    let foundIdList = false;

    switch (props.type) {
      case 'channel':
        data.ChannelControlFlagIdList = listValues(values);
        foundIdList = true;
        break;
    }

    if (foundIdList) {
      data.productBasic = undefined;
      data.productBasicInfoAttributeList = undefined;
    }
  };

  const saveValues = async (value = currentValue) => {
    /*if (!isValueChanged()) {
      setIsEditable(false);
      return;
    }*/
    const attrLabel = props.attr ? props.attr.basicAttributeName : props.label || props.name || '';

    //console.log('at->', props.attr);
    setIsLoading(true);

    try {
      const overwrite = props.state?.overwriteChildren;
      const basic: StringKAnyVPair = {
        /*sku: props.styleCode,
        styleCode: props.styleCode,*/
      };
      const data: StringKAnyVPair = {
        //ApplyToAllRelevantSubStyleAndSKU: props.state?.overwriteChildren || false,
        ApplyToAllRelevantSubStyleAndSKU: overwrite ? true : false,
        applyWithBlank: overwrite === 2 ? 1 : 0,
        styleCode: props.styleCode,
        productBasic: basic,
      };

      if (props.name) basic[props.name] = value;

      if (props.attr && props.attr.basicAttributeNum > 999) {
        const { basicAttributeId } = props.attr;

        //console.log('ccc->', currentValue, lastValue);
        data.productBasicInfoAttributeList = [
          {
            AttributeId: basicAttributeId,
            //Value: currentValue,
            Value: value,
            editType: isLastValueNull() ? 0 : 1,
            //editType: 0,
            //editType: 1,
          },
        ];
        data.productBasic = undefined;
      }

      if (props.category) {
        if (props.category === ATTR_CATEGORY_OPERATION) {
          data.productOperation = basic;
          data.productBasic = undefined;
        }
      }

      setSpecialSaveData(data, value);

      // console.log('pdata ->', data);
      if (props.productId) {
        await Products.editSimpleStyleMaster(props.productId, data);
        setCurrentValue(value);
        setLastValue(value);
        //setLastTempChecked(JSON.stringify(tempChecked));
        setIsEditable(false);
        message.success(`Saved '${attrLabel}' successfully`);

        if (typeof props.onSelect === 'function') props.onSelect(value);
      } else {
        message.info(`Not enough conditions to save '${attrLabel}'`);
      }
    } catch (e) {
      console.log('err', e);
      message.error(`Saved '${attrLabel}' failed: ${e}`);
    } finally {
      setIsLoading(false);
    }
    /*setTimeout(() => {
      message.success(`Saved ${attrName || ''} successfully`);
      setIsEditable(false);
      setIsLoading(false);
    }, 1000);*/
  };

  const showValue = () => {
    if (isDataReady && Array.isArray(currentValue)) {
      const dict: StringKAnyVPair = {};

      //currentValue.forEach(id => dict[id] = { id, label: id });
      options.forEach(e => {
        //if (dict[e.ProductCHNLCtrlFlagId]) {
          if (currentValue.indexOf(e.ProductCHNLCtrlFlagId) > -1) {
            dict[e.ProductCHNLCtrlFlagId] = {
              id: e.ProductCHNLCtrlFlagId,
              label: e.CHNLCtrlFlagDesc || e.CHNLCtrlFlag || e.ProductCHNLCtrlFlagId,
            };
          }
          //dict[e.ProductCHNLCtrlFlagId].label = e.CHNLCtrlFlagDesc || e.CHNLCtrlFlag || e.ProductCHNLCtrlFlagId;
        //}
      });

      return currentValue.filter(e => dict[e])
        .map(e => dict[e])
        .map(e => <Tag key={e.id}>{e.label}</Tag>);
    }

    return null;
  };

  const toggleEditMode = () => {
    if (isEditable) {
      //saveValues();
    } else {
      openSalesChannelDialog();

      /*if (!isDataReady) {
        loadSelectOptions();
      }*/
    }
  };

  React.useEffect(() => {
    if (!inited) {
      //loadSelectOptions();
      loadChannelFlags();
      setInited(true);
    }
  }, [inited, loadChannelFlags]);

  return (<>
    <EditorWrapper className={props.className || ''}>
      <Input.Group compact>
        <span
          className={contentWrapperClass()}
          onDoubleClick={openSalesChannelDialog}
        >
          {showValue()}
        </span>
        {props.editOnly && attrEditOpeIcon()}
        {!props.editOnly && attrOpeIcon()}
      </Input.Group>
    </EditorWrapper>
    {editorDialogVisible && (
      <SalesChannelDialog
        dispatch={props.dispatch}
        channels={options}
        onClose={closeEditorDialog}
        onSave={saveBySelection}
        state={props.state ? props.state : {}}
        visible={editorDialogVisible}
      />
    )}
  </>);
};

export default SalesChannelEditor;
