import React from 'react';
import { Input } from 'antd';
import styled from "styled-components";

type Props = {
  onMouseEnter: Function;
  onMouseLeave: Function;
  outline: StringKAnyVPair;
  searchMultiCode: string;
  setMultiCode: Function;
  visible: boolean;
};

const InputWrapper = styled.div`
  //background-color: #FFF;
  position: absolute;
  z-index: 1;

  & textarea {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const MultipleInput = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const inputRef = React.useRef<any>(null);

  const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;
    const str = value.replace(/\t/g, "\n");

    //setSearchMultiCode(str);
    props.setMultiCode(str);
  };

  const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (props.searchMultiCode.trim()) {
        props.setMultiCode(props.searchMultiCode.trim() + "\n");
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };

  React.useEffect(() => {
    if (!inited) {
      if (inputRef.current) {
        inputRef.current.focus();
        setInited(true);
      }
    }
  }, [inited]);

  return (<>
    <InputWrapper
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
      style={{
        left: props.outline.x,
        top: props.outline.y,
        width: props.outline.width,
      }}
    >
      <Input.TextArea
        allowClear
        onChange={onSearchCodeListChange}
        onKeyDown={onSearchCodeListKeyDown}
        ref={inputRef}
        rows={5}
        value={props.searchMultiCode}
      />
    </InputWrapper>
  </>);
};

export default MultipleInput;
