import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import CategoryPathEditor from './CategoryPathEditor';

interface Props extends ModalProps {
  attr: StringKAnyVPair;
  onClose: Function;
  onSave?: Function;
  visible: boolean;
};

const CtnWrapper = styled.div`
  & .cat-path {
    font-size: 16px;
  }

  & .path-blk {
    margin-bottom: 8px;
  }
`;

const CategorySelector = (props: Props) => {
  const { useState } = React;
  const { attr } = props;
  const [currentPathStr, setCurrentPathStr] = useState(attr.copywritingValue);

  const onPathChange = (pns: StringKAnyVPair[]) => {
    const path = pns.map(p => p.categoryName).join(' > ');
    //console.log('path', pns);
    setCurrentPathStr(path);
  };

  const saveCategory = () => {
    if (attr.copywritingValue !== currentPathStr) {
      if (props.onSave) props.onSave(currentPathStr);
    }

    props.onClose();
  };

  return (<>
    <Modal
      centered
      onCancel={() => props.onClose()}
      onOk={saveCategory}
      maskClosable={false}
      title={props.attr.elementName || 'Category'}
      visible={props.visible}
    >
      <CtnWrapper>
        <div className="path-blk">
          <span className="cat-path">{currentPathStr}</span>
        </div>
        <CategoryPathEditor
          editOnly={true}
          onPathChange={onPathChange}
          value={null}
        />
      </CtnWrapper>
    </Modal>
  </>);
};

export default CategorySelector;
