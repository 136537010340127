import { Row, Button, Modal, Typography, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Text, Title } = Typography;

const CardSpace = styled(Space)`
  width: 450px;
`;

const RemoveDialog: React.FC<{
  onClose?: () => void;
  onOk: () => void;
}> = ({ onOk, onClose }) => {
  return (
    <Modal
      visible
      width={500}
      closable={false}
      title={
        <Row justify="center">
          <Title level={4}>Remove Skus from Channel</Title>
        </Row>
      }
      footer={false}
    >
      <CardSpace direction="vertical">
        <Text strong>Are you sure to remove selected skus?</Text>
        <Row justify="end">
          <Button type="primary" onClick={() => onOk()} style={{ margin: 5 }}>
            Remove
          </Button>
          <Button onClick={() => onClose?.call(null)} style={{ margin: 5 }}>
            Cancel
          </Button>
        </Row>
      </CardSpace>
    </Modal>
  );
};

export default RemoveDialog;
