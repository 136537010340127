import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormButtons from '../../components/common/FormButtons';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { USStates, USStatesMap } from '../../constants/countries/states';
import { Permissions } from '../../constants/enums/permissions';
import {
  createSellerConfig,
  getSellerConfig,
  SellerConfigCreatePayload,
  updateSellerConfig,
} from '../../services/sellers';
import { useExecutePromise } from '../../util/hooks';
import { validateURL } from '../../util/validators';

const formInitialValues: SellerConfigCreatePayload = {
  sellerConfig: {
    enabledAsSeller: 0,
    listPreference: 0,
  },
  company: {
    websiteUrl: '',
  },
  taxIds: [],
};

const stateTaxTableColumns = [
  {
    key: '1',
    title: 'State',
    render(text: string, record: any, index: number) {
      return (
        <Form.Item name={[index, '$name']} noStyle>
          <Input bordered={false} readOnly />
        </Form.Item>
      );
    },
  },
  {
    key: '2',
    title: 'Tax ID',
    render(text: string, record: any, index: number) {
      return (
        <Form.Item name={[index, 'id']} noStyle>
          <Input bordered={false} readOnly />
        </Form.Item>
      );
    },
  },
];

type StateTaxFormFieldProps = {
  form: FormInstance;
  fields: FormListFieldData[];
  operation: FormListOperation;
  disabled?: boolean;
};

const StateTaxFormField: React.FC<StateTaxFormFieldProps> = (
  {
    fields,
    operation,
    disabled,
    form,
  },
) => {
  const [internalForm] = Form.useForm<{ state: string, id: string }>();
  const addState = useCallback((data: { state: string, id: string }) => {
    const { taxIds }: { taxIds: Entities.TaxID[] } = form.getFieldsValue(['taxIds']);
    const exists = taxIds.find(t => t.state === data.state);

    if (!exists) {
      operation.add({
        ...data,
        $name: USStatesMap[data.state],
      });
    } else {
      message.error('This state already exists on the table').then();
    }

    internalForm.resetFields();
  }, [operation, form, internalForm]);
  const columns = useMemo(() => [
    {
      key: 0,
      title: '',
      width: 28,
      render(text: string, record: any, index: number) {
        return (
          <Button
            type="text"
            shape="circle"
            size="small"
            onClick={() => operation.remove(index)}
            disabled={disabled}
            icon={<MinusCircleOutlined />}
          />
        );
      },
    },
    ...stateTaxTableColumns,
  ], [operation, disabled]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form
        form={internalForm}
        onFinish={addState}
      >
        <Row gutter={12}>
          <Col xs={24} lg={8}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select disabled={disabled} showSearch>
                {USStates.map(state => (
                  <Select.Option value={state.value} key={state.key}>
                    {state.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label="Tax ID"
              name="id"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
              >
                Add
                <PlusOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={fields}
        columns={columns}
        size="small"
        pagination={false}
        scroll={{ y: 280 }}
      />
    </Space>
  );
};

const getMappedSellerConfig = async (): Promise<Entities.SellerConfigItem> => {
  const config = await getSellerConfig();
  const taxIds = config.taxIds.map((taxId) => ({
    ...taxId,
    $name: USStatesMap[taxId.state],
  }));

  return {
    ...config,
    taxIds,
  };
};

const SellerSettings: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm<SellerConfigCreatePayload>();
  const [config, loading, refresh] = useExecutePromise(getMappedSellerConfig, null);
  const cancelDisabled = useMemo(() => !config || !config.sellerConfig, [config]);
  const onFormFinish = useCallback(async (data: SellerConfigCreatePayload) => {
    setSaving(true);

    const notificationAttrs = {
      message: '',
      duration: 5,
    };

    try {
      if (config && config.sellerConfig) {
        await updateSellerConfig(data);
        notificationAttrs.message = 'Settings updated successfully';
      } else {
        await createSellerConfig(data);
        notificationAttrs.message = 'Settings updated successfully';
      }

      notification.success(notificationAttrs);
      refresh(true);
      setEditMode(false);
    } catch (e) {
      notification.error({
        message: 'There was an error processing your request, please try again later.',
        duration: 5,
      });
    } finally {
      setSaving(false);
    }
  }, [config, refresh]);
  const onCancelEdit = useCallback(() => {
    setEditMode(false);
    form.resetFields();
    if (config && config.sellerConfig) {
      form.setFieldsValue(config);
    }
  }, [form, config, setEditMode]);

  useEffect(() => {
    if (!config || !config.sellerConfig) {
      setEditMode(true);
      return;
    }

    setEditMode(false);
    form.setFieldsValue(config);
  }, [config, form]);

  const disabled = !editMode || saving;
  const labelCol = { xs: 24, md: 8, lg: 8, xl: 4 };
  const wrapperCol = { xs: 24, md: 16, lg: 16, xl: 8 };

  return (
    <ContentLayout>
      <Heading
        title="Seller - Settings"
      />
      <Spacer />
      <SiteContent>
        <Spin spinning={loading}>
          <Tabs
            tabBarExtraContent={(
              <FormButtons
                onSave={form.submit}
                onEdit={() => setEditMode(true)}
                onCancel={onCancelEdit}
                editingMode={editMode}
                // TODO: change for correct permission
                permissionNumber={Permissions.MANAGE_PRODUCTS}
                disableCancel={cancelDisabled}
                hideDelete
                saving={saving}
              />
            )}
          >
            <Tabs.TabPane tab="Basic Info">
              <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={onFormFinish}
                initialValues={formInitialValues}
              >
                <Form.Item
                  label="Enable as Seller"
                  name={['sellerConfig', 'enabledAsSeller']}
                >
                  <Select disabled={disabled}>
                    <Select.Option value={0}>
                      Disabled
                    </Select.Option>
                    <Select.Option value={1}>
                      Enabled
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="List preference:"
                  name={['sellerConfig', 'listPreference']}
                >
                  <Radio.Group disabled={disabled}>
                    <Space direction="vertical">
                      <Radio value={0}>
                        Public (non-registered users can see my information)
                      </Radio>
                      <Radio value={1}>
                        Private (only registered users can see my information)
                      </Radio>
                      <Radio value={2}>
                        Don&apos;t list
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="State Tax ID"
                  wrapperCol={{ xl: 16 }}
                >
                  <p>
                    Suppliers may require Tax ID in particular states.
                    <br />
                    Save all of your state tax ID here, you don&apos;t need to upload the information for each
                    application.
                  </p>
                  <Form.List name="taxIds">
                    {
                      (fields, operation) => (
                        <StateTaxFormField
                          form={form}
                          fields={fields}
                          operation={operation}
                          disabled={disabled}
                        />
                      )
                    }
                  </Form.List>
                </Form.Item>
                <Form.Item
                  label="Website"
                  name={['company', 'websiteUrl']}
                  rules={[
                    { required: true, message: 'This field is required' },
                    { validator: validateURL, message: 'The provided value must be a valid URL.' },
                  ]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </SiteContent>
    </ContentLayout>
  );
};

export default SellerSettings;
