const initialState = {
  filter: {
    orderDateFrom: null,
    orderDateTo: null,
    channelNum: undefined,
    orderStatus: undefined,
    trackingNumber: undefined,
    sku: undefined,
    channelItemID: undefined,
  },
  viewFilter: 'view_all'
};

const orders = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: {
          ...action.filter,
        }
      };
    case 'CLEAR_FILTER_BY_KEY':
      let itemVal = undefined;
      if(action.value && state.filter[action.key].length) {
        itemVal = state.filter[action.key].filter(k => k !== action.value)
      }
      return {
        filter: {
          ...state.filter,
          [action.key]: itemVal
        }
      };
    case 'CLEAR_ALL_FILTER':
      return {
        ...initialState
      };
    case 'SET_VIEW_FILTER':
      return {
        ...state,
        viewFilter: action.viewFilter
      };
    default:
      return state;
  }
};

export default orders;
