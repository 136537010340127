import React, { useCallback } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Row, Button, Space, Modal, Dropdown, Menu } from 'antd';

type ExportProps = {
  blukType: string;
  selectedRows: Array<any>;
  // queryParams: StringKAnyVPair[];
  data: Array<any>;
  onClose: () => void;
  needpushData: (data: { products: any[]; searchParams?: StringKAnyVPair; all: number, isProducts: boolean }) => void;
  searchParams?: StringKAnyVPair;
};
export default function BlukModal(props: ExportProps): JSX.Element {
  const { data, selectedRows, onClose, needpushData, blukType, searchParams } = props;

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onClose();
  }, [onClose]);
  /**
   * handle download event
   */

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={handleHide}>Close</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title={`Content Resources Bulk ${blukType}`}
      centered
      visible
      width={700}
      destroyOnClose
      onCancel={handleHide}
      footer={<Footer />}
    >
      <Row justify="space-around" style={{ marginTop: 10 }}>
        <Button
          type="primary"
          onClick={() => needpushData({ products: selectedRows, searchParams: props.searchParams, all: 0, isProducts: searchParams?.queryGrouper.groupName === 'Product'})}
          disabled={selectedRows.length === 0 }
        >
          {`${blukType} Selected`}
        </Button>
        <Button
          type="primary"
          disabled={data.length === 0 }
          onClick={() =>
            needpushData({
              products: data,
              searchParams: props.searchParams,
              all: 0,
              isProducts: searchParams?.queryGrouper.groupName === 'Product'
            })
          }
        >
          {`${blukType} Current Page`}
        </Button>
        <Button
          type="primary"
          disabled={data.length === 0}
          onClick={() =>
            needpushData({
              products: [],
              // queryParams: queryParams,
              searchParams: props.searchParams,
              all: 0,
              isProducts: searchParams?.queryGrouper.groupName === 'Product'
            })
          }
        >
          {`${blukType} Search Result`}
        </Button>
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                needpushData({
                  products: [],
                  // queryParams: undefined,
                  all: Number(e.key),
                  isProducts: Number(e.key) === 1
                });
              }}
            >
              <Menu.Item key={2}>Style</Menu.Item>
              <Menu.Item key={3}>Substyle</Menu.Item>
              <Menu.Item key={1}>Product</Menu.Item>
            </Menu>
          }
        >
          <Button>
            <Space>
              {`${blukType} All`}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Row>
    </Modal>
  );
}
