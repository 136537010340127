import React from 'react';
import { Card } from 'antd';
import { useDraggable, useDroppable } from '@dnd-kit/core';

type Props = {
  data?: StringKAnyVPair;
  dragId: string;
  id: string;
  children?: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
};

const DraggableBasicImageCard = (props: Props) => {
  const { useMemo } = React;
  const {attributes, listeners, setNodeRef} = useDraggable({
    id: props.id,
    data: props.data,
  });
  const {isOver, setNodeRef: setDropNodeRef} = useDroppable({ id: props.id });
  const isDropEnable = useMemo(() => {
    return isOver && props.id && props.dragId !== props.id;
  }, [isOver, props]);

  const dropCtnClassNames = () => {
    const cls = ['img-drop-ctn'];

    if (isDropEnable) {
      cls.push('is-over');
    }

    return cls.join(' ');
  };

  /*React.useEffect(() => {
    console.log('p', props, props.id, isOver);
  }, [isOver, props]);*/
  
  return (
    <Card
      className={props.className}
      title={props.title}
    >
      <div
        ref={setNodeRef} {...listeners} {...attributes} 
      >
        <div
          className={dropCtnClassNames()}
          ref={setDropNodeRef}
        >
          {props.children}
        </div>
      </div>
    </Card>
  );
}

export default DraggableBasicImageCard;
