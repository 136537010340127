import React, { createContext } from 'react';
import { editClassification } from '../../services/products';

export enum Actions {
  SET_LOADING,
  SET_DATA,
  SET_EDIT_MODAL_VISIBLE,
  SET_EDITING_ENTITY_ID,
  SET_ENTITY_TO_DELETE,
  REPLACE_INLINE_EDIT,
  SET_SEARCH_DISABLED,
  SET_IS_SAVING,
  EDIT_ENTITY,
  EDIT_FORM_SUBMITTED,
}

export interface PageState {
  loading: boolean;
  /**
   * Used by effect hook to check whether or not it should fetch the page's data
   */
  fetchData: boolean;
  data: Array<Entities.ProductClassification>;
  /**
   * Whether or not the editing modal should be visible
   */
  editModalVisible: boolean;
  /**
   * ID of the entity to edit.
   * When this value is set, the Edit modal will use it to retrieve the object's data
   * from the backend and render it appropriately.
   */
  editingEntityId: string | null;
  /**
   * ID of the entity to delete.
   * This will make the deletion modal visible
   */
  entityToDelete: Entities.ProductClassification | null;

  searchDisabled: boolean;

  isSaving: boolean;
}

export interface InitialStateProps {
  loading?: boolean;
}

export const initialState = ({ loading = false }: InitialStateProps = { loading: false }): PageState => ({
  loading,
  data: [],
  editModalVisible: false,
  editingEntityId: null,
  entityToDelete: null,
  fetchData: true,
  searchDisabled: false,
  isSaving: false,
});

const replaceRow = (state: PageState, value: Entities.ProductClassification, rowIndex: number) => {
  const data = [...state.data];
  data.splice(rowIndex, 1, value);
  return {
    ...state,
    data,
  };
};

export const reducer = (state: PageState, action: Utils.DispatchAction<Actions>) => {
  switch (action.type) {
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_EDIT_MODAL_VISIBLE:
      return { ...state, editModalVisible: action.params };
    case Actions.SET_DATA:
      return { ...state, data: action.params };
    case Actions.SET_EDITING_ENTITY_ID:
      return { ...state, editingEntityId: action.params };
    case Actions.SET_ENTITY_TO_DELETE:
      return { ...state, entityToDelete: action.params };
    case Actions.REPLACE_INLINE_EDIT:
      return replaceRow(state, action.params.value, action.params.rowIndex);
    case Actions.SET_SEARCH_DISABLED:
      return { ...state, searchDisabled: action.params };
    case Actions.SET_IS_SAVING:
      return { ...state, isSaving: action.params };
    case Actions.EDIT_ENTITY:
      return {...state, editingEntityId: action.params, editModalVisible: true};
    case Actions.EDIT_FORM_SUBMITTED:
      return { ...state, editModalVisible: false, editingEntityId: null };
    default:
      return state;
  }
};


export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [PageState, Dispatcher] | [undefined, undefined];

export const PageStateContext = createContext<ContextType>([undefined, undefined]);

export const saveClassification = async (dispatch: Dispatcher, value: Entities.ProductClassification, rowIndex: number) => {
  dispatch({ type: Actions.SET_IS_SAVING, params: true });

  try {
    // server returns a "ok!" instead of the modified object
    await editClassification(value, value.ClassificationId);
    dispatch({ type: Actions.REPLACE_INLINE_EDIT, params: { value, rowIndex } });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  } finally {
    dispatch({ type: Actions.SET_IS_SAVING, params: false });
  }
};
