const pushMessage = (value: any) => ({
  type: "PUSH_MESSAGE",
  value,
});

const closeBox = () => ({
  type: "CLOSE_BOX",
});

const setIsRead = (value: string) => ({
  type: "SET_ISREAD",
  value,
});

const clearOne = (value: string) => ({
  type: "CLEAR_ONE",
  value,
});

const clearAll = () => ({
  type: "CLEAR_ALL",
});

export default {
  pushMessage,
  closeBox,
  setIsRead,
  clearOne,
  clearAll,
};
