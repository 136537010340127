import { PlusOutlined } from '@ant-design/icons';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import { Button, Col, Modal, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid, TypeActionsColumn } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import ModalForm from '../../components/ProductMediaPlacement/ModalForm';
import SiteContent from '../../components/SiteContent';
import Notifications from '../../redux/actions/notifications';
import { deleteMedia, editMedia, fetchMedia } from '../../services/products';
import { MediaType } from '../../types/enums';
import { enumToObjects } from '../../util/enums';
import { enumNameToLabel } from '../../util/strings';


const useLoadData = (): [Entities.MediaPlacement[], boolean, () => void] => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Entities.MediaPlacement[]>([]);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setLoading(true);

    fetchMedia()
      .then(response => {
        setData(response);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  useEffect(() => setReload(true), []);

  const reloadData = useCallback(() => setReload(true), []);

  return [data, loading, reloadData];
};


const searchFields: Array<SearchField<Entities.MediaPlacement> | string> = [
  'MediaPlacementName',
  {
    fieldName: 'MediaType',
    attributes: {
      extractor(obj) {
        return enumNameToLabel(MediaType[obj.MediaType]);
      },
    },
  },
];

const columns = [
  {
    name: 'MediaPlacementName',
    header: 'Name',
    defaultFlex: 2,
    editable: true,
  },
  {
    name: 'DispSequenceNum',
    header: 'Display Sequence',
    defaultFlex: 1,
    editable: true,
    editor: NumericEditor,
    // TODO: wait for bugfix from reactdatagrid.io
    // https://github.com/inovua/reactdatagrid/issues/132
    editorProps: {
      allowNegative: false,
      allowEmpty: false,
      allowFloat: false,
    },
  },
  {
    name: 'MediaType',
    header: 'Media Type',
    defaultFlex: 1,
    editable: true,
    render: ({ value }: { value: any }) => enumNameToLabel(MediaType[value]),
    editor: SelectEditor,
    editorProps: {
      dataSource: enumToObjects({ choices: MediaType }),
      clearIcon: false,
      searchable: false,
    },
  },
  {
    name: 'ProductCount',
    header: 'Media count',
    defaultFlex: 1,
  },
];

const csvColumns: CSVColumns = [
  { key: 'MediaPlacementName', header: 'Name' },
  { key: 'DispSequenceNum', header: 'Display Sequence' },
  { key: 'MediaType', header: 'Media Type', transform: (v: any) => enumNameToLabel(MediaType[v]) },
  { key: 'ProductCount', header: 'Media Count' },
];

const actionsColumn: TypeActionsColumn<Entities.MediaPlacement> = {
  name: 'MediaPlacementId',
  header: '',
  defaultFlex: 2,
};

interface PageContentProps {
  data: Entities.MediaPlacement[];
  loading: boolean;
  reloadData: () => void;
}

const PageContent: React.FC<PageContentProps> = ({ data, reloadData, loading }) => {
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState(data);
  const [interactionsDisabled, setInteractionsDisabled] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const onSave = useCallback((entity: Entities.MediaPlacement) => {
    const notificationActions = bindActionCreators(Notifications, dispatch);
    const save = async () => {
      setSaving(true);
      await editMedia(entity);
      reloadData();
      setInteractionsDisabled(false);
    };

    save()
      .then(() => notificationActions.setNotification('success', 'Media Placement updated successfully'))
      .catch(() => notificationActions.setNotification('error', 'There was an error saving the changes'))
      .finally(() => setSaving(false));
  }, [reloadData, dispatch]);

  const onDelete = useCallback((row: Entities.MediaPlacement) => Modal.confirm({
    title: `Are you sure you want to delete "${row.MediaPlacementName}"`,
    content: 'This action cannot be undone and you will be unable to recovery any data.',
    okType: 'danger',
    async onOk() {
      const notificationActions = bindActionCreators(Notifications, dispatch);
      setSaving(true);

      try {
        await deleteMedia(row.MediaPlacementId);
        notificationActions.setNotification('success', 'Media placement deleted successfully');
        reloadData();
      } catch (e) {
        notificationActions.setNotification('error', 'There was an error processing your request');
      } finally {
        setSaving(false);
      }
    },
  }), [reloadData, dispatch]);

  const disableInteractions = useCallback(() => {
    if (interactionsDisabled) {
      return;
    }

    setInteractionsDisabled(true);
  }, [setInteractionsDisabled, interactionsDisabled]);

  const enableInteractions = useCallback((editInfo, dirty: boolean) => {
    if (!interactionsDisabled || dirty) {
      return;
    }

    setInteractionsDisabled(false);
  }, [setInteractionsDisabled, interactionsDisabled]);

  return (
    <>
      <Row justify="space-between">
        <Col xs={24} lg={12}>
          <SearchBar
            reference="MediaPlacementNum"
            data={data}
            onResult={setLocalData}
            fields={searchFields}
            disabled={interactionsDisabled}
          />
        </Col>
        <Col>
          <CSVLink
            filename="media_placements"
            data={localData}
            disabled={interactionsDisabled}
            columns={csvColumns}
          />
        </Col>
      </Row>
      <Spacer />
      <DataGrid<Entities.MediaPlacement>
        rowHeight={35}
        columns={columns}
        dataSource={localData}
        loading={loading || saving}
        actionsColumn={actionsColumn}
        onEditStart={disableInteractions}
        onEditCancel={enableInteractions}
        onEditComplete={enableInteractions}
        onSave={onSave}
        onDelete={onDelete}
        editable
        pagination
      />
    </>
  );
};


const Page: React.FC = () => {
  const [data, loading, reloadData] = useLoadData();
  const [addFormVisible, setAddFormVisible] = useState<boolean>();

  return (
    <ContentLayout>
      <Heading
        title="Product Media Placement"
        actions={(
          <Button type="primary" onClick={() => setAddFormVisible(true)}>
            Add
            <PlusOutlined />
          </Button>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <PageContent
          data={data}
          loading={loading}
          reloadData={reloadData}
        />
        <ModalForm
          visible={addFormVisible}
          onCancel={() => setAddFormVisible(false)}
          onOk={() => {
            setAddFormVisible(false);
            reloadData();
          }}
        />
      </SiteContent>
    </ContentLayout>
  );
};

export default Page;
