import React, { useCallback, useContext, useState } from 'react';
import { Alert, Button, Col, DatePicker, Form, Input, message, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Title, Description } from '../styledComponents';
import TableComponent from '../../../components/TableComponent';
import Spacer from '../../../components/common/Spacer';
import OrderDetailsActions from '../../../redux/actions/orderDetails';
import { createShipment } from '../../../services/orders';
import notificationActions from '../../../redux/actions/notifications';

import { formatDate } from '../../../util';
import { sumObjectArrayValues } from '../../../util/strings';
import FileCopy from '../../../assets/icons/file_copy';
import { FormsContext, InfoValue, setOrderDetailspage } from '../common';
import linkERP from '../../../util/linkERP';

const ProductLink: React.FC<{ value: string, displayName: string }> = ({ value, displayName }) => (
  <Link to={`/product-detail/${value}`}>
    {displayName}
  </Link>
);

const ShippingInfo: React.FC = () => {
  const contact = useSelector((state: any) => state.orderDetails.order?.detail?.shipping?.contact);
  return (
    <Row>
      <Col span={12} style={{ padding: '15px' }}>
        <Row>
          <Col span={24}>
            <Title>Shipping</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InfoValue infoSpan={6} valueSpan={18} info="Name" value={contact?.name || 'Not provided'} />
            <InfoValue infoSpan={6} valueSpan={18} info="Company" value={contact?.company || 'Not provided'} />
            <InfoValue infoSpan={6} valueSpan={18} info="Address" value={contact?.address1 || 'Not provided'} />
            <InfoValue infoSpan={6} valueSpan={18} info="" value={contact?.address2 || 'Not provided'} />
            <InfoValue infoSpan={6} valueSpan={18} info="" value={contact?.address3 || 'Not provided'} />
            <Row>
              <Col span={8}>
                <InfoValue infoSpan={6} valueSpan={18} info="City" value={contact?.city || 'Not provided'} />
              </Col>
              <Col span={6}>
                <InfoValue infoSpan={12} valueSpan={12} info="State" value={contact?.state || 'Not provided'} />
              </Col>
              <Col span={10}>
                <InfoValue infoSpan={10} valueSpan={14} info="Zip Code" value={contact?.postalCode || 'Not provided'} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <InfoValue infoSpan={6} valueSpan={18} info="Email" value={contact?.email || 'Not provided'} />
              </Col>
              <Col span={14}>
                <InfoValue infoSpan={6} valueSpan={18} info="Phone" value={contact?.daytimePhone || 'Not provided'} />
              </Col>
            </Row>

          </Col>
        </Row>
      </Col>
      <Col span={12} style={{ padding: '15px' }}>
        <Row>
          <Col span={24}>
            <Title>Special Information</Title>
          </Col>
        </Row>
        <Row>
          <Description>
            {contact?.instruction || 'No instruction provided'}
          </Description>
        </Row>
      </Col>
    </Row>
  );
};
// carrier select component
interface CarrierSelectProps {
  // set method list
  editMode: boolean;

}
// created element to house carrier select carrier & method
const CarrierSelect: React.FC<CarrierSelectProps> = (selectProps) => {
  const { editMode } = selectProps;
  // forms context
  const forms = useContext(FormsContext);
  const { shippingViewForm } = forms;
  const [methods, setMethodList] = useState<any[]>([]);
  // get carrier Names
  const carriers = useSelector((state: any) => state.orderDetails.carriers);
  const carrierNames = carriers?.systemCarrierList.map((c: any) => (c.carrierCode)) || [];
  // on select handler
  const onSelectHandler = useCallback((value: number) => {
    // get method list and set method list
    const targetCarrier = carriers?.systemCarrierList.find((c: any) => c.carrierCode === value);
    let availableMethods: any[] = [];
    if (targetCarrier) {
      availableMethods = targetCarrier.systemCarrierServiceList.map((m: any) => m.serviceCode);
    }
    // clear methods list
    shippingViewForm?.setFieldsValue({ Method: undefined });
    // set method list options
    setMethodList(availableMethods);
  }, [carriers?.systemCarrierList, shippingViewForm]);

  return (
    <Row gutter={[16, 8]}>
      <Col span={12}>
        <Form.Item
          name="ShippingCarrier"
          label="Carrier"
        >
          <Select
            style={{ width: '100%' }}
            disabled={!editMode}
            onSelect={onSelectHandler}
          >
            {
              carrierNames.map((c: any) => (
                <Select.Option key={c} value={c}>{c}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="Method"
          label="Method"
        >
          <Select
            style={{ width: '100%' }}
            disabled={!editMode || methods.length === 0}
          >
            {methods.map((c: any) => (
              <Select.Option key={c} value={c}>{c}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};
// FE-683 & 684 Commenting in case its needed again
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AddShipment: React.FC = () => {
  const editMode = useSelector((state: any) => state.orderDetails.editMode);
  const forms = useContext(FormsContext);
  const { shippingViewForm, pendingForm, billingForm, shippingForm } = forms;
  const order = useSelector((state: any) => state.orderDetails.order?.detail.summary) || [];
  const selectedPendingItems = useSelector((state: any) => state.orderDetails.selectedPendingItems);
  const isFormUpdated = useSelector((state: any) => state.orderDetails.isFormUpdated);
  const { id } = useParams<{ id?: string }>();
  const pendingItems = useSelector((state: any) => state.orderDetails.order?.detail?.items?.filter((f: any) => f.orderItemStatus === 0));
  const dispatch = useDispatch();
  const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);
  const notifications = bindActionCreators(notificationActions, dispatch);
  // According to FE-660 & FE-661 Now order details & shipment are read only and redirecting to ERP System
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addShipmentAction = useCallback(async (data) => {
    if (!shippingViewForm || !pendingForm || !billingForm || !shippingForm) {
      return null;
    }
    orderDetailsActions.setLoading(true);

    const ShippedItems = pendingItems?.filter((p: any) => selectedPendingItems.includes(p.centralOrderLineNum)).map((o: any) => ({
      OrderDCAssignmentLineNum: o.orderDCAssignmentLineNum,
      ShippedQty: o.orderQty,
    })) || [];
    try {
      const form = data;
      const payload = {
        'InputShipments': [
          {
            'ShipmentHeader': {
              'ShipmentID': form.ShipmentID,
              'WarehouseID': order.DistributionCenterNum,
              'ShippingCarrier': form.ShippingCarrier,
              'ShippingClass': form.Method,
              'MainTrackingNumber': form.TrackingNumber,
              'TotalPackages': 1,
              'MainReturnTrackingNumber': form.ReturnNumber,
              'OrderDCAssignmentNum': order.orderDCAssignmentNum,
              'DistributionCenterNum': order.DistributionCenterNum,
              'ChannelOrderID': order.channelOrderID,
              'ShipmentType': form.DistributionCenterNum,
              'ShipmentReferenceID': form.ShipmentReferenceID,
            },
            'PackageItems': [
              {
                'ShipmentPackage': {
                  'PackageID': form.PackageID,
                  'PackageTrackingNumber': form.TrackingNumber,
                },
                ShippedItems,
              },
            ],
          },
        ],
      };
      await createShipment(payload);

      notifications.setNotification('success', 'Shipment saved');
      return await Promise.resolve();
    } catch (e) {
      notifications.setNotification('error', e);
      return await Promise.reject(e);
    } finally {
      setOrderDetailspage(id, dispatch, billingForm, shippingForm, pendingForm);
      orderDetailsActions.setLoading(false);
      shippingViewForm.resetFields();
    }
  }, [billingForm, dispatch, id, notifications, order.DistributionCenterNum, order.channelOrderID, order.orderDCAssignmentNum, orderDetailsActions, pendingForm, pendingItems, selectedPendingItems, shippingForm, shippingViewForm]);

  if (!shippingViewForm || !pendingForm || !billingForm || !shippingForm || !id) {
    return null;
  }

  return (
    <Form.Provider onFormChange={() => {
      if (!isFormUpdated) {
        orderDetailsActions.setIsFormUpdated(true);
      }
    }}
    >
      <Form
        labelAlign='right'
        labelCol={{
          sm: { span: 12 },
          lg: { span: 7 },
          xl: { span: 7 },
        }}
        wrapperCol={{
          sm: { span: 13 },
          lg: { span: 12 },
          xl: { span: 17 },
        }}
        form={shippingViewForm}
        style={{ backgroundColor: '#ececec', padding: '15px' }}
        // According to FE-660 & FE-661 Now order details & shipment are read only and redirecting to ERP System
        onFinish={() => linkERP.openCentralOrderHistory(order.centralOrderNum)}
      >
        <Row>
          <Col>
            <Title>Add Shipment</Title>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 19,
              }}
              name="ShipmentReferenceID"
              label="ShipmentReference ID"
              style={{ width: '100%' }}
            >
              <Input type="text" disabled={!editMode} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  name="PackageID"
                  label="Package ID"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ShipmentID"
                  label="Shipment ID"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  name="TrackingNumber"
                  labelAlign="right"
                  label="Tracking Number"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ReturnNumber"
                  label="Return Number"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  name="DistributionCenterNum"
                  label="DC"
                  style={{ width: '100%' }}
                >
                  <Select
                    value={0}
                    disabled={!editMode}
                  >
                    <Select.Option value={0}>DC1</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="Date"
                  label="Date"
                  style={{ width: '100%' }}
                >
                  <DatePicker
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
            </Row>
            <CarrierSelect editMode={editMode} />
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  name="Invoice"
                  label="Invoice"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Button
                    htmlType="submit"
                    style={{ alignSelf: 'center' }}
                    size="large"
                  >
                    Ship
                    <PlusOutlined />
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Form.Provider>
  );
};

const Pending: React.FC = () => {
  const editMode = useSelector((state: any) => state.orderDetails.editMode);
  const selectedPendingItems = useSelector((state: any) => state.orderDetails.selectedPendingItems);
  const forms = useContext(FormsContext);
  const { pendingForm, shippingViewForm } = forms;
  const pendingItems = useSelector((state: any) => state.orderDetails.order?.detail.items.filter((f: any) => f.orderItemStatus === 0)) || [];
  const dispatch = useDispatch();
  const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);

  if (!pendingForm) return null;
  // FE-683 & 684 Commenting in case its needed again
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowSelection = {
    selectedRowKeys: selectedPendingItems,
    onChange: (selectedRowKeys: any) => {
      const shippedItems = pendingItems?.filter((p: any) => selectedRowKeys.includes(p.centralOrderLineNum));
      const sumTotal = sumObjectArrayValues(shippedItems, 'unitPrice');
      const sumShippingAmount = sumObjectArrayValues(shippedItems, 'shippingAmount');
      orderDetailsActions.setShippingTotal(sumTotal.toString());

      shippingViewForm?.setFieldsValue({
        ...shippingViewForm?.getFieldsValue(),
        ShippingAmount: sumShippingAmount.toString(),
      });
      if (!editMode) {
        orderDetailsActions.setIsFormUpdated(true);
        // orderDetailsActions.setEditMode(true);
      }
      orderDetailsActions.setSelectedPendingItems(selectedRowKeys);

    },
    getCheckboxProps: (record: any) => ({
      id: record.centralProductNum,
      type: 'checkbox',
    }),
  };

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: '250px',
    },
    {
      title: 'Description',
      dataIndex: 'itemTitle',
      key: 'itemTitle',
      width: '550px',
    },
    {
      title: 'Qty',
      dataIndex: 'orderQty',
      key: 'orderQty',
      width: '150px',

    },
    {
      title: 'Status',
      dataIndex: 'orderItemStatusName',
      key: 'orderItemStatusName',
      width: '150px',

    },
    {
      title: '',
      dataIndex: 'centralOrderLineNum',
      key: 'centralOrderLineNum',
      width: '0px',
      className: 'hide',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Title>Pending</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form form={pendingForm}>
              <TableComponent
                pagination={false}
                loadingStatus={false}
                rowKey={(record: { centralOrderLineNum: any }) => record.centralOrderLineNum}
                dataSource={pendingItems}
                columns={columns}
              />
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Shipped: React.FC = () => {
  const shippedItems = useSelector((state: any) => state.orderDetails.order?.shipments) || [];
  const shipped = shippedItems.map((s: any) => s.packages.map((p: any) => p.skus.map((sku: any) => ({
    sku: sku.sku,
    itemTitle: sku.itemTitle,
    orderQty: sku.fulfilledQty,
    shipDate: formatDate(p.shippedDateUtc, 'fullDate'),
    carrier: p.shippingCarrier,
    TrackingNumber: p.trackingNum,
    centralProductNum: sku.centralProductNum,
  })),
  )).flat(2);

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: '250px',
      render: function CellWrapper(_: any, record: any) {
        return <ProductLink value={record.centralProductNum} displayName={record.sku} />;
      },
    },
    {
      title: 'Description',
      dataIndex: 'itemTitle',
      key: 'itemTitle',
      width: '550px',
    },
    {
      title: 'Qty',
      dataIndex: 'orderQty',
      key: 'orderQty',
      width: '150px',
    },
    {
      title: 'Ship date',
      dataIndex: 'shipDate',
      key: 'shipDate',
      width: '150px',
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      width: '150px',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'TrackingNumber',
      key: 'TrackingNumber',
      width: '150px',
      render: function render(value: any) {
        return (
          <CopyToClipboard text={value?.toString()} onCopy={() => message.success('Tracking number Copied to clipboard')}>
            <span style={{ cursor: 'pointer' }}>
              {value}
              <FileCopy width={24} height={24} style={{ marginLeft: '15px' }} />
            </span>
          </CopyToClipboard>
        );
      },
    },
    {
      title: '',
      dataIndex: 'centralProductNum',
      key: 'centralProductNum',
      width: '0px',
      className: 'hide',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Title>Shipped</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableComponent
              pagination={false}
              loadingStatus={false}
              dataSource={shipped}
              columns={columns}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Shipping: React.FC = () => {
  const showPendingErrorMessage = useSelector((state: any) => state.orderDetails.showPendingErrorMessage);
  const pendingErrorMessage = useSelector((state: any) => state.orderDetails.pendingErrorMessage);
  return (
    <Row>
      <Col span={24}>
        <ShippingInfo />
        <Spacer />
        {showPendingErrorMessage && (
          <Alert message={pendingErrorMessage} type="error" />
        )}
        <Spacer />
        <Pending />
        <Spacer />
        <Shipped />
      </Col>
    </Row>
  );
};

export default Shipping;
