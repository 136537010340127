import React from 'react';
import { Col, Divider, Modal, Row, Typography } from 'antd';
import { APP_VERSION } from '../../constants/config';
import { fetchSiteConfig } from '../../util';

const { Title } = Typography;

interface VersionModalProps {
  show: boolean;
  onClose: Function;
}

export default function VersionModal(props: VersionModalProps): JSX.Element {
  const { useState } = React;
  const { show, onClose } = props as any;
  const [inited, setInited] = useState(false);
  const [siteConfig, setSiteConfig] = useState<StringKAnyVPair>({});

  // eslint-disable-next-line
  const getSiteInfo = async () => {
    //const cfg = await fetchSiteConfig();
    const cfg = fetchSiteConfig();

    setSiteConfig(cfg);
  };

  React.useEffect(() => {
    if (!inited) {
      getSiteInfo();
      setInited(true);
    }
  }, [getSiteInfo, inited]);

  return (
    <Modal
      centered
      visible={show}
      onCancel={onClose}
      //maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      <div style={{
        padding: 8,
        backgroundColor: '#FFF',
        borderRadius: 4,
      }}>
        <Row wrap={false}>
          <Col>
            <div style={{ padding: 20 }} >
              <img className="logo" alt="Company Logo" src={siteConfig.logo} style={{ width: 100 }} />
            </div>
          </Col>
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col>
            <div style={{ padding: 20 }} >
              <Title level={2}>{siteConfig.name || ' '}</Title>
              <Title level={5}>{`Version ${APP_VERSION}`}</Title>
            </div>
          </Col>
        </Row>
      </div>
    </Modal >
  );
}
