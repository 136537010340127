import MockAdapter from 'axios-mock-adapter';
import {erpAPI} from './http';
import ordersService from '../mocks/orders.json';

function injectMockTest() {
  const mock = new MockAdapter(erpAPI, { delayResponse: 300 });
  const responseOrders = ordersService.response.orders;
  mock.onGet('/salesorder').reply(
    200,
    responseOrders,
  );
}

if (process.env.NODE_ENV === 'test') {
  injectMockTest();
}


export const fetchSalesOrderDetail = async (id: string):Promise<Entities.SalesOrderDetails> => {
  const { data: { success, messages, salesOrder } } = await erpAPI.get(`/salesorders/${id}`);
  if (!success) {
    throw (messages[0].message);
  } else {
    return salesOrder;
  }
};

export const fetchSalesOrderProducts = async (params: object):Promise<Entities.SalesProducts> => erpAPI.post('/productExts/find', {
  ...params,
});

export const fetchCustomers = async (params: object):Promise<Array<Entities.SalesOrderCustomers>> => {
  const  { data: { success, messages, customerList }} = await erpAPI.post('/customers/find', {
    ...params,
  });

  if (!success) {
    throw (messages[0].message);
  } else {
    return customerList;
  }
};

export const createSalesOrder = async (params: object):Promise<Entities.SalesOrderDetails> => {
  const { data: { success, messages, salesOrder } } = await erpAPI.post('/salesOrders', {
    ...params,
  });
  if (!success) {
    throw (messages[0].message);
  } else {
    return salesOrder;
  }
};

export const saveSalesOrder = async (params: object):Promise<Entities.SalesOrderDetails> => {
  const { data: { success, messages, salesOrder } } = await erpAPI.patch('/salesOrders', params);
  if (!success) {
    throw (messages[0].message);
  } else {
    return salesOrder;
  }
};

export const deleteSalesOrder = async (rowNum: string): Promise<any> => erpAPI.delete(`/salesOrders/${rowNum}`);
