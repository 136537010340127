import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Actions, Dispatcher, ProductDetailState } from '../context';
import { AccListWrapper, ProductTabContainer } from '../styledComponents';
import FormItemEnabler from '../../ProductBulkUpdate/FormItemEnabler';
import FormElement, { Type } from '../../../components/common/FormElement';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import message from '../../../components/common/message';
import { LOADING_ICON_SIZE1 } from '../../../constants/config';
//import message from '../../../components/common/message';
//import Products from '../../../services/products';
// import { saveChannelInv } from '../context';
import { useDebounce } from '../../../util';
import { loadAttributesChannels } from '../helper';

interface ChannelInvTabProps {
  channelsInventory: Array<object>;
  editMode: boolean;
  setFilter: any;
  channelInvForm: FormInstance,
  attributesChannels: Array<Entities.AttributesChannels>,
  channelsInvDCs: Array<object>,
  enabler: boolean,
  onCheckChange?: Function,
  enterEditModeDispatch: () => void;
  saveChannelInvDispatch: () => Promise<void>;
  state: ProductDetailState;
}

interface FiltersProps {
  setFilter: any;
  channelInvForm: FormInstance;
  attributesChannels: Array<Entities.AttributesChannels>;
  channelsInvDCs: Array<object>;
  enterEditModeDispatch: () => void;
  saveChannelInvDispatch: () => Promise<void>;
  state: ProductDetailState;
}

const Filters: React.FC<FiltersProps> = ({
    setFilter,
    channelInvForm,
    attributesChannels = [],
    channelsInvDCs = [],
    enterEditModeDispatch,
    saveChannelInvDispatch,
    state,
  }) => {
  const [isEditMode, setIsEditMode] = useState(!!state?.editMode);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const { basicInfoForm = null } = state || {};
  const channelsInv = basicInfoForm?.getFieldValue('channelsInv') || [];

  const enterEditMode = () => {
    enterEditModeDispatch();

    setIsEditMode(true);
  };
  
  // const saveChannelInvDispatch = async () => {
  //   if (state && dispatch) {
  //     await saveChannelInv(dispatch, state, state.productId);
  //     dispatch({ type: Actions.SET_FORMUPDATED, params: false });
  //   }
  // };

  const saveData = async () => {
    //console.log('s->', state?.productId);
    setIsLoading(true);

    try {
      await saveChannelInvDispatch();
    } catch(e) {
      message.error(`Save channel inv error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(setFilter, [debouncedQuery, setFilter]);

  return (<>
    <ProductTabContainer>
      <Row style={{ backgroundColor: '#e3e2e2', padding: '15px' }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col style={{ marginBottom: '45px' }}>
              <h3 style={{ marginBottom: '2px' }}>Filters</h3>
            </Col>
            <Col>
              {isEditMode && <Button
                disabled={!state?.isFormUpdated}
                onClick={saveData}
                type="primary"
              >
                <SaveOutlined />
                Save
              </Button>}
              {!isEditMode && <Button
                disabled={channelsInv.length === 0}
                onClick={enterEditMode}
                type="primary"
              >
                <EditOutlined />
                Edit
              </Button>}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
              <Form
                layout="inline"
                size="large"
                form={channelInvForm}
              >
                <Form.Item
                  name="Filter"
                >
                  <Input.Search
                    style={{ width: 200, marginRight: '5px', borderBottom: 'solid 1px #a7acb4', borderRadius: '0px' }}
                    name="Filter"
                    size="large"
                    placeholder="Code"
                    onChange={(e: any) => setQuery(e.target.value)}
                    bordered={false}
                    allowClear
                    value={query}
                  />
                </Form.Item>
                <Form.Item
                  name="Channel"
                >
                  <Select
                    placeholder="Channel"
                    onChange={setFilter}
                    showSearch
                    allowClear
                    style={{ width: 200, marginRight: '5px' }}
                  >
                    {attributesChannels?.map((c: any) => (
                      <Select.Option key={c.channelNum} value={c.channelNum}>{c.channelName}</Select.Option>))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="DistributionCenter"
                >
                  <Select
                    placeholder="Distribution Center"
                    showSearch
                    allowClear
                    onChange={setFilter}
                    style={{ width: 200, marginRight: '5px' }}
                  >
                    {channelsInvDCs?.map((dc: any) => (
                      <Select.Option
                        key={dc.distributionCenterNum}
                        value={dc.distributionCenterNum}
                      >
                        {dc.distributionCenterName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </ProductTabContainer>
    {isLoading && <ScreenMask>
      <Loading size={LOADING_ICON_SIZE1} />
    </ScreenMask>}
  </>);
};

export const ChannelInvTab: React.FC<ChannelInvTabProps> = ({
    channelsInventory = [],
    enterEditModeDispatch,
    editMode,
    setFilter,
    channelInvForm,
    attributesChannels = [],
    channelsInvDCs = [],
    enabler,
    onCheckChange,
    saveChannelInvDispatch,
    state,
  }) => {
  const ChannelInvInput = enabler ? FormItemEnabler : FormElement;
  return (
    <>
      <Filters
        setFilter={setFilter}
        attributesChannels={attributesChannels}
        channelsInvDCs={channelsInvDCs}
        channelInvForm={channelInvForm}
        enterEditModeDispatch={enterEditModeDispatch}
        saveChannelInvDispatch={saveChannelInvDispatch}
        state={state}
      />
      {channelsInventory?.map((c: any, channelsInvIndex: number ) => (
        <div key={`${Math.random()}_${Math.random()}`} style={{ display: c.display ? '' : 'none' }}>
          <Row>
            <Col span={24} style={{ marginTop: '45px' }}>
              <h3 style={{ marginBottom: '2px', borderBottom: 'solid 1px #016dff' }}>{c.channelName}</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ marginBottom: '20px', paddingLeft: '15px' }}>
              {c.channelAccountList.map((i: any, channelAccountListIndex: number) => (
                <AccListWrapper key={`${Math.random()}_${Math.random()}`}>
                  <Row>
                    <Col span={24} style={{ marginTop: '25px', marginBottom: '15px' }}>
                      <h3 style={{ marginBottom: '2px', borderBottom: 'solid 1px #016dff' }}>{i.channelAccountName}</h3>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '15px' }}>
                    <Col span={6}>
                      <b>Distribution Center</b>
                    </Col>
                    <Col span={6}>
                      <b>Code</b>
                    </Col>
                    <Col span={6}>
                      <b>Type</b>
                    </Col>
                    <Col span={6}>
                      <b>Quantity</b>
                    </Col>
                  </Row>
                  {
                    i.distributionList.length > 0 ? i.distributionList.map((dl: any, distributionListIndex: number) => (
                      <Row key={`${dl.distributionCenterNum}_${Math.random()}`} style={{ display: dl.display ? '' : 'none' }}>
                        <Col span={6}>
                          {dl.distributionCenterName}
                        </Col>
                        <Col span={6}>
                          {dl.distributionCenterCode}
                        </Col>
                        <Col span={6}>
                          Warehouse
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'channelAccountNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'indexNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'distributionCenterNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'display']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <ChannelInvInput
                            inputProperties={{
                              disabled: !editMode,
                            }}
                            inputType={Type.NUMBER}
                            formItemProperties={{
                              name: ['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'qty'],
                            }}
                            onCheckChange={onCheckChange}
                          />
                        </Col>
                      </Row>
                    )) : <h3>No Distribution Centers found</h3>
                  }
                </AccListWrapper>
              ))}
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

type ChannelInvProps = {
  focused?: boolean;
  loadChannelInv: Function;
  productId: string;
  dispatch: Dispatcher;
  state: ProductDetailState;
  setFilter: () => void;
  enterEditModeDispatch: () => void;
  saveChannelInvDispatch: () => Promise<void>;
};

const ChannelInv = (props: ChannelInvProps) => {
  const {
    state,
    dispatch,
    setFilter,
    enterEditModeDispatch,
    saveChannelInvDispatch,
  } = props;
  const { basicInfoForm = null } = state || {};
  //const [attributeChannels, setAttributeChannels] = useState<Array<Entities.AttributesChannels>>([]);
  //const [channelsInvDCList, setChannelsInvDCList] = useState<object[]>([]);
  //const [channelsInvList, setChannelsInvList] = useState<object[]>([]);
  const [attrChnReady, setAttrChnReady] = useState(false);
  const [inited, setInited] = useState(false);

  /*const loadAttributeChannels = async () => {
    try {
      const list = await Products.getAccountEnabled();

      if (Array.isArray(list)) {
        setAttributeChannels(list);

        if (typeof dispatch === 'function') {
          dispatch({ type: Actions.SET_ATTRIBUTESCHANNELS, params: list });
        }
      }
    } catch(e) {
      message.error(`Fetch attribte channels error: ${e}`);
    }
  };*/

  /*const loadChannelInv = async () => {
    try {
      const res = await Products.fetchProductAssignedChannelInv(props.productId);
      //console.log('res -->', res);
      if (res && typeof res === 'object') {
        if (Array.isArray(res.distributionCenterList)) {
          setChannelsInvDCList(res.distributionCenterList);
        }

        if (Array.isArray(res.channelList)) {
          setChannelsInvList(res.channelList);
        }
      }
    } catch(e) {
      message.error(`Fetch channel inv error: ${e}`);
      console.error('Fetch channel inv error:', e);
    }
  };*/

  const isDataReady = () => {
    if (state) {
      const { attributesChannels } = state;
      const acReady = Array.isArray(attributesChannels) && attributesChannels.length > 0;

      return acReady && attrChnReady;
    }
  };

  // eslint-disable-next-line
  const loadAttrChannels = async () => {
    if (!state) return;
    console.log('atcs', state.attributesChannels);
    let readyData = false;
    let ret = state.attributesChannels;

    if (ret && Array.isArray(ret) && ret.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      //const attributesChannels = await Products.getAccountEnabled();
      const attributesChannels = await loadAttributesChannels();
      console.log('attr:', attributesChannels);

      if (typeof dispatch === 'function') {
        dispatch({ type: Actions.SET_ATTRIBUTESCHANNELS, params: attributesChannels });
      }

      ret = attributesChannels;
    }

    setAttrChnReady(true);

    return ret;
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    //loadAttributeChannels();
    //loadChannelInv();
    props.loadChannelInv();
  };
  React.useEffect(() => {
    if (props.focused) {
      if (!inited) {
        loadInitialData();
        loadAttrChannels();
        setInited(true);
      }
    }
  }, [attrChnReady, inited, loadAttrChannels, loadInitialData, props]);

  if (!state || !dispatch) {
    return null;
  }

  const channelsInv = basicInfoForm?.getFieldValue('channelsInv') || [];
  
  /*if (attrChnReady && channelsInv?.length === 0) {
  //if (channelsInvList.length === 0) {
    return (
      <>
        <h3 style={{ marginBottom: '2px' }}>Channel Inventory not enabled on this profile! Contact administrator if assistance required!</h3>
      </>
    );
  }*/

  if (props.focused && !isDataReady()) {
    //console.log('->', state);
    return (<>
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    </>);
  }

  return (
    <ChannelInvTab
      enterEditModeDispatch={enterEditModeDispatch}
      channelsInventory={channelsInv}
      //channelsInventory={[]}
      //channelsInventory={channelsInvList}
      editMode={state.editMode}
      setFilter={setFilter}
      channelsInvDCs={state.channelsInvDCs}
      //channelsInvDCs={channelsInvDCList}
      channelInvForm={state.channelInvForm}
      attributesChannels={state.attributesChannels}
      //attributesChannels={attributeChannels}
      enabler={false}
      state={state}
      saveChannelInvDispatch={saveChannelInvDispatch}
    />
  );
};


export default React.memo(ChannelInv);
