import { Col, Form, FormInstance, Row, Tabs } from 'antd';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import TableComponent from '../../../components/TableComponent';
import OrderDetailsActions from '../../../redux/actions/orderDetails';
import { toMoney } from '../../../util/strings';
// eslint-disable-next-line import/no-cycle
import { FormWrapper, Title } from '../styledComponents';
import { useActiveTabOnQuery } from '../../../util/hooks';
import FormElement from '../../../components/common/FormElement';
import { contactFields, FormsContext, InfoValue, productColumns } from '../common';

const TotalDivider = styled.div`
  border-bottom: solid #2d3f5d 1px;
  width: 100%;
  height: 1px;
`;

const ContactDetailsForm: React.FC<{ name: string, title: string, form: FormInstance }> = ({ name, title, form }) => {
  const isFormUpdated = useSelector((state: any) => state.orderDetails.isFormUpdated);
  const dispatch = useDispatch();
  const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);
  return (
    <FormWrapper>
      <Row>
        <Col span={24}>
          <Title>{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Provider onFormChange={() => {
            if (!isFormUpdated) {
              orderDetailsActions.setIsFormUpdated(true);
            }
          }}
          >
            <Form
              name={name}
              form={form}
              labelCol={{
                sm: { span: 12 },
                lg: { span: 7 },
                xl: { span: 4 },
              }}
              wrapperCol={{
                sm: { span: 13 },
                lg: { span: 12 },
                xl: { span: 20 },
              }}
            >
              {contactFields.data1.map((d: any) => (
                <FormElement
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...d}
                  key={d.formItemProperties.name}
                />
              ))}
              <Form.Item
                style={{ margin: 'auto' }}
                wrapperCol={{
                  xl: { span: 24 },
                }}
              >
                {contactFields.data2.map((d: any) => (
                  <FormElement
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...d}
                    key={d.formItemProperties.name}
                  />
                ))}
              </Form.Item>
              <Form.Item
                style={{ margin: 'auto' }}
                wrapperCol={{
                  xl: { span: 24 },
                }}
              >
                {contactFields.data3.map((d: any) => (
                  <FormElement
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...d}
                    key={d.formItemProperties.name}
                  />
                ))}
              </Form.Item>
            </Form>
          </Form.Provider>
        </Col>
      </Row>
    </FormWrapper>
  );
};


const DetailsTab: React.FC = () => {
  const forms = useContext(FormsContext);
  const { shippingForm, billingForm } = forms;
  const order = useSelector((state: any) => state.orderDetails.order);
  if (!shippingForm || !billingForm) {
    return null;
  }
  billingForm.setFieldsValue({
    ...order?.detail.billing.contact,
  });
  shippingForm.setFieldsValue({
    ...order?.detail.shipping.contact,
  });
  return (
    <>
      <Row>
        <Col span={12}>
          <ContactDetailsForm name="shipping" title="Shipping" form={shippingForm} />
        </Col>
        <Col span={12}>
          <ContactDetailsForm name="billing" title="Billing" form={billingForm} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title>Products</Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TableComponent
                scroll={{ x: 1000 }}
                columns={productColumns}
                dataSource={order?.detail?.items}
                loadingStatus={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col offset={20} span={4}>
          <InfoValue info="Subtotal" floatValue="right" value={true ? '' : toMoney(order?.detail?.summary?.subtotalOrderAmount)} />
          <InfoValue info="Shipping" floatValue="right" value={true ? '' : toMoney(order?.detail?.summary?.totalShippingAmount)} />
          <InfoValue info="Discount" floatValue="right" value={true ? '' : toMoney(order?.detail?.summary?.totalDiscountAmount)} />
          <InfoValue info="Tax" floatValue="right" value={true ? '' : toMoney(order?.detail?.summary?.totalTaxAmount)} />
          <TotalDivider />
          <InfoValue info="Total" floatValue="right" value={true ? '' : toMoney(order?.detail?.summary?.totalOrderAmount)} />
        </Col>
      </Row>
    </>
  );
};

const HistoryTab: React.FC = () => null;

const Details: React.FC = () => {
  const [activeTab, onTabChange] = useActiveTabOnQuery();
  return (
    <Tabs
      defaultActiveKey={activeTab}
      onChange={onTabChange}
    >
      <Tabs.TabPane
        tab="Details"
        key="details"
        forceRender
        className="orderDetailDetails"
      >
        <DetailsTab />
      </Tabs.TabPane>
      <Tabs.TabPane key="history" tab="History">
        <HistoryTab />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Details;
