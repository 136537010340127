import {
  LSK_PROFILE_IS_SELECTED,
  LSK_PROFILE_SELECTED_INDEX,
} from '../../constants/config';
import { ProfilesAction, ProfilesActionType } from '../types/profiles';

const setProfiles = (profiles: Entities.LoginProfile[]): ProfilesActionType => ({
  type: ProfilesAction.SET_PROFILES,
  profiles,
});

const setPermissions = (permissions: Entities.ProfileUserPermission[]): ProfilesActionType => {
  const permissionsMap = new Map<number, Entities.ProfileUserPermission>(permissions.map(p => [p.PermissionNum, p]));

  return {
    type: ProfilesAction.SET_PERMISSIONS,
    permissions: permissionsMap,
  };
};

const setSelectedIndex = (selectedIndex: number): ProfilesActionType => {
  localStorage.setItem(LSK_PROFILE_SELECTED_INDEX, `${selectedIndex}`);

  return {
    type: ProfilesAction.SET_SELECTED_INDEX,
    selectedIndex,
  };
};

const setIsProfileSelected = (isProfileSelected: boolean): ProfilesActionType => {
  localStorage.setItem(LSK_PROFILE_IS_SELECTED, isProfileSelected ? '1' : '0');

  return {
    type: ProfilesAction.SET_IS_PROFILE_SELECTED,
    isProfileSelected,
  };
};

export default {
  setProfiles,
  setPermissions,
  setSelectedIndex,
  setIsProfileSelected,
};
