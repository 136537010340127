import {bindActionCreators} from 'redux';
import {FormInstance} from 'antd';
import React from 'react';
import SalesOrderDetailsActions from '../../redux/actions/salesOrderDetails';

export const calculateTotals = (discount: number, shippingAmount: number, subTotalAmount: number, discountType: string, taxAmount: number, miscAmount: number, dispatch: any) => {
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  const discountRate = discountType === 'discountRate' ? discount : 0;
  const discountAmount = discountType === 'discountRate' ? Math.round((((subTotalAmount/100)*discount) + Number.EPSILON) * 100 ) / 100 : discount;
  const totalAmount = (((subTotalAmount - discountAmount) + shippingAmount) + miscAmount) + taxAmount;
  const totals = {
    totalAmount: totalAmount < 0 ? 0 : totalAmount,
    subTotalAmount,
    discountAmount,
    discountRate,
    shippingAmount,
    taxAmount,
    miscAmount,
  };
  salesOrderDetailsActions.setTotalQty(totals);
};

export type FormsContextType = {detailsForm?: FormInstance, shippingForm?: FormInstance, billingForm?: FormInstance, shippingProductsTableForm?: FormInstance};

export const FormsContext = React.createContext<FormsContextType>({});
