import Icon from '@ant-design/icons'
import React from 'react';
import { BaseIcon } from './base';

const Map: React.FC<BaseIcon> = (
  {
    style = {},
    height = 16,
    width = 16,
    viewBox = '0 0 24 24',
    pathColor = 'black',
  },
) => (
  <Icon
    component={() => (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} viewBox={viewBox} width={width} fill={pathColor}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"
        />
      </svg>
    )}
  />
);

export default Map;
