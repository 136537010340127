import React from 'react';
import {
  Checkbox,
  List,
  Row,
  Typography,
  notification,
} from 'antd';
import {
  DownOutlined,
  //EyeOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { FormLabel } from '../../../components/common/styledComponents';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import {
  ATTRIBUTE_DATA_TYPE_VIRTUAL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { fetchContentSourceType } from '../../../services/copywriting';
import { AttrWrapper } from '../Tabs/Attributes';
import AttrEditor from './AttrEditor';
import { SOURCE_TYPE_ERP, SOURCE_TYPE_SYS } from '.';

type AttrListProps = {
  dispatch?: Function;
  editable?: boolean;
  list: StringKAnyVPair[];
  productId: string;
  selectable?: boolean;
  setSelectedAttributes?: Function;
  state?: StringKAnyVPair;
  tiled?: boolean;
  version: number;
  filtered?: boolean;
};

const cwGroups: StringKAnyVPair[] = [];

const AttrList = (props: AttrListProps) => {
  const { useState } = React;
  const [attrGroups, setAttrGroups] = useState<StringKAnyVPair[]>([]);
  const [currentVersion, setCurrentVersion] = useState(props.version);
  //const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [listRows, setListRows] = useState<StringKAnyVPair[]>([]);
  const [selectedAttrs, setSelectedAttrs] = useState<number[]>([]);

  const attrListRow = (attrRow: StringKAnyVPair) => {
    const { row } = attrRow;

    return (<>
      <div>
        {row.map((e: StringKAnyVPair) => (
          <span
            className="attr-cell"
            key={e.copywritingElementNum}
          >
            <Row align="middle">
              <FormLabel
                className="attr-label"
                onClick={() => onSelectAttribute(e)}
              >
                {props.selectable && (
                  <Checkbox
                    disabled={!e.copywritingValue}
                    value={e.copywritingElementNum}
                    checked={selectedAttrs.indexOf(e.copywritingElementNum) > -1}
                  />
                )}
                <Typography.Text
                  className={getLabelClassNames(e)}
                  ellipsis={{ tooltip: e.elementName }}
                >
                  {[SOURCE_TYPE_ERP, /*SOURCE_TYPE_SYS*/].indexOf(e.source) > -1 && (<>
                    &nbsp;
                    <sup className="text-tag sup-box">
                      <span className="text-black text-size09">
                        {e.source === SOURCE_TYPE_ERP && 'ERP'}
                        {e.source === SOURCE_TYPE_SYS && 'PIM'}
                      </span>
                    </sup>
                  </>)}
                  {e.elementName}
                </Typography.Text>
              </FormLabel>
              <AttrEditor
                attr={e}
                className="attr-editor-wrapper"
                dispatch={props.dispatch}
                //editable={props.editable}
                editable={isAttrNotEditable(e) ? false : props.editable}
                key={e.copywritingElementNum}
                productId={props.productId || ''}
                state={props.state}
              />
            </Row>
          </span>
        ))}
      </div>
    </>);
  };

  const getLabelClassNames = (attr: StringKAnyVPair) => {
    const cls: string[] = [];

    if ([SOURCE_TYPE_ERP, /*SOURCE_TYPE_SYS*/].indexOf(attr.source) > -1) {
      cls.push('italic grey');
    }

    if ([SOURCE_TYPE_SYS].indexOf(attr.source) > -1) {
      cls.push('normal-weight');
    }

    return cls.join(' ');
  };

  const getRows = (dataList: StringKAnyVPair[]) => {
    const rows: StringKAnyVPair[] = [];

    // maybe need to filter tags at first
    for (let i = 0; i < dataList.length;) {
      const row: StringKAnyVPair[] = [];

      if (dataList[i]) row.push(dataList[i]);
      if (dataList[i + 1]) row.push(dataList[i + 1]);

      if (row.length > 0) {
        const attrRow = {
          key: `${row[0].copywritingElementNum}-${row[1] ? row[1].copywritingElementNum : ''}`,
          row,
        };
        rows.push(attrRow);
      }

      i += 2;
    }

    return rows;
  };

  const getWrapperClassName = () => {
    return '';
  };

  const isAttrNotEditable = (attr: StringKAnyVPair) => {
    let ret = false;

    if (typeof attr.source === 'number') {
      ret = attr.source === 1;
    }

    if (!ret) {
      if (attr.elementDataType === ATTRIBUTE_DATA_TYPE_VIRTUAL) {
        ret = true;
      }
    }

    if (!ret) {
      if ([SOURCE_TYPE_ERP, /*SOURCE_TYPE_SYS*/].indexOf(attr.source) > -1) {
        ret = true;
      }
    }

    return ret;
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    if (cwGroups.length === 0) {
      setIsLoading(true);

      try {
        const { code, data } = await fetchContentSourceType();

        console.log('cc', code, data);
        if (code === 200 && Array.isArray(data)) {
          data.forEach((e: StringKAnyVPair) => {
            if (!e.isDelete) {
              cwGroups.push({
                id: e.enumValue,
                name: e.enumName,
                type: e.enumType,
              });
            }
          });
          //console.log('cwg', cwGroups);
          if (cwGroups.length > 0) {
            setGroups(cwGroups);
          }
        }
      } catch (e) {
        notification.error({
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
          message: `Fetch content resources groups error: ${e}`,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSectionCollapseChange = (grp: StringKAnyVPair) => {
    //console.log('g ->', grp);
    grp.extended = !grp.extended;
    setAttrGroups([...attrGroups]);
  };

  const onSelectAttribute = (attr: StringKAnyVPair) => {
    if (props.selectable && attr.copywritingValue) {
      const idx = selectedAttrs.indexOf(attr.copywritingElementNum);
      //console.log('sel', attr);
      if (idx > -1) {
        selectedAttrs.splice(idx, 1);
      } else {
        selectedAttrs.push(attr.copywritingElementNum);
      }

      setSelectedAttrs([...selectedAttrs]);

      if (typeof props.setSelectedAttributes === 'function') {
        props.setSelectedAttributes([...selectedAttrs]);
      }
    }
  };

  const renderAttrGroup = (ag: StringKAnyVPair) => {
    const rows = getRows(ag.attrs);
    //console.log('ag->', ag);
    return (
      <div
        className="attr-section"
        key={`${ag.name}`}
        style={{border: props.tiled ? 'none' : undefined}}
      >
        {!props.tiled && (<div
          className="attr-section-header"
          onClick={() => onSectionCollapseChange(ag)}
        >
          {ag.extended ?
            <DownOutlined /> :
            <RightOutlined />
          }
          &nbsp;&nbsp;
          <span className="attr-section-title">{ag.name}</span>
        </div>)}
        <div
          className="attr-section-cell-ctn"
          style={{ display: ag.extended ? '' : 'none', padding: props.tiled ? 0 : undefined }}
        >
          <List
            dataSource={rows}
            renderItem={attrListRow}
            rowKey="key"
          />
        </div>
      </div>
    );
  };

  // eslint-disable-next-line
  const setGroups = (grps: StringKAnyVPair[]) => {
    const gList: StringKAnyVPair[] = [];
    const gids: number[] = [];

    grps.forEach(e => {
      const id = parseInt(e.id);

      if (!isNaN(id)) {
        gList.push({
          ...e,
          attrs: [],
          extended: props.filtered || false,
          id,
        });
        gids.push(id);
      }
    });
    props.list.forEach(e => {
      const gid = parseInt(e.contentSourceGroup);
      const idx = gids.indexOf(gid);

      if (idx > -1) {
        gList[idx].attrs.push(e);
      }
    });
    //console.log('gids', gList, gids);
    setAttrGroups(gList.filter(e => e.attrs.length > 0));
  };

  // eslint-disable-next-line
  const setRows = (dataList: StringKAnyVPair[]) => {
    const rows: StringKAnyVPair[] = [];

    // maybe need to filter tags at first
    for (let i = 0; i < dataList.length;) {
      const row: StringKAnyVPair[] = [];

      if (dataList[i]) row.push(dataList[i]);
      if (dataList[i + 1]) row.push(dataList[i + 1]);

      if (row.length > 0) {
        const attrRow = {
          key: `${row[0].copywritingElementNum}-${row[1] ? row[1].copywritingElementNum : ''}`,
          row,
        };
        rows.push(attrRow);
      }

      i += 2;
    }

    setListRows(rows);
  };

  React.useEffect(() => {
    /*if (!inited) {
      loadInitialData();
      setInited(true);
    }*/

    if (currentVersion !== props.version) {
      //setRows(props.list);
      setCurrentVersion(props.version);

      if (cwGroups.length === 0) {
        loadInitialData();
      } else {
        setGroups(cwGroups);
      }

      console.log('rcal', props.version);
    }
  }, [
    currentVersion,
    loadInitialData,
    props,
    setGroups,
    //setRows,
  ]);

  return (attrGroups.length > 0 /*&& listRows.length > 0*/) ? (<>
    <AttrWrapper className={getWrapperClassName()} style={{ minHeight: 'unset', }}>
      {attrGroups.map(g => renderAttrGroup(g))}
    </AttrWrapper>
    {/*<List
      dataSource={listRows}
      renderItem={attrListRow}
      rowKey="key"
    />*/}
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>) : null;
};

export default AttrList;
