import { digitBridgeApi, settingAPI, ccChannelMetaApi } from './http';


export const editCustomTemplate = async (
  channelAccountNum: number,
  productMappingNum: number,
  headers: StringKAnyVPair,
  params: any,
): Promise<any> => {
  return digitBridgeApi.patch(`/v1/channelAccount/${channelAccountNum}/channelIntegrationTemplate/${productMappingNum}`, null, { headers, params });
};

export const fetchChannelAccountCategoryName = async (
  channelNum: number,
): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelNum}/categoryName`);

export const fetchChannelAccountCategories = async (
  channelNum: number,
  channelAccountNum: number,
): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/selectedCategories`, { headers: { channelNum }});

export const fetchChannelAvailableCategoryList = async (
  channelNum: number,
  channelAccountNum: number,
): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/availableCategoryList`, { headers: { channelNum } });

export const fetchChannelTemplateList = async (
  channelNum: number,
  channelAccountNum: number,
  templateType: number,
  categoryNames: string,
): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/channelIntegrationTemplate/search`, { headers: { channelNum }, params: { templateType, categoryNames } });

export const fetchChannelTemplateType = async (
  channelNum: number,
): Promise<any> => {
  const res = await settingAPI.get(`/api/channel/${channelNum}/templateType`) as StringKAnyVPair;
  let ret = [];

  if (res && typeof res === 'object' && res.code === 200) {
    ret = res.data;
  }

  return ret;
};

export const getAvailableMappingsByChannelNum = async (channelNum: number): Promise<Entities.MappingContentProfile[]> => digitBridgeApi.get(`/v1/channelIntegration/${channelNum}/availableMappingContent`);

export const getChannelAccountTemplate = async (
  channelAccountNum: number,
  channelNum: number,
): Promise<StringKAnyVPair[]> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/ChannelIntegrationTemplate`, {headers: { channelNum }});

export const getChannelAccountVersion = async (
  channelNum: number,
  channelAccountNum: number,
): Promise<any> => {
  const res = await settingAPI.get(`/api/channel/${channelNum}/ChannelAccount/${channelAccountNum}/version`) as StringKAnyVPair;
  let ret = '';

  if (res && typeof res === 'object' && res.code === 200) {
    ret = res.data;
  }

  return ret;
};

export const copyMappingTemplate = async (productMappingNum: number, templateName:string, channelAccountNum: number): Promise<any> => {
  const json = JSON.stringify({templateName});

  return digitBridgeApi.post(`/v1/channels/${productMappingNum}/copyTemplate`, json, {headers:{channelAccountNum}});
};

export const deleteTemplate = async (
  channelNum: number,
  channelAccountNum: number,
  productMappingNum: number,
) => digitBridgeApi.delete(`/v1/channelAccount/${channelAccountNum}/channelIntegrationTemplate/${productMappingNum}`, { headers: { channelNum } });

export const mappingFromExistTemplate = async (sourceMappingNum: number, targetMappingNum: number, channelAccountNum: number): Promise<any> => {
  return digitBridgeApi.post(`/v1/channels/copyTemplateRule/${sourceMappingNum}/${targetMappingNum}`, '', {headers: {channelAccountNum}});
};

export const setMappingTemplateStatus = async (
  channelNum: number,
  channelAccountNum: number,
  productMappingNum: number,
  feedStatus: number,
): Promise<any> => {
  return digitBridgeApi.patch(`/v1/channelAccount/${channelAccountNum}/mappingTemplate/${productMappingNum}/feedStatus/${feedStatus}`, null, {headers: {channelNum}});
};

export const uploadCustomTemplate = async (
  headers: StringKAnyVPair,
  params: StringKAnyVPair,
  data: any,
): Promise<any> => {
  return digitBridgeApi.post('/v1/channelAccount/customTemplate/upload', data, { headers, params });
};

export const getCategories = async (
  params: StringKAnyVPair,
): Promise<any> => {
  return ccChannelMetaApi.get('/channel/searchCategories', { params });
};

export const createChannelTemplateByCategory = async (
  channelAccountNum: string,
  categoryIds: number[],
  isMerge: boolean,
  channelNum: string,
): Promise<any> => {
  return digitBridgeApi.post(`/v1/channelAccount/${channelAccountNum}/generateChannelMappingTemplateWithCategoryIds`, null, {  params : {categoryIds : categoryIds.toString(), isMerge: isMerge ? 1 : 0}, headers: { channelNum } });
};


export const getCategoryVersion = async (
  channelNum: string,
): Promise<any> => {
  return settingAPI.get(`/api/channel/${channelNum}/categoryVersion`);
};
