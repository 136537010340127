import {SalesOrderDetailsActions, SalesOrderDetailsActionTypes} from '../types/salesOrderDetails';

const setSalesOrderDetails = (order: Entities.SalesOrderDetails): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_ORDER,
  order,
});

const setLoading = (value: boolean): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_LOADING,
  value,
});

const setEditMode = (value: boolean): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_EDITMODE,
  value,
});

const setIsFormUpdated = (value: boolean): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_ISFORMUPDATED,
  value,
});

const setActiveView = (value: string): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_ACTIVEVIEW,
  value,
});

const setOpenProductsModal = (value: boolean): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_OPENPRODUCTSMODAL,
  value,
});

const setOpenCustomerModal = (value: boolean): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_OPENCUSTOMERMODAL,
  value,
});

const setDetailsProducts = (value: Array<object>): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_DETAILS_PRODUCTS,
  value,
});

const setSalesOrdersCustomers = (value: Array<Entities.SalesOrderCustomers>): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_CUSTOMERS,
  value,
});

const setTotalQty = (value: object): SalesOrderDetailsActionTypes => ({
  type: SalesOrderDetailsActions.SET_TOTALQUANTITY,
  value,
});

const actions = {
  setSalesOrderDetails,
  setLoading,
  setEditMode,
  setIsFormUpdated,
  setActiveView,
  setOpenProductsModal,
  setDetailsProducts,
  setSalesOrdersCustomers,
  setOpenCustomerModal,
  setTotalQty,
};

export default actions;
