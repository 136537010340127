import React, { SVGProps } from 'react';

const PIM_BGIcon: React.FC<SVGProps<SVGAElement>> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="10" fill="#BC6C25" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.687 21.4574H20.9668C21.2608 21.4572 21.5151 21.2597 21.5781 20.9827L21.8361 19.8477C22.0098 19.7741 22.1792 19.6912 22.3434 19.5995C22.4998 19.5118 22.6512 19.416 22.7969 19.3126L23.948 19.6659C24.2284 19.7519 24.5331 19.638 24.6802 19.3923L25.8201 17.4878C25.9656 17.2417 25.9155 16.9312 25.6992 16.7394L24.8067 15.9542C24.8473 15.5985 24.8473 15.2395 24.8067 14.8838L25.6992 14.0986C25.9155 13.9068 25.9656 13.5964 25.8201 13.3502L24.6802 11.4457C24.5331 11.2 24.2284 11.0861 23.948 11.1721L22.7969 11.5255C22.6532 11.423 22.5037 11.3284 22.349 11.2422C22.1832 11.1486 22.012 11.0643 21.8361 10.9897L21.5781 9.85232C21.5154 9.57505 21.261 9.37725 20.9668 9.37695H18.687C18.3928 9.37725 18.1384 9.57505 18.0757 9.85232L17.8202 10.9891C17.6536 11.0585 17.491 11.1363 17.3329 11.2222C17.1683 11.3142 17.0092 11.415 16.8563 11.5243L15.7058 11.1709C15.4252 11.0846 15.1201 11.1985 14.973 11.4445L13.8331 13.349C13.6872 13.5953 13.7374 13.9062 13.954 14.098L14.8465 14.8814C14.8059 15.2371 14.8059 15.5961 14.8465 15.9518L13.954 16.737C13.7377 16.9288 13.6875 17.2392 13.8331 17.4854L14.9755 19.3887C15.1227 19.6347 15.4277 19.7486 15.7083 19.6623L16.8588 19.3089C17.1577 19.5213 17.4801 19.701 17.8202 19.8447L18.0596 20.9827C18.1159 21.3006 18.3927 21.4574 18.687 21.4574ZM17.3191 15.4172C17.3191 16.7516 18.4408 17.8333 19.8244 17.8333C21.2073 17.8316 22.3279 16.7509 22.3296 15.4172C22.3296 14.0828 21.208 13.0011 19.8244 13.0011C18.4408 13.0011 17.3191 14.0828 17.3191 15.4172Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.0723 22.3554H18.58C18.0136 22.3554 17.5087 22.0318 17.3986 21.5463L17.1591 20.4847C16.8699 20.3694 16.9078 20.4035 16.6801 20.2191L15.533 20.5416C15.0131 20.6317 14.6943 20.3406 14.521 20.1188L12.8213 20.9093C12.5487 21.0369 12.4132 21.3171 12.4132 21.6662L12.4131 26.7492C12.4131 27.4922 12.5898 27.7344 13.1822 27.9313C13.7745 28.1282 18.1378 29.5962 18.6149 29.7501C19.0919 29.9041 19.7168 29.8985 20.2577 29.7501C20.7986 29.6018 26.2875 28.0458 26.8087 27.8943C27.33 27.7427 27.587 27.4757 27.587 26.7711V22.2063C27.587 21.582 27.3789 21.2227 26.9141 20.9827C26.4494 20.7427 25.2032 20.1097 25.2032 20.1097C24.963 20.4474 24.4762 20.6084 24.1513 20.5308L23.0565 20.2387C22.9001 20.3625 22.7248 20.5078 22.4933 20.5766L22.1891 21.6369C22.0984 22.0318 21.6841 22.3554 21.0723 22.3554ZM14.4172 22.5366C15.0098 22.7965 19.3678 24.3255 19.3678 24.3255C19.3678 24.3255 24.8775 22.9128 25.3241 22.7583C25.7708 22.6038 26.2964 23.3369 25.6992 23.5514C25.1019 23.766 19.8244 25.1445 19.8244 25.1445V27.6738C19.8244 27.9776 19.6716 28.4304 19.3678 28.4304C19.0641 28.4304 18.9025 27.9776 18.9025 27.6738V25.1236C18.9025 25.1236 14.5575 23.5097 13.954 23.2567C13.3504 23.0037 13.8246 22.2768 14.4172 22.5366Z" fill="white" />
    </svg>
  );
};

export default PIM_BGIcon;

