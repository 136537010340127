import React from 'react';

import { isValidHttpUrl } from '../../../util';
import LinkCell from './LinkCell';

export const adjustColumns = (cols: StringKAnyVPair[], p: StringKAnyVPair = {}) => {
  cols.forEach(col => {
    if (!col.render && !col.type) {
      if (col.name) {
        const k = col.name;

        col.render = (row: StringKAnyVPair) => {
          const v = row.data[k];

          if (typeof v === 'string') {
            //console.log('cv->', isValidHttpUrl(v), ' : ', v);
            if (isValidHttpUrl(v)) {
              return (
                <LinkCell
                  url={v}
                />
              );
            } else {
              return v;
            }
          } else {
            return v;
          }
        };
      }
    }
  });

  return cols;
};

export const renderColumnContextMenu = (menuProps: any, p: any): any => {
    //console.log('cols->', menuProps, p);
    if (Array.isArray(menuProps.items)) {
      const cols: StringKAnyVPair[] = p.computedProps?.allColumns || [];
      const i = p.cellProps.computedAbsoluteIndex;
      let col: StringKAnyVPair | undefined = undefined;
      let lockIdx = -1;

      if (Array.isArray(cols) && cols[i]) {
        //console.log('p->', p, p.cellProps.computedAbsoluteIndex);
        col = cols[i];
      }

      //console.log('col ->', col);
      menuProps.items.forEach((e: StringKAnyVPair, i: number) => {
        if (e.itemId && e.itemId.indexOf('lock') > -1) {
          lockIdx = i;
        }
      });

      if (lockIdx > -1) {
        menuProps.items.splice(lockIdx + 1, 0, {
          //disabled: col && col.computedLocked === 'end',
          disabled: col && col.computedLocked,
          label: 'Freeze columns to the end',
          onClick: () => {
            if (col) {
              const { computedPropsRef } = p;
              const pr = computedPropsRef.current;

              if (pr) {
                //console.log('cols', p.computedProps.getColumnsInOrder(), p);
                const cols = p.computedProps.getColumnsInOrder();

                for (let i = cols.length - 1; i >=0; i--) {
                  const pc = cols[i];

                  //pr.setColumnLocked(col.id, 'end');
                  setTimeout(() => pr.setColumnLocked(pc.id, 'end'), 0);

                  if (pc.id === col.id) {
                    break;
                  }
                }
              }
            }

            menuProps.onDismiss();
          },
        });
        menuProps.items.splice(lockIdx + 1, 0, {
          //disabled: col && col.computedLocked === 'start',
          disabled: col && col.computedLocked,
          label: 'Freeze columns',
          onClick: () => {
            if (col) {
              const { computedPropsRef } = p;
              const pr = computedPropsRef.current;

              if (pr) {
                //console.log('cols', p.computedProps.getColumnsInOrder(), p);
                const cols = p.computedProps.getColumnsInOrder();

                for (let i = 0; i < cols.length; i++) {
                  const pc = cols[i];

                  //pr.setColumnLocked(col.id, 'end');
                  setTimeout(() => pr.setColumnLocked(pc.id, 'start'), 0);

                  if (pc.id === col.id) {
                    break;
                  }
                }
              }
            }

            menuProps.onDismiss();
          },
        });
      }
    }
  };
