import { CloudDownloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Spin,
  Button,
  Modal,
  Card,
  Typography,
  Space,
  Switch,
  Col,
} from "antd";
import { useHistory } from "react-router-dom";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import { getChannelExportViewInfo } from "../../services/channels";
import { useExecutePromise } from "../../util/hooks";
import {
  useExportChannelAccountFileMutation,
  useExportChannelAccountFilterMutation,
} from "../../redux/api/exports";
import moment from "moment";
import { downloadXlsxFromCsv } from "../../util/files";

const { Text, Title } = Typography;
// const { TabPane } = Tabs;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

type ExportItemType = {
  productMappingNum: number;
  productMappingName: string;
  mappingContentId: string;
  isMapped: boolean;
  type?: string;
};

// const ItemRow = styled(Row)`
//   padding-left: 24px;
//   margin-top: 8px;
//   align-items: center;
// `;

// const ItemText = styled(Text)`
//   width: 200px;
//   padding-left: 24px;
// `;

const CardSpace = styled(Space)`
  width: 850px;
`;

// use to hide based on env
// const isDevEnv = () => {
//   const devEnvs = ['development', 'local'];

//   return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
// };

const ExportSearchButton: React.FC<{
  data?: ExportItemType;
  account: ChannelAccount;
  mappingType: number;
  disabled: boolean;
  templateName: string;
  onCallback?: (data: any) => void;
  getSearchOptions?: any; // get search options fn
  exportAs?: string;
}> = ({
  data,
  account,
  mappingType,
  onCallback,
  disabled,
  templateName,
  getSearchOptions,
  exportAs,
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportChannelAccountFilterMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback(data);
  }, [data, onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      if (typeof operationResponse === "object") {
        handleSuccess();
      }
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const payload = {
    fileOperation: 20004,
    channelAcctJsonFileId: data ? `${data.productMappingNum}` : undefined,
    exportFile: {
      channelNum: account.ChannelNum,
      channelAccountNum: account.ChannelAccountNum,
      mappingType,
      reason: "",
      isForAllSku: true,
      isFileOperation: false,
      operationSetNum: 0,
      TemplateName: templateName,
    },
    exportQuery: getSearchOptions?.call(null) || {},
    exportAs,
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(payload);
      }}
    >
      Export Search Result
      <CloudDownloadOutlined />
    </Button>
  );
};

const ExportButton: React.FC<{
  data?: ExportItemType;
  account: ChannelAccount;
  mappingType: number;
  disabled: boolean;
  templateName: string;
  onCallback?: (data: any) => void;
  exportAs?: string;
}> = ({
  data,
  account,
  mappingType,
  onCallback,
  disabled,
  templateName,
  exportAs,
}) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [
    queueFileExport,
    { data: operationResponse },
  ] = useExportChannelAccountFileMutation();

  const handleSuccess = useCallback(() => {
    onCallback && onCallback(data);
  }, [data, onCallback]);

  useEffect(() => {
    if (operationResponse && exportLoading) {
      setExportLoading(false);
      if (typeof operationResponse === "object") {
        handleSuccess();
      }
    }
  }, [handleSuccess, exportLoading, operationResponse]);

  const body = {
    fileOperation: 20004,
    channelAcctJsonFileId: data ? `${data.productMappingNum}` : undefined,
    channelNum: account.ChannelNum,
    channelAccountNum: account.ChannelAccountNum,
    mappingType,
    reason: "",
    isForAllSku: true,
    isFileOperation: false,
    operationSetNum: 0,
    TemplateName: templateName,
    exportAs,
  };

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        queueFileExport(body);
      }}
    >
      Export All
      <CloudDownloadOutlined />
    </Button>
  );
};

// TODO: export api
const ExportDialog: React.FC<{
  account: ChannelAccount;
  allData: any[];
  columns: any[];
  selectedRows: any[];
  fileName: string;
  filterArr: string[];
  onClose: (data?: ExportItemType) => void;
  getSearchOptions: any; // get search options fn
  templateName: string;
  formatColumns?: any;
}> = (props) => {
  const {
    columns,
    allData,
    selectedRows,
    account,
    onClose,
    fileName,
    filterArr,
    getSearchOptions,
    templateName,
    formatColumns,
  } = props;

  const getPayloadInfo = useCallback(async (): Promise<{
    mappedProductTemplates: ExportItemType[];
    mappedInventoryTemplates: ExportItemType[];
  }> => {
    if (!account || !account.ChannelAccountNum) {
      return { mappedProductTemplates: [], mappedInventoryTemplates: [] };
    }
    const res = await getChannelExportViewInfo(account.ChannelAccountNum);
    return res
      ? res
      : { mappedProductTemplates: [], mappedInventoryTemplates: [] };
  }, [account]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, loading] = useExecutePromise(getPayloadInfo, {
    mappedProductTemplates: [],
    mappedInventoryTemplates: [],
  });
  const loadingData = useMemo(() => loading, [loading]);
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  // const [selectedRadio, setSelectedRadio] = useState<any>();
  const [exportCsv, setExportCsv] = useState(false);
  const history = useHistory();

  const handleDownload = useCallback(
    (downloadData: Array<any>, filterArr: string[], fileName: string) => {
      const headers = columns
        .filter((item) => {
          if (filterArr.indexOf(item.header) > -1) {
            if (
              (item.header === "SKU" && item.name === "_sku_") ||
              (item.header === "Image" && item.name === "_mediaURL_") ||
              (item.header === "Style" && item.name === "_styleCode_") ||
              (item.header === "Substyle" &&
                item.name === "_subStyleCode_") ||
              (item.header === "Feed Inventory" &&
                item.name === "_sendInventory_") ||
              (item.header === "Sync Catalog" &&
                item.name === "_sendContent_") ||
              (item.header === "Status" && item.name === "_status_")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        })
        .map(
          (i) =>
            i.header.replace("\n", "").replace("\r", "") ||
            i.title.replace("\n", "").replace("\r", "")
        );
      const lines = [] as any;
      lines.push(
        headers
          .map((headerItem) => {
            let formattedHeader = headerItem;
            if (typeof headerItem === "string") {
              if (headerItem.includes(",")) {
                if (headerItem.includes('"')) {
                  formattedHeader = headerItem.replace(/"/g, '""');
                }
                formattedHeader = `"${headerItem}"`;
              }
            } else {
              formattedHeader = "";
            }
            return formattedHeader;
          })
          .join(",")
      );
      downloadData.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          value = (formatColumns && formatColumns[dataIndex]) ? formatColumns[dataIndex][`${row[dataIndex]}`] : row[dataIndex];
          if (dataIndex === "_styleCode_")
            value = row._styleCode_ || row.VariationParentSKU;
          if (dataIndex === "ProductId") value = row.colorPatternCode || "";
          if (typeof value === "string") {
            // replace \n with \\n,
            value = value.replaceAll("\n", "\\n");
            if (value.includes(",")) {
              if (value.includes('"')) {
                value = value.replaceAll(/"/g, '""');
              }
              value = `"${value}"`;
            }
          } else if (typeof value === "number") {
            value = `"${value}"`;
          } else {
            value = "";
          }
          items.push(value);
        });
        lines.push(items.join(","));
      });
      const csvString = lines.join("\n");
      const fileNameString = `${fileName}_${moment().format("YYYYMMDD")}`;
      if (!exportCsv) {
        // export as xlsx
        downloadXlsxFromCsv(csvString, fileNameString);
        return;
      }
      // return;
      const blob = new Blob([csvString], { type: "application/csv" });
      const name = `${fileNameString}.csv`;
      if ("download" in document.createElement("a")) {
        // No IE Download
        const elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // free url
        document.body.removeChild(elink);
      } else {
        // IE10+ download
        //navigator.msSaveBlob(blob, name);
      }
    },
    [selectedColumns, columns, exportCsv, formatColumns]
  );

  useEffect(() => {
    const checkedList: Array<string> = [];
    columns
      .filter((item) => {
        if (filterArr.indexOf(item.header) > -1) {
          if (
            (item.header === "SKU" && item.name === "_sku_") ||
            (item.header === "Image" && item.name === "_mediaURL_") ||
            (item.header === "Style" && item.name === "_styleCode_") ||
            (item.header === "Substyle" &&
              item.name === "_subStyleCode_") ||
            (item.header === "Feed Inventory" &&
              item.name === "_sendInventory_") ||
            (item.header === "Sync Catalog" && item.name === "_sendContent_") ||
            (item.header === "Status" && item.name === "_status_")
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .map((i) =>
        checkedList.push(
          (i.dataIndex || "").replace("\n", "").replace("\r", "") ||
            (i.name || "").replace("\n", "").replace("\r", "")
        )
      );
    // columns.forEach((item: any) => {
    //   if (
    //     filterArr.indexOf(
    //       (item.header || "").replace("\n", "").replace("\r", "") ||
    //         (item.title || "").replace("\n", "").replace("\r", "")
    //     ) < 0
    //   ) {
    //     checkedList.push(item.dataIndex || item.name);
    //   }
    // });
    setSelectedColumns(checkedList);
  }, [columns, filterArr]);

  return (
    <Modal
      visible
      width={900}
      title={
        <Row justify="center">
          <Title level={4}>Channel List Export</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
      closable={false}
    >
      <Spin spinning={loadingData}>
        <CardSpace direction="vertical">
          <Row>
            <Col style={{ marginRight: "40px" }}>
              <Text strong>Channel Account:</Text>
              <Text style={{marginLeft: 6}}>{account.ChannelAccountName}</Text>
            </Col>
            <Col>
              {/* <Text strong>{`Template Name: ${templateName}`}</Text> */}
              <Text strong>Template Name:</Text>
              <Text style={{marginLeft: 6}}>{templateName}</Text>
            </Col>
          </Row>
          {/* <Tabs type="editable-card" hideAdd>
            <TabPane tab="For Bulk Edit" key="Attributes" closable={false}> */}
          <Col style={{ padding: "5px" }}>
            <Space>
              <Text strong>Export As</Text>
              <Switch
              checked={exportCsv}
              onChange={setExportCsv}
              checkedChildren="CSV"
              unCheckedChildren="XLSX"
              style={{
                backgroundColor: "#006dff",
              }}
            />
            </Space>
          </Col>
          <Card
            size="small"
            bodyStyle={{
              alignItems: "center",
              display: "flex",
              justifyContent: "start",
              gap: 15,
            }}
          >
            <Button
              type="primary"
              disabled={selectedRows.length === 0}
              onClick={() => handleDownload(selectedRows, filterArr, fileName)}
            >
              Export Selected
            </Button>
            <Button
              type="primary"
              disabled={allData.length === 0}
              onClick={() => handleDownload(allData, filterArr, fileName)}
            >
              Export Current Page
            </Button>
            <ExportSearchButton
              disabled={false}
              account={account}
              mappingType={32}
              getSearchOptions={getSearchOptions}
              templateName={templateName}
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
            <ExportButton
              disabled={false}
              account={account}
              mappingType={32}
              templateName={templateName}
              onCallback={() =>
                Modal.confirm({
                  icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
                  content: `Data is scheduled to export. It may take some time.`,
                  okText: "Close",
                  okType: "primary",
                  cancelText: "Check Out Export Result",
                  onCancel: () => {
                    history.push("/file-export");
                  },
                })
              }
              exportAs={exportCsv ? "csv" : "xlsx"}
            />
          </Card>
          {/* </TabPane> */}
          {/* comment because of ticket FE-1080 */}
          {/* <TabPane tab="For Channel Upload" key="outputFile" closable={false}>
              <Card
                size="small"
                bodyStyle={{ maxHeight: 300, overflowY: 'auto', marginBottom: 12 }}
              >
                {data.mappedProductTemplates && data.mappedProductTemplates.length > 0 && (
                  <Card size="small">
                    <Row>
                      <Text strong>Product</Text>
                    </Row>
                    <Space direction="vertical">
                      {data.mappedProductTemplates.map((item) => (
                        <ItemRow key={item.productMappingNum}>
                          {item.isMapped ? (
                            <Radio
                              checked={selectedRadio?.productMappingNum === item.productMappingNum}
                              onClick={() => setSelectedRadio({ ...item, type: 1 })}
                            >
                              {item.productMappingName}
                            </Radio>
                          ) : (
                            <ItemText>{item.productMappingName}</ItemText>
                          )}
                          {!item.isMapped && (
                            <Link
                              to={`/integrations/${account.ChannelNum}-${account.PlatformNum}/mapping/products/${account.ChannelAccountNum}?productMappingNum=${item.productMappingNum}`}
                            >
                              <Button type="default">Configure mapping to enable export</Button>
                            </Link>
                          )}
                        </ItemRow>
                      ))}
                    </Space>
                  </Card>
                )}
                {data.mappedInventoryTemplates && data.mappedInventoryTemplates.length > 0 && (
                  <Card size="small" style={{ marginTop: 4 }}>
                    <Row>
                      <Text strong>Inventory</Text>
                    </Row>
                    <Space direction="vertical">
                      {data.mappedInventoryTemplates.map((item) => (
                        <ItemRow key={item.productMappingNum}>
                          {item.isMapped ? (
                            <Radio
                              checked={selectedRadio?.productMappingNum === item.productMappingNum}
                              onClick={() => setSelectedRadio({ ...item, type: 2 })}
                            >
                              {item.productMappingName}
                            </Radio>
                          ) : (
                            <ItemText>{item.productMappingName}</ItemText>
                          )}
                          {!item.isMapped && (
                            <Link
                              to={`/integrations/${account.ChannelNum}-${account.PlatformNum}/mapping/products/${account.ChannelAccountNum}?productMappingNum=${item.productMappingNum}`}
                            >
                              <Button type="default">Configure mapping to enable export</Button>
                            </Link>
                          )}
                        </ItemRow>
                      ))}
                    </Space>
                  </Card>
                )}
              </Card>
              <Text strong>Choose one option above, and click corresponding button.</Text>
              <Row justify="space-around" style={{ marginTop: 9 }}>
              <ExportSelectedButton
                  disabled={!selectedRadio || selectedRows.length===0}
                  selectedRows={selectedRows}
                  account={account}
                  data={selectedRadio}
                  mappingType={selectedRadio?.type || 0}
                  onCallback={() =>
                    Modal.confirm({
                      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                      content: `Data is scheduled to export. It may take some time.`,
                      okText: 'Close',
                      okType: 'primary',
                      cancelText: 'Check Out Export Result',
                      onCancel: () => {
                        history.push('/file-export');
                      },
                    })
                  }
                />

              <ExportCurrentButton
                  disabled={!selectedRadio}
                  account={account}
                  data={selectedRadio}
                  rows={allData}
                  mappingType={selectedRadio?.type || 0}
                  onCallback={() =>
                    Modal.confirm({
                      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                      content: `Data is scheduled to export. It may take some time.`,
                      okText: 'Close',
                      okType: 'primary',
                      cancelText: 'Check Out Export Result',
                      onCancel: () => {
                        history.push('/file-export');
                      },
                    })
                  }
                />
                {
                  isDevEnv() && (
                    <>
                      <Button type="primary" disabled={!selectedRadio} onClick={() => {}}>
                        Export Search Result
                      </Button>
      
                      <ExportButton
                        account={account}
                        data={selectedRadio}
                        mappingType={selectedRadio?.type || 0}
                        onCallback={() =>
                          Modal.confirm({
                            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                            content: `Data is scheduled to export. It may take some time.`,
                            okText: 'Close',
                            okType: 'primary',
                            cancelText: 'Check Out Export Result',
                            onCancel: () => {
                              history.push('/file-export');
                            },
                          })
                        }
                      />
                    </>
                  )
                }
              </Row>
            </TabPane> */}
          {/* </Tabs> */}

          {/* <Link to="/settings-templates">
            <Button>Add New Template</Button>
          </Link> */}

          <Row justify="end">
            <Button type="primary" onClick={() => onClose()}>
              Close
            </Button>
          </Row>
        </CardSpace>
      </Spin>
    </Modal>
  );
};

export default ExportDialog;
