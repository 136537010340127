import React from 'react';
import { Button, Row, Space, Steps, notification, Modal, Col, message } from 'antd';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION, LOADING_ICON_SIZE1 } from '../../../constants/config';
import Products from '../../../services/products';
import { CopywritingStepWrapper } from '../styledComponents';
import AttributeSelector from './AttributeSelector';
import SourceSelector from './SourceSelector';
import ReviewSelector from './Review';
import FilishSelector from './Finish';
import {
  fetchPullProductDestinationAttributes,
  saveCopywritingElementsPull,
} from '../../../services/copywriting';
import styled from 'styled-components';

type Props = {
  onCancel?: Function;
  productId: string;
};
const ViewCol = styled(Col)`
  & .title-bar {
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
  }

  & .value-area {
    border: 1px solid #d9d9d9;
    padding: 12px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
`;

const CopywritingSteps = (props: Props) => {
  const { useState } = React;
  const { Step } = Steps;
  const { productId } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productBasicInfo, setProductBasicInfo] = useState<StringKAnyVPair>({});
  const [canNext, setCanNext] = useState(false);
  const [attributeList, setAttributeList] = useState<StringKAnyVPair[]>([]);
  const [selectedAtrribute, setSelectedAtrribute] = useState<any[]>([]);
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [selectedSource, setSelectedSource] = useState<string>();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [viewDialogVisible, setViewDialogVisible] = useState(false);
  const [viewDestination, setViewDestination] = useState<StringKAnyVPair>({});

  const closeViewDialog = () => {
    setViewDialogVisible(false);
    setViewDestination({});
  };

  const openViewDialog = (dest: StringKAnyVPair) => {
    setViewDialogVisible(true);
    setViewDestination(dest);
  };

  const viewDialogWidth = () => {
    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchPullProductDestinationAttributes(props.productId);
      if (res && Array.isArray(res)) {
        setAttributeList(res);
        setSourceList(
          Array.from(
            new Set(
              res.map((i: any) => {
                return i.groupName;
              }),
            ),
          ),
        );
      }

      const { productBasic, productType } = await Products.getStyleMaster2(productId);

      if (productBasic && typeof productBasic === 'object') {
        setProductBasicInfo({ ...productBasic, productType });
      } else {
        // eslint-disable-next-line
        throw 'No product info found';
      }
    } catch (e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch product info error: ${e}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = async (isSubmit?: boolean) => {
    if (isSubmit) {
      try {
        setIsSubmiting(true);
        const params = selectedAtrribute.map((i: any) => {
          return {
            CopywritingElementNum: i.copywritingElementNum,
            CopywritingValue: i.existingAttributeValue,
          };
        });
        const group =
          productBasicInfo.productType === 2
            ? 'stylecode'
            : productBasicInfo.productType === 3
            ? 'ColorPatternCode'
            : '';
        const res = await saveCopywritingElementsPull(
          productId,
          params,
          productBasicInfo.sku,
          group,
        );
        setIsSubmiting(false);
        if (res.isSuccess) {
          setCurrentStep(currentStep + 1);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        message.error('Server error')
        setIsSubmiting(false);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onCancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData]);

  return (
    <>
      <CopywritingStepWrapper>
        <Row align="middle" className="title-bar">
          <span className="title-item-label">Style</span>
          &nbsp;
          <span className="title-item">{productBasicInfo.styleCode || ''}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {/* <span className="title-item-label">Sub-Style</span>
          &nbsp;
          <span className="title-item">item sub style</span>
          &nbsp;&nbsp;&nbsp;&nbsp; */}
          <span className="title-item-label">SKU</span>
          &nbsp;
          <span className="title-item">{productBasicInfo.sku || ''}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title-item-label">Title</span>
          &nbsp;
          <span className="title-item">{productBasicInfo.productTitle || ''}</span>
          &nbsp;&nbsp;
        </Row>
        <div className="step-ctn">
          <div className="step-wrapper">
            <div className="step-sider">
              <Steps current={currentStep} direction="vertical">
                <Step title="Select Source" />
                <Step title="Select Attributes" />
                <Step title="Review" />
                <Step title="Finish" />
              </Steps>
            </div>
            <div className="step-board">
              {currentStep === 0 && (
                <SourceSelector
                  maskScreen={setIsLoading}
                  setCanNext={setCanNext}
                  sourceList={sourceList}
                  onSelectedChange={setSelectedSource}
                  defaultSelected={selectedSource}
                />
              )}
              {currentStep === 1 && (
                <AttributeSelector
                  selectedSourceName={selectedSource || ''}
                  sourceList={attributeList.filter((i) => i.groupName === selectedSource)}
                  maskScreen={setIsLoading}
                  setCanNext={setCanNext}
                  onSelected={(selectedRow: any[]) => setSelectedAtrribute([...selectedRow])}
                  openViewDialog={openViewDialog}
                />
              )}
              {currentStep === 2 && (
                <ReviewSelector
                  selectedSourceName={selectedSource || ''}
                  maskScreen={setIsLoading}
                  selectedAttribute={selectedAtrribute}
                  openViewDialog={openViewDialog}
                />
              )}
              {currentStep === 3 && (
                <FilishSelector
                  selectedSourceName={selectedSource || ''}
                  maskScreen={setIsLoading}
                  resultList={selectedAtrribute}
                />
              )}
            </div>
          </div>
        </div>
        <Row align="middle" className="left-bottom-btn-block" justify="space-between">
          <Space>
            <Button
              onClick={previousStep}
              style={{ visibility: currentStep > 0 && currentStep < 3 ? 'unset' : 'hidden' }}
            >
              Back
            </Button>
            {currentStep < 2 && (
              <Button disabled={!canNext} onClick={() => nextStep()} type="primary">
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button onClick={() => nextStep(true)} loading={isSubmiting} type="primary">
                Submit
              </Button>
            )}
            {currentStep === 3 && (
              <Button onClick={onCancel} type="primary">
                Close
              </Button>
            )}
          </Space>
          <Space>{currentStep < 3 && <Button onClick={onCancel}>Cancel</Button>}</Space>
        </Row>
        {/* <Row align="middle" justify={currentStep === 0 ? 'end' : 'space-between'}>
          {currentStep > 0 && <Button onClick={previousStep}>Back</Button>}
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            {currentStep < 2 && (
              <Button onClick={()=>nextStep()} disabled={!canNext} type="primary">
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button onClick={()=>nextStep(true)} loading={isSubmiting} type="primary">
                Submit
              </Button>
            )}
          </Space>
        </Row> */}
      </CopywritingStepWrapper>
      {viewDialogVisible && (
        <Modal
          centered
          footer={
            <Row justify="end">
              <Button type="primary" onClick={closeViewDialog}>
                Close
              </Button>
            </Row>
          }
          onCancel={closeViewDialog}
          title={`View ${viewDestination.attributeName}`}
          visible={viewDialogVisible}
          width={viewDialogWidth()}
        >
          <Row
            gutter={16}
            style={{
              height: 300,
            }}
          >
            <ViewCol span={12} style={{ height: '100%' }}>
              <div className="title-bar">Attribute Value</div>
              <div className="value-area">{viewDestination.existingAttributeValue}</div>
            </ViewCol>
            <ViewCol span={12} style={{ height: '100%' }}>
              <div className="title-bar">Content Resources Value</div>
              <div className="value-area">{viewDestination.copywritingValue}</div>
            </ViewCol>
          </Row>
        </Modal>
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default CopywritingSteps;
