import { Col, DatePicker, Form, Row, Select, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CSVLink, { CSVColumns } from '../components/common/CSVLink';
import FilterModal from '../components/common/FilterModal';
import Heading from '../components/common/Heading';
import SearchBar, { SearchFields } from '../components/common/SearchBar';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { useListChannelsQuery } from '../redux/api/channels';
import { ListShipmentsParams, useListShipmentsQuery } from '../redux/api/shipments';
import { OrderStatus } from '../types/enums';
import { formatDate } from '../util';
import { enumToObjects } from '../util/enums';
import { useFilterPagination } from '../util/hooks';
import { enumNameToLabel } from '../util/strings';


const searchFields: SearchFields<Entities.SimpleShipment> = [
  'shipmentNum',
  'digitbridgeOrderId',
  'channelName',
  {
    fieldName: 'originalOrderDateUtc',
    attributes: {
      extractor: d => formatDate(d.originalOrderDateUtc, 'dateTime'),
    },
  },
  {
    fieldName: 'shippedDateUtc',
    attributes: {
      extractor: d => formatDate(d.shippedDateUtc, 'dateTime'),
    },
  },
  'shipToName',
  'endBuyerEmail',
  'trackingNumber',
  'orderQty',
  {
    fieldName: 'orderStatus',
    attributes: {
      extractor: d => enumNameToLabel(OrderStatus[d.orderStatus]),
    },
  },
];

const csvColumns: CSVColumns<Entities.SimpleShipment> = [
  'shipmentNum',
  { key: 'digitbridgeOrderId', header: 'Order #' },
  { key: 'channelName', header: 'Channel' },
  {
    key: 'originalOrderDateUtc',
    header: 'Date',
    transform: t => formatDate(t, 'dateTime'),
  },
  {
    key: 'shippedDateUtc',
    header: 'Ship Date',
    transform: t => formatDate(t, 'dateTime'),
  },
  { key: 'shipToName', header: 'Ship To' },
  { key: 'endBuyerEmail', header: 'Customer Email' },
  {
    key: 'trackingNumber',
    header: 'Tracking Number',
  },
  {
    key: 'orderQty',
    header: 'Total Items',
  },
  {
    key: 'orderStatus',
    header: 'Status',
    transform: v => enumNameToLabel(OrderStatus[v.orderStatus]),
  },
];

const initialData: { count: number, shipments: Entities.SimpleShipment[] } = { count: 0, shipments: [] };

const OrderLink: React.FC<{ order: Entities.SimpleShipment, view?: string }> = (
  {
    order,
    view = 'details',
    children,
  },
) => (
  <Link to={`/order-details/${order.digitbridgeOrderId}?activeView=${view}`}>
    {children}
  </Link>
);

const columns: ColumnsType<Entities.SimpleShipment> = [
  {
    title: 'Shipment #',
    dataIndex: 'shipmentNum',
    render(value: number, record: Entities.SimpleShipment) {
      const text = value === 0 ? 'N/A' : value.toString();

      return (
        <OrderLink order={record} view="shipping">
          {text}
        </OrderLink>
      );
    },
  },
  {
    title: 'Order #',
    dataIndex: 'digitbridgeOrderId',
    render(value: string, record: Entities.SimpleShipment) {
      return (
        <OrderLink order={record}>
          {value}
        </OrderLink>
      );
    },
  },
  {
    title: 'Channel',
    dataIndex: 'channelName',
  },
  {
    title: 'Date',
    dataIndex: 'originalOrderDateUtc',
    ellipsis: {
      showTitle: false,
    },
    render(value: string) {
      const formattedValue = formatDate(value, 'dateTime');

      return (
        <Tooltip placement="topLeft" title={formattedValue}>
          {formattedValue}
        </Tooltip>
      );
    },
  },
  {
    title: 'Ship Date',
    dataIndex: 'shippedDateUtc',
    ellipsis: {
      showTitle: false,
    },
    render(value: string) {
      const formattedValue = formatDate(value, 'dateTime');

      return (
        <Tooltip placement="topLeft" title={formattedValue}>
          {formattedValue}
        </Tooltip>
      );
    },
  },
  {
    title: 'Ship To',
    dataIndex: 'shipToName',
    ellipsis: {
      showTitle: false,
    },
    render(value: string) {
      return (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      );
    },
  },
  {
    title: 'Customer Email',
    dataIndex: 'endBuyerEmail',
    ellipsis: {
      showTitle: false,
    },
    render(value: string) {
      return (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      );
    },
  },
  {
    title: 'Tracking Number',
    dataIndex: 'trackingNumber',
  },
  {
    title: 'Total Items',
    dataIndex: 'orderQty',
  },
  {
    title: 'Status',
    dataIndex: 'orderStatus',
    render(value: number) {
      return enumNameToLabel(OrderStatus[value]);
    },
  },
];

interface FilterParams extends ListShipmentsParams {
  orderDate?: Moment[];
  shipDate?: Moment[];
  mustShipDate?: Moment[];
}


const Filters: React.FC<{ onFilter: (params: ListShipmentsParams) => void }> = ({ onFilter }) => {
  const { data = [], isFetching } = useListChannelsQuery();
  const onFiltersChanged = useCallback((filterParams: FilterParams) => {
    const filters = { ...filterParams };

    if (filterParams.orderDate) {
      filters.orderDateFrom = filterParams.orderDate?.[0]?.format('YYYY-MM-DD');
      filters.orderDateTo = filterParams.orderDate?.[0]?.format('YYYY-MM-DD');
      delete filters.orderDate;
    }

    if (filterParams.shipDate) {
      filters.shipDateFrom = filterParams.shipDate?.[0]?.format('YYYY-MM-DD');
      filters.shipDateTo = filterParams.shipDate?.[1]?.format('YYYY-MM-DD');
    }

    if (filterParams.mustShipDate) {
      filters.mustShipDateFrom = filterParams.mustShipDate?.[0]?.format('YYYY-MM-DD');
      filters.mustShipDateTo = filterParams.mustShipDate?.[1]?.format('YYYY-MM-DD');
    }

    onFilter(filters);
  }, [onFilter]);

  return (
    <FilterModal<FilterParams> onFilter={onFiltersChanged}>
      <Form.Item name="channelNum" label="Channel">
        <Select loading={isFetching} allowClear>
          {data.map((c: Entities.ChannelProfile) => (
            <Select.Option key={c.channelNum} value={c.channelNum}>
              {c.channelName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="orderDate" label="Order Date">
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item name="shipDate" label="Ship Date">
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item name="mustShipDate" label="Must Ship Date">
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item name="shippingStatus" label="Status">
        <Select mode="tags" allowClear>
          {enumToObjects({ choices: OrderStatus }).map(choice => (
            <Select.Option key={choice.key} value={choice.value.toString()}>
              {choice.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="trackingNumber" label="Tracking number(s)">
        <Select mode="tags" allowClear open={false} />
      </Form.Item>
      <Form.Item name="sku" label="SKU(s)">
        <Select mode="tags" allowClear open={false} />
      </Form.Item>
    </FilterModal>
  );
};

const Shipments: React.FC = () => {
  const [
    pagination,
    filters,
    updatePagination,
    updateFilters,
    updateTotalCount,
  ] = useFilterPagination<ListShipmentsParams>({
    defaultPagination: {
      pageSize: 50,
    },
  });
  const { data = initialData, isFetching } = useListShipmentsQuery(filters);
  const { shipments, count } = data;
  const [filteredData, setFilteredData] = useState<Entities.SimpleShipment[]>([]);


  useEffect(() => {
    updateTotalCount(count);
  }, [count, updateTotalCount]);

  return (
    <ContentLayout>
      <Heading
        title="Shipments"
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row gutter={12} justify="space-between">
          <Space>
            <SearchBar<Entities.SimpleShipment>
              reference="digitbridgeOrderId"
              data={shipments}
              onResult={setFilteredData}
              fields={searchFields}
              disabled={isFetching}
            />
            <Filters onFilter={updateFilters} />
          </Space>
          <Col>
            <CSVLink
              data={filteredData}
              columns={csvColumns}
              filename="shipments.csv"
              disabled={isFetching}
            />
          </Col>
        </Row>
        <Spacer />
        <Table
          rowKey="digitbridgeOrderId"
          columns={columns}
          dataSource={filteredData}
          loading={isFetching}
          pagination={pagination}
          onChange={updatePagination}
          scroll={{ y: '60vh', x: '100vw' }}
          size="small"
          bordered
        />
      </SiteContent>
    </ContentLayout>
  );
};

export default Shipments;
