import React from 'react';
import { Row } from 'antd';
import LoadingIcon from '../../components/common/LoadingIcon';
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import { fetchSimpleProductGroup } from '../../services/products';
// import { columns } from './';

type Props = {
  row: StringKAnyVPair;
  columns: any[]
};

const GroupDetail = (props: Props) => {
  const { useState } = React;
  const [isLoading, setIsLoading] = useState(false);
  const [inited, setInited] = useState(false);
  const [products, setProducts] = useState<StringKAnyVPair[]>([]);
  
  const loadGroupData = async (productId: string) => {
    setIsLoading(true);

    try {
      const { ProductList } = await fetchSimpleProductGroup(productId);
      
      if (Array.isArray(ProductList)) {
        console.log('-->', ProductList);
        setProducts(ProductList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      const { ProductId } = props.row;

      setInited(true);
      loadGroupData(ProductId);
    }
  }, [inited, props]);

  return (<>
    {isLoading && (
      <Row align="middle" justify="center" style={{height: '100%'}}>
        <LoadingIcon />
      </Row>
    )}
    {!isLoading && products.length > 0 && (
        <DataGrid
          idProperty="ProductId"
          rowHeight={35}
          columns={props.columns}
          dataSource={products}
          pagination={false}
          showHeader={false}
        />
    )}
  </>);
};

export default GroupDetail;
