export enum ProfileSettingsAction {
  SET_PROFILESETTINGS = 'profileSettings/setProfileSettings',
}

interface SetProfileSettingsAction {
  type: typeof ProfileSettingsAction.SET_PROFILESETTINGS;
  profileSettings: Entities.ProfileSetting[];
}

export type ProfileSettingsActionType = SetProfileSettingsAction;
