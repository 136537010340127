import React from 'react';
import {
  Col,
  Input,
  Row,
  Switch,
  //Tooltip,
  //Typography,
  notification,
} from 'antd';

import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
import {
  fetchControlTowerCfgHideField,
  setControlTowerCfgHideField,
} from '../../services/controlTower';
import { Action, StoreContext } from './model';
import { TowerListContainer } from './styles';
import { getTowerItemWidth } from '.';

type Props = {
};

//const { Paragraph } = Typography;

const TowerList = (props: Props) => {
  const ID_PREFIX = 'k'
  const { useContext, useState } = React;
  const { state, dispatch } = useContext(StoreContext) as any;
  const [inited, setInited] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const wrapperRef = React.useRef<any>(null);

  const getTowerGroupClass = (t: StringKAnyVPair) => {
    const cls = ['grp-titlebar'];
    const item = state.currentItem;

    if (item.key && item.key === t.key) {
      cls.push('selected');
    }

    return cls.join(' ');
  };

  const getTowerItemClass = (t: StringKAnyVPair) => {
    const cls = ['tower-item'];
    const item = state.currentItem;

    if (item.key && item.key === t.key) {
      cls.push('selected');
    }

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const loadHideFieldSetting = async () => {
    //dispatch({type: Action.SetIsLoading, payload: true});
    try {
      const hfCfg = await fetchControlTowerCfgHideField();

      //console.log('hf cfg', hfCfg);
      dispatch({type: Action.SetHideField, payload: hfCfg});
    } catch(e) {
      notification.error({
        message: `Fetch hide fields setting error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }
  };

  const onClickGroup = (evt: any, g: StringKAnyVPair) => {
    let keys = [ ...state.expandedKeys ];

    if (g.key && keys.indexOf(g.key) < 0) {
      keys.push(g.key);
      dispatch({type: Action.SetExpandedKeys, payload: keys});
    }

    setSelectedId(g.key);
    dispatch({type: Action.SetCurrentItem, payload: g});
    evt.stopPropagation();
  };

  const onClickTowerItem = (evt: any, item: StringKAnyVPair) => {
    let keys = [ ...state.expandedKeys ];

    if (item.parentKey && keys.indexOf(item.parentKey) < 0) {
      keys.push(item.parentKey);
      dispatch({type: Action.SetExpandedKeys, payload: keys});
    }

    setSelectedId(item.key);
    dispatch({type: Action.SetCurrentItem, payload: item});
    evt.stopPropagation();
  };

  const onHideFieldChange = async (checked: boolean) => {
    dispatch({type: Action.SetIsLoading, payload: true});

    try {
      const res = await setControlTowerCfgHideField(checked ? 1 : 0);

      if (res) dispatch({type: Action.SetHideField, payload: checked ? 1 : 0});
    } catch(e) {
      notification.error({
        message: `Set hide fields error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      dispatch({type: Action.SetIsLoading, payload: false});
    }
  };

  const renderGroupItems = (grp: StringKAnyVPair) => {
    const items: StringKAnyVPair[] = grp.children;

    return items.map(e => {
      const fw = Math.floor(100 / grp.columns);

      //console.log('fw', fw);
      return (
        <Col
          className={getTowerItemClass(e)}
          id={`${ID_PREFIX}${e.key}`}
          key={e.key}
          onClick={evt => onClickTowerItem(evt, e)}
          style={{width: `${fw}%`}}
        >
          {/*<Paragraph
            ellipsis={{
              rows: 1,
            }}
            style={{width: '30%'}}
          >
            <Tooltip title={e.title}>
              <label className="title">{e.title}</label>
            </Tooltip>
          </Paragraph>
          <Input.TextArea
            rows={e.rows}
            style={{width: '70%'}}
          />*/}
          <div style={{width: '100%'}}>
            <label className="title">{e.title}</label>
          </div>
          <Input.TextArea
            className={!e.editable ? 'form-item-disabled' : undefined}
            readOnly
            rows={e.rows}
            style={{width: `${getTowerItemWidth(e)}%`}}
          />
        </Col>
      );
    });
  };

  const renderGroups = (gs: StringKAnyVPair[]) => {
    return gs.map(g => {
      return (
        <Row
          className="tower-grp"
          id={`${ID_PREFIX}${g.key}`}
          key={g.key}
          onClick={evt => onClickGroup(evt, g)}
        >
          <div className={getTowerGroupClass(g)}>
            <span className="grp-title">{g.title}</span>
          </div>
          {Array.isArray(g.children) && renderGroupItems(g)}
        </Row>
      );
    });
  };

  // eslint-disable-next-line
  const scrollSelectedBlockById = (id: string) => {
    const ctn = wrapperRef.current;

    if (ctn) {
      const dom = ctn.querySelector(`#${ID_PREFIX}${id}`);

      if (dom) {
        //console.log('dom', dom);
        dom.scrollIntoView({ behavior: 'smooth', block: 'center', });
      }
    }
  };

  React.useEffect(() => {
    const { currentItem } = state;
    //console.log('st', state);
    if (currentItem) {
      if (currentItem.key !== selectedId) {
        if (currentItem.key) scrollSelectedBlockById(currentItem.key);

        setSelectedId(currentItem.key);
      }
    }

    if (!inited) {
      loadHideFieldSetting();
      setInited(true);
    }
  }, [
    inited,
    loadHideFieldSetting,
    selectedId,
    state,
    scrollSelectedBlockById,
  ]);

  return (<>
    <TowerListContainer ref={wrapperRef}>
      <div>
        <label className="title">Hide fields if no channel control flag</label>
        <Switch
          checked={state.hideField > 0 ? true : false}
          checkedChildren="Yes"
          onChange={onHideFieldChange}
          unCheckedChildren="No"
        />
      </div>
      {/*renderGroups(props.data)*/}
      {renderGroups(state.towers)}
    </TowerListContainer>
  </>);
};

export default TowerList;
