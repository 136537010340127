import { InboxOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Radio,
  Upload,
  Switch,
} from "antd";
import { RcFile } from "antd/lib/upload/interface";
import React, { useCallback, useEffect, useState } from "react";
import { useUploadMappingTemplateMutation } from "../../redux/api/templates";

const validExcelMimeTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface UploadModalProps extends Omit<ModalProps, "onOk"> {
  templateName?: string;
  channelAccountNum?: number;
  templateType: number;
  channelNum: number;
  title: string;
  onOk?: (shouldRefresh: boolean) => void;
}

interface UploadForm {
  templateName?: string;
  fileSelected: boolean;
}

const UploadModal: React.FC<UploadModalProps> = ({
  templateName,
  channelNum,
  templateType,
  title,
  channelAccountNum,
  onOk,
  ...modalProps
}) => {
  const [
    uploadMappingTemplate,
    { status: uploadTemplateStatus },
  ] = useUploadMappingTemplateMutation();
  const processing = uploadTemplateStatus === "pending";
  const [form] = Form.useForm<UploadForm>();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [tempType, setTemType] = useState<0 | 1>(0);
  const [saveOrigin, setSaveOrigin] = useState(false);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      form.setFieldsValue({ fileSelected: false });

      if (validExcelMimeTypes.indexOf(file.type) < 0) {
        message.error(`${file.name} is not a spreadsheet file`).then();
      } else {
        form.setFieldsValue({ fileSelected: true });
        setFileList([file]);
      }

      return false;
    },
    [form]
  );

  const { onCancel } = modalProps;

  const onSubmit = useCallback(
    async (values: UploadForm) => {
      if (fileList.length === 0) {
        return;
      }

      try {
        const data = new FormData();
        data.append("file", fileList[0]);

        const payload = {
          data,
          mappingType: templateType,
          channelNum,
          channelAccountNum,
          templateName: values.templateName,
          templateType: tempType,
          saveOriginalTemplate: saveOrigin ? 1 : 0,
        };
        const res = await uploadMappingTemplate(payload);
        const error = Object.prototype.hasOwnProperty.call(res, "error");
        if (error) {
          await message.error(
            "There was an error uploading the file, please try again later"
          );
          return;
        }
        setFileList([]);
        onOk?.(!error);
        await message.success("File uploaded successfully");
      } catch (e) {
        await message.error(
          "There was an error uploading the file, please try again later"
        );
      }
    },
    [
      onOk,
      fileList,
      uploadMappingTemplate,
      templateType,
      channelNum,
      channelAccountNum,
      saveOrigin,
      tempType,
    ]
  );

  const onModalCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      form.resetFields();
      setFileList([]);
      onCancel?.(e);
    },
    [onCancel, form]
  );

  useEffect(() => {
    form.setFieldsValue({ templateName });
  }, [form, templateName]);

  return (
    <Form form={form} onFinish={onSubmit}>
      <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
        visible
        title={title}
        okButtonProps={{
          loading: processing,
          htmlType: "submit",
          onClick: form.submit,
        }}
        width={700}
        okText='Submit'
        cancelButtonProps={{ disabled: processing }}
        onCancel={onModalCancel}
        maskClosable={!processing}
        closable={!processing}
        forceRender
      >
        <Form.Item label="">
          <Radio.Group
            value={tempType}
            onChange={(e) => setTemType(e.target.value)}
          >
            <Radio value={0}>Catalog</Radio>
            <Radio value={1}>Standard</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Keep the original template formatting consistent with the export file formatting">
          <Switch
            checked={saveOrigin}
            checkedChildren="Yes"
            onChange={(checked) => setSaveOrigin(checked)}
            unCheckedChildren="No"
          />
        </Form.Item>

        {templateType && [4, 5, 6].includes(templateType) && (
          <>
            <Form.Item
              label="Template Name"
              name="templateName"
              rules={[
                { required: !templateName, message: "This field is required" },
              ]}
            >
              <Input
                disabled={processing || !!templateName}
                readOnly={!!templateName}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="fileSelected"
          rules={[{ required: true, message: "Please add a file." }]}
        >
          <Upload.Dragger
            fileList={fileList}
            name="template"
            multiple={false}
            beforeUpload={beforeUpload}
            disabled={processing}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag a file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default UploadModal;
