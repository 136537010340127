import React from 'react';
import {
  Input,
  InputNumber,
  Select,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
//import EditIcon from '../../assets/images/edit-icon.png';
//import SaveIcon from '../../assets/images/save-icon.png';
import theme from '../../assets/styles/theme';
import LoadingIcon from '../../components/common/LoadingIcon';
import message from '../../components/common/message';
import {
  ATTRIBUTE_DATA_TYPE_DECIMAL,
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  ATTRIBUTE_DATA_TYPE_INTEGER,
  //ATTRIBUTE_DATA_TYPE_PAGEURL,
  ATTRIBUTE_DATA_TYPE_PRICE,
  ATTRIBUTE_DATA_TYPE_STRING,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  ATTRIBUTE_DATA_TYPE_IMAGEURLLIST,
} from '../../constants/config';
import { ProductAttributeDataType } from '../../types/enums';
import Products from '../../services/products';
import { isValidHttpUrl } from '../../util';
import TextEditor from './BasicAttrEditor/TextEditor';
import EditImageDialog from './EditImageDialog';
import EditImageListDialog from './ImageListTypeEditor'
import { loadClassifications, loadAttributeOptions } from './helper';

const EditorWrapper = styled.span`
  display: inline-block;

  &:hover .cell-value {
    border-radius: 0;
    width: calc(100% - 32px);
  }

  &:hover .cell-value.visual-ctn2 {
    width: calc(100% - 64px);
  }

  &:hover .cell-value.visual-ctn3 {
    width: calc(100% - 64px);
  }

  &:hover .cell-value.visual-ctn4 {
    width: calc(100% - 96px);
  }

  &:hover .icon-ctn-disabled {
    display: inline-block;
  }

  & .ant-btn {
    padding: 5px 8px;
  }

  & .ant-input {
    width: calc(100% - 64px);
  }

  & .ant-input-number,
    .ant-select {
    width: calc(100% - 32px);
  }

  & .cell-input-changed {
    border-color: ${theme['@danger-color']};
  }

  & .cell-input-changed:focus {
    box-shadow: 0 0 0 2px rgba(231, 95, 226, 0.2);
  }

  & .cell-value,
    .cell-value2,
    .icon-ctn-disabled,
    .icon-ctn-enabled {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .cell-value,
    .cell-value2 {
    padding: 4px 12px;
  }

  & .cell-value {
    width: 100%;
  }

  & .cell-value.visual-ctn3,
  & .cell-value.visual-ctn4 {
    width: calc(100% - 32px);
  }

  & .cell-value2 {
    width: calc(100% - 64px);
  }

  & .classification-icon,
  & .link-icon {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    font-size: 20px;
    height: 32px;
    padding: 0 4px;
    width: 32px;
  }

  & .link-icon {
    /*background-color: #C6F4FF;*/
    color: #111;
  }

  & .link-icon:hover {
    background-color: ${theme['@primary-color']};
    color: #FFF;
  }

  & .icon-ctn-disabled,
    .icon-ctn-enabled {
    border-radius: 0;
    cursor: pointer;
    /*padding: 2px 3px;*/
    padding: 5px 7px;
    width: 32px;
  }

  & .icon-ctn-disabled {
    display: none;
  }

  & .icon-ctn-enabled {
    background-color: #FFF;
  }

  & .icon-ctn-disabled img,
    .icon-ctn-enabled img {
    width: 24px;
    height: 24px;
  }

  & .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

type Props = {
  attr: StringKAnyVPair;
  className?: string;
  dispatch?: Function;
  getScrollWrapper?: Function;
  productId: string;
  refreshAttrs?: Function;
  state?: StringKAnyVPair;
  isDropship?: boolean;
};

let optionList: StringKAnyVPair[] = [];
let sysClassificationList: StringKAnyVPair[] = [];

const AttrEditor = (props: Props) => {
  const { useState } = React;
  const [classificationDict, setClassificationDict] = useState<StringKAnyVPair>({});
  const [currentImg, setCurrentImg] = useState<StringKAnyVPair>({});
  const [currentValue, setCurrentValue] = useState<any>(props.attr.productAttributeValue);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [isClassificationReady, setIsClassificationReady] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastValue, setLastValue] = useState<any>(props.attr.productAttributeValue);
  const [inited, setInited] = useState(false);
  const [textEditorVisible, setTextEditorVisible] = useState(false);
  const [editImageListDialogVisible, setEditImageListDialogVisible] = useState(false);
  const ctnRef = React.useRef<any>(null);

  const saveFuc = props.isDropship ? Products.saveProductDropshipAttributes : Products.saveProductAttributes;

  const attrEditableField = () => {
    const { attr } = props;
    const numTypes = [
      ATTRIBUTE_DATA_TYPE_DECIMAL,
      ATTRIBUTE_DATA_TYPE_INTEGER,
      ATTRIBUTE_DATA_TYPE_PRICE,
    ];
    const options = optionList.filter(e => e.attributeNum === props.attr.attributeNum);
    const editorStyle = attr.classificationNums.length > 0 ? { width: 'calc(100% - 64px)' } : {};

    if ((attr.classificationNums.length > 0 || isLinkable()) && isCopyable()) {
      editorStyle.width = 'calc(100% - 96px)';
    }

    if (options.length > 0) {
      return (
        <Select
          defaultValue={currentValue}
          disabled={!isEditable}
          onBlur={onInputBlur}
          onChange={onSelectChange}
          style={editorStyle}
        >
          {options.map(e => (
            <Select.Option key={e.optionNum} value={e.attributeOptionValue}>
              {e.attributeOptionValue}
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      const isNumType = numTypes.indexOf(attr.attributeDataType) > -1;

      if (isNumType) {
        return (
          <InputNumber
            className={inputFieldClass()}
            defaultValue={currentValue}
            disabled={!isEditable}
            onBlur={onInputBlur}
            onChange={onNumberInputChange}
            style={editorStyle}
          />
        );
      } else {
        return (
          <Input
            className={inputFieldClass()}
            defaultValue={currentValue}
            disabled={!isEditable}
            onBlur={onInputBlur}
            onDoubleClick={openTextEditor}
            onChange={onInputChange}
            style={editorStyle}
          />
        );
      }
    }
  };

  const attrReadonlyField = () => {
    return (
      <span
        className={getReadonlyVisualCtnClassName()}
        onDoubleClick={openTextEditor}
      >
        <Typography.Text ellipsis={{ tooltip: currentValue }}>
          {currentValue}
        </Typography.Text>
      </span>
    );
  };

  const attrOpeIcon = () => {
    return (<>
      {isCopyable() && <CopyToClipboard
        text={copyValue()}
        onCopy={() => notifyCopyMsg()}
      >
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          title="Copy value to clipboard"
        >
          <CopyOutlined />
        </span>
      </CopyToClipboard>}
      {isSaving && (
        <LoadingIcon />
      )}
      {!isSaving && (
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={enterEditMode}
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? '💾' : '🖊️'}
        </span>
      )}
    </>);
  };

  const classificationTooltip = () => {
    if (isClassificationReady) {
      const names = props.attr.classificationNums
        .map((num: string) => {
          const cl = classificationDict[num];

          return cl ? cl.ClassificationName : num;
        });

      //return props.attr.classificationNums.join(', ');
      return names.join(', ');
    } else {
      return <LoadingIcon color="white" />;
    }
  };

  const closeEditImageDialog = () => {
    setEditImageDialogVisible(false);
    setCurrentImg({});
    //setEditImageUrl('');
  };

  const closeEditImageListDialog = () => {
    setEditImageListDialogVisible(false);
    setCurrentImg({})
  }

  const closeTextEditor = () => {
    setTextEditorVisible(false);
  };

  const copyValue = () => {
    return currentValue === null || currentValue === undefined ? '' : `${currentValue}`.trim();
  };

  const enterEditMode = () => {
    if (isEditable) {
      if (currentValue === lastValue) {
        setIsEditable(false);
      }
    } else {
      setIsEditable(true);
    }
  };

  const getReadonlyVisualCtnClassName = () => {
    let cls = ['cell-value'];

    if (props.attr.classificationNums.length > 0) {
      if (isCopyable()) {
        cls.push('visual-ctn4');
      } else {
        cls.push('visual-ctn3');
      }
    } else if (isCopyable()) {
      cls.push('visual-ctn2');
    } else {
      cls.push('visual-ctn');
    }

    //if (props.attr.attributeDataType === ProductAttributeDataType.PAGEURL) {
    if (isLinkable()) {
      if (isCopyable()) {
        cls.push('visual-ctn4');
      } else {
        cls.push('visual-ctn3');
      }
    }

    return cls.join(' ');
  };

  const isCopyable = (typ = props.attr.attributeDataType) => {
    const options = optionList.filter(e => e.attributeNum === props.attr.attributeNum);
    const ts = [ATTRIBUTE_DATA_TYPE_IMAGEURL, ATTRIBUTE_DATA_TYPE_STRING, ProductAttributeDataType.PAGEURL];

    return ts.indexOf(typ) > -1 && options.length === 0;
  };

  const inputFieldClass = () => {
    const cls: string[] = [];

    if (currentValue !== lastValue) {
      cls.push('cell-input-changed');
    }

    return cls.join(' ');
  };

  const isLinkable = () => {
    return (props.attr.attributeDataType === ProductAttributeDataType.PAGEURL) || (typeof currentValue === 'string' && isValidHttpUrl(currentValue));
  };

  const isTextType = () => {
    const options = optionList.filter(e => e.attributeNum === props.attr.attributeNum);
    let ret = false;

    if (options.length === 0) {
      const numTypes = [
        ATTRIBUTE_DATA_TYPE_DECIMAL,
        ATTRIBUTE_DATA_TYPE_INTEGER,
        ATTRIBUTE_DATA_TYPE_PRICE,
      ];

      ret = numTypes.indexOf(props.attr.attributeDataType) < 0;
    }

    return ret;
  };

  const notifyCopyMsg = () => {
    copyValue() ?
      notification.success({ message: 'Attribute value has been copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION }) :
      notification.info({ message: 'No value can be copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION })
  };

  const onInputBlur = () => {
    if (currentValue !== lastValue) {
      saveAttribute();
    }
  };

  const onInputChange = (evt: any) => {
    // console.log('evt->', evt);
    setCurrentValue(evt.target.value);
  };

  const onNumberInputChange = (value: any) => {
    // console.log('evt->', evt);
    setCurrentValue(value);
  };

  const onSelectChange = (val: any) => {
    console.log('val->', val);
    setCurrentValue(val);
  };

  const onTooltipVisibleChange = async (visible: boolean) => {
    if (visible) {
      if (!isClassificationReady) {
        const cls = await loadClassifications();

        if (Array.isArray(cls)) {
          sysClassificationList = cls;
        }

        setIsClassificationReady(true);
      }

      if (Object.keys(classificationDict).length === 0) {
        sysClassificationList.forEach(e => {
          classificationDict[e.ClassificationNum] = e;
        });
        setClassificationDict({ ...classificationDict });
      }
    }
  };

  const openEditImageDialog = (image: StringKAnyVPair) => {
    setCurrentImg(image);
    setEditImageDialogVisible(true);
  };

  const openEditImageListDialog = (image: StringKAnyVPair) => {
    setCurrentImg(image);
    setEditImageListDialogVisible(true);
  };

  const openTextEditor = () => {
    //console.log('pp->', props);
    if (isTextType()) {
      if (props.attr.attributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL) {
        openEditImageDialog(props.attr);
        return;
      }
      if (props.attr.attributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURLLIST) {
        openEditImageListDialog(props.attr);
        return;
      }

      setTextEditorVisible(true);
    }
  };

  const saveAttribute = async (value = currentValue) => {
    //console.log('ready save', currentValue, props.attr);
    setIsSaving(true);

    //const overwrite = props.state?.overwriteChildren ? 1 : 0;
    const overwrite = props.state?.overwriteChildren;
    let scrollTop = 0;
    let scrollWrapper: any = null;

    console.log('ow->', overwrite);
    if (props.attr.attributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL) {
      setEditImageDialogVisible(false);
    }

    if (typeof props.getScrollWrapper === 'function') {
      scrollWrapper = props.getScrollWrapper();

      if (scrollWrapper) scrollTop = scrollWrapper.scrollTop;
      //console.log('stop', scrollWrapper.scrollTop);
    }

    try {
      await saveFuc(props.productId, [{
        attributeNum: props.attr.attributeNum,
        productAttributeValue: value,
      }], {
        params: {
          applyWithBlank: overwrite === 2 ? 1 : 0,
          isApplyChild: overwrite ? 1 : 0,
        },
      });
      setCurrentValue(value);
      setLastValue(value);
      setIsEditable(false);
      props.attr.productAttributeValue = value;
      message.success(`Saved ${props.attr.attributeName} successfully`);

      if (typeof props.refreshAttrs === 'function') {
        props.refreshAttrs();
      }

      if (scrollWrapper && scrollTop > 0) {
        scrollWrapper.scrollTo({top: scrollTop});
      }
    } catch (e) {
      message.error(`Saved ${props.attr.attributeName} error: ${e}`);
    } finally {
      if (ctnRef.current) {
        setIsSaving(false);
      }
    }
  };

  const saveAttributeByEditor = async (value = currentValue, isOverwrite = 0) => {
    setIsSaving(true);

    //const overwrite = isOverwrite ? 1 : 0;
    let scrollTop = 0;
    let scrollWrapper: any = null;

    if (props.attr.attributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL) {
      setEditImageDialogVisible(false);
    }

    if (typeof props.getScrollWrapper === 'function') {
      scrollWrapper = props.getScrollWrapper();

      if (scrollWrapper) scrollTop = scrollWrapper.scrollTop;
      //console.log('stop', scrollWrapper.scrollTop);
    }

    try {
      await saveFuc(props.productId, [{
        attributeNum: props.attr.attributeNum,
        productAttributeValue: value,
      }], {
        params: {
          applyWithBlank: isOverwrite === 2 ? 1 : 0,
          isApplyChild: isOverwrite ? 1 : 0,
        },
      });
      setCurrentValue(value);
      setLastValue(value);
      setIsEditable(false);
      props.attr.productAttributeValue = value;
      message.success(`Saved ${props.attr.attributeName} successfully`);

      if (typeof props.refreshAttrs === 'function') {
        props.refreshAttrs();
      }

      if (scrollWrapper && scrollTop > 0) {
        scrollWrapper.scrollTo({top: scrollTop});
      }
    } catch (e) {
      message.error(`Saved ${props.attr.attributeName} error: ${e}`);
    } finally {
      setIsSaving(false);
    }
  };

  const setOptions = async () => {
    const options = await loadAttributeOptions();

    if (Array.isArray(options)) {
      // console.log('opt->', options);
      optionList = options;
    }
  };

  React.useEffect(() => {
    if (!inited) {
      //loadAttrClassifications();
      if (optionList.length === 0) {
        setOptions();
      }

      setInited(true);
    }
  }, [inited]);

  return (<>
    <EditorWrapper className={props.className || ''} ref={ctnRef}>
      <Input.Group compact>
        {props.attr.classificationNums.length > 0 && (
          <Tooltip
            title={classificationTooltip()}
            onVisibleChange={onTooltipVisibleChange}
          >
            <span className="classification-icon" role="img" aria-label="flag">🇧🇶</span>
          </Tooltip>
        )}
        {isLinkable() && (
          props.attr.productAttributeValue ?
            <a className="link-icon" href={props.attr.productAttributeValue} target="_blank" rel="noreferrer"><span role="img" aria-label="flag"><LinkOutlined /></span></a> :
            <span className="classification-icon" role="img" aria-label="flag">
              <LinkOutlined />
            </span>
        )}
        {isEditable ? attrEditableField() : attrReadonlyField()}
        {attrOpeIcon()}
      </Input.Group>
    </EditorWrapper>
    {editImageDialogVisible && (
      <EditImageDialog
        imageUrl={currentImg.productAttributeValue || ''}
        onClose={closeEditImageDialog}
        onSave={saveAttribute}
        title={`Edit ${currentImg.attributeName}`}
        visible={editImageDialogVisible}
      />
    )}
    {textEditorVisible && <TextEditor
      productId={props.productId}
      dispatch={props.dispatch}
      editable={isEditable}
      onClose={closeTextEditor}
      // onSave={saveAttribute}
      onSave={(newValue: string, overwrite: number) => {
        saveAttributeByEditor(newValue, overwrite);
      }}
      state={props.state}
      textContent={currentValue}
      title={`${props.attr.attributeName}`}
      visible={textEditorVisible}
    />}
    {editImageListDialogVisible &&
      <EditImageListDialog
        imageUrls={currentImg.productAttributeValue || ''}
        onClose={closeEditImageListDialog}
        onSave={saveAttribute}
        visible={true}
      />
    }
  </>);
};

export default AttrEditor;
