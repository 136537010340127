import React, { useState } from 'react';
import { Radio, Space, Typography } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  sourceList: string[];
  setCanNext: (value: boolean) => void;
  onSelectedChange: (value: string | undefined) => void;
  defaultSelected: string | undefined;
};

const SourceSelector = (props: Props) => {
  const { sourceList, defaultSelected, onSelectedChange, setCanNext } = props;
  const [list, setList] = useState<string[]>([]);

  const onChange = (value: string) => {
    if (value) {
      onSelectedChange(value);
      setCanNext(true);
    } else {
      onSelectedChange(undefined);
      setCanNext(false);
    }
  };


  React.useEffect(()=>{
    setList(sourceList)
  },[sourceList])

  React.useEffect(() => {
    if (defaultSelected) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [defaultSelected, setCanNext]);

  return (
    <>
      <div className="dest-step-wrapper">
        {/* {list.filter((i) => i === 'Basic Info' || i === 'Common Attributes').length > 0 ? ( */}
          <Space direction="vertical">
            <Radio.Group onChange={(e) => onChange(e.target.value)} value={defaultSelected}>
              <Space direction="vertical">
                {list
                  .filter((i) => i === 'Basic Info' || i === 'Common Attributes')
                  .sort()
                  .map((i: string) => (
                    <Radio key={i} value={i}>
                      <Text strong>{i}</Text>
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
            {list.filter((i) => i !== 'Basic Info' && i !== 'Common Attributes').length >
              0 && (
              <Text style={{ marginLeft: 20, color: '#40a9ff' }} strong>
                Channel Specific Attributes
              </Text>
            )}
            <Radio.Group onChange={(e) => onChange(e.target.value)} value={defaultSelected}>
              <Space direction="vertical">
                {list
                  .filter((i) => i !== 'Basic Info' && i !== 'Common Attributes')
                  .map((i: string) => (
                    <Radio key={i} value={i}>
                      <Text strong>{i}</Text>
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </Space>
        {/* ) : (
          <Empty />
        )} */}
      </div>
    </>
  );
};

export default SourceSelector;
