import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import DialogBody from './DialogBody';
import ContentLayout from '../../components/ContentLayout';

import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
// import message from '../../components/common/message';
import {
  LOADING_ICON_SIZE1,
} from '../../constants/config';
import Products from '../../services/products';
import ProductDetailTitle from '../ListProducts/ProductDetailTitle';

type ProductDetailScreenProps = {
  isCreating: boolean,
  isFullscreen?: boolean,
};

const HeaderWrapper = styled.h3`
  font-size: 25px;
  width: 100%;
  font-weight: bold;
  margin: 5px;
  padding: 10px;
  background-color: white;
  line-height: 22px;
  word-wrap: break-word;
  text-align: left;
  font-variant: tabular_nums;
`;

//const ProductDetailScreen: React.FC<{ isCreating: boolean }> = ({ isCreating = false }) => {
const ProductDetailScreen = (props: ProductDetailScreenProps) => {
  const {
    isCreating,
    // isFullscreen,
  } = props;
  const {
    productId,
    // productType,
    // bundleType,
  } = useParams<{
    productId?: string,
    productType?: string,
    bundleType?: string,
  }>();
  const [currentProduct, setCurrentProduct] = useState<any>();
  // const [currentProductId, setCurrentProductId] = useState(productId);
  const [detailDialogTitleType, setDetailDialogTitleType] = useState('');
  const [isSwitching, setIsSwitching] = useState(false);
  const history = useHistory();
  const titleRef = useRef<any>();

  const setTitleType = (prdType: number) => {
    switch (prdType) {
      case 1:
        setDetailDialogTitleType('Product -');
        break;

      case 2:
        setDetailDialogTitleType('Style -');
        break;

      case 3:
        setDetailDialogTitleType('Substyle -');
        break;

      default:
        setDetailDialogTitleType('');
        break;
    }
  };

  const setCurrentProductIdFn = (newProductId: string) => {
    if (newProductId === productId) return;
    history.push(`/product-detail/${newProductId}`);
  };

  const setCurrentProductFn = () => {
  };

  useEffect(() => {
    const loadProduct = async () => {
      const productData = await Products.fetchSingleProductById(productId);
      const {
        ProductBasic,
        ...other
      } = productData || {};

      if (ProductBasic) {
        const {
          VariationParentSKU: styleCode,
        } = ProductBasic;

        setCurrentProduct({
          ...other,
          styleCode,
          ...ProductBasic,
        });
      }

      setDetailDialogTitleType('');
    };
    loadProduct();
  }, [productId]);

  if (!productId || !currentProduct) {
    return (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    );
  }
  
  return (
    <ContentLayout>
      <HeaderWrapper>
        <ProductDetailTitle
          product={currentProduct}
          setDetailDialogTitleType={setDetailDialogTitleType}
          currentProduct={currentProduct}
          setCurrentProduct={setCurrentProductFn}
          detailDialogTitleType={detailDialogTitleType}
          isSwitching={isSwitching}
          setIsSwitching={setIsSwitching}
          innerRef={titleRef}
          currentProductId={productId}
          setCurrentProductId={setCurrentProductIdFn}
        />
      </HeaderWrapper>
      {/* forced to unmount due to current architecture requiring full refresh */}
      {
        !isSwitching && (
          <DialogBody 
            isCreating={isCreating}
            isFullscreen={false}
            productId={productId}
            setTitleType={setTitleType}
          />
        )
      }
    </ContentLayout>
  );
};

export default ProductDetailScreen;
