import { TablePaginationConfig } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchChannels } from '../services/channels';
import { tablePaginationTotal } from './table';

/**
 * Utility hook to wrap promises.
 *
 * `useEffect` principles affect the received request function, so use a `useCallback` whenever required to prevent
 * undesired behavior.
 *
 * This hook returns three elements:
 * - The current data value
 * - The loading status
 * - Function to trigger a data refresh (i.e.: refresh(true))
 *
 * Usage example:
 * ```ts
 * const [data, loading, refresh] = useExecutePromise(fetchFoo, null);
 * const onButtonClicked = useCallback(() => refresh(true), []);
 * ```
 *
 * @param req - A function that returns a Promise. The result of the promise is stored on the data attribute
 * @param initialValue - Initial value for the data
 */
export function useExecutePromise<T>(req: () => Promise<T>, initialValue: T): [T, boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(initialValue);

  // If the request changes, reload
  useEffect(() => setReload(true), [req]);

  useEffect(() => {
    let mounted = true;

    if (!reload) {
      return () => {
        mounted = false;
      };
    }

    setLoading(true);

    req()
      .then((res) => {
        if (!mounted) {
          return;
        }

        setData(res);
      })
      .finally(() => {
        if (!mounted) {
          return;
        }

        setReload(false);
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [req, reload]);

  return [data, loading, setReload];
}

export const useFetchChannels = (): [Entities.ChannelProfile[], boolean] => {
  const [data, loading] = useExecutePromise(fetchChannels, []);
  return [data, loading];
};

/**
 * Custom hook that allows keeping a TabPane active key as an argument on the query string.
 *
 * Useful for when you have tabbed views and want to allow an specific tab to be active.
 *
 * @param qsName
 * @param defaultValue
 */
export const useActiveTabOnQuery = (qsName: string = 'tab', defaultValue: string = ''): [string, (activeKey: string) => void] => {
  const history = useHistory();
  const location = useLocation();

  const activeTab: string = useMemo((): string => {
    if (!location.search) {
      return defaultValue;
    }

    const params: any = qs.parse(location.search, { ignoreQueryPrefix: true });
    return params[qsName] || defaultValue;
  }, [location, qsName, defaultValue]);

  const onTabChange = useCallback((activeKey: string) => {
    history.push({ search: qs.stringify({ tab: activeKey }) });
  }, [history]);

  return [activeTab, onTabChange];
};

/**
 * Hook that keeps track of the checked status of a checkbox.
 *
 * Usage is as follows:
 *
 * ```tsx
 * const [checked, onChange] = useObserveCheckbox()
 *
 * // Possible local effect
 * useEffect(() => {
 *   if (checked) {
 *     form.clearValues()
 *   }
 * }, [checked, form]);
 *
 * return (
 *  <Checkbox onChange={onChange}>
 *    Foo?
 *  </Checkbox>
 * );
 * ```
 * @param initialValue
 * @param callback - Pass a function to execute whenever the value changes.
 */
export const useObserveCheckbox = (initialValue: boolean = false, callback?: (value: boolean) => void): [boolean, (e: CheckboxChangeEvent) => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [checked, setChecked] = useState(initialValue);
  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    callback?.(e.target.checked);
  }, [setChecked, callback]);

  return [checked, onChange, setChecked];
};

export const useObserveSwitch = (initialValue: boolean = false, callback?: (value: boolean) => void): [boolean, (bl: boolean) => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [checked, setChecked] = useState(initialValue);
  const onChange = useCallback((bl: boolean) => {
    setChecked(bl);
    callback?.(bl);
  }, [setChecked, callback]);

  return [checked, onChange, setChecked];
};



interface UseFilterPaginationParams<T> {
  defaultPagination?: TablePaginationConfig;
  defaultFilter?: T & api.Pagination | any;
}

type UseFilterPaginationReturnType<T> = [
  TablePaginationConfig,
  (T & api.Pagination),
  (pagination: TablePaginationConfig) => void,
  (filters: T & api.Pagination) => void,
  (total: number) => void,
];

/**
 * Hook that handles pagination and filtering
 *
 * This hook assumes working with ant.d Pagination component, whereas the
 * filters include the pagination parameters the API expects.
 *
 * @param defaultValues
 */
export function useFilterPagination<T>(defaultValues?: UseFilterPaginationParams<T>): UseFilterPaginationReturnType<T> {
  const { defaultPagination = {}, defaultFilter = {} } = defaultValues || {};
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    size: 'small',
    showQuickJumper: true,
    showTotal: tablePaginationTotal,
    position: ['bottomLeft'],
    ...defaultPagination,
  });

  const [filterParams, setFilterParams] = useState<T>({
    $top: pagination.pageSize,
    $skip: ((pagination.current || 1) - 1) * (pagination.pageSize || 10),
    ...defaultFilter,
  });

  const updatePagination = useCallback((data: TablePaginationConfig) => {
    const { current = 1, pageSize = 10 } = data;
    setPagination({ ...data, showTotal: pagination.showTotal });
    setFilterParams({
      ...filterParams,
      $top: pageSize,
      $skip: (current - 1) * pageSize,
    });
  }, [pagination, filterParams]);

  const updateFilters = useCallback((data: T) => {
    setFilterParams({
      ...data,
      $skip: 0,
      $top: pagination.pageSize,
    });

    setPagination({
      ...pagination,
      current: 1,
    });
  }, [pagination]);

  const updateTotalCount = useCallback((total: number) => {
    if (total === pagination.total) {
      return;
    }

    setPagination({ ...pagination, total, showTotal: tablePaginationTotal });
  }, [pagination]);

  return [
    pagination,
    filterParams,
    updatePagination,
    updateFilters,
    updateTotalCount,
  ];
}

/**
 * Hook that allows downloading a Blob
 *
 * @param file - Object to download
 * @param fileName - Name of the downloaded file
 * @returns An array containing the callback to trigger the download and a loading indicator
 */
export const useFileDownload = (file?: () => Promise<Blob>, fileName?: string): [() => void, boolean] => {
  const [loading, setLoading] = useState<boolean>(false);

  const download = useCallback(async () => {
    if (!file || !fileName) {
      return;
    }

    setLoading(true);

    try {
      const data = await file();
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      // TODO: show error
    } finally {
      setLoading(false);
    }
  }, [file, fileName]);

  return [download, loading];
};
