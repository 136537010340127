import React from "react";
import { Link } from 'react-router-dom';
import ContentLayout from "../../../components/ContentLayout";
import Heading from "../../../components/common/Heading";
import Spacer from "../../../components/common/Spacer";
import { Col, Row } from "antd";
import SiteContent from "../../../components/SiteContent";
import styled from "styled-components";
import ArrowRight from "../../../assets/icons/arrowRight";


const ChannelWrapper = styled.div`
  margin-left: 40px;
  padding: 30px 0px;
  border-bottom: 1px solid #E4E4E4;

  &:last-child  {
    border-bottom: none;
  }
`

// const CardTitle = styled.h4`
//   font-size: 20px;
//   margin: 0px 0px 30px 0px;
//   color: #2d3f5d;
// `

const ChannelCard = styled(Col)`
  position: relative;
  padding: 10px;
  height: 112px;
  width: 170px;
  background-color: #F0F2F5;
  border-radius: 4px;
  transition: background-color 0.2s;

  .name-line {
    color: #2d3f5d;
    font-size: 17px;
    font-weight: 500;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    position: absolute;
    bottom: 4px;
    right: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: #F7D6B8;
  }
`

const ProductFeature = () => {

  const cardList = [
    {
      title: 'Fields',
      key: 'fields',
      url: '/pimSettings-enssentialInfo-productFeature/fields'
    },
    {
      title: 'Groups',
      key: 'groups',
      url: '/pimSettings-enssentialInfo-productFeature-groups'
    },
    {
      title: 'Layout',
      key: 'layout',
      url: '/pimSettings-enssentialInfo-productFeature/layout'
    },
  ]

  const CardRow = (props: { cardList: any[] }) => {
    const { cardList } = props;
    return (
      <ChannelWrapper>
        {/* <CardTitle>{title}</CardTitle> */}
        <Row gutter={[20, 20]}>
          {cardList.map((card: any) => <CardCol key={`card-${card.key}`} card={card} />)}
        </Row>
      </ChannelWrapper>
    )
  }

  const CardCol = (props: { card: any }) => {
    const { card } = props;
    const { title = '', url = '' } = card;

    return (
      <Col>
        <Link to={url}>
          <ChannelCard>
            <div className="name-line">{title}</div>
            <div className="arrow"><ArrowRight /></div>
          </ChannelCard>
        </Link>
      </Col>
    )
  }

  return (
    <ContentLayout>
      <Heading title="Product Feature" />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <CardRow cardList={cardList} />
      </SiteContent>
    </ContentLayout>
  )
}

export default ProductFeature;
