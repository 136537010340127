import { Button, Col, Dropdown, Menu, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CSVLink, { useGenerateCSV } from '../components/common/CSVLink';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { fetchChannels } from '../services/channels';
import { fetchProductMappingList } from '../services/products';
import { sortDataTable } from '../util';

const { Option } = Select;

const ButtonLink = styled.label`
  color: #006dff;
  cursor: pointer;
`;

const CustomButton = styled(Button)`
  width: 140px;
  height: 40px;
`;

const initialExportColumnsState = [
  {
    header: 'SKU',
    key: 'SKU',
  },
  {
    header: 'Parent SKU',
    key: 'ParentSKU',
  },
  {
    header: 'Title',
    key: 'Title',
  },
  {
    header: 'Category',
    key: 'Category',
  },
  {
    header: 'Has Buy Box',
    key: 'HasBuyBox',
  },
  {
    header: 'Listing Status',
    key: 'ListingStatus',
  },
  {
    header: 'Price',
    key: 'Price',
  },
  {
    header: 'TemplatePrice',
    key: 'TemplatePrice',
  },
  {
    header: 'ChannelStock',
    key: 'ChannelStock',
  },
  {
    header: 'Quantity',
    key: 'Quantity',
  },
];

const initialSelectDefaultValueState = [
  'Parent SKU',
  'Title',
  'Category',
  'Has Buy Box',
  'Listing Status',
  'Price',
  'Template Price',
  'Channel Stock',
  'Quantity',
  // 'SyncStatus',
];

const hashes: any = {
  'Listing Status': new Map([[0, 'Listed'], [1, 'Unlisted']]),
  'Channel Stock': new Map([[0, 'Out of Stock'], [1, 'In Stock']]),
  // 'Sync Status': new Map([[9, 'Error'], [1, 'Success'], [0, 'N/A'] ]),
  'Lifecycle Status': new Map([[0, 'N/A'], [1, 'Active'], [2, 'Retired']]),
  'Published Status': new Map([[4, 'Published'], [2, 'Ready to publish'], [1, 'In Progress'], [0, 'Unpublished'], [9, 'Error']]),
};

const formatDataToExport = (unformatted: any) => unformatted.map((dte: any) => ({
  ...dte,
  ListingStatus: hashes['Listing Status'].get(dte.ListingStatus),
  ChannelStock: hashes['Channel Stock'].get(dte.ChannelStock),
  // SyncStatus: hashes['Sync Status'].get(dte.SyncStatus),
  PublishedStatus: hashes['Published Status'].get(dte.PublishedStatus),
  LifecycleStatus: hashes['Lifecycle Status'].get(dte.LifecycleStatus),
}));

const defaultColumnValues: any = {
  columnsByProduct: [
    'Parent SKU',
    'Title',
    'Category',
    'Has Buy Box',
    'Listing Status',
    'Price',
    'Template Price',
    'Channel Stock',
    'Quantity',
    // 'SyncStatus',
    'Messages',
    'Published Status',
    'Lifecycle Status',
    'Last Updated',
  ],
  columnsByErrorMessage: [
    'Parent SKU',
    'Category',
    'Listing Status',
    // 'SyncStatus',
    'Messages',
    'Published Status',
    'Lifecycle Status',
    'Last Submitted',
    'Last Updated',
  ],
  default: [
    'Parent SKU',
    'Title',
    'Category',
    'Has Buy Box',
    'Listing Status',
    'Price',
    'Template Price',
    'Channel Stock',
    'Quantity',
    // 'SyncStatus',
  ],
  all: [
    'Parent SKU',
    'Title',
    'Category',
    'Has Buy Box',
    'Listing Status',
    'Price',
    'Last Submitted',
    'Template Price',
    'Channel Stock',
    'Quantity',
    // 'SyncStatus',
    'Last Updated',
    'Messages',
    'Message Titles',
    'Walmart Marketplace ID',
    'Published Status',
    'Lifecycle Status',
  ],
  none: [],
};

function ProductList(props: any) {
  const { match } = props;
  const [channelName, setChannelName] = useState<string>('');
  const [data, setData] = useState<Array<any>>([]);
  const [dataToExport, setDataToExport] = useState<Array<any>>([]);
  const [selectDefaultValue, setSelectDefaultValue] = useState<Array<any>>(initialSelectDefaultValueState);
  const [exportColumns, setExportColumns] = useState<Array<any>>(initialExportColumnsState);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [disableActions, setDisableActions] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [exportSelectedRows] = useGenerateCSV(dataToExport, 'SelectedProducts.csv', exportColumns);
  // TODO: uncomment when feature is ready
  // const sync = async (type: string, sku: any) => {
  //   setLoading(true);
  //   const response = await fetchChannelAccountNum(match.params.id);
  //   const params = {
  //     SKU: sku,
  //     ChannelAccountNum: response[0].ChannelAccountNum,
  //     ChannelNum: Number(match.params.id),
  //   };
  //   if (type === 'status') {
  //     await syncProductStatusFromChannel(params);
  //     reduxActions.setNotification('success', 'Product status synchronized successfully!');
  //   } else {
  //     await syncProductToChannel(params);
  //     reduxActions.setNotification('success', 'Product synchronized successfully!');
  //   }
  //   setLoading(false);
  // };
  // const actionOptions = (sku: any, category: any) => (
  //   <Menu>
  //     <Menu.Item onClick={() => props.history.push(`/product-mapping-preview/${props.match.params.id}/${category}/${sku}`)}>
  //       Preview
  //     </Menu.Item>
  //      <Menu.Item onClick={() => sync('status', sku)}>
  //       Sync Channel Product  Status
  //      </Menu.Item>
  //      <Menu.Item onClick={() => sync('product', sku)}>
  //       Sync Product To Channel
  //      </Menu.Item>
  //   </Menu>
  // );
  const initialColumnsState = [
    {
      title: 'SKU',
      dataIndex: 'SKU',
      fixed: 'left',
      width: 250,
      sorter: (a: any, b: any) => a.SKU.length - b.SKU.length,
    },
    {
      title: 'Parent SKU',
      dataIndex: 'ParentSKU',
      sorter: true,
      width: 250,
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      width: 250,
      sorter: (a: any, b: any) => a.Title.length - b.Title.length,
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      width: 250,
      sorter: (a: any, b: any) => a.Category.length - b.Category.length,
    },
    {
      title: 'Has Buy Box',
      dataIndex: 'HasBuyBox',
      width: 250,
    },
    {
      title: 'Listing Status',
      dataIndex: 'ListingStatus',
      sorter: true,
      width: 250,
      filters: [
        { text: 'Listed', value: 0 },
        { text: 'Unlisted', value: 1 },
      ],
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.ListingStatus === value,
      render: (value: any) => hashes['Listing Status'].get(value),
    },
    {
      title: 'Price',
      dataIndex: 'Price',
      sorter: true,
      width: 250,
    },
    {
      title: 'Template Price',
      dataIndex: 'TemplatePrice',
      sorter: true,
      width: 250,
    },
    {
      title: 'Channel Stock',
      dataIndex: 'ChannelStock',
      sorter: true,
      width: 250,
      filters: [
        { text: 'Out of Stock', value: 0 },
        { text: 'In Stock', value: 1 },
      ],
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.ChannelStock === value,
      render: (value: any) => hashes['Channel Stock'].get(value),
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      sorter: true,
      width: 250,
    },
    // {
    //   title: 'Sync Status',
    //   dataIndex: 'SyncStatus',
    //   sorter: true,
    //   width: 200,
    //   filters: [
    //     { text: 'Error', value: 9 },
    //     { text: 'Success', value: 1 },
    //     { text: 'N/A', value: 0 },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value: any, record: any) => record.SyncStatus === value,
    //   render: (value: any) => hashes['Sync Status'].get(value),
    // },
    // {
    //   title: 'Action',
    //   dataIndex: 'Action',
    //   fixed: 'right',
    //   width: 250,
    //   render: function render(_: any, record: any) {
    //     return (
    //       <Dropdown overlay={() => actionOptions(record.SKU, record.Category)} placement="bottomCenter">
    //         <CustomButton>Select an action</CustomButton>
    //       </Dropdown>
    //     );
    //   },
    // },
  ];
  const [columns, setColumns] = useState<Array<any>>(initialColumnsState);
  const actionTableLevelOptions = (
    <Menu>
      <Menu.Item key="selectedRowExport" onClick={exportSelectedRows}>
        Export selected rows
      </Menu.Item>
    </Menu>
  );
  const actionTableLevel = (
    <Dropdown disabled={disableActions} overlay={actionTableLevelOptions} placement="bottomCenter">
      <CustomButton>Actions</CustomButton>
    </Dropdown>
  );

  const handleColumnSelectionChange = (val: any) => {
    const newColumns: any = [];
    const newColumnsToExport: any = [];
    val.map((v: any) => {
      newColumnsToExport.push({
        label: v,
        key: v,
      });
      let valueToPush: any = {
        title: v,
        dataIndex: v,
        sorter: (a: any, b: any) => a[v].length - b[v].length,
        width: 200,
      };
      if (v === 'Listing Status' ||
        v === 'Channel Stock' ||
        v === 'Sync Status' ||
        v === 'Published Status' ||
        v === 'Lifecycle Status') {
        const filters = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of hashes[v].entries()) {
          filters.push({
            text: value,
            value: key,
          });
        }
        valueToPush = {
          title: v,
          dataIndex: v,
          filters,
          filterMultiple: false,
          onFilter: (value: any, record: any) => record[v] === value,
          sorter: (a: any, b: any) => a[v].length - b[v].length,
          width: 200,
          render: (value: any) => hashes[v].get(value),
        };
      }
      newColumns.push(valueToPush);
      return v;
    });

    setExportColumns([
      {
        header: 'SKU',
        key: 'SKU',
      },
      ...newColumnsToExport,
    ]);

    setColumns([
      {
        title: 'SKU',
        dataIndex: 'SKU',
        fixed: 'left',
        sorter: (a: any, b: any) => a.SKU.length - b.SKU.length,
        width: 200,
      },
      ...newColumns,
      // {
      //   title: 'Action',
      //   dataIndex: 'Action',
      //   sorter: true,
      //   fixed: 'right',
      //   width: 250,
      //   render: function render(_: any, record: any){
      //     return (
      //       <Dropdown overlay={() => actionOptions(record.SKU, record.Category)} placement="bottomCenter">
      //         <CustomButton>Select an action</CustomButton>
      //       </Dropdown>
      //     );
      //   },
      // },
    ]);
    setSelectDefaultValue(val);
    setDataToExport(formatDataToExport(data));
  };
  const handleTableChange = (paginationParam: any, filters: any, sorter: any) => {
    setPagination({
      ...paginationParam,
      pageSize: paginationParam.pageSize,
      current: paginationParam.current,
    });
    const sortedData = sortDataTable(data, sorter);
    setData(sortedData);
  };
  const handleSetColumnsByDefault = (type: string) => {
    setSelectDefaultValue(defaultColumnValues[type]);
    handleColumnSelectionChange(defaultColumnValues[type]);
  };

  useEffect(() => {

    let filteredData = data;
    if (selectedRowKeys.length > 0) {
      filteredData = data.filter(e => selectedRowKeys.indexOf((e as any).index) >= 0);
    }

    setDataToExport(formatDataToExport(filteredData));
  }, [data, selectedRowKeys]);

  const onSelectChange = (selectedKeys: any) => {
    setDisableActions(!selectedKeys.length);
    setSelectedRowKeys([...selectedKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any) => {
          const newSelectedRowKeys = changableRowKeys.filter((key: any, index: any) => index % 2 === 0);
          setSelectedRowKeys([...newSelectedRowKeys]);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any) => {
          const newSelectedRowKeys = changableRowKeys.filter((key: any, index: any) => index % 2 !== 0);
          setSelectedRowKeys([...newSelectedRowKeys]);
        },
      },
    ],
  };
  const fetchData = async (id: number) => {
    try {
      setLoading(true);
      const availableChannel = await fetchChannels();
      const indexSelectedChannel = availableChannel.map((c: any) => c.channelNum).indexOf(Number(id));
      setChannelName(availableChannel[indexSelectedChannel].channelName);
      const response = await fetchProductMappingList(id);
      setData(response.map((r, i) => ({ ...r, index: i })));
      setDataToExport(formatDataToExport(response));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const useFetch = (id: number) => {
    useEffect(() => {
      fetchData(id);
    }, [id]);
  };

  useFetch(match.params.id);

  return (
    <ContentLayout>
      <SiteContent>
        <Heading
          title={`${channelName} Product Listing`}
          actions={(
            <>
              <CSVLink
                filename="ExportedProducts.csv"
                data={dataToExport}
                columns={exportColumns}
              />
            </>
          )}
        />
      </SiteContent>
      <Spacer />
      <SiteContent>
        <Row>
          <Col span={24}>
            <h3>Select/Remove table columns to export</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CustomButton
              style={{ float: 'left' }}
              onClick={() => {
                handleSetColumnsByDefault('default');
              }}
            >
              Reset columns
            </CustomButton>
            <CustomButton
              style={{ float: 'right' }}
              onClick={() => {
                handleSetColumnsByDefault('columnsByErrorMessage');
              }}
              danger
            >
              By Error Message
            </CustomButton>
            <CustomButton
              style={{ float: 'right', marginRight: '10px' }}
              onClick={() => {
                handleSetColumnsByDefault('columnsByProduct');
              }}
              color="primary"
            >
              By Product
            </CustomButton>
          </Col>
        </Row>
        <Spacer />
        <Row>
          <Col span={24}>
            <ButtonLink onClick={() => handleSetColumnsByDefault('all')}>Select All</ButtonLink>
            {' '}
            /
            <ButtonLink onClick={() => handleSetColumnsByDefault('none')}>Unselect All</ButtonLink>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%', marginTop: '10px' }}
              placeholder="Please select"
              defaultValue={selectDefaultValue}
              onChange={handleColumnSelectionChange}
              showArrow
              className="select-multiple-tags"
              value={selectDefaultValue}
            >
              <Option key="Category" value="Category">Category</Option>
              <Option key="ChannelStock" value="Channel Stock">Channel Stock</Option>
              <Option key="HasBuyBox" value="Has Buy Box">Has Buy Box</Option>
              <Option key="LastSubmitted," value="Last Submitted">Last Submitted</Option>
              <Option key="LastUpdated" value="Last Updated">Last Updated</Option>
              <Option key="LifecycleStatus" value="Lifecycle Status">Lifecycle Status</Option>
              <Option key="ListingStatus" value="Listing Status">Listing Status</Option>
              <Option key="Messages" value="Messages">Messages</Option>
              <Option key="MessageTitles" value="Message Titles">Message Titles</Option>
              <Option key="Parent SKU" value="Parent SKU">Parent SKU</Option>
              <Option key="Price" value="Price">Price</Option>
              <Option key="PublishedStatus" value="Published Status">Published Status</Option>
              <Option key="Quantity" value="Quantity">Quantity</Option>
              <Option key="TemplatePrice" value="Template Price">Template Price</Option>
              <Option key="Title" value="Title">Title</Option>
              {/* <Option key="SyncStatus" value="Sync Status">Sync Status</Option> */}
              <Option key="WalmartMarketplaceID" value="Walmart Marketplace ID">Walmart Marketplace ID</Option>
            </Select>
          </Col>
        </Row>
        <Spacer />
        <Table
          bordered
          rowSelection={rowSelection}
          scroll={{ x: 1500, y: 500 }}
          showSorterTooltip={false}
          columns={columns}
          dataSource={data}
          pagination={{
            ...pagination,
          }}
          rowKey="index"
          title={() => actionTableLevel}
          className="product-list-mapping-table"
          loading={loading}
          onChange={handleTableChange}
        />
      </SiteContent>
    </ContentLayout>
  );
}


export default ProductList;
