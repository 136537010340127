import {
  Spin,
  Row,
  Space,
  Typography,
  Button,
  Checkbox,
  Col,
  message,
} from "antd";
import React, { useState, useCallback, useEffect } from "react";
import {
  fetchCopywritingelements,
  editAttributeContentResouce,
} from "../../services/copywriting";
import TableFilter from "../../components/common/TableFilter";

const { Text } = Typography;

type MappingDialogProps = {
  current: Entities.ProductAttribute;
  contentSourceList: any[];
  onReturnList: (data: any) => void;
  handleCancel: () => void;
  handleUpdated: (data: Entities.ProductAttribute) => void;
};

function ManageMapping({
  current,
  handleCancel,
  contentSourceList,
  onReturnList,
  handleUpdated,
}: MappingDialogProps) {
  const [loading, setLoaidng] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedNum, setSelectedNum] = useState<any>(
    current.contentResourceNum
  );

  const handleSave = useCallback(async () => {
    try {
      if (!current.AttributeNum) return;
      setSaveLoading(true);

      const res = await editAttributeContentResouce(
        current.AttributeNum,
        selectedNum || 0
      );
      if (res.isSuccess) {
        message.success("saved successfully");
        if (selectedNum) {
          const temp = list.filter(
            (i) => i.copywritingElementNum === selectedNum
          );
          handleUpdated({
            ...current,
            contentResourceName: temp[0].elementName,
            contentResourceNum: temp[0].copywritingElementNum,
          });
        } else {
          handleUpdated({
            ...current,
            contentResourceName: undefined,
            contentResourceNum: undefined,
          });
        }
      } else {
        message.error(res.message || res.Message);
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, [current, selectedNum, handleUpdated, list]);

  const getList = useCallback(async () => {
    if (contentSourceList.length === 0) {
      try {
        setLoaidng(true);
        const { data } = await fetchCopywritingelements();
        setList(data);
        onReturnList(data);
        setLoaidng(false);
      } catch (error) {
        setLoaidng(false);
      }
    } else {
      setList(contentSourceList);
    }
  }, [contentSourceList, onReturnList]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Spin spinning={loading} wrapperClassName="ant-spin-flex-height">
      <Row justify="space-between">
        <Text
          strong
          style={{ fontSize: 18 }}
        >{`Attribute Name: ${current?.AttributeName}`}</Text>
        <Space>
          <Button
            type="primary"
            loading={saveLoading}
            disabled={current.contentResourceNum === selectedNum}
            onClick={() => handleSave()}
          >
            Save
          </Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </Space>
      </Row>
      <TableFilter
        size="middle"
        columns={[{ name: "elementName" }]}
        dataSource={list}
        setFilteredData={setFilteredData}
      />
      <Checkbox.Group
        value={selectedNum ? [selectedNum] : undefined}
        style={{ width: "100%" }}
        onChange={(checkedValue) => {
          setSelectedNum(
            checkedValue.length > 1
              ? checkedValue[1]
              : checkedValue.length === 1
              ? checkedValue[0]
              : undefined
          );
        }}
      >
        <Row>
          {filteredData.map((i) => (
            <Col span={6} key={i.copywritingElementNum}>
              <Checkbox value={i.copywritingElementNum}>
                {i.elementName}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Spin>
  );
}

export default ManageMapping;
