import styled from 'styled-components';
import { Divider } from 'antd';


export const Paragraph = styled.div`
  float: ${(props: { float?: string, bold?: boolean }) => props.float};
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${(props: { color?: string, bold?: boolean }) => props.color};
  margin: 3px;
  font-weight: ${(props: { bold?: boolean }) => props.bold ? '600' : 'inherited'};
`;


export const Title = styled.h1`
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 900;
  color: #2d3f5d;
  border-bottom: 1px solid;
  margin-bottom: 15px;
`;

export const FormWrapper = styled.div`
  padding: 0px;
`;

export const Description = styled.p`
  font-family: Lato, sans-serif;
  font-size: 14px;
`;

export const Divider2 = styled(Divider)`
  width: 1px;
  height: 100%;
  box-shadow: 1px 0 0 #2d3f5d;
  margin: 0 25px;
`;

export const ControlsWrapper = styled.div`
  float: ${ (props: { float?: string }) => props.float };
`;

export const ContentLayoutModal = styled.div``;
