import { digitBridgeApi } from './http';

type PostOverwriteRuleProps = {
  RuleNum: number;
  RuleType: number;
  ChannelAccountNum: number;
  RuleName: string;
  RuleExpression: {
    TreatZero: number;
    UserSetValue: number;
    LessValue: number;
    GreaterValue: number;
    UseOriginalValue: number;
  };
  RuleStatus: number;
}
export const createOverwriteRule = async (payload: PostOverwriteRuleProps): Promise<any> => {
  const json = JSON.stringify(payload)
  return digitBridgeApi.post('/v1/QtyOverwrite/Rule', json);
}

export const updateOverwriteRule = async (ruleNum: number, payload: PostOverwriteRuleProps): Promise<any> => {
  const json = JSON.stringify(payload)
  return digitBridgeApi.patch(`/v1/QtyOverwrite/${ruleNum}/Rule`, json);
};

export const deleteOverwriteRule = async (ruleNum: number): Promise<void> => digitBridgeApi.delete(`/v1/QtyOverwrite/${ruleNum}/Rule`);

export const fetchOverwriteRuleList = async (): Promise<any> => digitBridgeApi.get(`/v1/QtyOverwrite/Rule`)


export const fetchRuleById = async (ruleNum: number): Promise<any> => digitBridgeApi.get(`/v1/QtyOverwrite/${ruleNum}/Rule`);