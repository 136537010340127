import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import { Button, Col, Form, Input, Menu, Modal, Row, Select, Tag, Dropdown, message, Space, Typography } from 'antd';
import {
  BuildOutlined,
  ExclamationCircleOutlined,
  //MinusCircleTwoTone,
  PicCenterOutlined,
  PictureOutlined,
  //PlusCircleTwoTone,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import SiteContent from '../../../components/SiteContent';
import notificationActions from '../../../redux/actions/notifications';
import SearchIcon from '../../../assets/icons/search';
import LabelIcon from '../../../assets/icons/label';
import MapIcon from '../../../assets/icons/map';
import VerifiedIcon from '../../../assets/icons/verified';
import ThumbsUpIcon from '../../../assets/icons/thumbsup';
import BlockIcon from '../../../assets/icons/block';
import TableComponent from '../../../components/TableComponent';
import CheckMark from '../../../assets/icons/checkmark';
import LocationIcon from '../../../assets/icons/location';
import {
  fetchHelp,
  //fetchMappingAttributes,
  fetchChannelMappingAttributes,
  fetchProductMapping2,
  patchProductMapping,
  productAutoMapping,
} from '../../../services/products';
import { sortDataTable } from '../../../util';
import EditMappingModal, { PatternType, emptyStrToHtml } from '../../../components/ProductMapping/EditMappingModal';
import ConfirmationModal from '../../../components/ProductMapping/ConfirmationModal';
import TextField from '../../../assets/icons/textfield';
import Heading from '../../../components/common/Heading';
import Spacer from '../../../components/common/Spacer';
import { MapperType } from '../../../constants/enums/mapping';
import { Permissions } from '../../../constants/enums/permissions';
// import MappingTemplateSelect from '../../../components/ChannelIntegration/MappingTemplateSelect';
import MappingTemplateSelect2 from '../../../components/ChannelIntegration/MappingTemplateSelect2';
import {
  mappingFromExistTemplate,
} from "../../../services/ChannelIntegration";

const { Option } = Select;

const { SubMenu } = Menu;

const SelectPlus = styled(Select)`
  border: solid 1px #d8dde6;
`

const LabelFilter = styled.p`
  font-size: 14px;
  margin: 0;
`;

const ElementNameTitle = styled.p`
color: #297bff;
cursor: pointer;
margin: 0;
`;

interface IButton {
  hasPermission: boolean
}

const InputTitle = styled.p`
  margin: 0;
  color: #546279;
`;

const SaveButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: auto;
  display: ${(props: IButton) => props.hasPermission ? 'inherit' : 'none'}
`;

const DiscardButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: 5px;
  display: ${(props: IButton) => props.hasPermission ? 'inherit' : 'none'}
`;

interface IConditionalMappersElement {
  Mapper: {
    MapperType: number;
    AttributeMappers?: any;
  };
  ConditionType: number;
}

interface IElement {
  ElementName: string;
  ElementDisplayName?: string;
  MappedStatus: number;
  Requirement?: number;
  HelpNum?: number;
  IsArray?: number;
  Mapper: {
    MapperExpression?: StringKAnyVPair[];
    MapperPattern?: StringKAnyVPair[];
    MapperType: number;
    AttributeMappers: any;
    MapperLookup: number;
  };
  ConditionalMappers?: IConditionalMappersElement[] | null;
}

interface ISelectedMapping {
  SectionName: string;
  SectionDisplayName: string;
  SectionFlag: number,
  Elements: IElement[];
}

interface ISections {
  ProductMappingNum: number,
  Sections: ISelectedMapping[];
}

const sectionsInitialState = {
  ProductMappingNum: 0,
  Sections: [
    {
      SectionName: '',
      SectionDisplayName: '',
      SectionFlag: 1,
      Elements: [],
    },
  ],
};

const initialFormValues = {
  ElementDisplayName: '',
  HelpText: '',
  MapperType: 1,
  MappedValue: '',
  Attributes: [] as any,
  ConditionAttributes: [] as any,
  ConditionType: 2,
  ConditionalMapperType: 1,
  ConditionalMappedValue: '',
  ElementName: '',
  MapperLookup: 0,
  Suffix: '',
};

let backUpData: any = {};
const attributeHash = new Map();

function ProductMapping(props: any) {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const [data, setData] = useState<IElement[]>([]);
  const [dataToSave, setDataToSave] = useState<Array<any>>([]);
  const [editingMappingObj, setEditingMappingObj] = useState<StringKAnyVPair>({});
  const [attributes, setAttributes] = useState({});
  const [showEditModal, setShowEditModal] = useState<Boolean>(false);
  const defaultProductMappingNum = Number(searchParams.get('productMappingNum'))
  const [productMappingNum, setProductMappingNum] = useState<number>(0);
  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<Boolean>(false);
  const [showConfirmationDiscardMappingData, setShowConfirmationDiscardMappingData] = useState<Boolean>(false);
  const [showConfirmationDiscardMappingDataCondition, setShowConfirmationDiscardMappingDataCondition] = useState<Boolean>(false);
  const [showConfirmationDiscardModal, setShowConfirmationDiscardModal] = useState<Boolean>(false);
  const [sections, setSections] = useState<Array<any>>([]);
  const [help, setHelp] = useState<Array<any>>([]);
  const [isMultiple, setIsMultiple] = useState<number>(0);
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [keyFields, setKeyFields] = useState<string[]>([]);
  // const [keyFieldsReady, setKeyFieldsReady] = useState(false);
  const [fetchedData, setFetchedData] = useState<ISections>({ ...sectionsInitialState });
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [lastKeyFields, setLastKeyFields] = useState<string[]>([]);
  const [lastSectionKey, setLastSectionKey] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [filteredInfo, setFilteredInfo] = useState({
    Requirement: 0,
    MappedStatus: 0,
  });
  const [autoMappingModalVisible, setAutoMappingModalVisible] = useState(false);
  const profilesEntity = useSelector((state: any) => state.profiles);
  const dispatch = useDispatch();
  const actions = bindActionCreators(notificationActions, dispatch);
  const { channelNum, channelAccountNum }: { channelNum: string, channelAccountNum: string } = props;
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const handleModalClose = () => setShowEditModal(false);
  const handleDiscard = () => setShowConfirmationDiscardModal(true);
  const handleConfirmationClose = () => setShowConfirmationModal(false);
  const handleClearValues = () => setFormValues({ ...initialFormValues });
  const handleSidebarSelect = (selected: string) => {
    setSelectedSection(selected);
    setData(fetchedData.Sections.filter((s: any) => s.SectionName === selected)[0].Elements);
  };
  const handleMappingTemplateSelect = (value: number, option: any) => {
    //console.log('v', value, option);
    setProductMappingNum(value)
    setCurrentTemplate(option)
  };
  const discardChanges = () => {
    setDataToSave([]);
    setKeyFields([...lastKeyFields]);
    //setKeyFieldsReady(false);
    setShowConfirmationDiscardModal(false);
    const dataResponse = JSON.parse(JSON.stringify(backUpData));
    setFetchedData(dataResponse);
    setData(dataResponse.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements);
    //rerenderKeyFields();
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilteredInfo(filters);
    const sortedData = sortDataTable(data, sorter);
    setData(sortedData);
  };

  const handleSearchCategoriesInput = (e: any) => {
    if (e.target.value !== '') {
      setSections(fetchedData.Sections.filter((s: any) => s.SectionDisplayName.toLowerCase().includes(e.target.value.toLowerCase().trim())));
    } else {
      setSections(fetchedData.Sections);
    }
  };
  const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
    if (permissions && permissions.size === 0) return false;
    return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
  };

  const handleChannelFieldSearchText = (e: any) => {
    if (e.target.value !== '') {
      setData(fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements.filter((d: any) => {
        if (d.ElementDisplayName === '') return false;

        return d.ElementDisplayName.toLowerCase().includes(e.target.value.toLowerCase().trim());
      }));
    } else {
      setData(fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements);
    }
  };

  const handleMappingFromExistTemplate = async () =>  {
    const params = await form1.validateFields();
    if (params.errorFields) return;
    try {
      setLoading1(true)
      const res = await mappingFromExistTemplate(params.sourceMappingNum, params.targetMappingNum, Number(channelAccountNum))
      setLoading1(false)
      if (res) {
        message.success('Mapping Successfully')
        setAutoMappingModalVisible(false)
        form1.resetFields()
        fetchMapping(Number(channelNum), Number(channelAccountNum), productMappingNum);
      }
    } catch (error) {
      setLoading1(false)
    }

  }

  const handleAutomapping = () => {
    const autoMap = async () => {
      try {
        setLoading(true);
        await productAutoMapping(fetchedData.ProductMappingNum);
        actions.setNotification('success', 'Auto-mapping succeded!');
        setLoading(false);
        fetchMapping(Number(channelNum), Number(channelAccountNum), productMappingNum);
      } catch (e) {
        setLoading(false);
        actions.setNotification('error', 'Error occurred');
        console.log(e);
      }
    };

    Modal.confirm({
      title: 'Automapping will replace existing mapping attributes, are you sure to process?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        autoMap();
      },
    });
  };

  const handleDiscardMappingData = () => {
    setFormValues({
      ...formValues,
      MapperType: 1,
      ConditionType: 0,
      Attributes: [],
      ConditionalMappedValue: '',
      ConditionAttributes: [],
      MapperLookup: 0,
      Suffix: '',
    });
    form.setFieldsValue({
      ...formValues,
      MapperType: 1,
      ConditionType: 0,
      ConditionalMappedValue: '',
      Attributes: [],
      ConditionAttributes: [],
      MapperLookup: false,
      Suffix: '',
    });
  };

  const handleDiscardMappingDataCondition = () => {
    setFormValues({
      ...formValues,
      ConditionalMapperType: 2,
      MappedValue: '',
      ConditionAttributes: [],
      Suffix: '',
    });
    form.setFieldsValue({
      ...formValues,
      MappedValue: '',
      ConditionalMapperType: 2,
      ConditionAttributes: [],
      Suffix: '',
    });
  };

  const handleModalChange = (e: any, type: string) => {
    switch (type) {
      case 'ConditionType':
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.checked ? 1 : 0,
        });
        break;
      case 'ConditionalMapperType':
        if (e.target.value === 2) {
          if (formValues.ConditionAttributes.length > 0) {
            setShowConfirmationDiscardMappingDataCondition(true);
          } else {
            setFormValues({
              ...formValues,
              [e.target.name]: e.target.value,
            });
          }
        } else {
          setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
          });
        }
        break;

      case 'MapperType':
        if (e.target.value === 1) {
          if (formValues.Attributes.length > 0 || formValues.ConditionAttributes.length > 0) {
            setShowConfirmationDiscardMappingData(true);
          } else {
            setFormValues({
              ...formValues,
              MapperType: e.target.value,
              ConditionType: 0,
            });
          }
        } else {
          setFormValues({
            ...formValues,
            MapperType: e.target.value,
            ConditionType: 0,
          });
        }

        break;
      case 'Attributes':
        setFormValues({
          ...formValues,
          Attributes: e,
        });
        break;

      case 'ConditionAttributes':
        setFormValues({
          ...formValues,
          ConditionAttributes: e,
        });
        break;

      case 'text':
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
        });
        break;
      default:
        setFormValues({
          ...formValues,
          [type]: e,
        });
        break;
    }
  };

  const handleModifiedData = (
    isCleaning = false,
    patterns?: StringKAnyVPair[],
    imageAttrs?: StringKAnyVPair[],
    expAttrs?: StringKAnyVPair[],
  ) => {
    const existentData = [...dataToSave];
    const indexValue = existentData.findIndex((e: any) => e.ElementName === formValues.ElementName);
    if (indexValue !== -1) existentData.splice(indexValue, 1);
    let payloadElement: IElement = {
      ElementName: formValues.ElementName,
      MappedStatus: 0,
      Mapper: {
        MapperType: 1,
        AttributeMappers: [],
        MapperLookup: 0,
      },
      ConditionalMappers: [],
    };

    if (!isCleaning) {
      form.resetFields();
      let MapperTypeV = formValues.MapperType;
      console.log('f v', formValues);
      /*if (formValues.MapperType === 1) {
        MapperTypeV = 1;
      } else if (Array.isArray(formValues.Attributes)) {
        MapperTypeV = 4;
      } else {
        MapperTypeV = 3;
      }*/
      let NormalAttributeMappers: StringKAnyVPair[] = MapperTypeV === MapperType.MULTIPLE && Array.isArray(formValues.Attributes) ? formValues.Attributes?.map((mv: any) => ({
        MappedValue: `${mv}^${formValues.Suffix || ''}`,
        MapperDisplayName: attributeHash.get(mv),
      })) : [
        {
          MappedValue: MapperTypeV === 3 ? `${formValues.Attributes}^${formValues.Suffix || ''}`  : `${formValues.MappedValue}^${formValues.Suffix || ''}`,
          MapperDisplayName: MapperTypeV === 3 ? attributeHash.get(formValues.Attributes) : formValues.MappedValue,
        },
      ];

      if (formValues.MapperType === MapperType.IMAGE && Array.isArray(imageAttrs)) {
        console.log('ia ->', imageAttrs);
        NormalAttributeMappers = [{MappedValue: imageAttrs.map(e => e.pattern.value).join(',')}];
      }

      console.log('attr ->', NormalAttributeMappers);
      let ConditionalMappers = null;
      if (formValues.ConditionType === 1) {
        let ConditionalMapperType;
        if (formValues.ConditionalMapperType === 2) {
          ConditionalMapperType = 1;
        } else if (Array.isArray(formValues.ConditionAttributes)) {
          ConditionalMapperType = 4;
        } else {
          ConditionalMapperType = 3;
        }
        const ConditionalAttributeMappers = ConditionalMapperType === 4 ? formValues.ConditionAttributes?.map((cmv: any) => ({
          MappedValue: cmv,
          MapperDisplayName: attributeHash.get(cmv),
        })) : [
          {
            MappedValue: ConditionalMapperType === 3 ? formValues.ConditionAttributes : formValues.ConditionalMappedValue,
            MapperDisplayName: ConditionalMapperType === 3 ? attributeHash.get(formValues.ConditionAttributes) : formValues.ConditionalMappedValue,
          },
        ];
        ConditionalMappers = [
          {
            ConditionType: formValues.ConditionType,
            Mapper: {
              MapperType: ConditionalMapperType,
              AttributeMappers: ConditionalAttributeMappers,
            },
          },
        ];
      }
      payloadElement = {
        ElementName: formValues.ElementName,
        MappedStatus: 1,
        Mapper: {
          MapperExpression: MapperTypeV === MapperType.EXPRESSION ? expAttrs : [],
          MapperPattern: MapperTypeV === MapperType.MULTIPLE ? patterns : [],
          MapperType: MapperTypeV,
          AttributeMappers: MapperTypeV !== MapperType.MULTIPLE ? NormalAttributeMappers : [],
          MapperLookup: formValues.MapperLookup,
        },
        ConditionalMappers,
      };

      console.log('tpv', MapperTypeV, payloadElement);
    }

    existentData.push(payloadElement);
    console.log('dtoS->', existentData, patterns);
    setDataToSave(existentData);
    const editedElementDataIndex = data.findIndex((d: any) => d.ElementName === formValues.ElementName);
    const editedContent: StringKAnyVPair = {
      ...payloadElement,
      //ElementDisplayName: data[editedElementDataIndex].ElementDisplayName,
      ElementDisplayName: editingMappingObj.ElementDisplayName,
      //Requirement: data[editedElementDataIndex].Requirement,
      Requirement: editingMappingObj.Requirement,
      //HelpNum: data[editedElementDataIndex].HelpNum,
      HelpNum: editingMappingObj.HelpNum,
      //IsArray: data[editedElementDataIndex].IsArray,
      IsArray: editingMappingObj.IsArray,
    };

    const selectedSectionElements = fetchedData.Sections.filter((s: any) => s.SectionName === selectedSection)[0].Elements;
    const elementIndex = selectedSectionElements.findIndex((d: any) => d.ElementName === formValues.ElementName);
    const sectionIndex = fetchedData.Sections.findIndex((s: any) => s.SectionName === selectedSection);
    const fetchedDataTemp = { ...fetchedData };

    console.log('si', sectionIndex);
    console.log('edi', editedElementDataIndex, elementIndex);
    if (editedElementDataIndex >= 0 || elementIndex >= 0) {
      fetchedDataTemp.Sections[sectionIndex].Elements.splice(elementIndex, 1);
      fetchedDataTemp.Sections[sectionIndex].Elements.unshift(editedContent as any);
    } else {
      //console.log('edd', editedContent, editingMappingObj);
      for (let k in editedContent) {
        editingMappingObj[k] = editedContent[k];
      }
    }

    setFetchedData(fetchedDataTemp);
    setData([...fetchedDataTemp.Sections[sectionIndex].Elements]);
    setShowEditModal(false);
    setEditingMappingObj({});
  };

  const handleShowEditModal = (values: any) => {
    console.log('v->', values);
    const mType = values.Mapper.MapperType;
    const ConditionalMappers = (values.ConditionalMappers?.length === 0 || values.ConditionalMappers === null) ? {
      ConditionType: 0,
      Mapper: {
        MapperType: 1,
        AttributeMappers: [],
      },
    } : values.ConditionalMappers[0];
    const helpText = help.find((h: any) => h.AssetInfoNum === values.HelpNum)?.AssetInfoContent;

    const suffix = mType === MapperType.IMAGE ? '' : values.Mapper.AttributeMappers[0]?.MappedValue.split('^')[1] || '';
    const attributeMappers = mType === MapperType.IMAGE ? values.Mapper.AttributeMappers : values.Mapper.AttributeMappers.map((am: any) => am.MappedValue);
    console.log('at->', attributeMappers, mType);
    const attributesValue = mType === MapperType.IMAGE ? attributeMappers[0].MappedValue.split(',') : (attributeMappers.length > 0 ? attributeMappers[0].split('^')[0] : attributeMappers);
    //console.log('av->', attributesValue);
    const content = {
      ElementDisplayName: values.ElementDisplayName,
      HelpText: helpText === -1 ? '' : helpText,
      MapperExpression: Array.isArray(values.Mapper.MapperExpression) ? values.Mapper.MapperExpression : [],
      MapperPattern: Array.isArray(values.Mapper.MapperPattern) ? values.Mapper.MapperPattern : [],
      //MapperType: values.Mapper.MapperType === MapperType.MULTIPLE ? MapperType.SINGLE : values.Mapper.MapperType,
      MapperType: values.Mapper.MapperType,
      MappedValue: values.Mapper.MapperType === MapperType.TEXT ? values.Mapper.AttributeMappers[0]?.MapperDisplayName : '',
      Attributes: values.Mapper.MapperType === MapperType.MULTIPLE || values.Mapper.MapperType === MapperType.SINGLE || values.Mapper.MapperType === MapperType.IMAGE ? attributesValue : [],
      ConditionType: ConditionalMappers?.ConditionType,
      ConditionalMapperType: ConditionalMappers?.Mapper.MapperType === MapperType.TEXT ? 2 : 1,
      ConditionalMappedValue: ConditionalMappers?.Mapper.MapperType === MapperType.TEXT ? ConditionalMappers.Mapper.AttributeMappers[0]?.MapperDisplayName : '',
      ConditionAttributes: ConditionalMappers?.Mapper.MapperType === MapperType.MULTIPLE || ConditionalMappers?.Mapper.MapperType === MapperType.SINGLE ? ConditionalMappers.Mapper.AttributeMappers.map((am: any) => am.MappedValue) : [],
      ElementName: values.ElementName,
      MapperLookup: values.Mapper.MapperLookup,
      Suffix: suffix,
    };
    setIsMultiple(values.IsArray);
    console.log('ctn->', content);
    setFormValues(content);
    form.setFieldsValue(content);
    setShowEditModal(true);
    setEditingMappingObj(values);
  };

  // const keyFieldSource = () => {
  //   const keys: string[] = [];

  //   return data.filter(e => {
  //       if (e.ElementDisplayName && keys.indexOf(e.ElementDisplayName) < 0) {
  //         keys.push(e.ElementDisplayName);

  //         return true;
  //       }

  //       return false;
  //     });
  // };

  const mapperExpressionContent = (ps: StringKAnyVPair[]) => {
    /*const ats = attributes as Record<string, StringKAnyVPair[]>;
    const dvk: StringKAnyVPair = {};

    for (let k in ats) {
      // eslint-disable-next-line
      ats[k].forEach((e: StringKAnyVPair) => {
        dvk[e.AttributeNumID] = e.AttributeName;
      });
    }*/

    return ps.map(e => {
        const result = e.Result || e.result;
        const variable = e.Variable || e.variable;
        const pt = result.Type || result.type;
        let fs: string[] = [
          `{{${variable.Label || variable.label}}}`,
          e.Operator || e.operator,
          e.Operand || e.operand,
        ];
        //const pt = e.Type || e.type;
        //let value = e.Value || e.value || '';

        if (e.isElseValue || e.IsElseValue) fs = [];

        if (pt === PatternType.Attr) {
          //value = `{{${dvk[value] || value}}}`;
          fs.push(`{{${result.Label || result.label}}}`);
        } else {
          fs.push(result.Value || result.value);
        }

        return emptyStrToHtml(fs.join(''));
      })
      .join('');
  };

  const mapperImageExpression = (ps: StringKAnyVPair[]) => {
    const ats = attributes as Record<string, StringKAnyVPair[]>;
    const dvk: StringKAnyVPair = {};

    for (let k in ats) {
      // eslint-disable-next-line
      ats[k].forEach((e: StringKAnyVPair) => {
        dvk[e.AttributeNumID] = e.AttributeName;
      });
    }

    return ps.map(e => {
        let ret = '';

        if (e.MappedValue) {
          ret = e.MappedValue.split(',')
            .map((k: string) => `{{${dvk[k] || ''}}}`)
            .join('');
        }

        return ret;
      })
      .join('');
  };

  const mapperPatternExpression = (ps: StringKAnyVPair[]) => {
    const ats = attributes as Record<string, StringKAnyVPair[]>;
    const dvk: StringKAnyVPair = {};

    for (let k in ats) {
      // eslint-disable-next-line
      ats[k].forEach((e: StringKAnyVPair) => {
        dvk[e.AttributeNumID] = e.AttributeName;
      });
    }

    return ps.map(e => {
        const pt = e.Type || e.type;
        let value = e.Value || e.value || '';

        if (pt === PatternType.Attr) {
          value = `{{${dvk[value] || value}}}`;
        }

        return emptyStrToHtml(value);
      })
      .join('');
  };

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'Requirement',
      //align: 'center',
      key: 'Requirement',
      width: 50,
      filters: [
        {
          text: (
            <i>
              <VerifiedIcon color="#30c8ff" />
              Mandatory
            </i>
          ),
          value: 1,
        },
        {
          text: (
            <i>
              <LabelIcon color="#b56416" />
              Require If Relevant
            </i>
          ),
          value: 2,
        },
        {
          text: (
            <i>
              <ThumbsUpIcon color="#bababa" />
              Recommended
            </i>
          ),
          value: 3,
        },
      ],
      filteredValue: filteredInfo.Requirement || null,
      onFilter: (value: any, record: any) => record.Requirement.toString().includes(value),
      sorter: (a: any, b: any) => a.Requirement.length - b.Requirement.length,
      ellipsis: true,
      render: function render(value: any) {
        switch (value) {
          case 1:
            return <VerifiedIcon color="#30c8ff" style={{marginTop: 3}} />;
          case 2:
            return <LabelIcon color="#b56416" style={{marginTop: 3}} />;
          case 3:
            return <ThumbsUpIcon color="#bababa" style={{marginTop: 3}} />;
          default:
            return '';
        }
      },
    },
    {
      title: 'Channel Field',
      width: 100,
      dataIndex: 'ElementDisplayName',
      key: 'ElementDisplayName',
      sorter: (a: any, b: any) => a.ElementDisplayName - b.ElementDisplayName,
      render: function render(name: any, record: any) {
        return record.Mapper ? (
          <ElementNameTitle onClick={() => {
            handleShowEditModal(record);
          }}
          >
            {name}
          </ElementNameTitle>
        ) : (
          <span>{name}</span>
        );
      },
    },
    {
      title: 'Mapping from your data',
      dataIndex: 'Mapper',
      width: 150,
      key: 'Mapper',
      render: function render(value: any, record: any) {
        let conditionalMappers;
        let mappers;
        let ifblank;

        //console.log('re:', record);
        if (record.MappedStatus && record.Mapper) {
          mappers = record.Mapper.AttributeMappers.map((am: any) => {
            switch(record.Mapper.MapperType) {
              case MapperType.TEXT:
                return (<i key={uuid()}>
                  <TextField />
                  {am.MapperDisplayName}
                </i>);

              case MapperType.SINGLE:
                return (<i key={uuid()}>
                  <LocationIcon />
                  {am.MapperDisplayName}
                </i>);

              default:
                return null;
            }
          });

          if (record.Mapper.MapperType === MapperType.MULTIPLE) {
            if (Array.isArray(record.Mapper.MapperPattern) && record.Mapper.MapperPattern.length > 0) {
              //pas = record.Mapper.MapperPattern;
              mappers = [(<i key={uuid()}>
                <BuildOutlined />
                &nbsp;
                <span dangerouslySetInnerHTML={{ __html: mapperPatternExpression(record.Mapper.MapperPattern) }}></span>
              </i>)];
            }
          }

          if (record.Mapper.MapperType === MapperType.IMAGE) {
            mappers = [(<i key={uuid()}>
              <PictureOutlined />
              &nbsp;
              <span dangerouslySetInnerHTML={{ __html: mapperImageExpression(record.Mapper.AttributeMappers) }}></span>
            </i>)];
          }

          if (record.Mapper.MapperType === MapperType.EXPRESSION) {
            mappers = [(<i key={uuid()}>
              <PicCenterOutlined />
              &nbsp;
              <span dangerouslySetInnerHTML={{ __html: mapperExpressionContent(record.Mapper.MapperExpression || []) }}></span>
            </i>)];
          }

          if (record.ConditionalMappers) {
            ifblank = record.ConditionalMappers.length > 0 && <Tag color="cyan">If Blank</Tag>;
            conditionalMappers = record.ConditionalMappers.length > 0 && record.ConditionalMappers[0].Mapper.AttributeMappers.map((cm: any) =>
              record.ConditionalMappers.ConditionType === 2 ? (
                <>
                  <i>
                    <TextField />
                    {cm.MapperDisplayName}
                  </i>
                </>
              ) : (
                <>
                  <i>
                    <LocationIcon />
                    {cm.MapperDisplayName}
                  </i>
                </>
              ));
          }
        }
        /*if (mappers) {
          console.log('mappers', mappers, `mdk-${record.uuid}`, `md1-${record.uuid}`);
        }*/
        return (
          <div key={`mdk-${record.uuid}`}>
            {mappers && <p key={`md1-${record.uuid}`}>
              {mappers}
            </p>}
            <>
              <p key={`md2-${record.uuid}`}>
                {ifblank}
                {conditionalMappers}
              </p>
            </>
          </div>
        );
      },
    },
    {
      title: 'Status',
      width: 50,
      dataIndex: 'MappedStatus',
      key: 'MappedStatus',
      filters: [
        {
          text:
            (
              <i>
                <CheckMark color="#52c41a" />
                Mapped
              </i>
            ),
          value: 1,
        },
        {
          text:
            (
              <i>
                <BlockIcon />
                Unmapped
              </i>
            ),
          value: 0,
        },
      ],
      filteredValue: filteredInfo.MappedStatus || null,
      onFilter: (value: any, record: any) => record.MappedStatus.toString().includes(value),
      sorter: (a: any, b: any) => a.MappedStatus.length - b.MappedStatus.length,
      ellipsis: true,
      render: function render(value: any) {
        return value ? (
          <p style={{ color: '#52c41a', margin: '0px' }}>
            <CheckMark color="#52c41a" />
            Mapped
          </p>
        ) : (
          <p style={{ margin: '0px' }}>
            <BlockIcon />
            Unmapped
          </p>
        );
      },
    },
  ];

  const fetchMapping = async (channelNumP: number, channelAccountNumP: number, defaultTemplateNum: number) => {
    try {
      if (defaultTemplateNum === 0) {
        return;
      }
      setLoading(true);
      const pmRes = await fetchProductMapping2(channelNumP, channelAccountNumP, defaultTemplateNum, 1);
      const response = polyfillProductMapping(pmRes);

      /*if (response && Array.isArray(response.SectionKeyFields)) {
        setKeyFields(response.SectionKeyFields);
        setLastKeyFields(response.SectionKeyFields);
        setKeyFieldsReady(true);
      }*/

      setFetchedData({ ...response });
      backUpData = JSON.parse(JSON.stringify(response));
      setSections(response.Sections);
      const tSections = response.Sections;
      let selectedPosition = 0;
      if (selectedSection !== '') {
        selectedPosition = response.Sections.findIndex((s: any) => s.SectionName === selectedSection);
      }
      //console.log('res:', selectedPosition, response);
      //if (response.Sections[selectedPosition]) {
      if (tSections[selectedPosition]) {
        const ds = tSections[selectedPosition].Elements || [];
        const dm = ds.map((e: StringKAnyVPair) => ({uuid: uuid(), ...e}));
        //setData(response.Sections[selectedPosition].Elements);
        //setSelectedSection(response.Sections[selectedPosition].SectionName);
        //setData(tSections[selectedPosition].Elements); //modified on 11.05
        setData(dm);
        //console.log('dm:', dm);
        setSelectedSection(tSections[selectedPosition].SectionName);
        resetSectionKeyFields(tSections[selectedPosition]);
      } else {
        //setData(response.Sections[0].Elements);
        //setSelectedSection(response.Sections[0].SectionName);
        setData(tSections[0].Elements);
        setSelectedSection(tSections[0].SectionName);
        resetSectionKeyFields(tSections[0]);
      }
      console.log('ss ->', selectedSection, ':', selectedPosition);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      actions.setNotification('error', 'No mappings found for this account');
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const helpResponse = await fetchHelp();
      setHelp(helpResponse);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      actions.setNotification('error', `${e}`);
    }
  };

  const handleSave = async () => {
    // if (keyFields.length === 0) {
    //   Modal.warning({
    //     title: 'Key Fields required',
    //     icon: <ExclamationCircleOutlined />,
    //     content: 'Please select key fields',
    //   });
    //   return;
    // }

    try {
      const pd = {
        SectionKeyFields: keyFields,
        Elements: dataToSave,
      };
      setLoading(true);
      setShowEditModal(false);
      //await patchProductMapping(backUpData.ProductMappingNum, dataToSave);
      await patchProductMapping(backUpData.ProductMappingNum, pd, Number(channelAccountNum));
      actions.setNotification('success', 'Mapping Updated');
      setDataToSave([]);
      setLastKeyFields([ ...keyFields ]);
      await fetchData();
      fetchMapping(Number(channelNum), Number(channelAccountNum), productMappingNum);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const isKeyFieldsChanged = () => {
    let ret = keyFields.length !== lastKeyFields.length;

    return ret || lastKeyFields.some(e => keyFields.indexOf(e) < 0);
  };

  // const onKeyFieldsChange = (value: any) => {
  //   setKeyFields(value);

  //   if (dataToSave.length === 0) {
  //     //console.log('ksd->', dataToSave, data);
  //     if (data.length > 0) {
  //       setDataToSave([data[0]]);
  //     }
  //   }
  // };

  const fetchAtts = async () => {
    //const atts = await fetchMappingAttributes();
    const atts = await fetchChannelMappingAttributes(channelNum);
    atts.map((a: any) => attributeHash.set(a.AttributeNumID, a.AttributeName));
    const formattedAtts = atts.reduce((r: any, a: any) => {
      const rCopy = r;
      rCopy[a.GroupBy] = rCopy[a.GroupBy] || [];
      rCopy[a.GroupBy].push(a);
      return rCopy;
    }, Object.create(null));

    setAttributes(formattedAtts);
  };

  const polyfillProductMapping = (pm: any) => {
    const polyfillElement = (ele: StringKAnyVPair) => {
      if (Array.isArray(ele.ObjectElements)) {
        if (ele.ObjectElements.length > 0) {
          ele.ObjectElements.forEach(e => polyfillElement(e));
          ele.children = ele.ObjectElements;
        }

        // maybe this code is dangerous
        delete ele.ObjectElements;
      }
    };

    pm.Sections.forEach((sec: StringKAnyVPair) => {
      sec.Elements.forEach((ele: StringKAnyVPair) => {
        polyfillElement(ele);
      });
    });

    return pm;
  };

  const resetSectionKeyFields = (section: StringKAnyVPair) => {
    if (Array.isArray(section.SectionKeyFields)) {
      setKeyFields(section.SectionKeyFields);
      setLastKeyFields(section.SectionKeyFields);
    }
  };

  const useFetch = (channelNumParam: number, channelAccountNumParam: number, productMappingNumParam: number) => {
    useEffect(() => {
      fetchAtts();
      fetchData();
    }, [channelNumParam, channelAccountNumParam]);
    useEffect(() => {
      fetchMapping(channelNumParam, channelAccountNumParam, productMappingNumParam);
    }, [channelNumParam, channelAccountNumParam, productMappingNumParam]);
  };
  useFetch(Number(channelNum), Number(channelAccountNum), productMappingNum);

  useEffect(() => {
    if (lastSectionKey !== selectedSection) {
      //setSectionKeyFields(selectedSection);
      console.log('ff->', lastSectionKey, selectedSection, fetchedData);
      if (Array.isArray(fetchedData.Sections) && fetchedData.Sections.length > 0) {
        const secs = fetchedData.Sections.filter(e => e.SectionName === selectedSection);

        if (secs.length > 0) {
          resetSectionKeyFields(secs[0]);
          // setKeyFieldsReady(true);
        }

        setLastSectionKey(selectedSection);
      }
    }
  }, [fetchedData, lastSectionKey, selectedSection]);

  return (
    <>
      <Prompt
        when={Boolean(dataToSave.length)}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {showEditModal && <EditMappingModal
        visible={showEditModal}
        channelNum={channelNum}
        channelAccountNum={channelAccountNum}
        formValues={formValues}
        submit={handleModifiedData}
        handleCancel={handleModalClose}
        handleModalChange={handleModalChange}
        attributes={attributes}
        handleClearValues={handleClearValues}
        handleCleanMapping={() => setShowConfirmationModal(true)}
        form={form}
        isMultiple={isMultiple}
      />}
      {showConfirmationModal && <ConfirmationModal
        name={formValues.ElementDisplayName}
        handleCancel={handleConfirmationClose}
        handleConfirm={handleModifiedData}
        visible={true}
        confirmationType="cleaning"
      />}
      {showConfirmationDiscardModal && <ConfirmationModal
        name=""
        handleCancel={handleConfirmationClose}
        handleConfirm={discardChanges}
        visible={true}
        confirmationType="discard"
      />}
      <ConfirmationModal
        name=""
        handleCancel={() => setShowConfirmationDiscardMappingData(false)}
        handleConfirm={handleDiscardMappingData}
        visible={showConfirmationDiscardMappingData}
        confirmationType="discard"
      />
      <ConfirmationModal
        name=""
        handleCancel={() => setShowConfirmationDiscardMappingDataCondition(false)}
        handleConfirm={handleDiscardMappingDataCondition}
        visible={showConfirmationDiscardMappingDataCondition}
        confirmationType="discard"
      />
      <SiteContent>
        <Heading
          title="Product Mapping Template"
          actions={
            (
              <>
                <SaveButton
                  onClick={handleSave}
                  disabled={dataToSave.length <= 0 && !isKeyFieldsChanged()}
                  hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)}
                  type="primary"
                >
                  Save changes
                </SaveButton>
                <DiscardButton
                  onClick={handleDiscard}
                  disabled={dataToSave.length <= 0 && !isKeyFieldsChanged()}
                  hasPermission={hasPermissions(profilesEntity.permissions, Permissions.MANAGE_PRODUCTS, 1)}
                  type="primary"
                  danger
                >
                  Discard changes
                </DiscardButton>
              </>
            )
          }
        />
      </SiteContent>
      <Spacer />
      <SiteContent>
        <Row>
          <Col span={5}>
            <Row>
              <Col span={24}>
                <Input
                  placeholder="Search Categories..."
                  onChange={handleSearchCategoriesInput}
                  prefix={<SearchIcon />}
                />
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col span={24} style={{ height: '900px', overflowY: 'scroll' }}>
                <Menu
                  defaultSelectedKeys={['0']}
                  defaultOpenKeys={['sub1', 'sub2']}
                  mode="inline"
                >
                  <SubMenu key="sub1" title="All Products">
                    {
                      sections.filter(((s: any) => s.SectionFlag === 1)).map((section: any) => (
                        <Menu.Item
                          onClick={() => handleSidebarSelect(section.SectionName)}
                          key={section.SectionName}
                        >
                          {section.SectionDisplayName}
                        </Menu.Item>
                      ))
                    }
                  </SubMenu>
                  <SubMenu key="sub2" title="Specific Product">
                    {
                      sections.filter(((s: any) => s.SectionFlag === 2)).map((section: any) => (
                        <Menu.Item
                          onClick={() => handleSidebarSelect(section.SectionName)}
                          key={section.SectionName}
                        >
                          {section.SectionDisplayName}
                        </Menu.Item>
                      ))
                    }
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </Col>
          <Col span={19} style={{ padding: '0px 30px' }}>
            <Form layout="vertical">
              <Row>
                <Space>
                  
                </Space>
                <Col style={{ paddingRight: '15px' }}>
                  <Form.Item
                    name="status"
                    label={<LabelFilter />}
                  >
                    <Input style={{minWidth: 200, maxWidth: 250}} addonBefore={
                      <SelectPlus
                      defaultValue={0}
                      style={{ width: 100 }}
                    >
                      <Option key={0} value={0}>Contains</Option>
                    </SelectPlus>
                    } onChange={handleChannelFieldSearchText} placeholder="Search text..." />
                  </Form.Item>
                </Col>
                <Col xs={7}>
                  <InputTitle>Mapping Templates</InputTitle>
                  <MappingTemplateSelect2
                    channel={Number(channelNum)}
                    channelAccount={Number(channelAccountNum)}
                    productMappingNum={Number(productMappingNum)}
                    defaultProductMappingNum={defaultProductMappingNum}
                    dropdownMatchSelectWidth={false}
                    placeholder="Mapping Templates"
                    applyTemplate={handleMappingTemplateSelect}
                    value={productMappingNum}
                    // style={{width:'calc(100% - 60px)'}}
                  />
                </Col>
                <Col xs={3}>

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="2">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            handleAutomapping()
                          }}
                        >
                          Map with channel attributes
                        </Button>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setAutoMappingModalVisible(true)
                          }}
                        >
                          Copy from existing template
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button icon={<MapIcon />}  style={{ marginTop: '21px', marginLeft: 16 }}>
                    Auto-Mapping
                  </Button>
                </Dropdown>

                  {/* <Button icon={<MapIcon />} style={{ marginTop: '21px', marginLeft: 16 }} onClick={handleAutomapping}>
                    Auto-Mapping
                  </Button> */}
                </Col>
              </Row>
            </Form>
            <Row>
              <Col span={24}>
                <TableComponent
                  className="productMappingTable"
                  loadingStatus={loading}
                  dataSource={data}
                  expandable={{
                    /*expandIcon: ({expanded, onExpand, record}: {expanded: boolean, onExpand: Function, record: any}) => {
                      return expanded ?
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} /> :
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />;
                    },*/
                    indentSize: 30,
                  }}
                  columns={columns}
                  onChange={handleTableChange}
                  pagination={{
                    total: data.length,
                    pageSize: data.length,
                    hideOnSinglePage: true,
                  }}
                  //rowKey="ElementName"
                  rowKey="uuid"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {
          autoMappingModalVisible && (
    <Modal
      visible={true}
      title="Mapping Template"
      onCancel={()=> {
        setAutoMappingModalVisible(false)
        form1.resetFields();
      }}
      width={700}
      footer={[
        <Button disabled={loading1} onClick={()=>{
          setAutoMappingModalVisible(false)
          form1.resetFields();
        }}>
          Close
        </Button>,
        <Button type="primary" loading={loading1} onClick={()=>handleMappingFromExistTemplate()}>
          Transfer
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          sourceMappingNum: undefined,
          targetMappingNum: productMappingNum
        }}
        form={form1}
      >
        <Form.Item
          label="Existing Template"
          name="sourceMappingNum"
          rules={[{ required: true, message: 'Existing Template is required.' }]}
        >
          <MappingTemplateSelect2
            channel={Number(channelNum)}
            channelAccount={Number(channelAccountNum)}
            productMappingNum={Number(productMappingNum)}
            defaultProductMappingNum={0}
            dropdownMatchSelectWidth={false}
            placeholder="Mapping Templates"
            applyTemplate={()=>{}}
            value={undefined}
            withOut={productMappingNum}
           />
        </Form.Item>
        <Form.Item>
          <Typography.Text type='danger'>Transfer Mapped Attributes To</Typography.Text>
        </Form.Item>
        <Form.Item label="Target Template">
          <Space size="small">
            {/* <Tag color="volcano">{currentTemplate?.name ? 'Standard': 'Catalog'}</Tag> */}
            <Typography.Text type="secondary" strong>{currentTemplate?.title}</Typography.Text>
          </Space>
        </Form.Item>
        <Form.Item noStyle name="targetMappingNum">
          <Input type="hidden" />
        </Form.Item>
      </Form>

    </Modal>)}
        
      </SiteContent>
    </>
  );
}

export default ProductMapping;
