import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { Actions, ProductDetailScreenContext } from '../context';
import { AccListWrapper, ProductTabContainer } from '../styledComponents';
import FormItemEnabler from '../../ProductBulkUpdate/FormItemEnabler';
import FormElement, { Type } from '../../../components/common/FormElement';
import { useDebounce } from '../../../util';

interface ChannelInvTabProps {
  channelsInventory: Array<object>;
  editMode: boolean;
  setFilter: any;
  channelInvForm: FormInstance,
  attributesChannels: Array<Entities.AttributesChannels>,
  channelsInvDCs: Array<object>,
  enabler: boolean,
  onCheckChange?: Function,
}

const Filters: React.FC<{ setFilter: any, channelInvForm: FormInstance, attributesChannels: Array<Entities.AttributesChannels>, channelsInvDCs: Array<object> }> = ({
  setFilter, channelInvForm, attributesChannels = [], channelsInvDCs = [],
}) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  useEffect(setFilter, [debouncedQuery, setFilter]);
  return (
    <ProductTabContainer>
      <Row style={{ backgroundColor: '#e3e2e2', padding: '15px' }}>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ marginBottom: '45px' }}>
              <h3 style={{ marginBottom: '2px' }}>Filters</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
              <Form
                layout="inline"
                size="large"
                form={channelInvForm}
              >
                <Form.Item
                  name="Filter"
                >
                  <Input.Search
                    style={{ width: 200, marginRight: '5px', borderBottom: 'solid 1px #a7acb4', borderRadius: '0px' }}
                    name="Filter"
                    size="large"
                    placeholder="Code"
                    onChange={(e: any) => setQuery(e.target.value)}
                    bordered={false}
                    allowClear
                    value={query}
                  />
                </Form.Item>
                <Form.Item
                  name="Channel"
                >
                  <Select
                    placeholder="Channel"
                    onChange={setFilter}
                    showSearch
                    allowClear
                    style={{ width: 200, marginRight: '5px' }}
                  >
                    {attributesChannels?.map((c: any) => (
                      <Select.Option key={c.channelNum} value={c.channelNum}>{c.channelName}</Select.Option>))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="DistributionCenter"
                >
                  <Select
                    placeholder="Distribution Center"
                    showSearch
                    allowClear
                    onChange={setFilter}
                    style={{ width: 200, marginRight: '5px' }}
                  >
                    {channelsInvDCs?.map((dc: any) => (
                      <Select.Option
                        key={dc.distributionCenterNum}
                        value={dc.distributionCenterNum}
                      >
                        {dc.distributionCenterName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </ProductTabContainer>
  );
};

export const ChannelInvTab: React.FC<ChannelInvTabProps> = ({ channelsInventory = [], editMode, setFilter, channelInvForm, attributesChannels = [], channelsInvDCs = [], enabler, onCheckChange }) => {
  const ChannelInvInput = enabler ? FormItemEnabler : FormElement;
  return (
    <>
      <Filters
        setFilter={setFilter}
        attributesChannels={attributesChannels}
        channelsInvDCs={channelsInvDCs}
        channelInvForm={channelInvForm}
      />
      {channelsInventory?.map((c: any, channelsInvIndex: number ) => (
        <div key={`${Math.random()}_${Math.random()}`} style={{ display: c.display ? '' : 'none' }}>
          <Row>
            <Col span={24} style={{ marginTop: '45px' }}>
              <h3 style={{ marginBottom: '2px', borderBottom: 'solid 1px #016dff' }}>{c.channelName}</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ marginBottom: '20px', paddingLeft: '15px' }}>
              {c.channelAccountList.map((i: any, channelAccountListIndex: number) => (
                <AccListWrapper key={`${Math.random()}_${Math.random()}`}>
                  <Row>
                    <Col span={24} style={{ marginTop: '25px', marginBottom: '15px' }}>
                      <h3 style={{ marginBottom: '2px', borderBottom: 'solid 1px #016dff' }}>{i.channelAccountName}</h3>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '15px' }}>
                    <Col span={6}>
                      <b>Distribution Center</b>
                    </Col>
                    <Col span={6}>
                      <b>Code</b>
                    </Col>
                    <Col span={6}>
                      <b>Type</b>
                    </Col>
                    <Col span={6}>
                      <b>Quantity</b>
                    </Col>
                  </Row>
                  {
                    i.distributionList.length > 0 ? i.distributionList.map((dl: any, distributionListIndex: number) => (
                      <Row key={`${dl.distributionCenterNum}_${Math.random()}`} style={{ display: dl.display ? '' : 'none' }}>
                        <Col span={6}>
                          {dl.distributionCenterName}
                        </Col>
                        <Col span={6}>
                          {dl.distributionCenterCode}
                        </Col>
                        <Col span={6}>
                          Warehouse
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'channelAccountNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'indexNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'distributionCenterNum']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'display']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <ChannelInvInput
                            inputProperties={{
                              disabled: !editMode,
                            }}
                            inputType={Type.NUMBER}
                            formItemProperties={{
                              name: ['channelsInv', channelsInvIndex, 'channelAccountList', channelAccountListIndex, 'distributionList', distributionListIndex, 'qty'],
                            }}
                            onCheckChange={onCheckChange}
                          />
                        </Col>
                      </Row>
                    )) : <h3>No Distribution Centers found</h3>
                  }
                </AccListWrapper>
              ))}
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

const ChannelInv: React.FC = () => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  const { channelInvForm = null, basicInfoForm = null } = state || {};

  const setFilter = useCallback(() => {
    if (!dispatch) {
      return;
    }

    const filterValues = channelInvForm?.getFieldsValue();
    let newChannelsInv = basicInfoForm?.getFieldValue('channelsInv');

    if (filterValues?.Channel !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) =>({
        ...channel,
        display: channel.platFormNum === 0 ? channel.channelNum === filterValues?.Channel : channel.platFormNum === filterValues?.Channel,
      }));
    } else {
      newChannelsInv = newChannelsInv?.map((channel: any) =>({
        ...channel,
        display: true,
      }));
    }

    if (filterValues?.DistributionCenter !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: dl.distributionCenterNum === filterValues?.DistributionCenter,
          })),
        })),
      }));
    }

    if (filterValues?.Filter !== '' && filterValues?.Filter !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: dl.distributionCenterCode ? dl.distributionCenterCode.toString().toLowerCase().includes(filterValues?.Filter?.toString()?.toLowerCase()) : false,
          })),
        })),
      }));
    }

    if ((filterValues?.Filter === '' || filterValues?.Filter === undefined) && filterValues?.DistributionCenter === undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: true,
          })),
        })),
      }));
    }

    dispatch({ type: Actions.FILTER_CHANNELSINV, params: newChannelsInv });
  }, [basicInfoForm, channelInvForm, dispatch]);

  if (!state || !dispatch) {
    return null;
  }

  const channelsInv = basicInfoForm?.getFieldValue('channelsInv') || [];

  if (channelsInv?.length === 0) {
    return (
      <>
        <h3 style={{ marginBottom: '2px' }}>Channel Inventory not enabled on this profile! Contact administrator if assistance required!</h3>
      </>
    );
  }
  return (
    <ChannelInvTab
      channelsInventory={channelsInv}
      editMode={state.editMode}
      setFilter={setFilter}
      channelsInvDCs={state.channelsInvDCs}
      channelInvForm={state.channelInvForm}
      attributesChannels={state.attributesChannels}
      enabler={false}
    />
  );
};


export default ChannelInv;
