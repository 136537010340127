import React, { useCallback, useState } from 'react';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { Form, Select, Upload, message, Space, Button, notification } from 'antd';
import {downloadContentResourceTemplate} from '../../../services/imports'
import {
  downloadFile
} from '../../../util/files';
const validExcelMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

type Props = {
  maskScreen: Function;
  fileList: any[];
  setFileList: (data: any[]) => void;
  operation: string;
  setOperation: (data: string) => void;
  setDownloadType:(data:string) => void;
  downloadType: string;
};

const SourceSelector = (props: Props) => {
  const { fileList, setFileList, operation, downloadType, setDownloadType } = props;
  const [loadingTemplate, setLoadingTemplate] = useState(false)

  const [form] = Form.useForm();

  const downloadTemplate = useCallback(async () => {

    let template;
    try {
      setLoadingTemplate(true);
      const res = await downloadContentResourceTemplate(downloadType);
      template = res
    } catch (e) {
      setLoadingTemplate(false);
    } finally {
      setLoadingTemplate(false);
    }

    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }
    
    downloadFile(
      'content-resource-template.xlsx',
      template,
    );

  }, [downloadType]);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      form.setFieldsValue({ fileSelected: false });

      if (validExcelMimeTypes.indexOf(file.type) < 0) {
        message.error(`${file.name} is not a spreadsheet file`).then();
      } else {
        form.setFieldsValue({ fileSelected: true });
        setFileList([file]);
      }

      return false;
    },
    [form, setFileList],
  );

  const onSelectDownloadType = (value: any) => {
    if (typeof value === 'string') {
      setDownloadType(value);
    }
  };

  const downloadOptions = () => {
    const options = [
      { label: 'Style', value: 'style' },
      { label: 'Substyle', value: 'substyle' },
      { label: 'Product', value: 'product' },
    ];

    return options.map((e) => (
      <Select.Option key={e.value} value={e.value}>
        {e.label}
      </Select.Option>
    ));
  };

  return (
    <>
      <div className="dest-step-wrapper">
        <Form form={form} style={{ marginTop: 16, width: 400 }}>
          <Form.Item label="Template Type:">
            <Space>
              {/* <Select style={{ width: 250 }} value={operation} onChange={setOperation}>
                <Select.Option value="5100">Content Resources</Select.Option>
              </Select> */}
              <Select
                onChange={onSelectDownloadType}
                placeholder="Download Option"
                style={{ width: 150 }}
                value={downloadType}
              >
                {downloadOptions()}
              </Select>
              {operation==='5100' && <Button type='primary' loading={loadingTemplate} icon={<DownloadOutlined />} disabled={!operation} onClick={()=>downloadTemplate()}>Download Template</Button>}
            </Space>
          </Form.Item>
          <Form.Item
            name="fileSelected"
            rules={[{ required: true, message: 'Please add a file.' }]}
          >
            <Upload.Dragger
              fileList={fileList}
              name="template"
              multiple={false}
              beforeUpload={beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag a file to this area to upload</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SourceSelector;
