import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Modal, Col, Switch } from "antd";
import moment from "moment";
import { downloadXlsxFromCsv } from "../../../../util/files";

type ExportProps = {
  selectedRows: Array<any>;
  data: Array<any>;
  columns: Array<StringKAnyVPair>;
  fileName: string;
  filterArr: string[];
  onClose: () => void;
  formatColumns?: any;
};
export default function ExportCSV(props: ExportProps): JSX.Element {
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const {
    columns,
    data,
    selectedRows,
    onClose,
    fileName,
    filterArr,
    formatColumns,
  } = props;
  const [exportCsv, setExportCsv] = useState(false);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onClose();
  }, [onClose]);
  /**
   * handle download event
   */
  const handleDownload = useCallback(
    (downloadData: Array<any>, filterArr: string[], fileName: string) => {
      const headers = columns
        .map(
          (item) =>
            item.header.replace("\n", "").replace("\r", "") ||
            item.title.replace("\n", "").replace("\r", "")
        )
        .filter((i) => filterArr.indexOf(i) < 0);
      const lines = [] as any;
      lines.push(
        headers
          .map((headerItem) => {
            let formattedHeader = headerItem;
            if (typeof headerItem === "string") {
              if (headerItem.includes(",")) {
                if (headerItem.includes('"')) {
                  formattedHeader = headerItem.replace(/"/g, '""');
                }
                formattedHeader = `"${headerItem}"`;
              }
            } else {
              formattedHeader = "";
            }
            return formattedHeader;
          })
          .join(",")
      );
      downloadData.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          value =
            formatColumns && formatColumns[dataIndex]
              ? formatColumns[dataIndex][`${row[dataIndex]}`]
              : row[dataIndex];
          if (typeof value === "string") {
            // replace \n with \\n,
            value = value.replaceAll("\n", "\\n");
            if (value.includes(",")) {
              if (value.includes('"')) {
                value = value.replaceAll(/"/g, '""');
              }
              value = `"${value}"`;
            }
          }
          items.push(value);
        });
        lines.push(items.join(","));
      });
      const csvString = lines.join("\n");
      const fileNameString = `${fileName}_${moment().format("YYYYMMDD")}`;
      if (!exportCsv) {
        // export as xlsx
        downloadXlsxFromCsv(csvString, fileNameString);
        return;
      }
      const blob = new Blob([csvString], { type: "application/csv" });
      const name = `${fileNameString}.csv`;
      if ("download" in document.createElement("a")) {
        // No IE Download
        const elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // free url
        document.body.removeChild(elink);
      } else {
        // IE10+ download
        //navigator.msSaveBlob(blob, name);
      }
    },
    [columns, exportCsv, selectedColumns, formatColumns]
  );

  useEffect(() => {
    const checkedList: Array<string> = [];
    columns.forEach((item) => {
      if (
        filterArr.indexOf(
          item.header.replace("\n", "").replace("\r", "") ||
            item.title.replace("\n", "").replace("\r", "")
        ) < 0
      ) {
        checkedList.push(item.dataIndex || item.name);
      }
    });
    setSelectedColumns(checkedList);
  }, [columns, filterArr]);

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={handleHide}>Close</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Export Control Tower Fields"
      centered
      visible
      width={700}
      destroyOnClose
      onCancel={handleHide}
      footer={<Footer />}
    >
      <Col style={{ padding: "5px" }}>
        <h5>Export As</h5>
        <Switch
          checked={exportCsv}
          onChange={setExportCsv}
          checkedChildren="CSV"
          unCheckedChildren="XLSX"
          style={{
            backgroundColor: "#006dff",
          }}
        />
      </Col>
      <Row justify="center" style={{ marginTop: 10, gap: 15 }}>
        <Button
          type="primary"
          onClick={() => handleDownload(selectedRows, filterArr, fileName)}
          disabled={selectedRows.length === 0}
        >
          Export Filter Result
        </Button>
        <Button
          type="primary"
          onClick={() => handleDownload(data, filterArr, fileName)}
        >
          Export All
        </Button>
      </Row>
    </Modal>
  );
}
