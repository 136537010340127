/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Dropdown,
  Menu,
  Row,
  notification,
} from 'antd';
import {
  MenuOutlined,
} from '@ant-design/icons';
import theme from '../../assets/styles/theme';
import LoadingIcon from '../../components/common/LoadingIcon';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
import Products from '../../services/products';


interface RefType {
  current: {
    reset?: () => void;
  };
};

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props {
  product: StringKAnyVPair;
  setDetailDialogTitleType: any;
  detailDialogTitleType: string;
  isSwitching: boolean;
  setIsSwitching: any;
  innerRef: RefType;
  currentProduct: any;
  setCurrentProduct: any;
  currentProductId: any;
  setCurrentProductId: any;
};

const getRealProductId = (ids: string) => {
  //console.log('ids ->', ids);
  const fs = ids.split('/').map(e => e.trim()).filter(e => e);

  return fs[fs.length - 1] || '';
};
/**
 * use ref to force reset on close
 * @param props 
 * @returns 
 */
const ProductDetailTitle = (props: Props) => {
  const {
    product,
    // setDetailDialogTitleType,
    detailDialogTitleType,
    isSwitching,
    setIsSwitching,
    currentProduct,
    setCurrentProduct,
    // currentProductId,
    setCurrentProductId,
    innerRef,
  } = props;
  const {
    // ProductId,
    styleCode: propStyleCode,
  } = product;
  const [isLoadingStyleChildren, setIsLoadingStyleChildren] = useState(false);
  const [currentStyleCode, setCurrentStyleCode] = useState<string>();
  const [styleMenuItems, setStyleMenuItems] = useState<StringKAnyVPair[]>([]);

  // eslint-disable-next-line
  const getStyleChildren = async (
    styleCode: string,
  ) => {
    const menu = getTitleStyleMenuItemBySku(styleCode);

    //console.log('found menu ->', menu);
    if (menu) {
      menu.isLoading = true;
      setStyleMenuItems([...styleMenuItems]);
    } else {
      setIsLoadingStyleChildren(true);
      //setStyleMenuItems([]);
    }

    try {
      const children: StringKAnyVPair[] = [];
      const res = await Products.getStyleMasterChildren(styleCode, {withFullList: true});

      if (Array.isArray(res)) {
        for (let i = 0; i < res.length; i++) {
          const e = res[i];
          const childLoaded = Array.isArray(e.subLevelSKUList) || e.productType === 2;
          const childNodes = Array.isArray(e.subLevelSKUList) ? e.subLevelSKUList : undefined;

          children.push({
            ...e,
            children : childNodes,
            expanded: false,
            isLoading: false,
            childLoaded,
          });
        }

        console.log('children ->', children);
        if (menu) {
          menu.childLoaded = true;
          menu.chldren = children;
          menu.isLoading = false;
          console.log('sm s --->', styleMenuItems);
          setStyleMenuItems([...styleMenuItems]);
        } else {
          setStyleMenuItems(children);
        }
      }
    } catch (e) {
      notification.error({
        message: `Fetch children of Style Master error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      if (!menu) {
        setIsLoadingStyleChildren(false);
      }
    }
  };

  const getTitleStyleDropdownMenu = () => {
    return (
      <Menu
        onClick={openStyleItemDetailDialog}
      >
        {styleMenuItems.map(e => (
          e.productType === 3 ?
          titleSubMenu(e) :
          <Menu.Item key={e.productId}>
            <Row align="middle" justify="space-between">
              {e.sku}
              {e.isLoading && <LoadingIcon size="mini" />}
            </Row>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const getTitleStyleMenuItemById = (productId: string) => {
    const findItem = (
      items: StringKAnyVPair[],
    ): null | StringKAnyVPair => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].productId === productId) {
          return items[i];
        }
        if (Array.isArray(items[i].children) && items[i].children.length > 0) {
          const res = findItem(items[i].children);

          if (res) return res;
        }
      }

      return null;
    };

    return findItem(styleMenuItems);
  };

  const getTitleStyleMenuItemBySku = (sku: string) => {
    const findItem = (
      items: StringKAnyVPair[],
    ): null | StringKAnyVPair => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].sku === sku) {
          return items[i];
        }
        if (Array.isArray(items[i].children) && items[i].children.length > 0) {
          const res = findItem(items[i].children);

          if (res) return res;
        }
      }

      return null;
    };

    return findItem(styleMenuItems);
  };

  const onTitleStyleSubMenuClick = async (evt: any) => {
    const menu = getTitleStyleMenuItemBySku(evt.key);

    //console.log('menu ->', evt.key, menu);
    //if (!menu) console.log('-->', styleMenuItems);
    if (!menu) return;

    if (menu.childLoaded) {
      //closeProductDetailTitle();
      //props.onClose()
      setIsSwitching(true);
      setTimeout(() => {
        const pid = getRealProductId(menu.productId);

        setCurrentProductId(pid);
        setCurrentProduct({
          ProductId: pid,
          ProductType: menu.productType,
          SKU: menu?.sku || '',
        });
        setIsSwitching(false);
        //setDetailVisible(true);
        //props.setDialogVisible(true);
      }, 50);
      return;
    }
    menu.isLoading = true;
    setStyleMenuItems([...styleMenuItems]);

    try {
      const res = await Products.getStyleMasterChildren(evt.key);

      if (Array.isArray(res)) {
        menu.childLoaded = true;
        menu.children = res.map(e => ({
          ...e,
          childLoaded: false,
          isLoading: false,
        }));
      }
    } catch (e) {
      notification.error({
        message: `Fetch children of Style Menu error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      menu.isLoading = false;
      setStyleMenuItems([...styleMenuItems]);
    }
  };

  const openStyleItemDetailDialog = async (evt: any) => {
    const menu = getTitleStyleMenuItemById(evt.key);
    let menuReady = true;
    console.log('open', evt, menu);
    if (menu) {
      if (menu.productType !== 1) {
        if (!menu.childLoaded) {
          menuReady = false;
          //evt.domEvent.preventDefault();
          //evt.domEvent.stopPropagation();
          await getStyleChildren(menu.sku);
        }
      }
    }

    if (menuReady) {
      const pid = getRealProductId(menu?.productId || '');
      //closeProductDetailDialog();
      //props.onClose();
      setIsSwitching(true);
      setCurrentProductId(getRealProductId(evt.key));
      setCurrentProduct({
        ProductId: pid,
        ProductType: menu?.productType,
        SKU: menu?.sku || '',
      });
      setTimeout(() => {
        setIsSwitching(false);
        //setDetailVisible(true);
        //props.setDialogVisible(true);
      }, 50);
    }
  };

  const titleSubMenu = (menu: StringKAnyVPair) => {
    return (
      <Menu.SubMenu
        key={menu.sku}
        icon={menu.isLoading && <LoadingIcon size="mini" />}
        onTitleClick={onTitleStyleSubMenuClick}
        popupOffset={[-3, -3]}
        title={menu.sku}
      >
        {Array.isArray(menu.children) && menu.children.map(e => {
          return (
            e.productType === 1 ?
          <Menu.Item key={e.productId}>
            <Row align="middle" justify="space-between">
              {e.sku}
              {e.isLoading && <LoadingIcon size="mini" />}
            </Row>
          </Menu.Item> :
          titleSubMenu(e)
          );
        })}
      </Menu.SubMenu>
    );
  };

  React.useEffect(() => {
    if (currentStyleCode !== propStyleCode) {
      setCurrentStyleCode(propStyleCode);
      setStyleMenuItems([]);
      console.log('sc ----->', product);

      if (propStyleCode) {
        getStyleChildren(propStyleCode);
      }
    }
    innerRef.current = {
      reset: () => {
        setCurrentProduct({});
        setCurrentProductId('');
        setCurrentStyleCode(undefined);
        setStyleMenuItems([]);
      },
    };
  }, [currentStyleCode, setStyleMenuItems, getStyleChildren, propStyleCode, product, setCurrentStyleCode, innerRef, setCurrentProduct, setCurrentProductId]);

  return (
    <>
    {isLoadingStyleChildren || isSwitching ?
      <LoadingIcon /> :
      (styleMenuItems.length > 0 ?
        <Dropdown
          overlay={getTitleStyleDropdownMenu()}
        >
          <span>
            <MenuOutlined />
            &nbsp;&nbsp;
            <span style={{color: theme['@primary-color']}}>
              {detailDialogTitleType}
              {currentProduct.SKU || currentProduct.ProductTitle}
            </span>
          </span>
        </Dropdown> :
        <span>
          {detailDialogTitleType}
          {currentProduct.SKU || currentProduct.ProductTitle}
        </span>
      )}
    </>
  );
};

export default ProductDetailTitle;
