import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input, InputNumber,
  Modal, Pagination,
  Row,
  Select,
  Tabs,
} from 'antd';
import { PictureOutlined } from '@ant-design/icons/lib';
import { useActiveTabOnQuery, useFilterPagination } from '../../../util/hooks';
import { FormWrapper, Title } from '../../OrderDetails/styledComponents';
import TableComponent from '../../../components/TableComponent';
import { sumObjectArrayValues, toMoney } from '../../../util/strings';
import SalesOrderDetailsActions from '../../../redux/actions/salesOrderDetails';
import { GetShippingProductsParams, useGetShippingProductsQuery } from '../../../redux/api/shippingProducts';
import ProductSearch from '../../../components/common/ProductSearch';
import { objectToArray } from '../../../util';
import { calculateTotals, FormsContext } from '../common';
import { InfoValue } from '../../OrderDetails/common';


const TotalDivider = styled.div`
  border-bottom: solid #2d3f5d 1px;
  width: 100%;
  height: 1px;
`;

const InputNumberComponent = styled(InputNumber)`
  width: 100%;
`;

const ContactDetailsForm: React.FC<{ name: string, form: FormInstance }> = ({ name, form }) => {
  const editMode = useSelector((state: any) => state.salesOrderDetails.editMode);
  return (
    <FormWrapper>
      <Row>
        <Col span={24}>
          <Form
            name={name}
            form={form}
            labelCol={{
              sm: { span: 12 },
              lg: { span: 7 },
              xl: { span: 4 },
            }}
            wrapperCol={{
              sm: { span: 13 },
              lg: { span: 12 },
              xl: { span: 20 },
            }}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
                <Form.Item
                  name="company"
                  label="Company"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
                <Form.Item
                  name="address1"
                  label="Address"
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
                <Form.Item
                  name="address2"
                  wrapperCol={{
                    sm: { span: 13 },
                    lg: { span: 12 },
                    xl: { span: 20, offset: 4 },
                  }}
                >
                  <Input type="text" disabled={!editMode} />
                </Form.Item>
                <Form.Item
                  style={{ margin: 'auto' }}
                  wrapperCol={{
                    xl: { span: 24 },
                  }}
                >
                  <Form.Item
                    name="city"
                    label="City"
                    style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
                  >
                    <Input type="text" disabled={!editMode} />
                  </Form.Item>
                  <Form.Item
                    name="state"
                    label="State"
                    style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
                  >
                    <Input type="text" disabled={!editMode} />
                  </Form.Item>
                  <Form.Item
                    name="postalCode"
                    label="Zip Code"
                    style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
                  >
                    <Input type="text" disabled={!editMode} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  style={{ margin: 'auto' }}
                  wrapperCol={{
                    xl: { span: 24 },
                  }}
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    style={{ display: 'inline-block', width: '50%', paddingLeft: '10px' }}
                  >
                    <Input type="text" disabled={!editMode} />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    style={{ display: 'inline-block', width: '50%', paddingLeft: '10px' }}
                  >
                    <Input type="text" disabled={!editMode} />
                  </Form.Item>
                </Form.Item>
              </Col>
              {name === 'shipping' && (
                <Col span={12}>
                  <Form.Item
                    name="RequestedShippingCarrier"
                    label="Requested Shipping Carrier"
                    style={{ width: '100%', paddingLeft: '10px' }}
                    labelCol={{
                      sm: { span: 12 },
                      lg: { span: 7 },
                      xl: { span: 9 },
                    }}
                    wrapperCol={{
                      sm: { span: 13 },
                      lg: { span: 12 },
                      xl: { span: 15 },
                    }}
                  >
                    <Select
                      placeholder="Select a carrier"
                      style={{ width: '100%' }}
                      disabled={!editMode}
                    >
                      <Select.Option value={0}>Carrier 1</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="MappedShippingCarrier"
                    label="Mapped Shipping Carrier"
                    style={{ width: '100%', paddingLeft: '10px' }}
                    labelCol={{
                      sm: { span: 12 },
                      lg: { span: 7 },
                      xl: { span: 9 },
                    }}
                    wrapperCol={{
                      sm: { span: 13 },
                      lg: { span: 12 },
                      xl: { span: 15 },
                    }}
                  >
                    <Select
                      placeholder="Select a carrier"
                      style={{ width: '100%' }}
                      disabled={!editMode}
                    >
                      <Select.Option value={0}>Carrier 1</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="ShippingService"
                    label="Shipping Service"
                    style={{ width: '100%', paddingLeft: '10px' }}
                    labelCol={{
                      sm: { span: 12 },
                      lg: { span: 7 },
                      xl: { span: 9 },
                    }}
                    wrapperCol={{
                      sm: { span: 13 },
                      lg: { span: 12 },
                      xl: { span: 15 },
                    }}
                  >
                    <Select
                      placeholder="Select a Shipping"
                      style={{ width: '100%' }}
                      disabled={!editMode}
                    >
                      <Select.Option value={0}>Shipping 1</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </FormWrapper>
  );
};

const initialData = { count: 0, products: [] };

const columnsAddProductModal = [
  {
    header: 'SKU',
    name: 'sKU',
    defaultFlex: 3,
  },
  {
    header: 'Title',
    name: 'productTitle',
    defaultFlex: 3,
  },
  {
    header: 'UPC',
    name: 'uPC',
    defaultFlex: 2,
  },
];

const searchFieldsAddProductModal = [
  'sKU',
  'productTitle',
  'uPC',
];

const AddProductModal: React.FC = () => {
  const openProductsModal = useSelector((state: any) => state.salesOrderDetails.openProductsModal);
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  const detailsProducts = useSelector((state: any) => state.salesOrderDetails.detailsProducts);
  const [form] = Form.useForm();
  const forms = useContext(FormsContext);
  const { detailsForm, shippingProductsTableForm } = forms;
  const [
    pagination,
    filters,
    updatePagination,
    updateFilters,
    updateTotalCount,
  ] = useFilterPagination<GetShippingProductsParams>();
  const { data = initialData, isFetching } = useGetShippingProductsQuery(filters);
  const { products, count } = data;

  useEffect(() => {
    updateTotalCount(count);
  }, [count, updateTotalCount]);

  if (!detailsForm || !shippingProductsTableForm) {
    return null;
  }

  const header = (
    <Form form={form} onFinish={(dataf: any) => {updateFilters({ $filter: dataf });}}>
      <Form.Item
        name="sku"
        style={{ display: 'inline-block', paddingLeft: '10px' }}
      >
        <Input
          type="text"
          placeholder="SKU"
          name="sku"
        />
      </Form.Item>
      <Form.Item
        name="productTitle"
        style={{ display: 'inline-block', paddingLeft: '10px' }}
      >
        <Input
          type="text"
          placeholder="Title"
          name="productTitle"
        />
      </Form.Item>
      <Form.Item
        name="brand"
        style={{ display: 'inline-block', paddingLeft: '10px' }}
      >
        <Input
          type="text"
          placeholder="Brand"
          name="brand"
        />
      </Form.Item>
      <Form.Item
        style={{ display: 'inline-block', paddingLeft: '10px' }}
      >
        <Button type="primary" htmlType="submit">Search</Button>
      </Form.Item>
    </Form>
  );

  const addProducts = (selected: any) => {
    const newProducts = objectToArray(selected).map((p: any) => ({
      imageUrl: '',
      sku: p.sKU,
      description: p.productTitle,
      supplier: '',
      warehouseCode: p.warehouseCode,
      shipPack: 0,
      orderQty: 1,
      price: p.price,
      discountAmount: 0,
      taxAmount: 0,
      salesOrderItemstatus: 0,
      itemTotalAmount: p.price,
      inventoryUuid: p.inventoryUuid,
    }));
    salesOrderDetailsActions.setDetailsProducts([
      ...detailsProducts,
      ...newProducts,
    ]);
    shippingProductsTableForm?.setFieldsValue({
      ShippingProducts: [
        ...detailsProducts,
        ...newProducts,
      ],
    });
    salesOrderDetailsActions.setOpenProductsModal(false);
    salesOrderDetailsActions.setIsFormUpdated(true);

    const shippingProductsTable = shippingProductsTableForm.getFieldValue('ShippingProducts');
    const itemsRecalculated = shippingProductsTable.map((s: any) => {
      const total = (s.price * s.orderQty) - s.discountAmount;
      return {
        ...s,
        itemTotalAmount: total < 0 ? 0 : total,
      };
    });
    const subTotalAmount = sumObjectArrayValues(itemsRecalculated, 'itemTotalAmount');
    const taxAmount = sumObjectArrayValues(itemsRecalculated, 'taxAmount');
    calculateTotals(detailsForm.getFieldValue('discount'), detailsForm.getFieldValue('shippingAmount'), subTotalAmount, detailsForm.getFieldValue('discountType'), taxAmount, detailsForm.getFieldValue('miscAmount'), dispatch);
  };
  return (
    <Modal
      visible={openProductsModal}
      style={{ top: 30 }}
      onCancel={() => salesOrderDetailsActions.setOpenProductsModal(false)}
      footer={null}
      width={900}
    >
      <ProductSearch
        loading={isFetching}
        data={products || []}
        header={header}
        columns={columnsAddProductModal}
        onApply={addProducts}
        footer={(
          <Pagination
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...pagination}
            onChange={(page: number, pageSize: number | undefined) => {
              updatePagination({ current: page, pageSize });
            }}
          />
        )}
        defaultSearch={false}
        searchFields={searchFieldsAddProductModal}
        tableIdProperty="inventoryUuid"
        searchReference="inventoryUuid"
      />
    </Modal>
  );
};


const QuantityCell: React.FC<{ record: any, index: number }>  = ({ index, record }) => {
  const editMode = useSelector((state: any) => state.salesOrderDetails.editMode);
  const detailsProducts = useSelector((state: any) => state.salesOrderDetails.detailsProducts);
  const forms = useContext(FormsContext);
  const { shippingProductsTableForm } = forms;
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);

  const setQty = useCallback((value: string) => {
    if (!shippingProductsTableForm){
      return null;
    }
    const indexProduct = detailsProducts.findIndex((f: any) => f.inventoryUuid === record.inventoryUuid);
    const newDetailsProducts = [...detailsProducts];
    const total = (Number(newDetailsProducts[indexProduct].price) * Number(value)) - Number(newDetailsProducts[indexProduct].discountAmount);
    newDetailsProducts[indexProduct] = {
      ...newDetailsProducts[indexProduct],
      orderQty: value,
      itemTotalAmount: total < 0 ? 0 : total,
    };

    salesOrderDetailsActions.setDetailsProducts([
      ...newDetailsProducts,
    ]);

    shippingProductsTableForm?.setFieldsValue({
      ...shippingProductsTableForm?.getFieldsValue(),
      ShippingForm: [
        ...newDetailsProducts,
      ],
    });

    return null;

  }, [detailsProducts, salesOrderDetailsActions, shippingProductsTableForm, record]);


  return (
    <Form.Item name={['ShippingProducts', index, 'orderQty']}>
      <InputNumber
        disabled={!editMode}
        min="1"
        onChange={setQty}
      />
    </Form.Item>
  );
};

const ShippingProductsCells: React.FC<{ record: any, index: number }> = ({ index, record }) => (
  <>
    {record.sku}
    <Form.Item
      name={['ShippingProducts', index, 'imageUrl']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'sku']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'description']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'warehouseCode']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'shipPack']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'price']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'discountAmount']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'taxAmount']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'salesOrderItemstatus']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'itemTotalAmount']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['ShippingProducts', index, 'supplier']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
  </>
);

const columnsCustomerModal = [
  {
    header: 'Name',
    name: 'customerName',
    defaultFlex: 3,
  },
  {
    header: 'Code',
    name: 'customerCode',
    defaultFlex: 2,
  },
  {
    header: 'Contact',
    name: 'contact',
    defaultFlex: 2,
  },
  {
    header: 'Email',
    name: 'email',
    defaultFlex: 2,
  },
  {
    header: 'Phone',
    name: 'phone1',
    defaultFlex: 2,
  },

];

const searchFieldsCustomerModal = [
  'customerName',
  'customerCode',
  'contact',
  'email',
  'phone1',
];

const CustomerModal: React.FC = () => {
  const openCustomerModal = useSelector((state: any) => state.salesOrderDetails.openCustomerModal);
  const customers = useSelector((state: any) => state.salesOrderDetails.customers);
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);
  const forms = useContext(FormsContext);
  const { detailsForm } = forms;

  if (!detailsForm) {
    return null;
  }

  const setCustomer = (selected: any) => {
    detailsForm.setFieldsValue({
      ...detailsForm.getFieldsValue(),
      customerName: selected[Object.keys(selected)[0]].customerName,
      customerCode: selected[Object.keys(selected)[0]].customerCode,
    });
    salesOrderDetailsActions.setOpenCustomerModal(false);
    salesOrderDetailsActions.setIsFormUpdated(true);
  };

  return (
    <Modal
      visible={openCustomerModal}
      style={{ top: 30 }}
      onCancel={() => salesOrderDetailsActions.setOpenCustomerModal(false)}
      footer={null}
      width={900}
    >
      <ProductSearch
        title="Search Customers"
        loading={false}
        data={customers}
        allowMultiple={false}
        columns={columnsCustomerModal}
        onApply={setCustomer}
        searchFields={searchFieldsCustomerModal}
        tableIdProperty="customerUuid"
        searchReference="customerUuid"
      />
    </Modal>
  );
};

const DetailsTab: React.FC = () => {
  const forms = useContext(FormsContext);
  const { shippingForm, billingForm, detailsForm, shippingProductsTableForm } = forms;
  const totals = useSelector((state: any) => state.salesOrderDetails.totals);
  const detailsProducts = useSelector((state: any) => state.salesOrderDetails.detailsProducts);
  const isFormUpdated = useSelector((state: any) => state.salesOrderDetails.isFormUpdated);
  const editMode = useSelector((state: any) => state.salesOrderDetails.editMode);
  const dispatch = useDispatch();
  const salesOrderDetailsActions = bindActionCreators(SalesOrderDetailsActions, dispatch);

  const productColumns = [
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 100,
      render: function render(url: any) {
        return url !== '' ? (
          <img src={url} alt="" style={{ width: '100%' }} />
        ) : (
          <PictureOutlined style={{ fontSize: '35px', marginBottom: '15px' }} />
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: 150,
      render: function CellWrapper(_: any, record: any, index: number) {
        return <ShippingProductsCells index={index} record={record} />;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 450,
      key: 'description',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      width: 100,
      key: 'supplier',
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseCode',
      width: 200,
      key: 'warehouseCode',
    },
    {
      title: 'Shipped',
      dataIndex: 'shipPack',
      width: 100,
      key: 'shipPack',
    },
    {
      title: 'Qty',
      dataIndex: 'orderQty',
      width: 100,
      key: 'orderQty',
      render: function CellWrapper(_: any, record: any, index: number) {
        return <QuantityCell index={index} record={record} />;
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 100,
      key: 'price',
      className: 'moneyColumn',
      render: function render(value: string) {
        return toMoney(value);
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discountAmount',
      width: 100,
      key: 'discountAmount',
      className: 'moneyColumn',
      render: function render(value: string) {
        return toMoney(value);
      },
    },
    {
      title: 'Tax',
      dataIndex: 'taxAmount',
      width: 100,
      key: 'taxAmount',
      className: 'moneyColumn',
      render: function render(value: string) {
        return toMoney(value);
      },
    },
    {
      title: 'Item Total',
      dataIndex: 'itemTotalAmount',
      width: 100,
      key: 'itemTotalAmount',
      className: 'moneyColumn',
      render: function render(value: string) {
        return toMoney(value);
      },
    },
    {
      title: 'Status',
      width: 150,
      dataIndex: 'salesOrderItemstatus',
      key: 'salesOrderItemstatus',
    },
  ];
  if (!shippingForm || !billingForm || !detailsForm || !shippingProductsTableForm) {
    return null;
  }
  return (
    <Form.Provider onFormChange={(formName: string, info: any) => {
      if (!isFormUpdated) {
        salesOrderDetailsActions.setIsFormUpdated(true);
      }
      if ((formName === 'details' && (info.changedFields[0].name[0] === 'discount' || info.changedFields[0].name[0] === 'discountType' || info.changedFields[0].name[0] === 'shippingAmount' || info.changedFields[0].name[0] === 'miscAmount')) || formName === 'shippingProductsTable') {
        const formDetails = info.forms.details.getFieldsValue();
        const shippingProductsTable = info.forms.shippingProductsTable.getFieldsValue();
        if (shippingProductsTable.ShippingProducts) {
          const itemsRecalculated = shippingProductsTable.ShippingProducts.map((s: any) => {
            const total = (s.price * s.orderQty) - s.discountAmount;
            return {
              ...s,
              itemTotalAmount: total < 0 ? 0 : total,
            };
          });
          const subTotalAmount = sumObjectArrayValues(itemsRecalculated, 'itemTotalAmount');
          const taxAmount = sumObjectArrayValues(itemsRecalculated, 'taxAmount');
          calculateTotals(formDetails.discount, formDetails.shippingAmount, subTotalAmount, formDetails.discountType, taxAmount, formDetails.miscAmount, dispatch);
        }
      }
    }}
    >
      <CustomerModal />
      <Form form={detailsForm} name="details">
        <Row>
          <Col span={24}>
            <Form.Item
              name="customerName"
              label="Customer Name"
              style={{ width: '400px', margin: 'auto' }}
            >
              <Input.Search disabled={!editMode} onSearch={() => salesOrderDetailsActions.setOpenCustomerModal(true)} />
            </Form.Item>
            <Form.Item
              name="customerCode"
              noStyle
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ margin: 'auto' }}
              wrapperCol={{
                xl: { span: 24 },
              }}
            >
              <Form.Item
                name="orderType"
                label="Order Type"
                style={{ display: 'inline-block', width: '30%', paddingLeft: '10px' }}
              >
                <Select
                  placeholder="Select a type"
                  style={{ width: '100%' }}
                  disabled={!editMode}
                >
                  <Select.Option value={0}>Type 1</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Discount"
                style={{ display: 'inline-block', width: '70%', paddingLeft: '10px' }}
              >
                <Form.Item
                  name="discountType"
                  style={{ display: 'inline-block', width: '30%' }}
                >
                  <Select disabled={!editMode}>
                    <Select.Option value="discountRate">Rate</Select.Option>
                    <Select.Option value="discountAmount">Amount</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="discount"
                  style={{ display: 'inline-block', width: '70%' }}
                >
                  <InputNumberComponent
                    disabled={!editMode}
                    min="0"
                    name="discount"
                  />
                </Form.Item>
              </Form.Item>
            </Form.Item>
            <Form.Item
              style={{ margin: 'auto' }}
              wrapperCol={{
                xl: { span: 24 },
              }}
            >
              <Form.Item
                name="refNum"
                label="Ref #"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
              <Form.Item
                name="customerPoNum"
                label="PoNumber"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
              <Form.Item
                name="shippingAmount"
                label="ShipAmount"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              style={{ margin: 'auto' }}
              wrapperCol={{
                xl: { span: 24 },
              }}
            >
              <Form.Item
                name="terms"
                label="Terms"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <Input type="text" disabled={!editMode} />
              </Form.Item>
              <Form.Item
                name="miscAmount"
                label="Misc Amnt"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
              <Form.Item
                name="termsDays"
                label="Terms Days"
                style={{ display: 'inline-block', width: '33.3333333333%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              wrapperCol={{
                xl: { span: 24 },
              }}
            >
              <Form.Item
                name="chargeAndAllowanceAmount"
                label="C&A"
                style={{ display: 'inline-block', width: '50%', paddingLeft: '10px' }}
              >
                <InputNumberComponent min="0" disabled={!editMode} />
              </Form.Item>
              <Form.Item
                name="orderDate"
                label="Order Date"
                style={{ display: 'inline-block', width: '50%', paddingLeft: '10px' }}
              >
                <DatePicker
                  disabled={!editMode}
                  style={{ width: '100%' }}
                  format="MM/DD/YYYY"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="notes"
              label="Note"
              style={{ display: 'inline-block', width: '100%', paddingLeft: '10px' }}
            >
              <Input.TextArea
                name="Note"
                maxLength={250}
                disabled={!editMode}
                rows={5}
              />
            </Form.Item>
            <Form.Item
              name="rowNumHeader"
              noStyle
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="rowNumHeaderInfo"
              noStyle
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="shipping"
          >
            <Tabs.TabPane
              tab="Shipping"
              key="shipping"
              forceRender
            >
              <ContactDetailsForm name="shipping" form={shippingForm} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Billing"
              key="billing"
              forceRender
            >
              <ContactDetailsForm name="billing" form={billingForm} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={22}>
              <Title>Products</Title>
            </Col>
            <Col span={2} style={{ paddingLeft: '10px' }}>
              <Button disabled={!editMode} type="primary" style={{ width: '100%' }} onClick={() => salesOrderDetailsActions.setOpenProductsModal(true)}>
                Add
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AddProductModal />
              <Form form={shippingProductsTableForm} name="shippingProductsTable">
                <TableComponent
                  scroll={{ x: 1000 }}
                  columns={productColumns}
                  dataSource={detailsProducts}
                  loadingStatus={false}
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col offset={20} span={4}>
          <InfoValue info="Subtotal" floatValue="right" value={toMoney(totals.subTotalAmount)} />
          <InfoValue info="Discount" floatValue="right" value={toMoney(totals.discountAmount)} />
          <InfoValue info="Shipping" floatValue="right" value={toMoney(totals.shippingAmount)} />
          <InfoValue info="Handling" floatValue="right" value={toMoney(totals.miscAmount)} />
          <InfoValue info="Tax" floatValue="right" value={toMoney(totals.taxAmount)} />
          <TotalDivider />
          <InfoValue info="Total" floatValue="right" value={toMoney(totals.totalAmount)} />
        </Col>
      </Row>
    </Form.Provider>
  );
};

const Details: React.FC = () => {
  const [activeTab] = useActiveTabOnQuery();
  return (
    <Tabs
      defaultActiveKey={activeTab}
    >
      <Tabs.TabPane
        tab="Details"
        key="details"
        forceRender
      >
        <DetailsTab />
      </Tabs.TabPane>
      <Tabs.TabPane key="history" tab="History">
        {/* <HistoryTab /> */}
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Details;
