import React, { useState } from "react";
import { Row, Tooltip, message, Space, Typography } from "antd";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  value: string;
  onClick?: Function;
  type: 1 | 2 | 3;
  id?: string;
};

const ERPLinkTableCell = (props: Props) => {
  const [isHover, setIsHover] = useState(props.type === 3 ? true : false);
  const url =
    props.type === 1
      ? `${process.env.ERP_LINK}Inventory/StyleCodesManager?productStyleCode=${props.value}&autoClose=true`
      : props.type === 2
      ? `${process.env.ERP_LINK}Inventory/StyleCodesManager?productStyleCode=${props.value}&autoClose=true`
      : `${process.env.ERP_LINK}Inventory/InventoryManager?sku=${props.value}&autoClose=true`;

  const mouseenter = (e: any) => {
    if (props.type !== 3) {
      e.stopPropagation();
      setIsHover(true);
    }
  };

  const mouseleave = (e: any) => {
    if (props.type !== 3) {
      e.stopPropagation();
      setIsHover(false);
    }
  };
  return (
    <Row
      align="middle"
      justify="space-between"
      onMouseEnter={mouseenter}
      onMouseLeave={mouseleave}
    >
      {props.onClick ? (
        <Typography.Text
          ellipsis={{ tooltip: props.value }}
          onClick={() => {
            props.onClick && props.onClick();
          }}
          style={{
            width: "calc(100% - 40px)",
            color: "rgb(25, 118, 210)",
            cursor: "pointer",
          }}
        >
          {props.value}
        </Typography.Text>
      ) : (
        <Typography.Text
          ellipsis={{ tooltip: props.value }}
          style={{ width: `calc(100% - 40px)` }}
        >
          {props.value}
        </Typography.Text>
      )}
      <Space
        style={{
          position: "absolute",
          right: 2,
          top: 7,
          visibility: isHover && props.value ? "visible" : "hidden",
        }}
      >
        <Tooltip
          placement="top"
          title="Open in ERP"
          trigger={["hover", "click"]}
        >
          <LinkOutlined
            style={{
              color: "#138496",
            }}
            onClick={() => window.open(url, "_blank")}
          />
        </Tooltip>

        <Tooltip placement="top" title="Copy" trigger={["hover", "click"]}>
          <CopyToClipboard
            text={props.value}
            onCopy={() =>
              message.info(`"${props.value}" has been copied to clipboard`)
            }
          >
            <CopyOutlined
              style={{
                color: "#138496",
              }}
            />
          </CopyToClipboard>
        </Tooltip>
      </Space>
    </Row>
  );
};

export default ERPLinkTableCell;
