import React, { useContext } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { ProductDetailScreenContext } from '../context';
import TableComponent from '../../../components/TableComponent';
import { ProductTabContainer } from '../styledComponents';
import { distributionCenterTypes } from '../../../constants/distributionCenter';
import FormItemEnabler from '../../ProductBulkUpdate/FormItemEnabler';
import FormElement, { Type } from '../../../components/common/FormElement';


interface ProductInventoryTabContentProps {
  tableData: Array<any>;
  editMode: boolean;
  enabler: boolean;
}

const QuantityCell = ({ index, enabler }: any) => {
  const QuantityInput = enabler ? FormItemEnabler : FormElement;
  return (
    <QuantityInput
      formItemProperties={{
        name: ['DcList', index, 'AvailableQuantity'],
      }}
      inputProperties={{
        disabled: true,
        min: 0,
      }}
      inputType={Type.NUMBER}
    />
  );
};

const DistributionCenterCell: React.FC<{ record: any, index: number }> = ({ index, record }) => (
  <>
    {record.DistributionCenterName}
    <Form.Item
      name={['DcList', index, 'DistributionCenterName']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['Dclist', index, 'DistributionCenterCode']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['DcList', index, 'DistributionCenterType']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['DcList', index, 'DistributionCenterId']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
  </>
);

export const InventoryFormContent: React.FC<{ tableData: Array<any>, editMode: boolean, enabler: boolean }> = ({ tableData, editMode, enabler }) => {
  const columns = [
    {
      title: 'Distribution Center',
      dataIndex: 'DistributionCenterName',
      key: 'DistributionCenterName',
      width: '350px',
      render: function CellWrapper(_: any, record: any, index: number) {
        return <DistributionCenterCell index={index} record={record} />;
      },
    },
    {
      title: 'Code',
      dataIndex: 'DistributionCenterCode',
      key: 'DistributionCenterCode',
      width: '200px',
    },
    {
      title: 'Type',
      dataIndex: 'DistributionCenterType',
      key: 'DistributionCenterType',
      width: '200px',
      render: (value: any) => distributionCenterTypes.find((dc: any) => dc.key === value)?.title,
    },
    {
      title: 'Quantity',
      dataIndex: 'AvailableQuantity',
      key: 'AvailableQuantity',
      type: 'number',
      editable: true,
      width: '250px',
      render: function CellWrapper(_: any, record: any, index: number) {
        return <QuantityCell index={index} enabler={enabler}  />;
      },
    },
    {
      title: '',
      dataIndex: 'DistributionCenterId',
      key: 'DistributionCenterId',
      className: 'hide',
    },
  ];
  return (
    <TableComponent
      className="inventoryTable"
      pagination={false}
      loadingStatus={false}
      dataSource={tableData}
      columns={columns}
    />
  );
};

const ProductInventoryTabContent: React.FC<ProductInventoryTabContentProps> = ({ tableData, editMode, enabler }) => (
  <ProductTabContainer>
    <Row>
      <Col span={24} style={{ paddingLeft: '15px' }}>
        <InventoryFormContent tableData={tableData} editMode={editMode} enabler={enabler} />
      </Col>
    </Row>
  </ProductTabContainer>
);

const ProductInventoryTab: React.FC = () => {
  const [state] = useContext(ProductDetailScreenContext);
  if (!state) {
    return null;
  }
  return (
    <ProductInventoryTabContent
      tableData={state.inventoryTable}
      editMode={state.editMode}
      enabler={false}
    />
  );
};

export default ProductInventoryTab;
