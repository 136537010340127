import {SalesOrderDetailsActions, SalesOrderDetailsActionTypes} from '../types/salesOrderDetails';

interface SalesOrderDetailsState {
  salesOrder: Entities.SalesOrderDetails | null;
  loading: boolean;
  editMode: boolean;
  isFormUpdated: boolean;
  activeView: string;
  openProductsModal: boolean;
  openCustomerModal: boolean;
  totals: object;
  originalProducts: Array<object>;
  detailsProducts: Array<object>;
  customers: Array<Entities.SalesOrderCustomers>;
}

const initialState: SalesOrderDetailsState = {
  salesOrder: null,
  originalProducts: [],
  loading: false,
  editMode: false,
  totals: {
    totalAmount: 0,
    subTotalAmount: 0,
    discountAmount: 0,
    discountRate: 0,
    shippingAmount: 0,
    taxAmount: 0,
    miscAmount: 0,
  },
  isFormUpdated: false,
  activeView: 'details',
  openProductsModal: false,
  openCustomerModal: false,
  detailsProducts: [],
  customers: [],
};

const profiles = (state = initialState, action: SalesOrderDetailsActionTypes) => {
  switch (action.type) {
    case SalesOrderDetailsActions.SET_TOTALQUANTITY:
      return { ...state, totals: action.value };
    case SalesOrderDetailsActions.SET_CUSTOMERS:
      return { ...state, customers: action.value };
    case SalesOrderDetailsActions.SET_OPENCUSTOMERMODAL:
      return { ...state, openCustomerModal: action.value };
    case SalesOrderDetailsActions.SET_DETAILS_PRODUCTS:
      return { ...state, detailsProducts: action.value };
    case SalesOrderDetailsActions.SET_OPENPRODUCTSMODAL:
      return { ...state, openProductsModal: action.value };
    case SalesOrderDetailsActions.SET_ACTIVEVIEW:
      return { ...state, activeView: action.value };
    case SalesOrderDetailsActions.SET_ISFORMUPDATED:
      return { ...state, isFormUpdated: action.value };
    case SalesOrderDetailsActions.SET_EDITMODE:
      return { ...state, editMode: action.value };
    case SalesOrderDetailsActions.SET_LOADING:
      return { ...state, loading: action.value };
    case SalesOrderDetailsActions.SET_ORDER:
      return {
        ...state,
        salesOrder : action.order,
      };
    default:
      return state;
  }
};

export default profiles;
