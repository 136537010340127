import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Typography, Image } from 'antd';
import Styled from 'styled-components';
const { Text } = Typography;

type CellProps = {
  imageUrl: string;
  compact: boolean;
  onButtonClick: (imgUrl: string) => void;
};

const CellBox = Styled.div`
  width: 130px;
  height: 35px;
  &:hover {
    width: 130px;
  }
`;

const ImageContainer = Styled.div`
  display: flex;
  justify-content: center;
`;

export default function ExportCSV(props: CellProps): JSX.Element {
  const { imageUrl, compact, onButtonClick } = props;
  const [showButton, setShowButton] = React.useState<boolean>(false);
  const divRef = React.useRef<any>();

  React.useEffect(() => {
    let interval: any = undefined;
    if (showButton) {
      interval = setInterval(() => {
        if (showButton && divRef.current && divRef.current.matches(':hover') === false) {
          setShowButton(false);
        }
      }, 800);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showButton]);

  return (
    <CellBox
      ref={divRef}
      // style={{ width: 130, height: 35, }}
      onMouseEnter={() => {
        setShowButton(true);
      }}
      onMouseLeave={() => {
        setShowButton(false);
      }}
    >
      {!showButton && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: imageUrl ? 'center' : 'end',
            alignItems: 'center',
          }}
        >
          {/* {imageUrl ? compact ? <CheckOutlined /> : <Text>{imageUrl}</Text> : <Text>...</Text>} */}
          {imageUrl ? compact ? <CheckOutlined /> : 
                  <ImageContainer className="image-tree">
                  <Image width={28} height={28} src={imageUrl} />
                </ImageContainer>
           : <Text>...</Text>}
        </div>
      )}
      {showButton && (
        <div style={{ width: 130, height: 35 }}>
          <Button
            type="link"
            style={{ width: '100%', height: '100%' }}
            onClick={() => onButtonClick(imageUrl)}
          >
            {imageUrl ? 'View' : 'Edit'}
          </Button>
        </div>
      )}
    </CellBox>
  );
}
