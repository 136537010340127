import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Typography } from "antd";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { CloseOutlined } from "@ant-design/icons";

import ModalDialog from '../../../components/common/ModalDialog';
import { fetchControlTowerAttribute } from '../../../services/controlTower';

interface WhiteListProps {
  editIndex: number;
  onHide: () => void;
  onRefresh: (data: any) => void;
  row: StringKAnyVPair[];
  visible: boolean;
}

const SelectAtrributeComponent = (props: WhiteListProps) => {
  const { visible, onHide, onRefresh } = props;
  const initialGCStyle = {height: 'calc(100vh - 260px)', maxHeight: 600};
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [gridReady, setGridReady] = useState(false);
  const [selected, setSelected] = useState({});
  const [seletedRow, setSelectedRow] = useState<any[]>([]);
  const [gridCtnStyle,setGridCtnStyle] = useState<StringKAnyVPair>(initialGCStyle);

  const gridCtnRef = React.useRef<any>(null);

  const getAttributeData = useCallback(async () => {
    try {
      setLoading(true);
      let res = await fetchControlTowerAttribute();
      setLoading(false);
      if (res) {
        const chn = props.row[0] ? props.row[0].displayName : '';

        if (chn) {
          res = res.filter((attr: StringKAnyVPair) => attr.groupName === chn);
        }

        setData(res);
        setGridReady(true);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [props]);

  const filterValue = [
    { name: "attributeName", operator: "contains", type: "string", value: "" },
    //{ name: "groupName", operator: "contains", type: "string", value: "" },
  ];

  const columns = [
    {
      name: "attributeName",
      header: "Name",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: "groupName",
      header: "Source",
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
  ];

  const handleNext = async () => {
    if (seletedRow.length > 0) {
      onRefresh(seletedRow[0]);
    }
  };

  const getDialogDom = () => {
    const gridCtn = gridCtnRef?.current;

    if (gridCtn) {
      return gridCtn.parentNode;
    }

    return null;
  };

  const onFullscreen = (full: boolean) => {
    const dialogBody = getDialogDom();

    if (!dialogBody) return;
    //console.log('full', full, dialogBody, dialogBody.parentNode);
    if (full) {
      setGridCtnStyle({
        height: 'calc(100vh - 144px)',
        maxHeight: '',
      });
      // maybe there need to detect the window environment
      dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 16}px`;
      setTimeout(() => {
        dialogBody.style.height = 'auto';
        dialogBody.parentNode.style.height = 'auto';
        //dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
      }, 0);
      setTimeout(() => {
        dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
      }, 200);
    } else {
      setGridCtnStyle(initialGCStyle);
    }
  };

  const onSelectionChange = useCallback(({ data, selected }) => {
    console.log(data);
    setSelected(selected);
    setSelectedRow(data);
  }, []);

  useEffect(() => {
    getAttributeData();
  }, [getAttributeData]);

  return (
    <ModalDialog
      title="Edit Control Tower Field"
      centered
      closable={false}
      closeButton
      fullscreen
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      //onCancel={() => onHide()}
      onClose={() => onHide()}
      onFullscreen={onFullscreen}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Typography.Text>
              Selected Row :{" "}
              <Typography.Text>
                {seletedRow.length > 0
                  ? `${seletedRow[0].attributeName} [${seletedRow[0].groupName}]`
                  : ""}
              </Typography.Text>
            </Typography.Text>
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleNext}
              disabled={seletedRow.length === 0}
            >
              Next
            </Button>
            <Button onClick={() => onHide()} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <div ref={gridCtnRef} style={gridCtnStyle}>
        {gridReady && (
        <ReactDataGrid
          dataSource={data}
          columns={columns}
          loading={loading}
          defaultFilterValue={filterValue}
          onSelectionChange={onSelectionChange}
          selected={selected}
          idProperty="attributeNum"
          style={{
            height: "100%",
          }}
          virtualized
        />
        )}
      </div>
    </ModalDialog>
  );
};

export default SelectAtrributeComponent;
