import { Col, Form, Input, notification, Radio, Row, Select, Space, Spin, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import FormButtons from '../../components/common/FormButtons';
import Heading from '../../components/common/Heading';
import ListInput from '../../components/common/ListInput';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { USState, USStates, USStatesMap } from '../../constants/countries/states';
import { Permissions } from '../../constants/enums/permissions';
import {
  createSupplierConfig,
  getSupplierConfig,
  SupplierConfigCreatePayload,
  updateSupplierConfig,
} from '../../services/suppliers';
import { useExecutePromise } from '../../util/hooks';
import { validateURL } from '../../util/validators';


const initialValues: SupplierConfigCreatePayload = {
  supplierConfig: {
    enabledAsSupplier: 0,
    listPreference: 2,
    inventoryWarehousePublishRule: 0,
  },
  requiredTaxStates: [],
  warehouseStates: [],
  company: {
    websiteUrl: '',
  },
};

/**
 * Retrieves the supplier config and pre-process the object.
 */
const getMappedSupplierConfig = async (): Promise<Entities.SupplierConfigItem> => {
  const config = await getSupplierConfig();

  return {
    ...config,
    warehouseStates: config.warehouseStates.map(state => ({ ...state, name: USStatesMap[state.state] })),
    requiredTaxStates: config.requiredTaxStates.map(state => ({ ...state, name: USStatesMap[state.state] })),
  };
};

const SupplierSettings: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm<SupplierConfigCreatePayload>();
  const [config, loading, refresh] = useExecutePromise(getMappedSupplierConfig, null);
  const disabled = !editing;

  const uniqueState = useCallback((states: Entities.BaseUSState[], newValue: USState): boolean => states.find(state => state.state === newValue.value) === undefined, []);

  const onCancelEdit = useCallback(() => {
    setEditing(false);
    form.resetFields();
    if (config && config.supplierConfig) {
      form.setFieldsValue(config);
    }
  }, [form, config, setEditing]);

  const onFormFinish = useCallback(async (data: SupplierConfigCreatePayload) => {
    setSaving(true);
    try {
      if (config && config.supplierConfig) {
        await updateSupplierConfig(data);
        notification.success({ message: 'Settings updated successfully', duration: 5 });
      } else {
        await createSupplierConfig(data);
        notification.success({ message: 'Settings saved successfully', duration: 5 });
      }

      refresh(true);
      setEditing(false);
    } catch (e) {
      notification.error({ message: 'There was an error processing your request', duration: 10 });
    } finally {
      setSaving(false);
    }
  }, [config, refresh]);

  useEffect(() => {
    if (!config) {
      return;
    }

    if (!config.supplierConfig) {
      setEditing(true);
      return;
    }

    form.setFieldsValue(config);
  }, [config, form]);

  const labelCol = { xs: 24, md: 8, lg: 8, xl: 4 };
  const wrapperCol = { xs: 24, md: 16, lg: 16, xl: 8 };

  return (
    <ContentLayout>
      <Heading title="Supplier - Settings" />
      <Spacer />
      <SiteContent>
        <Spin spinning={loading}>
          <Tabs
            tabBarExtraContent={(
              <FormButtons
                editingMode={editing}
                onSave={form.submit}
                onEdit={() => setEditing(true)}
                onCancel={onCancelEdit}
                // TODO: change for correct permission
                permissionNumber={Permissions.MANAGE_PRODUCTS}
                disableDelete
                saving={saving}
              />
            )}
          >
            <Tabs.TabPane tab="Basic Info">
              <Form
                form={form}
                initialValues={initialValues}
                onFinish={onFormFinish}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
              >
                <Form.Item
                  label="Enable as Supplier"
                  name={['supplierConfig', 'enabledAsSupplier']}
                >
                  <Select disabled={disabled}>
                    <Select.Option value={0}>
                      Disabled
                    </Select.Option>
                    <Select.Option value={1}>
                      Enabled
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="List preference:"
                  name={['supplierConfig', 'listPreference']}
                >
                  <Radio.Group disabled={disabled}>
                    <Space direction="vertical">
                      <Radio value={0}>
                        Public (non-registered users can see my information)
                      </Radio>
                      <Radio value={1}>
                        Private (only registered users can see my information)
                      </Radio>
                      <Radio value={2}>
                        Don&apos;t list
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Spacer />
                <Row gutter={12}>
                  <Col xs={24} md={24} lg={24} xl={12}>
                    <Form.Item
                      label="Required Tax ID in states"
                      labelCol={{ xs: 24, xl: 10 }}
                      wrapperCol={{ xs: 24, xl: 14 }}
                    >
                      <ListInput<USState, Entities.RequiredTaxState>
                        form={form}
                        name={['requiredTaxStates']}
                        itemKey="name"
                        choices={USStates}
                        disabled={disabled}
                        onAdd={(choice) => ({ state: choice.key, name: choice.label })}
                        unique={uniqueState}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={24} xl={12}>
                    <Form.Item
                      label="Warehouse States"
                      labelCol={{ xs: 24, xl: 10 }}
                      wrapperCol={{ xs: 24, xl: 14 }}
                    >
                      <ListInput<USState, Entities.WarehouseState>
                        form={form}
                        name={['warehouseStates']}
                        itemKey="name"
                        choices={USStates}
                        disabled={disabled}
                        onAdd={(choice) => ({ state: choice.key, name: choice.label })}
                        unique={uniqueState}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Spacer />
                <Form.Item
                  label="Website"
                  name={['company', 'websiteUrl']}
                  rules={[
                    { required: true, message: 'This field is required' },
                    { validator: validateURL, message: 'The provided value must be a valid URL.' },
                  ]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </SiteContent>
    </ContentLayout>
  );
};

export default SupplierSettings;
