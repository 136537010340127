import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import ActionsCell from '../../../components/common/antd/ActionsCell';
import ProductSearch, { OnApplyCallback } from '../../../components/common/ProductSearch';
import Spacer from '../../../components/common/Spacer';
import TableComponent from '../../../components/TableComponent';
import { Actions, ProductDetailScreenContext } from '../context';


const ComponentQtyCell: React.FC<{ field: FormListFieldData }> = (
  {
    field,
  },
) => {
  const [state] = useContext(ProductDetailScreenContext);
  const editMode = state?.editMode;

  const valueFormatter = useCallback((value: number | undefined): string => {
    if (!value) {
      return '0';
    }

    return Number(value).toFixed(0).toString();
  }, []);

  return (
    <Form.Item
      name={[field.name, 'ComponentQty']}
      noStyle={!editMode}
      rules={[
        { min: 1, message: 'Value must be equal or greater than 1', type: 'number' },
      ]}
    >
      <InputNumber
        min={1}
        step={1}
        formatter={valueFormatter}
        bordered={editMode}
        readOnly={!editMode}
      />
    </Form.Item>
  );
};

interface DeleteCellProps {
  index: number;
  onRemove: (index: number) => void;
}

const DeleteCell: React.FC<DeleteCellProps> = (
  {
    index,
    onRemove,
  },
) => {
  const [state] = useContext(ProductDetailScreenContext);

  const onDelete = useCallback(() => Modal.confirm({
    title: 'Are you sure you want to delete this entry?',
    content: 'This action cannot be undone and you will be unable to recovery any data.',
    okType: 'danger',
    onOk() {
      onRemove(index);
    },
  }), [index, onRemove]);

  return (
    <ActionsCell
      onDelete={onDelete}
      hideEditSaveButton
      disableDeleteCancelButton={!state?.editMode}
    />
  );
};

const ProductBundles: React.FC<{ fields: FormListFieldData[], operations: FormListOperation }> = (
  {
    fields,
    operations,
  },
) => {
  const { remove, add } = operations;
  const columns = useMemo(() => [
    {
      title: 'SKU',
      dataIndex: '',
      render(value: any, record: any, index: number) {
        return (
          <>
            <Form.Item name={[fields[index].name, 'SKU']} noStyle>
              <Input readOnly bordered={false} tabIndex={-1} />
            </Form.Item>
            <Form.Item name={[fields[index].name, 'ProductId']} noStyle hidden>
              <Input readOnly bordered={false} tabIndex={-1} />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: '',
      width: '30%',
      render(value: any, record: any, index: number) {
        return (
          <Form.Item name={[fields[index].name, 'ProductTitle']} noStyle>
            <Input readOnly bordered={false} tabIndex={-1} />
          </Form.Item>
        );
      },
    },
    {
      title: 'Number Per Bundle',
      dataIndex: 'ComponentQty',
      editable: true,
      render: function CellWrapper(_: any, record: any, index: number) {
        return <ComponentQtyCell field={fields[index]} />;
      },
    },
    {
      title: '',
      dataIndex: 'ProductId',
      render(value: string, record: any, index: number) {
        return (
          <DeleteCell
            index={index}
            onRemove={remove}
          />
        );
      },
    },
  ], [fields, remove]);
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [state, dispatch] = useContext(ProductDetailScreenContext);

  const { basicInfoForm: form } = state || { basicInfoForm: undefined };

  const applySelectedProducts: OnApplyCallback = useCallback((selected) => {
    if (!form) {
      return;
    }

    const currentProducts = (form.getFieldValue('BundleComponents') as { ProductId: string }[]) || [];
    const currentProductIds = currentProducts.map(entry => entry.ProductId);
    Object.keys(selected).filter(productId => currentProductIds.indexOf(productId) < 0).forEach(k => {
      const product = selected[k];
      const formProduct = {
        SKU: product.SKU,
        ProductTitle: product.ProductTitle,
        DcAvialableQuantity: 0,
        ProductId: product.ProductId,
        ComponentQty: 0,
      };

      add(formProduct);
    });
    setSearchModalVisible(false);
    dispatch?.({ type: Actions.SET_FORMUPDATED, params: true });
  }, [dispatch, setSearchModalVisible, add, form]);

  return (
    <div>
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <h1>
            Simple Products in Bundle
          </h1>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={!state?.editMode}
            onClick={() => setSearchModalVisible(true)}
          >
            Add
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <Spacer />
      <TableComponent
        rowKey="fieldKey"
        style={{ minHeight: 600 }}
        loadingStatus={false}
        dataSource={fields}
        columns={columns}
      />
      <Modal
        visible={searchModalVisible}
        style={{ minWidth: 800 }}
        footer={null}
        onCancel={() => setSearchModalVisible(false)}
      >
        <ProductSearch
          onApply={applySelectedProducts}
        />
      </Modal>
    </div>
  );
};

const ProductBundlesForm: React.FC = () => (
  <Form.List name="BundleComponents">
    {
      (fields, operations) => (
        <ProductBundles
          fields={fields}
          operations={operations}
        />
      )
    }
  </Form.List>
);

export default ProductBundlesForm;
