import React from 'react';
import {
  Radio,
  Typography,
  Space
} from 'antd';
import TableFilter from '../../../components/common/TableFilter'

type Props = {
  focused: boolean;
  attrList:StringKAnyVPair[]
  setSelectedAttributes?: Function;
};

const FieldSelector = (props: Props) => {
  const { useState } = React;
  const { attrList } = props
  const [filteredData, setFilteredData] = useState<StringKAnyVPair[]>([])
  const [selectedAttrs, setSelectedAttrs] = useState<number[]>([]);
  const style = {
    display: props.focused ? '' : 'none',
  };

  const onSelectAttribute = (attr: StringKAnyVPair) => {
    const idx = selectedAttrs.indexOf(attr.copywritingElementNum);
    //console.log('sel', attr);
    if (idx > -1) {
      setSelectedAttrs([])
      if (typeof props.setSelectedAttributes === 'function') {
        props.setSelectedAttributes([]);
      }
    } else {
      setSelectedAttrs([attr.copywritingElementNum])
      if (typeof props.setSelectedAttributes === 'function') {
        props.setSelectedAttributes([attr.copywritingElementNum]);
      }
    }

  };

  return (<>
    <div className="dest-step-wrapper" style={style}>
      <div>
      <Space>
        <TableFilter size="middle" columns={[{name:'elementName'}]} dataSource={attrList} setFilteredData={setFilteredData}   />
      </Space>
      </div>
      {filteredData.map(e => (
        <span
          className="attr-cell label"
          key={e.copywritingElementNum}
        >
          <span
            className="attr-label full-width form-label"
            onClick={() => onSelectAttribute(e)}
          >
            <Radio
              //disabled={!e.copywritingValue}
              value={e.copywritingElementNum}
              checked={selectedAttrs.indexOf(e.copywritingElementNum) > -1}
            />
            <Typography.Text ellipsis={{tooltip: e.elementName}}>
              {e.elementName}
            </Typography.Text>
          </span>
        </span>
      ))}
    </div>
  </>);
};

export default FieldSelector;
