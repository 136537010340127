import React from 'react';
import {
  Button,
  Modal,
  Row,
  Space,
} from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import { CloseOutlined, /*FullscreenExitOutlined, FullscreenOutlined*/ } from '@ant-design/icons';
import Fullscreen from '../../assets/icons/fullscreen';
import FullscreenExit from '../../assets/icons/fullscreen_exit';
import { ModalDialogBodyCtn, ModalTitle } from './styledComponents';

interface Props extends ModalProps  {
  bodyHeight?: number;
  children?: React.ReactNode;
  closeButton?: boolean;
  footerHeight?: number;
  fullscreen?: boolean;
  fullscreenForFirst?: boolean;
  onClose: Function;
  onFullscreen?: Function;
  titleRightCrumb?: React.ReactNode;
};

const ModalDialog = (props: Props) => {
  const { useState } = React;
  const [bodyHeight, setBodyHeight] = useState(props.bodyHeight);
  const [dialogIsFullscreen, setDialogIsFullscreen] = useState(false);
  const [inited, setInited] = useState(false);
  const [width, setWidth] = useState(props.width);

  const closeDialog = () => {
    props.onClose();
  };

  // eslint-disable-next-line
  const toggleDialogFullscreen = () => {
    setDialogIsFullscreen(!dialogIsFullscreen);

    if (dialogIsFullscreen) {
      setBodyHeight(props.bodyHeight);
      setWidth(props.width);
    } else {
      const footerHeight = props.footerHeight || 0;

      setBodyHeight(window.innerHeight - 66 - footerHeight);
      setWidth(window.innerWidth - 4);
    }

    if (typeof props.onFullscreen === 'function') {
      props.onFullscreen(!dialogIsFullscreen);
    }
  };

  // eslint-disable-next-line
  React.useEffect(() => {
    if (!inited) {
      if (props.fullscreenForFirst) {
        toggleDialogFullscreen();
      }

      setInited(true);
    }
  }, [inited, props, toggleDialogFullscreen]);

  return (<>
    <Modal
       {...props}
       bodyStyle={{
         backgroundColor: '#F0F2F5',
         padding: 12,
         height: bodyHeight,
       }}
      centered
      className="fullscreen-modal"
      onCancel={closeDialog}
      style={{ paddingBottom: 0 }}
      title={
        <Row align="middle" justify="space-between">
          <ModalTitle>{props.title || ''}</ModalTitle>
          <Space>
            {props.titleRightCrumb}
            {props.fullscreen && <Button onClick={toggleDialogFullscreen}>
              {dialogIsFullscreen ? <FullscreenExit width={14} height={14} /> : <Fullscreen width={14} height={14} />}
              {dialogIsFullscreen ? 'Exit' : ''} Fullscreen
            </Button>}
            {props.closeButton && <Button onClick={closeDialog}>
              <CloseOutlined />
              Close
            </Button>}
          </Space>
        </Row>
      }
      visible={props.visible}
      width={width}
    >
     <ModalDialogBodyCtn>
       {props.children}
     </ModalDialogBodyCtn>
    </Modal>
  </>);
};

export default ModalDialog;
