import React from 'react';
import { Col, Image, Row, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DraggableCore } from 'react-draggable';
import styled from 'styled-components';
import theme from '../../assets/styles/theme.js';
import { randomString } from '../../util';

const ImageWrapper = styled.div`
  & .image-thumb {
    cursor: move;
    height: 300px;
    object-fit: contain;
    user-drag: none;
    width: 300px;
  }

  & .image-layer {
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    height: 326px;
    left: 50px;
    position: fixed;
    top: 50px;
  }

  & .image-title {
    font-weight: 500;
    margin-left: 6px;
  }

  & .image-title-bar {
    background-color: #D9D9D9;
    cursor: move;
  }

  & .image-title-button {
    cursor: pointer;
    padding: 1px 4px;
  }

  & .resizable-dragger {
	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
	background-origin: content-box;
	background-position: bottom right;
	background-repeat: no-repeat;
    bottom: 0;
	box-sizing: border-box;
	cursor: se-resize;
	height: 16px;
	padding: 0 3px 3px 0;
	position: absolute;
    right: 0;
	width: 16px;
  }
`;

/**
 * image: {id, src, title?,}
 */
type Props = {
  images: StringKAnyVPair[];
  onClosePreviewedImage?: Function;
  version?: number;
};

const PreviewImages = (props: Props) => {
  const RESIZER_CLASS = 'resizable-dragger';
  const { useState } = React;
  const [currentImages, setCurrentImages] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [lastVersion, setLastVersion] = useState(props.version);

  const adjustImageLayer = (
    img: StringKAnyVPair,
    opt: StringKAnyVPair,
  ) => {
    const layers = currentImages.filter(e => e.id === img.id);

    if (layers.length > 0) {
      const layer = layers[0];

      layer.x = opt.x;
      layer.y = opt.y;
      layer.width = opt.width;
      layer.height = opt.height;
      setCurrentImages([...currentImages]);
    }
  };

  const closeImage = (img: StringKAnyVPair) => {
    if (typeof props.onClosePreviewedImage === 'function') {
      props.onClosePreviewedImage(img);
    }
  };

  const renderImage = (img: StringKAnyVPair) => {
    let dragOpt: StringKAnyVPair = {};

    const onDragLayer = (evt: any) => {
      let [x, y] = [0, 0];

      if (!dragOpt.mask) {
        const mask = document.createElement('div');
        const rect = document.getElementById(img.key)?.getBoundingClientRect();

        mask.setAttribute(
          'style',
          'position: absolute; background-color: rgba(0, 0, 0, .3); z-index: 2000;',
        );
        dragOpt.width = rect?.width || 326;
        dragOpt.height = rect?.height || 300;
        mask.style.height = `${dragOpt.height}px`;
        mask.style.width = `${dragOpt.width}px`;
        document.body.appendChild(mask);
        //document.body.insertBefore(mask, document.body.firstChild);
        dragOpt.mask = mask;
      }

      if (dragOpt.action === 'resize') {
        let w = Math.abs(evt.clientX - img.x);
        let h = Math.abs(evt.clientY - img.y);

        x = img.x < evt.clientX ? img.x : evt.clientX;
        y = img.y < evt.clientY ? img.y : evt.clientY;
        dragOpt.mask.style.width = `${w}px`;
        dragOpt.mask.style.height = `${h}px`;
        dragOpt.width = w < 200 ? 150 : w;
        dragOpt.height = h < 200 ? 150 : h;
      } else {
        x = evt.clientX - dragOpt.offsetX;
        y = evt.clientY - dragOpt.offsetY;
      }

      dragOpt.mask.style.left = `${x}px`;
      dragOpt.mask.style.top = `${y}px`;
      evt.preventDefault();
    };

    const onDragLayerEnd = (evt: any) => {
      const opt: StringKAnyVPair = {};
      let x = evt.clientX - dragOpt.offsetX;
      let y = evt.clientY - dragOpt.offsetY;

      if (dragOpt.action === 'resize') {
        x = img.x < evt.clientX ? img.x : evt.clientX;
        y = img.y < evt.clientY ? img.y : evt.clientY;
      }

      if (x < 0) x = 0;
      if (y < 0) y = 0;
      if (x + dragOpt.width > window.innerWidth) x = window.innerWidth - dragOpt.width;
      if (y + dragOpt.height > window.innerHeight) y = window.innerHeight - dragOpt.height;

      opt.x = x;
      opt.y = y;
      opt.width = dragOpt.width;
      opt.height = dragOpt.height;
      adjustImageLayer(img, opt);
      evt.preventDefault();

      if (dragOpt.mask && dragOpt.mask.parentNode) {
        document.body.removeChild(dragOpt.mask);
      }

      dragOpt = {};
    };

    const onDragLayerStart = (evt: any) => {
      const cls = evt.target.getAttribute('class');

      if (cls === RESIZER_CLASS) {
        dragOpt.action = 'resize';
      } else {
        dragOpt.action = 'move';
      }

      dragOpt.offsetX = evt.clientX - img.x;
      dragOpt.offsetY = evt.clientY - img.y;
      evt.stopPropagation();
      //evt.preventDefault();
    };

    return (
      <DraggableCore
        key={img.key}
        onDrag={onDragLayer}
        onStart={onDragLayerStart}
        onStop={onDragLayerEnd}
      >
        <Col
          className="image-layer"
          id={img.key}
          style={{
            height: img.height,
            left: img.x > 0 ? img.x : 0,
            top: img.y > 0 ? img.y : 0,
          }}
        >
          <Row
            align="middle"
            className="image-title-bar"
            justify="space-between"
          >
            <span className="image-title">
              {img.title || ''}
            </span>
            <span
              className="image-title-button"
              onClick={() => closeImage(img)}
            >
              <Tooltip title="close">
                <CloseOutlined />
              </Tooltip>
            </span>
          </Row>
          <Image
            alt={img.title || ''} src={img.src}
            className="image-thumb"
            placeholder={true}
            preview={false}
            style={{
              width: img.width,
              height: img.height ? img.height - 24 : undefined,
            }}
          />
          <span className={RESIZER_CLASS}></span>
        </Col>
      </DraggableCore>
    );
  };

  // eslint-disable-next-line
  const renderImage2 = (img: StringKAnyVPair) => {
    let dragOpt: StringKAnyVPair = {};

    const onDragLayer = (evt: any) => {
      if (!dragOpt.mask) {
        const mask = document.createElement('div');
        const rect = document.getElementById(img.key)?.getBoundingClientRect();

        mask.setAttribute(
          'style',
          'position: absolute; background-color: rgba(0, 0, 0, .3); z-index: 2000;',
        );
        mask.style.height = `${rect?.height || 326}px`;
        mask.style.width = `${rect?.width || 300}px`;
        document.body.appendChild(mask);
        //document.body.insertBefore(mask, document.body.firstChild);
        dragOpt.mask = mask;
      }

      dragOpt.mask.style.left = `${evt.clientX - dragOpt.offsetX}px`;
      dragOpt.mask.style.top = `${evt.clientY - dragOpt.offsetY}px`;
      evt.preventDefault();
    };

    const onDragLayerEnd = (evt: any) => {
      const opt: StringKAnyVPair = {};

      evt.preventDefault();
      opt.x = evt.clientX - dragOpt.offsetX;
      opt.y = evt.clientY - dragOpt.offsetY;

      adjustImageLayer(img, opt);
      document.body.removeChild(dragOpt.mask);
      dragOpt = {};
    };

    const onDragLayerStart = (evt: any) => {
      const tm = new window.Image();

      tm.style.opacity = '0';
      tm.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      evt.dataTransfer.setDragImage(tm, 0, 0);
      evt.stopPropagation();
      dragOpt.offsetX = evt.clientX - img.x;
      dragOpt.offsetY = evt.clientY - img.y;
      //evt.preventDefault();
    };

    return (
      <Col
        className="image-layer"
        draggable="true"
        id={img.key}
        key={img.key}
        onDrag={onDragLayer}
        onDragEnd={onDragLayerEnd}
        onDragStart={onDragLayerStart}
        style={{
          left: img.x > 0 ? img.x : 0,
          top: img.y > 0 ? img.y : 0,
        }}
      >
        <Row
          align="middle"
          className="image-title-bar"
          justify="space-between"
        >
          <span className="image-title">
            {img.title || ''}
          </span>
          <span
            className="image-title-button"
            onClick={() => closeImage(img)}
          >
            <CloseOutlined />
          </span>
        </Row>
        <Image
          alt={img.title || ''} src={img.src}
          className="image-thumb"
          placeholder={true}
          preview={false}
        />
      </Col>
    );
  };

  // eslint-disable-next-line
  const updateImages = () => {
    const ids = props.images.map(e => e.id);

    for (let i = currentImages.length; i > 0; i--) {
      const img = currentImages[i - 1];

      if (ids.indexOf(img.id) < 0) {
        currentImages.splice(i - 1, 1);
      }
    }

    const eids = currentImages.map(e => e.id);

    for (let i = 0; i < props.images.length; i++) {
      if (eids.indexOf(props.images[i].id) < 0) {
        const img = props.images[i];

        currentImages.push({
          ...img,
          key: `${img.id}-${randomString()}`,
        });
      }
    }

    //setImagesInitialPosition(currentImages);
    setCurrentImages([...currentImages]);
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }

    if (lastVersion !== props.version) {
      updateImages();
      setLastVersion(props.version);
    }
  }, [
    inited,
    lastVersion,
    props,
    updateImages,
  ]);

  return (<>
    <ImageWrapper>
      {currentImages.map(e => renderImage(e))}
    </ImageWrapper>
  </>);
};

export default PreviewImages;
