import React, { useCallback, useEffect } from 'react';
import { Select, Switch } from 'antd';
import { Actions } from './context';
import { getProfileSettingValue } from '../../util'

type Props = {
  dispatch: Function;
  state: StringKAnyVPair;
  useDropdowns?: boolean;
};

const options = [
  { label: 'No', value: 0 },
  { label: "When parent's value is not empty", value: 1 },
  { label: 'Always', value: 2 },
];

const OverwriteOptionList = (props: Props) => {
  const { useState } = React;
  const { dispatch, state } = props;
  const [inited, setInited] = useState(false);
  const spanRef = React.useRef<any>(null);

  const initSwitch = useCallback(async ()=>{
    try {
      const res = await getProfileSettingValue('ProductOverwriteChildrenValue', 0);

      //console.log('props', state.overwriteChildren, res, typeof res);
      //dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: !!res });
      dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: parseInt(res) === 0 ? 0 : parseInt(res) });
    } catch (error) {
      console.log(`init overwrite switcher error: ${error}`);
    }
  },[dispatch]);

  const onChange = (chk: number) => {
    dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk });
  };

  const onSwitchChange = (chk: boolean) => {
    dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk ? 2 : 0 });
  };

  useEffect(() => {
    if (spanRef.current && !inited) {
      if (state.overwriteChildren === false) {
        //console.log('init swi');
        initSwitch();
      }

      setInited(true);
    }
  },[initSwitch, inited, state]);

  return (<>
    <span ref={spanRef} style={{marginRight: 4}}>Overwrite children's value:&nbsp;</span>
    {props.useDropdowns ? (
      <Select
        onChange={onChange}
        options={options}
        style={{width: 260}}
        value={state.overwriteChildren || 0}
      />
    ) : (
      <Switch
        checkedChildren="Yes"
        //defaultChecked={overwrite}
        onChange={onSwitchChange}
        unCheckedChildren="No"
        checked={state.overwriteChildren || 0}
      />
    )}
  </>);
};

export default OverwriteOptionList;
