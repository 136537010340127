import React, { forwardRef, useState } from 'react';
import {
  Collapse,
  //Image,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import {
  DownOutlined,
  EyeOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { VariableSizeList } from 'react-window';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
import message from '../../../components/common/message';
import { FormLabel, HoverBgButton } from '../../../components/common/styledComponents';
import { ATTRIBUTE_DATA_TYPE_IMAGEURL, LOADING_ICON_SIZE1 } from '../../../constants/config';
import Products from '../../../services/products';
import { randomString, getProfileSettingValue } from '../../../util';
import AttrEditor from '../AttrEditor';
import {
  loadAttributeOptions,
  loadAttributeSet,
  loadAttributes,
  loadAttributesChannels,
  //loadClassifications,
} from '../helper';

export const AttrWrapper = styled.div`
  min-height: calc(100vh - 244px);

  &.large-screen-wrapper {
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
  }

  &.large-screen-wrapper .filter-ctn {
    position: fixed;
  }

  &.large-screen-wrapper .large-screen-area-wrapper {
    margin-left: 260px;
  }

  & .attr-area {
    height: calc(100% - 56px);
    overflow-y: auto;
  }

  & .attr-area-top-bar {
    padding: 12px;
  }

  & .attr-area-wrapper {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    /*min-height: calc(100% - 72px);*/
  }

  & .attr-cell {
    display: inline-block;
    width: 50%;
    padding: 4px;
  }

  & .attr-editor-wrapper {
    display: inline-block;
    width: 50%;
  }

  & .attr-label {
    display: inline-block;
    padding-right: 8px;
    text-align: right;
    width: 50%;
  }

  & .attr-label .ant-typography {
    width: calc(100% - 16px);
  }

  & .attr-name-filter {
    width: 200px;
  }

  & .attr-name-searching-btn .ant-input-search-button {
    color: #FFF !important;
    background-color: ${theme['@info-color']};
  }

  & .attr-section {
    border: 1px solid #D9D9D9;
  }
    
  & .attr-section-cell-ctn {
    padding: 12px;
  }

  & .attr-section-header {
    background-color: #FAFAFA;
    color: #546279;
    padding: 12px 16px;
  }

  & .attr-section-header:hover {
    cursor: pointer;
  }

  & .attr-section-title {
    font-size: 16px;
    font-weight: 550;
  }

  & .common-large-screen-selector-filter {
    width: 100%;
  }

  & .common-selector-filter {
    width: 180px;
  }

  & .filter-ctn {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    height: min-content;
    margin-bottom: 12px;
    padding: 12px;
  }

  & .label-icon {
    cursor: pointer;
  }

  & .label-icon:hover {
    background-color: #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
  }

  & .large-screen-area-wrapper {
    width: calc(100% - 258px);
  }

  & .large-screen-filter-space {
    width: 220px;
  }

  & .panel-tilte {
    font-weight: 600;
  }
`;

type Props = {
  dispatch?: Function;
  focused: boolean;
  getClassification: Function;
  isFullscreen?: boolean;
  isInDialog?: boolean;
  loadAttributesChannels: Function;
  loadAttributes: Function;
  previewImage?: Function;
  productId?: string;
  state?: StringKAnyVPair;
};

const Dropship = (props: Props) => {
  const CELL_CHANNEL_HEIGHT = 52;
  const CELL_ROW_HEIGHT = 40;
  const CELL_SECTION_PADDING = 12;
  const CLASSIFICATION_CHANNEL_NAME = 'Classification';
  const CUSTOM_CHANNEL_NAME = 'Common Product Attribute';
  const DROPSHIPPER_CHANNEL_NAME = 'Dropshipper';
  const DROPSHIPPING_CHANNEL_NAME = 'Dropshipping Supplier';
  //const CUT_ROW_THRESHOLD = 40;
  const LIST_AREA_ID = 'attr-section-ctn';
  const LIST_VIEW_TYPE_CHANNEL = 'channel';
  const LIST_VIEW_TYPE_ATTR_ROW = 'attr_row';
  const TEXT_SEARCH_TIMEOUT = 1000;
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [allSectionExpanded, setAllSectionExpanded] = useState(false);
  const [attrListKey, setAttrListKey] = useState(randomString());
  const [attrSearchBoxKey, setAttrSearchBoxKey] = useState(randomString());
  const [attrSearchChannels, setAttrSearchChannels] = useState<string[]>([]);
  const [attrSearchCollections, setAttrSearchCollections] = useState<string[]>([]);
  const [attrSearchGroups, setAttrSearchGroups] = useState<string[]>([]);
  const [attrSearchText, setAttrSearchText] = useState('');
  const [channelAttrDict, setChannelAttrDict] = useState<StringKAnyVPair>({});
  const [channelList, setChannelList] = useState<StringKAnyVPair[]>([]);
  const [channelNameList, setChannelNameList] = useState<string[]>([]);
  // const [classificationList, setClassificationList] = useState<StringKAnyVPair[]>([]);
  const [collectionList, setCollectionList] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [currentImage, setCurrentImage] = useState<StringKAnyVPair>({});
  const [groupList, setGroupList] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [currentProductId, setProductId] = useState(props.productId);
  const [enableDropshipper, setEnableDropshipper] = useState(false);
  const [enableDropshipSupplier, setEnableDropshipSupplier] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchingAttrName, setIsSearchingAttrName] = useState(false);
  // eslint-disable-next-line
  const [previewImageVisible, setPreviewImageVisible] = useState(false);
  // eslint-disable-next-line
  const [productClassification, setProductClassification] = useState<StringKAnyVPair>({});
  const [scrollEvtTop, setScrollEvtTop] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [useCommonAttribute, setUseCommonAttribute] = useState(false);
  const attrListRef = React.useRef<any>(null);
  const wrapperRef = React.useRef<any>(null);
  let textSearchTimer: any = 0;

  const attrListHeight = React.useMemo(() => {
    let hd = props.isFullscreen ? 230 : 440;

    if (typeof props.isFullscreen === 'undefined') hd = 380;

    return window.innerHeight - hd;
  }, [props]);

  const channelAttrCells = (attrs: StringKAnyVPair[]) => {
    console.log('cell rendered');
    return attrs.map(e => (
      <span className="attr-cell" key={e.attributeNum}>
        <Row align="middle">
          <FormLabel className="attr-label">
            <Typography.Text ellipsis={{ tooltip: e.attributeName }}>
              {e.attributeName}
            </Typography.Text>
          </FormLabel>
          <AttrEditor
            attr={e}
            className="attr-editor-wrapper"
            dispatch={props.dispatch}
            key={e.attributeNum}
            productId={props.productId || ''}
            state={props.state}
            isDropship
          />
        </Row>
      </span>
    ));
  };

  // eslint-disable-next-line
  const channelAttrPanels = () => {
    return channelNameList.map(name => {
      const item = channelAttrDict[name];
      //const expanded = activePanels.indexOf(name) > -1;
      //console.log('expaned', expanded);

      return (
        <Collapse.Panel
          key={`${name}`}
          header={<span className="panel-title">{name}</span>}
        >
          {channelAttrCells(item.attrs)}
        </Collapse.Panel>
      );
    });
  };

  // eslint-disable-next-line
  const channelAttrSections = () => {
    return channelNameList.map(name => {
      const item = channelAttrDict[name];

      if (item && typeof item === 'object' && typeof item.show === 'undefined') {
        console.log('in complete data', item);
        return <></>;
      }

      return (
        <div
          className="attr-section"
          key={`${name}`}
          style={{ display: item.show ? '' : 'none' }}
        >
          <div
            className="attr-section-header"
            onClick={() => onSectionCollapseChange(name)}
          >
            {activePanels.indexOf(name) > -1 ?
              <DownOutlined /> :
              <RightOutlined />
            }
            &nbsp;&nbsp;
            <span className="attr-section-title">{name}</span>
          </div>
          <div
            id={`${item.uniqueId}`}
            className="attr-section-cell-ctn"
            style={{ display: 'none' }}
          >
            {activePanels.indexOf(name) > -1 && channelSectionCells(item.attrs, item.uniqueId)}
          </div>
        </div>
      );
    });
  };

  // eslint-disable-next-line
  const channelAttrSections2 = () => {
    const list = getAttributesViewList(useCommonAttribute);

    //console.log('l -->', list);
    const AttrRow = (data: any) => {
      const { index, style } = data;
      const item = list[index];
      const itemStyle = { ...style };

      if (item) {
        itemStyle.height = item.itemHeight;
        itemStyle.top = item.itemTop;
        itemStyle.marginBottom = item.marginBottom;
        itemStyle.marginTop = item.marginTop;
      }

      return item && item.itemType === LIST_VIEW_TYPE_CHANNEL ? (
        <div
          className="attr-section"
          key={`${item.channelName}`}
          //style={{ display: item.show ? '' : 'none' }}
          style={itemStyle}
        >
          <div
            className="attr-section-header"
            onClick={() => onSectionCollapseChange(item.channelName)}
          >
            {activePanels.indexOf(item.channelName) > -1 ?
              <DownOutlined /> :
              <RightOutlined />
            }
            &nbsp;&nbsp;
            <span className="attr-section-title">{item.channelName}</span>
          </div>
        </div>
      ) : (
        <div style={itemStyle}>
          {item.row.map((e: StringKAnyVPair) => (
            <span
              className="attr-cell"
              key={e.attributeNum}
              style={{ display: e.show ? '' : 'none' }}
            >
              <Row align="middle">
                <FormLabel className="attr-label">
                  <Typography.Text ellipsis={{ tooltip: e.attributeName }}>
                    {e.attributeName}
                  </Typography.Text>
                </FormLabel>
                <AttrEditor
                  attr={e}
                  className="attr-editor-wrapper"
                  dispatch={props.dispatch}
                  key={e.attributeNum}
                  productId={props.productId || ''}
                  state={props.state}
                  isDropship
                />
              </Row>
            </span>
          ))}
        </div>
      );
    };

    const getItemSize = (index: number) => {
      const item = list[index];
      let ret = 0;

      if (item) {
        ret = item.itemType === LIST_VIEW_TYPE_CHANNEL ?
          item.itemHeight : CELL_ROW_HEIGHT;
      }

      return ret;
    };

    const totalHeight = list.reduce((p, c) => p + c.itemHeight + c.marginTop + c.marginBottom, 0);

    const handleOnWheel = (evt: any) => {
      const { currentTarget } = evt;

      if (currentTarget) {
        if (currentTarget.parentNode.id === LIST_AREA_ID) {
          const listDom = currentTarget.firstChild;

          if (listDom) {
            if (listDom.offsetHeight > totalHeight) {
              listDom.style.height = `${totalHeight}px`;
            }
          }
        }
      }
    };

    const outerElementType = forwardRef<HTMLInputElement>((props, ref) => (
      <div ref={ref} onWheel={handleOnWheel} {...props} />
    ));

    return (
      <VariableSizeList
        estimatedItemSize={10}
        height={attrListHeight}
        itemCount={list.length}
        itemSize={getItemSize}
        key={attrListKey}
        outerElementType={outerElementType}
        ref={attrListRef}
      >
        {AttrRow}
      </VariableSizeList>
    );
  };

  type ASProps = {
    ctnId: string;
  };

  // eslint-disable-next-line
  const ChannelAttrSections3 = (aps: ASProps) => {
    const { ctnId } = aps;
    const list = getAttributesViewList(useCommonAttribute);

    //console.log('ll->', list);
    const AttrRow = (virtualRow: any) => {
      const item = list[virtualRow.index];

      return (
        <div
          key={virtualRow.index}
          className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: getItemHeight(virtualRow.index),
            transform: `translateY(${virtualRow.start}px)`,
          }}
        >
          {item && item.itemType === LIST_VIEW_TYPE_CHANNEL ? (
            <div className="attr-section">
              <div
                className="attr-section-header"
                onClick={() => onSectionCollapseChange(item.channelName)}
              >
                {activePanels.indexOf(item.channelName) > -1 ?
                  <DownOutlined /> :
                  <RightOutlined />
                }
                &nbsp;&nbsp;
                {/*<span className="attr-section-title">{item.channelName === CUSTOM_CHANNEL_NAME ? item.channelName : `${item.channelName} Specific`}</span>*/}
                <span className="attr-section-title">{item.channelName === CUSTOM_CHANNEL_NAME ? item.channelName : getGroupName(item.channelName)}</span>
              </div>
            </div>
          ) : (
            <div>
              {item.row.map((e: StringKAnyVPair) => (
                <span
                  className="attr-cell"
                  key={e.attributeNum}
                  style={{ display: e.show ? '' : 'none' }}
                >
                  <Row align="middle">
                    <FormLabel className="attr-label">
                      <Typography.Text ellipsis={{ tooltip: e.attributeName }}>
                        {/*e.attributeName*/}
                        {getAttributeLabel(e)}
                      </Typography.Text>
                    </FormLabel>
                    <AttrEditor
                      attr={e}
                      className="attr-editor-wrapper"
                      dispatch={props.dispatch}
                      getScrollWrapper={getFormScrollWrapper}
                      key={e.attributeNum}
                      productId={props.productId || ''}
                      refreshAttrs={refreshAttributes}
                      state={props.state}
                      isDropship
                    />
                  </Row>
                </span>
              ))}
            </div>
          )}
        </div>
      );
    };

    const getAttributeLabel = (attr: StringKAnyVPair) => {
      const view = attr.attributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL && attr.productAttributeValue ?
        (<>
          <EyeOutlined className="label-icon" onClick={evt => previewAttrImage(attr, evt)} />
          &nbsp;
        </>)
        : null;

      return (<>
        {view}
        {attr.attributeName}
      </>);
    };

    const getItemHeight = (index: number) => {
      const item = list[index];
      let ret = 0;

      if (item) {
        ret = item.itemType === LIST_VIEW_TYPE_CHANNEL ?
          item.itemHeight : CELL_ROW_HEIGHT;
      }

      return ret;
    };

    const totalHeight = list.reduce((p, c) => p + c.itemHeight + c.marginTop + c.marginBottom, 0);

    const rowVirtualizer = useVirtualizer({
      count: list.length,
      getScrollElement: () => document.getElementById(ctnId),
      estimateSize: (i) => getItemHeight(i),
      overscan: 5,
    });

    const setContainerHeight = () => {
      const ctn = document.getElementById(ctnId);

      if (ctn) {
        const rect = ctn.getBoundingClientRect();
        const wh = window.innerHeight;
        let h = wh - rect.top - 40;

        if (props.isInDialog) {
          if (props.isFullscreen) {
            h = wh - rect.top - 30;
          } else {
            h = wh - rect.top - 100;
          }
        }

        // console.log('rect', rect, h);
        ctn.style.height = `${h}px`;
      }
    };

    setContainerHeight();

    return (
      <div
        style={{ height: totalHeight, position: 'relative' }}
      >
        {rowVirtualizer.getVirtualItems().map(virtualRow => (

          AttrRow(virtualRow)
        ))}
      </div>
    );
  };

  // eslint-disable-next-line
  const channelSectionCells = (attrs: StringKAnyVPair[], ctnId: string) => {
    const visibleCells = attrs.filter(e => e.show);

    return visibleCells.map(e => (
      <span
        className="attr-cell"
        key={e.attributeNum}
        style={{ display: e.show ? '' : 'none' }}
      >
        <Row align="middle">
          <FormLabel className="attr-label">
            <Typography.Text ellipsis={{ tooltip: e.attributeName }}>
              {e.attributeName}
            </Typography.Text>
          </FormLabel>
          <AttrEditor
            attr={e}
            className="attr-editor-wrapper"
            dispatch={props.dispatch}
            key={e.attributeNum}
            productId={props.productId || ''}
            state={props.state}
            isDropship
          />
        </Row>
      </span>
    ));
  };

  // eslint-disable-next-line
  const channelSectionCells2 = (attrs: StringKAnyVPair[], ctnId: string) => {
    /*const ctn = document.getElementById(ctnId);
    const height = window.innerHeight;
    const rect = ctn?.getBoundingClientRect();*/
    const visibleCells = attrs.filter(e => e.show);
    //let [paddingBottom, paddingTop] = [CELL_SECTION_PADDING, CELL_SECTION_PADDING];

    /*if (ctn && rect && typeof rect === 'object') {
      const rowCount = Math.ceil(visibleCells.length / 2);
      const ctnHeight = CELL_ROW_HEIGHT * rowCount + CELL_SECTION_PADDING * 2;
      const top = rect.y;
      const bottom = rect.y + ctnHeight;

      //ctn.style.height = `${ctnHeight}px`;

      if ((top < 0 && rect.bottom > 0) ||
          (top >= 0 && top < height)) {
        console.log('top ->', top, scrollTop);
        if (top < 0) {
          let hiddenRowCount = Math.ceil((0 - top) / CELL_ROW_HEIGHT);

          console.log('top h ->', top, rect.bottom, hiddenRowCount);
          //ctn.style.paddingTop = `${hiddenRowCount * CELL_ROW_HEIGHT + CELL_SECTION_PADDING}px`;
          if (hiddenRowCount > CUT_ROW_THRESHOLD) {
            if (rect.bottom > 0 && rect.bottom < height) {
              hiddenRowCount = Math.ceil((rect.height - rect.bottom) / CELL_ROW_HEIGHT);
              console.log('hiddenrowcount', hiddenRowCount);
            }

            hiddenRowCount -= 20;
            paddingTop = hiddenRowCount * CELL_ROW_HEIGHT + CELL_SECTION_PADDING;
            console.log('before t slice', visibleCells.length, hiddenRowCount);
            visibleCells.splice(0, hiddenRowCount * 2);
            console.log('after t slice', visibleCells.length, paddingTop);
          }
        }

        if (bottom > height) {
          const hiddenRowCount = Math.floor((bottom - height) / CELL_ROW_HEIGHT) - CUT_ROW_THRESHOLD;

          if (hiddenRowCount > 0) {
            const idx = visibleCells.length - hiddenRowCount * 2 - 1;

            console.log('hb ->', bottom, height, hiddenRowCount);
            //ctn.style.paddingBottom = `${hiddenRowCount * CELL_ROW_HEIGHT + CELL_SECTION_PADDING}px`;
            paddingBottom = hiddenRowCount * CELL_ROW_HEIGHT + CELL_SECTION_PADDING;

            if (idx > 0) {
              visibleCells.splice(idx, hiddenRowCount * 2);
            }
          }
        }
        console.log('need draw section', ctnId);
        console.log('render cells', scrollTop, rowCount, ctn, rect, ctnHeight, top);
        console.log(ctn.offsetTop, ctn.offsetHeight);
        ctn.style.paddingBottom = `${paddingBottom}px`;
        ctn.style.paddingTop = `${paddingTop}px`;
      } else {
        console.log('not need to draw ---- ---->', ctn, ctnHeight, rect);
        if (ctnHeight > CELL_SECTION_PADDING) {
          ctn.style.paddingTop = `${ctnHeight - CELL_SECTION_PADDING}px`;
          return null;
        }
      }
    }*/

    return visibleCells.map(e => (
      <span
        className="attr-cell"
        key={e.attributeNum}
        style={{ display: e.show ? '' : 'none' }}
      >
        <Row align="middle">
          <FormLabel className="attr-label">
            <Typography.Text ellipsis={{ tooltip: e.attributeName }}>
              {e.attributeName}
            </Typography.Text>
          </FormLabel>
          <AttrEditor
            attr={e}
            className="attr-editor-wrapper"
            dispatch={props.dispatch}
            key={e.attributeNum}
            productId={props.productId || ''}
            state={props.state}
            isDropship
          />
        </Row>
      </span>
    ));
  };

  const channelOptions = () => {
    return channelList.map(e => (
      <Select.Option key={e.channelNum} value={e.channelName}>
        {e.channelName}
      </Select.Option>
    ));
  };

  const clearFilters = () => {
    setAttrSearchBoxKey(randomString());
    setAttrSearchChannels([]);
    setAttrSearchCollections([]);
    setAttrSearchGroups([]);
    setAttrSearchText('');
    searchAttr({
      attr: '',
      channels: [],
      collections: [],
      groups: [],
    });
  };

  const collectionOptions = (groups: StringKAnyVPair[]) => {
    return groups.map(e => (
      <Select.Option key={e.rowNum} value={e.rowNum}>
        {e.attributeSetName}
      </Select.Option>
    ));
  };

  const getAttributesAreaClassName = () => {
    const cls: string[] = ['attr-area-wrapper'];

    if (props.isFullscreen) {
      cls.push('large-screen-area-wrapper');
    }

    return cls.join(' ');
  };

  const getAttributesViewList = (useCommon: boolean) => {
    const list: StringKAnyVPair[] = [];

    channelNameList.forEach(name => {
      if(!useCommon && name==='Common Product Attribute') return;
      const item = channelAttrDict[name];

      if (item && typeof item === 'object') {
        if (typeof item.show === 'undefined') {
          console.log(`Incomplete data ${item}`);
        } else if (item.show) {
          const chn = { ...item };

          chn.channelName = name;
          chn.itemHeight = CELL_CHANNEL_HEIGHT;
          chn.itemType = LIST_VIEW_TYPE_CHANNEL;
          //console.log(chn.attrs);
          delete chn.attrs;
          list.push(chn);

          if (activePanels.indexOf(name) > -1) {
            const { attrs } = item;

            if (Array.isArray(attrs)) {
              const cells = attrs.filter(e => e.show);
              let rowIndex = 0;

              for (let i = 0; i < cells.length;) {
                const item: StringKAnyVPair = {};

                item.channelName = name;
                item.itemHeight = CELL_ROW_HEIGHT;
                item.itemType = LIST_VIEW_TYPE_ATTR_ROW;
                item.row = [];
                item.rowIndex = rowIndex;

                if (cells[i]) item.row.push(cells[i]);

                if (cells[i + 1]) item.row.push(cells[i + 1]);

                if (item.row.length > 0) list.push(item);

                i += 2;
                rowIndex += 1;
              }
            }
          }
        }
      } else {
        console.log(`Channel [${name}] data not exists`);
      }
    });

    list.forEach((e, i) => {
      e.marginBottom = 0;
      e.marginTop = 0;

      if (e.itemType === LIST_VIEW_TYPE_ATTR_ROW) {
        if (e.rowIndex === 0) {
          e.marginTop = CELL_SECTION_PADDING;
        }

        if (list[i + 1] && list[i + 1].itemType === LIST_VIEW_TYPE_CHANNEL) {
          e.marginBottom = CELL_SECTION_PADDING;
        }
      }

      if (i > 0) {
        e.itemTop = list[i - 1].itemTop + list[i - 1].itemHeight + list[i - 1].marginTop + list[i - 1].marginBottom;
      } else {
        e.itemTop = 0;
      }
    });

    return list;
  };

  const getFilterSpaceClassName = () => {
    const cls: string[] = [];

    if (props.isFullscreen) {
      cls.push('large-screen-filter-space');
    }

    return cls.join(' ');
  };

  const getFormScrollWrapper = () => {
    return document.getElementById(LIST_AREA_ID);
  };

  const getGroupName = (n: string) => {
    let name = n;

    if (name === DROPSHIPPER_CHANNEL_NAME) {
      if (enableDropshipper) {
        name = 'From Supplier (My Network Dropshipping)';
      }

      /*if (enableDropshipSupplier) {
        name = 'To Dropshipper (My Network Dropshipping)';
      }*/
    }

    if (name === DROPSHIPPING_CHANNEL_NAME) {
      /*if (enableDropshipper) {
        name = 'From Supplier (My Network Dropshipping)';
      }*/

      if (enableDropshipSupplier) {
        name = 'To Dropshipper (My Network Dropshipping)';
      }
    }

    return name;
  };

  const getWrapperClassName = () => {
    const cls: string[] = [];

    if (props.isFullscreen) {
      cls.push('large-screen-wrapper');
    }

    return cls.join(' ');
  };

  const getVisibleSections = () => {
    return channelNameList.filter(chn => channelAttrDict[chn].show);
  };

  const groupOptions = (groups: StringKAnyVPair[]) => {
    return groups.map(e => (
      <Select.Option key={e.AttributeNum} value={e.Group2}>
        {e.Group2} 
        {/* don't now Group1 and Group2, who is right */}
      </Select.Option>
    ));
  };

  const loadProductAttributes = async (
    productId: string,
  ) => {
    try {
      const channels: string[] = [];
      const dict: StringKAnyVPair = {};
      const list = await Products.fetchProductDropships(productId);

      // console.log('pat->', list);
      list.forEach(e => {
        e.show = true;

        if (Array.isArray(e.classificationNums) && e.classificationNums.length > 0) {
          e.channelName = CLASSIFICATION_CHANNEL_NAME;
          //console.log('cf atr->', e);
        }

        const keyName = e.channelName.trim() || CUSTOM_CHANNEL_NAME;

        if (channels.indexOf(e.channelName) < 0) {
          // can not use keyName here
          channels.push(e.channelName);
        }

        if (!dict[keyName]) {
          dict[keyName] = {
            attrs: [],
            channelNum: e.channelNum,
            //expanded: false,
            rendered: false,
            uniqueId: `G-${randomString()}`,
            show: true,
          };
        }

        dict[keyName].attrs.push(e);
      });

      for (let i = channels.length - 1; i >= 0; i--) {
        if (channels[i] === CLASSIFICATION_CHANNEL_NAME) {
          channels.splice(i, 1);
          break;
        }
      }

      channels.sort(sortCustomString);
      //if (channels.length > 0 && !channels[0]) channels[0] = CUSTOM_CHANNEL_NAME;
      for (let i = 0; i < channels.length; i++) {
        if (!channels[i]) {
          channels[i] = CUSTOM_CHANNEL_NAME;
          break;
        }
      }

      if (typeof dict[CLASSIFICATION_CHANNEL_NAME] === 'object') {
        channels.splice(0, 0, CLASSIFICATION_CHANNEL_NAME);
      }

      for (let i = 0; i < channels.length; i++) {
        // put custom attribute at the beginning of the queue
        if (channels[i] === CUSTOM_CHANNEL_NAME) {
          channels.splice(i, 1);
          channels.splice(0, 0, CUSTOM_CHANNEL_NAME);
          break;
        }
      }

      for (let k in dict) {
        dict[k].attrs.sort(sortByAttrName);
      }

      console.log('cha', channels, dict);
      setChannelAttrDict(dict);
      setChannelNameList(channels.sort((a,b)=>b === 'Dropshipper' ? -1 : 0).sort((a,b)=>b === 'Dropshipping Supplier' ? -1 : 0));
    } catch (e) {
      message.error(`Fetched attributes error: ${e}`);
    }
  };

  /*const loadProductClassification = (classifications: StringKAnyVPair[]) => {
    const clsNum = props.getClassification();
    const cls = classifications.filter(e => e.ClassificationNum === clsNum);
    console.log('cls num', clsNum, cls);

    if (cls.length > 0) {
      setProductClassification(cls[0]);
    }
  };*/

  const loadSysAttributes = async () => {
    const attrs = await loadAttributes();
    const aSet = await loadAttributeSet();
    const settingValue = await getProfileSettingValue('UseCommonAttributes', '0');

    setUseCommonAttribute(settingValue !== '0')

    if (Array.isArray(attrs)) {
      const cDict: StringKAnyVPair = {};
      const collection: StringKAnyVPair[] = [];
      const gDict: StringKAnyVPair = {};
      const group: StringKAnyVPair[] = [];
      // console.log('at->', attrs);
      attrs.forEach(e => {
        if (e.Group1) {
          if (!cDict[e.Group1]) {
            cDict[e.Group1] = true;
            collection.push(e);
          }
        }

        if (e.Group2) {
          if (!gDict[e.Group2]) {
            gDict[e.Group2] = true;
            group.push(e);
          }
        }
      });
      //setCollectionList(collection);
      // console.log(group)
      setGroupList(group.sort((a, b) => (a.Group2.toUpperCase() > b.Group2.toUpperCase()) ? 1 : -1));
    }

    if (Array.isArray(aSet)) {
      // console.log('aSet', aSet)
      setCollectionList(aSet.sort((a, b) => (a.attributeSetName.toUpperCase() > b.attributeSetName.toUpperCase()) ? 1 : -1));
    }
  };

  const loadSysAttributesChannels = async () => {
    const ac = await loadAttributesChannels();

    if (Array.isArray(ac)) {
      // console.log('ac->', ac);
      setChannelList(ac.sort((a, b) => a.channelName.toUpperCase() >  b.channelName.toUpperCase() ? 1 : -1));
    }
  };

  /*const loadSysClassifications = async () => {
    return await loadClassifications();
  };*/

  // eslint-disable-next-line
  const loadInitialData = async (productId: string) => {
    setIsLoading(true);

    if (!inited) {
      const enableAsDropShipper = await getProfileSettingValue('EnableAsDropShipper', '0') === '1';
      const enableAsDropShippingSupplier = await getProfileSettingValue('EnableAsDropShippingSupplier', '0') === '1';

      setEnableDropshipper(enableAsDropShipper);
      setEnableDropshipSupplier(enableAsDropShippingSupplier);
    }

    loadSysAttributes();
    loadSysAttributesChannels();
    await loadAttributeOptions();
    //const cls = await loadSysClassifications();
    await loadProductAttributes(productId);
    //console.log('cls ->', cls);
    // loadProductClassification(cls);
    setIsLoading(false);
  };

  // eslint-disable-next-line
  const onCollapseChange = (val: any) => {
    const chnList = getVisibleSections();
    const keys = val as string[];

    setActivePanels(keys);
    //setAllSectionExpanded(keys.length === channelNameList.length);
    setAllSectionExpanded(keys.length === chnList.length);
    // setChannelAttrDict({ ...channelAttrDict });
  };

  // eslint-disable-next-line
  const onPreviewImageVisibleChange = (visible: boolean) => {
    if (!visible) {
      setPreviewImageVisible(visible);
      setCurrentImage({});
    }
  };

  // eslint-disable-next-line
  const onScroll = (evtTop: number) => {
    console.log('on s', evtTop, inited);
    setScrollEvtTop(evtTop);
  };

  const onSearchChannelChange = (value: any) => {
    // console.log('chann', value);
    setAttrSearchChannels(value);
    setTimeout(() => {
      searchAttr({ channels: value });
    }, 500);
  };

  const onSearchCollectionChange = (value: any) => {
    setAttrSearchCollections(value);
    searchAttr({ collections: value });
  };

  const onSearchGroupsChange = (value: any) => {
    setAttrSearchGroups(value);
    searchAttr({ groups: value });
  };

  const onSearchText = (text: any) => {
    //console.log('search', text);
    setAttrSearchText(text);

    if (text && text.length < 3) {
      message.info('Please enter at least 3 characters to search');
      return;
    }

    setIsSearchingAttrName(true);
    setTimeout(() => {
      setIsSearchingAttrName(false);
    }, TEXT_SEARCH_TIMEOUT);
    searchAttr({ attr: text });
  };

  const onSearchTextChange = (evt: any) => {
    const t = evt.target.value.trim();

    if (textSearchTimer) {
      clearTimeout(textSearchTimer);
      textSearchTimer = 0;
    }

    setTimeout(() => {
      setAttrSearchText(t);
    }, 500);
    // do not modify state at here, so the input will be blocked
    // setAttrSearchText(t);
    /*textSearchTimer = setTimeout(() => {
      if (t && t.length < 3) {
        message.info('Please enter at least 3 characters to search');
        return;
      }

      searchAttr({attr: t});
    }, TEXT_SEARCH_TIMEOUT);*/
  };

  const onSectionCollapseChange = (name: string) => {
    const chnList = getVisibleSections();
    const list = [...activePanels];
    const idx = activePanels.indexOf(name);
    const sectionId = channelAttrDict[name].uniqueId;
    const section = wrapperRef?.current.querySelector(`#${sectionId}`)
    let display = 'none';

    if (idx > -1) {
      for (let i = list.length - 1; i >= 0; i--) {
        if (list[i] === name) {
          list.splice(i, 1);
        }
      }
    } else {
      list.push(name);
      display = '';
    }

    setActivePanels(list);
    //setAllSectionExpanded(list.length === channelNameList.length);
    setAllSectionExpanded(list.length === chnList.length);
    // console.log('-->', section, wrapperRef?.current);
    // console.log('act ->', activePanels, list);
    if (section) {
      section.style.display = display;
    }

    console.log('set key ...');
    setAttrListKey(randomString());
    setTimeout(() => {
      const list = getAttributesViewList(useCommonAttribute);
      let idx = 0;

      for (let i = 0; i < list.length; i++) {
        if (list[i].channelName === name) {
          idx = i;
          break;
        }
      }

      attrListRef?.current?.scrollToItem(idx, 'center');
    }, 50);
  };

  const previewAttrImage = (attr: StringKAnyVPair, evt: any) => {
    setCurrentImage(attr);
    setPreviewImageVisible(true);

    if (typeof props.previewImage === 'function') {
      //console.log('pre ->', attr, evt.clientX, evt.pageX);
      props.previewImage({
        id: attr.attributeNum,
        src: attr.productAttributeValue,
        title: attr.attributeName,
        x: evt.clientX - 50,
        y: evt.clientY - 50,
      });
    }
  };

  const refreshAttributes = () => {
    setChannelAttrDict({ ...channelAttrDict });
  };

  const searchAttr = (query: StringKAnyVPair) => {
    const attrDict = { ...channelAttrDict };
    const attr = 'attr' in query ? query.attr : attrSearchText;
    const channels = 'channels' in query ? query.channels : attrSearchChannels;
    const collections = 'collections' in query ? query.collections : attrSearchCollections;
    const groups = 'groups' in query ? query.groups : attrSearchGroups;

    // console.log('attr', attr);
    // console.log('channels', channels);
    // console.log('collections', collections);
    // console.log('groups', groups);
    setAttrSearchText(attr);
    //setAttrSearchChannels(channels);
    searchAttrByChannel(attrDict, channels);
    searchAttrByCollection(attrDict, collections);
    searchAttrByGroup(attrDict, groups);

    if (attr) searchAttrByName(attrDict, attr);

    setChannelAttrVisibility(attrDict);
    setChannelAttrDict(attrDict);
  };

  const searchAttrByChannel = (ad: StringKAnyVPair, channels: string[]) => {
    if (channels.length > 0) {
      const cs = channelList.filter(e => channels.indexOf(e.channelName) > -1).map(e => e.channelNum);

      for (let k in ad) {
        //ad[k].show = channels.indexOf(k) > -1;
        ad[k].show = cs.indexOf(ad[k].channelNum) > -1;
      }
    } else {
      for (let k in ad) {
        ad[k].show = true;
      }
    }

    for (let k in ad) {
      // eslint-disable-next-line
      ad[k].attrs.forEach((e: StringKAnyVPair) => {
        e.show = true;
      });
    }
  };

  const searchAttrByCollection = (ad: StringKAnyVPair, cs: string[]) => {
    //console.log('ss->', ad, cs);
    if (cs.length > 0) {
      for (let k in ad) {
        // eslint-disable-next-line
        //ad[k].attrs.forEach((e: StringKAnyVPair) => e.show = cs.indexOf(e.collection) > -1);
        //ad[k].attrs.forEach((e: StringKAnyVPair) => e.show = cs.indexOf(e.attributeSetNum) > -1);
        // eslint-disable-next-line
        ad[k].attrs.forEach((e: StringKAnyVPair) => e.show = e.attributeSetNums.some((n: string) => cs.indexOf(n) > -1));
      }
    }
  };

  const searchAttrByGroup = (ad: StringKAnyVPair, gs: string[]) => {
    if (gs.length > 0) {
      for (let k in ad) {
        // eslint-disable-next-line
        ad[k].attrs.forEach((e: StringKAnyVPair) => e.show = gs.indexOf(e.group) > -1);
      }
    }
  };

  const searchAttrByName = (ad: StringKAnyVPair, name: string) => {
    const key = name.toLowerCase();

    for (let k in ad) {
      // eslint-disable-next-line
      ad[k].attrs.forEach((e: StringKAnyVPair) => e.show = e.attributeName.toLowerCase().indexOf(key) > -1);
    }
  };

  const searchAttrButtonClassName = () => {
    const cls = [props.isFullscreen ? '' : 'attr-name-filter'];

    if (isSearchingAttrName) {
      cls.push('attr-name-searching-btn');
    }

    return cls.join(' ');
  };

  const setAllPanelsActive = () => {
    setActivePanels([...channelNameList]);
    // setChannelAttrDict({...channelAttrDict});
  };

  const setChannelAttrVisibility = (ad: StringKAnyVPair) => {
    const visibleChannels: string[] = [];

    for (let k in ad) {
      if (ad[k].show) {
        const attrs = ad[k].attrs as StringKAnyVPair[];

        ad[k].show = attrs.filter(e => e.show).length > 0;
      }

      if (ad[k].show) {
        visibleChannels.push(k);
      }
    }

    const collapseAll = Object.keys(ad).length === visibleChannels.length;

    for (let k in ad) {
      if (ad[k].show) {
        const ctn = wrapperRef?.current.querySelector(`#${ad[k].uniqueId}`);

        if (ctn) {
          ctn.style.display = collapseAll ? 'none' : '';
        }
      }
    }

    setActivePanels(collapseAll ? [] : visibleChannels);
    setAllSectionExpanded(collapseAll ? false : true);
  };

  // eslint-disable-next-line
  const sortByAttrName = (a: any, b: any): number => {
    const strA = a.attributeName ? a.attributeName.toUpperCase() : ''; // ignore upper and lowercase
    const strB = b.attributeName ? b.attributeName.toUpperCase() : ''; // ignore upper and lowercase
    /*if (!strA || !strB) {
      return 0;
    }*/

    if (strA < strB) {
      return -1;
    }

    if (strA > strB) {
      return 1;
    }

    return 0;
  };

  const sortCustomString = (a: string, b: string) => {
    const strA = a.toUpperCase() || ''; // ignore upper and lowercase
    const strB = b.toUpperCase() || ''; // ignore upper and lowercase

    if (strA < strB) {
      return -1;
    }

    if (strA > strB) {
      return 1;
    }

    return 0;
  };

  const toggleAllSectionCollapseStatus = () => {
    const display = allSectionExpanded ? 'none' : '';

    if (allSectionExpanded) {
      setActivePanels([]);
    } else {
      setAllPanelsActive();
    }

    setAllSectionExpanded(!allSectionExpanded);

    for (let k in channelAttrDict) {
      const id = channelAttrDict[k].uniqueId;
      const section = wrapperRef?.current.querySelector(`#${id}`);

      // console.log('-->', id, display, section);
      if (section) section.style.display = display;
    }
  };

  React.useEffect(() => {
    const handleScroll = (evt: any) => {
      if (wrapperRef?.current) {
        //console.log('scroll', props.focused, inited);
        if (props.focused /*&& inited*/) {
          //console.log('scroll', evt);
          //console.log(evt.target.offsetTop, evt.target.scrollTop);
          onScroll(evt.target.scrollTop);
        }
      } else {
        // eslint-disable-next-line
        window.removeEventListener('scroll', handleScroll, true);
        console.log('remved scroll event');
      }
    };

    if (props.focused) {
      if (props.productId && (!inited || currentProductId !== props.productId)) {
        loadInitialData(props.productId);
        setInited(true);
        setProductId(props.productId);

        //window.addEventListener('scroll', handleScroll, true);
      }

      if (scrollEvtTop !== scrollTop) {
        setScrollTop(scrollEvtTop);
      }
    }

    return () => {
      /*setTimeout(() => {
      // eslint-disable-next-line
      if (!wrapperRef?.current) {
        console.log('tp->', props.focused);
      } else {
        console.log('maybe need to unmount');
      }
      }, 1000);*/
    };
  }, [
    currentProductId,
    inited,
    loadInitialData,
    onScroll,
    props,
    scrollEvtTop,
    scrollTop,
    wrapperRef,
  ]);

  return (<>
    <AttrWrapper className={getWrapperClassName()}>
      {channelNameList.length > 0 && (
        <Row className="filter-ctn">
          <Space
            className={getFilterSpaceClassName()}
            direction={props.isFullscreen ? 'vertical' : 'horizontal'}
          >
            <Input.Search
              allowClear
              className={searchAttrButtonClassName()}
              //enterButton
              key={attrSearchBoxKey}
              name="Filter"
              placeholder="Filter"
              onChange={onSearchTextChange}
              onSearch={onSearchText}
            //value={query}
            />
            <Select
              allowClear
              className={props.isFullscreen ? 'common-large-screen-selector-filter' : 'common-selector-filter'}
              mode="multiple"
              placeholder="Channel"
              onChange={onSearchChannelChange}
              showSearch
              value={attrSearchChannels}
            >
              {channelOptions()}
            </Select>
            <Select
              allowClear
              className={props.isFullscreen ? 'common-large-screen-selector-filter' : 'common-selector-filter'}
              mode="multiple"
              onChange={onSearchCollectionChange}
              placeholder="Operation Set"
              showSearch
              value={attrSearchCollections}
            >
              {collectionOptions(collectionList)}
            </Select>
            <Select
              allowClear
              className={props.isFullscreen ? 'common-large-screen-selector-filter' : 'common-selector-filter'}
              mode="multiple"
              placeholder="Group"
              onChange={onSearchGroupsChange}
              showSearch
            >
              {groupOptions(groupList)}
            </Select>
            <HoverBgButton
              hovertype="danger"
              onClick={clearFilters}
            >
              Clear Filters
            </HoverBgButton>
          </Space>
        </Row>
      )}
      {channelNameList.length > 0 && (
        <div className={getAttributesAreaClassName()} ref={wrapperRef}>
          <div className="attr-area-top-bar">
            <HoverBgButton
              hovertype="info"
              onClick={toggleAllSectionCollapseStatus}
            >
              {allSectionExpanded ? <DownOutlined /> : <RightOutlined />}
              {allSectionExpanded ? 'Collapse' : 'Expand'} All
            </HoverBgButton>
          </div>
          <div className="attr-area" id={LIST_AREA_ID}>
            {/*<Collapse
              activeKey={activePanels}
              onChange={onCollapseChange}
              style={{ width: '100%' }}
            >
              {channelAttrPanels()}
            </Collapse>*/}
            {/*channelAttrSections()*/}
            {/*channelAttrSections2()*/}
            {<ChannelAttrSections3 ctnId={LIST_AREA_ID} />}
          </div>
        </div>
      )}
    </AttrWrapper>
    {/*previewImageVisible && (
      <Image
        src={currentImage.productAttributeValue}
        style={{display: 'none'}}
        preview={{
          visible: previewImageVisible,
          src: currentImage.productAttributeValue,
          onVisibleChange: onPreviewImageVisibleChange,
        }}
      />
    )*/}
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default React.memo(Dropship);
