import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CSVLink, { CSVColumns } from '../components/common/CSVLink';
import { DataGrid } from '../components/common/datagrid/DataGrid';
import Heading from '../components/common/Heading';
import SearchBar, { SearchFields } from '../components/common/SearchBar';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { fetchCustomers } from '../services/customers';
import { useExecutePromise } from '../util/hooks';

// Link to go to customer page in full detail
const NameLink: React.FC<{ data: Entities.Customer, value?: string }> = ({ value, data }) =>
  (
    <Link to={`/customer-details/${data.customerCode}`}>
      {value || data.customerCode}
    </Link>
  );

// link to create a new customer
const NewCustomerLink: React.FC = () =>
  (
    <Link to="/customer/create">
      <Button type="primary">
        Add
        <PlusOutlined />
      </Button>
    </Link>
  );

const columns = [
  {
    header: 'Name',
    name: 'customerName',
    defaultFlex: 4,
    render({ value, data }: { data: Entities.Customer, value: string }) {
      return (
        <NameLink data={data} value={value} />
      );
    },
  },
  {
    header: 'Contact',
    name: 'contact',
    defaultFlex: 4,
  },
  {
    header: 'Phone',
    name: 'phone1',
    defaultFlex: 4,
  },
  {
    header: 'Email',
    name: 'email',
    defaultFlex: 4,
  },
  // Non-existent columns
  {
    header: 'Type',
    name: 'customerType',
    defaultFlex: 2,
  },
  {
    header: 'Status',
    name: 'customerStatus',
  },
];

const searchFields: SearchFields<Entities.Customer> = [
  'CustomerName',
  'Contact',
  'Phone1',
  'Email',
  'CustomerType',
  'CustomerStatus',
];

const csvColumns: CSVColumns = [
  {
    key: 'customerName',
    header: 'Customer Name',
  },
  {
    key: 'contact',
    header: 'Contact',
  },
  {
    key: 'phone1',
    header: 'Phone',
  },
  {
    key: 'email',
    header: 'Email',
  },
  {
    key: 'customerType',
    header: 'Customer Type',
  },
  {
    key: 'customerStatus',
    header: 'Customer Status',
  },
];

// Customers Component
const Customers: React.FC = () => {
  const [data, loading] = useExecutePromise(fetchCustomers, { customerList: [], customerListCount: 0 });
  const [filteredData, setFilteredData] = useState<Entities.Customer[]>([]);
  return (
    <ContentLayout>
      <Heading
        title="Customers"
        actions={(
          <NewCustomerLink />
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Row justify="space-between">
          <Col xs={24} lg={12}>
            <SearchBar
              data={data.customerList}
              fields={searchFields}
              reference="CustomerCode"
              onResult={setFilteredData}
            />
          </Col>
          <Col>
            <CSVLink
              filename="customers.csv"
              data={filteredData}
              columns={csvColumns}
            />
          </Col>
        </Row>
        <Spacer height={14} />
        <DataGrid
          idProperty="id"
          rowHeight={35}
          columns={columns}
          loading={loading}
          dataSource={filteredData}
          pagination
        />
      </SiteContent>
    </ContentLayout>
  );
};

export default Customers;
