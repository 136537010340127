import React, { useContext } from 'react';
import { Form, Tooltip } from 'antd';
import { componentMapping, FormElementProps, Type } from '../../components/common/FormElement';
import { ProductDetailScreenContext } from './context';

const AttributeFormElement: React.FC<FormElementProps> = ({
  inputType = Type.INPUT,
  inputProperties = {},
  formItemProperties = {},
  toolTip = '',
}) => {

  // @ts-ignore
  const ElementInput = componentMapping[inputType];
  const [state] = useContext(ProductDetailScreenContext);
  if (!state) {
    return null;
  }


  const element = (
    <Form.Item
      hidden={!state.booleanMap[`${formItemProperties?.name}`]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formItemProperties}
    >
      <ElementInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProperties}
      />
    </Form.Item>
  );

  return toolTip !== '' ? (
    <Tooltip title={toolTip}>
      {element}
    </Tooltip>
  ) : element;
};

export default AttributeFormElement;
