import React from "react";
//   import { DataGrid } from '../../../components/common/datagrid/DataGrid3';
import Heading from "../../../components/common/Heading";
import Spacer from "../../../components/common/Spacer";
import SiteContent from "../../../components/SiteContent";
import ContentLayout from "../../../components/ContentLayout";
import TowerSetting from '../../../components/TowerSetting';


const PageContent: React.FC = () => {
  return (
    <ContentLayout>
      <Heading title="Control Tower Layout" />
      <Spacer />
      <SiteContent className="common-fixed-box" flexGrow>
        <TowerSetting />
      </SiteContent>
    </ContentLayout>
  );
};

export default PageContent;
