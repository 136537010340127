import React from 'react';
import { Input, Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

interface Props extends ModalProps {
  dataIndex: number;
  onClose: Function;
  onSave: Function;
  dataDict: StringKAnyVPair;
  visible: boolean;
};

const ChannelDataEditor = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState('');
  const [inited, setInited] = useState(false);
  const [title, setTitle] = useState('');

  const onCancel = () => {
    props.onClose();
  };

  const onTextChange = (evt: any) => {
    setCurrentValue(evt.target.value);
  };

  const saveData = () => {
    const { dataIndex, dataDict } = props;

    if (dataDict[dataIndex]) {
      const cd = dataDict[dataIndex];

      if (cd && typeof cd === 'object') {
        if (cd.attributeValue !== currentValue) {
          props.onSave(currentValue);
        }
      }
    }

    props.onClose();
  };

  // eslint-disable-next-line
  const setInitialData = () => {
    const { dataIndex, dataDict } = props;

    //console.log('ddi', dataIndex);
    if (dataDict[dataIndex]) {
      const vd = dataDict[dataIndex];

      setCurrentValue(vd.attributeValue);
      setTitle(vd.attributeName);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInitialData();
      setInited(true);
    }
  }, [inited, setInitialData]);

  return (<>
    <Modal
      centered
      maskClosable={false}
      onCancel={onCancel}
      onOk={saveData}
      title={`Edit ${title}`}
      visible={props.visible}
    >
      <div>
        {inited && (
          <Input
            defaultValue={currentValue}
            onChange={onTextChange}
          />
        )}
      </div>
    </Modal>
  </>);
};

export default ChannelDataEditor;
