import { FormInstance } from 'antd/es/form';
import React, { createContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';
import { BasicInfoFormInput } from './interfaces';
import Products, { addLabel, fetchLabels } from '../../services/products';
import { cartesian } from '../../util';
import { capitalizeWords } from '../../util/strings';
import { AttributeDropDown, RetailPriceInput, SKUInput } from './common';

export enum Actions {
  SET_EDITING,
  SET_LOADING,
  BEFORE_SUBMIT,
  SET_PRODUCT_DATA,
  SET_CLASSIFICATIONS,
  SET_LABELS,
  SET_CHANNELS,
  SET_EDITMODE,
  RESET_PRODUCT,
  SET_FORMUPDATED,
  SET_INVENTORY,
  SET_INVENTORYFORM,
  SET_INVENTORYTABLE,
  SET_PRODUCTTYPE,
  SET_BUNDLETYPE,
  SET_TABERRORDOTS,
  SET_ACTIVETAB,
  SET_ATTRIBUTES,
  SET_ATTRIBUTEFILTERING,
  RESET_ATTRIBUTEFILTERS,
  SET_CLASSIFICATION,
  CLEAR_VARYBYLIST,
  SET_PRODUCTS,
  SET_VARIATIONS_TABLE_COLUMNS,
  SET_OPENPRODUCTSMODAL,
  SET_VARIATIONSPRODUCTSTOSELECT,
  SET_SELECTEDVARIATIONPRODUCTS,
  DELETE_VARIATION,
  SET_PRODUCTS_LOADING,
  SET_ATTRIBUTESCHANNELS,
  FILTER_PRODUCTS,
  SET_PRODUCTEXT,
  SET_DC,
  SET_CHANNELSINV,
  FILTER_CHANNELSINV,
  SET_CHANNELSINV_DCS,
  SET_PRODUCTID,
  INIT_ATTRIBUTE_FILTER_BOOLS,
  SET_ATTRIBUTE_FILTER_BOOLS,
  SET_STYLEVARIATIONS,
  SET_STYLE_FORMATTER_ITEMS_ORDER,
  SET_ATTRIBUTE_FILTER_BOOLS_STYLE_MASTER,
  RECALCULATE_VARIATIONS,
  GENERATE_SKUS,
  FILTER_EDITREVIEW,
  FILTER_GENERATESKUS,
  SET_PRODUCT_STYLEMASTER,
  SET_OVERWRITE_CHILDREN,
  SET_STYLEMASTER_CURRENT_VIEW,
  SET_SHOWSTYLEMASTERSTEPS,
  FILTER_PRODUCTSTYLEMASTERVALUES,
  SET_PRODUCT_STYLE_MASTER_COLUMNS,
  SET_PRODUCT_STYLE_MASTER_VALUES,
}

export interface ProductDetailState {
  editing: boolean;
  loading: boolean;
  product: Entities.ProductData | null;
  basicInfoForm: FormInstance<BasicInfoFormInput>;
  classifications: Array<Entities.ProductClassification>;
  labels: Array<Entities.ILabel>;
  channels: Array<any>;
  booleanMap: { [key: string]: boolean };
  booleanMapStyleMaster: { [key: string]: boolean };
  channelsInvDCs: Array<object>;
  channelListRaw: Array<object>;
  channelListProduct: Array<object>;
  editMode: boolean;
  distributionCenters: Array<Entities.DistributionCenter>;
  productExt: Entities.ProductExts | null;
  productId: any;
  isFormUpdated: boolean;
  inventory: Array<Entities.Inventory>;
  productType: number;
  bundleType: number;
  tabErrorDots: Map<string, boolean>;
  activeTab: string;
  isProductExist: boolean;
  attributes: Array<Entities.ProductAttribute>;
  originalAttributes: Array<Entities.ProductAttribute>;
  overwriteChildren: boolean;
  groups1: Array<string>;
  groups2: Array<string>;
  attributesForm: FormInstance;
  channelInvForm: FormInstance;
  productExtForm: FormInstance;
  attributeFilterForm: FormInstance;
  products: Array<Entities.ProductProfile>;
  variationsColumns: Array<any>;
  openProductsModal: boolean;
  variationProductsToSelect: Array<any>;
  originalVariationProductsToSelect: Array<any>;
  selectedVariationProducts: any;
  productsLoading: boolean;
  attributesChannels: Array<Entities.AttributesChannels>;
  inventoryTable: Array<any>;
  stylesVariations: any;
  stylesFormatterItemsOrder: Array<string>;
  editReviewValues: Array<object>;
  filteredEditReviewValues: Array<object>;
  editReviewColumns: Array<object>;
  generateSKUsColumns: Array<object>;
  generateSKUsValues: Array<object>;
  filteredGenerateSKUsValues: Array<object>;
  productStyleMaster: Entities.StyleMasterPayload | null;
  productStyleMasterColumns: Array<object>;
  productStyleMasterValues: Array<object>;
  productStyleMasterValuesFiltered: Array<object>;
  productStyleMasterSelectedCodes: any;
  styleMasterCurrentView: number;
  isCreating: boolean;
  showSteps: boolean;
}

export interface InitialStateProps {
  basicInfoForm: FormInstance;
  attributeFilterForm: FormInstance;
  attributesForm: FormInstance;
  productExtForm: FormInstance;
  channelInvForm: FormInstance;
  overwriteChildren?: boolean;
  productType?: number;
  bundleType?: number;
  isCreating?: boolean;
  showSteps?: boolean;
}

export const initialState = (
  {
    basicInfoForm,
    channelInvForm,
    attributeFilterForm,
    attributesForm,
    overwriteChildren = false,
    productExtForm,
    productType = 0,
    bundleType = 0,
    isCreating = false,
    showSteps = false,
  }: InitialStateProps,
): ProductDetailState => ({
  editing: false,
  loading: true,
  basicInfoForm,
  channelsInvDCs: [],
  classifications: [],
  booleanMap: {},
  booleanMapStyleMaster: {},
  labels: [],
  distributionCenters: [],
  product: null,
  channels: [],
  channelListRaw: [],
  channelListProduct: [],
  editMode: false,
  productId: 0,
  productExt: null,
  isFormUpdated: false,
  inventory: [],
  productType,
  bundleType,
  isCreating,
  showSteps,
  tabErrorDots: new Map([['Basic', false], ['Pricing', false]]),
  activeTab: 'Basic',
  attributes: [],
  overwriteChildren: overwriteChildren,
  originalAttributes: [],
  groups1: [],
  groups2: [],
  attributesForm,
  channelInvForm,
  isProductExist: false,
  productExtForm,
  attributeFilterForm,
  products: [],
  variationsColumns: [
    {
      dataIndex: 'Id',
      key: 'Id',
      className: 'hide',
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      key: 'SKU',
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
    },
  ],
  openProductsModal: false,
  variationProductsToSelect: [],
  originalVariationProductsToSelect: [],
  selectedVariationProducts: [],
  productsLoading: false,
  attributesChannels: [],
  inventoryTable: [],
  stylesVariations: null,
  stylesFormatterItemsOrder: [],
  editReviewValues: [],
  filteredEditReviewValues: [],
  editReviewColumns: [],
  generateSKUsColumns: [],
  generateSKUsValues: [],
  filteredGenerateSKUsValues: [],
  productStyleMaster: null,
  productStyleMasterColumns: [],
  productStyleMasterValues: [],
  productStyleMasterValuesFiltered: [],
  productStyleMasterSelectedCodes: null,
  styleMasterCurrentView: 0,
});

const SKULink: React.FC<{ product: any, SKU: string }> = ({ product, SKU }) =>
  (
    <Link to={`/product-detail/${product.Id}`}>
      {SKU}
    </Link>
  );

export const setExistingInventory = (productDcList: any, generalDcList: Array<Entities.Inventory>) => {
  const inventoryForm = generalDcList;
  if (productDcList) {
    productDcList.map((dl: any) => {
      const inventoryIndex = inventoryForm.findIndex((oa: any) => oa.DistributionCenterId === dl.DistributionCenterId);
      if (inventoryIndex === -1) return null;

      const selectedInventory = inventoryForm[inventoryIndex];
      inventoryForm.splice(inventoryIndex, 1);
      inventoryForm.unshift({
        DistributionCenterName: selectedInventory.DistributionCenterName,
        DistributionCenterId: selectedInventory.DistributionCenterId,
        DistributionCenterCode: selectedInventory.DistributionCenterCode,
        DistributionCenterType: selectedInventory.DistributionCenterType,
        AvailableQuantity: dl.AvailableQuantity,
      });
      return null;
    });
  }
  return inventoryForm;
};

const setVariationTableData = (selected: Array<string>, state: ProductDetailState) => selected
  .map((s: any) =>
    state.products.find((f: any) => f.CentralProductId === s),
  )
  .filter((f: any) => f !== undefined)
  .map((e: any) => ({
    Id: e.CentralProductId,
    SKU: e.SKU,
    Title: e.ProductTitle,
    attributes: e.AttributeList,
    ...e.AttributeList.map((al: any) => ({
      [al.AttributeId]: al.AttributeValue,
    })).reduce((obj: any, item: any) => Object.assign(obj, item), {}),
  }));

const setVariationColumns = (values: Array<string>, state: ProductDetailState) => {
  const baseColumns = [
    {
      dataIndex: 'Id',
      key: 'Id',
      className: 'hide',
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      key: 'SKU',
      render(text: any, record:any) {
        return <SKULink SKU={text} product={record} />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
    },
  ];
  const newAttColumns = values.map((v: any) => {
    const att: any = state.originalAttributes.filter((oa: any) => oa.AttributeId === v);
    return {
      title: att[0].AttributeName,
      dataIndex: v,
      key: v,
    };
  });

  return baseColumns.concat(newAttColumns).map((c: any) => ({
    ...c,
    sorter: (a: any, b: any) => a[c.key].localeCompare(b[c.key]),
  }));
};

export const generateStyleMasterColumns = (codes: string[], attributesSelectedArray: any[], attributes: Array<Entities.ProductAttribute>) => {
  const columns: ColumnsType = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render(value:any, _: any, index: number) {
        return (<SKUInput index={index} value={value} />);
      },
    },
    {
      title: 'Style',
      dataIndex: 'styleCode',
    },
    {
      title: 'Color/Pattern Code',
      dataIndex: 'colorCode',
    },
    {
      title: 'Size Code',
      dataIndex: 'sizeCode',
    },
    {
      title: 'Length Code',
      dataIndex: 'lengthCode',
    },
  ];

  // console.log('cc->', codes);
  // note the filter, code start with '{' will be dropped
  codes.filter(e => e && e[0] !== '{')
    .forEach((c: string) => columns.push({
      title: capitalizeWords(c),
      dataIndex: c,
      key: c,
    }));

  attributesSelectedArray.filter(e => e).map((as: string | null) => columns.push({
    title: attributes.filter((at: Entities.ProductAttribute) => at.AttributeId === as)[0]?.AttributeName,
    dataIndex: as || '',
    key: as || '',
    render: function CellWrapperAttributes(value: string, _: any, index: number) {
      const options = attributes.filter((at: Entities.ProductAttribute) => at.AttributeId === value)[0]?.AttributeOptionList.map((ol: { OptionValue: string }) => ({
        label: ol.OptionValue,
        value: ol.OptionValue,
      }));
      const attributeListIndex = attributesSelectedArray.indexOf(value);
      return (<AttributeDropDown index={index} attributeId={value} options={options.length ? options : [{ label: 'No options assigned yet to this attribute', value: 0 }]} attributeListIndex={attributeListIndex} />);
    },
  }));

  columns.push({
    title: 'Retail Price',
    dataIndex: 'retailPrice',
    key: 'retailPrice',
    render: function CellWrapperPrice(value: number, record: any, index: number) {
      return (<RetailPriceInput index={index} value={value} record={record} />);
    },
  });

  return columns;
};

const setProductData = (state: ProductDetailState, action: Utils.DispatchAction<Actions>): ProductDetailState => {
  const productData = action.params;
  const labels = productData?.LabelList ? productData?.LabelList.map((ll: any) => ll) : [];
  const channels = productData?.FlagList ? productData?.FlagList.map((fl: any) => fl) : [];
  const inventoryForm = setExistingInventory(productData?.DcList, state.inventory);

  state.basicInfoForm.setFieldsValue({
    ...productData?.ProductBasic,
    styleMaster: {
      styleCode: productData?.ProductBasic.VariationParentSKU,
    },
    labels,
    channels,
    MediaList: productData?.MediaList || [],
    DcList: inventoryForm,
    VaryByList: productData?.VaryByList?.filter((f: any) => state.originalAttributes.filter((oa: any) => oa.AttributeId === f)[0]?.AttributeOptionMasterList.length > 0) || [],
    VariationChildren: productData?.VariationChildren || [],
    BundleComponents: productData.BundleComponents || [],
  });
  if (productData?.AttributeList) {
    const attributes = productData?.AttributeList?.reduce((ac: any, a: any) => ({
      ...ac,
      [a.AttributeId]: a.Value,
    }), {});
    state.attributesForm.setFieldsValue({
      ...attributes,
    });
  }

  let attributesToSet = state.attributes.filter((f: any) => f.AttributeType !== 2);
  if (productData?.ProductBasic.ClassificationNum > 0) {
    const classificationAtts = state.attributes.filter((f: any) => f.Classifications?.filter((acf: any) => acf.ClassificationNum === productData?.ProductBasic.ClassificationNum).length > 0);
    const normalAttributes = state.attributes.filter((f: any) => f.AttributeType === 1);
    attributesToSet = normalAttributes.concat(classificationAtts);
  }
  const channelExistentAccount: any = {};
  state.channelListProduct.map((clp: any) => clp.channelAccountList.map((cal: any) => {
    channelExistentAccount[cal.channelAccountNum] = cal.distributionList.filter((f: any) => f.distributionCenterCode !== null && f.distributionCenterName !== null);
    return null;
  }));
  const channelsInv = state.channelListRaw.map((cln: any) => ({
    ...cln,
    channelAccountList: cln.channelAccountList.map((cal: any) => {
      const clean = [].concat(
        cal.distributionList.filter((obj1: any) => channelExistentAccount[cal.channelAccountNum].every((obj2: any) => obj1?.distributionCenterNum !== obj2?.distributionCenterNum)),
        channelExistentAccount[cal.channelAccountNum].filter((obj2: any) => cal.distributionList.every((obj1: any) => obj2?.distributionCenterNum !== obj1?.distributionCenterNum)),
      ).filter((f: any) => f.distributionCenterCode !== null && f.distributionCenterName !== null);
      return {
        ...cal,
        distributionList: [
          ...clean.map((dl: any) => ({ ...dl, display: true })),
          ...channelExistentAccount[cal.channelAccountNum].map((dl: any) => ({ ...dl, display: true })),
        ],
      };
    }),
  }));
  state.basicInfoForm.setFieldsValue({
    ...state.basicInfoForm.getFieldsValue(),
    channelsInv: channelsInv.sort((a: any, b: any) => (a.channelName > b.channelName) ? 1 : -1),
  });

  const styleMasterCodes = state?.productStyleMaster?.itemPatternArr.filter((f: string) => f !== 'STD' && f.length > 1) || [];
  const styleMasterValues = state?.productStyleMaster ? state?.productStyleMaster?.styleMasterItemList?.map((item: Entities.StyleMasterItemList) => ({
    sku: item.styleSKU,
    styleCode: state.basicInfoForm.getFieldValue('styleMaster').styleCode || '',
    retailPrice: item.retailPrice,
    productId: item.productId,
    ...item.erpCode,
    ...item.attributeList.reduce((p: any, c: { attributeId: string, value: string }) => {
      const r = { ...p };
      r[c?.attributeId || 0] = c?.value;
      return r;
    }, {}),
  })) : [];

  const styleMasterAttributes = state?.productStyleMaster && state?.productStyleMaster?.styleMasterItemList ? state?.productStyleMaster?.styleMasterItemList[0]?.attributeList.reduce((p: any, c: { attributeId: string, value: string }) => {
    const r = { ...p };
    r[c?.attributeId || 0] = true;
    return r;
  }, {}) : {};

  state?.basicInfoForm.setFieldsValue({
    ...state?.basicInfoForm.getFieldsValue(),
    styleMaster: {
      ...state.basicInfoForm.getFieldValue('styleMaster'),
      attributes: styleMasterAttributes,
      formatter: {
        ...state.basicInfoForm.getFieldValue('styleMaster').formatter,
        allCaps: state?.productStyleMaster?.itemPatternAllCaps,
      },
    },
  });


  const codesGrouped = styleMasterCodes.map((a: string) => ({
    type: a,
    value: new Set(state?.productStyleMaster?.styleMasterItemList?.map((s: any) => s.erpCode[a] === 'STD' ? null : s.erpCode[a]).filter((x: any) => x !== null)),
  }));
  const productStyleMasterSelectedCodes = codesGrouped.reduce((p: any, c: { type: string, value: Set<string> }) => {
    const r = { ...p };
    r[c?.type] = c?.value;
    return r;
  }, {});



  const styleMasterAttributesSelectedArray = state?.productStyleMaster?.styleMasterItemList?.length ? state?.productStyleMaster.styleMasterItemList[0]?.attributeList.map((a: { attributeId: string, value: string }) => a.attributeId) : [];

  const onStyleMasterItemDelete = async (styleMasterId: string) => {
    try {
      action.params.dispatch({ type: Actions.SET_LOADING, params: true });
      await Products.deleteStyleMaster(styleMasterId);
      const removedStyleMasterItem = state.productStyleMasterValues.filter((f: any) => f.productId !== styleMasterId);
      action.params.dispatch({ type: Actions.SET_PRODUCT_STYLE_MASTER_VALUES, params: removedStyleMasterItem });
      action.params.dispatch({ type: Actions.SET_LOADING, params: false });
    } catch {
      action.params.dispatch({ type: Actions.SET_LOADING, params: false });
    }
  };

  const productStyleMasterColumns = [
    ...generateStyleMasterColumns(styleMasterCodes, styleMasterAttributesSelectedArray, state.attributes).map(({ render, ...rest }) => rest),
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: function CellWrapperActions(value:any, record: any) {
        return (<Button onClick={() => {onStyleMasterItemDelete(record.productId).then();}}>Delete</Button>);
      },
    },
  ];

  return {
    ...state,
    product: productData,
    productId: productData?.productId,
    productType: Number(productData?.ProductBasic.ProductType),
    bundleType: Number(productData?.ProductBasic.BundleType),
    attributes: attributesToSet,
    selectedVariationProducts: setVariationTableData(productData?.VariationChildren || [], state),
    variationsColumns: setVariationColumns(productData?.VaryByList || [], state),
    inventoryTable: inventoryForm,
    productStyleMasterValues: styleMasterValues,
    productStyleMasterValuesFiltered: styleMasterValues,
    productStyleMasterSelectedCodes,
    productStyleMasterColumns,
  };
};

const resetProduct = (state: ProductDetailState): ProductDetailState => {
  const labels = state.product?.LabelList?.map((ll: any) => ll);
  const channels = state.product?.FlagList?.map((fl: any) => fl);
  const inventoryForm = setExistingInventory(state.product?.DcList, state.inventory);
  state.basicInfoForm.setFieldsValue({
    ...state.product?.ProductBasic,
    labels,
    channels,
    MediaList: state.product?.MediaList || [],
    DcList: inventoryForm,
    BundleComponents: state.product?.BundleComponents || [],
    VaryByList: state.product?.VaryByList || [],
    VariationChildren: state.product?.VariationChildren || [],
  });

  return {
    ...state,
    selectedVariationProducts: setVariationTableData(state.product?.VariationChildren || [], state),
    variationsColumns: setVariationColumns(state.product?.VaryByList || [], state),
  };
};

export const saveChannelInv = async (dispatch: Dispatcher, state: ProductDetailState, productId: string) => {
  state.channelInvForm.resetFields();
  try {
    const { channelsInv } = state.basicInfoForm.getFieldsValue();
    const payload = {
      channelList: channelsInv.map((ci: any) => ({
        channelAccountList: ci.channelAccountList.map((cal: any) => ({
          ...cal,
          distributionList: cal.distributionList.filter((dl: any) => dl.qty > 0),
        })),
      })),
    };

    await Products.createProductAssignedChannelInv(payload, productId);
    return await Promise.resolve();
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const saveProductExt = async (dispatch: Dispatcher, state: ProductDetailState) => {
  try {
    const productExtPayload = {
      inventory: {
        productBasic: {
          sku: state?.productExt?.data?.inventory?.productBasic?.sku || state.basicInfoForm.getFieldValue('SKU'),
          rowNum: state?.productExt?.data?.inventory?.productBasic?.rowNum || '',
        },
        productExt: {
          ...state.productExtForm.getFieldsValue(),
          sku: state?.productExt?.data?.inventory?.productBasic?.sku || state.basicInfoForm.getFieldValue('SKU'),
        },
      },
    };

    if (state.isProductExist) {
      productExtPayload.inventory.productExt = {
        ...productExtPayload.inventory.productExt,
        rowNum: state?.productExt?.data?.inventory.productExt?.rowNum,
        productUuid: state?.productExt?.data?.inventory.productExt?.productUuid,
      };
      await Products.saveProductExts(productExtPayload);
    } else {
      await Products.createProductExts(productExtPayload);
    }
    return await Promise.resolve();
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const saveProduct = async (dispatch: Dispatcher, state: ProductDetailState) => {
  try {
    dispatch({ type: Actions.SET_FORMUPDATED, params: false });
    dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs: ['Basic', 'Pricing'], show: false } });
    const labels = state.labels.map((l: any) => l.ProductLabelId);
    const labelsSaved = state.basicInfoForm.getFieldValue('labels') || [];
    const newLabels = labelsSaved.filter((ls: any) => !labels.includes(ls));
    const oldLabelsIds = labelsSaved.filter((ls: any) => labels.includes(ls));

    const newLabelsRequests = newLabels.map(async (nl: any) => addLabel({
      ProductLabelDesc: '',
      ProductLabelName: nl,
    }));
    await Promise.all(newLabelsRequests).then();
    const labelsUpdated = await fetchLabels({
      $count: true,
      $top: 0,
    });

    const newLabelsIds  = newLabels.map((nl: any) => {
      const found = labelsUpdated.LabelList.find((lu: any) => lu.ProductLabelName === nl);
      if (!found) return {};

      return found.ProductLabelId;
    });

    const labelsToSave = newLabelsIds.concat(oldLabelsIds);
    const rawAttributes = state.attributesForm.getFieldsValue();
    const attributesToSave = Object.entries(rawAttributes).map((a: any) => {
      if (a[1] !== undefined) {
        return {
          AttributeId: a[0],
          Value: a[1],
        };
      }
      return null;
    }).filter((f: any) => (f?.Value !== '' && f !== null) );
    const basic = {
      ...state.basicInfoForm.getFieldsValue(),
    };
    delete basic?.channels;
    delete basic?.labels;
    delete basic?.DcList;
    delete basic?.Dclist;
    delete basic?.MediaList;
    delete basic?.VaryByList;
    delete basic?.VariationChildren;
    delete (basic as any).channelsInv;
    delete (basic as any).styleMaster;

    // if (state.productId !== 0) delete (basic as any).SKU;

    const dcToSave = state.basicInfoForm.getFieldValue('DcList').filter((dc: any) => dc.AvailableQuantity > 0);
    const classificationNum = state.basicInfoForm.getFieldValue('ClassificationNum');
    const newProductData = {
      PageType: state.productType,
      ProductBasic: { ...basic },
      LabelList: labelsToSave,
      FlagList: state.basicInfoForm.getFieldValue('channels') || [],
      DcList: dcToSave,
      AttributeList: attributesToSave,
      MediaList: state.basicInfoForm.getFieldValue('MediaList') || [],
      BundleComponents: state.basicInfoForm.getFieldValue('BundleComponents') || [],
      ClassificationID: classificationNum,
      VariationChildren: state.basicInfoForm.getFieldValue('VariationChildren'),
      VaryByList: state.basicInfoForm.getFieldValue('VaryByList'),
    };
    const itemPatternArr = state.stylesFormatterItemsOrder.map((f: string) => f.includes('Separator') ? state.basicInfoForm.getFieldValue('styleMaster')?.formatter?.separators[f] : f);
    let response;
    const cameCaseBasicInfo = {
      sku: basic.SKU,
      styleCode: state.basicInfoForm.getFieldValue('styleMaster').styleCode,
      fnSku: basic.FNSku,
      condition: basic.Condition,
      brand: basic.Brand,
      manufacturer: basic.Manufacturer,
      productTitle: basic.ProductTitle,
      longDescription: basic.LongDescription,
      shortDescription: basic.ShortDescription,
      subtitle: basic.Subtitle,
      asin: basic.ASIN,
      upc: basic.UPC,
      ean: basic.EAN,
      isbn: basic.ISBN,
      mpn: basic.MPN,
      price: basic.Price,
      cost: basic.Cost,
      avgCost: basic.AvgCost,
      mapPrice: basic.MAPPrice,
      msrp: basic.MSRP,
      netWeight: basic.NetWeight,
      grossWeight: basic.GrossWeight,
      weightUnit: basic.WeightUnit,
      productHeight: basic.ProductHeight,
      productLength: basic.ProductLength,
      productWidth: basic.ProductWidth,
      boxHeight: basic.BoxHeight,
      boxLength: basic.BoxLength,
      boxWidth: basic.BoxWidth,
      dimensionUnit: basic.DimensionUnit,
      harmonizedCode: basic.HarmonizedCode,
      taxProductCode: basic.TaxProductCode,
      warranty: basic.Warranty,
    };
    let productId = '';
    if (state.productId === 0) {
      const styleMasterPayload = {
        styleMasterItemList: state.basicInfoForm.getFieldValue('styleMaster')?.editReviewValues || [],
        basic: cameCaseBasicInfo,
        itemPatternArr,
        itemPatternAllCaps: state.basicInfoForm.getFieldValue('styleMaster')?.formatter?.allCaps || false,
        labelArr: labelsToSave,
        channelControlFlagArr: state.basicInfoForm.getFieldValue('channels') || [],
        classificationArr: classificationNum ? [classificationNum] : [],
        mediaPlacementList: state.basicInfoForm.getFieldValue('MediaList') || [],
        attributeList: attributesToSave,
      };
      if (state.productType === 2 && state.bundleType === 0) {
        response = await Products.createStyleMaster(styleMasterPayload);
        productId = response.productId || '0';
      } else {
        response = await Products.createProduct(newProductData);
        productId = `${response?.ProductBasic?.DatabaseNum}-${response?.ProductBasic?.CentralProductNum}`;
      }
    } else if (state.productType === 2 && state.bundleType === 0) {
      const styleMasterPayload = {
        styleMasterItemList: state.basicInfoForm.getFieldValue('styleMaster')?.editReviewValues || [],
        basic: cameCaseBasicInfo,
        itemPatternArr,
        itemPatternAllCaps: state.basicInfoForm.getFieldValue('styleMaster')?.formatter?.allCaps || false,
        labelArr: labelsToSave,
        channelControlFlagArr: state.basicInfoForm.getFieldValue('channels') || [],
        classificationArr: classificationNum ? [classificationNum] : [],
        mediaPlacementList: state.basicInfoForm.getFieldValue('MediaList') || [],
        attributeList: attributesToSave,
      };
      response = await Products.editStyleMaster(styleMasterPayload, state.productId);
      productId = response.productId || '0';
    } else {
      console.log('ep', newProductData);
      response = await Products.editProduct(newProductData, state.productId);
      productId = `${response?.ProductBasic?.DatabaseNum}-${response?.ProductBasic?.CentralProductNum}`;
    }

    return await Promise.resolve({ productId });
  } catch (e) {
    return await Promise.reject(e);
  }
};

const recalculateVariations = (state: ProductDetailState) => {
  const { isCreating, basicInfoForm, stylesFormatterItemsOrder, attributes, stylesVariations, productStyleMasterSelectedCodes } = state;
  const { Price } = basicInfoForm.getFieldsValue();
  const styleMasterValues = basicInfoForm.getFieldValue('styleMaster');
  const { allCaps } = styleMasterValues.formatter;
  const attributesColumnValues = styleMasterValues.attributes ? Object.keys(styleMasterValues.attributes).reduce((a, v) => ({ ...a, [v]: v }), {}) : {};
  const attributesSelectedArray = styleMasterValues.attributes !== undefined ? Object.keys(styleMasterValues.attributes).map(key => styleMasterValues.attributes[key] ? key : null).filter((x: any) => x !== null) : [];
  const codes = stylesFormatterItemsOrder.filter((f: string) => !f.includes('Separator') && f !== 'STD');
  let newCodes = codes;
  if (!isCreating) {
    newCodes = stylesVariations && productStyleMasterSelectedCodes ? codes.filter((c: any) => stylesVariations[c].length !== productStyleMasterSelectedCodes[c].size ) : [];
  }
  const selectedCodes = newCodes.map((c: string) => {
    if (!styleMasterValues[c]) {
      return null;
    }
    const v = Object.keys(styleMasterValues[c]).map(key => styleMasterValues[c][key] ? { code: c, value: key } : null).filter((x: any) => x !== null);
    return v.length > 0 ? v : null;
  }).filter((x: any) => x !== null);
  const variationsRaw = cartesian([...selectedCodes]);
  const variations = variationsRaw.map((v: { code: '', value: '' }[]) => {
    const merged: any = {};
    for (let index = 0; index < v.length; index += 1) {
      merged[v[index].code] = v[index].value;
    }
    return merged;
  });
  const columns = generateStyleMasterColumns(newCodes, attributesSelectedArray, attributes);

  const values = variations.map((v: any) => {
    const sku = stylesFormatterItemsOrder.map((format: string) => {
      if (format.includes('Separator')) {
        return styleMasterValues.formatter.separators[format];
      }

      return v[format];
    }).join('');
    return {
      sku: allCaps ? `${styleMasterValues.styleCode}-${sku}STD`.toUpperCase() : `${styleMasterValues.styleCode}-${sku}STD`,
      sizeCode: v?.sizeCode || '',
      colorCode: v?.colorCode || '',
      widthCode: v?.widthCode || '',
      lengthCode: v?.lengthCode || '',
      retailPrice: Price,
      ...attributesColumnValues,
    };
  });

  return {
    editReviewColumns: columns,
    editReviewValues: values,
    filteredEditReviewValues: values,
    generateSKUsColumns: columns.map(({ render, ...rest }) => rest),
  };

};

const generateSKUs = (state: ProductDetailState) => {
  const { editReviewValues, basicInfoForm } = state;
  const attributeValues = basicInfoForm.getFieldValue('styleMaster').editReviewValues;
  if (attributeValues.length === 0) {
    return {};
  }
  const values = editReviewValues.map((v: object, index: number) => ({
    ...v,
    ...attributeValues[index].attributeList.reduce((a: any, val: any) => ({ ...a, [val.attributeId]: val.value }), {}),
    retailPrice: attributeValues[index].retailPrice,
  }));

  return { generateSKUsValues: values, filteredGenerateSKUsValues: values };
};

export const reducer = (state: ProductDetailState, action: Utils.DispatchAction<Actions>) => {
  switch (action.type) {
    case Actions.SET_PRODUCT_STYLE_MASTER_VALUES:
      return { ...state, productStyleMasterValues: action.params, productStyleMasterValuesFiltered: action.params };
    case Actions.SET_PRODUCT_STYLE_MASTER_COLUMNS:
      return { ...state, productStyleMasterColumns: action.params };
    case Actions.FILTER_PRODUCTSTYLEMASTERVALUES:
      return { ...state, productStyleMasterValuesFiltered: action.params };
    case Actions.SET_SHOWSTYLEMASTERSTEPS:
      return { ...state, showSteps: action.params };
    case Actions.SET_STYLEMASTER_CURRENT_VIEW:
      return { ...state, styleMasterCurrentView: action.params };
    case Actions.SET_PRODUCT_STYLEMASTER:
      return { ...state, productStyleMaster: action.params };
    case Actions.FILTER_GENERATESKUS:
      return { ...state, filteredGenerateSKUsValues: action.params };
    case Actions.FILTER_EDITREVIEW:
      return { ...state, filteredEditReviewValues: action.params };
    case Actions.GENERATE_SKUS:
      return {
        ...state,
        ...generateSKUs(state),
        isFormUpdated: true,
      };
    case Actions.RECALCULATE_VARIATIONS:
      return {
        ...state,
        ...recalculateVariations(state),
      };
    case Actions.SET_STYLE_FORMATTER_ITEMS_ORDER:
      return { ...state, stylesFormatterItemsOrder: action.params };
    case Actions.SET_STYLEVARIATIONS:
      return { ...state, stylesVariations: action.params };
    case Actions.INIT_ATTRIBUTE_FILTER_BOOLS:
      return { ...state, booleanMap: action.params, booleanMapStyleMaster: action.params };
    case Actions.SET_ATTRIBUTE_FILTER_BOOLS_STYLE_MASTER:
      return { ...state, booleanMapStyleMaster: action.params };
    case Actions.SET_ATTRIBUTE_FILTER_BOOLS:
      return { ...state, booleanMap: action.params };
    case Actions.SET_PRODUCTID:
      return { ...state, productId: action.params };
    case Actions.FILTER_CHANNELSINV:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        channelsInv: action.params.sort((a: any, b: any) => (a.channelName > b.channelName) ? 1 : -1),
      });
      return { ...state };
    case Actions.SET_CHANNELSINV_DCS:
      return { ...state, channelsInvDCs: action.params };
    case Actions.SET_CHANNELSINV:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        channelsInv: action.params.channelListRaw.sort((a: any, b: any) => (a.channelName > b.channelName) ? 1 : -1),
      });
      return { ...state, channelListRaw: action.params.channelListRaw, channelListProduct: action.params.channelListProduct };
    case Actions.SET_DC:
      return { ...state, distributionCenters: action.params };
    case Actions.SET_PRODUCTEXT:
      if (action.params?.data?.data?.inventory === undefined) {
        state.productExtForm.setFieldsValue({
          costable: false,
          isAp: false,
          isAr: false,
          isProfit: false,
          release: false,
          stockable: false,
          taxable: false,
        });
      } else {
        state.productExtForm.setFieldsValue({
          ...action.params.data.data.inventory.productExt,
        });
      }
      return { ...state, productExt: action.params.data, isProductExist: action.params.isProductExist };
    case Actions.FILTER_PRODUCTS:
      return { ...state, variationProductsToSelect: state.originalVariationProductsToSelect.filter((f: any) => f.SKU.toLowerCase().includes(action.params.toLowerCase())) };
    case Actions.SET_ATTRIBUTESCHANNELS:
      return { ...state, attributesChannels: action.params };
    case Actions.SET_PRODUCTS_LOADING:
      return { ...state, productsLoading: action.params };
    case Actions.DELETE_VARIATION:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        VariationChildren: state.basicInfoForm.getFieldValue('VariationChildren').filter((vc: any) => vc !== action.params),
      });
      return {
        ...state,
        selectedVariationProducts: state.selectedVariationProducts.filter((sv: any) => sv.Id !== action.params),
        isFormUpdated: true,
      };
    case Actions.SET_SELECTEDVARIATIONPRODUCTS:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        VariationChildren: action.params,
      });
      return {
        ...state,
        selectedVariationProducts: setVariationTableData(action.params, state),
      };
    case Actions.SET_VARIATIONSPRODUCTSTOSELECT:
      return { ...state, variationProductsToSelect: action.params, originalVariationProductsToSelect: action.params };
    case Actions.SET_OPENPRODUCTSMODAL:
      return { ...state, openProductsModal: action.params };
    case Actions.SET_VARIATIONS_TABLE_COLUMNS:
      return { ...state, variationsColumns: setVariationColumns(action.params, state) };
    case Actions.SET_PRODUCTS:
      return {
        ...state,
        products: action.params,
      };
    case Actions.CLEAR_VARYBYLIST:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        VaryByList: [],
        VariationChildren: [],
      });
      return { ...state, selectedVariationProducts: []  };
    case Actions.SET_CLASSIFICATION:
      return {
        ...state,
        attributes: state.originalAttributes.filter((f: any) => f.Classifications?.filter((acf: any) => acf.ClassificationNum === action.params).length > 0 || f.AttributeType === 1),
      };
    case Actions.RESET_ATTRIBUTEFILTERS: {
      state.attributeFilterForm.resetFields();
      let attributesToSet = state.originalAttributes.filter((f: any) => f.AttributeType !== 2);
      if (action.params > 0) {
        const classificationAtts = state.originalAttributes.filter((f: any) => f.Classifications?.filter((acf: any) => acf.ClassificationNum === action.params).length > 0);
        const normalAttributes = state.originalAttributes.filter((f: any) => f.AttributeType === 1);
        attributesToSet = normalAttributes.concat(classificationAtts);
      }
      return {
        ...state,
        attributes: attributesToSet,
      };
    }
    case Actions.SET_ATTRIBUTEFILTERING:
      return { ...state, attributes: action.params };
    case Actions.SET_ATTRIBUTES: {
      // uncomment to bring default attributes values back
      // const attributes = action.params.attributes.reduce((ac: any, a: any) => ({
      //   ...ac,
      //   [a.AttributeId]: a.DefaultValue,
      // }), {});
      // state.attributesForm.setFieldsValue({
      //   ...attributes,
      // });
      return {
        ...state,
        attributes: action.params.attributes,
        originalAttributes: action.params.attributes,
        groups1: action.params.groups1,
        groups2: action.params.groups2,
      };
    }
    case Actions.SET_ACTIVETAB:
      return { ...state, activeTab: action.params };
    case Actions.SET_TABERRORDOTS: {
      const dots = new Map();
      action.params.errorTabs.forEach((item: string) => dots.set(item, action.params.show));
      return { ...state, tabErrorDots: dots };
    }
    case Actions.SET_BUNDLETYPE:
      return { ...state, bundleType: Number(action.params) };
    case Actions.SET_PRODUCTTYPE:
      return { ...state, productType: Number(action.params) };
    case Actions.SET_INVENTORYFORM:
      state.basicInfoForm.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        DcList: state.inventory,
      });
      return { ...state };
    case Actions.SET_INVENTORY:
      return { ...state, inventory: action.params };
    case Actions.SET_INVENTORYTABLE:
      return { ...state, inventoryTable: action.params };
    case Actions.SET_FORMUPDATED:
      return { ...state, isFormUpdated: action.params };
    case Actions.RESET_PRODUCT:
      return resetProduct(state);
    case Actions.SET_EDITMODE:
      return { ...state, editMode: action.params };
    case Actions.SET_CHANNELS:
      return { ...state, channels: action.params };
    case Actions.SET_LABELS:
      return { ...state, labels: action.params?.LabelList || [] };
    case Actions.SET_CLASSIFICATIONS:
      return { ...state, classifications: action.params };
    case Actions.SET_PRODUCT_DATA:
      return setProductData(state, action);
    case Actions.BEFORE_SUBMIT:
      state.basicInfoForm.resetFields();
      return state;
    case Actions.SET_EDITING:
      return { ...state, editing: action.params };
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_OVERWRITE_CHILDREN:
      return { ...state, overwriteChildren: action.params };
    default:
      return state;
  }
};


export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [ProductDetailState, Dispatcher] | [undefined, undefined];

export const ProductDetailScreenContext = createContext<ContextType>([undefined, undefined]);
