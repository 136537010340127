import React, { useCallback, useEffect } from 'react';// eslint-disable-line
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  notification,
} from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, FontSizeOutlined, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import Info from '../../assets/icons/info';
import { HoverBgButton, InfoButton } from '../../components/common/styledComponents';
import Loading from '../../components/common/Loading';
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../../constants/config';
import { MapperType } from '../../constants/enums/mapping';
import { fetchChannelAccountMappingAttributes } from '../../services/products';
//import ImageAttrs from './ImageAttrs';
import { getProfileSettingValue } from  '../../util'

const { Option } = Select;
const { Text } = Typography;

const IconWrapper = styled.div`
  margin-top: 3px;
  margin-right: 4px;
  cursor: pointer;
`;

const PatternLabelCtn = styled.div`
  & .pat-attr-exp {
    color: #006DFF;
  }

  & .pat-label-exp {
    color: #bc0000;
  }
`;

const PatternUnitCtn = styled.div`
  margin-top: 16px;

  & .exp-result-selector .ant-input,
  & .exp-result-selector .ant-select-selector {
    border-bottom-left-radius: unset;
    border-left: unset;
    border-top-left-radius: unset;
  }

  & .exp-result-type-btn {
    border: none;
    height: 30px;
    padding: 0 8px;
  }

  & .exp-unit1 {
    width: 180px;
  }

  & .exp-unit2 {
    width: 130px;
  }

  & .exp-unit3 {
    width: 80px;
  }

  & .exp-unit4 {
    width: 212px;
  }

  & .exp-unit5 {
    text-align: right;
    width: 106px;
  }

  & .exp-unit1 .ant-select,
  & .exp-unit2 .ant-select,
  & .exp-unit3 .ant-input-number,
  & .exp-unit4 .ant-btn-group {
    width: 100%;
  }

  & .exp-unit4 .ant-radio-button-wrapper {
    padding: 0;
  }

  & .exp-unit5 .condition-cell {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    height: 32px;
    text-align: center;
  }

  & .exp-unit5 .condition-cell .ant-tag {
    margin-right: 0;
  }

  & .pat-unit {
    width: calc(100% - 32px);
  }

  & .pat-unit1 {
    width: calc(100% - 96px);
  }

  & .exp-unit4 .ant-select,
  & .pat-unit .ant-input,
  & .pat-unit .ant-select,
  & .pat-unit1 .ant-input,
  & .pat-unit1 .ant-select {
    width: calc(100% - 32px);
  }

  & .pat-unit .attr-select1 {
    width: calc(100% - 96px);
  }

  & .pat-unit .pat-sec-icon,
  & .pat-unit1 .pat-sec-icon {
    width: 32px;
  }

  & .pat-unit-blk {
    margin-left: 120px;
    width: 440px;
  }

  & .pat-unit-blk.exp-row {
    width: 910px;
  }

  & .pat-unit-space {
    width: 100%;
    height: 12px;
  }

  & .pat-unit-space .pat-unit-connector {
    width: 50%;
    height: 100%;
    border-right: 2px solid #666;
  }
`;

// eslint-disable-next-line
const SuffixContainer = styled.div`
  width: 30%;
  float: left
`;

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

const TitleWrapper = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.57;
  color: #2d3f5d;
  display: flex;
  margin-top: -30px;
  margin-bottom: 45px;
`;

export enum PatternType {
  Attr = 2,
  Text = 1,
};

function ModalForm(props: any) {
  const { useState } = React;
  const { formValues, visible, handleCancel, form, handleCleanMapping, isMultiple } = props;
  const [expAttrs, setExpAttrs] = useState<StringKAnyVPair[]>([]);
  const [imageAttrs, setImageAttrs] = useState<StringKAnyVPair[]>([]);
  const [lastEditKey, setLastEditKey] = useState<any>();
  const [patternUnits, setPatternUnits] = useState<StringKAnyVPair[]>([]);
  const [productFieldLoaded, setProductFieldLoaded] = useState(false);
  const [productFieldIsLoading, setProductFieldIsLoading] = useState(false);
  const [productFields, setProductFields] = useState<StringKAnyVPair[]>([]);
  const [attributes, setAttributes] = useState<any>()

  const initAttributes = useCallback(async ()=>{
    let temp = {...props.attributes};
    const allowBasic = await getProfileSettingValue('AllowBasicInfoChannelMapping', '0');
    if (allowBasic === '0') {
      temp['Basic Info'] = [];
    }
    setAttributes(temp)
  },[props.attributes])

  useEffect(()=>{
    initAttributes()
  },[initAttributes])

  const addAttrExpression = (opType: string) => {
    const exp: StringKAnyVPair = {
      uuid: uuid(),
      isElseValue: false,
      variable: {},        // label, value, defaultValue
      operator: undefined,
      operand: undefined,
      result: {type: PatternType.Attr,}, // label, value, defaultValue
      opType: opType, // number or text
    };

    if (expAttrs.length === 1) {
      exp.isElseValue = true;
    }

    if (expAttrs.length < 2) {
      expAttrs.push(exp);
    } else {
      expAttrs.splice(expAttrs.length - 1, 0, exp);
    }

    setExpAttrs([...expAttrs]);
  };

  const addAttrImage = () => {
    setImageAttrs([
      ...imageAttrs,
      {
        uuid: uuid(),
      },
    ]);
  };

  const addAttrUnit = () => {
    setPatternUnits([
      ...patternUnits,
      {
        type: PatternType.Attr,
        uuid: uuid(),
      }
    ]);
  };

  const addTextUnit = () => {
    setPatternUnits([
      ...patternUnits,
      {
        type: PatternType.Text,
        uuid: uuid(),
      }
    ]);
  };

  const beforeSubmit = () =>  {
    const expAttrsData: StringKAnyVPair = expAttrs.map(e => ({
      isElseValue: e.isElseValue || false,
      opType: e.opType,
      operand: e.operand === undefined ? '' : `${e.operand}`,
      operator: e.operator,
      result: { ...e.result, defaultValue: undefined, },
      variable: { ...e.variable, defaultValue: undefined, },
      uuid: e.uuid,
    }));

    if (formValues.MapperType !== MapperType.MULTIPLE) {
      clearAllPatterns();
    }

    props.submit(false, patternMetaData(), imageAttrs, expAttrsData);
  };

  const clearAllPatterns = () => {
    setPatternUnits([]);
  };

  // eslint-disable-next-line
  const checkProductFields = async () => {
    if (productFieldLoaded || !props.channelNum || !props.channelAccountNum) return;
    //console.log('p--->', props);

    try {
      //const cmps = await fetchChannelMappingAttributesByChannelNum(props.channelNum);
      const allowBasic = await getProfileSettingValue('AllowBasicInfoChannelMapping', '0');
      /* const allowCommon = await getProfileSettingValue('UseCommonAttributes', '0'); */
      const cmps = await fetchChannelAccountMappingAttributes(props.channelNum, props.channelAccountNum);
      /* setProductFieldIsLoading(false); */
      //console.log('cn ->', props.channelNum, cmps, attributes);
      if (Array.isArray(cmps)) {
        let temp = [...cmps]
        if (allowBasic === '0') {
          temp = temp.filter(i => i.groupBy !== 'Basic Info')
        }
        /* if (allowCommon === '0') {
          temp = temp.filter(i => i.groupBy !== 'Common Attributes')
        } */
        setProductFields(temp);
        setProductFieldLoaded(true);
      } else {
        notification.error({
          message: 'Fetched wrong format of channel mapping attributes',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch(e) {
      console.log('fetch channel attrs error:', e);
      notification.error({
        message: `Fetched channel attributes error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const elseTypeSelector = (exp: StringKAnyVPair) => {
    console.log('exp', exp);
    const onAttrConditionChange = (v: any) => {
      exp.isElseValue = v === 1 ? true : false;

      if (v === 0 && exp.variable && typeof exp.variable === 'object') {
        exp.variable.defaultValue = exp.variable.value;
      }

      setExpAttrs([...expAttrs]);
    };

    const options = [
      { label: 'else', value: 1, },
      { label: 'else if', value: 0, },
    ];

    return (
      <Select
        onChange={onAttrConditionChange}
        options={options}
        value={exp.isElseValue ? 1 : 0}
      />
    );
  };

  /* const filterOption = (value: any, option: any) => {
    const { children } = option;

    if (option.type?.isSelectOptGroup) {
      return children.includes((child: any) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    }
    return children?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
  }; */

  const expResultDropdownMenu = (res: StringKAnyVPair) => {
    const setExpressionResultType = (typ: number) => {
      res.type = typ;
      res.defaultValue = undefined;
      res.label = undefined;
      res.value = undefined;
      setExpAttrs([...expAttrs]);
    };

    return (<Menu>
      <Menu.Item key="0" icon={<MenuOutlined />} onClick={() => setExpressionResultType(PatternType.Attr)}>
        Attribute
      </Menu.Item>
      <Menu.Item key="1" icon={<FontSizeOutlined />} onClick={() => setExpressionResultType(PatternType.Text)}>
        Text
      </Menu.Item>
    </Menu>);
  };

  const expressionAttrItem = (exp: StringKAnyVPair) => {
    const attrs: StringKAnyVPair[] = [];
    let defaultValue: any = undefined;

    if (exp && typeof exp === 'object') {
      defaultValue = exp.defaultValue;
    }

    for (let k in attributes) {
      // eslint-disable-next-line
      attributes[k].forEach((e: StringKAnyVPair) => attrs.push({...e}));
    }

    const options = attrs.map(e => ({
      label: e.AttributeName,
      value: e.AttributeNumID,
      groupBy: e.GroupBy,
    }));

    const onExpressionAttrChange = (val: any) => {
      const fs = options.filter(e => e.value === val);
      //console.log('ch', val);

      if (fs.length > 0) {
        exp.label = fs[0].label;
        exp.value = fs[0].value;
        setExpAttrs([...expAttrs]);
        //console.log('exp', exp);
      }
    };

    return (<>
      <Select
        defaultValue={defaultValue}
        dropdownRender={renderAttributeSelectorDropdown}
        onChange={onExpressionAttrChange}
        optionFilterProp="label"
        optionLabelProp="label"
        showSearch
      >
        {renderAttributeSelectorOptions(options)}
      </Select>
    </>);
  };

  const expressionItemList = () => {
    const pl = expAttrs.length;

    const onOperandChange = (exp: StringKAnyVPair, v: any) => {
      //console.log('vand->', v, typeof v);
      exp.operand = v;
      setExpAttrs([...expAttrs]);
    };

    const onOperatorChange = (exp: StringKAnyVPair, v: any) => {
      //console.log('vope->', v);
      exp.operator = v;
      setExpAttrs([...expAttrs]);
    };

    const onResultTextChange = (res: StringKAnyVPair, t: string) => {
      //console.log('evt', res, t);
      res.label = '';
      res.value = t;
      setExpAttrs([...expAttrs]);
    };

    console.log('exp', expAttrs);
    return expAttrs.map((p, i) => (
      <Row align="middle" className="pat-unit-blk exp-row" key={p.uuid}>
        <Col className="exp-unit5">
          {!p.isElseValue && <Tag color="blue">
            {i === 0 && 'if'}
            {/*pl > 1 && pl === i + 1 && 'else'*/}
            {/*pl > 1 && pl === i + 1 && <Select />*/}
            {pl > 1 && i > 0 && pl > i + 1 && 'else if'}
            {/*pl > 2 && i > 0 && pl > i + 1 && 'else if'*/}
          </Tag>}
          {pl > 1 && pl === i + 1 && elseTypeSelector(p)}
        </Col>
        <Col className="exp-unit1">
          {p.isElseValue && <Select disabled />}
          {!p.isElseValue && expressionAttrItem(p.variable)}
        </Col>
        {p.opType === 'number' ? (<Col className="exp-unit2">
          {p.isElseValue && <Select disabled />}
          {!p.isElseValue && (
            <Select
              defaultValue={p.operator}
              disabled={p.isElseValue || !p.variable.value}
              onChange={val => onOperatorChange(p, val)}
            >
              <Select.Option value="=">=</Select.Option>
              <Select.Option value=">">&gt;</Select.Option>
              <Select.Option value=">=">&gt;=</Select.Option>
              <Select.Option value="<">&lt;</Select.Option>
              <Select.Option value="<=">&lt;=</Select.Option>
            </Select>
          )}
        </Col>) : (<Col className="exp-unit2">
          {p.isElseValue && <Select disabled />}
          {!p.isElseValue && (
            <Select
              defaultValue={p.operator}
              disabled={p.isElseValue || !p.variable.value}
              onChange={val => onOperatorChange(p, val)}
            >
              <Select.Option value="cts">Contain</Select.Option>
              <Select.Option value="exc">Exclude</Select.Option>
              <Select.Option value="mat">Match</Select.Option>
              <Select.Option value="stw">Starting with</Select.Option>
              <Select.Option value="edw">Ending with</Select.Option>
            </Select>
          )}
        </Col>)}
        {p.opType === 'number' ? <Col className="exp-unit3">
          {p.isElseValue && <Input disabled />}
          {!p.isElseValue && (
            <InputNumber defaultValue={p.operand ? Number(p.operand) : p.operand} disabled={!p.operator} onChange={val => onOperandChange(p, val)} />
          )}
        </Col> : <Col className="exp-unit3">
          {p.isElseValue && <Input disabled />}
          {!p.isElseValue && (
            <Input defaultValue={(p.opType === 'text' && p.operand === undefined) ? '' : p.operand} disabled={!p.operator} onChange={(e) => onOperandChange(p, e.target.value)} />
          )}
        </Col>}
        <Col className="exp-unit5">
          <Row align="middle" className="condition-cell" justify="center" style={{border: p.isElseValue ? 'none' : undefined,}}>
            {p.isElseValue && <Input disabled />}
            {!p.isElseValue && <Tag color="blue">then</Tag>}
          </Row>
        </Col>
        <Col className="exp-unit4">
          <div className="ant-btn-group exp-result-selector">
            <span className="ant-radio-button-wrapper">
              <Dropdown
                disabled={p.isElseValue ? false : (!p.operand && p.operand !== 0)}
                overlay={expResultDropdownMenu(p.result)}
                trigger={['click']}
              >
                <Button className="exp-result-type-btn">
                  {p.result.type === PatternType.Attr && (
                    <MenuOutlined />
                  )}
                  {p.result.type === PatternType.Text && (
                    <FontSizeOutlined />
                  )}
                </Button>
              </Dropdown>
            </span>
            {p.isElseValue ? false : (!p.operand && p.operand !== 0) && <Input disabled={true} />}
            {(p.isElseValue ? true : (p.operand || p.operand === 0)) && p.result.type === PatternType.Attr && (expressionAttrItem(p.result))}
            {(p.isElseValue ? true : (p.operand || p.operand === 0)) && p.result.type === PatternType.Text && (
              <Input
                defaultValue={p.result.defaultValue}
                onChange={evt => onResultTextChange(p.result, evt.target.value)}
              />
            )}
          </div>
        </Col>
        <Col>
          <HoverBgButton hovertype="danger" icon={<DeleteOutlined />} onClick={() => removeExpressionItem(p.uuid)} />
          <HoverBgButton disabled={i === 0 || p.isElseValue} hovertype="secondary" icon={<ArrowUpOutlined onClick={() => sortExpressionUp(p)} />} />
          <HoverBgButton disabled={i === expAttrs.length - 1} hovertype="secondary" icon={<ArrowDownOutlined onClick={() => sortExpressionDown(p)} />} />
        </Col>
        {i < pl - 1 && (
          <div className="pat-unit-space">
            <div className="pat-unit-connector" />
          </div>
        )}
      </Row>
    ));
  };

  const imageItemList = () => {
    const pl = imageAttrs.length;

    return imageAttrs.map((p, i) => (
      <Row className="pat-unit-blk" key={p.uuid}>
        <Col className="pat-unit1">
          {imageItem(p)}
        </Col>
        <Col>
          <HoverBgButton hovertype="danger" icon={<DeleteOutlined />} onClick={() => removeImageItem(p.uuid)} />
          <HoverBgButton disabled={i === 0} hovertype="secondary" icon={<ArrowUpOutlined onClick={() => sortImageUp(p)} />} />
          <HoverBgButton disabled={i === imageAttrs.length - 1} hovertype="secondary" icon={<ArrowDownOutlined onClick={() => sortImageDown(p)} />} />
        </Col>
        {i < pl - 1 && (
          <div className="pat-unit-space">
            <div className="pat-unit-connector" />
          </div>
        )}
      </Row>
    ));
  };

  const imageItem = (pat: StringKAnyVPair) => {
    //console.log('ats', pat);
    const attrs: StringKAnyVPair[] = [];
    let defaultValue: any = undefined;

    if (pat.pattern && typeof pat.pattern === 'object') {
      defaultValue = pat.pattern.defaultValue;
    }

    for (let k in attributes) {
      // eslint-disable-next-line
      attributes[k].forEach((e: StringKAnyVPair) => attrs.push({...e}));
    }

    const options = attrs.map(e => ({
      label: e.AttributeName,
      value: e.AttributeNumID,
      groupBy: e.GroupBy,
    }));
    //console.log('attrs ->', attrs, options);
    const onAttrChange = (val: any) => {
      const fs = options.filter(e => e.value === val);
      console.log('ch', val);
      if (fs.length > 0) {
        const ps = imageAttrs.filter(e => e.uuid === pat.uuid);

        if (ps.length === 1) {
          console.log('ps', ps, imageAttrs);
          ps[0].pattern = fs[0];
          //console.log('sign', ps[0]);
          setImageAttrs([...imageAttrs]);
        }
      }
    };

    return (<>
      <MenuOutlined className="pat-sec-icon" />
      <Select
        defaultValue={defaultValue}
        dropdownRender={renderAttributeSelectorDropdown}
        onChange={onAttrChange}
        optionFilterProp="label"
        optionLabelProp="label"
        showSearch
      >
        {renderAttributeSelectorOptions(options)}
      </Select>
    </>);
  };

  // eslint-disable-next-line
  const initExpAttrs = (ps: StringKAnyVPair[]) => {
    const dvk: StringKAnyVPair = {};
    //const sps: StringKAnyVPair[] = JSON.parse(JSON.stringify(ps));
    const sps: StringKAnyVPair[] = ps.map(e => {
      const result = e.Result || e.result;
      const operand = e.Operand === undefined ? e.operand : e.Operand;
      const variable = e.Variable || e.variable;

      console.log('e ->', e);
      return {
        isElseValue: e.IsElseValue || e.isElseValue || false,
        opType: e.OpType || e.opType,
        operand: operand === undefined ? '' : `${operand}`,
        operator: e.Operator || e.operator,
        result: {
          label: result.Label || result.label,
          type: result.Type || result.type,
          value: result.Value || result.value,
        },
        variable: {
          label: variable.Label || variable.label,
          value: variable.Value || variable.value,
        },
      };
    });

    console.log('attrs', sps, attributes);
    /*sps.forEach((e: StringKAnyVPair) => {
      e.Type = e.Type || e.type;
      e.Value = e.Value || e.value;
    });*/

    for (let k in attributes) {
      // eslint-disable-next-line
      attributes[k].forEach((e: StringKAnyVPair) => {
        dvk[e.AttributeNumID] = e.AttributeName;
      });
    }

    setExpAttrs(sps.map(e => (
      {
        ...e,
        uuid: e.uuid || uuid(),
        result: {
          ...e.result,
          defaultValue: e.result.value,
        },
        variable: {
          ...e.variable,
          defaultValue: e.variable.value,
        },
      }
    )));
  };

  // eslint-disable-next-line
  const initImageItems = (imgs: string[]) => {
    const items: StringKAnyVPair[] = [];

    if (Array.isArray(imgs) && imgs.length > 0) {
      const dvk: StringKAnyVPair = {};

      for (let k in attributes) {
        // eslint-disable-next-line
        attributes[k].forEach((e: StringKAnyVPair) => {
          dvk[e.AttributeNumID] = e.AttributeName;
        });
      }

      imgs.forEach(k => {
        items.push({
          uuid: uuid(),
          pattern: {
            defaultValue: k,
            label: dvk[k] || undefined,
            value: k,
          },
        });
      });
    }

    setImageAttrs(items);
  };

  // eslint-disable-next-line
  const initPatternUnits = (ps: StringKAnyVPair[]) => {
    const dvk: StringKAnyVPair = {};
    const sps: StringKAnyVPair[] = JSON.parse(JSON.stringify(ps));

    //console.log('attrs', ps, attributes);
    sps.forEach((e: StringKAnyVPair) => {
      e.Type = e.Type || e.type;
      e.Value = e.Value || e.value;
    });

    for (let k in attributes) {
      // eslint-disable-next-line
      attributes[k].forEach((e: StringKAnyVPair) => {
        dvk[e.AttributeNumID] = e.AttributeName;
      });
    }

    setPatternUnits(sps.map(e => (
      {
        type: e.Type,
        uuid: e.uuid || uuid(),
        pattern: {
          defaultValue: e.Value,
          label: e.Type === PatternType.Attr ? dvk[e.Value] : undefined,
          value: e.Value,
        },
      }
    )));
  };

  const patternAttrUnit = (pat: StringKAnyVPair) => {
    //console.log('ats', pat);
    const attrs: StringKAnyVPair[] = [];
    let defaultValue: any = undefined;

    if (pat.pattern && typeof pat.pattern === 'object') {
      defaultValue = pat.pattern.defaultValue;
    }

    for (let k in attributes) {
      // eslint-disable-next-line
      attributes[k].forEach((e: StringKAnyVPair) => attrs.push({...e}));
    }

    const options = attrs.map(e => ({
      label: e.AttributeName,
      value: e.AttributeNumID,
      groupBy: e.GroupBy,
    }));
    //console.log('attrs ->', attrs, options);
    const onAttrChange = (val: any) => {
      const fs = options.filter(e => e.value === val);
      console.log('ch', val);
      if (fs.length > 0) {
        const ps = patternUnits.filter(e => e.uuid === pat.uuid);

        if (ps.length === 1) {
          console.log('ps', ps, patternUnits);
          if (PatternType.Attr === ps[0].type) {
            //ps[0] = { ...ps[0], pattern: fs[0] };
            ps[0].pattern = fs[0];
            //console.log('sign', ps[0]);
            setPatternUnits([...patternUnits]);
          }
        }
      }
    };

    return (<>
      <MenuOutlined className="pat-sec-icon" />
      <Select
        defaultValue={defaultValue}
        dropdownRender={renderAttributeSelectorDropdown}
        onChange={onAttrChange}
        optionFilterProp="label"
        optionLabelProp="label"
        showSearch
      >
        {renderAttributeSelectorOptions(options)}
      </Select>
    </>);
  };

  const patternExpression = (forBE = false) => {
    const ps: StringKAnyVPair[] = [];

    //console.log('pu', patternUnits);
    patternUnits.forEach(e => {
      if (e.pattern) {
        let section = emptyStrToHtml(e.pattern.value);
        let cls = 'pat-label-exp';

        if (PatternType.Attr === e.type) {
          cls = 'pat-attr-exp';
          section = `{{${forBE ? e.pattern.value : e.pattern.label}}}`;
        }

        ps.push({label: section, cls});
      }
    });

    return ps.map((e, i) => (<span className={e.cls} key={`pat-label-exp-${i}`} dangerouslySetInnerHTML={{__html: e.label}}></span>));
  };

  const patternMetaData = () => {
    const ps: StringKAnyVPair[] = [];

    //console.log('pu', patternUnits);
    patternUnits.forEach(e => {
      if (e.pattern) {
        let p = {
          type: e.type === PatternType.Attr ? PatternType.Attr : PatternType.Text,
          value: e.pattern.value,
        }

        ps.push(p);
      }
    });

    return ps;
  };

  const patternTextUnit = (pat: StringKAnyVPair) => {
    let defaultValue: any = undefined;

    if (pat.pattern && typeof pat.pattern === 'object') {
      defaultValue = pat.pattern.defaultValue;
    }

    const onTextChange = (evt: any) => {
      //const val = evt.target.value.trim();
      const val = evt.target.value;

      if (val) {
        const ps = patternUnits.filter(e => e.uuid === pat.uuid);

        if (ps.length === 1) {
          if (PatternType.Text === ps[0].type) {
            ps[0].pattern = { value: val };
            //console.log('sign', ps[0]);
            setPatternUnits([...patternUnits]);
          }
        }
      }
    };

    return (<>
      <FontSizeOutlined className="pat-sec-icon" />
      <Input onChange={onTextChange} defaultValue={defaultValue} />
    </>);
  };

  const patternUnitList = () => {
    const pl = patternUnits.length;

    return patternUnits.map((p, i) => (
      <Row className="pat-unit-blk" key={p.uuid}>
        <Col className="pat-unit1">
          {p.type === PatternType.Attr ? patternAttrUnit(p) : patternTextUnit(p)}
        </Col>
        <Col>
          <HoverBgButton hovertype="danger" icon={<DeleteOutlined />} onClick={() => removePatternUnit(p.uuid)} />
          <HoverBgButton disabled={i === 0} hovertype="secondary" icon={<ArrowUpOutlined onClick={() => sortPatternUp(p)} />} />
          <HoverBgButton disabled={i === pl - 1} hovertype="secondary" icon={<ArrowDownOutlined onClick={() => sortPatternDown(p)} />} />
        </Col>
        {i < pl - 1 && (
          <div className="pat-unit-space">
            <div className="pat-unit-connector" />
          </div>
        )}
      </Row>
    ));
  };

  const removeExpressionItem = (uuid: string) => {
    for (let i = expAttrs.length - 1; i >= 0; i--) {
      if (expAttrs[i].uuid === uuid) {
        expAttrs.splice(i, 1);
        setExpAttrs([...expAttrs]);
      }
    }
  };

  const removeImageItem = (uuid: string) => {
    for (let i = imageAttrs.length - 1; i >= 0; i--) {
      if (imageAttrs[i].uuid === uuid) {
        imageAttrs.splice(i, 1);
        setImageAttrs([...imageAttrs]);
      }
    }
  };

  const removePatternUnit = (uuid: string) => {
    for (let i = patternUnits.length - 1; i >= 0; i--) {
      if (patternUnits[i].uuid === uuid) {
        patternUnits.splice(i, 1);
        setPatternUnits([...patternUnits]);
      }
    }
  };

  const renderAttributeSelectorDropdown = (optNode: any) => {
    return (
      <div>
        <Row style={{backgroundColor: '#fafafa'}}>
          <Col span={16} style={{paddingLeft: 8}}><Text strong>Name</Text></Col>
          <Col span={8}><Text strong>Source</Text></Col>
        </Row>
        <Divider style={{ margin: 0}} />
        {optNode}
      </div>
     );
  };

  const renderAttributeSelectorOptions = (options: StringKAnyVPair[]) => {
    //console.log('opts->', options);
    return options.map(e => (
      <Option key={e.value} value={e.value} label={e.label}>
        <Row>
          <Col span={16}>
            <Text style={{width:280}} ellipsis={{ tooltip: e.label }}>{e.label}</Text>
          </Col>
          <Col span={8}>
            <Text style={{width:180}} ellipsis={{ tooltip: e.groupBy }}>{e.groupBy}</Text>
          </Col>
        </Row>
      </Option>
    ));
  };

  const sortExpressionDown = (img: StringKAnyVPair) => {
    for (let i = 0; i < expAttrs.length - 1; i++) {
      if (expAttrs[i] === img) {
        expAttrs[i] = expAttrs[i + 1];
        expAttrs[i + 1] = img;
        break;
      }
    }

    setExpAttrs([...expAttrs]);
  };

  const sortExpressionUp = (img: StringKAnyVPair) => {
    for (let i = expAttrs.length - 1; i > 0; i--) {
      if (expAttrs[i] === img) {
        expAttrs[i] = expAttrs[i - 1];
        expAttrs[i - 1] = img;

        if (img.isElseValue) {
          img.isElseValue = false;
          img.variable.defaultValue = img.variable.value;
        }

        break;
      }
    }

    setExpAttrs([...expAttrs]);
  };

  const sortImageDown = (img: StringKAnyVPair) => {
    for (let i = 0; i < imageAttrs.length - 1; i++) {
      if (imageAttrs[i] === img) {
        imageAttrs[i] = imageAttrs[i + 1];
        imageAttrs[i + 1] = img;
        break;
      }
    }

    setImageAttrs([...imageAttrs]);
  };

  const sortImageUp = (img: StringKAnyVPair) => {
    for (let i = imageAttrs.length - 1; i > 0; i--) {
      if (imageAttrs[i] === img) {
        imageAttrs[i] = imageAttrs[i - 1];
        imageAttrs[i - 1] = img;
        break;
      }
    }

    setImageAttrs([...imageAttrs]);
  };

  const sortPatternDown = (pat: StringKAnyVPair) => {
    for (let i = 0; i < patternUnits.length - 1; i++) {
      if (patternUnits[i] === pat) {
        patternUnits[i] = patternUnits[i + 1];
        patternUnits[i + 1] = pat;
        break;
      }
    }

    setPatternUnits([...patternUnits]);
  };

  const sortPatternUp = (pat: StringKAnyVPair) => {
    for (let i = patternUnits.length - 1; i > 0; i--) {
      if (patternUnits[i] === pat) {
        patternUnits[i] = patternUnits[i - 1];
        patternUnits[i - 1] = pat;
        break;
      }
    }

    setPatternUnits([...patternUnits]);
  };

  // eslint-disable-next-line
  const suffix = (
    <>
      <Form.Item
        name="Suffix"
        style={{ width: '90%', float: 'left' }}
      >
        <Input
          style={{ width: 180 }}
          placeholder="Suffix"
          onChange={(value) => {props.handleModalChange(value, 'text');}}
          value={formValues.Suffix}
          name="Suffix"
          addonAfter={(
            <Popover title="Suffix" content={`Suffix is used for cases where specific text values are required for Mapping, ex:  'lb', 'kilograms', 'CAD', etc`}>
              <IconWrapper>
                <Info style={{ marginLeft: '20px', marginTop: '5px' }} />
              </IconWrapper>
            </Popover>
          )}
        />
      </Form.Item>
    </>
  );

  React.useEffect(() => {
    //if (formValues.ConditionType === 1) {
    if (formValues.MapperType === MapperType.SINGLE && !productFieldIsLoading) {
      setProductFieldIsLoading(true);
      checkProductFields();
    }
  }, [
    checkProductFields,
    productFieldIsLoading,
    setProductFieldIsLoading,
    formValues,
  ]);

  React.useEffect(() => {
    if (lastEditKey !== formValues.ElementName) {
      console.log('d ->', formValues);
      if (Array.isArray(formValues.MapperPattern)) {
        initPatternUnits(formValues.MapperPattern);
      } else {
        setPatternUnits([]);
      }

      if (formValues.MapperType === MapperType.IMAGE) {
        initImageItems(formValues.Attributes);
      } else {
        setImageAttrs([]);
      }

      if (formValues.MapperType === MapperType.EXPRESSION) {
        initExpAttrs(formValues.MapperExpression);
      } else {
        setExpAttrs([]);
      }

      setLastEditKey(formValues.ElementName);
    }
    //console.log('inp', patternUnits);
  }, [
    formValues,
    initExpAttrs,
    initImageItems,
    initPatternUnits,
    lastEditKey,
  ]);

  return (<>
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={
        (
          <TitleHeader>
            {`What value should we map to ${formValues.ElementDisplayName}`}
          </TitleHeader>
        )
      }
      onCancel={handleCancel}
      width={1100}
      footer={
        (
          <Form form={form} onFinish={beforeSubmit}>
            <Row justify="end">
              <Col span={6} style={{ padding: '0 5px' }}>
                <Form.Item>
                  <Button block key="back" danger onClick={handleCleanMapping}>
                    Clean Mapping
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3} style={{ padding: '0 5px' }}>
                <Form.Item>
                  <Button block key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3} style={{ padding: '0 5px' }}>
                <Form.Item>
                  <Button block key="submit" type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )
      }
    >
      <TitleWrapper>
        <IconWrapper><Info /></IconWrapper>
        {formValues.HelpText}
      </TitleWrapper>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ size: 'middle' }}
        onFinish={beforeSubmit}
      >
        <Form.Item label="Mapper Type" name="MapperType">
          <Radio.Group buttonStyle="solid" name="MapperType" onChange={(value) => {props.handleModalChange(value, 'MapperType');}} defaultValue={formValues.MapperType} value={formValues.MapperType}>
            <Radio.Button value={3}>Product Field</Radio.Button>
            <Radio.Button value={1}>Text</Radio.Button>
            <Radio.Button value={MapperType.MULTIPLE}>Pattern</Radio.Button>
            <Radio.Button value={MapperType.IMAGE}>Image</Radio.Button>
            <Radio.Button value={MapperType.EXPRESSION}>Expression</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {formValues.MapperType === MapperType.SINGLE && (<>
          {!productFieldLoaded && <Loading size={32} />}
          <Input.Group>
            <Form.Item
              label={' '}
              name="Attributes"
              rules={[{ required: true, message: 'Field required.' }]}
              style={{ width: '70%', float: 'left' }}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 18 }}
            >
              <Select
                dropdownMatchSelectWidth={500}
                showSearch
                mode={isMultiple ? 'multiple' : undefined}
                onChange={(value) => {props.handleModalChange(value, 'Attributes');}}
                placeholder="Select an Attribute"
                /* filterOption={filterOption} */
                filterOption={(value: string, option: any)=> {return option.label?.toLowerCase().indexOf(value.toLowerCase()) >= 0}}
                getPopupContainer={trigger => trigger.parentNode}
                optionLabelProp="label"
                dropdownRender={(OptinNode) => (
                  <div>
                    <Row style={{backgroundColor: '#fafafa'}}>
                      <Col span={16} style={{paddingLeft: 8}}><Text strong>Name</Text></Col>
                      <Col span={8}><Text strong>Source</Text></Col>
                    </Row>
                    <Divider style={{ margin: 0}} />
                    {OptinNode}
                  </div>
                )}
              >
                {
                  productFields.map((o: any) => <Option key={o.attributeNumID} value={o.attributeNumID} label={o.attributeName}>{
                    <Row>
                      <Col span={16}><Text style={{width:280}} ellipsis={{ tooltip: o.attributeName }}>{o.attributeName}</Text></Col>
                      <Col span={8}><Text style={{width:180}} ellipsis={{ tooltip: o.groupBy }}>{o.groupBy}</Text></Col>
                    </Row>
                  }</Option>)
                }
                {/*Object.keys(attributes).map((key: any) => (
                    props.attributes[key].map((o: any) => <Option key={o.AttributeNumID} value={o.AttributeNumID} label={o.AttributeName}>{
                      <Row>
                        <Col span={16}><Text style={{width:280}} ellipsis={{ tooltip: o.AttributeName }}>{o.AttributeName}</Text></Col>
                        <Col span={8}><Text style={{width:180}} ellipsis={{ tooltip: o.GroupBy }}>{o.GroupBy}</Text></Col>
                      </Row>
                    }</Option>)
                ))*/}
              </Select>
            </Form.Item>
            {/*<SuffixContainer>
              {suffix}
            </SuffixContainer>*/}
          </Input.Group>
          <Form.Item
            label={' '}
            name="MapperLookup"
            valuePropName="checked"
          >
            <Checkbox name="MapperLookup" onChange={(value) => {props.handleModalChange(value, 'ConditionType');}} checked={formValues.MapperLookup}>Use Channel Lookup Table</Checkbox>
          </Form.Item>
          <Form.Item
            label={' '}
            name="ConditionType"
          >
            <Checkbox onChange={(value) => {checkProductFields();props.handleModalChange(value, 'ConditionType');}} name="ConditionType" checked={formValues.ConditionType === 1}>If this field is blank, then use another value</Checkbox>
          </Form.Item>
          {formValues.ConditionType === 1 &&
          (
            <>
              <Form.Item label={' '} name="ConditionalMapperType">
                <Radio.Group
                  buttonStyle="solid"
                  name="ConditionalMapperType"
                  onChange={(value) => {props.handleModalChange(value, 'ConditionalMapperType');}}
                  defaultValue={formValues.ConditionalMapperType}
                  value={formValues.ConditionalMapperType}
                >
                  <Radio.Button value={1}>Product Field</Radio.Button>
                  <Radio.Button value={2}>Text</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {formValues.ConditionalMapperType === 1 ?
                ( productFieldLoaded ?
                  <Form.Item
                    label="Attribute"
                    name="ConditionAttributes"
                    rules={[{ required: true, message: 'Field required.' }]}
                  >
                    <Select
                      dropdownMatchSelectWidth={500}
                      showSearch
                      mode={isMultiple ? 'multiple' : undefined}
                      onChange={(value) => {props.handleModalChange(value, 'ConditionAttributes');}}
                      placeholder="Select an Attribute"
                      style={{ width: '100%' }}
                      /* filterOption={filterOption} */
                      filterOption={(value: string, option: any)=> {return option.label?.toLowerCase().indexOf(value.toLowerCase()) >= 0}}
                      getPopupContainer={trigger => trigger.parentNode}
                      optionLabelProp="label"
                      dropdownRender={(OptinNode) => (
                        <div>
                          <Row style={{backgroundColor: '#fafafa'}}>
                            <Col span={16} style={{paddingLeft: 8}}><Text strong>Name</Text></Col>
                            <Col span={8}><Text strong>Source</Text></Col>
                          </Row>
                          <Divider style={{ margin: 0}} />
                          {productFieldLoaded ? OptinNode : <Loading size={32} />}
                        </div>
                      )}
                    >
                      {/*
                        productFields.map((o: any) => <Option key={o.attributeNumID} value={o.attributeNumID} label={o.attributeName}>{
                          <Row>
                            <Col span={16}><Text style={{width:280}} ellipsis={{ tooltip: o.attributeName }}>{o.attributeName}</Text></Col>
                            <Col span={8}><Text style={{width:180}} ellipsis={{ tooltip: o.groupBy }}>{o.groupBy}</Text></Col>
                          </Row>
                        }</Option>)
                      */}
                      {Object.keys(attributes).map((key: any) => (
                        props.attributes[key].map((o: any) => <Option key={o.AttributeNumID} value={o.AttributeNumID} label={o.AttributeName}>{
                          <Row>
                          <Col span={16}><Text style={{width:280}} ellipsis={{ tooltip: o.AttributeName }}>{o.AttributeName}</Text></Col>
                        <Col span={8}><Text style={{width:180}} ellipsis={{ tooltip: o.GroupBy }}>{o.GroupBy}</Text></Col>
                          </Row>
                        }</Option>)
                      ))}
                      {
                        /* Object.keys(attributes).map((key: any) => (
                          <OptGroup key={key} label={key}>
                            {props.attributes[key].map((o: any) => <Option key={o.AttributeNumID} value={o.AttributeNumID}>{o.AttributeName}</Option>)}
                          </OptGroup>
                        )) */
                      }
                    </Select>
                  </Form.Item>
                  : <Loading size={32} />
                )
                : (
                  <Form.Item
                    label={' '}
                    name="ConditionalMappedValue"
                    rules={[{ required: true, message: 'Field required.' }]}
                  >
                    <Input
                      onChange={(value) => {props.handleModalChange(value, 'text');}}
                      value={formValues.ConditionalMappedValue}
                      name="ConditionalMappedValue"
                      placeholder="Condition Mapped Value"
                    />
                  </Form.Item>
                )}
            </>
          )}
        </>)}
        {formValues.MapperType === MapperType.TEXT && (<>
          <Form.Item
            label={' '}
            name="MappedValue"
            rules={[{ required: true, message: 'Field required.' }]}
            style={{marginBottom: 0}}
          >
            <Input
              onChange={(value) => {props.handleModalChange(value, 'text');}}
              value={formValues.MappedValue}
              name="MappedValue"
              placeholder="Mapped Value"
              style={{ width: '100%' }}
              //addonAfter={suffix}
            />
          </Form.Item>
        </>)}
        {formValues.MapperType === MapperType.MULTIPLE && (<>
           <Space style={{marginLeft: 157}}>
            <InfoButton type="primary" onClick={addAttrUnit}>
              Add Pattern Attribute
            </InfoButton>
            <InfoButton type="primary" onClick={addTextUnit}>
              Add Pattern Text
            </InfoButton>
            <HoverBgButton hovertype="danger" onClick={clearAllPatterns}>
              Remove
            </HoverBgButton>
          </Space>
          {patternExpression().length > 0 && (<PatternLabelCtn style={{ margin: '8px 0 8px 157px' }}>
            <span style={{margin: '0 6px 0 0'}}>Pattern: </span>
            {patternExpression()}
          </PatternLabelCtn>)}
          {patternUnits.length > 0 && (
            <PatternUnitCtn>
              {patternUnitList()}
            </PatternUnitCtn>
          )}
        </>)}
        {formValues.MapperType === MapperType.IMAGE && (<>
          {/*<div style={{ margin: '8px 0 8px 157px' }}>
            <ImageAttrs
              images={imageAttrs}
              updateImages={setImageAttrs}
            />
          </div>*/}
          <Space style={{marginLeft: 157}}>
            <Button type="primary" onClick={addAttrImage}>
              Add Image
            </Button>
          </Space>
          {imageAttrs.length > 0 && (
            <PatternUnitCtn>
              {imageItemList()}
            </PatternUnitCtn>
          )}
        </>)}
        {formValues.MapperType === MapperType.EXPRESSION && (<>
          <Space style={{marginLeft: 157}}>
            <Button type="primary" onClick={()=> addAttrExpression('number')}>
              Add Number Expression
            </Button>
            <Button type="primary" onClick={()=> addAttrExpression('text')}>
              Add Text Expression
            </Button>
          </Space>
          {expAttrs.length > 0 && (
            <PatternUnitCtn>
              {expressionItemList()}
            </PatternUnitCtn>
          )}
        </>)}
      </Form>
    </Modal>
  </>);
}

export const emptyStrToHtml = (str: string) => {
  return str.replace(/(\s)/g, '&nbsp;');
};

export default ModalForm;
