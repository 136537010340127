import { UserOutlined, BulbOutlined } from "@ant-design/icons";
import React from "react";
import Profile from "../../assets/icons/profile";

const iconColor = "currentColor";

export type IMenuItem = {
  key: string;
  name?: string;
  route?: string;
  link?: string;
  icon?: any;
  children?: IMenuItem[];
  permissionNum?: number;
  group?: boolean;
  onClick?: () => void;
  visible?: boolean;
};

const MenuItems: IMenuItem[] = [
  {
    key: "administration",
    name: "Administration",
    icon: <UserOutlined />,
    children: [
      {
        key: "administration-user-management",
        name: "Users",
        route: "/administration-user-management",
        icon: <Profile pathColor={iconColor} />,
        permissionNum: 10004,
      },
      {
        key: "administration-roles",
        name: "Roles",
        route: "/administration-roles",
        icon: <BulbOutlined />,
        permissionNum: 10004,
      },
    ],
  },
  {
    //  An object with only a key and with spacer included in key will be inserted as a spacer
    key: "spacer-1",
  },
];

export default MenuItems;
