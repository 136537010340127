import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
import { Col, Row, Spin } from 'antd';
import DashboardRow from './DashboardRow';
import SummaryChart from './SummaryChart';
import { fetchERPDigitBridgeLogSummary } from '../../services/channels';
import { convertToUtcTime } from '../../util/utcTime';
import { summaryData } from './function';
import { getChannelInfo } from './util';

const Page: React.FC = () => {
  const defaultFilterDate = moment();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<any>(defaultFilterDate);
  const [filterChannel, setFilterChannel] = useState<any>([]);
  const [summary, setSummary] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);

  const getDataSource = async () => {
    setSpinning(true);
    try {
      const res = await fetchERPDigitBridgeLogSummary(convertToUtcTime(filterDate, '', '', 'YYYY-MM-DDTHH:mm:ss')) || [];

      const formatRes = [];
      for (let i = 0; i < res.length; i++) {
        const data = res[i];
        let { channelName, channelAccountName } = await getChannelInfo(data.channelNum, data.channelAccountNum);
        if (data.channelAccountNum > 0) {
          formatRes.push({
            ...data,
            channelAccountName,
            channelName
          })
        }
      }
      // eslint-disable-next-line
      const [summary, chartData, summaryHour, hourChartData] = await summaryData(formatRes);
      setSummary(summary);
      setChartData(chartData);
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
    }
  }


  useEffect(() => {
    if (filterDate) {
      getDataSource();
    }
    // eslint-disable-next-line
  }, [filterDate])

  return (
    <ContentLayout>
      <Heading title="Operation Dashboard" />
      <Spin spinning={spinning}>
        <SummaryChart setFilterDate={setFilterDate} setFilterChannel={setFilterChannel} />
        <DashboardRow title="Total Day Summary" dataSource={summary[0]} chartData={chartData[0]} />
        <Row style={{ width: '100%' }} wrap={true}>
          {summary.filter((item: any, filterIndex: number) => filterIndex > 0).map((items: any, index: number) => {
            if ((filterChannel || []).length > 0 && !filterChannel.includes(items.channelAccountNum)) {
              return null;
            }
            return (
              <Col flex="631px" key={`dashboardRow-col-${index}`} >
                <DashboardRow
                  key={`dashboardRow-${index}`}
                  title={`${items.channelAccountName} ${items.dateFrom}`}
                  dataSource={items}
                // chartData={chartData[index + 1]}
                />
              </Col>
            )
          })}
        </Row>
      </Spin>
    </ContentLayout>
  );
};

export default Page;
