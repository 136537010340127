import { ProfilesState } from '../redux/types';
import { getProfilesState } from '../redux/utils';

// eslint-disable-next-line
const AcrossTabs = require('across-tabs').default;

type SharedProfileDataType = {
  displayName?: string,
  email?: string,
  masterAccountNum?: number,
  profileNum?: number,
};

/**
 * Shared profile data from Digit Bridge Web App
 */
type SharedProfileType = {
  profiles?: SharedProfileDataType[],
  selectedIndex?: number,
  permissions?: any,
  isProfileSelected?: boolean,
  isSelectingProfile?: boolean,
  apiAuthToken?: string,
};
/**
 * Message type
 */
enum MessageType {
  profileSet,
  profileRequest,
  parentConnected,
  closeChild,
}

/**
 * Broadcast message type
 * same as ERP
 */
type BroadcastMessageType = {
  messageType: MessageType,
  messageContent: string,
  tabId?: string,
};

/**
 * Get Profile & auth info - transform and stringify
 */
function getProfileStorage() {
  const apiAuthToken = localStorage.getItem('msal.idtoken');
  const profiles: ProfilesState = { ...getProfilesState(), apiAuthToken };
  const { profiles: profileData, ...rest } = profiles;
  // reformat since ERP not aligned with CC, may change later if we align on both
  const formattedProfiles: SharedProfileType = {
    ...rest,
    profiles: profileData?.map(profile => (
      {
        displayName: profile.DisplayName,
        email: profile.Email,
        masterAccountNum: profile.MasterAccountNum,
        profileNum: profile.ProfileNum,
      }
    )),
  };
  return JSON.stringify(formattedProfiles);
}
/**
 * Across Tab Parent Class will manage outgoing ERP links
 */
export class AcrossTabParent {
  key: string;

  parent: any;

  windowName: string;

  config: {
    onHandshakeCallback: (Tab: any) => void,
    onPollingCallback?: () => void,
    onChildCommunication?: (message: BroadcastMessageType) => void,
  };

  closedChild: string[];


  constructor() {
    // keys setup
    this.key = 'digit_bridge_profile';
    this.windowName = 'AcrossTab';

    // callback functions
    this.onHandshakeCallback = this.onHandshakeCallback.bind(this);
    this.onPollingCallback = this.onPollingCallback.bind(this);
    this.onChildCommunication = this.onChildCommunication.bind(this);

    // helper functions
    this.sendMessageToChild = this.sendMessageToChild.bind(this);
    this.openUrl = this.openUrl.bind(this);

    // config setup
    this.config = {
      onHandshakeCallback: this.onHandshakeCallback,
      onChildCommunication: this.onChildCommunication,
    };
    this.parent = new AcrossTabs.Parent(this.config);
    this.closedChild = [];
  }

  // callback functions
  onHandshakeCallback = (Tab: any) => {
    const { id } = Tab;
    this.sendMessageToChild(id, MessageType.parentConnected, '');
  };

  onPollingCallback = () => {
  };

  onChildCommunication = (message: BroadcastMessageType) => {
    const { messageType, tabId } = message;
    // tabId should always be defined for child communications
    if (!tabId || this.closedChild.includes(tabId)) return;

    switch (messageType) {
      case MessageType.profileRequest: {
        this.sendMessageToChild(tabId, MessageType.profileSet, getProfileStorage());
        break;
      }
      case MessageType.closeChild: {
        this.closedChild.push(tabId);
        break;
      }
      default: 
        break;
    }
  };

  // helper functions
  sendMessageToChild = (tabId: any, messageType: MessageType, messageContent: string) => {
    const message: BroadcastMessageType = {
      messageContent,
      messageType,
    };
    this.parent.broadCastTo(tabId, message);
  };

  openUrl = (postUrl: string = '') => {
    this.parent.closeAllTabs();
    this.parent.openNewTab({ url: `${process.env.ERP_LINK}${postUrl}`, windowName: this.windowName });
  };
}

const parent = new AcrossTabParent();

interface LinkERPType {
  open: ()=>void,
  openCentralOrderHistory: (centralOrderNum: string) => void,
  openBuyDashboard: () => void,
  openSellDashboard: () => void,
  openInvoiceCustomerReturnList: () => void,
  openInquiryProductSalesLog: () => void,
  openCustomerList: () => void,
}
/**
 * Export object containing all types of links to ERP
 */
const linkERP: LinkERPType = {
  open: function open() {
    parent.openUrl();
  },
  openCentralOrderHistory: function openCentralOrderHistory(centralOrderNum: string) {
    parent.openUrl(`salesOrder/CentralOrderHistory/${centralOrderNum}`);
  },
  openBuyDashboard: function openBuyDashboard() {
    parent.openUrl('purchaseDashboard');
  },
  openSellDashboard: function openSellDashboard() {
    parent.openUrl('salesDashboard');
  },
  openInvoiceCustomerReturnList: function openInvoiceCustomerReturnList() {
    parent.openUrl('Return/CustomerReturnList');
  },
  openInquiryProductSalesLog: function openInquiryProductSalesLog() {
    parent.openUrl('Bi/ProductSalesLog');
  },
  openCustomerList: function openCustomerList() {
    parent.openUrl('Customer/CustomerList')
  }
};

export default linkERP;
