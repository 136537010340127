import React, { useEffect } from 'react';// eslint-disable-line
import jwt_decode from 'jwt-decode';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { activateUser } from '../services/userManagement';
import Loop from '../assets/icons/loop';
import notificationActions from '../redux/actions/notifications';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100vh;
 `;

const parseQueryString = (url: string) => {
  let newUrl = '';
  for (let i = 0; i < url.length; i += 1) {

    if (url[i] === '?') newUrl += '{"';
    else if (url[i] === '=') newUrl += '":"';
    else if (url[i] === '&') newUrl += '","';
    else newUrl += url[i];
  }
  newUrl += '"}';
  return JSON.parse(newUrl);
};
function Activate(props: any) {
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('Authorization') || '';
      const decoded = jwt_decode(token);
      const params = parseQueryString(props.location.search);
      const masterAccountNum = params.masterAccountNum || 0;
      const profileNum = params.profileNum || 0;
      // @ts-ignore
      await activateUser(decoded?.emails[0], params.activationCode, masterAccountNum, profileNum);
      props.history.push('/');
      window.location.reload();

    } catch (e) {
      if (e.response.status === 404) {
        props.actions.setNotification('error', 'Please logout and register a new account first before clicking invitation link');
      }
      props.history.push('/');
      throw e;
    }
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData();
    }, []);
  };

  useFetch();

  return (
    <LoadingWrapper>
      <Loop
        height={40}
        width={40}
        style={{
          transform: 'rotate(45deg)',
          animation: 'antRotate 1.2s infinite reverse linear',
        }}
      />
    </LoadingWrapper>
  );
}

const mapStateToProps = (state: any) => ({
  profilesEntity: state.profiles,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activate);
