// eslint-disable-next-line
import { authAPI, digitBridgeApi } from './http';

export const assignRolePermission = async (
  email: string,
  roles: StringKAnyVPair[],
) => digitBridgeApi.post(`/v1/userManagement/${email}/assignRolePermissions`, roles);

export const fetchuserManagement = async (): Promise<any> => digitBridgeApi.get('/v1/userManagement/profileUsers');

export const registerNewProfile = async (params: any): Promise<any> => digitBridgeApi.post('/v1/userManagement/profilesUsers', params);

export const fetchProfiles = async (): Promise<Entities.LoginProfile[]> => digitBridgeApi.get('/v1/login/profiles');

export const fetchPortalProfiles = async (): Promise<StringKAnyVPair[]> => authAPI.post('/api/account/Profiles');

export const fetchProfilesByNetworkNum = async (nn: number | string): Promise<Entities.LoginProfile[]> => authAPI.get('/api/loginProfiles', {params: {networkNum: nn}});

export const deleteProfile = async (email: string): Promise<any> => digitBridgeApi.delete(`/v1/userManagement/profileUsers/${email}`);

export const resendInvitation = async (email: string): Promise<any> => digitBridgeApi.post(`/v1/userManagement/profileUsers/${email}/reSendInvitation`);

export const activateInvitation = async (email: string): Promise<any> => digitBridgeApi.post(`/v1/userManagement/profilerUsers/${email}/directActivate`);

export const fetchRoles = async () => digitBridgeApi.get('/v1/userManagement/profileRoles');

export const deleteRole = async (roleNum: number) => digitBridgeApi.post(`v1/userManagement/profileRoles/${roleNum}/delete`);

export const fetchAccountPermissions = async (): Promise<Entities.ProfileUserPermission[]> => authAPI.post('/api/account/UserPermissions');

export const fetchUserPermissions = async (email: string): Promise<Entities.ProfileUserPermission[]> => digitBridgeApi.get(`/v1/userManagement/profileUsers/${email}/permissions`);

export const fetchPermissions = async (): Promise<any> => digitBridgeApi.get('/v1/userManagement/permissions');
export const fetchRolePermissions = async (rowNum: number): Promise<Entities.IGetProfileRolesByRoleResponse[]> => digitBridgeApi.get(`/v1/userManagement/profileRoles/${rowNum}/permissions`);

export const editUserPermissions = async (params: any): Promise<any> => digitBridgeApi.patch(`/v1/userManagement/profileUsers/${params[0].Email}/permissions`, params);

export const editUser = async (params: any): Promise<any> => digitBridgeApi.patch(`/v1/userManagement/profilesUsers/${params.Email}`, params.body);

export const activateUser = async (email: string, code: string, masterAccountNum: string, profileNum: string): Promise<any> => digitBridgeApi.post(`/v1/userManagement/profilerUsers/${email}/activation`, {
  ActivationCode: code,
  masterAccountNum,
  profileNum,
});

export const userAdmins = async (email: string): Promise<any> => digitBridgeApi.get(`/v1/userManagement/profileUsers/${email}/userAdmins`);

export const fetchPermissionsDesription = async (): Promise<any> => digitBridgeApi.get('/v1/userManagement/permissionsDesription');
