import React from 'react';
import { Alert, notification,Row } from 'antd';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
} from '../../../constants/config';
import { fetchProductCopywritingElements } from '../../../services/copywriting';
import { isDevEnv } from '../../../util';
import AttrList from './AttrList';
import AttrGroupList from './AttrGroupList';
import TableFilter from '../../../components/common/TableFilter'

type Props = {
  focused: boolean;
  maskScreen: Function;
  productId: string;
  setSelectedAttributes?: Function;
  state?: StringKAnyVPair;
};

const FieldSelector = (props: Props) => {
  const { useState } = React;
  const [attrList, setAttrList] = useState<StringKAnyVPair[]>([]);
  const [filterAttrList, setFilterAttrList] = useState<StringKAnyVPair[]>([]);
  const [attrListVersion, setAttrListVersion] = useState(0);
  const [attrLoaded, setAttrLoaded] = useState(false);
  const [inited, setInited] = useState(false);
  const style = {
    display: props.focused ? '' : 'none',
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    props.maskScreen(true);
    //console.log('pp id->', props.productId);
    try {
      const res = await fetchProductCopywritingElements(props.productId);

      console.log('res->', res);
      if (res && typeof res === 'object') {
        const { data } = res;

        if (Array.isArray(data)) {
          setAttrList(data);
          setAttrListVersion(attrListVersion + 1);
        }
      }
    } catch(e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch content resources attributes error: ${e}`,
      });
    } finally {
      setAttrLoaded(true);
      props.maskScreen(false);
    }
  };

  React.useEffect(() => {
    if (props.focused && !inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData, props]);

  React.useEffect(()=>{
    setAttrListVersion(prev => prev + 1);
  },[filterAttrList])

  return (<>
    <div className="dest-step-wrapper" style={style}>
      <Row justify='start' style={{marginBottom: 8}}>
      <TableFilter size="middle" columns={[{name:'elementName'}]} dataSource={attrList} setFilteredData={setFilterAttrList}   />
      </Row>
      {attrLoaded && attrList.length === 0 && (
        <Alert message="No attribute found" type="error" />
      )}
      {isDevEnv() || true ?
        <AttrGroupList
          list={filterAttrList}
          productId={props.productId}
          selectable
          setSelectedAttributes={props.setSelectedAttributes}
          state={props.state}
          version={attrListVersion}
        /> :
        <AttrList
          list={filterAttrList}
          productId={props.productId}
          selectable
          setSelectedAttributes={props.setSelectedAttributes}
          state={props.state}
          version={attrListVersion}
        />
      }
    </div>
  </>);
};

export default FieldSelector;
