import { digitBridgeApi } from './http';


export const getMetafieldDefinitions = async (): Promise<any> => digitBridgeApi.get('/v1/metafield/definitions');

export const getMetafields= async (definitionNum: number): Promise<any> => digitBridgeApi.get('/v1/metafields',{params: { definitionNum }});

type AddMetafieldParams = {
    MetaFieldName: string,
    ChannelNum: number,
    DefinitionNum: number,
    TypeNum: number,
    IsCollectionSet: number,
    ValidationJsonValue: any;
    OptionsJsonValue: any;
    ChannelAccountNum: number;
    Description: string;
}

export const addMetafield = async (params : AddMetafieldParams): Promise<any> => {
    const json = JSON.stringify(params)
    return digitBridgeApi.post('/v1/metafields', json,{ headers:{ channelNum: params.ChannelNum} })
}
type EditMetafieldParams = {
    Description: string;
    ValidationJsonValue: any[];
    OptionsJsonValue: any[];
}

export const editMetafield = async (metafieldNum: number,channelNum: number, params:EditMetafieldParams): Promise<any> => {
    const json = JSON.stringify(params)
    return digitBridgeApi.patch(`/v1/metafields/${metafieldNum}`, json, {headers: {channelNum}})
}

export const getMetafieldById = async (metafieldNum: number, channelNum: number): Promise<any> => {
    return digitBridgeApi.get(`/v1/metafields/${metafieldNum}`, {headers:{channelNum}})
}

export const getMetaTypes = async (): Promise<any> => digitBridgeApi.get('/v1/metafield/types');

