import React from 'react';
//   import { DataGrid } from '../../../components/common/datagrid/DataGrid3';
import Heading from '../../../../components/common/Heading';
import Spacer from '../../../../components/common/Spacer';
import SiteContent from '../../../../components/SiteContent';
import ContentLayout from '../../../../components/ContentLayout';
import FeatLayout from './FeatLayout';


const PageContent: React.FC = () => {
  return (
    <ContentLayout>
      <Heading title="Essential Info > Product Features > Layout" />
      <Spacer />
      <SiteContent className="common-fixed-box" flexGrow>
        <FeatLayout />
      </SiteContent>
    </ContentLayout>
  );
};

export default PageContent;

export const LABEL_POSITION_TOP = 1;
export const LABEL_POSITION_LEFT = 2;
export const ITEM_TYPE_PLACEHOLDER = -10;
