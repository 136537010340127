import React, { memo, useCallback, useContext, useState } from 'react';
import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Actions, ProductDetailScreenContext } from '../context';
import { ProductTabContainer } from '../styledComponents';
import { basicInfoFields } from '../common';
import FormElement from '../../../components/common/FormElement';

interface BasicInfoTabProps {
  basicInfoForm: FormInstance;
  editMode: boolean;
  isFormUpdated: boolean;
  dispatch: any;
  classifications: any;
  labels: any;
  channels: any;
  sku: string;
  isCreating: boolean;
  productType: number;
  bundleType: number;
}

const BasicInfoTab: React.FC<BasicInfoTabProps> = ({ dispatch, editMode, basicInfoForm, classifications, labels, channels, sku, isCreating, productType, bundleType }) => {
  const MediaList = basicInfoForm.getFieldValue('MediaList');
  const [basicInfoHeight, setHeight] = useState<number>(500);
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const classificationOnChange = (e: any) => dispatch({ type: Actions.SET_CLASSIFICATION, params: e });
  const onStyleCodeChange = useCallback(() => {
    basicInfoForm?.setFieldsValue({
      ...basicInfoForm.getFieldsValue(),
      styleMaster: {
        editReviewValues: [],
      },
    });
    dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
  }, [basicInfoForm, dispatch]);
  return (
    <ProductTabContainer>
      <Row>
        <Col span={24} style={{ paddingLeft: '15px' }}>
          <Row style={{ width:'100%', flexDirection:'row', display:'flex' }}>
            <Col style={{ height: 'fit-content' }} ref={measuredRef} span={12}>
              {basicInfoFields(editMode, productType, bundleType, isCreating, sku,  labels, classificationOnChange, channels, classifications, onStyleCodeChange ).data1.map((f: any) => (
                <FormElement
                  key={`data1-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
            <Col span={12} style={{ height: basicInfoHeight, textAlign:'center' }}>
              <img src={MediaList?.length > 0 ? MediaList[0].MediaURL : 'https://via.placeholder.com/500x300'} alt='product' style={{ maxWidth:'100%', maxHeight:'100%', width:'auto', height:'auto' }} />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ borderBottom: 'solid 1px #016dff', marginBottom: '15px' }}>
              <h3 style={{ marginBottom: '2px' }}>Pricing</h3>
            </Col>
            <Col span={12}>
              {basicInfoFields(editMode, productType, bundleType).data2.map((f: any) => (
                <FormElement
                  key={`data2-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
            <Col span={12} style={{ paddingLeft: '15px' }}>
              {basicInfoFields(editMode, productType, bundleType).data3.map((f: any) => (
                <FormElement
                  key={`data3-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ borderBottom: 'solid 1px #016dff', marginBottom: '15px' }}>
              <h3 style={{ marginBottom: '2px' }}>Technical</h3>
            </Col>
            <Col span={12}>
              {basicInfoFields(editMode, productType, bundleType).data4.map((f: any) => (
                <FormElement
                  key={`data4-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
            <Col span={12} style={{ paddingLeft: '15px' }}>
              {basicInfoFields(editMode, productType, bundleType).data5.map((f: any) => (
                <FormElement
                  key={`data5-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ borderBottom: 'solid 1px #016dff', marginBottom: '15px' }}>
              <h3 style={{ marginBottom: '2px' }}>Physical</h3>
            </Col>
            <Col span={12}>
              {basicInfoFields(editMode, productType, bundleType).data6.map((f: any) => (
                <FormElement
                  key={`data6-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
            <Col span={12} style={{ paddingLeft: '15px' }}>
              {basicInfoFields(editMode, productType, bundleType).data7.map((f: any) => (
                <FormElement
                  key={`data7-${Math.random()}`}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...f}
                />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </ProductTabContainer>
  );
};

const BasicInfoTabMemo = memo<BasicInfoTabProps>(BasicInfoTab);

const BasicInfo: React.FC<{ isCreating: boolean }> = ({ isCreating }) => {
  const [state, dispatch] = useContext(ProductDetailScreenContext);
  if (!state || !dispatch) {
    return null;
  }

  return (
    <BasicInfoTabMemo
      basicInfoForm={state.basicInfoForm}
      editMode={state.editMode}
      isFormUpdated={state.isFormUpdated}
      dispatch={dispatch}
      classifications={state.classifications}
      labels={state.labels}
      channels={state.channels}
      sku={state.basicInfoForm.getFieldValue('SKU')}
      isCreating={isCreating}
      productType={state.productType}
      bundleType={state.bundleType}
    />
  );
};

export default BasicInfo;
