import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import routes from './constants/routes';

function PrivateRoute ({component: Component, ...rest}: any) {
  const route = routes.get(rest.path);
  let hasPermissions = true;
  const hasRoute = rest.permissions.size && rest.permissions.get(route);
  if (hasRoute) {
    hasPermissions = rest.permissions.get(route).PermissionLevel;
  }
  return <Route {...rest} render={props => hasPermissions ? <Component {...props} /> : <Redirect to="/" />} />
}

const mapStateToProps = (state: any) => ({
  permissions: state.profiles.permissions
});

export default connect(mapStateToProps)(PrivateRoute);