import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import {
  CloseOutlined,
  UpOutlined,
  EditOutlined,
  LockOutlined,
  RedoOutlined,
  RightOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
//import moment from 'moment';

import Fullscreen from '../../../assets/icons/fullscreen';
import FullscreenExit from '../../../assets/icons/fullscreen_exit';
import FullScreenSection from '../../../components/common/FullScreenSection';
import Loading from '../../../components/common/Loading';
import ModalDialog from '../../../components/common/ModalDialog';
import ScreenMask from '../../../components/common/ScreenMask';
import { DataGrid } from '../../../components/common/datagrid/DataGrid2';
import { InfoButton } from '../../../components/common/styledComponents';
import { getTowerItemWidth } from '../../../components/TowerSetting';
import {
  CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import SalesChannelEditor from '../../../screens/DetailProduct/BasicAttrEditor/SalesChannelEditor';
//import { loadChannelControlFlags } from '../../../screens/DetailProduct/helper';
import {
  editControlTowerValues,
  fetchControlTowerByProductNum,
  fetchControlTowerCfgHideField,
  fetchControlTowerMappingBySubGroupNum,
  fetchSalesPerformance,
} from '../../../services/controlTower';
import Products, {
  fetchSimpleProductPartialData,
} from '../../../services/products';
import { getProductTypeByName, getProfileSettingValue } from '../../../util';
//import { convertToUtcTime } from '../../../util/utcTime';

import ProductDetailTitle from '../../ListProducts/ProductDetailTitle';
import { loadProductBasicAttrs, loadStyleVariations } from '../../DetailProduct/helper';
import ChannelDataEditor from './ChannelDataEditor';
import OverwriteSwitch from './OverwriteSwitch';
import { TowerDialogBody } from './styles';
import { TOWER_ITEM_MARGIN } from '.';

interface Props extends ModalProps {
  onClose: Function;
  onSave?: Function;
  openProductDialog?: Function;
  product: StringKAnyVPair;
};

const getProductIdByPath = (p: string) => {
  const fs = p.split('/');

  return fs[fs.length - 1];
};

const ProductDetailDialog = (props: Props) => {
  const FORM_ITEM_CLS = 'form-item';
  const FORM_LABEL_CLS = 'form-label';
  const FORM_SECTION_CLS = 'form-section';
  const { useCallback, useRef, useState } = React;
  const { product } = props;
  const [attrDict, setAttrDict] = useState<StringKAnyVPair>({});
  const [basicInfo, setBasicInfo] = useState<StringKAnyVPair>({});
  const [channelDataDict, setChannelDataDict] = useState<StringKAnyVPair>({});
  const [channelFlagDict, setChannelFlagDict] = useState<StringKAnyVPair>({});
  const [channelAccountList, setChannelAccountList] = useState<number[]>([]);
  const [channelDataEditorVisible, setChannelDataEditorVisible] = useState(false);
  const [currentChannelDataCellIndex, setCurrentChannelDataCellIndex] = useState(-1);
  //const [currentChannelDataRow, setCurrentChannelDataRow] = useState<StringKAnyVPair>({});
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>(props.product);
  const [currentProductId, setCurrentProductId] = useState(getProductIdByPath(props.product.ProductId));
  const [detailDialogTitleType, setDetailDialogTitleType] = useState('');
  const [displayGrid, setDisplayGrid] = useState(false);
  const [displayInvChannel, setDisplayInvChannel] = useState(true);
  const [editDict, setEditDict] = useState<StringKAnyVPair>({});
  const [editMode, setEditMode] = useState(0);
  const [gridColumns, setGridColumns] = useState<StringKAnyVPair[]>([]);
  const [gridData, setGridData] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [gridRef, setGridRef] = useState<any>(null);
  const [hideNoChannelField, setHideNoChannelField] = useState(false);
  const [isChannelDataFullscreen, setIsChannelDataFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inited, setInited] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [itemDict, setItemDict] = useState<StringKAnyVPair>({});
  const [lastProductId, setLastProductId] = useState(getProductIdByPath(props.product.ProductId));
  const [overwriteChildren, setOverwriteChildren] = useState(false);
  //const [perfData, setPerfData] = useState<StringKAnyVPair[]>([]);
  const [scExpand, setScExpand] = useState(true);
  const [spExpand, setSpExpand] = useState(true);
  const [searchTimer, setSearchTimer] = useState<number>();
  //const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const [towerData, setTowerData] = useState<StringKAnyVPair[]>([]);
  const attrSearchInputRef = useRef<any>(null);
  const bodyRef = useRef<any>(null);
  const titleRef = useRef<any>();

  const initSwitch = useCallback(async ()=>{
    try {
      const res = await getProfileSettingValue('ProductOverwriteChildrenValue', 0)
      setOverwriteChildren(!!res)
    } catch (error) {
    }
  },[])

  useEffect(()=>{
    initSwitch()
  },[initSwitch])

  const allGroupsExpanded = React.useMemo(() => {
    return towerData.every(t => t.expand) && scExpand && spExpand;
  }, [scExpand, spExpand, towerData]);

  const cellDOMProps = (cellProps: StringKAnyVPair) => {
    const { indexInColumns } = cellProps;
    const data = cellProps.data.data[indexInColumns];
    //console.log('cc-->', cellProps);

    return {
      //editor: (data && data.childRowNum) ? undefined : null,
      onClick: () => {
        if (data && data.childRowNum) {
          //gridRef.current.startEdit({ columnId: cellProps.id, rowIndex: cellProps.rowIndex });
        }
      },
      onDoubleClick: (evt: any) => {
        if (!data || data.childRowNum === 0) {
          /*setTimeout(() => {
            gridRef.current.cancelEdit();
          }, 0);*/
        }
      },
    }
  };

  const closeChannelDataCellEditor = () => {
    setChannelDataEditorVisible(false);
  };

  const closeProductDetailDialog = () => {
    if (titleRef.current.refresh) titleRef.current.refresh();

    props.onClose();
  };

  const fetchControlTowerInfo = async (
    prd: StringKAnyVPair,
  ) => {
    setIsLoading(true);

    try {
      const gs = await fetchControlTowerByProductNum(prd.CentralProductNum);

      if (Array.isArray(gs)) {
        const tds: StringKAnyVPair[] = [];
        const cdvDict: StringKAnyVPair = {};
        let chdNum = 0;

        gs.forEach(gd => {
          const g = {
            children: [] as StringKAnyVPair[],
            columns: gd.columns,
            displayName: gd.controlTowerGroupName,
            displaySequence: gd.displaySequence,
            expand: true,
            groupType: gd.groupType,
            key: uuid(),
            rowNum: gd.rowNum,
            title: gd.controlTowerGroupName,
          };

          if (Array.isArray(gd.children)) {
            gd.children.forEach((ed: StringKAnyVPair) => {
              const item = {
                attributeId: ed.attributeId,
                attributeName: ed.attributeName,
                attributeNum: ed.attributeNum,
                attributeValue: ed.attributeValue,
                basicAttributeId: ed.attributeId,
                basicAttributeNum: ed.attributeNum,
                channelNum: ed.channelNum,
                childRowNum: ed.childRowNum,
                columns: ed.columns,
                displaySequence: ed.displaySequence,
                editable: ed.editable,
                fieldName: ed.attributeName,
                groupId: ed.groupId,
                key: uuid(),
                originalSource: ed.originalSource,
                parentKey: g.key,
                rows: ed.rows,
                source: ed.source,
              };

              g.children.push(item);
              itemDict[ed.attributeNum] = item;

              if (g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE) {
                //cdvDict[ed.childRowNum] = ed.attributeValue;
                cdvDict[ed.childRowNum] = ed;
              }
            });
          }

          tds.push(g);

          if (g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE) {
            chdNum = g.rowNum;
          }
        });
        console.log('res', gs, tds, /*cdvDict*/);
        if (chdNum > 0) {
          await loadChannelData(chdNum, prd, cdvDict);
        }

        setChannelDataDict(cdvDict);
        setItemDict({...itemDict});
        setTowerData(tds);
      }
    } catch(e) {
      notification.error({
        message: `Fetched control tower item error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const filterChannelData = (cds: StringKAnyVPair[]) => {
    let grps: StringKAnyVPair = {};
    let list = cds;

    if (displayInvChannel) {
      const selChn = getSelectedChannel();

      //list = cds.filter(row => row.data.some((e: StringKAnyVPair) => e.channelNum in selChn.group));
      list = cds.filter(row => channelAccountList.indexOf(row.channelAccountNum) > -1);
      list.forEach(row => row[`data0`] = row.data[0].displayName);
      for (let k in selChn.group) {
        if (!grps[k]) grps[k] = { rowSpanChecked: false, channelAccounts: selChn.group[k] };
      }
      //console.log('s c n', selChn, list);
    } else {
      for (let k in channelFlagDict) {
        const chn = channelFlagDict[k];

        if (!grps[chn.ChannelNum]) grps[chn.ChannelNum] = { rowSpanChecked: false, channelAccounts: [] };

        grps[chn.ChannelNum].channelAccounts.push(chn.ChannelAccountNum);
      }
    }

    //console.log('grps', grps);
    list.forEach(row => {
      const grp = grps[row.data[0].channelNum];
      let rowSpan = 1;

      //console.log('grp', row.data[0].channelNum, grp);
      if (grp) {
        if (!grp.rowSpanChecked) {
          const vd = channelDataDict;

          rowSpan = grp.channelAccounts.length;
          grp.rowSpanChecked = true;

          for (let i = 1; i < row.data.length; i++) {
            const rn = row.data[i].childRowNum;

            if (rn > 0 && vd[rn]) {
              row[`data${i}`] = vd[rn].attributeValue;
            } else {
              row[`data${i}`] = '';
            }
          }
        }
      }

      row.rowSpan = rowSpan;
    });

    return list;
  };

  const filterGroupData = (groups: StringKAnyVPair[]) => {
    const gs: StringKAnyVPair[] = [];
    let chdGrp: StringKAnyVPair = {};

    groups.forEach(g => {
      if (g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE) {
        chdGrp = g;
      } else {
        if (Array.isArray(g.children)) {
          g.children = g.children.filter(c => {
            let ret = true;

            if (hideNoChannelField) {
              if (c.channelNum > 0) {
                ret = isChannelExist(c);
              }
              //if (c.channelNum === 0) console.log('chn', c.channelNum, c.attributeName);
            }

            return ret;
          });

          if (g.children.length > 0) {
            gs.push(g);
          }
        }
      }
    });

    if (Object.keys(chdGrp).length > 0) {
      gs.push(chdGrp);
    }

    return gs;
  };

  const getAttrByNum = (an: string) => {
    const item = itemDict[an];

    if (item) {
      const pn = item.attributeName;

      return attrDict[pn] || item;
    }
  };

  const getSelectedChannel = () => {
    const caDict: StringKAnyVPair = {};
    const grps: StringKAnyVPair = {};

    for (let k in channelFlagDict) {
      const chn = channelFlagDict[k];

      if (channelAccountList.indexOf(chn.ChannelAccountNum) > -1) {
        caDict[chn.ChannelAccountNum] = chn;

        if (!grps[chn.ChannelNum]) grps[chn.ChannelNum] = [];

        if (grps[chn.ChannelNum].indexOf(chn.ChannelAccountNum) < 0) grps[chn.ChannelNum].push(chn.ChannelAccountNum);
      }
    }

    return {
      dict: caDict,
      group: grps,
    };
  };

  const getTowerItemValue = (item: StringKAnyVPair) => {
    return editDict[item.attributeNum] === undefined ? item.attributeValue : editDict[item.attributeNum];
  };

  const isChannelExist = (item: StringKAnyVPair) => {
    const selChn = getSelectedChannel();

    return item.channelNum in selChn.group;
    //return !!selChn.group[item.channelNum];
  };

  const isSavable = () => {
    return editMode === 1 && Object.keys(editDict).length > 0;
  };

  const loadAttrData = async (
    productId: string,
    aDict: StringKAnyVPair,
  ) => {
    try {
      const res = await Products.getStyleMaster2(productId);

      if (res && typeof res === 'object') {
        const {
          channelControlFlagIdList,
          labelIdList,
          productBasic,
          productBasicInfoAttributeList,
          productOperation,
        } = res;
        let info: StringKAnyVPair = {};
        console.log('attr data ->', res);
        if (productBasic && typeof productBasic === 'object') {
          console.log('pdb->', productBasic);
          info = { ...productBasic };
        }

        if (Array.isArray(productBasicInfoAttributeList)) {
          productBasicInfoAttributeList.forEach(e => {
            const k = e.attributeName ? e.attributeName.trim() : '';

            if (k && aDict[k]) {
              aDict[k].value = e.value;
              //console.log(k, aDict[k]);
            }
          });
        }

        if (productOperation && typeof productOperation === 'object') {
          basicInfo.Operation = productOperation;
        }

        basicInfo.LabelList = Array.isArray(labelIdList) ? labelIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        basicInfo.FlagList = Array.isArray(channelControlFlagIdList) ? channelControlFlagIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        basicInfo.FlagList1 = Array.isArray(channelControlFlagIdList) ? channelControlFlagIdList : undefined;
        //console.log('-->', labelIdList, basicInfo);
        setBasicInfo({
          ...info,
          ...basicInfo,
        });
        console.log('info', info, basicInfo);
      }
    } catch(e) {
      notification.error({
        message: `Fetch product style master data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }
  };

  const loadBasicAttrs = async (prd = currentProduct) => {
    setIsLoading(true);

    const attrs = await loadProductBasicAttrs();

    //console.log('attrs ->', attrs);
    if (Array.isArray(attrs)) {
      const dict: StringKAnyVPair = {};

      //attrs.forEach(e => dict[e.basicAttributeId] = e);
      //attrs.forEach(e => dict[e.basicAttributeName] = e);
      attrs.forEach(e => {
        e.fieldName = e.fieldName.trim();
        dict[e.fieldName] = e;
      });

      if (currentProduct.ProductId) {
        await loadAttrData(getProductIdByPath(prd.ProductId), dict);
      }

      console.log('attr dict', dict);
      setAttrDict(dict);

      /*if (dict.ProductClassifications.value) {
        if (typeof dict.ProductClassifications.value === 'string') {
          setCurrentCategoryRoot(parseInt(dict.ProductClassifications.value));
        }
      }*/
    }

    setIsLoading(false);
  };

  const loadChannelAccounts = async () => {
    setIsLoading(true);

    try {
      const ccfs = await Products.getChannelControlFlags();

      //console.log('ccfs ---  --->', ccfs);
      if (Array.isArray(ccfs)) {
        const dict: StringKAnyVPair = {};
        const list: number[] = [];
        const canList: number[] = [];

        ccfs.forEach((cf: StringKAnyVPair) => {
          if (cf.Active) {
            //dict[cf.ProductCHNLCtrlFlagId] = cf.ChannelNum;
            dict[cf.ProductCHNLCtrlFlagId] = cf;
            //list.push(cf.ChannelNum);
          }
        });

        if (Array.isArray(basicInfo.FlagList)) {
          basicInfo.FlagList.forEach((f: string) => {
            if (f in dict) {
              list.push(dict[f].ChannelNum);
              canList.push(dict[f].ChannelAccountNum);
            }
          });
        }
        console.log('----->', dict, canList, list);
        setChannelFlagDict(dict);
        setChannelAccountList(canList);
      }
    } catch(e) {
      notification.error({
        message: `Loaded channels information error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // This function does not need capture exception.
  // The exception will be catched outside of this function.
  const loadChannelData = async (
    chdNum: number,
    prd: StringKAnyVPair,
    valDict?: StringKAnyVPair,
  ) => {
    const res = await fetchControlTowerMappingBySubGroupNum(chdNum);

    console.log('ttt', res);
    if (res && typeof res === 'object') {
      const { datalist, headers } = res;
      const data: StringKAnyVPair[] = [];
      let cols: StringKAnyVPair[] = [];

      if (Array.isArray(datalist)) {
        const vd = valDict || channelDataDict;

        datalist.forEach((ds: StringKAnyVPair[]) => {
          const row: StringKAnyVPair = {};
          const rowNums: number[] = [];

          ds.filter(d => {
            const ret = rowNums.indexOf(d.rowNum) < 0;

            if (ret) rowNums.push(d.rowNum);

            return ret;
          }).forEach((d, i) => {
            if (i === 0) {
              row[`data${i}`] = d.displayName;
            } else {
              if (d.childRowNum > 0) {
                //row[`data${i}`] = vd[d.childRowNum];
                row[`data${i}`] = vd[d.childRowNum].attributeValue;
              } else {
                //row[`data${i}`] = d.displayName;
                row[`data${i}`] = '';
              }
            }
          });
          row.data = ds;
          row.uuid = `r${uuid()}`;
          data.push(row);
        });
      }

      if (Array.isArray(headers)) {
        cols = headers.map((h: StringKAnyVPair, i: number) => {
          return {
            defaultLocked: h.rowNum === 0,
            editable: editMode === 1 && h.rowNum > 0,
            name: `data${i}`,
            header: h.displayName,
            //defaultFlex: 1,
            //locked: true,
            minWidth: 160,
            render(row: any) {
              return renderMappingCell(row, i);
            },
            rowspan(row: any) {
              const { data } = row;
              //console.log('row span', data.rowSpan);
              return data && typeof data === 'object' && typeof data.rowSpan === 'number' ? data.rowSpan : 1;
            },
            showColumnMenuTool: false,
            sortable: i === 0,
            textAlign: 'center' as 'center',
            //rendersInlineEditor,
            //cellDOMProps,
          };
        });
        setDisplayGrid(true);
      }

      if (data.length > 0) {
        //const cds = await loadChannelControlFlags();
        const cds = await Products.getChannelControlFlags();
        //const prfd = await loadSalesPerformanceData(product);
        const prfd = await loadSalesPerformanceData(prd);
        //const cins = await Products.getChannelControlFlagInventory(getProductIdByPath(product.ProductId));
        const cins = await Products.getChannelControlFlagInventory(getProductIdByPath(prd.ProductId));
        const cdd: StringKAnyVPair = {};
        const cind: StringKAnyVPair = {};
        const chns: number[] = [];
        const caCol = {
          header: 'Channel Account',
          name: 'channelAccountName',
          minWidth: 160,
        };
        const invCol = {
          header: 'Inventory Flag',
          name: 'SendInventory',
          minWidth: 166,
          render(row: any) {
            const { data } = row;

            switch(data.SendInventory) {
              case 0:
                return 'None';
              case 1:
                return 'Feed Inventory by Rule';
              case 2:
                return  'Feed Inventory as Zero';
            }
          },
        };

        cds.forEach((e: StringKAnyVPair) => {
          if (e.Active) {
            if (!Array.isArray(cdd[e.ChannelNum])) {
              cdd[e.ChannelNum] = [] as StringKAnyVPair[];
            }

            if (chns.indexOf(e.ChannelNum) < 0) chns.push(e.ChannelNum);

            cdd[e.ChannelNum].push(e);
          }
          //console.log('ee', e.ChannelAccountNum, e.ChannelAccountName);
          //if (e.ChannelAccountNum === 10075) console.log('found', e);
        });
        cins.forEach((e: StringKAnyVPair) => {
          cind[e.ChannelAccountNum] = e;
        });

        for (let i = data.length - 1; i >= 0; i--) {
          const d = data[i];

          if (chns.indexOf(d.data[0].channelNum) < 0) {
            data.splice(i, 1);
          }
        }

        for (let i = data.length - 1; i >= 0; i--) {
          const d = data[i];
          const cd = cdd[d.data[0].channelNum];

          if (cd) {
            d.channelAccountName = cd[0].ChannelAccountName;
            d.channelAccountNum = cd[0].ChannelAccountNum;
            d.rowSpan = cd.length;

            for (let j = cd.length - 1; j > 0; j--) {
              const r = {
                channelAccountName: cd[j].ChannelAccountName,
                channelAccountNum: cd[j].ChannelAccountNum,
                data: JSON.parse(JSON.stringify(d.data)),
              };

              data.splice(i + 1, 0, r);
            }
          } else {
            //data.splice(i, 1);
          }
        }

        console.log('cins', cins, data, cdd);
        if (prfd.length > 0) {
          const headers = [
            /*{
              header: 'Calc Date',
              name: 'calDate',
              width: 110,
            },*/
            {
              header: 'Year To End',
              name: 'yearToEndQTY',
              width: 130,
            },
            {
              header: '30 Days',
              name: 'thirdtyDaysQTY',
              width: 100,
            },
            {
              header: '14 Days',
              name: 'fourteenDaysQTY',
              width: 100,
            },
            {
              header: '7 Days',
              name: 'sevenDaysQTY',
              width: 100,
            },
            {
              header: 'Yesterday',
              name: 'yesterdayQTY',
              width: 116,
            },
            invCol,
            caCol,
          ];
          const kds: StringKAnyVPair = {};

          prfd.forEach(e => {
            if (!Array.isArray(kds[e.channelNum])) {
              //kds[e.channelNum] = [] as StringKAnyVPair[];
              kds[e.channelAccountNum] = [] as StringKAnyVPair[];
            }

            //kds[e.channelNum].push(e);
            kds[e.channelAccountNum].push(e);
          });
          console.log('prfd', /*data,*/ prfd, kds);
          headers.forEach(h => cols.splice(1, 0, h));

          for (let i = data.length - 1; i >= 0; i--) {
            const rd = data[i];
            //const kd = kds[rd.data[0].channelNum];
            const kd = kds[rd.channelAccountNum];
            const cin = cind[rd.channelAccountNum];

            if (cin) {
              rd.SendInventory = cin.SendInventory;
            }

            if (kd) {
              //console.log(rd, '<---');
              rd.calDate = kd[0].calDate;
              rd.channelAccountName = kd[0].channelAccountName;
              rd.thirdtyDaysQTY = kd[0].thirdtyDaysQTY;
              rd.fourteenDaysQTY = kd[0].fourteenDaysQTY;
              rd.sevenDaysQTY = kd[0].sevenDaysQTY;
              rd.yearToEndQTY = kd[0].yearToEndQTY;
              rd.yesterdayQTY = kd[0].yesterdayQTY;
              //rd.rowSpan = kd.length;

              /*for (let j = kd.length - 1; j > 0; j--) {
                const r: StringKAnyVPair = kd[j];

                r.data = JSON.parse(JSON.stringify(rd.data));
                data.splice(i + 1, 0, r);
              }*/
            }
          }
        } else {
          const headers = [invCol, caCol];

          headers.forEach(h => cols.splice(1, 0, h));
          data.forEach(d => {
            if (cind[d.channelAccountNum]) {
              d.SendInventory = cind[d.channelAccountNum].SendInventory;
            }
          });
        }
      }

      setGridColumns(cols);
      setGridData(data);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async (prd = currentProduct) => {
    const sv = await loadStyleVariations();

    await loadHideFieldSetting();
    await loadBasicAttrs(prd);
    await loadChannelAccounts();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      //setStyleVariation(sv);
    }

    fetchControlTowerInfo(prd);
    //loadSalesPerformance(prd);
    //setBasicInfoReady(true);
  };

  // eslint-disable-next-line
  const loadProductData = async (prd: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      const pid = getProductIdByPath(prd.ProductId);
      const data = await fetchSimpleProductPartialData([pid]);

      if (Array.isArray(data) && data.length > 0) {
        const pd = {
          ...prd,
          CentralProductNum: data[0].centralProductNum,
        };

        setCurrentProduct(pd);
        loadInitialData(pd);
        /*closeProductDetailDialog();
        setTimeout(() => {
          //console.log('cpn', data[0].centralProductNum, pd);
          if (props.openProductDialog) {
            props.openProductDialog(pd);
          }
        }, 0);*/
      }
    } catch(e) {
      notification.error({
        message: `Loaded product error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadHideFieldSetting = async () => {
    setIsLoading(true);

    try {
      const hfCfg = await fetchControlTowerCfgHideField();

      //console.log('hf cfg', hfCfg);
      setHideNoChannelField(hfCfg > 0);
    } catch(e) {
      notification.error({
        message: `Fetch hide fields setting error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /*const loadSalesPerformance = async (prd: StringKAnyVPair) => {
    setIsLoading(true);
    //console.log('pid', prd);

    try {
      const res = await fetchSalesPerformance({
        ProductId: prd.ProductId,
        SKU: prd.SKU,
        ProductType: getProductTypeByName(prd.Type),
      });

      //console.log('..', moment().format('YYYY-MM-DD'), moment().format(), convertToUtcTime(moment().format(), '', '', 'YYYY-MM-DD'));
      //console.log('sfd', res);
      if (res && typeof res === 'object' && Array.isArray(res.summaryReportChannelAccountDetails)) {
        setPerfData(res.summaryReportChannelAccountDetails);
      }
    } catch(e) {
      setPerfData([]);
      notification.error({
        message: `Fetch sales performance error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };*/

  const loadSalesPerformanceData = async (prd: StringKAnyVPair) => {
    let ret: StringKAnyVPair[] = [];

    try {
      const res = await fetchSalesPerformance({
        ProductId: getProductIdByPath(prd.ProductId),
        SKU: prd.SKU,
        ProductType: 'ProductType' in prd ? prd.ProductType : getProductTypeByName(prd.Type),
      });

      //console.log('sfd', res);
      if (res && typeof res === 'object' && Array.isArray(res.summaryReportChannelAccountDetails)) {
        //setPerfData(res.summaryReportChannelAccountDetails);
        ret = res.summaryReportChannelAccountDetails;
      }
    } catch(e) {
      notification.error({
        message: `Fetch sales performance error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }

    return ret;
  };

  // eslint-disable-next-line
  const saveMappingCellData = async (
    attr: StringKAnyVPair,
    value: string,
  ) => {
    setIsLoading(true);

    try {
      const basic: StringKAnyVPair = {};
      const operation: StringKAnyVPair = {};
      const data: StringKAnyVPair = {
        ApplyToAllRelevantSubStyleAndSKU: overwriteChildren,
        //ApplyToAllRelevantSubStyleAndSKU: false,
        styleCode: basicInfo.sku,
        productBasic: basic,
        productOperation: operation,
      };
      const productAttrList: StringKAnyVPair[] = [];
      //const skd: StringKAnyVPair = {};
      console.log('-->', data);
      console.log('attr', attr);
      //if (attr.valueChanged) {
      if (attr && typeof attr === 'object') {
        if (attr.source === 'productAttribute') {
          productAttrList.push({
            attributeNum: attr.attributeNum,
            //productAttributeValue: editDict[k],
            productAttributeValue: value,
          });
        } else if (attr.basicAttributeNum > 999) {
          if (!Array.isArray(data.productBasicInfoAttributeList)) data.productBasicInfoAttributeList = [];

          data.productBasicInfoAttributeList.push({
            AttributeId: attr.basicAttributeId,
            //Value: attr.editValue,
            //Value: editDict[k],
            Value: value,
            editType: 1,
          });
        } else {
          if (attr.isOperation) {
            //operation[attr.fieldName] = attr.editValue;
            //operation[attr.fieldName] = editDict[k];
            operation[attr.fieldName] = value;
          } else {
            console.log('field', attr);
            //basic[attr.fieldName] = attr.editValue;
            //basic[attr.fieldName] = editDict[k];
            basic[attr.fieldName] = value;
          }
        }
        //skd[k] = attr;
      }
      //}
      console.log('pdata', data);
      if (data.productBasic) {
        if (Array.isArray(data.productBasic.LabelIdList)) {
          data.LabelIdList = data.productBasic.LabelIdList;
          data.productBasic.LabelIdList = undefined;
        }

        if (Array.isArray(data.productBasic.ChannelControlFlagIdList)) {
          data.ChannelControlFlagIdList = data.productBasic.ChannelControlFlagIdList;
          data.productBasic.ChannelControlFlagIdList = undefined;
        }
      }

      //if (props.productId) {
      if (product.ProductId) {
        console.log('prdList', product.ProductId, productAttrList);
        const ctRes = await editControlTowerValues(getProductIdByPath(product.ProductId), productAttrList, {isApplyChild: overwriteChildren ? 1 : 0});
        console.log('-->', ctRes);
        //await Products.editSimpleStyleMaster(props.productId, data);
        await Products.editSimpleStyleMaster(getProductIdByPath(product.ProductId), data);
        // set attr status at here
        /*for (let k in skd) {
          skd[k].valueChanged = false;
        }
        console.log('saved', skd);*/
        //setAttrDict({ ...attrDict });
        notification.info({
          message: `Saved ${attr.attributeName} successfully`,
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });
      }
    } catch(e) {
      notification.error({
        message: `Saved cell value error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const updateGridData = (
    row: StringKAnyVPair,
    attr: StringKAnyVPair,
    columnId: string,
    value: any,
  ) => {
    attr.attributeValue = value;
    row[columnId] = value;
    setChannelDataDict({...channelDataDict});
    setGridData([...gridData]);
  };

  const onChannelInventoryDisplayChange = (evt: any) => {
    setDisplayInvChannel(evt.target.checked);
  };

  // eslint-disable-next-line
  const onEditComplete = useCallback(async ({ value, columnId, rowId }) => {
    let rd: StringKAnyVPair = {};
    let attr: StringKAnyVPair = {};
    //console.log('eeee', rowId, columnId, value);
    for (let i = 0; i < gridData.length; i++) {
      const row = gridData[i];

      if (row.uuid === rowId) {
        const i = parseInt(columnId.replace('data', ''));

        //console.log('i--', i, typeof i);
        if (typeof i === 'number' && i > 0) {
          console.log('ddd', row.data[i], towerData);
          if (row.data[i].childRowNum > 0) {
            const vd = channelDataDict[row.data[i].childRowNum];

            if (vd) {
              console.log('vd', vd);
              rd = row;
              attr = vd;
            }
          }
          break;
        }
      }
    }

    if (rd.uuid && attr.childRowNum > 0) {
      rd[columnId] = value;
      setGridData([...gridData]);
      await saveMappingCellData(attr, value);
      updateGridData(rd, attr, columnId, value);
    }
  }, [channelDataDict, gridData, saveMappingCellData, towerData, updateGridData]);

  const onFullscreen = (full: boolean) => {
    const body = bodyRef.current;

    if (body) {
      const ctn = body.parentNode.parentNode;
      const height = window.innerHeight;

      //console.log('full', full, ctn, height);
      setTimeout(() => {
        if (full) {
          ctn.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 16}px`;
          ctn.style.height = `${height - 120}px`;
          body.style.height = '100%';
          setTimeout(() => {
            ctn.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
          }, 100);
        } else {
          ctn.style.height = '';
          body.style.height = '';
        }
      }, 0);
    }
  };

  // eslint-disable-next-line
  const onSearchAttrKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (/*sk &&*/ typeof sk === 'string' && bodyRef.current) {
      const sections = bodyRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      sk = sk.toLowerCase().trim();
      //setIsSearchEmpty(sk === '');

      for (let i = 0; i < sections.length; i++) {
        const sec = sections[i];
        const items = sec.querySelectorAll(`.${FORM_ITEM_CLS}`);
        let showCount = 0;

        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          const labelDom = item.querySelector(`.${FORM_LABEL_CLS}`);

          if (labelDom) {
            const label = labelDom.textContent.trim();

            if (label && typeof label === 'string') {
              const match = label.toLowerCase().indexOf(sk) > -1;

              showCount += (match ? 1 : 0);
              item.style.display = match ? '' : 'none';
            }
          }
        }

        sec.style.display = showCount > 0 ? '' : 'none';
        /*if (i > 0 && items.length > 0) {
          sec.style.display = showCount > 0 ? '' : 'none';
        }*/
      }
    }
  };

  const onSelectChannels = (chns: any[]) => {
    /*const list: number[] = [];

    chns.forEach((f: string) => {
      if (f in channelFlagDict) list.push(channelFlagDict[f].ChannelNum);
    });

    console.log('sel', chns, list, channelDataDict);*/
    refreshContent();
  };

  const onTowerItemValueChange = (
    evt: any,
    item: StringKAnyVPair,
  ) => {
    const pn = item.attributeNum;
    //console.log('item', item, evt);
    editDict[pn] = evt.target.value;
    setEditDict({...editDict});
    //attrDict[item.attributeNum] = evt.target.value;
    //setAttrDict({...attrDict});
  };

  const openChannelDataCellEditor = (idx: number) => {
    setCurrentChannelDataCellIndex(idx);

    if (idx > -1) setChannelDataEditorVisible(true);
  };

  const refreshContent = () => {
    setLastProductId('');
  };

  const renderChannelData = (style: StringKAnyVPair = {}) => {
    return displayGrid ? (
      <div style={{width: '100%', height: style.height || '100%'}}>
        <DataGrid
          //columns={columns}
          columns={gridColumns}
          //dataSource={gridData}
          dataSource={filterChannelData(gridData)}
          //editable={editMode === 1}
          idProperty="uuid"
          //onEditComplete={onEditComplete}
          onReady={setGridRef}
          pagination={false}
          showCellBorders={true}
          style={{height: '100%'}}
        />
      </div>) : null;
  };

  const renderDialogFooter = () => {
    return (<>
      <Row justify="space-between">
        {editMode === 0 && (
          <InfoButton onClick={toggleEditMode}>
            <EditOutlined />
            Edit
          </InfoButton>
        )}
        {editMode !== 0 && (
          <Button onClick={toggleEditMode}>
            <LockOutlined />
            Readonly
          </Button>
        )}
        <Space>
          <Button onClick={closeProductDetailDialog}>
            <CloseOutlined />
            Close
          </Button>
          <Button
            disabled={!isSavable()}
            onClick={saveFormData}
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Space>
      </Row>
    </>);
  };

  const renderDialogToolbarRightCrumb = () => {
    return (<Space>
      <OverwriteSwitch
        overwrite={overwriteChildren}
        setOverwrite={setOverwriteChildren}
      />
    </Space>);
  };

  const renderGroupItems = (grp: StringKAnyVPair) => {
    const items: StringKAnyVPair[] = grp.children;

    return items.map(e => {
      const cw = Math.floor(100 / grp.columns);
      //const fw = cw < 100 ? `calc(${cw}% - 16px)` : 'calc(100% - 16px)';
      const fs = {
        display: grp.expand ? '' : 'none',
        width: `calc(${cw}% - ${TOWER_ITEM_MARGIN * grp.columns}px)`,
        margin: TOWER_ITEM_MARGIN,
      };

      return (
        <Col
          //align="middle"
          className="form-item"
          //className={getTowerItemClass(e)}
          key={e.key}
          //onClick={evt => onClickTowerItem(evt, e)}
          style={fs}
        >
          <div style={{width: '100%'}}>
            <label className="form-label">{e.attributeName}</label>
          </div>
          <Input.TextArea
            disabled={editMode === 0 || !e.editable}
            onChange={evt => onTowerItemValueChange(evt, e)}
            rows={e.rows}
            style={{width: `${getTowerItemWidth(e)}%`}}
            value={getTowerItemValue(e)}
          />
        </Col>
      );
    });
  };

  const renderGroups = (gs: StringKAnyVPair[]) => {
    return gs.map(g => {
      return Array.isArray(g.children) && g.children.length > 0 ? (
        <Row
          className="form-section"
          key={g.key}
        >
          <div className="section-title-bar space-between-bar">
            <span>
              <span onClick={() => toggleGroupExpandState(g)} className="grp-left-icon">
                {g.expand ? <UpOutlined /> : <RightOutlined />}
              </span>
              <span className="section-title">{g.title}</span>
              {g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE && !isChannelDataFullscreen && (
                <Checkbox
                  className="chn-display-switcher"
                  defaultChecked={displayInvChannel}
                  onChange={onChannelInventoryDisplayChange}
                >
                  Display listed channel only
                </Checkbox>
              )}
            </span>
            {g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE && !isChannelDataFullscreen && (
            <Tooltip title="Enter fullscreen">
              <Button
                className="section-bar-btn"
                onClick={() => setIsChannelDataFullscreen(true)}
              >
                <Fullscreen />
              </Button>
            </Tooltip>
            )}
          </div>
          <div
            className="section-title-line"
            style={{display: g.expand ? '' : 'none'}}
          >
            <hr />
          </div>
          {/*Array.isArray(g.children) && renderGroupItems(g)*/}
          {g.groupType === CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE ? (g.expand ? renderChannelData({height: 300}) : null) : renderGroupItems(g)}
        </Row>
      ) : null;
    });
  };

  const renderMappingCell = (
    row: StringKAnyVPair,
    idx: number,
  ) => {
    const { cellProps, data } = row;
    const Text = Typography.Text;
    const pd = data.data[idx];
    const text = data[`data${idx}`];

    //console.log('rddd', row, row.rowIndex, idx);
    return idx === 0 ? text : (
      <Row align="middle" justify="space-between">
        <Text className="mapping-cell-label" ellipsis={{tooltip: text}}>
          {text}
        </Text>
        {cellProps.editable && pd.childRowNum > 0 && (
          <span className="cell-right-icon" onClick={() => openChannelDataCellEditor(pd.childRowNum)}>
            <EditOutlined />
          </span>
        )}
      </Row>
    );
  };

  /*const renderSalesPerformance = () => {
    return (
      <div
        className="form-section"
       >
        <div className="section-title-bar">
          <span onClick={() => toggleSalesPerformanceExpandState()} className="grp-left-icon">
          {spExpand ? <UpOutlined /> : <RightOutlined />}
          </span>
          <label className="section-title">Sales Performance</label>
        </div>
        <div
          className="section-title-line"
          style={{display: spExpand ? '' : 'none'}}
        >
          <hr />
        </div>
        <div
          style={{ padding: 6, display: spExpand ? '' : 'none' }}
        >
          <DataGrid
            columns={perfColumns}
            dataSource={perfData}
            //editable={editMode === 1}
            //idProperty="uuid"
            //onEditComplete={onEditComplete}
            //onReady={setGridRef}
            pagination={false}
            style={{height: '100%'}}
          />
        </div>
      </div>
    );
  };*/

  const resetDataStateToInitial = () => {
    setAttrDict({});
    setBasicInfo({});
    setChannelAccountList([]);
    setChannelDataDict({});
    setChannelFlagDict({});
    setDisplayGrid(false);
    setGridColumns([]);
    setGridData([]);
    setItemDict({});
    setTowerData([]);
  };

  const saveChannelDataCell = async (val: string) => {
    if (channelDataDict[currentChannelDataCellIndex]) {
      const vd = channelDataDict[currentChannelDataCellIndex];

      vd.attributeValue = val;
      setGridData([...gridData]);
      await saveMappingCellData(vd, val);
      //updateGridData(rd, attr, columnId, value);
      //setCurrentChannelDataCellIndex(-1);
    }
  };

  const saveFormData = async () => {
    setIsLoading(true);

    try {
      const basic: StringKAnyVPair = {};
      const operation: StringKAnyVPair = {};
      const data: StringKAnyVPair = {
        ApplyToAllRelevantSubStyleAndSKU: overwriteChildren,
        //ApplyToAllRelevantSubStyleAndSKU: false,
        styleCode: basicInfo.sku,
        productBasic: basic,
        productOperation: operation,
      };
      const productAttrList: StringKAnyVPair[] = [];
      const skd: StringKAnyVPair = {};
      console.log('-->', data);
      //for (let k in attrDict) {
      for (let k in editDict) {
        //const attr = attrDict[k];
        const attr = getAttrByNum(k);

        console.log('attr', k, attr);
        //if (attr.valueChanged) {
        if (attr && typeof attr === 'object') {
          if (attr.source === 'productAttribute') {
            productAttrList.push({
              attributeNum: attr.attributeNum,
              productAttributeValue: editDict[k],
            });
          } else if (attr.basicAttributeNum > 999) {
            if (!Array.isArray(data.productBasicInfoAttributeList)) data.productBasicInfoAttributeList = [];

            data.productBasicInfoAttributeList.push({
              AttributeId: attr.basicAttributeId,
              //Value: attr.editValue,
              Value: editDict[k],
              editType: 1,
            });
          } else {
            if (attr.isOperation) {
              //operation[attr.fieldName] = attr.editValue;
              operation[attr.fieldName] = editDict[k];
            } else {
              console.log('field', k, attr);
              //basic[attr.fieldName] = attr.editValue;
              basic[attr.fieldName] = editDict[k];
            }
          }

          skd[k] = attr;
        }
      }
      console.log('pdata', data);
      if (data.productBasic) {
        if (Array.isArray(data.productBasic.LabelIdList)) {
          data.LabelIdList = data.productBasic.LabelIdList;
          data.productBasic.LabelIdList = undefined;
        }

        if (Array.isArray(data.productBasic.ChannelControlFlagIdList)) {
          data.ChannelControlFlagIdList = data.productBasic.ChannelControlFlagIdList;
          data.productBasic.ChannelControlFlagIdList = undefined;
        }
      }

      //if (props.productId) {
      if (product.ProductId) {
        console.log('prdList', product.ProductId, productAttrList);
        const ctRes = await editControlTowerValues(product.ProductId, productAttrList, {isApplyChild: overwriteChildren ? 1 : 0});
        console.log('-->', ctRes);
        //await Products.editSimpleStyleMaster(props.productId, data);
        await Products.editSimpleStyleMaster(product.ProductId, data);
        // set attr status at here
        for (let k in skd) {
          skd[k].valueChanged = false;
        }
        console.log('saved', skd);
        //setAttrDict({ ...attrDict });
        notification.info({
          message: 'Saved successfully',
          duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
        });

        if (typeof props.onSave === 'function') {
          //console.log('p', product, editDict);
          props.onSave();
        }

        closeProductDetailDialog();
        setEditDict({});
      }
    } catch(e) {
      notification.error({
        message: `Saved basic info error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const toggleAllGroupExpandState = () => {
    const expand = allGroupsExpanded ? false : true;

    towerData.forEach(g => {
      g.expand = expand;
    });
    setScExpand(expand); 
    setSpExpand(expand);
    setTowerData([...towerData]);
  };

  const toggleEditMode = () => {
    gridColumns.forEach(g => {
      if (g.name !== 'data0') {
        if (editMode === 0) {
          g.editable = true;
          g.cellDOMProps = cellDOMProps;
        } else {
          g.editable = false;
          g.cellDOMProps = undefined;
        }
      }
    });
    setGridColumns([...gridColumns]);
    setEditMode(editMode === 0 ? 1 : 0);
  };

  const toggleGroupExpandState = (grp: StringKAnyVPair) => {
    grp.expand = !grp.expand;
    setTowerData([...towerData]);
  };

  const toggleSalesChannelExpandState = () => {
    setScExpand(!scExpand);
  };

  /*const toggleSalesPerformanceExpandState = () => {
    setSpExpand(!spExpand);
  };*/

  React.useEffect(() => {
    if (!inited) {
      console.log('prd->', product);
      loadInitialData();
      setInited(true);
    }

    if (lastProductId !== getProductIdByPath(currentProductId)) {
      //console.log('ccc', currentProductId, currentProduct);
      resetDataStateToInitial();
      setLastProductId(getProductIdByPath(currentProductId));
      loadProductData(currentProduct);
    }
  }, [
    currentProduct,
    currentProductId,
    inited,
    lastProductId,
    loadInitialData,
    loadProductData,
    product,
  ]);

  return (<>
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton
      footer={renderDialogFooter()}
      fullscreen
      maskClosable={false}
      onClose={closeProductDetailDialog}
      onFullscreen={onFullscreen}
      //title={props.product.SKU}
      title={
        <ProductDetailTitle 
          product={props.product}
          setDetailDialogTitleType={setDetailDialogTitleType}
          currentProduct={currentProduct}
          setCurrentProduct={setCurrentProduct}
          currentProductId={currentProductId}
          setCurrentProductId={setCurrentProductId}
          detailDialogTitleType={detailDialogTitleType}
          isSwitching={isSwitching}
          setIsSwitching={setIsSwitching}
          innerRef={titleRef}
        />
      }
      titleRightCrumb={<>
        <Button
          onClick={refreshContent}
        >
          <RedoOutlined style={{transform: 'rotate(270deg)'}} />
          Refresh
        </Button>
      </>}
      visible={props.visible}
      width={'80%'}
    >
      <TowerDialogBody ref={bodyRef}>
        <Row className="top-toolbar" justify="space-between">
          <Space>
            {/*<Button onClick={toggleAllGroupExpandState}>
              {allGroupsExpanded ? <>
                  <UpOutlined className="grp-left-icon" />
                  Collapse All
                </> : (<>
                  <RightOutlined className="grp-left-icon" />
                  Expand All
                </>
              )}
            </Button>
            <Input.Search
              allowClear
              className="search-box"
              onKeyUp={onSearchAttrKeyUp}
              onSearch={onSearchAttributes}
              ref={attrSearchInputRef}
            />*/}
            <span></span>
          </Space>
          {renderDialogToolbarRightCrumb()}
        </Row>
        {renderGroups(filterGroupData(towerData))}
        {Object.keys(basicInfo).length > 0 && (
          <div
            className="form-section"
          >
            <div className="section-title-bar">
              <span onClick={() => toggleSalesChannelExpandState()} className="grp-left-icon">
                {scExpand ? <UpOutlined /> : <RightOutlined />}
              </span>
              <label className="section-title">Sales Channel</label>
            </div>
            <div
              className="section-title-line"
              style={{display: scExpand ? '' : 'none'}}
            >
              <hr />
            </div>
            <div
              style={{ padding: 6, display: scExpand ? '' : 'none' }}
            >
              <SalesChannelEditor
                editOnly={!!editMode}
                mode="multiple"
                name="Channel Control Flags"
                onSelect={onSelectChannels}
                productId={product.ProductId}
                //state={props.state}
                styleCode={basicInfo.sku}
                type="channel"
                value={basicInfo.FlagList}
                sourceValue={basicInfo.FlagList1}
              />
            </div>
          </div>
        )}
        {/*Object.keys(basicInfo).length > 0 && renderSalesPerformance()*/}
      </TowerDialogBody>
    </ModalDialog>
    {isChannelDataFullscreen && (
      <FullScreenSection>
        <Row
          className="form-section"
          style={{height: '100%'}}
        >
          <TowerDialogBody className="fullscreen-section">
          <div className="section-title-bar space-between-bar">
            <span>
              <span className="section-title">Channel Data</span>
              <Checkbox
                className="chn-display-switcher"
                defaultChecked={displayInvChannel}
                onChange={onChannelInventoryDisplayChange}
              >
                Display listed channel only
              </Checkbox>
            </span>
            <Tooltip placement="left" title="Exit fullscreen">
              <Button
                className="section-bar-btn"
                onClick={() => setIsChannelDataFullscreen(false)}
              >
                <FullscreenExit />
              </Button>
            </Tooltip>
          </div>
          <div
            className="section-title-line"
          >
            <hr />
          </div>
          <div className="datagrid-section1">
            {renderChannelData()}
          </div>
          </TowerDialogBody>
        </Row>
      </FullScreenSection>
    )}
    {channelDataEditorVisible && (
      <ChannelDataEditor
        dataIndex={currentChannelDataCellIndex}
        onClose={closeChannelDataCellEditor}
        onSave={saveChannelDataCell}
        //row={currentChannelDataRow}
        dataDict={channelDataDict}
        visible={channelDataEditorVisible}
      />
    )}
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default ProductDetailDialog;
