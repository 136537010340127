import React, { useContext, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { Actions, Dispatcher, ProductDetailScreenContext, ProductDetailState, setExistingInventory } from '../context';
import { ProductTabContainer } from '../styledComponents';
// eslint-disable-next-line
import { distributionCenterTypes } from '../../../constants/distributionCenter';
import FormItemEnabler from '../../ProductBulkUpdate/FormItemEnabler';
// eslint-disable-next-line
import TableComponent from '../../../components/TableComponent';
import FormElement, { Type } from '../../../components/common/FormElement';
// import LoadingIcon from '../../../components/common/LoadingIcon';
//import { DataGrid } from '../../../components/common/datagrid/DataGrid';
// import message from '../../../components/common/message';
// import Products from '../../../services/products';


interface ProductInventoryTabContentProps {
  tableData: Array<any>;
  editMode: boolean;
  enabler: boolean;
}

// eslint-disable-next-line
const QuantityCell = ({ editMode, index, enabler }: any) => {
  const [state] = useContext(ProductDetailScreenContext);
  const QuantityInput = enabler ? FormItemEnabler : FormElement;

  if (!state) {
    return null;
  }

  return (
    <QuantityInput
      formItemProperties={{
        name: ['DcList', index, 'AvailableQuantity'],
      }}
      inputProperties={{
        disabled: !editMode,
        min: 0,
      }}
      inputType={Type.NUMBER}
    />
  );
};

// eslint-disable-next-line
const DistributionCenterCell: React.FC<{ record: any, index: number }> = ({ index, record }) => (
  <>
    {record.DistributionCenterName}
    <Form.Item
      name={['DcList', index, 'DistributionCenterName']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['Dclist', index, 'DistributionCenterCode']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['DcList', index, 'DistributionCenterType']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={['DcList', index, 'DistributionCenterId']}
      noStyle
    >
      <Input type="hidden" />
    </Form.Item>
  </>
);

export const InventoryFormContent: React.FC<{ tableData: Array<any>, editMode: boolean, enabler: boolean }> = ({ tableData, editMode, enabler }) => {
  const columns = [
    {
      title: 'Distribution Center',
      dataIndex: 'DistributionCenterName',
      key: 'DistributionCenterName',
      width: '350px',
      render: function CellWrapper(_: any, record: any, index: number) {
        return <DistributionCenterCell index={index} record={record} />;
      },
    },
    {
      title: 'Code',
      dataIndex: 'DistributionCenterCode',
      key: 'DistributionCenterCode',
      width: '200px',
    },
    {
      title: 'Type',
      dataIndex: 'DistributionCenterType',
      key: 'DistributionCenterType',
      width: '200px',
      render: (value: any) => distributionCenterTypes.find((dc: any) => dc.key === value)?.title,
    },
    {
      title: 'Quantity',
      dataIndex: 'AvailableQuantity',
      key: 'AvailableQuantity',
      type: 'number',
      editable: true,
      width: '250px',
      render: function CellWrapper(_: any, record: any, index: number) {
        return <QuantityCell index={index} editMode={editMode} enabler={enabler}  />;
      },
    },
    {
      title: '',
      dataIndex: 'DistributionCenterId',
      key: 'DistributionCenterId',
      className: 'hide',
    },
  ];
  return (
    <TableComponent
      className="inventoryTable"
      pagination={false}
      loadingStatus={false}
      dataSource={tableData}
      columns={columns}
    />
  );
  /*const columns = [
    {
      header: 'Distribution Center',
      name: 'DistributionCenterName',
      width: 350,
    },
    {
      header: 'Code',
      name: 'DistributionCenterCode',
      width: 200,
    },
    {
      header: 'Type',
      name: 'DistributionCenterType',
      width: 200,
    },
    {
      header: 'Quantity',
      name: 'AvailableQuantity',
      width: 250,
    },
    {
      defaultVisible: false,
      header: 'Id',
      name: 'DistributionCenterId',
    },
  ];

  return (
    <div style={{height: 'calc(100vh - 268px)'}}>
      <DataGrid
        idProperty="DistributionCenterId"
        rowHeight={35}
        columns={columns}
        dataSource={tableData}
        pagination
        style={{ height: '100%' }}
      />
    </div>
  );*/
};

const ProductInventoryTabContent: React.FC<ProductInventoryTabContentProps> = ({ tableData, editMode, enabler }) => {
  const [data, setData] = useState<any[]>([]);

  React.useEffect(() => {
    setTimeout(() => {
      setData(tableData);
    }, 0);
  }, [tableData]);

  return (<>
    {data.length > 0 && (
      <ProductTabContainer>
        <Row>
          <Col span={24} style={{ paddingLeft: '15px' }}>
            <InventoryFormContent tableData={data} editMode={editMode} enabler={enabler} />
          </Col>
        </Row>
      </ProductTabContainer>
    )}
  </>);
};

type ProductInventoryTabProps = {
  focused: boolean;
  dispatch: Dispatcher;
  state: ProductDetailState;
  
};

const ProductInventoryTab = (props: ProductInventoryTabProps) => {
  const {
    state,
    dispatch,
  } = props;
  const [inited, setInited] = useState(false);
  // const [isLoadingInventory, setIsLoadingInventory] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  //const [tableData, setTableData] = useState<any[]>([]);

  // eslint-disable-next-line
  // const loadInitialData = async () => {
  //   loadInventoryData();
  // };

  // const loadInventoryData = async () => {
  //   setIsLoadingInventory(true);

  //   try {
  //     const dc = await Products.getDistributionCenters();
  //     const inventoryTable = dc.map((distC: any) => ({
  //       DistributionCenterName: distC.DistributionCenterName,
  //       DistributionCenterId: distC.DistributionCenterID,
  //       DistributionCenterCode: distC.DistributionCenterCode,
  //       DistributionCenterType: distC.DistributionCenterType,
  //       AvailableQuantity: 0,
  //     }));

  //     // dispatch({ type: Actions.SET_DC, params: dc });
  //     // dispatch({ type: Actions.SET_INVENTORY, params: inventoryTable });
  //     //setTableData(inventoryTable);
  //     console.log(inventoryTable);
  //   } catch(e) {
  //     message.error(`Fetch inventory error: ${e}`);
  //     console.log('Fetch inventory error:', e);
  //   } finally {
  //     setIsLoadingInventory(false);
  //   }
  // };

  React.useEffect(() => {
    if (props.focused && dispatch && state && !inited) {
      if (state.inventoryTable.length > 0) {
      //loadInitialData();
        setIsRendered(true);
        setInited(true);
      } else {
        const inventoryForm = setExistingInventory(state.product?.DcList, state.inventory) || [];
        // console.log('inf->', inventoryForm);
        dispatch({ type: Actions.SET_INVENTORYTABLE, params: inventoryForm });
      }
    }
    /*if (state) {
      console.log('st', state.inventoryTable.length);
      if (state.inventoryTable.length === 0) {
        console.log(state, state.inventory);
      }
    }*/
  }, [dispatch, inited, props, state]);

  if (!state) {
    return null;
  }

  return (<>
    {/* {isLoadingInventory && (
        <Row justify="center">
          <LoadingIcon />
        </Row>
    )} */}
    {isRendered && state && (
      <ProductInventoryTabContent
        //tableData={tableData}
        tableData={state.inventoryTable}
        editMode={state.editMode}
        enabler={false}
      />
    )}
  </>);
};

export default React.memo(ProductInventoryTab);
