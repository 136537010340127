import { PlusOutlined } from '@ant-design/icons';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Button, Col, Modal, notification, Row, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalForm from '../../components/Classifications/ModalForm';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import { DataGrid } from '../../components/common/datagrid/DataGrid';
import Heading from '../../components/common/Heading';
import SearchBar from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import notificationActions from '../../redux/actions/notifications';
import { deleteClassification, fetchClassifications } from '../../services/products';
import { Actions, Dispatcher, initialState, PageStateContext, reducer, saveClassification } from './context';

const fetchData = (dispatch: Dispatcher) => {
  dispatch({ type: Actions.SET_LOADING, params: true });

  fetchClassifications()
    .then((res: Entities.ProductClassification[]) => {
      dispatch({ type: Actions.SET_DATA, params: res });
    })
    .finally(() => {
      dispatch({ type: Actions.SET_LOADING, params: false });
    });
};

const columns: TypeColumn[] = [
  {
    name: 'ClassificationName',
    header: 'Name',
    sortable: true,
    defaultFlex: 2,
    editable: true,
  },
  {
    name: 'ClassificationDesc',
    header: 'Description',
    sortable: true,
    defaultFlex: 4,
    editable: true,
  },
  {
    name: 'ProductCount',
    header: 'Product Count',
    sortable: true,
    defaultFlex: 2,
    editable: false,
  },
];


const searchFields = [
  'ClassificationName', 'ClassificationDesc', 'ClassificationId',
];

const csvColumns: CSVColumns = columns.map(c => ({
  key: c.name as string,
  header: c.header as string,
}));

interface PageContentProps {
  loading: boolean;
  data: Entities.ProductClassification[];
}


const PageContentComponent: React.FC<PageContentProps> = ({ loading, data }) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useContext(PageStateContext);
  const [filteredData, setFilteredData] = useState<Entities.ProductClassification[]>([]);

  const actionsColumn = {
    name: 'ClassificationId',
    header: '',
    defaultFlex: 3,
  };

  const onSave = useCallback((value: Entities.ProductClassification, rowIndex: number) => {
    if (!dispatch) {
      return;
    }

    const actions = bindActionCreators(notificationActions, reduxDispatch);

    saveClassification(dispatch, value, rowIndex)
      .then(() => actions.setNotification('success', 'Classification saved'))
      .catch(() => actions.setNotification('error', 'There was an error'))
      .finally(() => dispatch?.({ type: Actions.SET_SEARCH_DISABLED, params: false }));
  }, [dispatch, reduxDispatch]);

  const onDelete = useCallback((entity) => {
    dispatch?.({ type: Actions.SET_ENTITY_TO_DELETE, params: entity });
  }, [dispatch]);

  return (
    <Spin spinning={state?.isSaving} wrapperClassName="ant-spin-flex">
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <SearchBar
            reference="ClassificationId"
            data={data}
            onResult={setFilteredData}
            fields={searchFields}
            disabled={state?.searchDisabled}
          />
        </Col>
        <Col>
          <CSVLink
            filename="classifications.csv"
            data={filteredData}
            disabled={state?.searchDisabled}
            columns={csvColumns}
          />
        </Col>
      </Row>
      <Spacer height={14} />
      <DataGrid<Entities.ProductClassification>
        idProperty="ClassificationId"
        rowHeight={35}
        columns={columns}
        actionsColumn={actionsColumn}
        dataSource={filteredData}
        loading={loading}
        onSave={onSave}
        onDelete={onDelete}
        onEdit={(row) => {
          dispatch?.({ type: Actions.EDIT_ENTITY, params: row.ClassificationId });
        }}
        inlineEdit={false}
        pagination
        editable
      />
    </Spin>
  );
};

interface AddEntityModalProps {
  visible: boolean;
  dispatch: Dispatcher;
  entityId: string | null;
}

const AddEntityModal: React.FC<AddEntityModalProps> = ({ visible, dispatch, entityId }) => {
  const onModalClose = useCallback(() => {
    dispatch?.({
      type: Actions.SET_EDIT_MODAL_VISIBLE,
      params: false,
    });
    dispatch?.({
      type: Actions.SET_EDITING_ENTITY_ID,
      params: null,
    });
  }, [dispatch]);

  const onOk = useCallback(() => {
    if (!dispatch) {
      return;
    }

    dispatch({ type: Actions.EDIT_FORM_SUBMITTED });

    if (entityId) {
      notification.success({ message: 'Classification updated successfully' });
    } else {
      notification.success({ message: 'Classification created successfully' });
    }

    fetchData(dispatch);
  }, [dispatch, entityId]);

  return (
    <ModalForm
      classificationId={entityId}
      visible={visible}
      onOk={onOk}
      onError={() => {
      }}
      onModalClose={onModalClose}
    />
  );
};

const AddEntityModalMemo = React.memo(AddEntityModal);

const DeleteEntityModal: React.FC = () => {
  const reduxDispatch = useDispatch();
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, dispatch] = useContext(PageStateContext);
  const visible = state && !!state.entityToDelete;
  const title = `Are you sure you want to delete ${state?.entityToDelete?.ClassificationName || ''}?`;

  const onModalClose = useCallback(() => dispatch && dispatch({
    type: Actions.SET_ENTITY_TO_DELETE,
    params: null,
  }), [dispatch]);

  const onOk = useCallback(() => {
    if (!dispatch || !state?.entityToDelete) return;

    const actions = bindActionCreators(notificationActions, reduxDispatch);
    setProcessing(true);

    deleteClassification(state?.entityToDelete?.ClassificationId)
      .then(() => {
        actions.setNotification('success', 'Classification deleted successfully');
        dispatch({ type: Actions.SET_ENTITY_TO_DELETE, params: null });
        fetchData(dispatch);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, reduxDispatch, state?.entityToDelete]);

  return (
    <Modal
      visible={visible}
      title={title}
      okButtonProps={{ danger: true, disabled: processing }}
      cancelButtonProps={{ disabled: processing }}
      closable={!processing}
      onOk={onOk}
      onCancel={onModalClose}
    >
      <Spin spinning={processing}>
        This action cannot be undone and you will be unable to recover any data.
      </Spin>
    </Modal>
  );
};


const PageContentMemo = React.memo(PageContentComponent);


const PageContent: React.FC = () => {
  const [state, dispatch] = useContext(PageStateContext);
  if (!state || !dispatch) {
    return null;
  }

  return <PageContentMemo loading={state.loading} data={state.data} />;
};


const Page: React.FC = () => {
  const [state, dispatch] = useContext(PageStateContext);

  useEffect(() => {
    if (!dispatch) {
      return;
    }

    fetchData(dispatch);
  }, [dispatch]);

  if (!state || !dispatch) {
    return null;
  }

  return (
    <>
      <ContentLayout>
        <Heading
          title="Classifications"
          actions={(
            <Button type="primary" onClick={() => dispatch?.({ type: Actions.SET_EDIT_MODAL_VISIBLE, params: true })}>
              Add Classification
              <PlusOutlined />
            </Button>
          )}
        />
        <Spacer />
        <SiteContent flexGrow>
          <PageContent />
        </SiteContent>
        {/* Add/Edit Modal */}
        <AddEntityModalMemo
          visible={state.editModalVisible}
          dispatch={dispatch}
          entityId={state.editingEntityId}
        />
        {/* Delete modal */}
        <DeleteEntityModal />
      </ContentLayout>
    </>
  );
};


const ClassificationsPage: React.FC = () => {
  const params = useReducer(reducer, initialState());

  return (
    <PageStateContext.Provider value={params}>
      <Page />
    </PageStateContext.Provider>
  );
};

export default ClassificationsPage;
