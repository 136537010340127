import React from 'react';
import { Input, Modal, notification } from 'antd';
import { DEFAULT_SUCCESS_MSG_DISPLAY_DURATION } from '../../../constants/config';

type Props = {
  onClose: Function;
  onEditName: Function;
  visible: boolean;
};

const EditChannelDataFieldName = (props: Props) => {
  const { useState } = React;
  //const [fieldDesc, setFieldDesc] = useState('');
  const [fieldName, setFieldName] = useState('');

  const onCancel = () => {
    props.onClose();
  };

  const onEditName = () => {
    const name = fieldName.trim();

    if (name) {
      props.onEditName(name);
      onCancel();
    } else {
      notification.warning({
        message: 'Please input the field name',
        duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
      });
    }
  };

  const onFieldNameChange = (evt: any) => {
    setFieldName(evt.target.value);
  };

  return (<>
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      onCancel={onCancel}
      onOk={onEditName}
      title="Add Channel Data Field"
      visible={props.visible}
    >
      <div>
        <label>Field Name:</label>
        <Input
          onChange={onFieldNameChange}
        />
      </div>
    </Modal>
  </>);
};

export default EditChannelDataFieldName;
