import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import NoResultsMessage from '../Orders/NoResultsMessage';
import Loop from '../../assets/icons/loop';

const LoadingText = styled.p`
  font-family: sans-serif, Lato;
  font-size: 14px;
  font-weight: bold;
  color: #546279;
  margin-top: 5px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 98px;
`;

function TableComponent({ loadingStatus, ...props }: any) {
  const tableLoading = {
    spinning: loadingStatus,
    indicator: (
      <LoadingWrapper>
        <Loop
          height={40}
          width={40}
          style={{
            transform: 'rotate(45deg)',
            animation: 'antRotate 1.2s infinite reverse linear',
          }}
        />
        <LoadingText>Loading results</LoadingText>
      </LoadingWrapper>
    ),
  };
  return (
    <Table
      scroll={{ y: '100vh', x: '100%' }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      loading={tableLoading}
      locale={{
        emptyText: (<NoResultsMessage loading={loadingStatus} />),
      }}
    />
  );
}

export default TableComponent;
