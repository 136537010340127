import styled from 'styled-components';
import { Button, Input } from 'antd';

export const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 `;

export const ProductTabContainer = styled.div`
  width: 100%;
`;

export const ClearButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  float: right;
`;

export const EditControls = styled.div`
 width: 100%;
 display: flex;
 justify-content: flex-end;
 align-items: flex-end;
`;

export const TopHeaderButtonsWrapper = styled.div`
display: flex;
`;

export const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left;
`;

export const ProductSKU = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

export const ProductTitle = styled.p`
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: 900;
  color: #2d3f5d;
`;

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TitlePlaceholderWrapper = styled.div`
  font-size: 25px;
  white-space: nowrap;
`;

export const TitleInput = styled(Input)`
  font-size: 25px;
  width: 100%;
  padding: 0 !important;
`;

export const AccListWrapper = styled.div``;

export const MasterCodesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
`;

export const ListItem = styled.span`
	letter-spacing: 0.035rem;
`;

export const SortableListItem = styled.li`
	align-items: center;
	color: #2e2e2e;
	cursor: grab;
	display: flex;
	height: 2rem;
	justify-content: center;
	white-space: nowrap;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  padding: 6px;
  margin-right: 10px;
`;

export const SortableListContainer = styled.ul`
	display: flex;
  align-items: center;
  justify-content: center;
	height: 3rem;
	overflow-x: auto;
	padding: 0;
	user-select: none;
	width: 100%;
	-webkit-overflow-scrolling: touch;
`;

export const SKUFormatterTitle = styled.h3`
  margin-right: 40px;
  align-items: center;
  color: #546279;
  cursor: col-resize;
  display: flex;
  height: 2rem;
  justify-content: center;
  white-space: nowrap;
`;

export const StepsActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const StepsContent = styled.div`
  margin-top: 24px;
`;

export const StepContentWrapper = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? '' : 'none')};
`;

export const ExistentStyleMasterItemsWrapper = styled.div`
  width: 100%;
`;
