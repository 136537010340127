import { Col, Row, Spin, Button, Card, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
// import styled from 'styled-components';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import ProductContent from './product';
import { fetchChannels, listProfileChannelAccounts, getChannelControlFlag } from '../../services/channels';
import { useExecutePromise } from '../../util/hooks';
import theme from '../../assets/styles/theme';

const { Text } = Typography;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};


const ChannelIntegration: React.FC<{ accounts: ChannelAccount[]; handleClick?: (p: ChannelAccount) => void }> = ({ accounts, handleClick }) => {
  const channelName = useMemo(() => {
    const entry = accounts[0];

    if (entry.platform) {
      return entry.platform.platformName;
    }

    return entry.ChannelName;
  }, [accounts]);
  return (
    <Card style={{ height: '100%' }} headStyle={{ backgroundColor: '#f0f0f0' }} type="inner" title={<Text style={{ fontSize: 18 }} strong>{channelName}</Text>}>
      {accounts.map((p) => (
        <div key={p.ChannelAccountNum}>
          <Row justify="space-between">
            {/* <div>{p.ChannelAccountName}</div> */}
            <Button
              type="link"
              onClick={() => {
                handleClick && handleClick(p);
              }}
            >
              {p.ChannelAccountName}
            </Button>
            <div
              style={{
                color: theme['@danger-color'],
                fontWeight: 'bold',
              }}>
              {p.Active ? '' : '(Inactive)'}
            </div>
          </Row>
        </div>
      ))}
    </Card>
  );
};

type AccountMap = {
  /**
   * Retail accounts grouped by PlatformNum
   */
  retail: { [key: number]: ChannelAccount[] };
  /**
   * Non-retail accounts grouped by ChannelNum
   */
  nonRetail: { [key: number]: ChannelAccount[] };
};

const loadMappedAccounts = async () => {
  const [accounts, platforms] = await Promise.all([listProfileChannelAccounts(), fetchChannels()]);

  const platformsMap: { [key: number]: Entities.ChannelProfile } = {};
  const mappedAccounts: AccountMap = {
    retail: {},
    nonRetail: {},
  };

  // Generate channels map for easier access.
  platforms.forEach((c: any) => {
    platformsMap[c.channelNum] = c;
  });

  accounts.forEach((a: any) => {
    if (a.PlatformNum === 0) {
      if (!mappedAccounts.nonRetail[a.ChannelNum]) {
        mappedAccounts.nonRetail[a.ChannelNum] = [];
      }

      mappedAccounts.nonRetail[a.ChannelNum].push(a);
      return;
    }

    const entry = {
      ...a,
      platform: platformsMap[a.ChannelNum],
    };

    if (!mappedAccounts.retail[a.PlatformNum]) {
      mappedAccounts.retail[a.PlatformNum] = [];
    }

    mappedAccounts.retail[a.PlatformNum].push(entry);
  });

  // console.log(mappedAccounts)

  return mappedAccounts;
};

// TODO: streamline links here and sidebar
const Channel: React.FC = () => {
  const [data, loading] = useExecutePromise(loadMappedAccounts, { retail: [], nonRetail: [] });
  const [selectedChannel, setSelectedChannel] = useState<ChannelAccount>();
  const [loadingFlagNum, setLoadingFlagNum] = useState<boolean>(false);

  const getControlFlag = async (channelAccount: ChannelAccount) => {
    try {
      setLoadingFlagNum(true);
      const CHNLCtrlFlagNum = await getChannelControlFlag(channelAccount.ChannelAccountNum);
      setLoadingFlagNum(false);
      setSelectedChannel({
        ...channelAccount,
        CHNLCtrlFlagNum,
      });
    } catch (error) {
      setLoadingFlagNum(false);
    }
  };

  return selectedChannel ? (
    <ProductContent selectedChannel={selectedChannel} onCallback={() => setSelectedChannel(undefined)} />
  ) : (
    <ContentLayout>
      <Heading title="Channel Listing -> Channels" />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <Spin spinning={loading || loadingFlagNum}>
          <Row gutter={24} justify="start">
            {Object.keys(data.nonRetail).map((channelNum) => {
              if (Number(channelNum) === 101 || Number(channelNum) === 102) return null;
              else return (
                <Col span={8} style={{ marginBottom: 24 }} key={channelNum}>
                  <ChannelIntegration
                    accounts={data.nonRetail[Number(channelNum)]}
                    handleClick={(p) => {
                      getControlFlag(p);
                    }}
                  />
                </Col>
              )
            })}
            {Object.keys(data.retail).map((channelNum) => (
              <Col span={8} key={channelNum}>
                <ChannelIntegration
                  accounts={data.retail[Number(channelNum)]}
                  handleClick={(p) => {
                    getControlFlag(p);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Spin>
      </SiteContent>
    </ContentLayout>
  );
};

export default Channel;
