import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Button,
  Row,
  notification,
} from 'antd';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import theme from '../../assets/styles/theme';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
} from '../../constants/config';

const ErrorWrapper = styled.div`
  font-size: 16px;

  & .bold-info {
    font-weight: 460;
  }

  & .email-color {
    color: ${theme['@primary-color']};
  }

  & .green-color {
    color: ${theme['@success-color']};
  }
`;

const ErrorDescription = (props: StringKAnyVPair) => {
  const getCopyContent = (cfg: StringKAnyVPair) => {
    //console.log('cfg ->', cfg);
    return JSON.stringify(cfg);
  };

  const onCopySuccessfully = (cfg: StringKAnyVPair) => {
    console.log(cfg);
    notification.success({
      message: 'Copy error detail successfully',
      duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
    });
  };

  return (<>
    <ErrorWrapper>
      <p className="bold-info">Sorry, something is wrong here! <span className="green-color">Please try again later.</span></p>
      <p className="bold-info">We have recorded this error on our end and will analyze and fix it.</p>
      <p className="bold-info">If you have experienced this error more than once and need to quickly fix it, then click the "Copy Error Detail" button and email the message to <span className="email-color">error-report@DigitBridge.com</span></p>
      <CopyToClipboard
        text={getCopyContent(props.cfg)}
        onCopy={() => onCopySuccessfully(props.cfg)}
      >
        <Button>
          Copy Error Detail
        </Button>
      </CopyToClipboard>
      <Row justify="end">
        <Button
          onClick={() => notification.close(props.key)}
          type="primary"
        >
          Close
        </Button>
      </Row>
    </ErrorWrapper>
  </>);
};

export const reportError = (cfg: StringKAnyVPair) => {
  const ntKey = uuid();

  notification.error({
    message: <span className="err-msg-title">Something went wrong!<span role="img" aria-label="sorry">😔</span></span>,
    description: ErrorDescription({key: ntKey, cfg}),
    duration: DEFAULT_ERR_MSG_DISPLAY_DURATION * 10,
    className: 'ec-err-notification',
    key: ntKey,
    icon: null,
  });
};
