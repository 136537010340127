import { ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Row, Spin, Button, Tabs, Card, Space, Typography, Modal, Dropdown, Menu, Col } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// import styled from 'styled-components';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import message from '../../components/common/message';
import { fetchOutputTemplates, deleteTemplate, downloadTemplate } from '../../services/template';
import { useExecutePromise } from '../../util/hooks';
import UploadModal from './UploadModal';
import MappingModal from './MappingModal';

const { TabPane } = Tabs;
const { Text } = Typography;

type TemplateType = {
  productMappingNum: number;
  productMappingName: string;
  mappingContentId: string;
  isMapped: boolean;
  channelNum: number;
  channelName: string;
};

const sortGroupArr = (sortData: TemplateType[]) => {
  const groupBy = (array: TemplateType[], f: Function) => {
    const groups: {
      [key: string]: TemplateType[];
    } = {};
    array.forEach((item) => {
      const group = JSON.stringify(f(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  };
  const sorted = groupBy(sortData, (item: TemplateType) => {
    return item.channelNum;
  });
  return sorted;
};

// TODO: streamline links here and sidebar
const Templates: React.FC = () => {
  const [data, loading, setReload] = useExecutePromise(fetchOutputTemplates, {
    channelTemplates: [],
    globalTemplates: [],
    copywritingTemplate: undefined,
  });
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const [defaultActiveTab] = useState<string>(searchParams.get('tab') || 'channel');
  const [current, setCurrent] = useState<any>();
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [mappingModalVisible, setMappingModalVisible] = useState<boolean>(false);
  const groupData = sortGroupArr(data.channelTemplates);

  const exportTemplate = async (productMappingNum: number, name: string) => {
    try {
      const res = await downloadTemplate(productMappingNum);
      // todo: save to file
      // console.log('res ->', res);
      const downloadName = filenameFromHeader(res.headers) || `Template-${name}.xlsx`;
      const content = res.data;
      const url = window.URL.createObjectURL(new Blob([content]));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', `Template-${name}.xlsx`);
      link.setAttribute('download', downloadName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      message.error(`Download file error: ${error}`);
    }
  };

  const filenameFromHeader = (header: StringKAnyVPair) => {
    const str = header['content-disposition'];

    if (str) {
      const fields = str.split('=');

      return fields[fields.length - 1];
    }
  };

  const delTemplate = async (tp: TemplateType) => {
    try {
      Modal.confirm({
        title: 'Do you want to delete this template?',
        icon: <ExclamationCircleOutlined />,
        content: `name: ${tp.productMappingName}`,
        onOk: async () => {
          const res = await deleteTemplate(tp.productMappingNum);
          if (res) {
            message.success(res);
            setReload(true);
          }
        },
        onCancel() {},
      });
    } catch (error) {}
  };

  const handleClickImportNew = (key: number, channelNum: number, title?:string) => {
    setCurrent({
      templateType: key,
      channelNum,
      title: `Upload ${key === 1 ? 'Channel Catalog' : key === 2 ? 'Channel Inventory' : title ? title : 'Custom' } Mapping template`,
    });
    setUploadModalVisible(true);
  };

  return (
    <ContentLayout>
      <Heading title="Output Templates" />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <Spin spinning={loading}>
          <Tabs defaultActiveKey={defaultActiveTab} type="card" size="large">
            <TabPane tab="Channel Templates" key="channel">
              <Card>
                <Row gutter={24} justify="start">
                  {groupData.map((item: TemplateType[], index: number) => {
                    return (
                      <Col xxl={8} xl={12} md={24} style={{ marginBottom: 24 }} key={`group${index}`}>
                        <Card
                          style={{ height: '100%' }}
                          headStyle={{ backgroundColor: '#f0f0f0' }}
                          type="inner"
                          title={
                            <Row justify="space-between" align="middle" style={{ marginTop: 10, width: '100%' }}>
                              <Text style={{ fontSize: 18 }} strong>
                                {item[0].channelName}
                              </Text>
                                <Dropdown
                                  overlay={
                                    <Menu onClick={(e) => handleClickImportNew(Number(e.key), item[0]?.channelNum || 0)}>
                                      <Menu.Item key="1">Channel Catalog</Menu.Item>
                                      <Menu.Item key="2">Channel Inventory</Menu.Item>
                                      {/* <Menu.Item key="5">Custom Template</Menu.Item>
                            <Menu.Item key="6">Custom Template</Menu.Item> */}
                                    </Menu>
                                  }
                                >
                                  <Button style={{ display: 'none' }}>
                                    Import New <DownOutlined />
                                  </Button>
                                </Dropdown>
                            </Row>
                          }
                        >
                          {item.map((i, index: number) => (
                            <Row style={{ paddingLeft: 10, width: '100%' }} key={`buttons${index}`} justify='space-between'>
                              <Text style={{ width: 200, lineHeight: '32px' }}>{i.productMappingName}</Text>
                              <Space>
                                <Button
                                  style={{ display: 'none' }}
                                  type="link"
                                  onClick={() => {
                                    setCurrent(i);
                                    setMappingModalVisible(true);
                                  }}
                                >
                                  Mapping
                                </Button>
                                <Button type="link" onClick={() => exportTemplate(i.productMappingNum, `${i.channelName}-${i.productMappingName}`)}>
                                  Export
                                </Button>
                                <Button type="link" onClick={() => delTemplate(i)} style={{ display: 'none' }}>
                                  Delete
                                </Button>
                              </Space>
                            </Row>
                          ))}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </TabPane>
            <TabPane tab="Global Templates" key="global">
              <Card>
                <Space direction="vertical">
                  {data.globalTemplates.map((item: TemplateType, index:number) => {
                    return (
                      <Row style={{ marginTop: 4 }} key={`button${index}`}>
                        <div style={{ width: 200, lineHeight: '32px' }}>
                          <Text>{item.productMappingName}</Text>
                        </div>
                        <Space>
                          <Button
                            type="link"
                            onClick={() => {
                              setCurrent(item);
                              setMappingModalVisible(true);
                            }}
                          >
                            Mapping
                          </Button>
                          <Button type="link" onClick={() => exportTemplate(item.productMappingNum, item.productMappingName)}>
                            Export
                          </Button>
                          <Button type="link" onClick={() => delTemplate(item)}>
                            Delete
                          </Button>
                        </Space>
                      </Row>
                    );
                  })}
                  <Button onClick={() => handleClickImportNew(4, data.globalTemplates[0]?.channelNum || 0)} style={{ display: 'none' }}>Import New</Button>
                </Space>
              </Card>
            </TabPane>
            <TabPane tab="Standard Templates" key="standard">
              <Card>
                <Space direction="vertical">
                  {Array.isArray(data.standardTemplates) && data.standardTemplates.map((item: TemplateType, index:number) => {
                    return (
                      <Row style={{ marginTop: 4 }} key={`button${index}`}>
                        <div style={{ width: 200, lineHeight: '32px' }}>
                          <Text>{item.productMappingName}</Text>
                        </div>
                        <Space>
                          <Button
                            type="link"
                            onClick={() => {
                              setCurrent(item);
                              setMappingModalVisible(true);
                            }}
                          >
                            Mapping
                          </Button>
                          <Button type="link" onClick={() => exportTemplate(item.productMappingNum, item.productMappingName)}>
                            Export
                          </Button>
                          <Button type="link" onClick={() => delTemplate(item)}>
                            Delete
                          </Button>
                        </Space>
                      </Row>
                    );
                  })}
                  {/*<Button onClick={() => handleClickImportNew(4, data.globalTemplates[0]?.channelNum || 0)}>Import New</Button>*/}
                </Space>
              </Card>
            </TabPane>
          </Tabs>
        </Spin>
      </SiteContent>
      {uploadModalVisible && (
        <UploadModal
          {...current}
          onCancel={() => setUploadModalVisible(false)}
          onOk={() => {
            setUploadModalVisible(false);
            setReload(true);
          }}
        />
      )}
      {mappingModalVisible && <MappingModal channelNum={current.channelNum} productMappingName={current.productMappingName} productMappingNum={current.productMappingNum} onCancel={() => setMappingModalVisible(false)} />}
    </ContentLayout>
  );
};

export default Templates;
