import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import SiteContent from '../components/SiteContent';
import ContentLayout from '../components/ContentLayout';
import { fetchChannels } from '../services/channels';
import {
  fetchCategories,
  fetchChannelAccountNum,
  fetchProductBySKU,
  fetchProductsByCategory,
} from '../services/products';
import TableComponent from '../components/TableComponent';
import VerifiedIcon from '../assets/icons/verified';
import LabelIcon from '../assets/icons/label';
import ThumbsUpIcon from '../assets/icons/thumbsup';
import CheckMark from '../assets/icons/checkmark';
import BlockIcon from '../assets/icons/block';
import { sortDataTable } from '../util';
import Heading from '../components/common/Heading';
import EyeIcon from '../assets/icons/eye';

const { Option } = Select;
interface IConditionalMappersElement {
  Mapper: {
    MapperType: number;
    MappedValue: string;
    MapperDisplayName: string;
  };
  ConditionType: number;
}

interface IElement {
  ElementName: string;
  ElementDisplayName: string;
  MappedStatus: number;
  Requirement: number;
  HelpNum: number;
  Mapper: {
    MapperType: number;
    MappedValue: string;
    MapperDisplayName: string;
  };
  ConditionalMappers: IConditionalMappersElement[] | null;
}

function ProductMappingPreview(props: any) {
  const { match } = props;
  const [channelName, setChannelName] = useState<string>('');
  const [channelAccountNum, setChannelAccountNum] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('Category');
  const [selectedSKU, setSelectedSKU] = useState<string>('SKU');
  const [loading, setLoading] = useState<boolean>(false);
  const [disableSKU, setDisableSKU] = useState<boolean>(true);
  const [data, setData] = useState<IElement []>([]);
  const [skus, setSkus] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<any>>([]);
  const [filteredInfo, setFilteredInfo] = useState({
    Requirement: 0,
    MappedStatus: 0,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: data.length,
  });
  const columns = [
    {
      title: 'Priority',
      dataIndex: 'Requirement',
      align: 'center',
      key: 'Requirement',
      width: 50,
      filters: [
        {
          text:(
            <i>
              <VerifiedIcon color="#30c8ff" />
              Mandatory
            </i>
          ),
          value: 1,
        },
        {
          text: (
            <i>
              <LabelIcon color="#b56416" />
              Require If Relevant
            </i>
          ),
          value: 2,
        },
        {
          text: (
            <i>
              <ThumbsUpIcon color="#bababa" />
              Recommended
            </i>
          ),
          value: 3,
        },
      ],
      filteredValue: filteredInfo.Requirement || null,
      onFilter: (value: any, record: any) => record.Requirement.toString().includes(value),
      sorter: (a: any, b: any) => a.Requirement.length - b.Requirement.length,
      ellipsis: true,
      render: function render(value: any) {
        switch (value) {
          case 2: return <LabelIcon color="#b56416" />;
          case 3: return <ThumbsUpIcon color="#bababa" />;
          default: return <VerifiedIcon color="#30c8ff" />;
        }
      },
    },
    {
      title: 'Channel Field',
      width: 100,
      dataIndex: 'ElementDisplayName',
      key: 'ElementDisplayName',
      sorter: (a: any, b: any) => a.ElementDisplayName - b.ElementDisplayName,
    },
    {
      title: 'Mapping from your data',
      dataIndex: 'Mapper',
      width: 150,
      key: 'Mapper',
      ellipsis: true,
      render: function render(value: any, record: any) {
        let mappers;
        if (record.MappedStatus) {
          mappers = (
            <i>
              <EyeIcon />
              {record.PreviewValue ? ` ${record.PreviewValue}` : ' Empty Preview Value'}
            </i>
          );
        }
        return (
          <>
            <p>
              {mappers}
            </p>
          </>
        );
      },
    },
    {
      title: 'Status',
      width: 50,
      dataIndex: 'MappedStatus',
      key: 'MappedStatus',
      filters: [
        {
          text:
            (
              <i>
                <CheckMark color="#52c41a" />
                Mapped
              </i>
            ),
          value: 1,
        },
        {
          text:
            (
              <i>
                <BlockIcon />
                Unmapped
              </i>
            ),
          value: 0,
        },
      ],
      filteredValue: filteredInfo.MappedStatus || null,
      onFilter: (value: any, record: any) => record.MappedStatus.toString().includes(value),
      sorter: (a: any, b: any) => a.MappedStatus.length - b.MappedStatus.length,
      ellipsis: true,
      render: function render(value: any) {
        return value ? (
          <p style={{ color: '#52c41a', margin: '0px' }}>
            <CheckMark color="#52c41a" />
            {' '}
            Mapped
          </p>
        )  : (
          <p style={{ margin: '0px' }}>
            <BlockIcon />
            {' '}
            Unmapped
          </p>
        );
      },
    },
  ];

  const handleTableChange = (paginationParam: any, filters: any, sorter: any) => {
    setFilteredInfo(filters);
    const sortedData = sortDataTable(data, sorter);
    setData(sortedData);
    setPagination({
      ...paginationParam,
      pageSize: paginationParam.pageSize,
      current: paginationParam.current,
    });
  };

  const handleCategoryChange = async (id: string, origin: string) => {
    try {
      setLoading(true);
      const response = await fetchProductsByCategory(id, props.match.params.channelId);
      setSkus(response);
      setDisableSKU(false);
      if (response.length === 0) setData([]);
      if (origin !== 'preset') setSelectedSKU('SKU');
      setSelectedCategory(id);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const handleSKUChange = async (sku: string) => {
    try {
      setLoading(true);
      const response = await fetchProductBySKU(sku, match.params.channelId, channelAccountNum);
      setData(response);
      setSelectedSKU(sku);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const fetchData = async (channelId: number, productSKU: string, categoryId: string) => {
    try {
      setLoading(true);
      const availableChannel = await fetchChannels();
      const indexSelectedChannel = availableChannel.map((c: any) => c.channelNum).indexOf(Number(channelId));
      setChannelName(availableChannel[indexSelectedChannel].channelName);
      const responseCategories = await fetchCategories(channelId);
      setCategories(responseCategories);
      const responseAccountNum = await fetchChannelAccountNum(channelId);
      setChannelAccountNum(responseAccountNum[0].ChannelAccountNum);
      if (productSKU !== '0') {
        const productMapping = await fetchProductBySKU(productSKU, channelId, responseAccountNum[0].ChannelAccountNum);
        setData(productMapping);
        setDisableSKU(false);
        setSelectedSKU(productSKU);
        await handleCategoryChange(categoryId, 'preset');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const useFetch = (channelId: number, productSKU: string, categoryId: string) => {
    useEffect(() => {
      fetchData(channelId, productSKU, categoryId);
    }, [channelId, productSKU, categoryId]);
  };

  useFetch(match.params.channelId, match.params.skuId, match.params.categoryId);

  const searchSection = (
    <>
      <Select
        placeholder="Category"
        showSearch
        value={selectedCategory}
        style={{ width: 200, marginRight: '5px' }}
        onChange={(e: any) => handleCategoryChange(e, '')}
      >
        {categories.map((s: any) => (<Option key={s.ProductCategoryID} value={s.ProductCategoryName}>{s.ProductCategoryName}</Option>))}
      </Select>
      <Select
        placeholder="SKU"
        showSearch
        disabled={disableSKU}
        value={selectedSKU}
        style={{ width: 400 }}
        onChange={handleSKUChange}
      >
        {skus.map((s: any) => (<Option key={s.sku} value={s.sku}>{s.sku}</Option>))}
      </Select>
    </>
  );

  return (
    <ContentLayout>
      <SiteContent>
        <Heading title={`${channelName} Product Mapping Template | ${selectedSKU} Preview`} />
      </SiteContent>

      <SiteContent>
        <TableComponent
          loadingStatus={loading}
          dataSource={data}
          title={() => searchSection}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            ...pagination,
          }}
        />
      </SiteContent>
    </ContentLayout>
  );
}

export default ProductMappingPreview;