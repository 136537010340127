export interface MsgItem {
  id: string;
  url: string;
  method: string;
  statusCode: string;
  time: string;
  isRead: boolean;
  response: any;
}

export interface ErrorMessageState {
  msgs: MsgItem[];
  count: Number;
}

const initialState: ErrorMessageState = {
  msgs: [],
  count: 0,
};

const errorMessage = (state = initialState, action: any) => {
  switch (action.type) {
    case 'PUSH_MESSAGE':
      const t = [action.value, ...state.msgs];
      const temp = t.length > 30 ? t.slice(0, 30 - [action.value, ...state.msgs].length) : [...t]
      const count = temp.filter(i => !i.isRead).length;
      return {
        msgs: [...temp],
        count,
      };
    case 'SET_ISREAD':
      const temp1 = [...state.msgs]
      const index1 = temp1.findIndex(i => i.id === action.value)
      if (index1 > -1) {
        temp1[index1] = {
          ...temp1[index1],
          isRead: true
        }
      }
      return {
        ...state,
        msgs: [...temp1],
      };
    case 'CLOSE_BOX':
      const count1 = state.msgs.filter(i => !i.isRead).length
      return {
        ...state,
        count: count1
      }
    case 'CLEAR_ONE':
      const temp2 = [...state.msgs]
      temp2.splice(temp2.findIndex(i => i.id === action.value), 1)
      return {
        ...state,
        msgs: [...temp2],
      };
    case 'CLEAR_ALL':
      return {
        msgs: [],
        count: 0
      }
    default:
      return state;
  }
};

export default errorMessage;
