export enum SalesOrderDetailsActions {
  SET_ORDER = 'salesOrder/setSalesOrderDetails',
  SET_LOADING = 'salesOrder/setLoading',
  SET_EDITMODE = 'salesOrder/setEditMode',
  SET_ISFORMUPDATED = 'salesOrder/setIsFormUpdated',
  SET_ACTIVEVIEW = 'salesOrder/setActiveView',
  SET_OPENPRODUCTSMODAL = 'salesOrder/setOpenProductsModal',
  SET_OPENCUSTOMERMODAL = 'salesOrder/setOpenCustomerModal',
  SET_DETAILS_PRODUCTS = 'salesOrder/setDetailsProducts',
  SET_CUSTOMERS = 'salesOrder/setCustomers',
  SET_TOTALQUANTITY = 'salesOrder/setTotalQty',
}

interface SetSalesOrderDetailsAction {
  type: typeof SalesOrderDetailsActions.SET_ORDER,
  order: Entities.SalesOrderDetails
}

interface SetLoading {
  type: typeof  SalesOrderDetailsActions.SET_LOADING
  value: boolean,
}

interface SetEditMode {
  type: typeof  SalesOrderDetailsActions.SET_EDITMODE
  value: boolean,
}

interface SetIsFormUpdated {
  type: typeof  SalesOrderDetailsActions.SET_ISFORMUPDATED
  value: boolean,
}

interface SetActiveView {
  type: typeof  SalesOrderDetailsActions.SET_ACTIVEVIEW
  value: string,
}

interface SetOpenProductsModal {
  type: typeof  SalesOrderDetailsActions.SET_OPENPRODUCTSMODAL
  value: boolean,
}

interface SetOpenCustomerModal {
  type: typeof  SalesOrderDetailsActions.SET_OPENCUSTOMERMODAL
  value: boolean,
}

interface SetDetailsProducts {
  type: typeof  SalesOrderDetailsActions.SET_DETAILS_PRODUCTS
  value: Array<object>,
}

interface SetCustomers {
  type: typeof  SalesOrderDetailsActions.SET_CUSTOMERS
  value: Array<Entities.SalesOrderCustomers>,
}

interface SetTotalQty {
  type: typeof  SalesOrderDetailsActions.SET_TOTALQUANTITY
  value: object,
}

export type SalesOrderDetailsActionTypes = SetTotalQty | SetCustomers | SetOpenCustomerModal | SetDetailsProducts | SetSalesOrderDetailsAction | SetLoading | SetEditMode | SetIsFormUpdated | SetActiveView | SetOpenProductsModal;
