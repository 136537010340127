import { Checkbox, Col, Divider, Form, FormInstance, Input, Modal, notification, Row, Select, Spin, Tabs } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'; // eslint-disable-line
import { useHistory, useParams } from 'react-router-dom';
import FormButtons from '../components/common/FormButtons';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import { Permissions } from '../constants/enums/permissions';
import {
  createDistributionCenter,
  fetchDistributionCenter,
  fetchDistributionCenters,
  saveDistributionCenter,
} from '../services/distributionCenter';
import { DistributionCenterType } from '../types/enums';
import { enumToObjects } from '../util/enums';
import { validateEmail, validatePhoneFaxNumber, validateURL, validateZipcode } from '../util/validators';


const useFetchDistributionCenter = (dcId?: string): [Entities.DistributionCenter | null, boolean, React.Dispatch<React.SetStateAction<Entities.DistributionCenter | null>>] => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Entities.DistributionCenter | null>(null);

  useEffect(() => {
    let mounted = true;

    if (!dcId) {
      return () => {
        mounted = false;
      };
    }
    setLoading(true);
    fetchDistributionCenter(dcId)
      .then((res: Entities.DistributionCenter) => {
        if (!mounted) {
          return;
        }

        setData(res);
      })
      .finally(() => {
        if (!mounted) {
          return;
        }

        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [dcId]);

  return [data, loading, setData];
};

const useFormSetup = (dc: Entities.DistributionCenter | null, form: FormInstance, dcCount: number) => {
  useEffect(() => {
    if (!dc) {
      return;
    }

    form.setFieldsValue(dc);

    if (dcCount === 0) {
      form.setFieldsValue({ IsDefault: 1 });
    }
  }, [dc, form, dcCount]);
};

const useCountDC = (): [number, boolean] => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    setLoading(true);

    fetchDistributionCenters()
      .then((dcs) => {
        if (!mounted) {
          return;
        }

        setCount(dcs.length);
      })
      .finally(() => {
        if (!mounted) {
          return;
        }

        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return [count, loading];
};

const defaultDCMessage: JSX.Element = (
  <p>
    Only one distribution center can be marked as
    <strong>&nbsp;default&nbsp;</strong>
    at any given time.
    <br />
    <br />
    <strong>If you want to mark the current distribution center the default,&nbsp;</strong>
    leave the field checked.
  </p>
);

interface DCFormProps {
  form: FormInstance<Entities.DistributionCenter>;
  editMode: boolean;
  onNameChange: (name: string) => void;
  onSaved: (created: boolean, entity: Entities.DistributionCenter) => void;
  onFinished: () => void;
  onFinishFailed: (...args: any[]) => void;
  disabled?: boolean;
}

const DCForm: React.FC<DCFormProps> = (
  {
    form,
    editMode,
    onNameChange,
    onSaved,
    onFinished,
    onFinishFailed,
    disabled = false,
  },
) => {
  const editingDisabled = useMemo(() => disabled || !editMode, [editMode, disabled]);

  const onMakeDefaultChange = useCallback((value: boolean) => {
    if (!value) {
      return;
    }

    notification.warn({
      message: 'Default Distribution Center',
      description: defaultDCMessage,
      duration: null,
    });
  }, []);

  const onFieldsChange = useCallback((changedFields: FieldData[]) => {
    const nameFieldChanged = changedFields.find(f => (f.name as string[]).join('.') === 'DistributionCenterName');
    const isDefaultChanged = changedFields.find(f => (f.name as string[]).join('.') === 'IsDefault');

    if (nameFieldChanged) {
      onNameChange(nameFieldChanged.value);
    }

    if (isDefaultChanged) {
      onMakeDefaultChange(isDefaultChanged.value);
    }
  }, [onNameChange, onMakeDefaultChange]);

  const onFormFinished = useCallback((values: Entities.DistributionCenter) => {
    // Replace true/false on IsDefault with 1 or 0
    const data: Entities.DistributionCenter = { ...values, IsDefault: values.IsDefault ? 1 : 0 };

    const dcId: string = values?.DistributionCenterID || '';
    const willSave: boolean = !!dcId;
    const req: Promise<Entities.DistributionCenter> = willSave ? saveDistributionCenter(dcId, data) : createDistributionCenter(data);

    req
      .then((res) => {
        notification.success({
          message: willSave ? 'Distribution center saved successfully' : 'Distribution center created successfully',
        });
        onSaved(!willSave, res);
      })
      .catch(() => {
        notification.error({ message: 'There was an error' });
      })
      .finally(onFinished);
  }, [onSaved, onFinished]);

  return (
    <Form
      form={form}
      labelAlign="left"
      labelCol={{ sm: 24, lg: 6 }}
      wrapperCol={{ sm: 24, lg: 18 }}
      onFieldsChange={onFieldsChange}
      onFinish={onFormFinished}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="DistributionCenterID"
        noStyle
        hidden
      >
        <Input disabled />
      </Form.Item>
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Status"
            name="DistributionCenterStatus"
          >
            <Select disabled={!editMode}>
              <Select.Option value={0}>
                Disabled
              </Select.Option>
              <Select.Option value={1}>
                Enabled
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Code"
            name="DistributionCenterCode"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="DistributionCenterName"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Type"
            name="DistributionCenterType"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select disabled={editingDisabled}>
              {
                enumToObjects({ choices: DistributionCenterType }).map((c) => (
                  <Select.Option key={c.id} value={c.value} title={c.label}>
                    {c.label}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="CompanyName"
            // rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Contact"
            name="ContactName"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="ContactEmail"
            rules={[{ validator: validateEmail }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="ContactPhone"
            rules={[{ validator: validatePhoneFaxNumber, message: 'Invalid Phone Number' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="IsDefault"
            valuePropName="checked"
          >
            {/* <Checkbox disabled={editingDisabled}> */}
            <Checkbox disabled={!editMode}>
              Default Warehouse
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Address Line 1"
            name="AddressLine1"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name="AddressLine2"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="City"
            name="City"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Row gutter={12}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="State"
                name="State"
              >
                <Input disabled={editingDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Zip Code"
                name="ZipCode"
                rules={[{ validator: validateZipcode }]}
              >
                <Input disabled={editingDisabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Website"
            name="Website"
            rules={[{ validator: validateURL }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Business Hours"
            name="BusinessHours"
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Main Email"
            name="Email"
            rules={[{ validator: validateEmail }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Main Phone"
            name="MainPhone"
            rules={[{ validator: validatePhoneFaxNumber, message: 'Invalid Phone Number' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
          <Form.Item
            label="Fax"
            name="Fax"
            rules={[{ validator: validatePhoneFaxNumber, message: 'Invalid Fax Number' }]}
          >
            <Input disabled={editingDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Notes"
            name="Notes"
            labelCol={{ sm: 24, lg: 3 }}
            wrapperCol={{ sm: 24, lg: 19 }}
          >
            <Input.TextArea
              rows={8}
              disabled={editingDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

interface PageTitleElement {
  updateName: (value: string) => void;
}

interface PageTitleProps {
  entity: Entities.DistributionCenter | null;
  creating: boolean;
}

const PageTitle = React.forwardRef<PageTitleElement, PageTitleProps>(({ entity, creating }, ref) => {
  const generateName = useCallback((value?: string) => `Distribution Center - ${value}`, []);

  const [title, setTitle] = useState<string>(generateName(entity?.DistributionCenterName));

  const updateName = useCallback((value: string) => {
    const newValue = creating && !value ? 'new' : value;
    setTitle(generateName(newValue));
  }, [generateName, creating]);

  useImperativeHandle(ref, () => ({
    updateName,
  }));

  useEffect(() => {
    const value = creating ? 'New' : entity?.DistributionCenterName || '';
    updateName(value);
  }, [entity, updateName, creating]);

  return (
    <Heading
      title={title}
    />
  );
});

PageTitle.displayName = 'PageTitle';

const Page: React.FC = () => {
  const { id: dcId } = useParams<{ id?: string }>();
  const history = useHistory();
  const creatingEntry = !dcId;
  const [form] = Form.useForm<Entities.DistributionCenter>();
  const [dc, loading, setDc] = useFetchDistributionCenter(dcId);
  const [dcCount, loadingDCCount] = useCountDC();
  const [editMode, setEditMode] = useState(creatingEntry);
  const [processing, setProcessing] = useState(false);
  const pageTitleRef = useRef<React.ElementRef<typeof PageTitle>>(null);

  const loadingData = useMemo(() => loading || loadingDCCount, [loading, loadingDCCount]);

  const deleteDisabled = useMemo(() => {
    if (editMode) {
      return false;
    }

    return !dcId;
  }, [dcId, editMode]);

  const onSaveClicked = useCallback(() => {
    setProcessing(true);
    form.submit();
  }, [form]);

  const onDeleteClicked = useCallback(() => {
    if (!dcId) {
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to delete this entry?',
      content: 'This action cannot be reversed.',
      onOk: () =>
        notification.info({
          message: 'Feature not implemented',
          description: 'This feature is not supported yet.',
        }),
    });
  }, [dcId]);

  const onEditClicked = useCallback(() => setEditMode(true), []);

  const onCancelClicked = useCallback(() => {
    if (creatingEntry) {
      history.push('/settings-distribution-center/');
      return;
    }

    setEditMode(false);
    if (dc) {
      form.setFieldsValue(dc);
      pageTitleRef.current?.updateName?.(dc.DistributionCenterName);
    } else {
      form.resetFields();
    }
  }, [form, dc, creatingEntry, history, pageTitleRef]);

  const onNameChange = useCallback((value: string) => pageTitleRef.current?.updateName?.(value), [pageTitleRef]);

  const onFormSaved = useCallback((created: boolean, entity: Entities.DistributionCenter) => {
    setEditMode(false);
    setDc(entity);

    if (created) {
      history.push(`/settings-distribution-center-details/${entity.DistributionCenterID}`);
    }
  }, [setDc, history]);

  const onFormFinished = useCallback(() => setProcessing(false), []);


  // eslint-disable-next-line
  const onFinishFailed = useCallback((errorInfo: any) => {

    notification.error({ message: 'Please verify that all fields are filled properly' });
    setEditMode(true);
    setProcessing(false);
  }, []);

  useFormSetup(dc, form, dcCount);

  return (
    <ContentLayout>
      <Spin spinning={loadingData}>
        <PageTitle
          ref={pageTitleRef}
          entity={dc}
          creating={creatingEntry}
        />
        <Spacer />
        <SiteContent>
          <Tabs
            tabBarExtraContent={(
              <FormButtons
                onSave={onSaveClicked}
                onDelete={onDeleteClicked}
                onCancel={onCancelClicked}
                onEdit={onEditClicked}
                permissionNumber={Permissions.MANAGE_DISTRIBUTION_CENTERS}
                editingMode={editMode}
                disableDelete={deleteDisabled}
                saving={processing}
                hideDelete
              />
            )}
          >
            <Tabs.TabPane
              tab="Details"
            >
              <DCForm
                form={form}
                editMode={editMode}
                onNameChange={onNameChange}
                onSaved={onFormSaved}
                onFinished={onFormFinished}
                onFinishFailed={onFinishFailed}
                // disabled={processing}
                disabled
              />
            </Tabs.TabPane>
          </Tabs>
        </SiteContent>
      </Spin>
    </ContentLayout>
  );
};

export default Page;
