import { FormInstance } from 'antd';

export enum IntegrationsActions {
  SET_FORMS = 'integrations/setForms',
  SET_LOADING = 'integrations/setLoading',
  SET_PAGETITLE = 'integrations/setPageTitle',
  SET_CHANNELS = 'integrations/setChannels',
  SET_CHANNELFIELDS = 'integrations/setChannelFields',
  SET_OAUTHURL = 'integrations/setOAuthURL',
  SET_FORM = 'integrations/setForm',
  SET_ISACTIVATED = 'integrations/setIsActivated',
  SET_VERIFY_CONNECTION = 'integrations/setVerifyConnection',
  SET_CHANNEL_ACCOUNT = 'integrations/setChannelAccount',
  SET_PROFILE_SETTINGS = 'integrations/setChannelAccountProfileSettings',
  SET_RETAILERS = 'integrations/setRetailers',
  SET_GLOBALENABLED = 'integrations/setGlobalEnabled',
  SET_GENERAL_DATA = 'integrations/setGeneralData',
  CLEAR_CURRENT_PROFILE = 'integrations/clearCurrentProfile',
  SET_EDIT_MODE = 'integrations/setEditMode',
  CANCEL_EDITION = 'integrations/cancelEdition',
  REMOVE_FORMS = 'integrations/removeForms',
  SET_FORM_DIRTY_STATUS = 'integrations/setFormDirtyStatus',
  SET_SAVING_SETTINGS = 'integrations/setSavingSettings',
  SET_FORM_HAS_ERRORS = 'integrations/setFormHasErrors',
  SET_SETTINGS_FORM_HAS_ERRORS = 'integrations/setSettingsFormHasErrors',
  RESET_ERROR_STATUS = 'integrations/resetErrorStatus',
}

interface SetForms{
  type: typeof IntegrationsActions.SET_FORMS;
  value: {
    form: FormInstance,
    settingsForm: FormInstance<Entities.ChannelAccountProfileSetting>
  };
}

interface RemoveForms {
  type: typeof IntegrationsActions.REMOVE_FORMS;
}

interface SetProfileSettings {
  type: typeof IntegrationsActions.SET_PROFILE_SETTINGS;
  value?: FormModels.IntegrationSettings;
}

interface SetLoading {
  type: typeof IntegrationsActions.SET_LOADING,
  value: boolean
}

interface SetPageTitle {
  type: typeof  IntegrationsActions.SET_PAGETITLE,
  value: string,
}

interface SetChannels {
  type: typeof  IntegrationsActions.SET_CHANNELS,
  groupedChannels: Entities.ChannelProfile[][],
  channels: Entities.ChannelProfile[],
}

interface SetChannelFields {
  type: typeof  IntegrationsActions.SET_CHANNELFIELDS,
  value: any,
}

interface SetOAuthURL {
  type: typeof  IntegrationsActions.SET_OAUTHURL,
  value?: string,
}

interface SetForm {
  type: typeof  IntegrationsActions.SET_FORM,
  value: FormInstance,
}

interface SetIsActivated {
  type: typeof  IntegrationsActions.SET_ISACTIVATED,
  value: boolean,
}

interface SetVerifyConnection {
  type: typeof IntegrationsActions.SET_VERIFY_CONNECTION,
  value: boolean,
}

interface SetChannelAccount {
  type: typeof IntegrationsActions.SET_CHANNEL_ACCOUNT,
  value?: Entities.ChannelAccountObj,
}

interface SetRetailers {
  type: typeof IntegrationsActions.SET_RETAILERS,
  value?: Entities.ChannelAccountObj | null,
}

interface SetGlobalEnabled {
  type: typeof IntegrationsActions.SET_GLOBALENABLED,
  value?: Entities.ChannelAccountObj | null,
}

interface SetGeneralData {
  type: typeof IntegrationsActions.SET_GENERAL_DATA,
  value: {
    retailers: Entities.ChannelProfile[],
    channels: Entities.ChannelProfile[],
    platforms: Entities.Platform[],
    groupedChannels: Entities.ChannelProfile[][],
    globalEnabled: Entities.ChannelProfile[],
  }
}

interface ClearCurrentProfile {
  type: typeof IntegrationsActions.CLEAR_CURRENT_PROFILE,
  value: {
    editMode?: boolean,
  }
}

interface SetEditMode {
  type: typeof IntegrationsActions.SET_EDIT_MODE,
  value: boolean,
}

interface CancelEdition {
  type: typeof IntegrationsActions.CANCEL_EDITION
}

interface SetFormDirtyStatus {
  type: typeof IntegrationsActions.SET_FORM_DIRTY_STATUS,
  value: {
    target: 'channelAccount' | 'channelAccountSettings',
    value: boolean,
  },
}

interface SetSavingSettings {
  type: typeof IntegrationsActions.SET_SAVING_SETTINGS,
  value: boolean,
}

interface SetFormHasErrors {
  type: typeof IntegrationsActions.SET_FORM_HAS_ERRORS,
  value: boolean,
}

interface SetSettingsFormHasErrors {
  type: typeof IntegrationsActions.SET_SETTINGS_FORM_HAS_ERRORS,
  value: boolean,
}

interface ResetErrorStatus {
  type: typeof IntegrationsActions.RESET_ERROR_STATUS;
}

export type IntegrationsActionTypes =
  SetForms
  | SetProfileSettings
  | SetPageTitle
  | SetLoading
  | SetChannels
  | SetIsActivated
  | SetChannelFields
  | SetForm
  | SetRetailers
  | SetOAuthURL
  | SetVerifyConnection
  | SetChannelAccount
  | SetGeneralData
  | ClearCurrentProfile
  | SetEditMode
  | CancelEdition
  | RemoveForms
  | SetFormDirtyStatus
  | SetSavingSettings
  | SetFormHasErrors
  | SetSettingsFormHasErrors
  | ResetErrorStatus
  | SetGlobalEnabled;
