import { Row, Button, Modal, Typography, Space, message } from 'antd';
import React, { useState, useCallback, } from 'react';
import styled from 'styled-components';
import { pushToChannelAccount, pushToChannelAccountAll } from '../../services/channels';
import { ChannelAccountEventType } from '../../types/enums';


const { Text, Title } = Typography;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const CardSpace = styled(Space)`
  width: 450px;
`;

const SecondaryText = styled(Text)`
  font-size: 16px;
`;

const ResultDialog: React.FC<{
  account: ChannelAccount;
  selectedRows: any[];
  channelControlFlagNum: number;
  onClose: () => void;
}> = ({ account, onClose, selectedRows, channelControlFlagNum }) => {
  const [selectedLoading, setSelectedLoading] = useState(false)
  const [allLoading, setAllLoading] = useState(false);

  const postPush = useCallback(async () => {
    try {
      setSelectedLoading(true);
      const skus = selectedRows.map((i: any) => i.SKU)
      await pushToChannelAccount(ChannelAccountEventType.OFFER_SYNC, account.ChannelAccountNum, account.ChannelNum, skus);
      message.success('Publish successfully');
      setSelectedLoading(false)
    } catch (error) {
      setSelectedLoading(false)
    }
  }, [selectedRows, account])

  const postPushAll = useCallback(async () => {
    try {
      if (channelControlFlagNum >= 0) {
        setAllLoading(true);
        await pushToChannelAccountAll(ChannelAccountEventType.OFFER_SYNC, account.ChannelAccountNum, account.ChannelNum, channelControlFlagNum);
        message.success('Publish successfully');
        setAllLoading(false)
      } else {
        message.warning('invalid mapping templates')
      }

    } catch (error) {
      setAllLoading(false)
    }
  }, [account, channelControlFlagNum])
  return (
    <Modal
      visible
      width={500}
      closable={false}
      title={
        <Row>
          <Title level={4}>Publish Offer</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
    >
      <CardSpace direction="vertical">
        <SecondaryText strong>
          Channel Account: <Text>{account.ChannelAccountName}</Text>
        </SecondaryText>
        <Row justify='center' style={{ marginTop: 16, marginBottom: 16 }}>
          <Space size="large">
            <Button loading={selectedLoading} disabled={selectedRows.length === 0} onClick={postPush}>Publish Selected</Button>
            <Button loading={allLoading} onClick={postPushAll}>Publish All</Button>
          </Space>
        </Row>
        <Row justify="end">
          <Button type="primary" onClick={() => onClose()}>
            Close
          </Button>
        </Row>
      </CardSpace>
    </Modal>
  );
};

export default ResultDialog;
