import React from 'react';
import {
  Button,
  Input,
  Row,
  Space,
} from 'antd';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Loading from '../../../components/common/Loading';
import ModalDialog from '../../../components/common/ModalDialog';
import ScreenMask from '../../../components/common/ScreenMask';
import { DataGrid } from '../../../components/common/datagrid/DataGrid';
import { LOADING_ICON_SIZE1 } from '../../../constants/config';

const GridWrapper = styled.div`
  & .InovuaReactDataGrid__cell {
    white-space: normal;
  }

  & .InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--selected,
  & .InovuaReactDataGrid__row--odd.InovuaReactDataGrid__row--selected {
    background: #C5CAEA;
  }

  & .search-row {
    margin-bottom: 12px;
  }
`;

type Props = {
  attr?: StringKAnyVPair;
  refreshOptions?: Function;
  onClose: Function;
  onSave: Function;
  openEditor?: Function;
  options?: StringKAnyVPair[];
  title?: string;
  value: any;
  visible: boolean;
};

const TwoColSelectorEditor = (props: Props) => {
  const DEFAULT_GRID_HEIGHT = 300;
  const { useState } = React;
  const [gridStyle, setGridStyle] = useState<StringKAnyVPair>({height: DEFAULT_GRID_HEIGHT});
  const [currentValue, setCurrentValue] = useState<any>(props.value);
  const [lastValue, setLastValue] = useState<any>(props.value);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState<StringKAnyVPair[]>(props.options || []);
  const [searchTimer, setSearchTimer] = useState<any>();
  const [selected, setSelected] = useState<StringKAnyVPair>({});
  const searchInputRef = React.useRef<any>(null);

  const columns = [
    {
      name: 'code',
      header: 'Code',
      minWidth: 150,
    },
    {
      flex: 1,
      header: 'Description',
      name: 'description',
      textEllipsis: false,
    },
  ];

  const getDialogTitle = () => {
    let ret = props.title || 'Selector';

    if (props.attr) {
      if (props.attr.basicAttributeName) {
        ret = props.attr.basicAttributeName;
      }
    }

    return ret;
  };

  const onCloseEditor = () => {
    props.onClose();
  };

  const onFullscreen = (full: boolean) => {
    console.log('on full', full);
    if (full) {
      setGridStyle({height: 'calc(100vh - 188px)'});
    } else {
      setGridStyle({height: DEFAULT_GRID_HEIGHT});
    }
  };

  const onRenderRow = (evt: any) => {
    const row = evt?.ref?.current;

    if (row) {
      row.ondblclick = () => {
        // console.log('dbl click ->', evt.data);
        saveValue(evt.data.code);
      };
    }
  };

  const onSearch = (key: string) => {
    const list = props.options || [];

    if (key) {
      setOptionList(list.filter(e => {
        const code = e.code.toLowerCase();
        const desc = e.description.toLowerCase();
        const k = key.toLowerCase();

        //e.code.toLowerCase().indexOf(key.toLowerCase()) > -1
        return code.indexOf(k) > -1 || desc.indexOf(k) > -1;
      }));
    } else {
      setOptionList(list);
    }

    if (searchTimer) {
      setSearchTimer(null);
      clearTimeout(searchTimer);
    }
  };

  const onSearchChange = (evt: any) => {
    const timer = setTimeout(() => {
      const key = searchInputRef?.current.state.value;

      setSearchTimer(null);

      if (key) {
        onSearch(key);
      }
    }, 150);

    if (searchTimer) clearTimeout(searchTimer);

    setSearchTimer(timer);
  };

  const onSelectionChange = (sel: any) => {
    if (Array.isArray(sel.data) && sel.data.length > 0) {
      const { code } = sel.data[sel.data.length - 1];

      if (code && code !== currentValue) setSelection(code);
    }
  };

  const refreshSelectorList = async () => {
    if (typeof props.refreshOptions === 'function') {
      setIsLoading(true);
      await props.refreshOptions();
      //setOptionList(props.options || []);
      setIsLoading(false);
      onCloseEditor();
      setTimeout(() => {
        if (typeof props.openEditor === 'function') {
          props.openEditor();
        }
      }, 0);
    }
  };

  const saveValue = (value = currentValue) => {
    props.onSave(value);
    setCurrentValue(value);
    setLastValue(value);
    onCloseEditor();
  };

  // eslint-disable-next-line
  const setSelection = (key: any) => {
    setCurrentValue(key);
    setSelected({[key]: true});
  };

  React.useEffect(() => {
    if (props.visible) {
      if (!inited) {
        if (props.value) {
          setSelection(props.value);
        }

        setInited(true);
      }
    }
  }, [
    inited,
    props,
    setSelection,
  ]);

  return (<>
    <ModalDialog
      centered
      closable={false}
      closeButton={true}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              disabled={!lastValue}
              onClick={() => saveValue(null)}
            >
              Remove Selection
            </Button>
          </Space>
          <Space>
            <Button
              onClick={onCloseEditor}
            >
              Cancel
            </Button>
            <Button
              disabled={lastValue === currentValue}
              onClick={() => saveValue()}
              type="primary"
            >
              Save
            </Button>
          </Space>
        </Row>
      }
      footerHeight={54}
      fullscreen={true}
      onClose={onCloseEditor}
      onFullscreen={onFullscreen}
      style={{ paddingBottom: 0 }}
      title={getDialogTitle()}
      titleRightCrumb={<>
        {props.refreshOptions && <Button onClick={refreshSelectorList}>
          Refresh
          <RedoOutlined style={{transform: 'rotate(270deg)'}} />
        </Button>}
      </>}
      visible={props.visible}
      width={700}
    >
      <GridWrapper>
        <Row className="search-row">
          <Input.Search
            allowClear
            enterButton={null}
            onKeyDown={onSearchChange}
            onSearch={onSearch}
            placeholder="Input search code"
            prefix={<SearchOutlined />}
            ref={searchInputRef}
            style={{ width: 300 }}
          />
        </Row>
        <DataGrid
          idProperty="code"
          rowHeight={null}
          columns={columns}
          dataSource={optionList}
          minRowHeight={35}
          onRenderRow={onRenderRow}
          onSelectionChange={onSelectionChange}
          pagination={false}
          selected={selected}
          style={gridStyle}
        />
      </GridWrapper>
    </ModalDialog>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default TwoColSelectorEditor;
