import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import { Button, Col, Form, Modal, Row, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import Heading from '../../components/common/Heading';
import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import ConfirmationModal from '../../components/ConfirmationModal';
import ContentLayout from '../../components/ContentLayout';
import ModalForm from '../../components/Flags/ModalForm';
import SiteContent from '../../components/SiteContent';
import { Permissions, ViewLevel } from '../../constants/enums/permissions';
import notificationActions from '../../redux/actions/notifications';
import { addChannelControlFlags, deleteChannelControlFlags, fetchChannelControlFlags } from '../../services/products';
import {
  channelControlFlagsReducer,
  ChannelControlFlagsScreenContext,
  DispatchAction,
  initialState,
  save,
  StateActions,
} from './context';
import { AddButton } from './styledComponents';
import FormElement, { Type } from '../../components/common/FormElement';

const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
  if (permissions && permissions.size === 0) return false;
  return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
};

const fetchData = async (dispatch: React.Dispatch<DispatchAction>) => {
  dispatch({ type: StateActions.SET_LOADING, params: { value: true } });
  fetchChannelControlFlags()
    .then((response) => dispatch({ type: StateActions.SET_DATA, params: { value: response } }))
    .finally(() => dispatch({ type: StateActions.SET_LOADING, params: { value: false } }));
};

const searchFields: Array<SearchField<Entities.ChannelControlFlag> | string> = ['CHNLCtrlFlag', 'CHNLCtrlFlagDesc', 'CHNLCtrlFlagType', 'CreatedBy'];

const EditModal: React.FC<{ show: boolean; closeModal: () => void; isSaving: boolean; onSave: () => void }> = ({ show, closeModal, isSaving, onSave }) => (
  <Modal
    visible={show}
    onCancel={closeModal}
    title="Edit Channel Control flag"
    closable
    okText="Save"
    footer={(
      <>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={onSave}
          type="primary"
          loading={isSaving}
        >
          Save
        </Button>
      </>
      )}
  >
    <FormElement
      formItemProperties={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        name: ['edit', 'CHNLCtrlFlag'],
        label: 'Flag Name',
        rules: [
          { required: true, message: 'Field required.' },
        ],
      }}
      inputProperties={{
        placeholder: 'Name',
        name: 'CHNLCtrlFlag',
      }}
    />
    <FormElement
      formItemProperties={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        name: ['edit', 'CHNLCtrlFlagDesc'],
        label: 'Description',
        rules: [
          { required: true, message: 'Field required.' },
        ],
      }}
      inputProperties={{
        placeholder: 'Description',
        name: 'CHNLCtrlFlagDesc',
      }}
      inputType={Type.TEXTAREA}
    />
  </Modal>
);

/**
 * Table for record listing.
 */
function PageContent() {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useContext(ChannelControlFlagsScreenContext);
  const [filteredData, setFilteredData] = useState<Entities.ChannelControlFlag[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<Entities.ChannelControlFlag>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const profiles = useSelector((s: any) => s.profiles);
  const canEdit = hasPermissions(profiles.permissions, Permissions.MANAGE_PRODUCT_ELEMENTS, ViewLevel.VIEW);
  const { form } = state || {};

  const openModal = (data:  Entities.ChannelControlFlag) => {
    if (!form) return;
    form.setFieldsValue({
      edit: {
        CHNLCtrlFlag: data.CHNLCtrlFlag,
        CHNLCtrlFlagDesc: data.CHNLCtrlFlagDesc,
      },
    });
    setSelectedRowData(data);
    setShowEditModal(true);
  };
  const closeModal = () => {
    if (!form) return;
    setShowEditModal(false);
    form.resetFields();
  };

  const onDelete = useCallback((data) => {
    if (!dispatch) {
      return;
    }

    dispatch({ type: StateActions.SET_SELECTED_FLAG, params: { value: data } });
    dispatch({ type: StateActions.SET_CONFIRMATION_MODAL, params: { value: true } });
  }, [dispatch]);

  const columns = [
    {
      header: 'Name',
      name: 'CHNLCtrlFlag',
      sortable: true,
      defaultFlex: 2,
    },
    {
      header: 'Description',
      name: 'CHNLCtrlFlagDesc',
      sortable: true,
      defaultFlex: 4,
    },
    {
      header: 'Type',
      name: 'CHNLCtrlFlagType',
      sortable: true,
      defaultFlex: 2,
    },
    {
      header: 'Created By',
      name: 'CreatedBy',
      sortable: true,
      defaultFlex: 3,
    },
    {
      header: 'Product Count',
      name: 'ProductCount',
      sortable: true,
      defaultFlex: 2,
    },
    {
      name: 'ProductCHNLCtrlFlagId',
      header: '',
      sortable: false,
      showColumnMenuTool: false,
      defaultFlex: 3,
      render({ data }: any) {
        return canEdit && (
          <>
            <Button
              size="small"
              disabled={false}
              onClick={() => {openModal(data);}}
            >
              <EditOutlined style={{ color: '#006dff' }} />
              Edit
            </Button>
            <Button
              size="small"
              disabled={false}
              onClick={() => {onDelete(data);}}
            >
              <DeleteOutlined style={{ color: '#c13939' }} />
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  const csvExportColumns: CSVColumns = columns.map(c => ({
    key: c.name,
    header: c.header,
  }));

  const onSave = useCallback(() => {
    if (!dispatch || !selectedRowData || !state || !form) return;
    setIsSaving(true);
    const actions = bindActionCreators(notificationActions, reduxDispatch);
    const payload = {
      ...form.getFieldValue('edit'),
    };

    save(dispatch, payload, selectedRowData.ProductCHNLCtrlFlagId)
      .then(() => actions.setNotification('success', 'Control Flag updated'))
      .catch(() => actions.setNotification('error', 'There was an error'))
      .finally(() => {
        dispatch({ type: StateActions.SET_SEARCH_DISABLED, params: false });
        form.resetFields();
        setIsSaving(false);
        fetchData(dispatch);
        setShowEditModal(false);
      });
  }, [dispatch, form, reduxDispatch, selectedRowData, state]);

  if (!state || !dispatch) {
    return null;
  }

  return (
    <Spin spinning={state.isSaving} wrapperClassName="ant-spin-flex">
      <EditModal
        show={showEditModal}
        onSave={onSave}
        closeModal={closeModal}
        isSaving={isSaving}
      />
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <SearchBar
            reference="ProductCHNLCtrlFlagId"
            data={state.data}
            onResult={setFilteredData}
            fields={searchFields}
            disabled={state.searchDisabled}
          />
        </Col>
        <Col>
          <CSVLink
            filename="channel_control_flags.csv"
            data={filteredData}
            disabled={filteredData.length === 0 || state.searchDisabled}
            columns={csvExportColumns}
          />
        </Col>
      </Row>
      <Spacer height={14} />
      <DataGrid
        idProperty="ProductCHNLCtrlFlagId"
        rowHeight={35}
        loading={state.loading}
        dataSource={filteredData}
        columns={columns}
        pagination
        enableFiltering={false}
      />
    </Spin>
  );
}

/**
 * Channel Control Flags home screen
 *
 */
export default function ChannelControlFlagsScreen() {
  // Redux
  const profiles = useSelector((state: any) => state.profiles);
  const reduxDispatch = useDispatch();
  const reduxActions = bindActionCreators(notificationActions, reduxDispatch);
  const [form] = Form.useForm();

  // Local state
  const reducer = useReducer(
    channelControlFlagsReducer,
    initialState(form),
  );
  const [state, dispatch] = reducer;

  // Event handlers
  const handleModalChange = (e: any) => dispatch({
    type: StateActions.SET_FORM_VALUES,
    params: {
      value: {
        ...state.formValues,
        [e.target.name]: e.target.value,
      },
    },
  });

  const handleAddModalShow = () => {
    dispatch({ type: StateActions.RESET_FORM });
    dispatch({ type: StateActions.SET_ADD_MODAL, params: { value: true } });
  };

  const onModalClose = () => {
    dispatch({ type: StateActions.SET_ADD_MODAL, params: { value: false } });
  };

  const onAddSubmit = async () => {
    const message: string = 'Channel control flag added';
    await addChannelControlFlags(state.formValues);
    dispatch({ type: StateActions.RESET_FORM });
    reduxActions.setNotification('success', message);
    dispatch({ type: StateActions.SET_ADD_MODAL, params: { value: false } });
    await fetchData(dispatch);
  };

  const onDeleteConfirmation = async () => {
    if (!state.selectedFlag) {
      return;
    }

    deleteChannelControlFlags(state.selectedFlag.ProductCHNLCtrlFlagId)
      .then(() => fetchData(dispatch))
      .then(() => reduxActions.setNotification('success', 'Channel Control Flag deleted'));
  };

  useEffect(
    () => {
      fetchData(dispatch).then();
    },
    [dispatch],
  );

  return (
    <ChannelControlFlagsScreenContext.Provider value={reducer}>
      <ContentLayout isModal={false}>
        <ConfirmationModal
          visible={state.confirmationModalVisible}
          title={state.selectedFlag ? `Are you sure you want to delete ${state.selectedFlag.CHNLCtrlFlag}` : ''}
          handleCancel={() => dispatch({ type: StateActions.SET_CONFIRMATION_MODAL, params: { value: false } })}
          handleConfirm={onDeleteConfirmation}
        />
        <ModalForm
          visible={state.addModalVisible}
          formValues={state.formValues}
          handleChange={handleModalChange}
          submit={onAddSubmit}
          handleCancel={onModalClose}
        />
        <Heading
          title="Channel Control Flags"
          actions={(
            <AddButton
              type="primary"
              $hasPermission={hasPermissions(profiles.permissions, Permissions.MANAGE_PRODUCT_ELEMENTS, ViewLevel.VIEW)}
              onClick={handleAddModalShow}
            >
              <PlusOutlined />
              Add
            </AddButton>
          )}
        />
        <Spacer />
        <SiteContent flexGrow>
          <Form form={state.form} component={false}>
            <PageContent />
          </Form>
        </SiteContent>
      </ContentLayout>
    </ChannelControlFlagsScreenContext.Provider>
  );
}
