import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

function ConfirmationModal (props: any) {
  return (
    <Modal
      visible={props.visible}
      style={{ top: 30 }}
      title={
        <TitleHeader id="users_delete_modal_title">Are you sure you want to delete {props.data.UserName} {props.data.Email} ?</TitleHeader>
      }
      onCancel={props.handleCancel}
      width={600}
      footer={[
        <Button id="users_confirmation_modal_cancel_button" key="back" onClick={props.handleCancel}>
          Cancel
        </Button>,
        <Button id="users_confirmation_modal_delete_button"  key="submit" type="primary" danger onClick={() => {props.handleConfirm(); props.handleCancel()}}>
          Delete
        </Button>,
      ]}
    >
      <p>This action cannot be undone and you will be unable to recovery any data.</p>
    </Modal>
  )
}

export default ConfirmationModal;
