import React from 'react';
import {
  Col,
  Image,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  CopyOutlined,
  EyeOutlined,
  FileTextOutlined,
  FormOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
//import EditIcon from '../../assets/images/edit-icon.png';
//import SaveIcon from '../../assets/images/save-icon.png';
import theme from '../../../assets/styles/theme';
import LoadingIcon from '../../../components/common/LoadingIcon';
import message from '../../../components/common/message';
import {
  ATTRIBUTE_DATA_TYPE_DECIMAL,
  ATTRIBUTE_DATA_TYPE_INTEGER,
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  ATTRIBUTE_DATA_TYPE_PRICE,
  ATTRIBUTE_DATA_TYPE_VIRTUAL,
  ATTRIBUTE_NUM_GOOGLE_PRDCAT,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
} from '../../../constants/config';
import { /*saveCopywritingElements, */ saveCopywritingSingleElement } from '../../../services/copywriting';
import Products from '../../../services/products';
import { ProductAttributeDataType } from '../../../types/enums';
import { isImageUrl, isValidHttpUrl, /*isVideoUrl*/ } from '../../../util';
import TextEditor from '../BasicAttrEditor/TextEditor';
import EditImageDialog from '../EditImageDialog';
import { /*loadClassifications,*/ loadAttributeOptions } from '../helper';
import CategorySelector from './CategorySelector';
import VirtualPatternViewer from './VirtualPatternViewer';

const EditorWrapper = styled.span`
  display: inline-block;

  &:hover .cell-value {
    width: calc(100% - 96px);
  }

  &:hover .cell-value3 {
    width: calc(100% - 64px);
  }

  &:hover .cell-value4 {
    width: calc(100% - 128px);
  }

  &:hover .cell-value5 {
    width: calc(100% - 94px);
  }

  &:hover .icon-ctn-disabled {
    display: inline-block;
  }

  & .ant-btn {
    padding: 5px 8px;
  }

  & .ant-input {
    width: calc(100% - 96px);
  }

  & .ant-input-number,
    .ant-select {
    width: calc(100% - 32px);
  }

  & .cell-value,
    .cell-value2,
    .cell-value3,
    .cell-value4,
    .cell-value5,
    .icon-ctn-disabled,
    .icon-ctn-enabled,
    .icon-ctn-text {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .cell-value,
    .cell-value2,
    .cell-value3,
    .cell-value4,
    .cell-value5 {
    padding: 4px 11px;
  }

  & .cell-value,
  & .cell-value3 {
    width: 100%;
  }

  & .cell-value2 {
    width: calc(100% - 64px);
  }

  & .cell-value4,
  & .cell-value5 {
    width: calc(100% - 32px);
  }

  & .cell-input-changed {
    border-color: ${theme['@danger-color']};
  }

  & .cell-input-changed:focus {
    box-shadow: 0 0 0 2px rgba(231, 95, 226, 0.2);
  }

  & .classification-icon,
  & .link-icon {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    font-size: 20px;
    height: 32px;
    padding: 0 4px;
    width: 32px;
  }

  & .editable-readonly-cell {
    background-color: initial;
  }

  & .link-icon {
    /*background-color: #C6F4FF;*/
    color: #111;
  }

  & .link-icon:hover {
    background-color: ${theme['@primary-color']};
    color: #FFF;
  }

  & .icon-ctn-disabled,
    .icon-ctn-enabled,
    .icon-ctn-text {
    cursor: pointer;
    /*padding: 2px 3px;*/
    padding: 5px 7px;
    width: 32px;
  }

  & .icon-ctn-disabled {
    display: none;
  }

  & .icon-ctn-enabled {
    background-color: #FFF;
  }

  .icon-ctn-text {
    cursor: default;
    font-style: italic;
    text-align: center;
  }

  & .icon-ctn-disabled img,
    .icon-ctn-enabled img {
    width: 24px;
    height: 24px;
  }
`;

const PopoverImageWrapper = styled(Col)`
  & .pop-img {
    object-fit: cover;
  }

  & .pop-img-ctn {
    background-color: rgba(0, 0, 0, .65);
    height: 300px;
    width: 200px;
  }
`;

type Props = {
  attr: StringKAnyVPair;
  className?: string;
  dispatch?: Function;
  editable?: boolean;
  productId: string;
  state?: StringKAnyVPair;
};

let optionList: StringKAnyVPair[] = [];
//let sysClassificationList: StringKAnyVPair[] = [];

const AttrEditor = (props: Props) => {
  const { useState } = React;
  //const [classificationDict, setClassificationDict] = useState<StringKAnyVPair>({});
  const [categorySelectorVisible, setCategorySelectorVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState<any>(props.attr.copywritingValue);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  //const [isClassificationReady, setIsClassificationReady] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastValue, setLastValue] = useState<any>(props.attr.copywritingValue);
  const [inited, setInited] = useState(false);
  const [textEditorVisible, setTextEditorVisible] = useState(false);
  const [virtualPatternViewerVisible, setVirtualPatternViewerVisible] = useState(false);

  const attrEditableField = () => {
    const { attr } = props;
    const numTypes = [
      ATTRIBUTE_DATA_TYPE_DECIMAL,
      ATTRIBUTE_DATA_TYPE_INTEGER,
      ATTRIBUTE_DATA_TYPE_PRICE,
    ];
    //const options = optionList.filter(e => e.attributeNum === props.attr.attributeNum);
    const options = attr.optionList;
    //const editorStyle = attr.classificationNums.length > 0 ? { width: 'calc(100% - 64px)' } : {};
    const editorStyle: StringKAnyVPair = {};

    if (options.length > 0) {
      editorStyle.width = 'calc(100% - 64px)';
      return (
        <Select
          defaultValue={currentValue}
          disabled={!isEditable}
          onBlur={onInputBlur}
          onChange={onSelectChange}
          style={editorStyle}
        >
          {options.map((e: string, i: number) => (
            <Select.Option key={`${e}_${i}`} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      const isNumType = numTypes.indexOf(attr.attributeDataType) > -1;

      if (isNumType) {
        return (
          <InputNumber
            className={inputFieldClass()}
            defaultValue={currentValue}
            disabled={!isEditable}
            onBlur={onInputBlur}
            onChange={onNumberInputChange}
            style={editorStyle}
          />
        );
      } else {
        if (isLinkable()) {
          editorStyle.width = 'calc(100% - 128px)';
        }

        return (<>
          {isLinkable() && linkIcon()}
          {richEditIcon()}
          <Input
            className={inputFieldClass()}
            defaultValue={currentValue}
            disabled={!isEditable}
            onBlur={onInputBlur}
            onDoubleClick={openTextEditor}
            onChange={onInputChange}
            style={editorStyle}
          />
        </>);
      }
    }
  };

  const attrReadonlyField = () => {
    const valueFieldClass = () => {
      let cls = props.editable ? 'cell-value' : 'cell-value3';

      if (isSelector()) {
        cls = 'cell-value3';
      } else if (isLinkable()) {
        cls = 'cell-value4';
      } else if (isVirtual()) {
        cls = 'cell-value5';
      }

      const cl = [ cls ];

      if (props.editable === true || props.editable === undefined) {
        cl.push('editable-readonly-cell');
      }

      return cl.join(' ');
    };

    return (<>
      {isLinkable() && linkIcon()}
      {isSelector() ? null : richEditIcon()}
      <span
        className={valueFieldClass()}
        onDoubleClick={openTextEditor}
      >
        <Typography.Text ellipsis={{tooltip: currentValue}}>
          {currentValue}
        </Typography.Text>
      </span>
    </>);
  };

  const attrOpeIcon = () => {
    return (<>
      <CopyToClipboard
        text={copyValue()}
        onCopy={() => notifyCopyMsg()}
      >
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          title="Copy value to clipboard"
        >
          <CopyOutlined />
        </span>
      </CopyToClipboard>
      {props.editable && isSaving && (
        <LoadingIcon />
      )}
      {props.editable && !isSaving && (
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={enterEditMode}
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? '💾' : '🖊️'}
        </span>
      )}
    </>);
  };

  /*const classificationTooltip = () => {
    if (isClassificationReady) {
      const names = props.attr.classificationNums
        .map((num: string) => {
          const cl = classificationDict[num];

          return cl ? cl.ClassificationName : num;
        });

      //return props.attr.classificationNums.join(', ');
      return names.join(', ');
    } else {
      return <LoadingIcon color="white" />;
    }
  };*/

  const closeCategorySelector = () => {
    setCategorySelectorVisible(false);
    setIsEditable(false);
  };

  const closeImageEditor = () => {
    setEditImageDialogVisible(false);
  };

  const closeTextEditor = () => {
    setTextEditorVisible(false);
  };

  const closeVirtualPatternViewer = () => {
    setVirtualPatternViewerVisible(false);
  };

  const copyValue = () => {
    return currentValue === null || currentValue === undefined ? '' : `${currentValue}`.trim();
  };

  const enterEditMode = () => {
    if (isEditable) {
      if (currentValue === lastValue) {
        setIsEditable(false);
      }
    } else {
      setIsEditable(true);
    }

    if (props.attr.copywritingElementNum === ATTRIBUTE_NUM_GOOGLE_PRDCAT) {
      console.log('enter editing', props);
      setCategorySelectorVisible(true);
    }
  };

  const inputFieldClass = () => {
    const cls: string[] = [];

    if (currentValue !== lastValue) {
      cls.push('cell-input-changed');
    }

    return cls.join(' ');
  };

  const isLinkable = () => {
    return (props.attr.attributeDataType === ProductAttributeDataType.PAGEURL) || (typeof currentValue === 'string' && isValidHttpUrl(currentValue));
  };

  const isSelector = () => {
    const { attr } = props;

    return attr.optionList.length > 0;
  };

  const isVirtual = () => {
    return props.attr.elementDataType === ATTRIBUTE_DATA_TYPE_VIRTUAL;
  };

  const linkIcon = () => {
    const previewBtn = (url: string) => {
      let btn = (<a className="link-icon" href={url} target="_blank" rel="noreferrer"><span role="img" aria-label="flag"><LinkOutlined /></span></a>);

      if (isImageUrl(url)) {
        btn = (<Popover className="link-icon" content={renderPopoverImage(url)}><span role="img" aria-label="flag"><EyeOutlined /></span></Popover>);
      }

      return btn;
    };

    return (
      currentValue ?
      previewBtn(currentValue) :
      <span className="classification-icon" role="img" aria-label="flag">
        <LinkOutlined />
      </span>
    );
  };

  const notifyCopyMsg = () => {
    copyValue() ?
      notification.success({message: 'Attribute value has been copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION}) :
      notification.info({message: 'No value can be copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION})
  };

  const onInputBlur = () => {
    if (currentValue !== lastValue) {
      saveAttribute();
    }
  };

  const onInputChange = (evt: any) => {
    // console.log('evt->', evt);
    setCurrentValue(evt.target.value);
  };

  const onNumberInputChange = (value: any) => {
    // console.log('evt->', evt);
    setCurrentValue(value);
  };

  const onSelectChange = (val: any) => {
    console.log('val->', val);
    setCurrentValue(val);
  };

  const openTextEditor = () => {
    const { attr } = props;

    // if optionList is not empty, enter editing mode
    if (attr && typeof attr === 'object') {
      if (Array.isArray(attr.optionList) && attr.optionList.length > 0) {
        setIsEditable(true);
        return;
      }

      if (props.attr.copywritingElementNum === ATTRIBUTE_NUM_GOOGLE_PRDCAT) {
        setCategorySelectorVisible(true);
        return;
      }

      switch (attr.elementDataType) {
        case ATTRIBUTE_DATA_TYPE_IMAGEURL:
          setEditImageDialogVisible(true);
          break;

        case ATTRIBUTE_DATA_TYPE_VIRTUAL:
          setVirtualPatternViewerVisible(true);
          break;

        default:
          console.log('open image editor', attr);
          setTextEditorVisible(true);
      }
    } else {
      setTextEditorVisible(true);
    }
  };

  /*const onTooltipVisibleChange = async (visible: boolean) => {
    if (visible) {
      if (!isClassificationReady) {
        const cls = await loadClassifications();

        if (Array.isArray(cls)) {
          sysClassificationList = cls;
        }

        setIsClassificationReady(true);
      }

      if (Object.keys(classificationDict).length === 0) {
        sysClassificationList.forEach(e => {
          classificationDict[e.ClassificationNum] = e;
        });
        setClassificationDict({...classificationDict});
      }
    }
  };*/

  const renderPopoverImage = (imgUrl: string) => {
    //props.attr
    return (
      <PopoverImageWrapper>
        <Row align="middle" className="pop-img-ctn" justify="center">
          <Image
            className="pop-img"
            src={imgUrl}
          />
        </Row>
      </PopoverImageWrapper>
    );
  };

  const richEditIcon = () => {
    return (<>
        <span
          className={isEditable ? 'icon-ctn-enabled' : 'icon-ctn-disabled'}
          onClick={openTextEditor}
          title="Edit in a popup window"
        >
          {/*<img alt="" src={isEditable ? SaveIcon : EditIcon} />*/}
          {isEditable ? <FormOutlined /> : <FileTextOutlined />}
        </span>
    </>);
  };

  const saveAttribute = async (value = currentValue) => {
    console.log('ready save', value, props.attr, props.productId);
    setIsSaving(true);

    try {
      const overwrite = props.state?.overwriteChildren;
      const { ProductBasic: { SKU } } = await Products.fetchSingleProductById(props.productId);
      //console.log('sku ->', SKU);

      // eslint-disable-next-line
      if (!SKU) throw 'No SKU found for this product';
      /*await saveCopywritingElements(props.productId, [{
        CopywritingElementNum: props.attr.copywritingElementNum,
        CopywritingValue: currentValue,
      }]);*/
      await saveCopywritingSingleElement(props.productId,
        SKU,
        props.attr.copywritingElementNum,
        {
          CopywritingElementNum: props.attr.copywritingElementNum,
          CopywritingValue: value,
          applyWithBlank: overwrite === 2 ? 1 : 0,
          overwrite: overwrite ? true : false,
        }
      );
      setCurrentValue(value);
      setLastValue(value);
      setIsEditable(false);
      props.attr.copywritingValue = value;
      message.success(`Saved ${props.attr.elementName} successfully`);
    } catch(e) {
      message.error(`Saved ${props.attr.elementName} error: ${e}`);
    } finally {
      setIsSaving(false);
    }
  };

  const saveAttributeByEditor = async (value = currentValue, isOverwrite = 0) => {
    setIsSaving(true);

    try {
      const { ProductBasic: { SKU } } = await Products.fetchSingleProductById(props.productId);
      // eslint-disable-next-line
      if (!SKU) throw 'No SKU found for this product';

      await saveCopywritingSingleElement(props.productId,
        SKU,
        props.attr.copywritingElementNum,
        {
          CopywritingElementNum: props.attr.copywritingElementNum,
          CopywritingValue: value,
          applyWithBlank: isOverwrite === 2 ? 1 : 0,
          overwrite: isOverwrite ? true : false,
        }
      );
      setCurrentValue(value);
      setLastValue(value);
      setIsEditable(false);
      props.attr.copywritingValue = value;
      message.success(`Saved ${props.attr.elementName} successfully`);

      if (props.attr.elementDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL) {
        closeImageEditor();
      }
    } catch(e) {
      message.error(`Saved ${props.attr.elementName} error: ${e}`);
    } finally {
      setIsSaving(false);
    }
  };

  // eslint-disable-next-line
  const setOptions = async () => {
    const options = await loadAttributeOptions();

    if (Array.isArray(options)) {
      // console.log('opt->', options);
      optionList = options;
    }
  };

  React.useEffect(() => {
    if (!inited) {
      //loadAttrClassifications();
      if (optionList.length === 0) {
        //setOptions();
      }

      setInited(true);
    }
  }, [inited]);

  return (<>
    <EditorWrapper className={props.className || ''}>
      <Input.Group compact>
        {/*props.attr.classificationNums.length > 0 && (
          <Tooltip
            title={classificationTooltip()}
            onVisibleChange={onTooltipVisibleChange}
          >
            <span className="classification-icon" role="img" aria-label="flag">🇧🇶</span>
          </Tooltip>
        )*/}
        {isEditable ? attrEditableField() : attrReadonlyField()}
        {attrOpeIcon()}
        {isVirtual() && <span className="icon-ctn-text">
          <Tooltip title="Virtual">V</Tooltip>
        </span>}
      </Input.Group>
    </EditorWrapper>
    {editImageDialogVisible && (
      <EditImageDialog
        dispatch={props.dispatch}
        imageUrl={props.attr.copywritingValue || ''}
        onClose={closeImageEditor}
        onSave={saveAttributeByEditor}
        overwriteEnable={props.state && props.state.productType !== 1}
        state={props.state}
        title={`Edit ${props.attr.elementName}`}
        visible={editImageDialogVisible}
      />
    )}
    {textEditorVisible && <TextEditor
      productId={props.productId}
      dispatch={props.dispatch}
      editable={isEditable}
      onClose={closeTextEditor}
      onSave={saveAttributeByEditor}
      readonly={!props.editable}
      state={props.state}
      textContent={props.attr.copywritingValue}
      title={`${props.attr.elementName}`}
      visible={textEditorVisible}
    />}
    {categorySelectorVisible && <CategorySelector
      attr={props.attr}
      onClose={closeCategorySelector}
      onSave={saveAttributeByEditor}
      visible={categorySelectorVisible}
    />}
    {virtualPatternViewerVisible && <VirtualPatternViewer
      attr={props.attr}
      onClose={closeVirtualPatternViewer}
      visible={virtualPatternViewerVisible}
    />}
  </>);
};

export default AttrEditor;
