import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { Item } from '../../screens/UserManagement';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputLimit?: number,
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const inputStyle = {
  margin: 0,
  borderRadius: '4px',
  height: '38px',
  border:' solid 1.5px #006dff',
};

const EditableCell: React.FC<EditableCellProps> = ({
 editing,
 dataIndex,
 title,
 inputLimit,
 inputType,
 record,
 index,
 children,
 ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber style={inputStyle}/> : <Input maxLength={inputLimit} style={inputStyle} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
