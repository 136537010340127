import { pmfAPI } from "./http";

export const postProductMediaFile = async (formData: FormData) => await pmfAPI.post('/productmediafile/ccfile', formData);


type ProductMediaFileSettingsRequest = {
  pmfSettingNum: number,
  connectionStringName: string,
  connectionString: string,
  serviceProvider: number,    // default 1
  settingExporession: {
    defaultContainer: string,
    enableVirtualFolder: number
  }
}

// type ProductMediaFileSettingsResponse = {
//   pmfSettingNum: number,
//   connectionStringName: string,
//   connectionString: string,
//   serviceProvider: number,    // default 1
//   settingExporession: {
//     defaultContainer: string,
//     enableVirtualFolder: number
//   }
// }

export const fetchProductMediaFileSettings = async (): Promise<any> => pmfAPI.get('/productmediafile/setting');

// export const addProductMediaFileSettings = async (params: ProductMediaFileSettingsRequest): Promise<any> => pmfAPI.patch('/productmediafile/setting', JSON.stringify(params));

export const updateProductMediaFileSettings = async (params: ProductMediaFileSettingsRequest): Promise<any> => pmfAPI.post('/productmediafile/setting', JSON.stringify(params));

export const saveConnectString = async (params: { connectionStringName: string, connectionString: string }): Promise<any> => pmfAPI.post('/productmediafile/connectionsetting', JSON.stringify(params));