import React from "react";

const Icon = ({ width = '1.8em', height = '1.8em', stroke = "white", fill = "none", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...other}
    >
      <path
        d="M13.126 15h-2.251a.614.614 0 0 1-.604-.472l-.252-1.13a4.983 4.983 0 0 1-.95-.532l-1.135.35a.627.627 0 0 1-.724-.271l-1.128-1.89a.59.59 0 0 1 .12-.744l.88-.78c-.04-.353-.04-.71 0-1.063l-.88-.778a.59.59 0 0 1-.12-.744l1.126-1.892a.627.627 0 0 1 .723-.272l1.136.351a5.01 5.01 0 0 1 .47-.3c.157-.085.317-.163.482-.232l.252-1.129A.614.614 0 0 1 10.875 3h2.251c.29 0 .542.197.604.472l.254 1.13a5.019 5.019 0 0 1 .95.532l1.136-.35a.627.627 0 0 1 .723.271l1.125 1.892a.59.59 0 0 1-.12.743l-.88.78c.04.354.04.71 0 1.063l.88.78a.59.59 0 0 1 .12.744l-1.125 1.892a.627.627 0 0 1-.723.271l-1.137-.35a4.963 4.963 0 0 1-.949.531l-.254 1.127a.615.615 0 0 1-.604.472Zm-3.834-3.463.507.36a3.895 3.895 0 0 0 .715.403l.577.245.282 1.255h1.256l.282-1.255.577-.246c.252-.107.491-.241.713-.4l.508-.36 1.262.39.628-1.054-.979-.866.07-.607c.03-.266.03-.534 0-.8l-.07-.606.98-.868-.629-1.055-1.262.39-.508-.36a3.882 3.882 0 0 0-.713-.403l-.577-.245L12.63 4.2h-1.256l-.284 1.255-.575.245a3.72 3.72 0 0 0-.713.4l-.508.36-1.262-.39-.629 1.058.98.865-.07.607a3.51 3.51 0 0 0 0 .8l.07.607-.98.866.628 1.054 1.262-.39Zm2.706-.137c-1.366 0-2.474-1.075-2.474-2.4s1.108-2.4 2.474-2.4 2.474 1.075 2.474 2.4c-.002 1.325-1.108 2.398-2.474 2.4Zm0-3.6c-.676 0-1.226.527-1.236 1.183-.01.655.525 1.197 1.2 1.216.676.02 1.242-.49 1.273-1.145v.24V9c0-.663-.554-1.2-1.237-1.2Z"
        fill={stroke}
      />

      <path
        d="M7.202 12h-1.82c-.596 0-1.191.596-1.191 1.191v5.559m0 0c-.596 0-1.191.467-1.191 1.125C3 20.533 3.533 21 4.19 21c.658 0 1.191-.467 1.191-1.125 0-.658-.595-1.125-1.19-1.125Zm7.774-3.772v3.772m0 0c-.595 0-1.19.467-1.19 1.125 0 .658.532 1.125 1.19 1.125.658 0 1.19-.467 1.19-1.125 0-.658-.595-1.125-1.19-1.125ZM16.728 12h1.89c.596 0 1.191.596 1.191 1.094v5.656m0 0c-.595 0-1.19.467-1.19 1.125 0 .658.533 1.125 1.19 1.125.658 0 1.191-.467 1.191-1.125 0-.658-.595-1.125-1.19-1.125Z"
        stroke={stroke}
      />
    </svg>
  );
};
export default React.memo(Icon);
