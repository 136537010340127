import React from 'react';
import { Select } from 'antd';
import LoadingIcon from '../../components/common/LoadingIcon';
import message from '../../components/common/message';
import { fetchRoles } from '../../services/userManagement';

type Props = {
  disabled?: boolean;
  onSelectRole: Function;
};

let sysRoleList: StringKAnyVPair[] = [];

const UserRoleSelector = (props: Props) => {
  const { useState, useEffect } = React;
  const { Option } = Select;
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState<StringKAnyVPair[]>([]);

  const loadRoles = async () => {
    if (sysRoleList.length > 0) {
      setRoleList(sysRoleList);
      return;
    }

    setIsLoading(true);

    try {
      const roles = await fetchRoles();

      // console.log('r->', roles);
      if (roles && Array.isArray(roles)) {
        setRoleList(roles);
        sysRoleList = roles;
      } else {
        message.error('No system roles returned');
      }
    } catch(e) {
      message.error(`Fetch roles error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectRole = (value: any) => {
    if (typeof value === 'number') {
      const roles = roleList.filter(e => e.rowNum === value);

      if (roles && roles.length > 0) {
        // console.log('r->', roles);
        props.onSelectRole(roles[0]);
      }
    }
  };

  const roleOptions = (roles: StringKAnyVPair[]) => {
    return roles.map(e => (
      <Option key={e.rowNum} value={e.rowNum}>
        {e.roleName}
      </Option>
    ));
  };

  useEffect(() => {
    if (!inited) {
      loadRoles();
      setInited(true);
    }
  }, [inited]);

  return (<>
    {isLoading ? (
      <LoadingIcon />
    ) : (
      <Select
        disabled={props.disabled || false}
        onChange={onSelectRole}
        placeholder="Select a Role Template"
        style={{width: 200}}
      >
        {roleOptions(roleList)}
      </Select>
    )}
  </>);
};

export default UserRoleSelector;
