import React from "react";

const Icon = ({ width = '1.8em', height = '1.8em', stroke = "white", fill = "currentColor", ...other }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -3 24 24"
      fill={fill}
      {...other}
    >
      <path d="M5.62496 16.125C4.46555 16.1201 3.4449 15.3596 3.10871 14.25H1.49996C1.07264 13.6385 1.10533 13.3137 1.49996 12.75H3.10871C3.49489 11.4547 4.80149 10.6644 6.12798 10.9238C7.45448 11.1831 8.36721 12.4074 8.2371 13.7527C8.10699 15.098 6.97657 16.1246 5.62496 16.125ZM5.62512 12.375C5.0038 12.375 4.49996 12.8787 4.49996 13.5C4.49996 14.1213 5.00364 14.625 5.62496 14.625C6.24628 14.625 6.74996 14.1213 6.74996 13.5C6.74996 12.8787 6.24644 12.375 5.62512 12.375ZM16.5 14.25H8.99996V12.75H16.5C16.9294 13.3347 16.9079 13.665 16.5 14.25ZM12.375 11.625C11.0233 11.6246 9.89294 10.598 9.76283 9.25269C9.63271 7.90735 10.5454 6.68313 11.8719 6.42378C13.1984 6.16442 14.505 6.95473 14.8912 8.25H16.5C16.9804 8.84279 16.9799 9.1712 16.5 9.75H14.8912C14.555 10.8596 13.5344 11.6201 12.375 11.625ZM12.375 7.875C11.7536 7.875 11.25 8.37868 11.25 9C11.25 9.62132 11.7536 10.125 12.375 10.125C12.9963 10.125 13.5 9.62132 13.5 9C13.5 8.37868 12.9963 7.875 12.375 7.875ZM8.99996 9.75H1.49996C1.05099 9.18652 1.0746 8.85968 1.49996 8.25H8.99996V9.75ZM7.12496 7.125C5.77335 7.12465 4.64294 6.09802 4.51282 4.75269C4.38271 3.40735 5.29544 2.18313 6.62194 1.92378C7.94843 1.66442 9.25504 2.45473 9.64121 3.75H16.5C16.9573 4.29744 16.9662 4.62509 16.5 5.25H9.64121C9.30481 6.35946 8.28429 7.1199 7.12496 7.125ZM7.12496 3.375C6.51144 3.37583 6.01172 3.86807 6.00163 4.4815C5.99155 5.09494 6.47482 5.60334 7.08798 5.62434C7.70114 5.64533 8.21806 5.17118 8.24996 4.5585V4.776V4.5C8.24996 3.87868 7.74628 3.375 7.12496 3.375ZM3.74996 5.25H1.49996C1.02603 4.66186 1.0576 4.33834 1.49996 3.75H3.74996V5.25Z" fill=""></path>
    </svg>
  );
};

export default React.memo(Icon);
