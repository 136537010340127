import React, { useMemo } from 'react';
import {
  Alert,
  Checkbox,
  Typography,
  notification,
  Button,
  Space
} from 'antd';
//import { FormLabel } from '../../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
} from '../../../constants/config';
import { fetchCopywritingelements } from '../../../services/copywriting';
//import AttrList from './AttrList';
import TableFilter from '../../../components/common/TableFilter'

type Props = {
  focused: boolean;
  maskScreen: Function;
  //productId: string;
  setSelectedAttributes?: Function;
};

const FieldSelector = (props: Props) => {
  const { useState } = React;
  const [attrList, setAttrList] = useState<StringKAnyVPair[]>([]);
  const [filteredData, setFilteredData] = useState<StringKAnyVPair[]>([])
  const [attrListVersion, setAttrListVersion] = useState(0);
  const [attrLoaded, setAttrLoaded] = useState(false);
  const [inited, setInited] = useState(false);
  const [selectedAttrs, setSelectedAttrs] = useState<number[]>([]);
  const style = {
    display: props.focused ? '' : 'none',
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    props.maskScreen(true);
    //console.log('pp id->', props.productId);
    try {
      const res = await fetchCopywritingelements();

      console.log('res->', res);
      if (res && typeof res === 'object') {
        const { data } = res;

        if (Array.isArray(data)) {
          setAttrList(data);
          setAttrListVersion(attrListVersion + 1);
        }
      }
    } catch(e) {
      notification.error({
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        message: `Fetch content resources attributes error: ${e}`,
      });
    } finally {
      setAttrLoaded(true);
      props.maskScreen(false);
    }
  };

  const onSelectAttribute = (attr: StringKAnyVPair) => {
    const idx = selectedAttrs.indexOf(attr.copywritingElementNum);
    //console.log('sel', attr);
    if (idx > -1) {
      selectedAttrs.splice(idx, 1);
    } else {
      selectedAttrs.push(attr.copywritingElementNum);
    }

    setSelectedAttrs([...selectedAttrs]);

    if (typeof props.setSelectedAttributes === 'function') {
      props.setSelectedAttributes([...selectedAttrs]);
    }
  };

  const isAllItemSelected = useMemo(() => {
    return selectedAttrs.length === attrList.length;
  }, [selectedAttrs, attrList]);

  const isNoItemSelected = useMemo(() => {
    return selectedAttrs.length === 0;
  }, [selectedAttrs]);

  const selectAllItems = () => {
    // add all attribute copyWritingElementNums
    const allList: any[] = [];
    attrList.forEach((item) => {
      if (item.copywritingElementNum) {
        allList.push(item.copywritingElementNum);
      }
    });
    setSelectedAttrs(allList);
    if (typeof props.setSelectedAttributes === 'function') {
      props.setSelectedAttributes(allList);
    }
  };

  const unSelectAllItems = () => {
    // remove all attribute copyWritingElementNums
    setSelectedAttrs([]);
    if (typeof props.setSelectedAttributes === 'function') {
      props.setSelectedAttributes([]);
    }
  };

  React.useEffect(() => {
    if (props.focused && !inited) {
      loadInitialData();
      setInited(true);
    }
  }, [inited, loadInitialData, props]);

  return (<>
    <div className="dest-step-wrapper" style={style}>
      {attrLoaded && attrList.length === 0 && (
        <Alert message="No attribute found" type="error" />
      )}
      {/*<AttrList
        list={attrList}
        productId={''}
        selectable
        setSelectedAttributes={props.setSelectedAttributes}
        version={attrListVersion}
      />*/}
      <div>
      <Space>
        <Button
          disabled={isAllItemSelected}
          onClick={selectAllItems}
        >
          Select All
        </Button>
        <Button
          disabled={isNoItemSelected}
          onClick={unSelectAllItems}
        >
          Unselect All
        </Button>
        <TableFilter size="middle" columns={[{name:'elementName'}]} dataSource={attrList} setFilteredData={setFilteredData}   />
      </Space>
      </div>
      {filteredData.map(e => (
        <span
          className="attr-cell label"
          key={e.copywritingElementNum}
        >
          <span
            className="attr-label full-width form-label"
            onClick={() => onSelectAttribute(e)}
          >
            <Checkbox
              //disabled={!e.copywritingValue}
              value={e.copywritingElementNum}
              checked={selectedAttrs.indexOf(e.copywritingElementNum) > -1}
            />
            <Typography.Text ellipsis={{tooltip: e.elementName}}>
              {e.elementName}
            </Typography.Text>
          </span>
        </span>
      ))}
    </div>
  </>);
};

export default FieldSelector;
