import React from 'react';
import { Row, Select } from 'antd';

type Props = {
  className?: string;
  current?: StringKAnyVPair;
  onChange?: Function;
  options: StringKAnyVPair[];
};

const AddSettingSelector = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<number>();

  const onChange = (val: any) => {
    //console.log('ch->', val);
    const opts = props.options.filter(e => e.SettingNum === val);

    if (opts.length > 0) {
      //console.log('opts->', opts);
      if (typeof props.onChange === 'function') {
        props.onChange(opts[0]);
      }
    }
  };

  const settingOptions = () => {
    return props.options.map(e => (
      <Select.Option
        key={`setting_${e.SettingNum}`}
        value={e.SettingNum}
      >
        {e.SettingDescription}
      </Select.Option>
    ));
  };

  React.useEffect(() => {
    if (props.current) {
      const { SettingNum: settingNum } = props.current;

      if (props.current.SettingNum !== currentValue) {
        setCurrentValue(settingNum);
      }
    }
  }, [currentValue, props]);

  return (<>
    <Row className={props.className || ''}>
      <Select
        onChange={onChange}
        style={{
          width: '100%',
        }}
        value={currentValue}
      >
        {settingOptions()}
      </Select>
    </Row>
  </>);
};

export default AddSettingSelector;
