import React from 'react';
import { Select } from 'antd';

type Props = {
  onChange?: Function;
  style?: StringKAnyVPair;
  defaultValue?: string;
};

const options = [
  {
    label: 'SKU Ascending',
    value: 'SKU ASC',
  },
  {
    label: 'SKU Descending',
    value: 'SKU DESC',
  },
  {
    label: 'Last Update Descending',
    value: 'LastUpdate DESC',
  },
];

const SortSelector = (props: Props) => {
  const style = props.style || {width: '100%'};

  const onSelectionChange = (value: any) => {
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  };

  const selectorOptions = () => {
    return options.map(e => (
      <Select.Option key={e.value} value={e.value}>
        {e.label}
      </Select.Option>
    ));
  };

  return (
    <Select
      //allowClear
      defaultValue={props.defaultValue || "SKU ASC"}
      onChange={onSelectionChange}
      placeholder="Please select a sort type"
      style={style}
    >
      {selectorOptions()}
    </Select>
  );
};

export default SortSelector;
