import message from '../../components/common/message';
import {
  HTTP_STATUS_OK,
  LSK_ALL_PRD_ATTR,
  LSK_CHANNEL_FLAGS,
  LSK_PRD_ATTR_CHANNELS,
  LSK_PRD_ATTR_OPTIONS,
  LSK_PRD_ATTR_SET,
  LSK_PRD_BASIC_ATTRS,
  LSK_PRD_BASIC_ENUM,
  LSK_PRD_CLASSIFICTIONS,
  LSK_PRD_LABELS,
  LSK_STYLE_VARIATION,
  LSK_PRD_ACT_ATTR_CHANNELS,
} from '../../constants/config'; 
import Products, { fetchProductAttributeSets } from '../../services/products';
import { getCacheItem, setCacheItem } from '../../util';

export const clearCachedData = () => {
  const localStorage = window.localStorage;

  localStorage.removeItem(LSK_ALL_PRD_ATTR);
  localStorage.removeItem(LSK_CHANNEL_FLAGS);
  localStorage.removeItem(LSK_PRD_ATTR_CHANNELS);
  localStorage.removeItem(LSK_PRD_ATTR_OPTIONS);
  localStorage.removeItem(LSK_PRD_ATTR_SET);
  localStorage.removeItem(LSK_PRD_BASIC_ATTRS);
  localStorage.removeItem(LSK_PRD_BASIC_ENUM);
  localStorage.removeItem(LSK_PRD_CLASSIFICTIONS);
  localStorage.removeItem(LSK_PRD_LABELS);
  localStorage.removeItem(LSK_STYLE_VARIATION);
  localStorage.removeItem(LSK_PRD_ACT_ATTR_CHANNELS);
};

export const getProductType = (pt: number) => {
  let ret = '';

  switch (pt) {
    case 1:
      ret = 'Product';
      break;

     case 2:
      ret = 'Style';
      break;

    case 3:
      ret = 'Substyle';
      break;
  }

  return ret;
};

export const loadAttributeOptions = async () => {
  const attrs = getCacheItem(LSK_PRD_ATTR_OPTIONS);

  if (attrs) {
    return attrs;
  } else {
    try {
      const options = await Products.getAttributeOptions();

      if (Array.isArray(options)) {
        setCacheItem(LSK_PRD_ATTR_OPTIONS, options);

        return options;
      }
    } catch(e) {
      message.error(`Fetch attribute options error: ${e}`);
    }
  }
};

export const loadAttributeSet = async () => {
  const attrs = getCacheItem(LSK_PRD_ATTR_SET);

  if (attrs) {
    return attrs;
  } else {
    try {
      const attributes = await fetchProductAttributeSets({});

      if (Array.isArray(attributes)) {
        setCacheItem(LSK_PRD_ATTR_SET, attributes);

        return attributes;
      }
    } catch(e) {
      message.error(`Fetch operation set error: ${e}`);
    }
  }
};

export const loadAttributes = async () => {
  const attrs = getCacheItem(LSK_ALL_PRD_ATTR);

  if (attrs) {
    return attrs;
  } else {
    try {
      const attributes = await Products.getAllAttributes();

      if (Array.isArray(attributes)) {
        setCacheItem(LSK_ALL_PRD_ATTR, attributes);

        return attributes;
      }
    } catch(e) {
      message.error(`Fetch attributes error: ${e}`);
    }
  }
};

export const loadAttributesChannels = async () => {
  const attrs = getCacheItem(LSK_PRD_ATTR_CHANNELS);

  if (attrs) {
    return attrs;
  } else {
    try {
      const ac = await Products.getAccountEnabled();

      if (Array.isArray(ac)) {
        setCacheItem(LSK_PRD_ATTR_CHANNELS, ac);

        return ac;
      }
    } catch(e) {
      message.error(`Fetch attribute channels error: ${e}`);
    }
  }
};

export const loadProfileAccountAttributesChannels = async () => {
  const attrs = getCacheItem(LSK_PRD_ACT_ATTR_CHANNELS);

  if (attrs) {
    return attrs;
  } else {
    try {
      const ac = await Products.getProfileAccountEnabled();

      if (Array.isArray(ac)) {
        setCacheItem(LSK_PRD_ACT_ATTR_CHANNELS, ac);

        return ac;
      }
    } catch(e) {
      message.error(`Fetch attribute channels error: ${e}`);
    }
  }
};

export const loadBasicEnumDef = async () => {
  const cls = getCacheItem(LSK_PRD_BASIC_ENUM);

  if (cls) {
    return cls;
  } else {
    try {
      const enums = await Products.fetchBasicEnumDef();

      if (enums && typeof enums === 'object') {
        setCacheItem(LSK_PRD_BASIC_ENUM, enums);

        return enums;
      }
    } catch(e) {
      message.error(`Fetch basic enum definition error: ${e}`);
    }
  }
};

export const loadChannelControlFlags = async () => {
  const cls = getCacheItem(LSK_CHANNEL_FLAGS);

  if (cls) {
    return cls;
  } else {
    try {
      const flags = await Products.getChannelControlFlags();

      if (Array.isArray(flags)) {
        setCacheItem(LSK_CHANNEL_FLAGS, flags);

        return flags;
      }
    } catch(e) {
      message.error(`Fetch channel flags error: ${e}`);
    }
  }
};

export const loadClassifications = async () => {
  const cls = getCacheItem(LSK_PRD_CLASSIFICTIONS);

  if (cls) {
    return cls;
  } else {
    try {
      const classifications = await Products.getClassifications();

      if (Array.isArray(classifications)) {
        setCacheItem(LSK_PRD_CLASSIFICTIONS, classifications);

        return classifications;
      }
    } catch(e) {
      message.error(`Fetch classifictions error: ${e}`);
    }
  }
};

export const loadProductBasicAttrs = async () => {
  const cls = getCacheItem(LSK_PRD_BASIC_ATTRS);

  if (cls) {
    return cls;
  } else {
    try {
      const attrs = await Products.getBasicAttrs();

      if (Array.isArray(attrs)) {
        setCacheItem(LSK_PRD_BASIC_ATTRS, attrs);

        return attrs;
      }
    } catch(e) {
      message.error(`Fetch basic attributes error: ${e}`);
    }
  }
};

export const loadProductLabels = async () => {
  const cls = getCacheItem(LSK_PRD_LABELS);

  if (cls) {
    return cls;
  } else {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (res && typeof res === 'object') {
        const { LabelList } = res;

        if (LabelList && Array.isArray(LabelList)) {
          setCacheItem(LSK_PRD_LABELS, LabelList);

          return LabelList;
        }
      }
    } catch(e) {
      message.error(`Fetch labels error: ${e}`);
    }
  }
};

export const loadStyleVariations = async (ignoreCache = false) => {
  const cls = getCacheItem(LSK_STYLE_VARIATION);

  if (cls && !ignoreCache) {
    return cls;
  } else {
    try {
      const res = await Products.fetchStyleVariations2();

      if (res && typeof res === 'object') {
        if (res.status === HTTP_STATUS_OK) {
          const { styleVariation } = res.data;

          if (styleVariation && typeof styleVariation === 'object') {
            setCacheItem(LSK_STYLE_VARIATION, styleVariation);

            return styleVariation;
          } else {
            const { messages } = res.data;

            if (Array.isArray(messages) && messages.length > 0) {
              const { Message } = messages[0];

              throw Message || 'no data returned';
            }
          }
        }
      }
    } catch(e) {
      message.error(`Fetch style variation error: ${e}`);
    }
  }
};


