import React from 'react';
import { Input, Row } from 'antd';
import { FilterOutlined, FilterTwoTone } from '@ant-design/icons';
//import ImagePlaceholder from '../../../assets/images/preview_image.jpeg';
import Spacer from '../../../components/common/Spacer';
import Loading from '../../../components/common/Loading';
import ScreenMask from '../../../components/common/ScreenMask';
//import Spacer from '../../../components/common/Spacer';
import { FormLabel } from '../../../components/common/styledComponents';
import message from '../../../components/common/message';
import {
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { TabPanelContainer } from '../styledComponents';
import Products from '../../../services/products';
//import CategoryPathEditor from '../BasicAttrEditor/CategoryPathEditor';
import CommonSelectEditor from '../BasicAttrEditor/CommonSelectEditor';
//import CommonSelectEditor1 from '../BasicAttrEditor/SelectWithCheckbox'; // Components only support type 'channel'
import SalesChannelEditor from '../BasicAttrEditor/SalesChannelEditor';
import BasicAttrEditor, {
  //ATTR_CATEGORY_BASIC,
  ATTR_CATEGORY_OPERATION,
  BASIC_ATTR_TYPE_NUMBER,
  BASIC_ATTR_TYPE_STRING,
  getOperationStatusOptions,
} from '../BasicAttrEditor';
//import ReadonlyCell from '../BasicAttrEditor/ReadonlyCell';
import { loadProductBasicAttrs, loadStyleVariations } from '../helper';

type Props = {
  dispatch?: Function;
  editOnly?: boolean;
  editSave?: number;
  focused: boolean;
  isCreating: boolean;
  loadChannelControlFlags: Function;
  loadClassifications: Function;
  loadLabels: Function;
  onContentChanged?: Function;
  onSaved?: Function;
  productId?: string;
  state?: StringKAnyVPair;
};

const Operation = (props: Props) => {
  const FORM_ITEM_CLS = 'form-item';
  const FORM_LABEL_CLS = 'form-label';
  const FORM_SECTION_CLS = 'form-section';
  const { useState } = React;
  const [attrDict, setAttrDict] = useState<StringKAnyVPair>({});
  const [basicInfo, setBasicInfo] = useState<StringKAnyVPair>({});
  const [basicInfoReady, setBasicInfoReady] = useState(false);
  // eslint-disable-next-line
  const [currentCategoryRoot, setCurrentCategoryRoot] = useState<any>();
  const [inited, setInited] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [saveCount, setSaveCount] = useState(props.editSave || 0);
  const [searchTimer, setSearchTimer] = useState<number>();
  const [currentProductId, setProductId] = useState(props.productId);
  // eslint-disable-next-line
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const attrSearchInputRef = React.useRef<any>(null);
  const pageRef = React.useRef<any>(null);
  //const [isLoadingProductData, setIsLoadingProductData] = useState(false);

  /*const getStyleCodeBySku = (sku: string = '') => {
    const fields = sku.split('-');

    return fields[0] || '';
  };*/

  // eslint-disable-next-line
  const attrValue = (key: string) => {
    if (attrDict[key] && typeof attrDict[key] === 'object') {
      switch(key) {
        case 'ProductClassifications':
          return isNaN(parseInt(attrDict[key].value)) ? undefined : parseInt(attrDict[key].value);

        default:
          return attrDict[key].value;
      }
    }
  };

  // eslint-disable-next-line
  const isSomeValueChanged = () => {
    let ret = false;

    for (let k in attrDict) {
      if (attrDict[k].valueChanged) {
        ret = true;
        break;
      }
    }

    return ret;
  };

  const loadAttrData = async (
    productId: string,
    aDict: StringKAnyVPair,
  ) => {
    try {
      const res = await Products.getStyleMaster2(productId);

      if (res && typeof res === 'object') {
        const {
          channelControlFlagIdList,
          labelIdList,
          productBasic,
          productBasicInfoAttributeList,
          productOperation,
        } = res;
        let info: StringKAnyVPair = {};
        console.log('attr data ->', res);
        if (productBasic && typeof productBasic === 'object') {
          console.log('pdb->', productBasic);
          info = { ...productBasic };
        }

        if (Array.isArray(productBasicInfoAttributeList)) {
          productBasicInfoAttributeList.forEach(e => {
            const k = e.attributeName ? e.attributeName.trim() : '';

            if (k && aDict[k]) {
              aDict[k].value = e.value;
              //console.log(k, aDict[k]);
            }
          });
        }

        if (productOperation && typeof productOperation === 'object') {
          basicInfo.Operation = productOperation;
        }

        basicInfo.LabelList = Array.isArray(labelIdList) ? labelIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        basicInfo.FlagList = Array.isArray(channelControlFlagIdList) ? channelControlFlagIdList.map((e: StringKAnyVPair) => e.elementId) : undefined;
        basicInfo.FlagList1 = Array.isArray(channelControlFlagIdList) ? channelControlFlagIdList : undefined;
        //console.log('-->', labelIdList, basicInfo);
        setBasicInfo({
          ...info,
          ...basicInfo,
        });
        console.log('info', info);
      }
    } catch(e) {
      message.error(`Fetch product style master data error: ${e}`);
    } finally {
    }
  };

  const loadBasicAttrs = async () => {
    setIsLoading(true);

    const attrs = await loadProductBasicAttrs();

    //console.log('attrs ->', attrs);
    if (Array.isArray(attrs)) {
      const dict: StringKAnyVPair = {};

      //attrs.forEach(e => dict[e.basicAttributeId] = e);
      //attrs.forEach(e => dict[e.basicAttributeName] = e);
      attrs.forEach(e => {
        e.fieldName = e.fieldName.trim();
        dict[e.fieldName] = e;
      });

      if (props.productId) {
        await loadAttrData(props.productId, dict);
      }

      console.log('attr dict', dict);
      setAttrDict(dict);

      if (dict.ProductClassifications.value) {
        if (typeof dict.ProductClassifications.value === 'string') {
          setCurrentCategoryRoot(parseInt(dict.ProductClassifications.value));
        }
      }
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line
  const loadProductData = async (productId: string) => {
    setIsLoading(true);
    //setIsLoadingProductData(true);

    try {
      const pd = await Products.fetchSingleProductById(productId);

      console.log('pd -->', pd);
      if (pd && typeof pd === 'object') {
        if (pd.ProductBasic && typeof pd.ProductBasic === 'object') {
          const { /*FlagList,*/ ProductBasic } = pd;
          const info = ProductBasic as StringKAnyVPair;

          if (Array.isArray(basicInfo.FlagList)) {
            info.FlagList = basicInfo.FlagList;
          }

          if (Array.isArray(basicInfo.LabelList)) {
            info.LabelList = basicInfo.LabelList;
          }

          //setBasicInfo(pd.ProductBasic);
          setBasicInfo({...info});
          console.log('info ->', info);
          setBasicInfoReady(true);
        }
      } else {
        message.error(`Product data error: ${pd}`);
      }
    } catch(e) {
      message.error(`Fetch product data error: ${e}`);
    } finally {
      setIsLoading(false);
      //setIsLoadingProductData(false);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    const sv = await loadStyleVariations();

    await loadBasicAttrs();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    setBasicInfoReady(true);
  };

  const mapCustPackTypeOptions = (ls: any) => {
    if (Array.isArray(ls)) {
      return ls.map((e: StringKAnyVPair) => ({
        ...e,
        code: e.packageCode,
      }));
    }

    return [];
  };

  const mapDefaultWarehouseOptions = (ws: any) => {
    if (Array.isArray(ws)) {
      const ops = ws.map((e: StringKAnyVPair) => {
        if (e && typeof e === 'object') {
          const d = e.DistributionCenter;

          return d && typeof d === 'object' ? {
            ...d,
            code: d.DistributionCenterCode,
            description: d.DistributionCenterName,
          } : null;
        }

        return null;
      });

      return ops.filter(e => e);
    }

    return [];
  };

  const onSearchAttrKeyUp = () => {
    //let sk = attrSearchInputRef.current?.input.value;
    //console.log('sk', sk);
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (/*sk &&*/ typeof sk === 'string') {
      const sections = pageRef?.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      sk = sk.toLowerCase().trim();
      setIsSearchEmpty(sk === '');

      for (let i = 0; i < sections.length; i++) {
        const sec = sections[i];
        const items = sec.querySelectorAll(`.${FORM_ITEM_CLS}`);
        let showCount = 0;

        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          const labelDom = item.querySelector(`.${FORM_LABEL_CLS}`);

          if (labelDom) {
            const label = labelDom.textContent.trim();

            if (label && typeof label === 'string') {
              const match = label.toLowerCase().indexOf(sk) > -1;

              showCount += (match ? 1 : 0);
              item.style.display = match ? '' : 'none';
            }
          }
        }

        if (i > 0 && items.length > 0) {
          sec.style.display = showCount > 0 ? '' : 'none';
        }
      }
    }
  };

  const onSelectOperationOption = (
    val: any,
    pn: string,
    isOperation = false,
  ) => {
    const noneOpes: string[] = [
      'warranty',
    ];

    if (attrDict[pn] === undefined) {
      attrDict[pn] = { fieldName: pn };
    }

    attrDict[pn].editValue = val;
    attrDict[pn].isOperation = noneOpes.indexOf(pn) > -1 ? false : isOperation;
    attrDict[pn].valueChanged = true;
    setAttrDict({...attrDict});
  };

  /**
   * Get the value of operation according to its key.
   */
  // eslint-disable-next-line
  const operationValue = (k: string) => {
    if (basicInfo.Operation && typeof basicInfo.Operation === 'object') {
      return k in basicInfo.Operation ? basicInfo.Operation[k] : '';
    }

    return '';
  };

  const refreshStyleVariations = async () => {
    const sv = await loadStyleVariations(true);

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }
  };

  // eslint-disable-next-line
  const resetAttrState = () => {
    setAttrDict({...attrDict});
  };

  const saveFormData = async () => {
    setIsLoading(true);

    try {
      const basic: StringKAnyVPair = {};
      const operation: StringKAnyVPair = {};
      const data: StringKAnyVPair = {
        ApplyToAllRelevantSubStyleAndSKU: props.state?.overwriteChildren || false,
        styleCode: basicInfo.sku,
        productBasic: basic,
        productOperation: operation,
      };
      const skd: StringKAnyVPair = {};
      console.log('-->', data);
      for (let k in attrDict) {
        const attr = attrDict[k];

        if (attr.valueChanged) {
          console.log(k, attr);
          if (attr.basicAttributeNum > 999) {
            if (!Array.isArray(data.productBasicInfoAttributeList)) data.productBasicInfoAttributeList = [];

            data.productBasicInfoAttributeList.push({
              AttributeId: attr.basicAttributeId,
              Value: attr.editValue,
              editType: 1,
            });
          } else {
            if (attr.isOperation) {
              operation[attr.fieldName] = attr.editValue;
            } else {
              basic[attr.fieldName] = attr.editValue;
            }
          }

          skd[k] = attr;
        }
      }
      console.log('pdata', data);
      if (data.productBasic) {
        if (Array.isArray(data.productBasic.LabelIdList)) {
          data.LabelIdList = data.productBasic.LabelIdList;
          data.productBasic.LabelIdList = undefined;
        }

        if (Array.isArray(data.productBasic.ChannelControlFlagIdList)) {
          data.ChannelControlFlagIdList = data.productBasic.ChannelControlFlagIdList;
          data.productBasic.ChannelControlFlagIdList = undefined;
        }
      }

      if (props.productId) {
        await Products.editSimpleStyleMaster(props.productId, data);
        // set attr status at here
        for (let k in skd) {
          skd[k].valueChanged = false;
        }
        console.log('saved', skd);
        setAttrDict({ ...attrDict });
        message.success('Saved successfully');

        if (typeof props.onSaved === 'function') {
          props.onSaved(true);
        }
      }
    } catch(e) {
      message.error(`Saved basic info error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  const trySaveFormData = () => {
    if (isSomeValueChanged()) {
      saveFormData();
    } else {
      message.info('No data changed');
    }
  };

  React.useEffect(() => {
    if (props.productId && props.focused && (!inited || currentProductId !== props.productId)) {
      loadInitialData();
      setInited(true);
      setProductId(props.productId);
    }

    if (typeof props.editOnly === 'boolean') {
      if (isEditMode !== props.editOnly) {
        setIsEditMode(props.editOnly);
      }
    }

    if (props.editOnly) {
      if (typeof props.editSave === 'number') {
        if (saveCount !== props.editSave) {
          setSaveCount(props.editSave);
          console.log('ready save', props.editSave);
          trySaveFormData();
        }
      }

      if (typeof props.onContentChanged === 'function') {
        props.onContentChanged(isSomeValueChanged());
      }
    }
  }, [
    currentProductId,
    inited,
    isEditMode,
    isSomeValueChanged,
    loadInitialData,
    props,
    saveCount,
    trySaveFormData,
  ]);

  return (<>
    <TabPanelContainer ref={pageRef}>
      <Row className="form-section">
        <Row align="middle" className="full-width" justify="space-between">
          <span className="section-title">Operation Setting</span>
          <Input.Search
            allowClear
            className="filter-inputbar"
            enterButton={null}
            onKeyUp={onSearchAttrKeyUp}
            onSearch={onSearchAttributes}
            ref={attrSearchInputRef}
            prefix={!isSearchEmpty ? <FilterTwoTone /> : <FilterOutlined />}
            style={{width: 300}}
          />
        </Row>
        <hr className="full-width" />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Sales Channel</FormLabel>
            <span className="form-input-wrapper input-1">
              {/*basicInfoReady && <CommonSelectEditor1
                editOnly={isEditMode}
                mode="multiple"
                name="Channel Control Flags"
                onSelect={onSelectOperationOption}
                productId={props.productId}
                state={props.state}
                styleCode={basicInfo.sku}
                type="channel"
                value={basicInfo.FlagList}
                sourceValue={basicInfo.FlagList1}
              />*/}
              {basicInfoReady && <SalesChannelEditor
                dispatch={props.dispatch}
                editOnly={isEditMode}
                mode="multiple"
                name="Channel Control Flags"
                onSelect={onSelectOperationOption}
                productId={props.productId}
                state={props.state}
                styleCode={basicInfo.sku}
                type="channel"
                value={basicInfo.FlagList}
                sourceValue={basicInfo.FlagList1}
              />}
            </span>
          </Row>
        </div>
        {/*<div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-5">Classification</FormLabel>
            <span className="form-input-wrapper input-7">
              {basicInfoReady && <CommonSelectEditor
                name="Classification"
                productId={props.productId}
                styleCode={basicInfo.sku}
                type="classification"
                value={undefined}
              />}
            </span>
          </Row>
        </div>*/}
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Tag</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                editOnly={isEditMode}
                mode="multiple"
                name="Label"
                onSelect={onSelectOperationOption}
                productId={props.productId}
                state={props.state}
                styleCode={basicInfo.sku}
                type="label"
                value={basicInfo.LabelList}
              />}
            </span>
          </Row>
        </div>
      </Row>
      <Spacer />
      <div className="form-section">
        <span className="section-title">Product Catalog</span>
        <hr />
        
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Status</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Status"
                name="status"
                onSelect={onSelectOperationOption}
                options={getOperationStatusOptions()}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="operationStatus"
                value={operationValue('status')}
              />}
              {/*<ReadonlyCell
                value={operationValue('status')}
              />*/}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Class</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Class"
                name="class"
                onSelect={onSelectOperationOption}
                options={styleVariation.classCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="classCode"
                value={operationValue('class')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">SubClass</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="SubClass"
                name="subClass"
                onSelect={onSelectOperationOption}
                options={styleVariation.subclassCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="subclassCode"
                value={operationValue('subClass')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Category</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Category"
                name="category"
                onSelect={onSelectOperationOption}
                options={styleVariation.categoryCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="categoryCode"
                value={operationValue('category')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Division</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Division"
                name="division"
                onSelect={onSelectOperationOption}
                options={styleVariation.divisionCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="divisionCode"
                value={operationValue('division')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Group</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Group"
                name="group"
                onSelect={onSelectOperationOption}
                options={styleVariation.groupCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="groupCode"
                value={operationValue('group')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">SubGroup</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="SubGroup"
                name="subGroup"
                onSelect={onSelectOperationOption}
                options={styleVariation.subgroupCode}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="subgroupCode"
                value={operationValue('subGroup')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Model</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Model"
                name="model"
                onSelect={onSelectOperationOption}
                options={styleVariation.model}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="model"
                value={operationValue('model')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Catalog Page</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Catalog Page"
                name="catalogPage"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={operationValue('catalogPage')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Remark</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Remark"
                name="remark"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={operationValue('remark')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Product Year</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Product Year"
                name="productYear"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={operationValue('productYear')}
              />}
            </span>
          </Row>
        </div>
      </div>
      <Spacer />
      <div className="form-section">
        <span className="section-title">Product Specifications</span>
        <hr />
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Default W #</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Default W #"
                name="defaultWarehouse"
                onSelect={onSelectOperationOption}
                options={mapDefaultWarehouseOptions(styleVariation.warehouses)}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="model"
                value={operationValue('defaultWarehouse')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Min Stock</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Min Stock"
                name="minStock"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('minStock')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Default Vendor #</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Default Vendor #"
                name="defaultVendor"
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={operationValue('defaultVendor')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Default P/O Size</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Default P/O Size"
                name="defaultPoSize"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('defaultPoSize')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Default Pack Type</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Default Pack Type"
                name="defaultPackType"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_STRING}
                value={operationValue('defaultPackType')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Qty/Case</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Qty/Case"
                name="qtyPerCase"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('qtyPerCase')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Qty/Box</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Qty/Box"
                name="qtyPerBox"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('qtyPerBox')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Qty/Pallet</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Qty/Pallet"
                name="qtyPerPallet"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('qtyPerPallet')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Cust. Pack Type</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Cust. Pack Type"
                name="custPackType"
                onSelect={onSelectOperationOption}
                options={mapCustPackTypeOptions(styleVariation.packages)}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="custPackType"
                value={operationValue('custPackType')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Qty/Pack</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Qty/Pack"
                name="qtyPerPack"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('qtyPerPack')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Size Type</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <CommonSelectEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Size Type"
                name="sizeType"
                onSelect={onSelectOperationOption}
                options={styleVariation.sizeType}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                refreshOptions={refreshStyleVariations}
                styleCode={basicInfo.sku}
                type="sizeType"
                value={operationValue('sizeType')}
              />}
            </span>
          </Row>
        </div>
        <div className="form-item item-1">
          <Row align="middle">
            <FormLabel className="form-label label-1">Lead Time</FormLabel>
            <span className="form-input-wrapper input-1">
              {basicInfoReady && <BasicAttrEditor
                category={ATTR_CATEGORY_OPERATION}
                editOnly={isEditMode}
                label="Lead Time"
                name="leadTime"
                onChange={onSelectOperationOption}
                productId={props.productId}
                readOnly={true}
                refreshFormState={resetAttrState}
                styleCode={basicInfo.sku}
                type={BASIC_ATTR_TYPE_NUMBER}
                value={operationValue('leadTime')}
              />}
            </span>
          </Row>
        </div>
      </div>
    </TabPanelContainer>
    {(isLoading /*|| isLoadingProductData*/) && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default React.memo(Operation);
