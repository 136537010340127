import React from 'react';
import { Button, Checkbox, Row, Space, Table, Typography } from 'antd';

const { Text } = Typography;

type Props = {
  maskScreen: Function;
  selectedSourceName: string;
  sourceList: any[];
  setCanNext: (value: boolean) => void;
  onSelected: (selectedRow: any[]) => void;
  openViewDialog: (data: any) => void;
};

const AttributeSelector = (props: Props) => {
  const { useState } = React;
  const { sourceList, onSelected, setCanNext, openViewDialog } = props;
  const [productBasicList, setProductBasicList] = useState<StringKAnyVPair[]>(
    sourceList.map((e) => ({
      ...e,
      selected: false,
    })),
  );

  const allBasicItemSelected = React.useMemo(() => {
    return productBasicList.every((e) => e.selected);
  }, [productBasicList]);

  const onSelectBasicItem = (evt: any) => {
    console.log('sel->', evt.target.value, evt.target.checked);
    for (let i = 0; i < productBasicList.length; i++) {
      const item = productBasicList[i];

      if (item.attributeNum === evt.target.value) {
        item.selected = evt.target.checked;
        break;
      }
    }

    setProductBasicList([...productBasicList]);
    onSelected(productBasicList.filter((i) => i.selected));
  };

  const productBasicColumns = [
    {
      dataIndex: 'attributeName',
      title: 'Name',
      render(text: string, data: any, index: number) {
        return (
          <Checkbox checked={data.selected} onChange={onSelectBasicItem} value={data.attributeNum}>
            {text}
          </Checkbox>
        );
      },
    },
    {
      dataIndex: 'existingAttributeValue',
      title: 'Attribute Value',
      render(text: string, data: any, index: number) {
        return (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        );
      },
    },
    {
      dataIndex: 'copywritingElementName',
      title: 'Content Resources',
    },
    {
      dataIndex: 'copywritingValue',
      title: 'Content Resources Value',
      render(text: string, data: any, index: number) {
        return (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        );
      },
    },
    {
      align: 'center' as 'center',
      title: '',
      render(data: any) {
        return (
          <Button className="primary-text-btn" type="text" onClick={() => openViewDialog(data)}>
            View
          </Button>
        );
      },
    },
  ];

  const selectAllBasicItems = () => {
    productBasicList.forEach((e) => (e.selected = true));
    setProductBasicList([...productBasicList]);
    onSelected([...productBasicList]);
  };

  const selectNoValueItems = () => {
    productBasicList.forEach((e) => (e.selected = !e.existingAttributeValue));
    setProductBasicList([...productBasicList]);
    onSelected([...productBasicList]);
  };

  React.useEffect(() => {
    // onSelected(productBasicList.filter(i => i.selected))
    if (productBasicList.findIndex((i) => i.selected) > -1) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [productBasicList, onSelected, setCanNext]);

  return (
    <>
      <div className="dest-step-wrapper">
        <div className="section">
          <Row align="middle" className="section-bar">
            <Space>
              <span className="section-title">{props.selectedSourceName}</span>
              <Button
                className="primary-text-btn"
                disabled={allBasicItemSelected}
                onClick={selectAllBasicItems}
                type="text"
              >
                Select All
              </Button>
              <Button className="primary-text-btn" type="text" onClick={selectNoValueItems}>
                Select No-Value Attributes
              </Button>
            </Space>
          </Row>
          <Table
            columns={productBasicColumns}
            dataSource={productBasicList}
            pagination={false}
            rowKey="attributeNum"
          />
        </div>
      </div>
    </>
  );
};

export default AttributeSelector;
