import _ from 'lodash';
import { ChannelAccountEventType } from '../types/enums';
import { commAPI, digitBridgeApi, erpAPI } from './http';
import moment from 'moment';
import { validateHttpStatus } from '../util';

export const downloadCopywritingTemplate = async (
  channelNum: number,
  channelAccountNum: number,
): Promise<any> => digitBridgeApi.get('/v1//channels/mappingTemplates/copywriting/download', {
  params: {
    channelNum,
    channelAccountNum,
  },
  responseType: 'blob',
});

export const fetchUserChannels = async (): Promise<any> => digitBridgeApi.get('/v1/userChannels', {
  params: {
    masterAccountNum: 10001,
    profileNum: 10001,
  },
});

export const fetchProductMappingChannels = async (): Promise<Entities.ProductTemplate[]> => digitBridgeApi.get('/v1/channels/availableProductTemplates');

export const fetchCategoryChildrenById = async (nodeNum: number | string): Promise<any> => digitBridgeApi.get(`/v1/category/${nodeNum}`);

export const fetchChannels = async (retailer?: boolean): Promise<Entities.ChannelProfile[]> => {
  // accountEnabled api does not support retailer, so manually filter retailer
  const result: Entities.ChannelProfile[] = await digitBridgeApi.get('/v1/channels/accountEnabled');
  return retailer ? result.filter((channel: Entities.ChannelProfile) => (channel.category === 'Retailer')) : result;
};

export const fetchCentralEnabled = async (): Promise<Entities.ChannelProfile[]> => digitBridgeApi.get('/v1/channels/centralEnabled');

export const fetchChannelsFields = async (): Promise<any> => digitBridgeApi.get('/v1/account/channelProfile');

export const getChannelAuthPath = async (channelAccountNum: number, channelNum: number): Promise<any> => digitBridgeApi.get(`/v1/channelSetting/authPath?channelNum=${channelNum}&channelAccountNum=${channelAccountNum}`);

export const getConfirmationActivation = async (channelAccountNum: number): Promise<boolean> => digitBridgeApi.get(`/v1/channelSetting/confirmactivation?&channelAccountNum=${channelAccountNum}`);

export const getChannelAccountProfile = async (channelAccountNum: number): Promise<Entities.ChannelAccountObj | null> => {
  const result: Entities.ChannelAccountObj[] = await digitBridgeApi.get('/v1/account/channels', { params: { channelAccountNum } });
  return result.length === 0 ? null : result[0];
};

export const createChannelAccountNum = async (payload: any): Promise<any> => digitBridgeApi.post('/v1/account/channels', payload);

export const updateChannelAccountNum = async (channelAccountNum: number, channelNum: number, data: any): Promise<any> => digitBridgeApi.patch('/v1/account/channels', data, {
  params: { channelAccountNum, channelNum },
});

/*
export const deleteChannelAccount = async (channelAccountNum: number): Promise<void> => digitBridgeApi.delete('/v1/account/channels', {
  params: { channelAccountNum },
});
*/
export const deleteChannelAccount = async (channelAccountNum: number): Promise<void> => digitBridgeApi.post(`/v1/account/channels/${channelAccountNum}/disable`);

export const listProfileChannelAccounts = async (channelNum?: string): Promise<Entities.ProfileChannelAccount[]> => digitBridgeApi.get('/v1/channels/profileChannelAccounts/', {
  headers: {
    channelNum,
  },
  validateStatus: (status) => validateHttpStatus([401, 400, 404, 500], status)
});

export const getChannelAccountProfileSettings = async (channelAccountNum: string): Promise<FormModels.IntegrationSettings> => {
  const settings: Entities.ChannelAccountProfileSetting = await digitBridgeApi.get(
    '/v1/channel/profileSettings',
    {
      headers: {
        channelAccountNum,
      },
    },
  );

  // Transform Array -> Map for easier management on the frontend
  const newSettings: any = {
    ...settings,
  };
  const dcMappings = settings.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings || [];
  const dcMap: { [key: string]: Entities.DistributionCenterWarehouseMapping } = {};
  dcMappings.forEach(dc => {
    dcMap[dc.DistributionCenterNum.toString()] = dc;
  });
  newSettings.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings = dcMap;

  return newSettings;
};

export const updateChannelAccountProfileSettings = async (channelAccountNum: number, channelAccountSettingsNum: number, data: FormModels.IntegrationSettings) => {
  // Transform Map -> Array
  const payload: any = _.cloneDeep(data);
  const dcMappings = payload.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings || {};
  payload.ScheduleSetting.InventorySettings.DistributionCenterWarehouseMappings = Object.keys(dcMappings).map(k => dcMappings[Number(k)]);

  return digitBridgeApi.patch(
    `/v1/channel/profileSettings/${channelAccountSettingsNum}`,
    payload,
    {
      headers: {
        channelAccountNum,
        channelAccountSettingNum: channelAccountSettingsNum,
      },
    },
  );
};

export const pushToChannelAccount = (
  eventType: ChannelAccountEventType,
  channelAccountNum: number,
  channelNum: number,
  body?: any,
  productMappingNum?: number,
) => digitBridgeApi.post(
  '/v1/channelAccount/events',
  body,
  {
    params: { eventType, productMappingNum },
    headers: { channelAccountNum, channelNum },
  },
);

export const pushToChannelAccountAll = (
  eventType: ChannelAccountEventType,
  channelAccountNum: number,
  channelNum: number,
  channelControlFlagNum: number,
  productMappingNum?: number,
) => digitBridgeApi.post(
  '/v1/channelAccount/events/pushall',
  null,
  {
    params: { eventType, productMappingNum },
    headers: { channelAccountNum, channelNum, channelControlFlagNum },
  },
);

export const uploadCopywritingTemplate = async (
  channelNum: number,
  channelAccountNum: number,
  data: FormData,
): Promise<any> => digitBridgeApi.post('/v1/channels/mappingTemplates/copywriting/upload',
  data,
  {
    params: {
      channelNum,
      channelAccountNum,
    },
  },
);

export const getChannelExportViewInfo = (channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/sell/channel/export/${channelAccountNum}/view`);

export const getChannelControlFlag = (channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/channels/${channelAccountNum}/channelControlFlag`);

export const getChannelAccountStatisticList = (): Promise<any> => digitBridgeApi.get('/v1/channelAccountStatistic/listing', { validateStatus: (status) => validateHttpStatus([401, 400, 404], status) });

export const getChannelAccountCatalogSetting = (channelAccountNum: string, channelNum: string): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/catalogSetting`, { headers: { channelNum } });

export const getChannelAccountCustomSetting = (channelAccountNum: string, channelNum: string): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/customSetting`, { headers: { channelNum } });

export const getChannelAccountStandardSetting = (channelAccountNum: string, channelNum: string): Promise<any> => digitBridgeApi.get(`/v1/channelAccount/${channelAccountNum}/standardSetting`, { headers: { channelNum } });

export const enableChannelOrderDownload = (channelAccountNum: string): Promise<any> => digitBridgeApi.post(`/v1/account/channels/${channelAccountNum}/autoDownload/enable`);

export const disableChannelOrderDownload = (channelAccountNum: string): Promise<any> => digitBridgeApi.post(`/v1/account/channels/${channelAccountNum}/autoDownload/disable`);

export const fetchGlobalEnabled = async (): Promise<Entities.ChannelProfile[]> => digitBridgeApi.get('/v1/channels/globalEnabled');

export const updateCatalogSetting = (channelAccountSettingNum: number, data: FormData): Promise<any> => digitBridgeApi.patch(`/v1/channel/catalogSetting/${channelAccountSettingNum}`, data);

export const getAvailableCatagoryTemplates = (channelAccountNum: string, channelNum: string): Promise<any> => digitBridgeApi.get('/v1/channelIntegration/availableCatagoryTemplates', { headers: { channelNum, channelAccountNum } });

export const saveAvailableCatagoryTemplates = (channelAccountNum: string, channelNum: string, data: any): Promise<any> => digitBridgeApi.post('/v1/channelIntegration/generateChannelCatagoryTemplates', data, { headers: { channelNum, channelAccountNum } });

export const fetchProfileSettingdefs = async (): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.get('/v1/pim/profilesetting/defs');
};

export const fetchProfileSettings = async (): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.get('/v1/pim/profilesettings');
};
type SaveSettingsParamsItem = {
  SettingCode: string;
  SettingValue: string;
  // MasterAccountNum: number,
  // ProfileNum: number,
};

export const saveProfileSettings = async (params: SaveSettingsParamsItem[]): Promise<any> => {
  const json = JSON.stringify(params);

  // eslint-disable-next-line
  return await digitBridgeApi.post('/v1/pim/profilesettings', json);
};


type TemplateListItem = {
  contentStorageId: string;
  isGenerated: boolean;
  templateName: string;
  type: number;
};

export const getStandardTemplates = async (channelAccountNum: string, channelNum: string): Promise<TemplateListItem[]> => {
  // eslint-disable-next-line
  return await digitBridgeApi.get('/v1/channelIntegration/availableStandardTemplates', { headers: { channelNum, channelAccountNum } });
};

export const saveSelectedStandardTemplates = async (channelAccountNum: string, channelNum: string, ids: string[]): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.patch('/v1/channelIntegration/standardTemplates', ids, { headers: { channelNum, channelAccountNum } });
};

export const getAllTemplates = async (channelAccountNum: string, channelNum: string): Promise<{ isForceUsing: boolean, templates: TemplateListItem[] }> => {
  // eslint-disable-next-line
  return await digitBridgeApi.get('/v1/channelIntegration/allTemplates', { headers: { channelNum, channelAccountNum } });
};

export const saveSelectedTemplates = async (channelAccountNum: string, channelNum: string, params: { templates: string[], isForceUsing: boolean }): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.patch('/v1/channelIntegration/generateTemplates', params, { headers: { channelNum, channelAccountNum } });
};

export const getProductMappingList = async (channelAccountNum: string, channelNum: string): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.get('/v1/channel/export/list', { headers: { channelNum, channelAccountNum } });
};

export const saveSyncTemplate = async (channelAccountNum: string, channelNum: string, productMappingNum: number): Promise<any> => {
  // eslint-disable-next-line
  return await digitBridgeApi.patch(`/v1/channel/export/${productMappingNum}/1`, {}, { headers: { channelNum, channelAccountNum } });
};

export const fetchERPWeeklySOReports = async (): Promise<any> => {
  const { data } = await erpAPI.post('/oaReports/weeklySalesOrderStatusReport', { reportDate: moment().format('MM/DD/YY') }, { validateStatus: (status) => validateHttpStatus([401, 400, 404], status) });
  return data.weeklySalesOrderByChannel;
};

export const fetchERPDigitBridgeLogSummary = async (filterDate: string): Promise<any> => {
  const { data } = await erpAPI.post('/dashboards/digitBridgeLogSummary', { $filter: { dates: [filterDate] } }, { validateStatus: (status) => validateHttpStatus([401, 400, 404], status) });
  return data.summary;
};

export const getDashboardSettingType = async (): Promise<any> => {
  return digitBridgeApi.get('/v1/settingSimpleGeneral?settingCode=DashBoardChannelsWithDrop', { validateStatus: (status) => validateHttpStatus([401, 400, 404], status) });
};

type UpdateDashboardSettingTypeRequest = {
  rowNum: number;
  settingCode: string;
  settingValue: {
    SettingType: number;
    ChannelDropShipItems: ChannelDropShipItemsProps[];
  };
};

type ChannelDropShipItemsProps = {
  ChannelAccountNum: number;
  ReportCode: string;
};

export const updateDashboardSettingType = async (params: UpdateDashboardSettingTypeRequest): Promise<any> => {
  const json = JSON.stringify(params);
  return digitBridgeApi.patch('/v1/settingSimpleGeneral', json);
};

export const fetchChannelListWithReportCode = async (): Promise<any> => {
  return digitBridgeApi.get('/v1/channelAccount/listWithReportCode', { validateStatus: (status) => validateHttpStatus([401, 400, 404], status) });
};

export const generateFeedUrl = async (channelNum: string | undefined, channelAccountNum: string | undefined, mappingType: number): Promise<any> => {
  return digitBridgeApi.get(`/v1/account/channels/${channelAccountNum}/generateFeedUrl/${mappingType}`, {
    headers: {
      channelNum,
    },
  });
};

type ChannelsLoginParams = {
  channelNum: string;
  channelAccountNum?: string;
  platformNum?: string;
  channelName: string;
  profileNum: number;
  masterAccountNum: number;
}
export const channelsLoginFromEdit = async (params: ChannelsLoginParams): Promise<any> => {
  const { channelNum, channelAccountNum, platformNum, profileNum, masterAccountNum, channelName } = params;
  const channel = channelName.replace(/\W+/g, '').toLowerCase();
  return commAPI.get(`/oauth/channel?parameters=${masterAccountNum}-${profileNum}-${channelNum}-${platformNum}-${channelAccountNum}&channel=${channel}&from=channelAccountEdit`)
}

export const channelsLoginFromAdd = async (params: ChannelsLoginParams): Promise<any> => {
  const { channelNum, channelName, profileNum, masterAccountNum } = params;
  const channel = channelName.replace(/\W+/g, '').toLowerCase();
  return commAPI.get(`/oauth/channel?parameters=${masterAccountNum}-${profileNum}-${channelNum}-0&channel=${channel}&from=channelAccountCreate`)
}

export const createChannelAccount = async (params: any): Promise<any> => {
  const json = JSON.stringify(params);
  return digitBridgeApi.post('/v1/account/channels/create', json)
};
