import { digitBridgeApi } from './http';

export const fetchDistributionCenters = async (): Promise<Entities.DistributionCenter[]> => digitBridgeApi.get('/v1/distributionCenters');

export const fetchDistributionCentersRoutingRules = async (): Promise<Entities.DistributionCenter[]> => digitBridgeApi.get('/v1/distributionCenterPriorities');

export const saveDistributionCentersRoutingRules = async (params: any): Promise<any> => digitBridgeApi.patch('/v1/distributionCenterPriorities', params);

export const fetchDistributionCenter = async (id: number | string): Promise<any> => digitBridgeApi.get(`/v1/distributionCenters/${id}`);

export const saveDistributionCenter = async (id: number | string, params: any): Promise<Entities.DistributionCenter> => digitBridgeApi.patch(`/v1/distributionCenters/${id}`, params);

export const createDistributionCenter = async (data: Entities.DistributionCenter): Promise<Entities.DistributionCenter> => digitBridgeApi.post('/v1/distributionCenters', data);
