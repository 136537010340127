import { ArrowUpOutlined, ArrowDownOutlined, MenuOutlined } from '@ant-design/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Spin, Row, Space, Typography, Button, Transfer, Tag, message } from 'antd';
import React, { useState, useEffect, useCallback, MutableRefObject, useRef } from 'react';
import {
  // fetchAvailableAttributes,
  fetchAvailableAttributesPlus,
  fetchAttributesByAttributeSet,
  saveAttributeSet,
} from '../../services/products';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";

const { Text } = Typography;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  > .label {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }
  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

type Attribute = {
  attributeDataType: number;
  attributeName: string;
  attributeNum: number;
  attributeType: number;
  displaySeq: number;
  groupName: string;
  itemType: number;
};

interface TableTransferProps {
  leftColumns: any[];
  rightColumns: any[];
  dataSource: any;
  targetKeys: any;
  showSearch: any;
  onChange: any;
  filterOption: any;
  rowKey: any;
}

const leftFilterValue = [
  { name: 'attributeName', operator: 'contains', type: 'string', value: '' },
  { name: 'groupName', operator: 'contains', type: 'string', value: '' },
];

const rightFilterValue = [
  { name: 'itemType', operator: 'eq', type: 'select', value: null },
  { name: 'attributeName', operator: 'contains', type: 'string', value: '' },
  { name: 'groupName', operator: 'contains', type: 'string', value: '' },
];

const TableTransferChild = ({
  direction,
  filteredItems,
  onItemSelectAll,
  // onItemSelect,
  selectedKeys: listSelectedKeys,
  disabled: listDisabled,
  leftColumns,
  rightColumns,
}: any) => {
  const columns = direction === 'left' ? leftColumns : rightColumns;
  const filterValue = direction === 'left' ? leftFilterValue : rightFilterValue;
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);

  const onSelectionChange = (gridData: any) => {
    const {
      selected,
    } = gridData;
    // const [item] = data;
    // const selectedKeys = Object.keys(selected).map((objectKey) => selected[objectKey].key);
    const selectedKeys = Object.keys(selected).map((objectKey) => Number(objectKey));

    const newSelect = selectedKeys.filter((key: any) => !listSelectedKeys.includes(key));
    const unSelect = listSelectedKeys.filter((key: any) => !selectedKeys.includes(key));

    if (newSelect.length) onItemSelectAll(newSelect, true); 
    if (unSelect.length) onItemSelectAll(unSelect, false);
  };

  const getSelection = () => {
      let obj: any = {};
      listSelectedKeys.forEach((key: any) => {
        obj[key] = true;
      });
      return obj;
  };

  useEffect(() => {
    // when filteredItems length changes then clear activeIndex to avoid bug
    if (!gridRef) return;
    gridRef.current?.doSetLastActiveIndex(-1);
    gridRef.current?.setActiveIndex(-1);
    if (gridRef.current?.lastLockedEndIndex) gridRef.current.lastLockedEndIndex = -1;
    if (gridRef.current?.computedActiveIndex) gridRef.current.computedActiveIndex = -1;
    if (gridRef.current?.activeRowRef) gridRef.current.activeRowRef.current = null;
    gridRef.current?.reload();
  }, [gridRef, filteredItems.length]);

  return (
    direction === 'left' ? 
    <ReactDataGrid
      // style={style}
      dataSource={filteredItems}
      columns={columns}
      checkboxColumn={!listDisabled}
      defaultFilterValue={filterValue}
      onSelectionChange={onSelectionChange}
      selected={getSelection()}
      idProperty='key'
      style={{ 
        height: '100%',
      }}
      onReady={setGridRef}
      virtualized
    />:
    <DndProvider backend={HTML5Backend}>
      <ReactDataGrid
        dataSource={filteredItems}
        columns={columns}
        checkboxColumn={!listDisabled}
        defaultFilterValue={filterValue}
        onSelectionChange={onSelectionChange}
        selected={getSelection()}
        idProperty='key'
        style={{ 
          height: '100%',
        }}
        onReady={setGridRef}
        virtualized
        activeIndex={undefined} 
      />
    </DndProvider>
  );
}


const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => {

  return (
    <Transfer className='transfer-class-height' style={{ height: '100%', width:'100%' }} {...restProps}>
      {(props: any) => <TableTransferChild {...props} leftColumns={leftColumns} rightColumns={rightColumns} />}
    </Transfer>
  );
};

interface ManageAttributeProps {
  handleCancel: () => void;
  attribute: Entities.IAttributeSet;
}

function ManageAttribute({ attribute, handleCancel }: ManageAttributeProps) {
  const [loading, setLoaidng] = useState<boolean>(false);
  const [loading1, setLoaidng1] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [targetKeys, setTargetKeys] = useState<number[]>([]);
  const [data, setData] = useState<Attribute[]>([]);

  const getData = useCallback(async () => {
    try {
      setLoaidng(true);
      const res = await fetchAvailableAttributesPlus(attribute.rowNum || -1);
      setLoaidng(false);
      if (res) {
        setData(res);
      }
    } catch (error) {
      setLoaidng(false);
    }
  }, [attribute]);

  const getSelected = useCallback(async () => {
    try {
      setLoaidng1(true);
      const res = await fetchAttributesByAttributeSet(attribute.rowNum || 0);
      setLoaidng1(false);
      if (res) {
        setTargetKeys(res.map((i: any) => i.attributeNum));
      }
    } catch (error) {
      setLoaidng1(false);
    }
  }, [attribute]);

  const handleSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      const json = JSON.stringify(
        targetKeys.map((i, index: number) => {
          return {
            attributeNum: i,
            setDisplaySeq: index + 1,
          };
        }),
      );
      const res = await saveAttributeSet(attribute.rowNum || 0, json);
      setSaveLoading(false);
      if (res) {
        message.success('saved successfully');
        handleCancel();
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, [attribute, targetKeys, handleCancel]);

  const onMoveUp = useCallback(
    (item: number, e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const temp = [...targetKeys];
      const index = temp.indexOf(item);
      if (index > 0) {
        const upperKey = temp[index - 1];
        temp[index - 1] = item;
        temp[index] = upperKey;
        setTargetKeys([...temp]);
      }
    },
    [targetKeys],
  );

  const DraggableItem = ({ index, value, moveRow }: {index: number, value: number, moveRow: any}) => {
    const ref = useRef<any>();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: "DraggableItem",
      collect: (monitor) => {
        const res:any = monitor.getItem() || {};
        if (res.index === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            res.index < index ? ` drop-over-downward` : ` drop-over-upward`,
        };
      },
      drop: (item:any) => {
        moveRow(item.index, index);
      },
    });
  
    const [, drag, preview] = useDrag({
      type: "DraggableItem",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    preview(drop(ref));
  
    return (
      <ItemWrapper
        key={index}
        ref={ref}
        className={`${isOver ? dropClassName : ""}`}
      >
        {index !== -1 && (
          <Space>
            <ArrowUpOutlined onClick={(e) => onMoveUp(value, e)} />
            <ArrowDownOutlined
              type="arrow-down"
              onClick={(e) => onMoveDown(value, e)}
              style={{ paddingLeft: 6, paddingRight: 4 }}
            />
            <span ref={drag}>
              <MenuOutlined />
            </span>
          </Space>

        )}
      </ItemWrapper>
    );
  };

  const onMoveDown = useCallback(
    (item, e) => {
      e.stopPropagation();
      e.preventDefault();
      const temp = [...targetKeys];
      const index = temp.indexOf(item);
      if (index < temp.length - 1) {
        const nextKey = temp[index + 1];
        temp[index + 1] = item;
        temp[index] = nextKey;
        setTargetKeys([...temp]);
      }
    },
    [targetKeys],
  );

  const leftTableColumns = [
    {
      name: 'attributeName',
      header: 'Name',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'groupName',
      header: 'Source',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
  ];
  const rightTableColumns = [
    {
      name: 'itemType',
      header: 'Type',
      width: 70,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => {
        return (value === 1 ? <Tag color="#2db7f5">SYS</Tag> : <Tag color="#87d068">ATT</Tag>)
      },
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: [
          {
            id: 1,
            label: 'SYS',
          },
          {
            id: 2,
            label: 'ATT',
          },
        ],
      },
    },
    {
      name: 'attributeName',
      header: 'Name',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'groupName',
      header: 'Source',
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'attributeNum',
      header: 'Sequence',
      width: 70,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => (
        <DraggableItem
        index={targetKeys.findIndex((key) => key === value)}
        value={value}
        moveRow={moveRow}
      />
      ),
      defaultFlex: 1,
    },
  ];

  const onChange = (nextTargetKeys: any[]) => {
    setTargetKeys(Array.from(new Set(nextTargetKeys)));
  };

  const moveRow = async (dragIndex: number, hoverIndex:number) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    onChange(clonedList);
  };

  useEffect(() => {
    getData();
    getSelected();
  }, [getData, getSelected]);

  return (
    <Spin spinning={loading || loading1} wrapperClassName='ant-spin-flex-height'>
      <Row justify="space-between">
        <Text
          strong
          style={{ fontSize: 18 }}
        >{`Operation Sets: ${attribute.attributeSetName}`}</Text>
        <Space>
          <Button type="primary" loading={saveLoading} onClick={() => handleSave()}>
            Save
          </Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </Space>
      </Row>
      <Row style={{ marginTop: 20, flexGrow: 1 }}>
        <TableTransfer
          dataSource={data}
          targetKeys={targetKeys}
          showSearch
          onChange={(d:any)=>{
            setTargetKeys((prev)=>{
                if(d.length > prev.length){
                    const temp = d.filter((i:number)=> prev.indexOf(i) === -1)
                    return [...prev,...temp]
                } else {
                    return d
                }
            })
          }}
          filterOption={(inputValue: string, item: any) =>
            item.attributeName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
            item.groupName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          rowKey={(record: any) => record.attributeNum}
        />
      </Row>
    </Spin>
  );
}

export default ManageAttribute;
