import React from 'react';
import {
  Row,
  notification,
} from 'antd';
import { v4 as uuid } from 'uuid';

import Loading from '../../components/common/Loading';
import Spacer from '../../components/common/Spacer';
import { FormLabel } from '../../components/common/styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_PRODUCT_FEATURE_GROUP_COLUMNS,
  LOADING_ICON_SIZE1,
} from '../../constants/config';
import {
  fetchProductCopywritingElements,
} from '../../services/copywriting';
import {
  fetchProductFeatureLayout,
} from '../../services/products';
import {
  ITEM_TYPE_PLACEHOLDER,
  LABEL_POSITION_LEFT,
  //LABEL_POSITION_TOP,
} from '../EssentialInfo/ProductFeature/Layout';

import { FormPlaceholder } from './styledComponents';

import BasicAttrEditor, {
  // ATTR_CATEGORY_BASIC,
  // BASIC_ATTR_TYPE_DEMICAL,
  // BASIC_ATTR_TYPE_NUMBER,
  // BASIC_ATTR_TYPE_PRICE,
  // BASIC_ATTR_TYPE_SELECT,
  // BASIC_ATTR_TYPE_STRING,
  BASIC_ATTR_TYPE_TEXT,
} from './BasicAttrEditor';

type Props = {
  attrDict: StringKAnyVPair;
  ignoreTrailingSpacer?: boolean;
  isEditMode: boolean;
  productId: string;
  refreshFormState: Function;
  setAttrDict?: Function;
  state: StringKAnyVPair;
  styleCode: string;
};

const FeatFormItems = (props: Props) => {
  const { useState } = React;
  const { attrDict } = props;
  const [featGroups, setFeatGroups] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const ctnRef = React.useRef<any>(null);

  const groupItemInputClass = (labelPos: number) => {
    const cls = [ 'form-input-wrapper' ];

    if (labelPos === LABEL_POSITION_LEFT) {
      cls.push('input-7');
    } else {
      cls.push('input-0');
    }

    return cls.join(' ');
  };

  const groupItemLabelClass = (labelPos: number) => {
    const cls = [ 'form-label' ];

    if (labelPos === LABEL_POSITION_LEFT) {
      cls.push('label-5');
    } else {
      cls.push('label-0');
    }

    return cls.join(' ');
  };

  const isInstanceExisted = () => {
    return ctnRef && ctnRef.current;
  };

  const loadGroupItemsValue = async (gs: StringKAnyVPair[]) => {
    const vd: StringKAnyVPair = {};

    gs.forEach(g => {
      if (Array.isArray(g.children)) {
        g.children.forEach(e => {
          if (e.source !== ITEM_TYPE_PLACEHOLDER) {
            vd[e.attributeId] = e;
          }
        });
      }
    });

    try {
      const { data } = await fetchProductCopywritingElements(props.productId);

      if (data && Array.isArray(data)) {
        data.forEach(d => {
          if (vd[d.copywritingElementNum]) {
            const v = typeof d.copywritingValue === 'number' ? d.copywritingValue : (d.copywritingValue || '');
            //console.log('v -- d', d);
            vd[d.copywritingElementNum].attributeValue = v;
          }
        });
        //console.log('vs g', vd, data);
      }
    } catch(e) {
      notification.error({
        message: `Fetched items' value error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    try {
      const gs = await fetchProductFeatureLayout();

      //console.log('gs->', gs);
      if (Array.isArray(gs)) {
        const tds: StringKAnyVPair[] = [];

        gs.forEach(gd => {
          const g = {
            children: [] as StringKAnyVPair[],
            columns: gd.columns || DEFAULT_PRODUCT_FEATURE_GROUP_COLUMNS,
            displayName: gd.groupName,
            displaySequence: gd.displaySequence,
            key: uuid(),
            labelPosition: gd.labelPosition,
            //rowNum: gd.rowNum,
            rowNum: gd.groupNum,
            //title: gd.controlTowerGroupName,
            title: gd.groupName,
          };

          if (Array.isArray(gd.elements)) {
            const attrIdDict: StringKAnyVPair = {};

            for (let k in attrDict) {
              attrIdDict[attrDict[k].basicAttributeNum] = attrDict[k];
            }
            //console.log('----- ----->', attrDict);

            gd.elements.forEach((ed: StringKAnyVPair) => {
              const cd = {
                attributeId: ed.attributeId,
                columns: ed.columns,
                //displayName: ed.displayName,
                displayName: ed.fieldName,
                displaySequence: ed.displaySequence,
                editable: ed.editable,
                key: uuid(),
                parentKey: g.key,
                rowNum: ed.rowNum,
                rows: ed.rows,
                source: ed.source,
                //title: ed.displayName,
                title: ed.fieldName,
              };

              if (ed.attributeId !== 0 && !attrIdDict[ed.attributeId]) {
                attrDict[ed.attributeId] = cd;
              }

              g.children.push(cd);
            });

            if (props.setAttrDict && isInstanceExisted()) props.setAttrDict({...attrDict});
          }

          tds.push(g);
        });
        await loadGroupItemsValue(tds);

        if (isInstanceExisted()) {
          setFeatGroups(tds);
          setIsDataReady(true);
        }
      }
    } catch(e) {
      notification.error({
        message: `Loaded features data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const renderGroupItems = (grp: StringKAnyVPair) => {
    const items: StringKAnyVPair[] = grp.children;
    const cw = Math.floor(100 / grp.columns);
    const fs = {
      width: `calc(${cw}% - 0px)`,
    };
    const labelPos = grp.labelPosition || LABEL_POSITION_LEFT;

    //console.log('grp', grp, labelPos);
    return items.map(e => {
      const attr = attrDict[e.attributeId] || e;

      return (
        <div
          className="form-item"
          key={e.key}
          style={fs}
        >
          {e.source === ITEM_TYPE_PLACEHOLDER && <FormPlaceholder>&nbsp;</FormPlaceholder>}
          {e.source !== ITEM_TYPE_PLACEHOLDER && (
          <Row
            align="middle"
          >
            <FormLabel className={groupItemLabelClass(labelPos)}>{e.title}</FormLabel>
            <span className={groupItemInputClass(labelPos)}>
              <BasicAttrEditor
                allowClear={true}
                //attr={attrDict['ShortDescription']}
                attr={attr}
                editOnly={props.isEditMode}
                //label="Short Description"
                label={e.title}
                name="shortDescription"
                productId={props.productId}
                refreshFormState={props.refreshFormState}
                rows={e.rows}
                state={props.state}
                styleCode={props.styleCode}
                type={BASIC_ATTR_TYPE_TEXT}
                value={e.attributeValue}
              />
            </span>
          </Row>
          )}
        </div>
      );
    });
  };

  const renderGroups = (gs: StringKAnyVPair[]) => {
    const gss = gs.filter(g => Array.isArray(g.children) && g.children.length > 0);

    return gss.map((g, i) => {
      return Array.isArray(g.children) && g.children.length > 0 ? (
        <div key={g.key}>
          <div
            className="form-section"
          >
            <span className="section-title">{g.title}</span>
            <hr />
            <Row align="top">
              {renderGroupItems(g)}
            </Row>
          </div>
          {(i + 1 < gss.length || !props.ignoreTrailingSpacer) && (<>
            <Spacer />
          </>)}
        </div>
      ) : null;
    });
  };

  React.useEffect(() => {
    if (!inited) {
      loadInitialData();
      setInited(true);
    }
  }, [
    inited,
    loadInitialData,
  ]);

  return (<>
    {isDataReady ? (
      renderGroups(featGroups)
    ) : (
      <div ref={ctnRef}>
        <Loading size={LOADING_ICON_SIZE1} />
      </div>
    )}
  </>);
};

export default FeatFormItems;
