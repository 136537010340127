import { Col, Form, Row, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import Info from '../../assets/icons/info';
import Money from '../../assets/icons/money';
import ShippingIcon from '../../assets/icons/shipping';
import FormButtons from '../../components/common/FormButtons';
import Heading from '../../components/common/Heading';
import LoadingIcon from '../../components/common/LoadingIcon';
import { HeaderButton } from '../../components/common/styledComponents';
import ConfirmationModal from '../../components/ConfirmationModal';
import SiteContent from '../../components/SiteContent';
import { Permissions } from '../../constants/enums/permissions';
import OrderDetailsActions from '../../redux/actions/orderDetails';
import { formatDate } from '../../util';
import { TopHeaderButtonsWrapper } from '../ProductDetail/styledComponents';
import { ContentLayoutModal, ControlsWrapper } from './styledComponents';
// eslint-disable-next-line import/no-cycle
import Details from './Tabs/Details';
// eslint-disable-next-line import/no-cycle
import Shipping from './Tabs/Shipping';
import { FormsContext, FormsContextType, InfoValue, setOrderDetailspage } from './common';
import linkERP from '../../util/linkERP';
import ContentLayout from '../../components/ContentLayout';

const useOrderDetailsPageLoad = (id?: string): FormsContextType => {
  const dispatch = useDispatch();
  const [shippingForm] = Form.useForm();
  const [billingForm] = Form.useForm();
  const [shippingViewForm] = Form.useForm();
  const [pendingForm] = Form.useForm();
  const [forms, setForms] = useState<FormsContextType>({});

  // State clearing hook, whenever the ID changes, it'll clear the current state
  useEffect(() => {
    const actions = bindActionCreators(OrderDetailsActions, dispatch);
    actions.clearPage();
  }, [id, dispatch]);

  useEffect(() => {
    setForms({
      shippingForm,
      billingForm,
      shippingViewForm,
      pendingForm,
    });
  }, [shippingForm, billingForm, shippingViewForm, pendingForm]);

  useEffect(() => {
    setOrderDetailspage(id, dispatch, shippingForm, billingForm, pendingForm);
  }, [id, dispatch, shippingForm, billingForm, pendingForm]);

  return forms;
};

const HeaderInfo: React.FC<{ data: Entities.OrderDetailsSummary }> = ({ data }) => (
  <Row>
    <Col span={6}>
      <InfoValue info="Order #" value={data?.centralOrderNum} clipboard />
      <InfoValue
        info="Request Shipping Carrier"
        value={data?.requestedShippingCarrier}
        infoSpan={14}
        valueSpan={10}
      />
      <InfoValue info="Mapped Shipping Carrier" value={data?.mappedShippingCarrier} infoSpan={14} valueSpan={10} />
    </Col>
    <Col span={7}>
      <InfoValue info="Channel Order #" value={data?.channelOrderID} clipboard infoSpan={9} valueSpan={15} />
      <InfoValue info="Channel" infoSpan={9} valueSpan={15} value={data?.channelName} />
      <InfoValue info="Shipping Service" infoSpan={9} valueSpan={15} value={data?.requestedShippingService} />
    </Col>
    <Col span={6}>
      <InfoValue info="Order Date" value={formatDate(data?.originalOrderDateUtc, 'fullDate')} />
      <InfoValue info="Import Date" value={formatDate(data?.centerEnterDateUtc, 'fullDate')} />
      <InfoValue info="Ship Date" value={formatDate(data?.centerEnterDateUtc, 'fullDate')} />
    </Col>
    <Col span={5}>
      <InfoValue info="Order Status" infoSpan={15} valueSpan={9} value={data?.orderStatusName} />
      <InfoValue info="Shipping Status" infoSpan={15} valueSpan={9} value={data?.orderStatusName} />
      <InfoValue info="Payment Status" infoSpan={15} valueSpan={9} value={data?.paymentStatusName} />
    </Col>
  </Row>
);

const PageHeading: React.FC<{ origin: string }> = ({ origin }) => {
  const orderNum = useSelector((state: any) => state.orderDetails.order?.detail?.summary?.centralOrderNum);
  const params: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { activeView } = params;
  const history = useHistory();
  const setView = useCallback((activeViewParam: string) => {
    history.push({ search: qs.stringify({ activeView: activeViewParam }) });
  }, [history]);
  const title = origin === 'modal' ? <>&zwnj;</> : `Order - ${orderNum}`;
  return (
    <Heading
      title={title}
      actions={(
        <TopHeaderButtonsWrapper style={{ justifyItems: 'flex-end' }}>
          <HeaderButton
            $hasPermission
            type={activeView === 'details' ? 'primary' : 'default'}
            onClick={() => setView('details')}
          >
            <Info
              width={22}
              height={22}
              pathColor={activeView === 'details' ? '#fff' : ''}
            />
            Details
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'shipping' ? 'primary' : 'default'}
            onClick={() => setView('shipping')}
          >
            <ShippingIcon
              width={22}
              height={22}
              pathColor={activeView === 'shipping' ? '#fff' : ''}
            />
            Shipping
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'invoice' ? 'primary' : 'default'}
            onClick={() => setView('invoice')}
          >
            <Money
              width={22}
              height={22}
              pathColor={activeView === 'invoice' ? '#fff' : ''}
            />
            Invoice
          </HeaderButton>
          <HeaderButton
            $hasPermission
            type={activeView === 'refund' ? 'primary' : 'default'}
            onClick={() => setView('refund')}
          >
            <Money
              width={22}
              height={22}
              pathColor={activeView === 'refund' ? '#fff' : ''}
            />
            Refund
          </HeaderButton>
        </TopHeaderButtonsWrapper>
      )}
    />
  );
};


const Controls: React.FC = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const editMode = useSelector((state: any) => state.orderDetails.editMode);
  const { id } = useParams<{ id?: string }>();
  const isFormUpdated = useSelector((state: any) => state.orderDetails.isFormUpdated);
  const dispatch = useDispatch();
  const forms = useContext(FormsContext);
  const { shippingForm, billingForm, shippingViewForm } = forms;
  const order = useSelector((state: any) => state.orderDetails.order);
  if (!shippingViewForm || !billingForm || !shippingForm || !id) {
    return null;
  }
  const orderDetailsActions = bindActionCreators(OrderDetailsActions, dispatch);
  const resetProduct = () => {
    orderDetailsActions.setEditMode(false);
    shippingForm.setFieldsValue({
      ...order.detail.shipping.contact,
    });
    billingForm.setFieldsValue({
      ...order.detail.billing.contact,
    });
    shippingViewForm.resetFields();
    orderDetailsActions.setSelectedPendingItems([]);
  };

  return (
    <ControlsWrapper float="right">
      <ConfirmationModal
        handleCancel={() => setShowConfirm(false)}
        handleConfirm={resetProduct}
        visible={showConfirm}
        title="Are you sure you want to cancel?"
        confirmText="Yes"
        cancelText="No"
      />
      <FormButtons
        editingMode={editMode}
        permissionNumber={Permissions.MANAGE_ORDERS}
        onEdit={() => linkERP.openCentralOrderHistory(order?.detail?.summary?.centralOrderNum)}
        onCancel={() => {
          if (isFormUpdated) {
            setShowConfirm(true);
          } else {
            orderDetailsActions.setEditMode(false);
          }
        }}
        disableSave={editMode && !isFormUpdated}
        hideDelete
      />
    </ControlsWrapper>
  );
};

const MainView: React.FC = () => {
  const params: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { activeView } = params;
  switch (activeView) {
    case 'shipping':
      return (<Shipping />);
    default:
      return (<Details />);
  }
};

const OrderDetailsScreen: React.FC<{ idArg: string, origin: string }> = ({ idArg, origin }) => {
  const { id } = useParams<{ id?: string }>();
  const identifier = origin === 'modal' ? idArg : id;
  const loading = useSelector((state: any) => state.orderDetails.loading);
  const isFormUpdated = useSelector((state: any) => state.orderDetails.isFormUpdated);
  const order: Entities.OrderDetails = useSelector((state: any) => state.orderDetails.order);
  const forms = useOrderDetailsPageLoad(identifier);
  const Layout = origin === 'modal' ? ContentLayoutModal : ContentLayout;
  return (
    <FormsContext.Provider value={forms}>
      <Layout>
        <Prompt
          when={isFormUpdated}
          message="You have unsaved changes, are you sure you want to exit?"
        />
        <Spin indicator={<LoadingIcon />} spinning={loading}>
          <PageHeading origin={origin} />
          <SiteContent style={{ paddingBottom: '0px' }}>
            <HeaderInfo data={order?.detail?.summary} key="header" />
          </SiteContent>
          <SiteContent style={{ paddingTop: '0px' }}>
            <Row>
              <Col span={24}>
                <Controls />
              </Col>
            </Row>
            <MainView />
          </SiteContent>
        </Spin>
      </Layout>
    </FormsContext.Provider>
  );
};

export default OrderDetailsScreen;
