import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Typography, List, Breadcrumb, Input } from "antd";
import {
  CloseOutlined,
  RightOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import ModalDialog from "../../../components/common/ModalDialog";
import { getCategories } from "../../../services/ChannelIntegration";
import styled from "styled-components";
import { ListItemProps } from "antd/lib/list";

const TItem: React.FunctionComponent<ListItemProps> = styled(List.Item)`
  &.ant-list-item:hover {
    background-color: #ebedf7;
  }
`;

const { Search } = Input;

type CategoryItem = {
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  level: number;
  isLeaf: boolean;
  categoryPath: string;
  filterValue?: string;
};
interface SelectCategoryProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: (selected: CategoryItem, tree: CategoryItem[]) => void;
  defaultselected: number[];
  channelName: string;
}

const SelectCategoryComponent = (props: SelectCategoryProps) => {
  const { visible, onHide, onRefresh, defaultselected, channelName } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CategoryItem[]>([]);
  const [selected, setSelected] = useState<CategoryItem[]>([]);
  const [searchMode, setSearchMode] = useState(false);

  // const gridCtnRef = React.useRef<any>(null);

  const getCategoryData = useCallback(
    async (item: CategoryItem, index: number) => {
      try {
        setLoading(true);
        const res = await getCategories({
          categoryParentId: item.categoryId,
          channel: channelName,
          categoryName: "",
          pageSize: 500,
          page: 1,
        });
        setLoading(false);
        if (res.isSuccess) {
          if (index === -2) {
            setSelected((prev) => {
              return [...prev, item];
            });
          } else if (index === -1) {
            setSelected([]);
          } else {
            setSelected((prev) => {
              const temp = [...prev];
              const s = temp.slice(0, index + 1);
              return [...s];
            });
          }
          setSearchMode(false);
          setData(res.data.categories);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [channelName]
  );

  const getCategoryDataBySearch = useCallback(
    async (searchStr: string) => {
      try {
        setLoading(true);
        const res = await getCategories({
          // categoryParentId: 0,
          channel: channelName,
          categoryName: `${searchStr}%`,
          leafOnly: true,
          pageSize: 500,
          page: 1,
        });
        setLoading(false);
        if (res.isSuccess) {
          setSearchMode(true);
          setData(res.data.categories);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [channelName]
  );

  // const getDialogDom = () => {
  //   const gridCtn = gridCtnRef?.current;

  //   if (gridCtn) {
  //     return gridCtn.parentNode;
  //   }

  //   return null;
  // };

  // const onFullscreen = (full: boolean) => {
  //   const dialogBody = getDialogDom();

  //   if (!dialogBody) return;
  //   //console.log('full', full, dialogBody, dialogBody.parentNode);
  //   if (full) {
  //     // maybe there need to detect the window environment
  //     dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
  //       window.innerWidth - 16
  //     }px`;
  //     setTimeout(() => {
  //       dialogBody.style.height = "auto";
  //       dialogBody.parentNode.style.height = "auto";
  //       //dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
  //     }, 0);
  //     setTimeout(() => {
  //       dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
  //         window.innerWidth - 2
  //       }px`;
  //     }, 200);
  //   }
  // };

  useEffect(() => {
    getCategoryData(
      {
        categoryId: 0,
        categoryName: "",
        categoryParentId: 0,
        level: 0,
        isLeaf: false,
        categoryPath: "",
      },
      0
    );
  }, [getCategoryData]);

  return (
    <ModalDialog
      title="Select Category"
      centered
      closable
      // closeButton
      // fullscreen
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      //onCancel={() => onHide()}
      onClose={() => onHide()}
      // onFullscreen={onFullscreen}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" onClick={() => onHide()}>
              OK
            </Button>
          </Space>
        </Row>
      }
    >
      <Search
        allowClear
        style={{ marginBottom: 8 }}
        placeholder="input search text"
        onSearch={(value) => {
          if (value.trim()) {
            getCategoryDataBySearch(value);
          } else {
            setSelected([]);
            getCategoryData(
              {
                categoryId: 0,
                categoryName: "",
                categoryParentId: 0,
                level: 0,
                isLeaf: false,
                categoryPath: "",
              },
              0
            );
          }
        }}
        enterButton
      />
      {selected.length > 0 && !searchMode ? (
        <Row justify="space-between" align="middle">
          <Breadcrumb>
            {selected.map((i, index) => (
              <Breadcrumb.Item key={i.categoryId}>
                <Typography.Link
                  onClick={() => {
                    getCategoryData(i, index);
                  }}
                >
                  {i.categoryName}
                </Typography.Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Button
            type="link"
            onClick={() => {
              getCategoryData(
                {
                  categoryId: 0,
                  categoryName: "",
                  categoryParentId: 0,
                  level: 0,
                  isLeaf: false,
                  categoryPath: "",
                },
                -1
              );
            }}
            icon={<CloseOutlined />}
          />
        </Row>
      ) : (
        <Row></Row>
      )}
      {searchMode ? (
        <List
          style={{
            height: 400,
            overflow: "auto",
            backgroundColor: "#fff",
          }}
          loading={loading}
          bordered
          dataSource={data}
          renderItem={(item: CategoryItem) => (
            <TItem
              actions={[
                <Button
                  size="small"
                  disabled={defaultselected.indexOf(item.categoryId) !== -1}
                  onClick={() => onRefresh(item, [...selected, item])}
                >
                  {defaultselected.indexOf(item.categoryId) !== -1
                    ? "Selected"
                    : "Select"}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  defaultselected.indexOf(item.categoryId) !== -1 ? (
                    <StarFilled />
                  ) : (
                    <StarOutlined />
                  )
                }
                title={item.categoryName}
                description={item.categoryPath}
              />
            </TItem>
          )}
        />
      ) : (
        <List
          style={{
            height: 400,
            overflow: "auto",
            backgroundColor: "#fff",
          }}
          loading={loading}
          bordered
          dataSource={data}
          renderItem={(item: CategoryItem) => (
            <TItem
              onClick={() => {
                if (!item.isLeaf) {
                  getCategoryData(item, -2);
                }
              }}
              actions={
                item.isLeaf
                  ? [
                      <Button
                        size="small"
                        disabled={
                          defaultselected.indexOf(item.categoryId) !== -1
                        }
                        onClick={() => onRefresh(item, [...selected, item])}
                      >
                        {defaultselected.indexOf(item.categoryId) !== -1
                          ? "Selected"
                          : "Select"}
                      </Button>,
                    ]
                  : [<RightOutlined />]
              }
            >
              <Typography.Text>
                {defaultselected.indexOf(item.categoryId) !== -1 ? (
                  <StarFilled />
                ) : (
                  <StarOutlined />
                )}
              </Typography.Text>{" "}
              {item.categoryName}
            </TItem>
          )}
        />
      )}
    </ModalDialog>
  );
};

export default SelectCategoryComponent;
