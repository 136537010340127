import React, { useCallback } from 'react';
import { Button, Card, Col, Empty, message, Row, Space, /*Switch,*/ Tag, Typography, } from 'antd';
import styled from 'styled-components';

import CategorySelector from "../../../../components/common/SelectCategory";
import Loading from '../../../../components/common/Loading';
import ScreenMask from '../../../../components/common/ScreenMask';
import { Label } from '../../../../components/common/styledComponents';
import {
  LOADING_ICON_SIZE1,
} from '../../../../constants/config';
import { createChannelTemplateByCategory, fetchChannelAccountCategories } from "./../../../../services/ChannelIntegration";

type CategoryItem = {
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  level: number;
  isLeaf: boolean;
  categoryPath: string;
  treeStr: string;
};

type Props = {
  channelAccountNum: string;
  channelNum: string;
  channelName: string;
};

const ContainerWrapper = styled(Card)`
  & .category-board .ant-tag {
    white-space: normal;
  }

  & .category-left-panel {
    border-right: 1px solid #D9D9D9;
    padding-right: 6px;
  }

  & .category-right-panel {
    border-left: 1px solid #D9D9D9;
    padding-left: 6px;
  }

  & .category-titlebar {
    height: 32px;
  }
`;

const ChannelCategory = (props: Props) => {
  const { useState } = React;
  const [categorySelectorVisible, setCategoryVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMerge, setIsMerge] = useState(false);
  const [rawCategories, setRawCategories] = useState<StringKAnyVPair[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<CategoryItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const closeCategorySelector = () => {
    setCategoryVisible(false);
  };

  const openCategorySelector = () => {
    setCategoryVisible(true);
  };

  // eslint-disable-next-line
  const getSelectedCategories = async () => {
    setIsLoading(true);

    try {
      const res = await fetchChannelAccountCategories(
        props.channelNum as any,
        props.channelAccountNum as any,
      );

      if (Array.isArray(res)) {
        // console.log('res', res);
        setRawCategories([...res]);
      }
    } catch(e) {
    } finally {
      setIsLoading(false);
    }
  };

  const generateTemplate = useCallback(async () => {
    try {
      setLoading(true);

      const res = await createChannelTemplateByCategory(
        props.channelAccountNum,
        selectedCategories.map((i) => i.categoryId),
        isMerge,
        props.channelNum,
      );

      if (res) {
        message.success("Generate template successfully");
        setSelectedCategories([]);
        setIsMerge(false)
        getSelectedCategories();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [getSelectedCategories, selectedCategories, props, isMerge]);

  const renderRawCategories = () => {
    return rawCategories.map(e => (
      <Row key={`raw-cat-${e.categoryId}`}>
        <Tag
          style={{ marginBottom: 8, fontSize: 14 }}
        >
           <Typography.Paragraph style={{marginBottom: 0}} copyable>{e.fullPath}</Typography.Paragraph>
           {/* {e.fullPath} */}
        </Tag>
      </Row>
    ));
  };

  const getAllFullPath = () => {
    let t = '';
    rawCategories.map(i=>{
      t = `${t}${i.fullPath}\n`
      return true
    })
    return t
  }

  React.useEffect(() => {
    if (!inited) {
      getSelectedCategories();
      setInited(true);
    }
  }, [getSelectedCategories, inited]);

  return (
    <>
      <ContainerWrapper>
        <Row className="category-board">
          <Col span={12} className="category-left-panel">
            <Row className="category-titlebar" justify="space-between">
              <Label className="label-bold">Selected Categories</Label>
              {rawCategories.length > 0 && <Typography.Text copyable={{text: getAllFullPath()}}>Copy All</Typography.Text>}
            </Row>
            <Card type="inner" style={{ marginTop: 12, width: '100%', minHeight: 100 }}>
              {rawCategories.length === 0 && (
                <Empty
                  description={
                    <Typography.Text type="secondary">
                      No selected categories
                    </Typography.Text>
                  }
                />
              )}
              {rawCategories.length > 0 && renderRawCategories()}
            </Card>
          </Col>
          <Col span={12} className="category-right-panel">
            <Button onClick={openCategorySelector}>Select Category</Button>
            <Card type="inner" style={{ marginTop: 12, width: '100%', minHeight: 100 }}>
            {selectedCategories.length === 0 && (
              <Empty
                description={
                    <Typography.Text type="secondary">
                      No categories selected
                    </Typography.Text>
                }
              />
            )}
            {selectedCategories.map((i) => (
              <Row key={i.categoryId}>
                <Tag
                  style={{ marginBottom: 8, fontSize: 14 }}
                  closable
                  onClose={() => {
                    setSelectedCategories((prev) => {
                      const temp = prev.filter(
                        (item) => item.categoryId !== i.categoryId
                      );

                      return [...temp];
                    });
                  }}
                >
                  {i.treeStr}
                </Tag>
              </Row>
            ))}
            </Card>
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: 12 }}>
          <Space>&nbsp;{/*<Typography.Text>Combine all categories in one</Typography.Text><Switch checked={isMerge} onChange={(e)=> setIsMerge(e)}/>*/}</Space>
          <Button
            type="primary"
            loading={loading}
            onClick={() => generateTemplate()}
            disabled={selectedCategories.length === 0}
          >
            Generate Template
          </Button>
        </Row>
      </ContainerWrapper>
      {categorySelectorVisible && (
        <CategorySelector
          visible
          onHide={closeCategorySelector}
          defaultselected={selectedCategories.map((i) => i.categoryId)}
          channelName={props.channelName}
          onRefresh={(data, tree) => {
            if (data) {
              if (
                selectedCategories.findIndex(
                  (i) => i.categoryId === data.categoryId
                ) === -1
              ) {
                const treeStr = tree.map(i=> i.categoryName).toString().replaceAll(',',' -> ')
                setSelectedCategories((prev) => {
                  return [...prev, {...data, treeStr}];
                });
              }
            }
            // closeCategorySelector();
          }}
        />
      )}
    {isLoading && <ScreenMask>
      <Loading size={LOADING_ICON_SIZE1} />
    </ScreenMask>}
    </>
  );
};

export default ChannelCategory;
