import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/ContentLayout";
import { Button, Card, Col, Input, Modal, Radio, Row, notification } from "antd";
import Spacer from "../../components/common/Spacer";
import Heading from "../../components/common/Heading";
import { SaveOutlined } from "@ant-design/icons";
import SiteContent from "../../components/SiteContent";
import { FormLabel } from "../../components/common/styledComponents";
import { fetchProductMediaFileSettings, updateProductMediaFileSettings, saveConnectString } from "../../services/productMediaFileService";
import theme from "../../assets/styles/theme";

type SetConnectStringModalProps = {
  visible: boolean;
  connectionString: string;
  // connectionStringName: string;
  // setConnectionStringName: Function;
  setconnectionString: Function;
  onClose: Function;
}
const SetConnectStringModal = (props: SetConnectStringModalProps) => {
  const { visible, connectionString, setconnectionString, onClose } = props;
  const [saving, setSaving] = useState<boolean>(false);
  // const [tempName, setTempName]= useState<string>(connectionStringName);
  const [tempString, setTempString] = useState<string>(connectionString);

  const handleOk = async () => {
    setSaving(true);
    try {
      const { data } = await saveConnectString({ connectionString: tempString, connectionStringName: '' });
      if (typeof data === 'string') {
        setconnectionString(data);
      }
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  }

  return (
    <Modal
      visible={visible}
      title="Set Connect String"
      okText="Save"
      maskClosable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleOk}
      onCancel={() => onClose()}
      okButtonProps={{ loading: saving }}
    >
      {/* <Row>
        <Col span={12}><FormLabel>Name</FormLabel></Col>
        <Col><Input value={connectionStringName} onChange={e => setConnectionStringName(e.target.value)} /></Col>
      </Row> */}
      <Row style={{ marginTop: 10 }}>
        <Col span={12}><FormLabel>Connect String</FormLabel></Col>
        <Col>
          <Input.Password
            value={tempString}
            onChange={e => setTempString(e.target.value)}
          />
        </Col>
      </Row>
    </Modal>
  )
}

const FileManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [pmfSettingNum, setPmfSettingNum] = useState<number>(0);
  // const [connectionStringName, setConnectionStringName] = useState<string>('');
  const [connectionString, setconnectionString] = useState<string>('');
  const [defaultContainer, setDefaultContainer] = useState<string>('');
  const [enableVirtualFolder, setEnableVirtualFolder] = useState<number>(0);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);

  const getFileManagementSettings = async () => {
    setLoading(true);
    setSaveLoading(true);
    try {
      const { data } = await fetchProductMediaFileSettings();
      const {
        pmfSettingNum = 0,
        connectionString = '',
        settingExporession
      } = data;
      const { defaultContainer = '', enableVirtualFolder = 0 } = settingExporession;
      setPmfSettingNum(pmfSettingNum);
      // setConnectionStringName(connectionStringName);
      setconnectionString(connectionString);
      setDefaultContainer(defaultContainer);
      setEnableVirtualFolder(enableVirtualFolder);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSaveLoading(false);
    }
  }

  const saveSettings = async () => {
    if (showErrMsg) return false;
    try {
      setSaveLoading(true);
      const params = {
        pmfSettingNum,
        connectionStringName: '',
        connectionString,
        serviceProvider: 1,
        settingExporession: { defaultContainer, enableVirtualFolder }
      }
      const { data } = await updateProductMediaFileSettings(params);
      if (data.isSuccess) {
        notification.success({
          message: data.message || 'Saving File Manage Settings successfully!',
        });
        setTimeout(() => {
          getFileManagementSettings();
        }, 800);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  }

  useEffect(() => {
    getFileManagementSettings();
  }, [])

  return (
    <ContentLayout>
      <Heading
        title="File Management"
        actions={(
          <Row gutter={12}>
            <Col>
              <Button
                type="primary"
                loading={saveLoading}
                // disabled={settings.length === 0}
                onClick={saveSettings}
              >
                <SaveOutlined />
                Save
              </Button>
            </Col>
          </Row>
        )}
      />
      <Spacer />
      <SiteContent flexGrow>
        <Card title="Azure Blob Storage" loading={loading}>
          <Row>
            <Button
              type="primary"
              onClick={() => {
                setConfirmModalVisible(true);
              }}
            >
              Set Connect String
            </Button>
          </Row>
          <Row wrap={false} gutter={8} align="middle" style={{ marginTop: 10 }}   >
            <Col>
              <FormLabel>Default Container</FormLabel>
            </Col>
            <Col>
              <Input
                allowClear
                value={defaultContainer}
                onChange={e => {
                  const { value } = e.target;
                  // eslint-disable-next-line 
                  const reg = /^[a-z0-9\-]{3,24}$/;
                  if (reg.test(value)) {
                    setShowErrMsg(false);
                  } else {
                    setShowErrMsg(true);
                  }
                  setDefaultContainer(e.target.value);
                }}
              />
            </Col>
            {/* <Col>
              <Button>Choose</Button>
            </Col> */}
          </Row>
          {showErrMsg && (
            <Row><span style={{ color: theme['@danger-color'] }}>Default container names must be between 3 and 24 characters long and may contain numbers and lowercase letters only</span></Row>
          )}
          {process.env.NODE_ENV !== 'production' && <Row wrap={false} gutter={8} style={{ marginTop: 10 }}   >
            <Col>
              <FormLabel>Enable Virtual Folder</FormLabel>
            </Col>
            <Col>
              <Radio.Group value={enableVirtualFolder} onChange={e => setEnableVirtualFolder(e.target.value)}>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Col>
          </Row>}
        </Card>
        {confirmModalVisible && (
          <SetConnectStringModal
            visible={confirmModalVisible}
            connectionString={connectionString}
            setconnectionString={setconnectionString}
            onClose={() => { setConfirmModalVisible(false) }}
          />
        )}
      </SiteContent>
    </ContentLayout >
  )
}

export default FileManagement;