import { DeploymentUnitOutlined, InfoCircleFilled, UnorderedListOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, useParams, useRouteMatch } from 'react-router-dom';
import { IntegrationsState } from '../../redux/reducers/integrations';
import { ChannelIntegrationRoutes } from '../../screens/ChannelsIntegrations';
import Heading, { Title } from '../common/Heading';
import {disableChannelOrderDownload, enableChannelOrderDownload} from '../../services/channels'


// const MappingOptions: React.FC = () => {
//   const match = useRouteMatch();

//   const productsPath = generatePath(ChannelIntegrationRoutes.mapping, {
//     ...match.params,
//     mappingType: 'products',
//   });

//   return (
//     <Menu>
//       <Menu.Item key="products">
//         <Link to={productsPath}>
//           Products
//         </Link>
//       </Menu.Item>
//       <Menu.Item key="inventory" disabled>
//         Inventory
//       </Menu.Item>
//       {/* <Menu.Item key="orders" disabled>
//         Orders
//       </Menu.Item>
//       <Menu.Item key="shipping" disabled>
//         Shipping
//       </Menu.Item>
//       <Menu.Item key="invoices" disabled>
//         Invoices
//       </Menu.Item>
//       <Menu.Item key="refunds" disabled>
//         Refunds
//       </Menu.Item> */}
//     </Menu>
//   );
// };
const MappingBtn: React.FC = () => {
  const match = useRouteMatch();

  const productsPath = generatePath(ChannelIntegrationRoutes.mapping, {
    ...match.params,
    mappingType: 'products',
  });

  return (
    <Link to={productsPath}>
      <Button>
          <DeploymentUnitOutlined />
          Mapping
      </Button>
    </Link>
  );
};


const PageHeadingActions: React.FC<{ integration?: Entities.ChannelAccountObj, channelAccountNum: string }> = ({ integration, channelAccountNum }) => {
  const match = useRouteMatch();

  const [disableLoading, setDisableLoading] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);

  const enableDownload = useCallback(async()=>{
    try {
      setEnableLoading(true)
      await enableChannelOrderDownload(channelAccountNum)
      setEnableLoading(false)
      message.success('Enable channel order download successfully')
    } catch (error) {
      setEnableLoading(false)
    }
  },[channelAccountNum])

  const disableDownload = useCallback(async()=>{
    try {
      setDisableLoading(true)
      await disableChannelOrderDownload(channelAccountNum)
      setDisableLoading(false)
      message.success('Disable channel order download successfully')
    } catch (error) {
      setDisableLoading(false)
    }
  },[channelAccountNum])

  if (!integration) {
    return null;
  }

  const detailsPath = generatePath(ChannelIntegrationRoutes.details, match.params);
  // const productsPath = generatePath(ChannelIntegrationRoutes.mapping, {
  //   ...match.params,
  //   mappingType: 'products',
  // });

  return (
    <Row gutter={12}>
      <Col>
        <Link to={detailsPath}>
          <Button>
            <InfoCircleFilled />
            Setting Details
          </Button>
        </Link>
      </Col>
      {false && (
        <Col>
          <Button loading={enableLoading} onClick={() => enableDownload()}>
            Enable Channel Order Download
          </Button>
        </Col>
      )}
      {false && (
        <Col>
          <Button loading={disableLoading} onClick={() => disableDownload()}>
            Disable Channel Order Download
          </Button>
        </Col>
      )}
      <Col>
        <MappingBtn />
      </Col>
      <Col>
        <Button disabled>
          <UnorderedListOutlined />
          Logs
        </Button>
      </Col>
    </Row>
  );
};


const Header: React.FC = () => {
  const { channelNum, channelAccountNum } = useParams<{ channelNum: string, channelAccountNum: string }>();
  const selectChannel = useCallback((channels: Entities.ChannelsIntegration[]) => channels.find(c => c.channelNum === Number(channelNum)), [channelNum]);
  const selectedChannel: Entities.ChannelsIntegration | undefined = useSelector((state: any) => selectChannel(state.integrations.channels));
  const integration = useSelector(({ integrations }: { integrations: IntegrationsState }) => integrations.channelAccount);

  const pageTitle = useMemo(() => {
    let title = 'Channel Integration';

    if (selectedChannel) {
      title += ` - ${selectedChannel.channelName}`;
    }

    if (channelAccountNum) {
      if (integration && integration.ChannelAccountNum.toString() === channelAccountNum) {
        title += ` - ${integration.ChannelAccountName}`;
      }
    } else {
      // if channelAccountNum is null, means new connection which means integration passed to PageHeadingActions should be undefined.. 
      title += ' - New Connection';
    }

    return title;
  }, [selectedChannel, integration, channelAccountNum]);


  return (
    <Heading
      title={
        <Space>
          <Tooltip placement="leftTop" title="back">
            <Link to="/settings-channels-integrations"><RollbackOutlined /></Link>
          </Tooltip>
          <Title id='HeadingID' as={'h1' as React.ElementType}>{pageTitle}</Title>
        </Space>
      }
      actions={<PageHeadingActions channelAccountNum={channelAccountNum} integration={channelAccountNum !== undefined ? integration : undefined} />}
    />
  );
};

export default Header;
