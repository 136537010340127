import React, { useCallback, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  // Radio,
  Upload,
  Switch,
} from "antd";
import { RcFile } from "antd/lib/upload/interface";
import styled from 'styled-components';
//import { useUploadMappingTemplateMutation } from "../../redux/api/templates";
import { editCustomTemplate, uploadCustomTemplate } from '../../services/ChannelIntegration';

const validExcelMimeTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface UploadModalProps extends Omit<ModalProps, "onOk"> {
  channelAccountNum?: number;
  channelNum: number;
  mode?: number;
  onOk?: (shouldRefresh: boolean) => void;
  templateData: StringKAnyVPair;
  templateName?: string;
  templateType: number;
  title: string;
}

interface UploadForm {
  categoryStr?: string;
  templateName?: string;
  fileSelected: boolean;
}

const DialogBody = styled.div`
  & .input-field1-ctn .ant-form-item-control {
    width: 100%;
  }
`;

const UploadModal: React.FC<UploadModalProps> = ({
  templateName,
  channelNum,
  templateData,
  templateType,
  title,
  channelAccountNum,
  onOk,
  mode,
  ...modalProps
}) => {
  /*const [
    uploadMappingTemplate,
    { status: uploadTemplateStatus },
  ] = useUploadMappingTemplateMutation();*/
  //const processing = uploadTemplateStatus === "pending";
  //const [form] = Form.useForm<UploadForm>(getFormByTemplate(templateData) as any);
  const [form] = Form.useForm<UploadForm>();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [tempType, setTemType] = useState<0 | 1>(0);
  const [saveOrigin, setSaveOrigin] = useState(true);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      form.setFieldsValue({ fileSelected: false });

      if (validExcelMimeTypes.indexOf(file.type) < 0) {
        message.error(`${file.name} is not a spreadsheet file`).then();
      } else {
        form.setFieldsValue({ fileSelected: true });
        setFileList([file]);
      }

      return false;
    },
    [form]
  );

  const { onCancel } = modalProps;

  const onSubmit = useCallback(
    async (values: UploadForm) => {
      // console.log('on submit', values.categoryStr, saveOrigin);
      // return;
      if (mode === 0 && fileList.length === 0) {
        return;
      }

      try {
        const data = new FormData();

        setIsLoading(true);
        data.append("file", fileList[0]);

        if (mode === 1) {
          const res = await editCustomTemplate(
            channelAccountNum as any,
            templateData.productMappingNum,
            { channelNum },
            { categoryNames: savableCategoryString(values.categoryStr) },
          );

          if (res === true) {
            // console.log('res', res);
            setFileList([]);
            onOk?.(res);
            message.success('Saved successfully');
          }
        } else {
          const res = await uploadCustomTemplate(
            { channelAccountNum, channelNum },
            { mappingType: 4, templateName: values.templateName, categoryNames: values.categoryStr, downloadFrom: saveOrigin ? 1 : 0 },
            data,
          );
        
          //console.log('res', res);
          const error = Object.prototype.hasOwnProperty.call(res, "error");
          if (error) {
            await message.error(
              "There was an error uploading the file, please try again later"
            );
            return;
          }
          setFileList([]);
          onOk?.(!error);
          await message.success("File uploaded successfully");
        }
      } catch (e) {
        await message.error(
          "There was an error uploading the file, please try again later"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      onOk,
      fileList,
      //uploadMappingTemplate,
      //templateType,
      channelNum,
      channelAccountNum,
      mode,
      saveOrigin,
      templateData,
    ]
  );

  const onModalCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      form.resetFields();
      setFileList([]);
      onCancel?.(e);
    },
    [onCancel, form]
  );

  const savableCategoryString = (cs: any) => {
    if (typeof cs === 'string') {
      return cs.trim().split('\n').filter(e => e.trim()).join(',');
    }
  };

  const splitCategoryString = (cs: any) => {
    if (typeof cs === 'string') {
      return cs.trim().replace(/,/g, '\n');
    }
  };

  useEffect(() => {
    if (!inited) {
      form.setFieldsValue({
        templateName: templateData.templateName,
        categoryStr: splitCategoryString(templateData.category),
      });

      if (mode === 1) setSaveOrigin(templateData.downloadFrom === 1 ? true : false);

      setInited(true);
    }
  }, [
    form,
    inited,
    mode,
    templateData,
  ]);

  return (
    <Form form={form} onFinish={onSubmit}>
      <Modal
        cancelButtonProps={{ disabled: isLoading }}
        closable={!isLoading}
        centered
        forceRender
        maskClosable={!isLoading}
        okButtonProps={{
          loading: isLoading,
          htmlType: "submit",
          onClick: form.submit,
        }}
        onCancel={onModalCancel}
        okText='Submit'
        title={title}
        visible
        width={700}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
      >
        <DialogBody>
        {/*<Form.Item label="">
          <Radio.Group
            value={tempType}
            onChange={(e) => setTemType(e.target.value)}
          >
            <Radio value={0}>Catalog</Radio>
            <Radio value={1}>Standard</Radio>
          </Radio.Group>
        </Form.Item>*/}
        <Form.Item label="Downloaded catalog template from channel">
          <Switch
            checked={saveOrigin}
            checkedChildren="Yes"
            disabled={mode === 1}
            onChange={(checked) => setSaveOrigin(checked)}
            unCheckedChildren="No"
          />
        </Form.Item>
        {saveOrigin && <Form.Item
          className="input-field1-ctn"
          label="Category"
          name="categoryStr"
          rules={[
            //{ required: !form.getFieldValue('categoryStr'), message: 'Thie field is required' },
          ]}
        >
          <Input.TextArea
            disabled={isLoading}
            rows={4}
          />
        </Form.Item>}
        {templateType && [4, 5, 6].includes(templateType) && (
          <>
            <Form.Item
              className="input-field1-ctn"
              label="Template Name"
              name="templateName"
              rules={[
                { required: !templateName, message: "This field is required" },
              ]}
            >
              <Input
                disabled={mode === 1 || isLoading || !!templateName}
                readOnly={!!templateName}
              />
            </Form.Item>
          </>
        )}
        {mode !== 1 && <Form.Item
          name="fileSelected"
          rules={[{ required: true, message: "Please add a file." }]}
        >
          <Upload.Dragger
            fileList={fileList}
            name="template"
            multiple={false}
            beforeUpload={beforeUpload}
            disabled={isLoading}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag a file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>}
        </DialogBody>
      </Modal>
    </Form>
  );
};

export default UploadModal;
