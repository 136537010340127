export enum NotificationActions {
  SET_NOTIFICATION = 'notifications/setNotification',
  CLEAR = 'notifications/clear',
}

interface SetNotificationAction {
  type: typeof NotificationActions.SET_NOTIFICATION,
  notificationType: string,
  message: string,
}

interface ClearNotificationAction {
  type: typeof NotificationActions.CLEAR
}

export type NotificationsActionTypes = SetNotificationAction | ClearNotificationAction;
