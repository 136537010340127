import { ProfileSettingsAction, ProfileSettingsActionType } from '../types/profilesSettings';

const setProfileSettings = (profileSettings: Entities.ProfileSetting[]): ProfileSettingsActionType => ({
  type: ProfileSettingsAction.SET_PROFILESETTINGS,
  profileSettings,
});

export default {
  setProfileSettings
};
