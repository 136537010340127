import { Drawer, List } from 'antd';
import React from 'react';

type ViewProps = {
  selectedRows: Array<any>;
  onClose: () => void;
};

export default function ProductsView(props: ViewProps): JSX.Element {
  const { onClose, selectedRows } = props;
  return (
    <Drawer title="Selectd Products" placement="right" onClose={onClose} visible={true}>
      <div
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <List
          itemLayout="horizontal"
          bordered={false}
          dataSource={selectedRows}
          renderItem={(item) => <List.Item>{item.SKU}</List.Item>}
        />
      </div>
    </Drawer>
  );
}
