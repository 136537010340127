import { Button, Col, Modal, Row, Space, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CloseOutlined, FullscreenExitOutlined, FullscreenOutlined, LinkOutlined } from '@ant-design/icons';
import CSVLink, { CSVColumns } from '../components/common/CSVLink';
import Heading from '../components/common/Heading';
import LoadingIcon from '../components/common/LoadingIcon';
import Spacer from '../components/common/Spacer';
import ContentLayout from '../components/ContentLayout';
import Filters from '../components/Orders/Filters';
import SiteContent from '../components/SiteContent';
import { ListOrdersParams, useListOrdersQuery } from '../redux/api/orders';
import { OrderStatus } from '../types/enums';
import { formatDate } from '../util';
import { useFilterPagination } from '../util/hooks';
import { enumNameToLabel } from '../util/strings';
import OrderDetailsScreen from './OrderDetails';

const OrderDetailModalWrapper = styled.div`
  height: calc(100vh - 260px);
  overflow-y: auto;
  &.fullscreen-mode {
    height: calc(105vh - 168px);
  }
`;
const OrderNumberButton = styled.div`
  color: #006dff;
  cursor: pointer;
`;

const csvColumns: CSVColumns<Entities.SimpleOrder> = [
  {
    key: 'centralOrderNum',
    header: 'Channel Order #',
  },
  {
    key: 'channelName',
    header: 'Channel',
  },
  {
    key: 'channelOrderID',
    header: 'Channel Order ID',
  },
  {
    key: 'originalOrderDateUtc',
    header: 'Date',
  },
  {
    key: 'endBuyerName',
    header: 'Bill To',
  },
  {
    key: 'shipToName',
    header: 'Ship To',
  },
  {
    key: 'endBuyerEmail',
    header: 'Customer Email',
  },
  /*{
    key: 'subtotalOrderAmount',
    header: 'Subtotal',
  },
  {
    key: 'totalOrderAmount',
    header: 'Grand Total',
  },*/
  {
    key: 'orderStatus',
    header: 'Status',
    transform: v => enumNameToLabel(OrderStatus[v.orderStatus]),
  },
];

const initialData = {
  orders: [],
  count: 0,
};


const initialSelectedOrderDetail = {
  id: '',
  number: 0,
};

interface SelectedOrderDetailType {
  id: string,
  number: number,
}
const Page: React.FC = () => {
  const [
    pagination,
    filters,
    updatePagination,
    updateFilters,
    updateTotalCount,
  ] = useFilterPagination<ListOrdersParams>({
    defaultPagination: {
      pageSize: 20,
    },
  });
  const { data = initialData, isFetching } = useListOrdersQuery(filters, { skip: Object.keys(filters).length === 2 });
  const { orders, count } = data;
  const [openOrderDetailModal, setOpenOrderDetailModal] = useState<boolean>(false);
  const [orderDetailSelected, setOrderDetailSelected] = useState<SelectedOrderDetailType>(initialSelectedOrderDetail);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState<boolean>(false);
  const onOpenOrderDetailModal = useCallback(({ id, number }: SelectedOrderDetailType) => {
    setOpenOrderDetailModal(true);
    setOrderDetailSelected({
      id, number,
    });
  }, []);
  const toggleDetailDialogFullscreen = () => {
    setDetailDialogIsFullscreen(!detailDialogIsFullscreen);
  };

  const onCloseModalClick = useCallback(() => { setOpenOrderDetailModal(false); }, []);


  const detailDialogWidth = useCallback(() => {
    if (detailDialogIsFullscreen) {
      return window.innerWidth;
    }

    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;

  }, [detailDialogIsFullscreen]);

  const columns: ColumnsType<Entities.SimpleOrder> = [
    {
      title: 'Channel Order #',
      dataIndex: 'centralOrderNum',
      width: 150,
      render(value:string, record: Entities.SimpleOrder) {
        return <OrderNumberButton onClick={() => { onOpenOrderDetailModal({ id: record.digitbridgeOrderId, number: record.centralOrderNum }); }}>{value}</OrderNumberButton>;
      },
    },
    {
      title: 'Channel',
      dataIndex: 'channelName',
    },
    {
      title: 'Channel Order ID',
      dataIndex: 'channelOrderID',
      ellipsis: {
        showTitle: false,
      },
      render(value: string) {
        return (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'originalOrderDateUtc',
      ellipsis: {
        showTitle: false,
      },
      render(value: string) {
        const formattedValue = formatDate(value, 'dateTime');

        return (
          <Tooltip placement="topLeft" title={formattedValue}>
            {formattedValue}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'endBuyerName',
      title: 'Bill To',
      ellipsis: {
        showTitle: false,
      },
      render(value: string) {
        return (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'shipToName',
      title: 'Ship To',
      ellipsis: {
        showTitle: false,
      },
      render(value: string) {
        return (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'endBuyerEmail',
      title: 'Customer Email',
      ellipsis: {
        showTitle: false,
      },
      render(value: string) {
        return (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    /*{
      dataIndex: 'subtotalOrderAmount',
      title: 'Subtotal',
      width: 100,
    },
    {
      dataIndex: 'totalOrderAmount',
      title: 'Grand Total',
      width: 100,
    },*/
    {
      title: 'Status',
      width: 100,
      dataIndex: 'orderStatus',
      render: (value: number) => enumNameToLabel(OrderStatus[value]),
    },
  ];

  useEffect(() => {
    updateTotalCount(count);
  }, [count, updateTotalCount]);

  return (
    <>
      <ContentLayout>
        <Heading title="Channel Sales Order" />
        <Spacer />
        <SiteContent>
          <Row justify="space-between">
            <Col span={24}>
              <Filters onFilter={updateFilters} />
            </Col>
          </Row>
          <Spacer />
          <Row>
            <Col offset={22} span={2}>
              <CSVLink
                style={{ float: 'right', marginTop: '-46px' }}
                data={orders}
                columns={csvColumns}
                filename="orders.csv"
                disabled={isFetching}
                type="default"
              />
            </Col>
          </Row>
          <Spacer />
          { orders.length === 0 ? (
            <Spin indicator={<LoadingIcon />} style={{ margin: 'auto' }} spinning={isFetching} />
          ) : (
            <Table
              rowKey="uid"
              columns={columns}
              dataSource={orders}
              loading={isFetching}
              pagination={pagination}
              onChange={updatePagination}
              scroll={{ x: '100vw', y: '35vh' }}
              size="small"
              bordered
            />
          ) }
          {Object.keys(filters).length > 2 && orders.length === 0 && !isFetching && (
            <h3 style={{ margin: 'auto' }}>No results</h3>
          )}
        </SiteContent>
      </ContentLayout>
      <Modal
        centered
        className="fullscreen-modal"
        title={(
          <Row align="middle" justify="space-between">
            <Link to={`/order-details/${orderDetailSelected.id}`}>
              <span>
                Order -
                {' '}
                {orderDetailSelected.number}
              </span>
            </Link>
            <Space>
              <Button
                type="link"
                href={`/order-details/${orderDetailSelected.id}`}
                target="_blank"
                style={{ border: '1px solid #D9D9d9', color: '#000' }}
              >
                <LinkOutlined />
                Open as a new tab
              </Button>
              <Button onClick={toggleDetailDialogFullscreen}>
                {detailDialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                {detailDialogIsFullscreen ? 'Exit' : 'Enter'}
                {' '}
                Fullscreen
              </Button>
              <Button onClick={onCloseModalClick}>
                <CloseOutlined />
                Close
              </Button>
            </Space>
          </Row>
        )}
        visible={openOrderDetailModal}
        footer={null}
        onCancel={() => { setOpenOrderDetailModal(false); }}
        closable={false}
        style={{ paddingBottom: 0 }}
        width={detailDialogWidth()}
      >
        <OrderDetailModalWrapper
          className={detailDialogIsFullscreen ? 'fullscreen-mode' : ''}
        >
          <OrderDetailsScreen
            idArg={orderDetailSelected.id}
            origin="modal"
          />
        </OrderDetailModalWrapper>
      </Modal>
    </>
  );
};

export default Page;
