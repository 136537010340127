import { FormInstance } from 'antd/es/form';
import React, { createContext } from 'react';
import { ProductTitle } from '../ProductDetail/styledComponents';
import Products from '../../services/products';

export enum Actions {
  SET_LOADING,
  SET_PRODUCT_DATA,
  SET_CHANNELS,
  SET_EDITMODE,
  RESET_ATTRIBUTE,
  SET_PAGE_TITLE,
  SET_ATTRIBUTE_DATA,
  SET_SHOWADDOPTIONSMODAL,
  SET_SELECTEDOPTIONS,
  SET_FORMUPDATED,
  SET_NEWOPTION,
  MOVE_OPTION,
  FILTER_OPTIONS,
  SET_CLASSIFICATIONS,
  INIT_FORM,
  SET_EDITINGOPTION,
  SET_CLASSIFICATION_OPTIONLIST,
  SET_ATTRIBUTETYPE,
  SET_SELECTEDROWS,
  SET_NORMAL_OPTIONLIST,
  SET_RELOAD,
  CLEAN_DEFAULT_VALUE,
  SET_TEMP_NEW_OPTION,
  CANCEL_MODAL_CHANGES,
  SET_USECOMMONATRRIBUTE,
  SET_TEMPLATE_VALUES,
}


export interface AttributeDetailState {
  pageTitle: any;
  loading: boolean;
  attribute: Entities.ProductAttribute | null;
  form: FormInstance;
  channels: Array<any>;
  editMode: boolean;
  isFormUpdated: boolean;
  showAddOptionsModal: boolean;
  selectedOptions: any;
  selectedOptionsTemp: Array<object>;
  selectedOptionsBack: any;
  unselectedOptions: any;
  unselectedOptionsBack: any;
  classifications: any;
  classificationsBack: any;
  editingOption: number,
  attributeType: number;
  selectedRowKeys: Array<number>;
  attributeNum: number;
  attributeData: any;
  reload: boolean;
  useCommonAttribute: string;
  templatesValues: any[];
}

export const generatePageTitle = (title: string): any => {
  let titleFormatted = 'Attribute - New';
  if (title) {
    titleFormatted = `Attribute - ${title}`;
  }
  return (
    <div>
      <ProductTitle>{titleFormatted}</ProductTitle>
    </div>
  );
};


export const initialState = ({ form }: { form: FormInstance }): AttributeDetailState => ({
  pageTitle: 'Attribute - New',
  loading: false,
  form,
  attribute: null,
  channels: [],
  editMode: false,
  isFormUpdated: false,
  showAddOptionsModal: false,
  selectedOptions: [],
  selectedOptionsTemp: [],
  selectedOptionsBack: [],
  unselectedOptions: [],
  unselectedOptionsBack: [],
  classifications: [],
  classificationsBack: [],
  editingOption: 0,
  attributeType: 1,
  selectedRowKeys: [],
  attributeNum: 0,
  attributeData: null,
  reload: true,
  useCommonAttribute: '0',
  templatesValues:[]
});

const setAttributeData = (state: AttributeDetailState, attributeData: any): AttributeDetailState => {
  let sel = [];
  let unsel = [];
  let classificationsClean = [];
  let classifications = [];
  let attributeClassificationsNumbers: any = [];
  if (attributeData.AttributeType === 2) {

    attributeClassificationsNumbers = attributeData?.Classifications ? attributeData?.Classifications.map((cla: any) => cla.ClassificationNum) : [];
    const originalClassifications = state.form.getFieldValue('Classifications');
    classificationsClean = originalClassifications.filter((obj: any) => attributeClassificationsNumbers.indexOf(obj.ClassificationNum) === -1);
    classifications = attributeData.Classifications ? attributeData.Classifications.concat(classificationsClean) : classificationsClean;
  }

  state.form.setFieldsValue({
    ...state.form.getFieldsValue(),
    AttributeChannelNum: attributeData.AttributeChannelNum,
    AttributeDataType: attributeData.AttributeDataType,
    AttributeName: attributeData.AttributeName,
    AttributeType: attributeData.AttributeChannelNum ? 2 : attributeData.AttributeType, //has channel - set type 2
    Group1: attributeData.Group1,
    Group2: attributeData.Group2,
    Classifications: classifications,
    AttributeOptionMasterList: attributeData.AttributeOptionMasterList,
    AttributeOptionList: attributeData.AttributeOptionList,
    DefaultValue: attributeData.DefaultValue,
    ChannelPricingRelated: !!attributeData.ChannelPricingRelated
  });

  if (attributeData.AttributeType === 1) {
    sel = attributeData.AttributeOptionList;
    unsel = attributeData.AttributeOptionMasterList;
  }

  return {
    ...state,
    attributeType: attributeData.AttributeChannelNum ? 2 : attributeData.AttributeType, // //has channel - set type 2
    selectedRowKeys: attributeClassificationsNumbers,
    attributeNum: attributeData.AttributeNum,
    selectedOptions: sel,
    selectedOptionsBack: sel,
    unselectedOptions: unsel,
    unselectedOptionsBack: unsel,
    classifications,
    pageTitle: `Attribute - ${attributeData.AttributeName}`,
    attributeData,
  };
};



export const saveAttribute = async (dispatch: Dispatcher, state: AttributeDetailState, isCreating: boolean, attributeNum: number) => {
  dispatch({ type: Actions.SET_LOADING, params: true });
  try {
    dispatch({ type: Actions.SET_FORMUPDATED, params: false });
    const dataToSave = state.form.getFieldsValue();
    // if (dataToSave.AttributeType === 2 && state.selectedRowKeys.length > 0) {
    //   dataToSave.Classifications = dataToSave.Classifications.filter((obj: any) => state.selectedRowKeys.indexOf(obj.ClassificationNum) > -1);
    // } else {
    //   dataToSave.Classifications = [];
    // }
    dataToSave.Classifications = [];  // Classifications had removed
    dataToSave.ChannelPricingRelated = dataToSave.ChannelPricingRelated ? 1 : 0;
    if (isCreating) {
      await Products.createAttribute({ ...dataToSave, AttributeType: 1 });  // always set attributeType to 1
    } else {
      await Products.editAttribute({ ...dataToSave, AttributeType: 1 }, attributeNum); // always set attributeType to 1
    }
    return await Promise.resolve();
  } catch (e) {
    return await Promise.reject(e);
  } finally {
    dispatch({ type: Actions.SET_LOADING, params: false });
  }
};


export const deleteAttribute = async (dispatch: Dispatcher, attributeNum: number) => {
  dispatch({ type: Actions.SET_LOADING, params: true });
  try {
    await Products.deleteAttribute(attributeNum);
    return await Promise.resolve();
  } catch (e) {
    return await Promise.reject(e);
  } finally {
    dispatch({ type: Actions.SET_LOADING, params: false });
  }
};


export const reducer = (state: AttributeDetailState, action: Utils.DispatchAction<Actions>) => {
  switch (action.type) {
    case Actions.CANCEL_MODAL_CHANGES:
      if (state.editingOption !== 0) {
        return {
          ...state,
          selectedOptionsTemp: state.form.getFieldValue('Classifications')[state.form.getFieldValue('Classifications').findIndex((fi: any) => fi.ClassificationNum === state.editingOption)].ClassificationOptionList,
        };
      }

      return {
        ...state,
        selectedOptionsTemp: state.form.getFieldValue('AttributeOptionList'),
      };

    case Actions.SET_TEMP_NEW_OPTION:
      state.form.setFieldsValue({
        ...state.form.getFieldsValue(),
        NewOption: '',
      });
      state.selectedOptionsTemp.unshift({ OptionValue: action.params });
      return { ...state };
    case Actions.CLEAN_DEFAULT_VALUE:
      state.form.setFieldsValue({
        ...state.form.getFieldsValue(),
        DefaultValue: undefined,
      });
      return { ...state };
    case Actions.SET_RELOAD:
      return { ...state, reload: action.params };
    case Actions.SET_NORMAL_OPTIONLIST:
      state.form.setFieldsValue({
        ...state.form.getFieldsValue(),
        AttributeOptionList: state.selectedOptions,
      });
      return { ...state };
    case Actions.SET_SELECTEDROWS:
      return { ...state, selectedRowKeys: action.params };
    case Actions.SET_FORMUPDATED:
      return { ...state, isFormUpdated: action.params };
    case Actions.SET_ATTRIBUTETYPE:
      return { ...state, attributeType: action.params };
    case Actions.SET_CLASSIFICATION_OPTIONLIST:
      // eslint-disable-next-line no-case-declarations
      const selectedIndex = state.form.getFieldValue('Classifications').findIndex((c: any) => c.ClassificationNum === state.editingOption);

      state.form.setFieldsValue({
        ...state.form.getFieldsValue(),
        Classifications: state.form.getFieldValue('Classifications').map((c: any, index: number) => {

          if (selectedIndex === index) {
            return {
              ClassificationName: c.ClassificationName,
              ClassificationNum: c.ClassificationNum,
              ClassificationOptionList: state.selectedOptionsBack,
            };
          }

          return c;
        }),
      });

      return { ...state };
    case Actions.SET_EDITINGOPTION:
      return { ...state, editingOption: action.params };
    case Actions.INIT_FORM:
      state.form.setFieldsValue({
        AttributeName: '',
        AttributeType:  action.params,
        AttributeDataType: 1,
        AttributeChannelNum: undefined,
        Group1: '',
        Group2: '',
        AttributeOptionMasterList: [],
        AttributeOptionList: [],
        DefaultValue: '',
        Classifications: [],
      });
      return { ...state };
    case Actions.SET_CLASSIFICATIONS:
      state.form.setFieldsValue({
        ...state.form.getFieldsValue(),
        Classifications: action.params.map((c: any) => ({
          ClassificationName: c.ClassificationName,
          ClassificationNum: c.ClassificationNum,
          ClassificationOptionList: [],
          DefaultValue: '',
        })),
      });
      return { ...state, classifications: action.params, classificationsBack: action.params };
    case Actions.FILTER_OPTIONS:
      return { ...state, selectedOptionsTemp: action.params.selected, unselectedOptions: action.params.unselected };
    case Actions.MOVE_OPTION:
      return {
        ...state,
        selectedOptionsTemp: [...action.params.newSelected],
        selectedOptionsBack: action.params.newSelected,
        unselectedOptions: action.params.newUnselected,
        unselectedOptionsBack: action.params.newUnselected,
      };
    case Actions.SET_NEWOPTION:
      if (state.attributeType === 2) {
        state.form.setFieldsValue({
          ...state.form.getFieldsValue(),
          Classifications: [
            {
              ...state.form.getFieldValue('Classifications').filter((f: any) => f.ClassificationNum === state.editingOption)[0],
              ClassificationOptionList: [
                ...state.selectedOptionsTemp,
              ],
            },
            ...state.form.getFieldValue('Classifications').filter((f: any) => f.ClassificationNum !== state.editingOption),
          ],
          AttributeOptionList: [
            ...state.selectedOptionsTemp,
          ],
        });
      } else {
        state.form.setFieldsValue({
          ...state.form.getFieldsValue(),
          AttributeOptionList: [
            ...state.selectedOptionsTemp,
          ],
        });
      }

      return {
        ...state,
        selectedOptions: [
          ...state.selectedOptionsTemp,
        ],
      };
    case Actions.SET_SELECTEDOPTIONS:
      return { ...state, selectedOptions: action.params };
    case Actions.SET_SHOWADDOPTIONSMODAL:
      return { ...state, showAddOptionsModal: action.params };
    case Actions.RESET_ATTRIBUTE:
      return setAttributeData(state, state.attributeData);
    case Actions.SET_EDITMODE:
      return { ...state, editMode: action.params };
    case Actions.SET_CHANNELS:
      return { ...state, channels: action.params };
    case Actions.SET_ATTRIBUTE_DATA:
      return setAttributeData(state, action.params);
    case Actions.SET_LOADING:
      return { ...state, loading: action.params };
    case Actions.SET_PAGE_TITLE:
      return { ...state, pageTitle: action.params };
    case Actions.SET_USECOMMONATRRIBUTE:
      return {...state, useCommonAttribute: action.params};
    case Actions.SET_TEMPLATE_VALUES:
      return {...state, templatesValues: action.params};
    default:
      return state;
  }
};


export type Dispatcher = React.Dispatch<Utils.DispatchAction<Actions>>;
type ContextType = [AttributeDetailState, Dispatcher] | [undefined, undefined];

export const AttributeDetailScreenContext = createContext<ContextType>([undefined, undefined]);

