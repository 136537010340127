import { FileImportType, ProductReportType } from '../types/enums';
import { digitBridgeApi, digitBridgeSimpleApi } from './http';

export const fetchImports = async (params: any):Promise<any> => digitBridgeApi.get('/v1/imports/importfiles', {
  params,
});

export const fetchImportsOfFile = async (fileId: string): Promise<any> => digitBridgeApi.get(`/v1/imports/${fileId}/importfiles`);

export const downloadTemplate = async (fileOperation: any):Promise<any> => digitBridgeApi.get('/v1/files/downloadProductTemplate', {
  headers: {
    fileOperation,
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
});

export const downloadTemplateAttributes = async ():Promise<any> => digitBridgeApi.get('/v1/files/downloadAttributeTemplate', {
  headers: {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
});

export const downloadProductTemplateByProfileNum = async (reportType: ProductReportType): Promise<Blob> => digitBridgeApi.get(
  '/v1/files/downloadProductTemplateByProfileNum',
  {
    params: { reportType },
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  },
);

export const downloadLookupTemplate = async (): Promise<Blob> => digitBridgeApi.get(
  '/v1/files/downloadLookUpTemplate',
  {
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  },
);
interface StyleMasterDownloadProps {
  [index: string]: boolean | number | string | undefined,
}
// export const downloadStyleLevelCustomizeTemplateByProfileNum = async (importType: ProductReportType | FileImportType, styleMasterDownloadParams: StyleMasterDownloadProps): Promise<Blob> => {
export const downloadStyleLevelCustomizeTemplateByProfileNum = async (importType: ProductReportType | FileImportType, styleMasterDownloadParams: StyleMasterDownloadProps): Promise<any> => {
  const { groupName } = styleMasterDownloadParams;
  const options = { ...styleMasterDownloadParams };
  const params = Object.keys(styleMasterDownloadParams).map((i: string) => {
    if (!styleMasterDownloadParams[i]) {
      return '';
    }
    return `${i}=${styleMasterDownloadParams[i]}`;
  });
  // let url = '/v1/files/downloadStyleLevelCustomizeTemplateByProfileNum';
  let url = '/v1/files/downloadStyleLevelCustomizeTemplateByProfileNumFromBlob';
  let reportType = importType;
  if (reportType === FileImportType.STYLE_MASTER_GROUP || reportType === FileImportType.STYLE_MASTER_UPDATE) {
    // url = `/v1/files/downloadStyleLevelCustomizeTemplateByProfileNum?${params.filter(Boolean).join('&')}`;
    url = `/v1/files/downloadStyleLevelCustomizeTemplateByProfileNumFromBlob?${params.filter(Boolean).join('&')}`;
    if (!groupName) {
      reportType = FileImportType.STYLE_MASTER_UPDATE;
    }
  }

  // options.code = undefined;
  delete options.code;
  // eslint-disable-next-line
  options.includeChannelAttribute = options.channelNum ? true : false;

  // console.log('p->', options);
  // return digitBridgeApi.get(
  return digitBridgeSimpleApi.get(
    url,
    {
      params: { reportType, ...options },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};

export const downloadChannelInventoryTemplate = async (): Promise<Blob> => downloadProductTemplateByProfileNum(20002);

export const downloadCustomTemplateBlob = async (p: StringKAnyVPair): Promise<any> => {
  const url = '/v1/files/downloadStyleLevelCustomizeTemplateByProfileNumFromBlob';

  return digitBridgeSimpleApi.get(
    url,
    {
      params: { ...p },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};

export const downloadAttributeTemplate = async (p: StringKAnyVPair = {}): Promise<any> => {
  const url = '/v1/files/downloadAttributeTemplate';

  return digitBridgeSimpleApi.get(
    url,
    {
      params: { ...p },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};

export const downloadImageTemplate = async (p: { channelNum: number, fileImportLevel: number }): Promise<any> => {

  const {
    channelNum,
    fileImportLevel,
  } = p;

  const url = '/v1/files/downloadImageTemplate';

  return digitBridgeSimpleApi.get(
    url,
    {
      params: { channelNum, fileImportLevel },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};


export const downloadAliasTemplate = async (p: StringKAnyVPair = {}): Promise<any> => {
  const url = '/v1/files/downloadAliasTemplate';

  return digitBridgeSimpleApi.get(
    url,
    {
      params: { ...p },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};

export const downloadSpecialTemplate = async (ChannelAttributeSpecialTemplateName: string, p: StringKAnyVPair = {}): Promise<any> => {
  const url = `/v1/files/downloadTemplateByFileName/${ChannelAttributeSpecialTemplateName}`;

  return digitBridgeSimpleApi.get(
    url,
    {
      params: { ...p },
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
};


export const uploadFile = async (data: FormData, channelNum?: number, overwrite?: boolean, ignore?: boolean, ApplyChangeToLowerLevelSKU?: boolean, channelName?: string) => {
  return digitBridgeApi.post(
    '/v1/files/upload',
    data,
    { 
      params: {
        channelNum,
        channelName,
        overwrite,
        ignore,
        ApplyChangeToLowerLevelSKU,
      },
    },
  );
}

export const downloadContentResourceTemplate = async (type: string): Promise<any> => digitBridgeApi.get(
  'v1/files/downloadContentResourceImportTemplate',
  {
    params: { type },
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  },
);

export const fetchVendors = async ():Promise<any> => digitBridgeApi.get('/v1/import/vendors');


export const uploadVendorFile = async (data: FormData, VendorUuid: string): Promise<any> => {
  return digitBridgeApi.post(
    '/v1/files/vendorfile',
    data,
    { 
      params: {
        VendorUuid,
      },
    },
  );
}