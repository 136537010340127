import {
  ArrowRightOutlined,
  CloseOutlined, FullscreenExitOutlined, FullscreenOutlined,
  MinusSquareTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import {
  Button, Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Image,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space, Spin,
  Table,
  Tabs,
  Typography,
} from 'antd';
import moment from 'moment';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';
import { OptionData, OptionGroupData } from 'rc-select/es/interface';
import React, { ElementRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import Heading from '../../components/common/Heading';
import { OnApplyCallback, ProductProfileMap } from '../../components/common/ProductSearch';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import {
  IEnquiredValuesResponse,
  useAddOPTProductMutation,
  usePostGetEnquiredValuesMutation,
} from '../../redux/api/opt';
import {
  useGetAttributesQuery,
  useGetAttributesByChannelNumQuery,
  useGetAvailableChannelsForAttributesQuery,
  //useGetCommonAttributesByChannelQuery,
} from '../../redux/api/productElements';
import FormButtons from '../../components/common/FormButtons';
import { Permissions } from '../../constants/enums/permissions';
import { getOPTDetails, downloadEnquiredValues } from '../../services/opt';
import ProductSearchApply from '../../components/common/ProductSearchApply';

const ArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ProductSearchWrapper = styled.div`
  height: calc(100vh - 260px);
  overflow-y: auto;
  padding: 12px;
  &.fullscreen-mode {
    height: calc(100vh - 120px);
  }
`;

// Title container for expand modal
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StepWrapper = styled.div`
  border: 1px solid #dcdcdc;
  margin: 10px;
  height: 93%;

  & .step2-selector-ctn,
    .step2-selector-ctn .ant-select,
    .step2-selector-ctn .ant-space-item {
    width: 100%;
  }
`;

const UpdateOptionsArea = styled.div`
  padding: 8px;

  &.options-has-error {
    border: 1px solid #BC0000;
    border-radius: 4px;
    padding: 7px;
  }

  & .option-label {
    display: inline-block;
    font-weight: 500;
    width: 246px;
  }
`;

interface CustomModalContainerProps {
  expand: boolean;
}

const CustomModalContainer = styled.div<CustomModalContainerProps>`
  background-color: white;
  position: ${p => (p.expand ? 'absolute' : undefined)};
  display: ${p => (p.expand ? 'flex' : undefined)};
  flex-direction: ${p => (p.expand ? 'column' : undefined)};
  top: ${p => (p.expand ? '0' : undefined)};
  left: ${p => (p.expand ? '0' : undefined)};
  border-radius: ${p => (p.expand ? '20px' : undefined)};
  width: ${p => (p.expand ? '90vw' : undefined)};
  height: ${p => (p.expand ? '95vh' : undefined)};
  margin: ${p => (p.expand ? '5vh 5vw' : undefined)};
  padding: ${p => (p.expand ? '25px' : undefined)};
  overflow-y: ${p => (p.expand ? 'auto' : undefined)};
  overflow-x: auto;
`;

const SelectProducts: React.FC<{ onSelected: (selected: ProductProfileMap) => void, readOnly: boolean }> = (
  {
    onSelected,
    readOnly,
  },
) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState<boolean>(false);
  const onSearchCancel = useCallback(() => setModalVisible(false), [setModalVisible]);
  const onApply: OnApplyCallback = useCallback((selected) => {
    onSelected(selected);
    setModalVisible(false);
  }, [onSelected, setModalVisible]);

  const toggleDetailDialogFullscreen = () => {
    setDetailDialogIsFullscreen(!detailDialogIsFullscreen);
  };

  const detailDialogWidth = useCallback(() => {
    if (detailDialogIsFullscreen) {
      return window.innerWidth;
    }

    return window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;

  }, [detailDialogIsFullscreen]);

  const onCloseModalClick = useCallback(() => { setModalVisible(false); }, []);

  return (
    <>
      <Form.Item>
        <Button id="step_1_select_products_button" type="primary" disabled={readOnly} onClick={() => setModalVisible(true)}>
          <PlusOutlined />
          Select Products
        </Button>
      </Form.Item>
      <Modal
        visible={modalVisible}
        onCancel={onSearchCancel}
        centered
        footer={null}
        closable={false}
        closeIcon={null}
        title={(
          <Row align="middle" justify="space-between">
            <Typography.Title level={4}>Search Products</Typography.Title>
            <Space>
              <Button onClick={toggleDetailDialogFullscreen}>
                {detailDialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                {detailDialogIsFullscreen ? 'Exit' : 'Enter'}
                {' '}
                Fullscreen
              </Button>
              <Button onClick={onCloseModalClick}>
                <CloseOutlined />
                Close
              </Button>
            </Space>
          </Row>
        )}
        className="fullscreen-modal"
        forceRender
        style={{ paddingBottom: 0 }}
        width={detailDialogWidth()}
      >
        <ProductSearchWrapper className={detailDialogIsFullscreen ? 'fullscreen-mode' : ''}>
          <ProductSearchApply onApply={onApply} />
        </ProductSearchWrapper>
      </Modal>
    </>
  );
};

const AttributesTypeSelect: React.FC<{ onSelectChannel: Function, onSelectType: (type: number) => void, readOnly : boolean }> = ({ onSelectChannel, onSelectType, readOnly }) => {
  //const [currentValue, setCurrentValue] = useState(0);
  const [currentValue, setCurrentValue] = useState<number>();
  const { data = [], isFetching } = useGetAvailableChannelsForAttributesQuery();
  const filterFunc = (input: string, option: OptionData | OptionGroupData | undefined) => {
    if (!option) {
      return false;
    }
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const onSelect = useCallback((e) => {
    const options = data.filter((d: StringKAnyVPair) => d.ChannelNum === e);

    setCurrentValue(e);
    onSelectType(e);
    //console.log('sel->', e, data);

    if (options.length > 0) {
      //console.log('sel->', options[options.length - 1]);
      onSelectChannel(options[options.length - 1]);
    }
  }, [data, onSelectChannel, onSelectType]);

  return (
    <Space className="step2-selector-ctn">
      <Select
        id="step_2_select_channel_of_attribute"
        onSelect={onSelect}
        filterOption={filterFunc}
        value={currentValue}
        loading={isFetching}
        allowClear
        showSearch
        disabled={readOnly}
      >
        {data.map((entry: { ChannelNum: number, ChannelName: string }) => (
          <Select.Option key={entry.ChannelNum} value={entry.ChannelNum}>
            {entry.ChannelName}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

const AttributesSelect: React.FC<{ onAddColumn?: (column: Entities.CommerceCentralOPTAttribute) => void, readOnly: boolean, type: number }> = ({ onAddColumn, readOnly, type }) => {
  const [currentValue, setCurrentValue] = useState<string>('');
  //const { data = [], isFetching } = useGetCommonAttributesByChannelQuery({ channelNum: type });
  const { data = [], isFetching } = useGetAttributesByChannelNumQuery({ channelNum: type });
  const filterFunc = (input: string, option: OptionData | OptionGroupData | undefined) => {
    if (!option) {
      return false;
    }
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const onSelect = useCallback((value: string) => {
    setCurrentValue(value);
  }, [setCurrentValue]);
  const addColumn = useCallback(() => {
    const item = (data as Entities.CommerceCentralOPTAttribute[]).find(d => d.AttributeId === currentValue);
    if (!item) {
      return;
    }

    onAddColumn?.(item);
    setCurrentValue('');
  }, [data, onAddColumn, currentValue, setCurrentValue]);

  return (
    <Col span={24} style={{ padding: '0px 10px 10px 10px' }}>
      <Row justify="center" gutter={[0, 16]}>
        <Col sm={24} xxl={18}>
          <Select
            id="step_3_select_attributes_to_update"
            style={{ width: '100%' }}
            onSelect={onSelect}
            filterOption={filterFunc}
            value={currentValue}
            loading={isFetching}
            allowClear
            showSearch
            disabled={readOnly}
          >
            {data.map((entry: Entities.CommerceCentralOPTAttribute) => (
              <Select.Option key={entry.AttributeId} value={entry.AttributeId}>
                {entry.AttributeName}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col sm={24} xxl={6} style={{ textAlign: 'center' }}>
          <Button
            id="step_3_slect_button"
            disabled={!currentValue || readOnly}
            onClick={addColumn}
            type="primary"
          >
            <PlusOutlined />
            Select
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  selectedKeys: React.Key[];
  confirm: (params?: any) => void;
  clearFilters?: () => void;
}
// filtering functionality for antd table
const getColumnSearchProps = (formData: FormModels.CommerceCentralOPTDetailProduct) => ({
  onFilter: function onFilter(value: any, record: any) {
    return formData.ProductList[record.name].SKU.toString().toLowerCase().includes(value.toLowerCase());
  },
});

const baseColumns = (formData: any) => (
  [
    {
      title: 'Image',
      dataIndex: 'Image',
      width: 52,
      render: function ImgColumn(text: string, value: FormListFieldData, index: number) {

        return (
          <Form.Item name={[value.name, 'MediaURL']} valuePropName="src">
            <Image
              id={`step_4_grid_coloums_image_row_${index}`}
              width={48}
              height={48}
              fallback="https://via.placeholder.com/300"
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      width: 280,
      render: function SKUColumn(text: string, value: FormListFieldData, index: number) {
        return (
          <Form.Item name={[value.name, 'SKU']}>
            <Input id={`step_4_grid_coloums_sku_row_${index}`} style={{ minWidth: '150px' }} readOnly bordered={false} />
          </Form.Item>
        );
      },
      ...getColumnSearchProps(formData),
    },
  ]
);

interface ProductListColumnProps {
  columnDefinition: Entities.CommerceCentralOPTAttribute;
  text: string;
  value: FormListFieldData;
  index: number;
  columnIndex: number;
  readOnly: boolean;
  attributeId: string;
  id: string;
}

const ProductListColumn: React.FC<ProductListColumnProps> = (
  {
    columnDefinition,
    value,
    columnIndex,
    readOnly,
    attributeId,
    id,
  },
) => {
  const { data = [], isFetching } = useGetAttributesQuery();
  const optionList = data.find(at => (at.AttributeId === attributeId))?.AttributeOptionList || [];
  return (
    <div>
      <Spin spinning={isFetching}>
        <Form.Item name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'NewValue']}>
          {optionList.length > 0 ? (
            <Select
              id={id}
              showSearch
              disabled={isFetching || readOnly}
            >
              {optionList.map((aol: any) => <Select.Option key={aol.OptionValue} value={aol.OptionValue}>{aol.OptionValue}</Select.Option>)}
            </Select>
          ) : (
            <Input id={id} disabled={isFetching || readOnly} />
          )}
        </Form.Item>
      </Spin>
      <Form.Item
        style={{ height: '0px' }}
        name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'AttributeId']}
        hidden
        initialValue={columnDefinition.AttributeId}
      >
        <Input hidden readOnly />
      </Form.Item>
      <Form.Item
        style={{ height: '0px' }}
        name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'AttributeChannelNum']}
        hidden
        initialValue={columnDefinition.AttributeChannelNum}
      >
        <Input hidden readOnly />
      </Form.Item>
      <Form.Item
        style={{ height: '0px' }}
        name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'AttributeChannelName']}
        hidden
        initialValue={columnDefinition.AttributeChannelName}
      >
        <Input hidden readOnly />
      </Form.Item>
      <Form.Item
        style={{ height: '0px' }}
        name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'AttributeName']}
        hidden
        initialValue={columnDefinition.AttributeName}
      >
        <Input readOnly hidden />
      </Form.Item>
      <Form.Item style={{ height: '0px' }} name={[value.name, 'ProductAttributeAssignedList', columnIndex, 'OriginalValue']}>
        <Input readOnly hidden />
      </Form.Item>
    </div>
  );
};

interface ProductListRemovableColumnProps {
  onChange: (value: string) => void;
  onRemove: () => void;
  name: string;
  attributeChannelName: string;
  attributeId: string;
  readOnly: boolean;
  id: string;
}

const ProductListRemovableColumn: React.FC<ProductListRemovableColumnProps> = (
  {
    onChange,
    onRemove,
    name,
    attributeChannelName,
    attributeId,
    readOnly,
    id,
  },
) => {

  const { data = [], isFetching } = useGetAttributesQuery();

  const optionList = data.find(at => (at.AttributeId === attributeId))?.AttributeOptionList || [];

  return (
    <Space wrap>
      <Space>
        <Button disabled={readOnly} size="small" type="text" onClick={onRemove}>
          <MinusSquareTwoTone />
        </Button>
        <span>{attributeChannelName}:{name}</span>
      </Space>
      {optionList.length > 0 ? (
        <Select
          id={id}
          showSearch
          style={{ minWidth: '150px' }}
          disabled={isFetching || readOnly}
          onChange={(value: string) => (onChange(value))}
        >
          {optionList.map((aol: any) => <Select.Option key={aol.OptionValue} value={aol.OptionValue}>{aol.OptionValue}</Select.Option>)}
        </Select>
      ) : (
        <Input id={id} style={{ minWidth: '150px' }} disabled={isFetching || readOnly} onChange={e => onChange(e.target.value)} />
      )}
    </Space>
  );
};

// custom modal required because AntD modal does not support required features
// need to be able to have a regular div turn into a modal since children is a table
interface CustomModalProps {
  expand: boolean;
  setExpand: (value: React.SetStateAction<boolean>) => void;
  children: any;
}

const CustomModal: React.FC<CustomModalProps> = (
  {
    expand,
    setExpand,
    children,
  },
) => (
  <CustomModalContainer expand={expand}>
    {expand ? (
      <TitleContainer>
        <CloseOutlined onClick={() => { setExpand(false); }} />
        <Typography.Title level={5}>Step 4: Update Attributes</Typography.Title>
      </TitleContainer>
    ) : null}
    {children}
  </CustomModalContainer>
);


interface ProductListProps {
  form: FormInstance<FormModels.CommerceCentralOPTDetailProduct>;
  data: FormListFieldData[];
  operation: FormListOperation;
  extraColumns: Entities.CommerceCentralOPTAttribute[];
  onRemoveColumn: (columnIndex: number) => void;
  readOnly: boolean;
}

interface ProductListRefProps {
  /**
   * Call this function to bulk-add items to the table
   * @param items
   */
  add: (items: FormModels.CommerceCentralOPTProduct[]) => void;
  /**
   * Call this function to remove an element from the table
   * @param index
   */
  remove: (index: number) => void;
  /**
   * Call this function when adding a new column in order to update the product list
   * with the original value
   * @param column
   */
  addColumn: (column: Entities.CommerceCentralOPTAttribute, columnIndex: number) => void;
}

const LevelTypesMap = new Map([['Style', 1], ['Substyle', 2], ['Product', 3]]);

const ProductList = forwardRef<ProductListRefProps, ProductListProps>(
  (
    {
      extraColumns,
      onRemoveColumn,
      operation,
      form,
      data,
      readOnly,
    }, ref,
  ) => {
    // expand variable for custom modal
    const [expand, setExpand] = useState(false);

    const [getEnquiredValues, { isLoading: isFetchingEnquiredValues }] = usePostGetEnquiredValuesMutation();
    // functionality for checkboxes
    const [selectedRowKeys, setKeys] = useState<number[]>([]);

    const onChange = (selectedKeys: number[]) => {
      setKeys(selectedKeys);
    };

    const toggleSelectAll = () => {
      setKeys((keys: number[]) =>
        keys.length === data.length ? [] : data.map((r) => r.key),
      );
    };

    const headerCheckbox = (
      <Checkbox
        checked={Boolean(selectedRowKeys.length)}
        indeterminate={
          selectedRowKeys.length > 0 && selectedRowKeys.length < data.length
        }
        onChange={toggleSelectAll}
      />
    );

    const rowSelection: any = {
      selectedRowKeys,
      onChange,
      columnTitle: headerCheckbox,
    };

    /**
     * Locally keep track of product entities on the table, used for retrieval of original
     * values when adding a new column
     */
    const products = useRef<Entities.ProductProfile[]>([]);
    const headerValues = useRef<{ [key: string]: string }>({});
    const updateFields = useCallback((values: { [key: string]: string }) => {
      const currentValues = form.getFieldsValue();
      currentValues.ProductList.filter((product, key) => (selectedRowKeys.includes(key))).forEach(p => {
        p.ProductAttributeAssignedList.forEach(pa => {
          const newValue = values[pa.AttributeName] || '';

          if (!newValue) {
            return;
          }

          // eslint-disable-next-line no-param-reassign
          pa.NewValue = newValue;
        });
      });
      form.setFieldsValue(currentValues);
    }, [form, selectedRowKeys]);
    const columns = useMemo(() => {
      const formData = form.getFieldsValue();
      //console.log('ttt->', extraColumns);
      const newColumns = extraColumns.map((c, cIndex) => ({
        title: function extColumn() {
          return (
            <ProductListRemovableColumn
              id={`update_attributes_step4_grid_ext_columns_${cIndex}_title`}
              readOnly={readOnly}
              name={c.AttributeName}
              attributeChannelName={c.AttributeChannelName || ''}
              attributeId={c.AttributeId}
              onChange={(value: string) => { headerValues.current[c.AttributeName] = value;}}
              onRemove={() => onRemoveColumn(cIndex)}
            />
          );
        },
        dataIndex: c.AttributeId,
        render: function basicColumn(text: string, value: any, index: number) {
          return (
            <ProductListColumn
              id={`update_attributes_step4_grid_ext_columns_${cIndex}_row_${index}`}
              readOnly={readOnly}
              columnDefinition={c}
              columnIndex={cIndex}
              attributeId={c.AttributeId}
              text={text}
              value={value}
              index={index}
            />
          );
        },
      }));

      const result: any[] = [
        ...baseColumns(formData),
        ...newColumns,
      ];


      result.push({
        dataIndex: '',
        title: (newColumns.length > 0) ? (() => (
          <Button id="update_attributes_step4_grid_ext_columns_apply_button" disabled={readOnly || products.current.length === 0} onClick={() => updateFields(headerValues.current)}>
            <PlusOutlined />
            Apply
          </Button>
        )) : '',
        render: function deleteButton(text: string, value: FormListFieldData, index: number){
          return (
            <Button id={`update_attributes_step4_grid_ext_columns_apply_row_${index}_delete`} disabled={readOnly} style={{ width: '100%' }} size="small" type="text" onClick={() => { operation.remove(value.name); }}>
              <MinusSquareTwoTone />
            </Button>
          );
        },
        width: 90,
      });

      return result;
    }, [extraColumns, form, readOnly, onRemoveColumn, updateFields, operation]);

    useImperativeHandle(ref, () => ({
      add(items) {
        const currentProductsSKUs: string[] = form
          .getFieldsValue(['ProductList'])
          .ProductList
          .map((p: FormModels.CommerceCentralOPTProduct) => p.SKU);

        const newProducts = items.filter(i => currentProductsSKUs.indexOf(i.SKU) < 0);
        products.current = products.current.concat(newProducts.map(n => n.$instance));
        newProducts.forEach(i => operation.add(i));
      },
      remove: operation.remove,
      addColumn(column, columnIndex) {
        const currentProducts: FormModels.CommerceCentralOPTProduct[] = form.getFieldsValue(['ProductList']).ProductList;
        currentProducts.forEach(p => {
          const product = products.current.find(prod => prod.SKU === p.SKU);
          if (!product) {
            return;
          }

          const fieldValue = (product as any)[column.AttributeName] || '';
          // eslint-disable-next-line no-param-reassign
          p.ProductAttributeAssignedList[columnIndex] = {
            ...column,
            OriginalValue: fieldValue,
            NewValue: fieldValue,
          };
        });
        form.setFieldsValue({ ProductList: currentProducts });
      },
    }), [operation, form, products]);

    const handleEnquiredValue = async (IsForExportToFile: boolean) => {
      const formData = form.getFieldsValue();
      const productSearchLevel = LevelTypesMap.get(formData.ProductList[0].$instance.Type);
      const payload = {
        ProductSearchLevel: productSearchLevel || 0,
        IsForExportToFile,
        KEYSEARCHPROPERTYNAMELIST: formData.ProductList.map( i => i.SKU),
        SEARCHATTRIBUTEIDLIST: formData.ProductList[0].ProductAttributeAssignedList.map(i => i.AttributeId),
      };

      if (IsForExportToFile) {
        const response = await downloadEnquiredValues(payload);

        if (!response) {
          notification.error({ message: 'No file information' });
          return;
        }

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'EnquiredValues.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {

        const response = await getEnquiredValues({ payload });
        if ('data' in response) {
          if (response.data) {
            const productList = formData.ProductList.map( i => {
              const centralProductId = i.CentralProductId.split('/').length === 2 ? i.CentralProductId.split('/')[1].split('-')[1].toString() : i.CentralProductId.split('-')[1].toString();
              const elementEnquiredValue: IEnquiredValuesResponse = response.data.filter((element: IEnquiredValuesResponse) => element.CentralProductNum === centralProductId)[0];
              return {
                ...i,
                ProductAttributeAssignedList: i.ProductAttributeAssignedList.map((a:Entities.CommerceCentralOPTProductAttribute) => ({
                  ...a,
                  NewValue: elementEnquiredValue ? elementEnquiredValue[a.AttributeId] : '',
                })),
              };
            }).filter(f => Object.keys(f).length !== 0);

            form.setFieldsValue({
              ...formData,
              ProductList: [
                ...productList,
              ],
            });
          }
        }
      }

    };

    return (
      <>
        <Row justify="space-between" style={{ backgroundColor: '#dcdcdc', padding: '10px 0px 0px 20px', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
          <Col span={8}>
            <Typography.Title level={5}>Step 4: Update Attributes</Typography.Title>
          </Col>
          <Col offset={10} span={6}>
            <Button id="step_4_apply_enquired_values_button" disabled={data.length === 0 || extraColumns.length === 0 || isFetchingEnquiredValues || readOnly} onClick={() => {handleEnquiredValue(false);}} style={{ marginBottom: '10px', marginRight: '20px' }}>
              Apply Enquired Values
            </Button>
            <Button id="step_4_export_enquired_values_button" disabled={data.length === 0 || extraColumns.length === 0 || isFetchingEnquiredValues || readOnly} onClick={() => {handleEnquiredValue(true);}} style={{ marginBottom: '10px', marginRight: '20px' }}>
              Export Enquired Values
            </Button>
          </Col>
          {/* <Button onClick={() => { setExpand(!expand); }}> */}
          {/*  Expand */}
          {/*  <ExpandAltOutlined /> */}
          {/* </Button> */}
        </Row>
        <CustomModal expand={expand} setExpand={setExpand}>
          <Table
            rowSelection={readOnly ? null : rowSelection}
            columns={columns}
            dataSource={data}
          />
        </CustomModal>
      </>
    );
  },
);
const useSetPage = (OPTId: string, form: FormInstance<FormModels.CommerceCentralOPTDetailProduct>, onAddColumns: (column: Entities.CommerceCentralOPTAttribute[]) => void ) => {
  const [readOnly, setReadOnly] = useState(false);

  // Rest of your code goes here
  // As your code executes asynchronously, you need to verify if it's still valid before making any changes.
  // This is relevant because something might trigger your hook execution multiple times and the last to execute will be the one making the changes
  const loadOptimization = useCallback(async (mounted: boolean) => {
    try {
      const { OPTDetailList = [] } = await getOPTDetails(OPTId);
      if (OPTDetailList.length === 0) {
        message.error('There is no information available for the optimization selected', 10);
        return;
      }
      // destructure for manipulation and ease
      const { StartDate, EndDate, ...OPTDetails } = OPTDetailList[0];
      const { ProductList: ProdList } = OPTDetailList[0];
      // add each column to the table manually using the onAddColumn function
      if (ProdList?.length !== undefined && ProdList?.length > 0) {
        const { ProductAttributeAssignedList = [] } = ProdList[0];
        if (ProductAttributeAssignedList.length) {
          onAddColumns(ProductAttributeAssignedList);
        }
      }
      const Period = [
        moment(StartDate),
        moment(EndDate),
      ];
      const newOPT = {
        ...OPTDetails,
        // ProdList,
        Period,
      };
      // const formInput = form.getFieldsValue();
      form.setFieldsValue(newOPT);
      setReadOnly(true);
    } catch (e) {
      await message.error('Something something');
    }

    // Rest of your code.
  }, [OPTId, form, onAddColumns]);

  useEffect(() => {
    let mounted = true;
    const unmount = () => {
      mounted = false;
    };

    // If add or readOnly === true, avoid loading the opt details
    if (OPTId === 'add' || readOnly) {
      return unmount;
    }

    loadOptimization(mounted)
      .catch((e) => {
        console.error(e);
        message.error('There was an error loading the information');
      });

    return unmount;
  }, [loadOptimization, OPTId, readOnly]);

  // Returning both in case you need to call `setReadOnly` from other places.
  return [readOnly];
};

// TODO: pass original values to elements of the table
const CreateProductOptimization: React.FC = () => {
  const { Text } = Typography;
  const { OPTId } = useParams<{ OPTId: string }>();
  const history = useHistory();
  const [attributeChannel, setAttributeChannel] = useState<StringKAnyVPair>({});
  const [attributeType, setAttributeType] = useState<number>(1234);
  const [form] = Form.useForm<FormModels.CommerceCentralOPTDetailProduct>();
  const [saveDisabled, setDisabled] = useState(true);
  const productListRef = useRef<ElementRef<typeof ProductList>>(null);
  const [extraColumns, setExtraColumns] = useState<Entities.CommerceCentralOPTAttribute[]>([]);
  const [optionApplyChange, setOptionApplyChange] = useState<boolean>();
  const [optionHasError, setOptionHasError] = useState(false);
  const [optionIgnore, setOptionIgnore] = useState<boolean>();
  const [optionOverwrite, setOptionOverwrite] = useState<boolean>();

  const [saveOPTProduct, { isLoading: savingOPTProduct }] = useAddOPTProductMutation();

  const onOptionApplyChange = (evt: any) => {
    setOptionApplyChange(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionIgnoreChange = (evt: any) => {
    setOptionIgnore(evt.target.value);
    setOptionHasError(false);
  };

  const onOptionOverwriteChange = (evt: any) => {
    setOptionOverwrite(evt.target.value);
    setOptionHasError(false);
  };

  const onSetTypeAttribute = useCallback((type: number) => setAttributeType(type), []);
  const onProductsSelected = useCallback((selected: { [key: string]: Entities.ProductProfile }) => {
    const items: FormModels.CommerceCentralOPTProduct[] = Object.keys(selected).map(k => {
      const attributeList = extraColumns.map(column => {
        const value = (selected[k] as any)[column.AttributeName] || '';

        return {
          ...column,
          OriginalValue: value,
          NewValue: value,
        };
      });

      return ({
        SKU: selected[k].SKU,
        CentralProductId: selected[k].ProductId,
        ProductAttributeAssignedList: attributeList,
        MediaURL: selected[k]?.MediaURL,
        $instance: selected[k],
      });
    });

    if (!productListRef.current) {
      return;
    }

    productListRef.current.add(items);
    // It is safe to assume that if any product has been added, you can proceed to save
  }, [productListRef, extraColumns]);

  const onAddColumn = useCallback((column: Entities.CommerceCentralOPTAttribute) => {
    const exists = extraColumns.find(c => c.AttributeId === column.AttributeId);
    if (exists) {
      return;
    }

    //column.AttributeChannel = JSON.parse(JSON.stringify(attributeChannel));
    const {
      ChannelName,
      ChannelNum,
    } = attributeChannel;
    const newColumns = [
      ...extraColumns,
      {
        ...column,
        AttributeChannelNum: ChannelNum,
        AttributeChannelName: ChannelName,
      },
    ];
    setExtraColumns(newColumns);
    productListRef.current?.addColumn?.(column, newColumns.length - 1);
  }, [attributeChannel, extraColumns, setExtraColumns]);

  // new function to be able to load a list of columns, instead of one at a time
  const onAddColumns = useCallback((columns: Entities.CommerceCentralOPTAttribute[]) => {
    const newColumns = [...extraColumns];
    for (let i = 0; i < columns.length; i += 1) {
      const exists = extraColumns.find(c => c.AttributeId === columns[i].AttributeId);
      if (!exists) {
        newColumns.push(columns[i]);
        productListRef.current?.addColumn?.(columns[i], newColumns.length - 1);
      }
    }

    setExtraColumns(newColumns);
  }, [extraColumns, setExtraColumns]);

  const onRemoveColumn = useCallback((columnIndex: number) => {
    const newColumns = [...extraColumns];
    const oldColumn = newColumns.splice(columnIndex, 1);
    const currentValues = form.getFieldsValue();
    // must remove all the values in the form associated with previous columns
    currentValues.ProductList.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      p.ProductAttributeAssignedList = p.ProductAttributeAssignedList.filter((pa) => (oldColumn[0].AttributeName !== pa.AttributeName));
    });
    form.setFieldsValue(currentValues);
    setExtraColumns(newColumns);
  }, [extraColumns, form]);

  const onFormFinished = useCallback(async (data: FormModels.CommerceCentralOPTDetailProduct) => {
    // validate options - all options required
    if (optionApplyChange === undefined || optionOverwrite === undefined || optionIgnore === undefined) {
      notification.error({ message: 'All upload options required!' });
      setOptionHasError(true);
      return;
    }
    
    const productList: any = data.ProductList.map(product => {
      const productId = product.CentralProductId.split('/');
      return ({
        SKU: product.SKU,
        CentralProductId: productId.length === 2 ? productId[1] : product.CentralProductId,
        ProductAttributeAssignedList: product.ProductAttributeAssignedList,
      });
    });

    const payload: Entities.CommerceCentralOPTDetailProduct = {
      ...data,
      StartDate: data.Period?.[0]?.toISOString() || '',
      EndDate: data.Period?.[1]?.toISOString() || '',
      ProductList: productList,
      FilterList: [{}],
      params: {
        ApplyChangeToLowerLevelSKU: optionApplyChange,
        overwrite: optionOverwrite,
        ignore: optionIgnore,
      },
    };

    //console.log('-->', optionOverwrite, optionApplyChange, optionIgnore);
    try {
      const res: any = await saveOPTProduct(payload);
      if (res.error) {
        const {
          data: errorData,
          status,
        } = res.error;
        const errorMessage = `${status && `(${status}) - `}${_.isString(errorData) && errorData}` || 'There was an error processing your request';
        notification.error({ message: errorMessage });
        return;
      }
      notification.success({ message: 'Product Optimization saved successfully' });
      history.push('/sell/product-optimization-center');
    } catch (e) {
      notification.error({ message: 'There was an error processing your request' });
    }
  }, [
    history,
    optionApplyChange,
    optionIgnore,
    optionOverwrite,
    saveOPTProduct,
  ]);

  // use effect takes care of loading OPTID, and setting all inputs to read only
  const [readOnly] = useSetPage(OPTId, form, onAddColumns);
  const pageTitle = `Product Optimization - ${OPTId === 'add' ? 'New' : OPTId}`;
  return (
    <ContentLayout>
      <Heading
        title={pageTitle}
      />
      <SiteContent>
        <Form
          form={form}
          initialValues={{ ProductList: [] }}
          onFinish={onFormFinished}
        >
          <Tabs
            tabBarExtraContent={(
              <FormButtons
                saving={savingOPTProduct}
                permissionNumber={Permissions.MANAGE_PRODUCTS}
                disableSave={saveDisabled || readOnly}
                editingMode
                hideCancel
                hideDelete
              />
              )}
          >
            <Tabs.TabPane tab="Optimization">
              <Row wrap>
                <Col flex="600px">
                  <Form.Item
                    name="OPTLabel"
                    label="Optimization Label"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input id="optimization_label_input" width="100%" disabled={readOnly} />
                  </Form.Item>
                </Col>
                <Col flex="auto" />
                <Col>
                  <Form.Item name="Period">
                    <DatePicker.RangePicker id="optimization_date_picker" disabled={readOnly} />
                  </Form.Item>
                </Col>
                <Col flex="auto" />
              </Row>
              <Spacer height="20px" />
              <Row>
                <Col span={6}>
                  <StepWrapper>
                    <Row justify="center" style={{ backgroundColor: '#dcdcdc', padding: '10px 0px 0px 0px', textAlign: 'center' }}>
                      <Typography.Title level={5}>Step 1: Select Products</Typography.Title>
                    </Row>
                    <Row justify="center" style={{ padding: '20px 0px 0px 0px' }}>
                      <SelectProducts readOnly={readOnly} onSelected={onProductsSelected} />
                    </Row>
                  </StepWrapper>
                </Col>
                <Col span={2}>
                  <ArrowWrapper>
                    <ArrowRightOutlined style={{ fontSize: '400%', color: '#dcdcdc' }} />
                  </ArrowWrapper>
                </Col>
                <Col span={7}>
                  <StepWrapper>
                    <Row justify="center" style={{ backgroundColor: '#dcdcdc', padding: '10px 0px 0px 0px', textAlign: 'center' }}>
                      <Typography.Title level={5}>Step 2: Select Channel of Attribute</Typography.Title>
                    </Row>
                    <Row justify="center" style={{ padding: '20px 0px 0px 0px' }}>
                      <Col span={24} style={{ padding: '0px 15px' }}>
                        <AttributesTypeSelect onSelectChannel={setAttributeChannel} onSelectType={onSetTypeAttribute} readOnly={readOnly} />
                      </Col>
                    </Row>
                  </StepWrapper>
                </Col>
                <Col span={2}>
                  <ArrowWrapper>
                    <ArrowRightOutlined style={{ fontSize: '400%', color: '#dcdcdc' }} />
                  </ArrowWrapper>
                </Col>
                <Col span={7}>
                  <StepWrapper>
                    <Row justify="center" style={{ backgroundColor: '#dcdcdc', padding: '10px 0px 0px 0px', textAlign: 'center' }}>
                      <Typography.Title level={5}>Step 3: Select Attributes to Update</Typography.Title>
                    </Row>
                    <Row justify="center" style={{ padding: '20px 0px 0px 0px' }}>
                      <AttributesSelect readOnly={readOnly} type={attributeType} onAddColumn={onAddColumn} />
                    </Row>
                  </StepWrapper>
                </Col>
              </Row>
              <Spacer height={20} />
              <UpdateOptionsArea
          className={optionHasError ? 'options-has-error' : ''}
        >
        <Row>
          <Space style={{ width: '100%' }}>
            <Text className="option-label">Apply change to lower level SKU(s):</Text>
            <Radio.Group
              onChange={onOptionApplyChange}
              value={optionApplyChange}
              disabled={readOnly}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
          <Space style={{ width: '100%' }}>
            <Text className="option-label">If destination has value (Not empty):</Text>
            <Radio.Group
              onChange={onOptionOverwriteChange}
              value={optionOverwrite}
              disabled={readOnly}
            >
              <Radio value>Overwrite</Radio>
              <Radio value={false}>Don't Overwrite</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Row>
          <Space style={{ width: '100%' }} align="start">
            <Text className="option-label">If source doesn't have value (empty):</Text>
            <Radio.Group
              onChange={onOptionIgnoreChange}
              value={optionIgnore}
              disabled={readOnly}
            >
              <Radio value={false}>Use empty value to update destination</Radio>
              <Radio value>Ignore (don't update destination)</Radio>
            </Radio.Group>
          </Space>
        </Row>
        </UpdateOptionsArea>
        {optionHasError && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">All upload options required</div>
        </div>
        )}
            </Tabs.TabPane>
          </Tabs>
          <Spacer height="20px" />
          <Form.List name="ProductList">
            {
                (fields, operation) => {
                  setDisabled(fields.length === 0);
                  return (
                    <ProductList
                      readOnly={readOnly}
                      ref={productListRef}
                      data={fields}
                      extraColumns={extraColumns}
                      operation={operation}
                      form={form}
                      onRemoveColumn={onRemoveColumn}
                    />
                  );
                }
            }
          </Form.List>
        </Form>
      </SiteContent>
    </ContentLayout>
  );
};

export default CreateProductOptimization;
