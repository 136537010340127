import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import styled from 'styled-components';
import SiteContent from '../components/SiteContent';
import TableComponent from '../components/TableComponent';
import EditableCell from '../components/UserManagement/EditableCell';
import ContentLayout from '../components/ContentLayout';
import Info from '../assets/icons/info';
import { deleteCarrierAndServices, editCarrierAndServices, fetchCarrierAndServices } from '../services/shipping';
import CheckMark from '../assets/icons/checkmark';
import CancelIcon from '../assets/icons/clear';
import EditIcon from '../assets/icons/edit';
import DeleteIcon from '../assets/icons/delete';
import ConfirmationModal from '../components/UserManagement/ConfirmationModal';
import { leftTrim } from '../util';
import notificationActions from '../redux/actions/notifications';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';


const ToggleWrapper = styled.div`
  margin-bottom: 10px;
`;
const TitleWrapper = styled.div`
  font-family: sans-serif, Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.57;
  color: #2d3f5d;
  display: flex;
`;
const Description = styled.div`
  margin-top: 20px;
  border-left: solid 2px #e09624;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 11px;
`;
const ItemWrapper = styled.div`
  margin-top: 10px;
`;
const DescriptionContent = styled.p`
  font-family: sans-serif, Lato;
  font-size: 13px;
  font-weight: normal;
  color: #2d3f5d;
  margin: 0;
`;
const DescriptionTitle = styled(DescriptionContent)`
  font-weight: bold;
`;

const IconWrapper = styled.div`
  margin-top: 3px;
  margin-left: 4px;
  cursor: pointer;
`;

const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left;
`;

const carrierAndServicesInfo = [
  {
    title: 'Channel specific carrier and services.',
    description: 'Orders from some channels (mostly marketplaces) use their own shipping method description, for example, value, standard and express. To make it consistent with normal shipping carrier expression, the marketplace name is used as carrier and the shipping method is used as service. If you enable these channels, these channel specific carriers and their services will be enabled automatically.',
  },
  {
    title: 'Standard carriers and services',
    description: 'Standard carrier and it\'s services, for example UPS, FedEx and USPS are included in the system you need to enable them individually.',
  },
  {
    title: 'Other carriers and services.',
    description: 'If the carrier and service are not in the system, you can add them.',
  },
];

export interface Item {
  carrierAndServices: string;
  CarrierCode: string;
  ServiceCode: string;
  TrackingURL: string;
  key: any;
  Source: number;
}

function Carrier(props: any) {
  const { isModal } = props;
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState('none');
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState<Item[]>([]);
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState({
    carrierAndServices: '',
    CarrierCode: '',
    ServiceCode: '',
    TrackingURL: '',
    key: 0,
    Source: 0,
  });
  const isEditing = (record: Item) => record.key === editingKey;
  const edit = (record: Item) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const handleConfirmationModal = (record: Item) => {
    setSelectedCarrier(record);
    setShowConfirmation(true);
  };
  const save = async (key: React.Key) => {
    const row = (await form.validateFields()) as Item;
    if (!row.TrackingURL.trim()) {
      form.setFieldsValue({
        ...row,
        TrackingURL: leftTrim(row.TrackingURL),
      });
      return;
    }
    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setData(newData);
      setEditingKey('');
      await editCarrierAndServices({
        ...row,
        CarrierCode: item.CarrierCode,
        ServiceCode: item.ServiceCode,
        MasterAccountNum: props.profilesEntity.profiles[props.profilesEntity.selectedIndex]?.MasterAccountNum,
        ProfileNum: props.profilesEntity.profiles[props.profilesEntity.selectedIndex]?.ProfileNum,
        carrierService: `${item.CarrierCode}-${item.ServiceCode}`,
      });
      props.actions.setNotification('success', 'Carrier Service updated');
    } else {
      newData.push(row);
      setData(newData);
      setEditingKey('');
    }
  };
  const columns = [
    {
      title: 'Carrier and Services',
      dataIndex: 'CarrierCode',
      sorter: true,
      fixed: true,
      render: (value: any, record: any) => `${record.CarrierCode} - ${record.ServiceCode}`,
    },
    {
      key: 1,
      title: 'Carrier Code',
      dataIndex: 'CarrierCode',
      sorter: true,
    },
    {
      key: 2,
      title: 'Sevice Code',
      dataIndex: 'ServiceCode',
      sorter: true,
    },
    {
      key: 3,
      title: 'Tracking URL Format',
      editable: true,
      dataIndex: 'TrackingURL',
      sorter: true,
      ellipsis: true,
    },
    {
      key: 4,
      title: '',
      dataIndex: 'operation',
      render: function render(_: any, record: Item) {
        const editable = isEditing(record);
        if (!record.Source) return null;
        return editable ? (
          <div>
            <TableButtonWrapper onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              <CheckMark height={25} width={25} style={{ fill: '#02a800' }} />
            </TableButtonWrapper>
            <TableButtonWrapper onClick={() => cancel()} style={{ marginRight: 8 }}>
              <CancelIcon height={25} width={25} style={{ fill: '#2d3f5d' }} />
            </TableButtonWrapper>
          </div>
        ) : (
          <>
            <TableButtonWrapper onClick={() => edit(record)}>
              <EditIcon height={25} width={25} style={{ fill: '#006dff' }} />
            </TableButtonWrapper>
            <TableButtonWrapper onClick={() => handleConfirmationModal(record)} style={{ marginLeft: 10 }}>
              <DeleteIcon height={25} width={25} style={{ fill: '#c13939' }} />
            </TableButtonWrapper>
          </>
        );
      },
    },
  ];
  const carrierAndServicesSelector = (carrierAndServices: any) => carrierAndServices.map((cs: any) => ({
    ...cs,
    key: `${cs.CarrierCode}-${cs.ServiceCode}`,
  }));
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputLimit: 500,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleToggle = () => {
    setToggle(toggle === 'none' ? '' : 'none');
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchCarrierAndServices();
      setLoading(false);
      setData(carrierAndServicesSelector(response));
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
  const handleConfirmation = async () => {
    const carrierService = `${selectedCarrier.CarrierCode}-${selectedCarrier.ServiceCode}`;
    await deleteCarrierAndServices(carrierService);
    fetchData().then();
    props.actions.setNotification('success', 'Carrier Service deleted');
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData().then();
    }, []);
  };

  useFetch();

  return (
    <ContentLayout isModal={isModal}>
      <ConfirmationModal
        visible={showConfirmation}
        data={selectedCarrier}
        handleCancel={handleCancel}
        handleConfirm={handleConfirmation}
      />
      <SiteContent>
        <Heading title="Shipping carriers" />
      </SiteContent>
      <Spacer />
      <SiteContent>
        <ToggleWrapper>
          <TitleWrapper>
            Enable Carriers and Service
            {' '}
            <IconWrapper onClick={handleToggle}><Info /></IconWrapper>
          </TitleWrapper>
          <Description style={{ display: toggle }}>
            {
              carrierAndServicesInfo.map(i => (
                <ItemWrapper key={i.title}>
                  <DescriptionTitle>
                    •
                    {i.title}
                  </DescriptionTitle>
                  <DescriptionContent>{i.description}</DescriptionContent>
                </ItemWrapper>
              ))
            }
          </Description>
        </ToggleWrapper>
        <Form form={form} component={false}>
          <TableComponent
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => 'editable-row'}
            loadingStatus={loading}
            dataSource={data}
            columns={mergedColumns}
          />
        </Form>
      </SiteContent>
    </ContentLayout>
  );
}

const mapStateToProps = (state: any) => ({ profilesEntity: state.profiles });


const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Carrier);
