import React, { useEffect } from 'react';
import {
  Col,
  Image,
  Input,
  Modal,
  Row,
  Space,
} from 'antd';
//import { CloseOutlined } from '@ant-design/icons';
//import moment from 'moment';
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
// import message from '../../components/common/message';
// import { playBell } from '../../util';
//import { convertTimeByUtc, convertToUtcTime, timezoneAbbrNameMap } from '../../util/utcTime';
import OverwriteOption from './OverwriteOption';
import OverwriteOptionList from './OverwriteOptionList';
import { ImageEditorWrapper } from './styledComponents';

type Props = {
  dispatch?: Function;
  imageUrl: string;
  onClose: Function;
  onOverwriteChange?: Function;
  onSave?: Function;
  overwrite?: boolean;
  overwriteEnable?: boolean;
  state?: StringKAnyVPair;
  title?: string;
  visible: boolean;
};

const EditImageDialog = (props: Props) => {
  const { useState } = React;
  const [editImageUrl, setEditImageUrl] = useState(props.imageUrl);
  const [overwrite, setOverwrite] = useState(false)

  useEffect(()=>{
    setOverwrite(props.overwrite? props.overwrite : false)
  },[props.overwrite])

  const onCloseDialog = () => {
    props.onClose();

    /*const tmap = timezoneAbbrNameMap();
    const utcTime = convertToUtcTime(moment().format());
    const tz = tmap['Mountain Standard Time'];
    console.log('utc time', utcTime, tz);
    if (tz) {
      console.log(convertTimeByUtc(utcTime, tz));
    }*/
  };

  const onEditImage = (evt: any) => {
    // console.log('v->', evt.target.value);
    setEditImageUrl(evt.target.value);
  };

  const onOverwriteChange = (ow: boolean) => {
    if (typeof props.onOverwriteChange === 'function') {
      props.onOverwriteChange(ow);
    } else  {
      setOverwrite(ow)
    }
  };

  const onSaveImage = async () => {
    // if (editImageUrl) {
      // const overwrite = props.state?.overwriteChildren;
      let closable = true;

      if (typeof props.onSave === 'function') {
        closable = await props.onSave(editImageUrl, overwrite);
      }

      if (closable) {
        onCloseDialog();
      }
    /*} else {
      message.info('Please input a valid image url');
      playBell('error');
    }*/
  };

  const modalTitle = () => {
    const title = props.title || 'Edit Image';

    return (<Row
      align="middle"
      justify="space-between"
      style={{marginRight: 30}}
    >
      {title}
      <Space>
        {props.overwriteEnable && (props.dispatch ? (
          <OverwriteOptionList
            dispatch={props.dispatch}
            state={props.state ? props.state : {}}
          />
        ) : (
          <OverwriteOption
            onChange={onOverwriteChange}
            overwrite={overwrite || false}
          />
        ))}
      </Space>
    </Row>);
  };

  return (
    <Modal
      centered
      className="fullscreen-modal"
      okText="Save"
      onCancel={onCloseDialog}
      onOk={()=>{
        if(!editImageUrl.trim()){
          Modal.confirm({
            title:'Are you sure you want to save it as null?',
            onOk:()=>{
              onSaveImage()
            },
            onCancel:()=>{}
          })
        } else {
          onSaveImage()
        }
      }}
      style={{ paddingBottom: 0 }}
      title={modalTitle()}
      visible={props.visible}
      width={600}
    >
      <ImageEditorWrapper>
        <Col span={12} className="img-col">
          <Row
            align="middle"
            className="img-ctn"
            justify="center"
          >
            <Image
              className="img-outlook"
              fallback={ImagePlaceholder}
              src={editImageUrl}
            />
          </Row>
        </Col>
        <Col className="url-col" span={12}>
          <Input.TextArea
            onChange={onEditImage}
            placeholder="Please Input Image Url At Here"
            rows={5}
            value={editImageUrl}
          />
        </Col>
      </ImageEditorWrapper>
    </Modal>
  );
};

export default EditImageDialog;
