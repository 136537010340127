import { Button, Form, Spin, Tabs } from 'antd';
import camelcaseKeys from 'camelcase-keys';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import { fetchChannelAccountNum } from '../../services/products';
import Quickbooks from '../../services/quickbooks';
import {
  Actions,
  Dispatcher,
  initialState,
  PageState,
  quickbooksSettingsReducer,
  QuickbooksSettingsScreenContext,
} from './context';
import BasicInfo from './tabs/BasicInfo';


const QuickbooksButton = styled(Button)`
background-color: #2CA01D;
border-color: #2CA01D;

&:hover, :focus {
  background-color: #2CA01D;
  border: 1px solid black;
}
`;

/**
 * Renders a message with a button that redirects to Quickbooks Auth page
 * @constructor
 */
const ConnectToQuickbooks: React.FC = () => {
  const [authUrl, setAuthUrl] = useState('');

  useEffect(() => {
    Quickbooks.getOAuthUrl()
      .then((url) => {
        setAuthUrl(url);
      })
      .catch(() => {
        // TODO: show message or retry?
      });
  }, [setAuthUrl]);

  return (
    <div>
      <p>
        In order to use this functionality, first you need to grant access to your Quickbooks account.
      </p>
      <QuickbooksButton
        id="qb-connect-btn"
        href={authUrl}
        shape="round"
        type="primary"
        disabled={authUrl === ''}
      >
        Connect to Quickbooks
      </QuickbooksButton>
    </div>
  );
};

const PageTabs: React.FC = () => (
  <Tabs>
    <Tabs.TabPane
      key="basicInfo"
      tab="Basic Info"
    >
      <BasicInfo />
    </Tabs.TabPane>
    <Tabs.TabPane
      key="technical"
      tab="Technical"
    />
    <Tabs.TabPane
      key="schedule"
      tab="Schedule"
    />
  </Tabs>
);

const PageContent: React.FC = () => {
  const [state] = useContext(QuickbooksSettingsScreenContext);
  const { pageState } = state || { pageState: PageState.FETCHING_INFO };

  const content = useMemo(() => {
    switch (pageState) {
      case PageState.RENDER_SETTINGS:
        return <PageTabs />;
      case PageState.REQUIRES_AUTH:
        return <ConnectToQuickbooks />;
      case PageState.UNKNOWN:
        return <div>Error</div>;
      case PageState.FETCHING_INFO:
      default:
        return null;
    }
  }, [pageState]);


  return (
    <div>
      {content}
    </div>
  );
};

/**
 * Custom hook that takes care of fetching the data to initialize the page
 * and set the appropriate PageState.
 *
 * @param dispatch - useReducer dispatch object.
 * @param shouldReload - indicates if the data should be completely reload.
 */
const usePageLoad = (dispatch: Dispatcher, shouldReload: boolean) => {
  useEffect(() => {
    if (!shouldReload) {
      return;
    }

    dispatch({ type: Actions.SET_LOADING, params: true });

    const handler = async () => {
      const data = await Quickbooks.getTokenStatus();
      if (data.qboOAuthTokenStatus !== 1) {
        dispatch({ type: Actions.SET_PAGE_STATE, params: PageState.REQUIRES_AUTH });
        return Promise.resolve();
      }

      const userData = await Quickbooks.getInitialData();
      dispatch({ type: Actions.SET_USER_DATA, params: userData });

      const settings = await Quickbooks.getSettings();
      dispatch({ type: Actions.SET_SETTINGS, params: settings });

      // TODO: refactor usage of this API call to apply camelcaseKeys by default
      const channelData: Array<api.ProfileChannelAccount> = camelcaseKeys(await fetchChannelAccountNum(10001)) as unknown as Array<api.ProfileChannelAccount>;
      dispatch({ type: Actions.SET_CHANNEL_ACCOUNTS, params: channelData });

      if (settings.integrationSetting?.qboSettingStatus === 1) {
        // Render page with settings coming from the API
        dispatch({ type: Actions.SET_FORM_VALUES, params: settings });
      } else {
        // Use channelData to pre-populate entries on the table
        dispatch({
          type: Actions.SET_FORM_VALUES,
          params: {
            chnlAccSettings: channelData,
          },
        });
      }

      dispatch({ type: Actions.SET_RELOAD_DATA, params: false });
      dispatch({ type: Actions.SET_PAGE_STATE, params: PageState.RENDER_SETTINGS });

      return Promise.resolve();
    };

    handler()
      .catch(() => {
        // TODO: ask @Will to provide a better error object to ensure that the cause of the error is due
        //    to an non-initialized QuickbooksSettings integration

        // Show "Connect to Quickbooks" button
        dispatch({ type: Actions.SET_PAGE_STATE, params: PageState.REQUIRES_AUTH });
      })
      .finally(() => dispatch({ type: Actions.SET_LOADING, params: false }));
  }, [dispatch, shouldReload]);

  // Invoke one time
  useEffect(() => {
    dispatch({ type: Actions.SET_RELOAD_DATA, params: true });
  }, [dispatch]);
};

const PageHeader: React.FC = () => {
  const [state] = useContext(QuickbooksSettingsScreenContext);

  const { companyName } = state?.userData?.userCompanyInfo || { companyName: '' };

  const title = useMemo(() => {
    if (!companyName) {
      return 'Channel Integration - Quickbooks';
    }

    return `Channel Integration - Quickbooks - ${companyName}`;
  }, [companyName]);

  return <Heading title={title} />;
};

const Page: React.FC = () => {
  const params = useReducer(quickbooksSettingsReducer, initialState({
    form: Form.useForm()[0],
  }));

  const [state, dispatch] = params;

  usePageLoad(dispatch, state.reload);

  return (
    <QuickbooksSettingsScreenContext.Provider value={params}>
      <ContentLayout>
        <PageHeader />
        <Spacer />
        <Spin spinning={params[0].loading}>
          <SiteContent>
            <PageContent />
          </SiteContent>
        </Spin>
      </ContentLayout>
    </QuickbooksSettingsScreenContext.Provider>
  );
};

export default Page;
