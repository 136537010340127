import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Radio,
  // message,
  Row,
  Space,
  Card,
  Typography,
  Switch,
} from 'antd';
import _, { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Map from '../../../assets/icons/map';
import { ProductProfileMap, ProductSearchProps } from '../../../components/common/ProductSearch';
import IntegrationsActions from '../../../redux/actions/integrations';
import { IntegrationsState } from '../../../redux/reducers/integrations';
import { AppState } from '../../../redux/types';
import { pushToChannelAccount, getChannelExportViewInfo } from '../../../services/channels';
import { fetchDistributionCenters } from '../../../services/distributionCenter';
import { ChannelAccountEventType, IntegrationScheduleSettingsSection } from '../../../types/enums';
import { useExecutePromise, useObserveSwitch } from '../../../util/hooks';
import { FormsContext } from '../details/context';
import ProductSearchAddRemove from '../../../components/common/ProductSearchAddRemove';
// import { deleteTemplate, downloadTemplate } from '../../../services/template';
import UploadModal from '../../Templates/UploadModal';
import WarehouseSelectionModal from './WarehouseSelectionModal';
import WarehouseSelectionModalContent from './WarehouseSelectionModal/ModalContent';

const { Text } = Typography;

const ProductSearchWrapper = styled.div`
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 12px;
`;

const SchedulingSectionWrapper = styled.div`
  margin-bottom: 1rem;
`;

const SchedulingSectionTitle = styled.h3`
  font-weight: bold;
  border-bottom: 1px solid #d8dde6;
  margin-bottom: 2rem;
`;

const InventoryExtraSection = styled.div`
  @media (min-width: 768px) {
    margin-left: 2rem;
  }
`;

const sections: { [key: string]: string } = {
  ProductSettings: 'Create and update products on {name} {category}',
  InventorySettings: 'Send inventory updates to {name} {category}',
  OfferSetting: 'Send Offer updates to {name} {category}',
  // OrderSettings: 'Import orders placed on {name} {category}',
  // ShippingSettings: 'Send shipment, cancellation and return information to {name} {category}',
  // InvoiceSettings: 'Send invoices to {name} {category}',
};

const sectionActionLabel: { [key: string]: string } = {
  ProductSettings: 'Publish Products',
  InventorySettings: 'Publish Inventory',
  OrderSettings: 'Get Orders',
  ShippingSettings: 'Push Shipping',
  InvoiceSettings: 'Push Invoices',
  OfferSetting: 'Publish Offer',
};
// Commented, reason see FE-682
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const periods = ['Hourly', 'Daily', 'Weekly', 'Monthly'];
const productsSearchProps: ProductSearchProps = {
  title: 'Product Search',
  allowMultiple: true,
  tableStyle: { height: '500px' },
  buttonText: 'Add products',
};

const ProductsActionButton: React.FC<{ isOffer?: boolean }> = ({ isOffer }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isStyleMaster, setIsStyleMaster] = useState<boolean>(false);
  const [selected, setSelected] = useState<ProductProfileMap>({});
  const [available, setAvailable] = useState<Entities.ProductProfile[]>([]);
  const [processing, setProcessing] = useState(false);
  const settings = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.profileSettings,
  );
  const disabled = useMemo(() => {
    if (!settings) {
      return false;
    }

    const { Update = false } = settings.ScheduleSetting?.ProductSettings || {};
    return !Update;
  }, [settings]);

  const openPushProductsModal = useCallback((styleMasterSelection: boolean) => {
    setModalVisible(true);
    setIsStyleMaster(styleMasterSelection);
  }, []);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => openPushProductsModal(false)}>Single Products</Menu.Item>
      <Menu.Item onClick={() => openPushProductsModal(true)}>Style products</Menu.Item>
    </Menu>
  );

  const onChangeProductsSelected = useCallback(
    (changeSelected: Entities.ProductProfile[], changeAvailable: Entities.ProductProfile[]) => {
      const selectedFormatted = changeSelected.reduce((p: any, item: Entities.ProductProfile) => {
        const r = { ...p };
        r[item?.ProductId] = item;
        return r;
      }, {});
      setSelected(selectedFormatted);
      setAvailable(changeAvailable);
    },
    [],
  );

  const onApply = useCallback(async () => {
    if (!settings) {
      return;
    }
    /**
     * As per Van comments:
     * - event is CATALOG_UPDATE
     * - Push empty body to process ALL products
     *  - Otherwise, send an array of Product's SKUs
     */
    const skus = (Object.keys(selected).length === available.length
      ? []
      : Object.keys(selected)
    ).map((k) => selected[k].SKU);

    setModalVisible(false);
    setProcessing(true);
    try {
      await pushToChannelAccount(
        isOffer ? ChannelAccountEventType.OFFER_SYNC : ChannelAccountEventType.CATALOG_UPDATE,
        settings.ChannelAccountNum,
        settings.ChannelNum,
        skus,
      )
      Modal.success({
        title: 'Post succeeded!'
      })
    } catch (error) {
      Modal.error({
        title: 'Post failed. Please try it again!',
        content: error
      })
    } finally {
      setProcessing(false);
    }
  }, [settings, selected, available.length, isOffer]);

  const onCancel = useCallback(() => {
    setModalVisible(false);
    setSelected({});
    setAvailable([]);
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <>
      <Dropdown overlay={menu} placement="bottomCenter" arrow>
        <Button disabled={disabled} loading={processing}>
          {isOffer ? 'Publish Offer' : 'Publish Products'}
        </Button>
      </Dropdown>
      <Modal
        visible={modalVisible}
        width={window.innerWidth}
        okText={isOffer ? 'Publish Offer' : "Publish Products"}
        closable={false}
        footer={null}
        className="fullscreen-modal"
        centered
        style={{ paddingBottom: 0 }}
      >
        <ProductSearchWrapper>
          <Row>
            <Col offset={21} span={3}>
              <Button style={{ marginRight: '5px' }} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={isEmpty(selected)}
                onClick={() => {
                  onApply();
                }}
              >
                {isOffer ? 'Publish Offer' : 'Publish Products'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ProductSearchAddRemove
                onChangeSelected={onChangeProductsSelected}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...productsSearchProps}
                styleMaster={isStyleMaster}
              />
            </Col>
          </Row>
        </ProductSearchWrapper>
      </Modal>
    </>
  );
};

const ActionButton: React.FC<{ section: IntegrationScheduleSettingsSection }> = ({ section }) => {
  const [processing, setProcessing] = useState(false);
  const settings = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.profileSettings,
  );
  const disabled = useMemo(() => {
    if (section === 'OrderSettings') {
      // TODO: remove once BE supports Get Orders
      return true;
    }

    if (!settings) {
      return false;
    }

    // if (section === 'OfferSetting') {
    //   // no 'Update' in OfferSetting 
    //   return false;
    // }

    const { Update = false } = settings.ScheduleSetting?.[section] || {};

    return !Update;
  }, [settings, section]);
  const label = sectionActionLabel[section];
  const onClick = useCallback(async () => {
    if (!settings) {
      return;
    }

    let eventType = ChannelAccountEventType.UNKNOWN;
    switch (section) {
      case 'ShippingSettings':
        eventType = ChannelAccountEventType.ORDER_SHIPMENT;
        break;
      case 'InvoiceSettings':
        eventType = ChannelAccountEventType.INVOICE_SYNC;
        break;
      case 'InventorySettings':
        eventType = ChannelAccountEventType.INVENTORY_SYNC;
        break;
      case 'OfferSetting':
        eventType = ChannelAccountEventType.OFFER_SYNC;
        break;
      default:
        break;
    }

    setProcessing(true);
    try {
      await pushToChannelAccount(eventType, settings.ChannelAccountNum, settings.ChannelNum);
      Modal.success({
        title: 'Post succeeded!'
      })
    } catch (error) {
      Modal.error({
        title: 'Post failed. Please try it again!',
        content: error
      })
    } finally {
      setProcessing(false);
    }
  }, [settings, section, setProcessing]);

  if (!settings) {
    return null;
  }

  return (
    <Button loading={processing} onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
};

// const SchedulingControls: React.FC<{ name: string; disabled?: boolean }> = ({ name, disabled = false, children }) => {
//   const labelCol = {
//     xs: 14,
//     md: 14,
//     lg: 14,
//   };
//   const wrapperCol = {
//     xs: 10,
//     md: 10,
//     lg: 10,
//   };
//   const form = useContext(FormsContext);
//   const editMode = useSelector((state: AppState) => state.integrations.editMode);
//   const settings = useSelector((state: AppState) => state.integrations.profileSettings);
//   const [enabled, setEnabled] = useState(false);
//   const onEnabledSwitchChange = useCallback((state: boolean) => setEnabled(state), [setEnabled]);
//   const baseName: string[] = useMemo((): string[] => ['settings', 'ScheduleSetting', name], [name]);
//   // Commented, reason see FE-682
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const fieldsDisabled = disabled || !enabled;
//   // Commented, reason see FE-682
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const validateEndDate = useCallback(
//     async (rule: RuleObject, value: Moment | null): Promise<any> => {
//       if (!form || !value) {
//         return;
//       }

//       const startDate: Moment | null = form.getFieldValue([...baseName, 'Start']);

//       if (!startDate) {
//         throw new Error('Select a starting date');
//       }

//       if (value.diff(startDate) < 0) {
//         throw new Error('This value must be greater than the starting date');
//       }
//     },
//     [form, baseName]
//   );

//   useEffect(() => {
//     if (!settings) {
//       return;
//     }
//     const areFieldsEnabled = settings.ScheduleSetting[name]?.Enabled;
//     setEnabled(areFieldsEnabled);
//   }, [settings, name, editMode]);

//   return (
//     <Row justify="end" wrap>
//       <Col>
//         <Row justify="end" style={{ width: 140 }}>
//           <Form.Item label="Enabled" valuePropName="checked" name={[...baseName, 'Enabled']} labelCol={labelCol} wrapperCol={wrapperCol} labelAlign="right" style={{ width: '80%' }}>
//             <Switch disabled={disabled} onChange={onEnabledSwitchChange} />
//           </Form.Item>
//         </Row>
//         <Row justify="end">{children}</Row>
//       </Col>
//     </Row>
//   );
// };

type ItemType = {
  productMappingNum: number;
  productMappingName: string;
  mappingContentId: string;
  isMapped: boolean;
  type?: string;
};

type SchedulingSectionProps = {
  sectionKey: IntegrationScheduleSettingsSection;
  enableText: string;
  mainCheckboxInitialValue?: boolean;
  templates: any;
  onMainCheckboxChange?: (checked: boolean) => void;
  extraActions?: React.ReactNode;
  onReloadTemplate?: () => void;
};

const useReduxActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(IntegrationsActions, dispatch), [dispatch]);
};

const SchedulingSection: React.FC<SchedulingSectionProps> = ({
  sectionKey,
  enableText,
  templates,
  children,
  extraActions,
  onMainCheckboxChange,
  onReloadTemplate,
}) => {
  const actions = useReduxActions();
  const form = useContext(FormsContext);
  const { channelNum } = useParams<{
    channelNum: string;
    channelAccountNum: string;
    platformNum: string;
  }>();
  // const channels = useSelector(
  //   ({ integrations }: { integrations: IntegrationsState }) => integrations.channels,
  // );
  const globalEnabled = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.globalEnabled,
  );

  const activeChannel = useMemo(() => globalEnabled.find((c) => Number(c.channelNum) === Number(channelNum)), [
    globalEnabled,
    channelNum,
  ]);
  // const channelName = useMemo(() => (activeChannel ? activeChannel.channelName : ''), [activeChannel]);
  const editMode = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.editMode,
  );
  const processing = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.savingSettings,
  );
  const settings = useSelector((state: AppState) => state.integrations.profileSettings);
  const [, onSwitchChange] = useObserveSwitch();
  // const [, onCheckboxChange] = useObserveCheckbox();
  // const disabled = useMemo(() => !editMode || !checkboxChecked || processing, [editMode, checkboxChecked, processing]);
  // const checkboxChange = useCallback(
  //   (e: CheckboxChangeEvent) => {
  //     onMainCheckboxChange?.(e.target.checked);
  //     onCheckboxChange(e);
  //   },
  //   [onMainCheckboxChange, onCheckboxChange],
  // );
  const switchChange = useCallback(
    (checked: boolean, sectionKey: string) => {
      actions.setFormDirtyStatus(true);
      onMainCheckboxChange?.(checked);
      onSwitchChange(checked);
      //'settings', 'ScheduleSetting', sectionKey, 'Update'
      if (sectionKey === 'InventorySettings') {
        form?.setFieldsValue({
          settings: {
            ScheduleSetting: {
              InventorySettings: {
                Update: checked,
              } as any,
            },
          },
        });
      }

      if (sectionKey === 'ProductSettings') {
        form?.setFieldsValue({
          settings: {
            ScheduleSetting: {
              ProductSettings: {
                Update: checked,
                Enabled: checked, // added on 2023.6.26
              } as any,
            },
          },
        });
      }

      if (sectionKey === 'OfferSetting') {
        form?.setFieldsValue({
          settings: {
            ScheduleSetting: {
              OfferSetting: {
                Update: checked,
                Enabled: checked,
              } as any,
            },
          },
        });
      }
      //console.log('form->', form?.getFieldValue('settings'));
    },
    [onMainCheckboxChange, form, onSwitchChange, actions],
  );
  const [templatesData, setTemplatesData] = useState<any>();
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();

  // const col1LgWidth = useMemo(() => (sectionKey === 'ProductSettings' ? 8 : 10), [sectionKey]);

  // const col2LgWidth = useMemo(() => (sectionKey === 'ProductSettings' ? 8 : 14), [sectionKey]);

  const title = useMemo(() => {
    let value = sectionKey.replace(/Settings/g, '');

    if (value === 'OfferSetting') {
      value = 'Offer (Promotion) Management'
    } else {
      value += ' Management';
    }

    return value;
  }, [sectionKey]);

  const checkboxText = useMemo(() => {
    if (!activeChannel) {
      return '';
    }

    return enableText
      .replace('{name}', activeChannel.channelName)
      .replace('{category}', activeChannel.category);
  }, [activeChannel, enableText]);

  // const exportTemplate = async (productMappingNum: number, name: string) => {
  //   try {
  //     const res = await downloadTemplate(productMappingNum);
  //     const content = res;
  //     const url = window.URL.createObjectURL(new Blob([content]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', `Template-${name}.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) { }
  // };

  // const delTemplate = async (tp: any) => {
  //   try {
  //     Modal.confirm({
  //       title: 'Do you want to delete this template?',
  //       icon: <ExclamationCircleOutlined />,
  //       content: `name: ${tp.productMappingName}`,
  //       onOk: async () => {
  //         const res = await deleteTemplate(tp.productMappingNum);
  //         if (res) {
  //           message.success(res);
  //           onReloadTemplate && onReloadTemplate();
  //         }
  //       },
  //       onCancel() { },
  //     });
  //   } catch (error) { }
  // };

  const handleClickImportNew = (key: number, channelNum: number) => {
    setCurrent({
      templateType: key,
      channelNum,
      title: `Upload ${key === 1 ? 'Channel Catalog' : key === 2 ? 'Channel Inventory' : 'Custom'
        } Mapping template`,
    });
    setUploadModalVisible(true);
  };

  useEffect(() => {
    if (!settings) {
      return;
    }
    const enabled = settings?.ScheduleSetting?.[sectionKey]?.Update || false;

    onMainCheckboxChange?.(enabled);
    // setCheckboxChecked(enabled);
  }, [editMode, sectionKey, onMainCheckboxChange, settings]);

  useEffect(() => {
    setTemplatesData(templates);
  }, [templates]);

  return (
    <SchedulingSectionWrapper>
      <SchedulingSectionTitle>{title}</SchedulingSectionTitle>
      <Space direction="vertical" style={{ width: '100%' }}>
        {settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate &&
          sectionKey === 'ProductSettings' &&
          templatesData?.mappedProductTemplates.length === 0 && (
            <Row align="middle">
              <Text strong>
                Need to{' '}
                <Button
                  type="primary"
                  style={{ marginLeft: 4, marginRight: 4 }}
                  onClick={() => handleClickImportNew(1, Number(channelNum))}
                >
                  Import Channel Catalog Template
                </Button>{' '}
                to enable product management.
              </Text>
            </Row>
          )}

        {settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate &&
          sectionKey !== 'ProductSettings' &&
          templatesData?.mappedProductTemplates.length === 0 && (
            <Row align="middle">
              <Text strong>
                Need to{' '}
                <Button
                  type="primary"
                  style={{ marginLeft: 4, marginRight: 4 }}
                  onClick={() => handleClickImportNew(2, Number(channelNum))}
                >
                  Import Inventory Template
                </Button>{' '}
                to enable Inventory management.
              </Text>
            </Row>
          )}

        {/* {templatesData?.mappedProductTemplates.length === 0 && sectionKey === 'ProductSettings' && (
          <Row align="middle">
            <Text strong>
              Need to{' '}
              <Button type="primary" style={{ marginLeft: 4, marginRight: 4 }}>
                Map Channel catalog Template
              </Button>{' '}
              to enable product management.
            </Text>
          </Row>
        )} */}

        {/* {templatesData?.mappedInventoryTemplates.length === 0 && sectionKey !== 'ProductSettings' && (
          <Row align="middle">
            <Text strong>
              Need to{' '}
              <Button type="primary" style={{ marginLeft: 4, marginRight: 4 }}>
                Map Inventory Template
              </Button>{' '}
              to enable Inventory management.
            </Text>
          </Row>
        )} */}
        {((sectionKey === 'ProductSettings' &&
          templatesData &&
          (templatesData.mappedProductTemplates || []).length !== 0) ||
          sectionKey !== 'ProductSettings') ? (
          <Row align="middle" wrap={false}>
            <Form.Item
              style={{ minWidth: 500, marginLeft: 12, marginBottom: 0 }}
              wrapperCol={{span:22}}
              name={['settings', 'ScheduleSetting', sectionKey, 'Update']}
              valuePropName="checked"
            >
              {/* <Checkbox disabled={!editMode || processing} onChange={checkboxChange}>
                {checkboxText}
              </Checkbox> */}
              <Switch
                defaultChecked={settings?.ScheduleSetting?.[sectionKey]?.Update}
                style={{ marginRight: 8 }}
                checkedChildren="on"
                unCheckedChildren="off"
                disabled={!editMode || processing}
                onChange={(checked: boolean) => switchChange(checked, sectionKey)}
              />
              {checkboxText}
            </Form.Item>
            {sectionKey === 'ProductSettings' || sectionKey === 'OfferSetting' ? (
              <ProductsActionButton isOffer={sectionKey === 'OfferSetting'} />
            ) : (
              <Space>
                {extraActions}
                <ActionButton section={sectionKey} />
              </Space>
            )}
          </Row>
        ) : (
          <Typography.Text>No Channel Catalog Template Found.</Typography.Text>
        )}
        {children}

        {/* {sectionKey === 'ProductSettings' && settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate && (
          <Card
            size="small"
            title={
              <Text style={{ fontSize: 16 }} type="secondary">
                Template
              </Text>
            }
            bordered={false}
          >
            {templatesData?.mappedProductTemplates.map((item: ItemType) => (
              <Row key={item.productMappingNum} style={{ width: '100%', marginBottom: 6 }}>
                <Text strong style={{ width: 588, marginTop: 6 }}>
                  {item.productMappingName}
                </Text>
                {item.isMapped ? (
                  <Space>
                    <Link
                      to={`/integrations/${channelNum}-${platformNum}/mapping/products/${channelAccountNum}?productMappingNum=${item.productMappingNum}`}
                    >
                      <Button type="link">Mapping</Button>
                    </Link>
                    <Button
                      type="link"
                      onClick={() =>
                        exportTemplate(item.productMappingNum, item.productMappingName)
                      }
                    >
                      Export
                    </Button>
                    <Button type="link" onClick={() => delTemplate(item)}>
                      Delete
                    </Button>
                  </Space>
                ) : (
                  <Link
                    to={`/integrations/${channelNum}-${platformNum}/mapping/products/${channelAccountNum}?productMappingNum=${item.productMappingNum}`}
                  >
                    <Button>Configure mapping to enable</Button>
                  </Link>
                )}
              </Row>
            ))}
            {settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate && (
              <Button onClick={() => handleClickImportNew(5, Number(channelNum))}>
                Import Customs Template
              </Button>
            )}
          </Card>
        )} */}

        {/* {sectionKey !== 'ProductSettings' && settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate && (
          <Card
            size="small"
            title={
              <Text style={{ fontSize: 16 }} type="secondary">
                Template
              </Text>
            }
            bordered={false}
          >
            {(templatesData?.mappedInventoryTemplates || []).map((item: ItemType) => (
              <Row key={item.productMappingNum} style={{ width: '100%' }}>
                <Text strong style={{ width: 588 }}>
                  {item.productMappingName}
                </Text>
                {item.isMapped ? (
                  <Space>
                    <Link
                      to={`/integrations/${channelNum}-${platformNum}/mapping/products/${channelAccountNum}?productMappingNum=${item.productMappingNum}`}
                    >
                      <Button type="default">Mapping</Button>
                    </Link>
                    <Button
                      type="link"
                      onClick={() =>
                        exportTemplate(item.productMappingNum, item.productMappingName)
                      }
                    >
                      Export
                    </Button>
                    <Button type="link" onClick={() => delTemplate(item)}>
                      Delete
                    </Button>
                  </Space>
                ) : (
                  <Link
                    to={`/integrations/${channelNum}-${platformNum}/mapping/products/${channelAccountNum}?productMappingNum=${item.productMappingNum}`}
                  >
                    <Button>Configure mapping to enable</Button>
                  </Link>
                )}
              </Row>
            ))}
            {settings?.ScheduleSetting?.[sectionKey]?.NeedTemplate && (
              <Button onClick={() => handleClickImportNew(6, Number(channelNum))}>
                Import Customs Template
              </Button>
            )}
          </Card>
        )} */}

        {/* <Col xs={24} lg={col1LgWidth}>
          <Form.Item
            name={['settings', 'ScheduleSetting', sectionKey, 'Update']}
            valuePropName="checked"
          >
            <Checkbox
              disabled={!editMode || processing}
              onChange={checkboxChange}
            >
              {checkboxText}
            </Checkbox>
          </Form.Item>
          <Space>
            <ImportMappingTemplateSelect
              section={sectionKey}
              channelNum={channelNum}
              channelAccountNum={channelAccountNum}
            />
            {extraActions}
          </Space>
          <Spacer />
          {children}
        </Col>
        {sectionKey === 'ProductSettings' && (
          <Col xs={24} lg={col2LgWidth}>
            <CopywritingTemplateDownloader
              channelAccountNum={Number(channelAccountNum)}
              channelName={channelName}
              channelNum={Number(channelNum)}
              style={{ width: '100%' }}
            />
            <Spacer />
            <CopywritingTemplateUploader
              channelAccountNum={Number(channelAccountNum)}
              channelNum={Number(channelNum)}
              style={{ width: '100%' }}
            />
          </Col>
        )}
        <Col xs={24} lg={col2LgWidth}>
          <SchedulingControls
            name={sectionKey}
            disabled={disabled}
          >
            {
              sectionKey === 'ProductSettings' ?
                <ProductsActionButton /> :
                <ActionButton section={sectionKey} />
            }
          </SchedulingControls>
        </Col> */}
      </Space>
      {uploadModalVisible && (
        <UploadModal
          {...current}
          onCancel={() => setUploadModalVisible(false)}
          onOk={() => {
            setUploadModalVisible(false);
            onReloadTemplate && onReloadTemplate();
          }}
        />
      )}
    </SchedulingSectionWrapper>
  );
};

type TableDCMapping = Entities.DistributionCenterWarehouseMapping & { id: string };

const inventoryWarehouseTableColumns = [
  {
    title: 'Name',
    dataIndex: 'Name',
    sorter: {
      compare: (
        a: Entities.DistributionCenterWarehouseMapping,
        b: Entities.DistributionCenterWarehouseMapping,
      ) => {
        if (a.Name < b.Name) {
          return -1;
        }

        return a.Name > b.Name ? 1 : 0;
      },
      multiple: 1,
    },
  },
  {
    title: 'Code',
    dataIndex: 'Code',
    sorter: {
      compare: (
        a: Entities.DistributionCenterWarehouseMapping,
        b: Entities.DistributionCenterWarehouseMapping,
      ) => {
        if (a.Code < b.Code) {
          return -1;
        }

        return a.Code > b.Code ? 1 : 0;
      },
      multiple: 2,
    },
  },
  {
    title: 'City',
    dataIndex: 'City',
    sorter: {
      compare: (
        a: Entities.DistributionCenterWarehouseMapping,
        b: Entities.DistributionCenterWarehouseMapping,
      ) => {
        if (a.City < b.City) {
          return -1;
        }

        return a.City > b.City ? 1 : 0;
      },
      multiple: 3,
    },
  },
  {
    title: 'Channel Warehouse Code',
    dataIndex: 'ChannelAccountWarehouseCodes',
    render(text: string, record: TableDCMapping) {
      const baseName = [
        'settings',
        'ScheduleSetting',
        'InventorySettings',
        'DistributionCenterWarehouseMappings',
        record.DistributionCenterNum.toString(),
      ];
      return (
        <>
          <Form.Item
            initialValue={record.DistributionCenterNum}
            name={[...baseName, 'DistributionCenterNum']}
            noStyle
            hidden
          >
            <Input hidden />
          </Form.Item>
          <Form.Item initialValue={record.Name} name={[...baseName, 'Name']} noStyle hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item initialValue={record.Code} name={[...baseName, 'Code']} noStyle hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item initialValue={record.City} name={[...baseName, 'City']} noStyle hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            initialValue={record.ChannelAccountWarehouseCode}
            name={[...baseName, 'ChannelAccountWarehouseCode']}
            noStyle
          >
            <Input />
          </Form.Item>
        </>
      );
    },
  },
];

const InventorySchedulingSection: React.FC<SchedulingSectionProps> = ({
  sectionKey,
  enableText,
  templates,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [distributionCenters, setDistributionCenters] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [projectedPercentageEnabled, setProjectedPercentageEnabled] = useState<boolean>(false);
  const [projectedMaxQtyEnabled, setProjectedMaxQtyEnabled] = useState<boolean>(false);
  const [projectedSendUnitsEnabled, setProjectedSendUnitsEnabled] = useState<boolean>(false);

  const baseName = useMemo(() => ['settings', 'ScheduleSetting', sectionKey], [sectionKey]);
  const editMode = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.editMode,
  );
  const processing = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.savingSettings,
  );
  const settings = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.profileSettings,
  );
  const form = useContext(FormsContext);
  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators(IntegrationsActions, dispatch), [dispatch]);
  const updateReduxForm = actions.setProfileSettings;
  const onWarehouseEditCancel = useCallback(
    (distributionCenters: Entities.DistributionCenterWarehouseMapping[]) => {
      if (!form) {
        return;
      }
      const dcMappings: { [key: string]: Entities.DistributionCenterWarehouseMapping } =
        settings?.ScheduleSetting?.InventorySettings?.DistributionCenterWarehouseMappings || {};

      // Add back to the form those entries that weren't available in the original API object.
      // At the end of the day, those who don't have a `ChannelAccountWarehouseCode` are not stored
      distributionCenters.forEach((dc) => {
        const dcNum = dc.DistributionCenterNum.toString();

        if (!dcMappings[dcNum]) {
          dcMappings[dcNum] = dc;
        }
      });

      form.setFieldsValue({
        settings: {
          ScheduleSetting: {
            InventorySettings: {
              DistributionCenterWarehouseMappings: dcMappings,
            } as any,
          },
        },
      });
    },
    [settings, form],
  );
  const onWarehouseApply = useCallback(() => {
    if (!form || !settings) {
      return;
    }
    // There's a need to store the whole `InventorySettings` object as there are extra attributes, such as `bools` that
    // are not available on the original API object.
    const inventorySettings = form.getFieldValue([
      'settings',
      'ScheduleSetting',
      'InventorySettings',
    ]);
    const newSettings = _.cloneDeep(settings);
    newSettings.ScheduleSetting.InventorySettings = inventorySettings;
    updateReduxForm(newSettings);
    actions.setFormDirtyStatus(true);
  }, [form, settings, updateReduxForm, actions]);
  const [useAssignedInventory, setUseAssignedInventory] = useState(false);
  const [isProjectedQtyRequired, setIsProjectedQtyRequired] = useState(false);
  const [enableProjectedQty, setEnableProjectedQty] = useState(false);
  const [inventoryRuleNum, setInventoryRuleNum] = useState(0);
  const [defaultRuleNum, setDefaultRuleNum] = useState<number>();
  // Checkboxes
  const [checkboxChecked, setCheckboxChecked] = useState<boolean | undefined>();
  const resetPercentage = useCallback(
    (checked: boolean) => {
      if (!form || checked) {
        return;
      }

      const payload: any = {
        settings: {
          ScheduleSetting: {
            InventorySettings: {
              SendPercentageTotalQty: null,
              NeedTemplate: false,
              DistributionCenterWarehouseMappings: {},
            },
          },
        },
      };

      form.setFieldsValue(payload);
    },
    [form],
  );
  const resetMaxQty = useCallback(
    (checked: boolean) => {
      if (!form || checked) {
        return;
      }

      const payload: any = {
        settings: { ScheduleSetting: { InventorySettings: { MaxQty: null } } },
      };
      form.setFieldsValue(payload);
    },
    [form],
  );
  const resetSendUnits = useCallback(
    (checked: boolean) => {
      if (!form || checked) {
        return;
      }

      const payload: any = {
        settings: { ScheduleSetting: { InventorySettings: { LessQty: null, SendUnits: null } } },
      };
      form.setFieldsValue(payload);
    },
    [form],
  );
  const [maxQtyEnabled, onMaxQtyEnabledChange, setMaxQtyEnabled] = useObserveSwitch(
    false,
    resetMaxQty,
  );
  const [percentageEnabled, onPercentageEnabledChange, setPercentageEnabled] = useObserveSwitch(
    false,
    resetPercentage,
  );
  const [sendUnitsEnabled, onSendUnitsEnabledChange, setSendUnitsEnabled] = useObserveSwitch(
    false,
    resetSendUnits,
  );

  /**
 * Retrieves the DCs and applies some mapping to have a consistent  list of them:
 * - Adds an index
 */
  const getMappedDistributionCenters = useCallback(async () => {
    try {
      setFetching(true);
      const mappings = settings?.ScheduleSetting?.InventorySettings?.DistributionCenterWarehouseMappings || {};
      const data = await fetchDistributionCenters();
      const temp = data
        .filter((dc) => dc.DistributionCenterStatus === 1) // Filtering inactive DCs because BE does not support it.
        .map((dc) => {
          const distributionCenterNum: number = Number(dc.DistributionCenterID.split('-')[1]);
          return {
            id: dc.DistributionCenterID,
            DistributionCenterNum: distributionCenterNum,
            Name: dc.DistributionCenterName,
            Code: dc.DistributionCenterCode,
            City: dc.City,
            ChannelAccountWarehouseCode: '',
            IsSelected: mappings[`${distributionCenterNum}`]?.IsSelected || 0,
          };
        })
        .sort((a, b) => a.DistributionCenterNum - b.DistributionCenterNum);
      setDistributionCenters(temp);
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  }, [settings])

  useEffect(() => {
    if (!settings) return;
    const { IsProjectedQtyRequired = false } = settings?.ScheduleSetting || {};
    const { UseAssignedInventory, EnableProjectedQty } = settings?.ScheduleSetting?.InventorySettings || {};
    setIsProjectedQtyRequired(IsProjectedQtyRequired as boolean);
    setEnableProjectedQty(settings?.ScheduleSetting?.InventorySettings?.EnableProjectedQty);
    getMappedDistributionCenters();
    setInventoryRuleNum(
      !UseAssignedInventory && !EnableProjectedQty ? 2
        : UseAssignedInventory && !EnableProjectedQty ? 1
          : !UseAssignedInventory && EnableProjectedQty ? 3 : 0
    )
    setDefaultRuleNum(!UseAssignedInventory && !EnableProjectedQty ? 2
      : UseAssignedInventory && !EnableProjectedQty ? 1
        : !UseAssignedInventory && EnableProjectedQty ? 3 : 0)
    // eslint-disable-next-line
  }, [settings])

  useEffect(() => {
    const { ScheduleSetting } = form?.getFieldsValue(baseName)?.settings || {};

    if (!ScheduleSetting) {
      return;
    }
    const {
      SendPercentageTotalQty,
      MaxQty,
      LessQty,
      Update,
      UseAssignedInventory,
      EnableProjectedQty,
      ProjectedQtySetting = {},
    } = ScheduleSetting.InventorySettings;

    const {
      SendPercentageTotalQty: projectedSendPercentageTotalQty,
      MaxQty: projetedMaxQty,
      LessQty: projetedLessQty,
    } = ProjectedQtySetting || {};

    setPercentageEnabled(SendPercentageTotalQty > 0);
    setMaxQtyEnabled(Number(MaxQty) > 0);
    setSendUnitsEnabled(Number(LessQty) > 0);
    setCheckboxChecked(Update);
    setUseAssignedInventory(UseAssignedInventory);

    setEnableProjectedQty(EnableProjectedQty);
    setProjectedMaxQtyEnabled(Number(projetedMaxQty) > 0);
    setProjectedPercentageEnabled(Number(projectedSendPercentageTotalQty) > 0);
    setProjectedSendUnitsEnabled(Number(projetedLessQty) > 0);
  }, [
    form,
    setMaxQtyEnabled,
    setPercentageEnabled,
    setSendUnitsEnabled,
    setCheckboxChecked,
    editMode,
    baseName,
  ]);

  const warehouseSelectionDisabled = !editMode || !checkboxChecked;
  const disabled = processing || !editMode || !checkboxChecked;
  const inventoryRulesDisabled = disabled || useAssignedInventory;
  const projectedQtyDisabled = disabled || !enableProjectedQty;

  return (
    <>
      <WarehouseSelectionModal
        disabled={warehouseSelectionDisabled}
        onCancel={onWarehouseEditCancel}
        onApply={onWarehouseApply}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        distributionCenters={distributionCenters}
        setDistributionCenters={setDistributionCenters}
        loading={fetching}
        inventoryWarehouseTableColumns={inventoryWarehouseTableColumns}
      />
      <SchedulingSection
        sectionKey={sectionKey}
        enableText={enableText}
        onMainCheckboxChange={setCheckboxChecked}
        templates={templates}
      // extraActions={
      //   <Button disabled={disabled} onClick={() => setModalVisible(true)}>
      //     <Map />
      //     Channel Warehouse Code Mapping
      //   </Button>
      // }
      >
        {/** required fields for api */}
        <div style={{ maxHeight: 0, overflow: 'hidden' }}>
          <Form.Item name={['settings', 'ChannelAccountSettingNum']} noStyle hidden />
          <Form.Item name={['settings', 'MasterAccountNum']} noStyle hidden />
          <Form.Item name={['settings', 'ProfileNum']} noStyle hidden />
          <Form.Item name={['settings', 'ChannelNum']} noStyle hidden />
          <Form.Item name={['settings', 'ChannelAccountNum']} noStyle hidden />
          <Form.Item name={[...baseName, 'UseAssignedInventory']} noStyle hidden />
          <Form.Item name={[...baseName, 'EnableProjectedQty']} noStyle hidden />
        </div>
        {/** end required fields */}
        <InventoryExtraSection>
          <Card title="Inventory Rule Settings">
            {/* <Form.Item name={[...baseName, 'UseAssignedInventory']}> */}
            <Form.Item>
              <Radio.Group
                disabled={disabled}
                value={inventoryRuleNum}
                onChange={(e) => {
                  const { value } = e.target;
                  setInventoryRuleNum(value);
                  if (value === 1) {
                    setEnableProjectedQty(false);
                    setUseAssignedInventory(true);
                    form?.setFieldsValue({
                      settings: {
                        ScheduleSetting: {
                          InventorySettings: {
                            EnableProjectedQty: false,
                            UseAssignedInventory: true,
                          } as any,
                        },
                      },
                    })
                  }
                  if (value === 2) {
                    setEnableProjectedQty(false);
                    setUseAssignedInventory(false);
                    form?.setFieldsValue({
                      settings: {
                        ScheduleSetting: {
                          InventorySettings: {
                            EnableProjectedQty: false,
                            UseAssignedInventory: false,
                          } as any,
                        },
                      },
                    })
                  }
                  if (value === 3) {
                    setEnableProjectedQty(true);
                    setUseAssignedInventory(false);
                    form?.setFieldsValue({
                      settings: {
                        ScheduleSetting: {
                          InventorySettings: {
                            EnableProjectedQty: true,
                            UseAssignedInventory: false,
                          } as any,
                        },
                      },
                    })
                  }
                  actions.setFormDirtyStatus(true);
                }}
              >
                <Space direction="vertical">
                  <Radio disabled={isProjectedQtyRequired && defaultRuleNum===3} value={1}>Use Channel Specific Quantity</Radio>
                  {/* <Radio value={false}>Use CC Inventory Rules</Radio> */}
                  <Radio disabled={isProjectedQtyRequired && defaultRuleNum===3} value={2}>Use ATS (Available to Sell) Quantity</Radio>
                  {
                    isProjectedQtyRequired && (
                      // <Form.Item name={[...baseName, 'EnableProjectedQty']} valuePropName="checked">
                      //   <Checkbox
                      //     disabled={disabled}
                      //     onChange={e => setEnableProjectedQty(e.target.checked)}
                      //   >
                      //     Use Projected Qty
                      //   </Checkbox>
                      // </Form.Item>
                      <Radio value={3}>Use Projected Qty</Radio>
                    )
                  }
                </Space>
              </Radio.Group>
            </Form.Item>
            {
              !useAssignedInventory && inventoryRuleNum === 2 && (
                <InventoryExtraSection>
                  <Form.Item>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Form.Item
                        noStyle
                        name={[...baseName, 'bools', 'SendPercentageTotalQty']}
                        valuePropName="checked"
                      >
                        <Switch
                          defaultChecked={
                            !!settings?.ScheduleSetting?.InventorySettings?.bools?.SendPercentageTotalQty
                          }
                          checkedChildren="on"
                          unCheckedChildren="off"
                          style={{ marginRight: 8 }}
                          disabled={inventoryRulesDisabled}
                          onChange={(checked: boolean) => {
                            onPercentageEnabledChange(checked);
                            form?.setFieldsValue({
                              settings: {
                                ScheduleSetting: {
                                  InventorySettings: {
                                    bools: {
                                      SendPercentageTotalQty: checked,
                                    },
                                  } as any,
                                },
                              },
                            });
                            actions.setFormDirtyStatus(true);
                          }}
                        />{' '}
                        Send percentage of total quantity
                      </Form.Item>

                      <Form.Item
                        noStyle
                        name={[...baseName, 'SendPercentageTotalQty']}
                        rules={[{ required: percentageEnabled, message: 'Please enter a value' }]}
                      >
                        <Input
                          style={{ width: 80, marginRight: 8, marginLeft: 8 }}
                          type="number"
                          min={0}
                          step={1}
                          disabled={inventoryRulesDisabled || !percentageEnabled}
                        />
                      </Form.Item>
                      <span>%</span>
                    </Row>
                  </Form.Item>

                  <Form.Item name={[...baseName, 'bools', 'LessQty']} valuePropName="checked">
                    <Space>
                      <Switch
                        defaultChecked={!!settings?.ScheduleSetting?.InventorySettings?.bools?.LessQty}
                        checkedChildren="on"
                        unCheckedChildren="off"
                        disabled={inventoryRulesDisabled}
                        onChange={(checked: boolean) => {
                          onSendUnitsEnabledChange(checked);
                          form?.setFieldsValue({
                            settings: {
                              ScheduleSetting: {
                                InventorySettings: {
                                  bools: {
                                    LessQty: checked,
                                  },
                                } as any,
                              },
                            },
                          });
                          actions.setFormDirtyStatus(true);
                        }}
                      />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          justifyItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>If total quantity is less than</span>
                        <Form.Item
                          noStyle
                          name={[...baseName, 'LessQty']}
                          rules={[{ required: sendUnitsEnabled, message: 'Please enter a value' }]}
                        >
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={inventoryRulesDisabled || !sendUnitsEnabled}
                          />
                        </Form.Item>
                        <span>units, send </span>
                        <Form.Item
                          noStyle
                          name={[...baseName, 'SendUnits']}
                          rules={[{ required: sendUnitsEnabled, message: 'Please enter a value' }]}
                        >
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={inventoryRulesDisabled || !sendUnitsEnabled}
                          />
                        </Form.Item>
                        <span>units</span>
                      </Row>
                    </Space>
                    {/* </Checkbox> */}
                  </Form.Item>

                  <Form.Item
                    name={[...baseName, 'bools', 'MaxQty']}
                    valuePropName="checked"
                    rules={[{ required: maxQtyEnabled, message: 'Please enter a value' }]}
                  >
                    <Space>
                      <Switch
                        defaultChecked={!!settings?.ScheduleSetting?.InventorySettings?.bools?.MaxQty}
                        checkedChildren="on"
                        unCheckedChildren="off"
                        disabled={inventoryRulesDisabled}
                        onChange={(checked: boolean) => {
                          onMaxQtyEnabledChange(checked);
                          form?.setFieldsValue({
                            settings: {
                              ScheduleSetting: {
                                InventorySettings: {
                                  bools: {
                                    MaxQty: checked,
                                  },
                                } as any,
                              },
                            },
                          });
                          actions.setFormDirtyStatus(true);
                        }}
                      />

                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          justifyItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>Send maximum quantity of</span>
                        <Form.Item noStyle name={[...baseName, 'MaxQty']}>
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={inventoryRulesDisabled || !maxQtyEnabled}
                          />
                        </Form.Item>
                        <span>units</span>
                      </Row>
                    </Space>
                  </Form.Item>
                </InventoryExtraSection>
              )
            }
            {
              isProjectedQtyRequired && enableProjectedQty && inventoryRuleNum === 3 && (
                <InventoryExtraSection>
                  <Form.Item>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Form.Item
                        noStyle
                        name={[...baseName, 'bools', 'ProjectedQtySetting', 'SendPercentageTotalQty']}
                        valuePropName="checked"
                      >
                        <Switch
                          defaultChecked={
                            !!settings?.ScheduleSetting?.InventorySettings?.bools?.ProjectedQtySetting?.SendPercentageTotalQty
                          }
                          checkedChildren="on"
                          unCheckedChildren="off"
                          style={{ marginRight: 8 }}
                          disabled={projectedQtyDisabled}
                          onChange={(checked: boolean) => {
                            setProjectedPercentageEnabled(checked);
                            form?.setFieldsValue({
                              settings: {
                                ScheduleSetting: {
                                  InventorySettings: {
                                    bools: {
                                      ProjectedQtySetting: {
                                        SendPercentageTotalQty: checked,
                                      },
                                    },
                                  } as any,
                                },
                              },
                            });
                            actions.setFormDirtyStatus(true);
                          }}
                        />{' '}
                        Send percentage of total quantity
                      </Form.Item>

                      <Form.Item
                        noStyle
                        name={[...baseName, 'ProjectedQtySetting', 'SendPercentageTotalQty']}
                        rules={[{ required: projectedPercentageEnabled, message: 'Please enter a value' }]}
                      >
                        <Input
                          style={{ width: 80, marginRight: 8, marginLeft: 8 }}
                          type="number"
                          min={0}
                          step={1}
                          disabled={projectedQtyDisabled || !projectedPercentageEnabled}
                        />
                      </Form.Item>
                      <span>%</span>
                    </Row>
                  </Form.Item>

                  <Form.Item name={[...baseName, 'bools', 'ProjectedQtySetting', 'LessQty']} valuePropName="checked">
                    <Space>
                      <Switch
                        defaultChecked={!!settings?.ScheduleSetting?.InventorySettings?.bools?.ProjectedQtySetting?.LessQty}
                        checkedChildren="on"
                        unCheckedChildren="off"
                        disabled={projectedQtyDisabled}
                        onChange={(checked: boolean) => {
                          setProjectedSendUnitsEnabled(checked);
                          form?.setFieldsValue({
                            settings: {
                              ScheduleSetting: {
                                InventorySettings: {
                                  bools: {
                                    ProjectedQtySetting: {
                                      LessQty: checked,
                                    }
                                  },
                                } as any,
                              },
                            },
                          });
                          actions.setFormDirtyStatus(true);
                        }}
                      />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          justifyItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>If total quantity is less than</span>
                        <Form.Item
                          noStyle
                          name={[...baseName, 'ProjectedQtySetting', 'LessQty']}
                          rules={[{ required: projectedSendUnitsEnabled, message: 'Please enter a value' }]}
                        >
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={projectedQtyDisabled || !projectedSendUnitsEnabled}
                          />
                        </Form.Item>
                        <span>units, send </span>
                        <Form.Item
                          noStyle
                          name={[...baseName, 'ProjectedQtySetting', 'SendUnits']}
                          rules={[{ required: projectedSendUnitsEnabled, message: 'Please enter a value' }]}
                        >
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={projectedQtyDisabled || !projectedSendUnitsEnabled}
                          />
                        </Form.Item>
                        <span>units</span>
                      </Row>
                    </Space>
                    {/* </Checkbox> */}
                  </Form.Item>

                  <Form.Item
                    name={[...baseName, 'bools', 'ProjectedQtySetting', 'MaxQty']}
                    valuePropName="checked"
                    rules={[{ required: projectedMaxQtyEnabled, message: 'Please enter a value' }]}
                  >
                    <Space>
                      <Switch
                        defaultChecked={!!settings?.ScheduleSetting?.InventorySettings?.bools?.ProjectedQtySetting?.MaxQty}
                        checkedChildren="on"
                        unCheckedChildren="off"
                        disabled={projectedQtyDisabled}
                        onChange={(checked: boolean) => {
                          setProjectedMaxQtyEnabled(checked);
                          form?.setFieldsValue({
                            settings: {
                              ScheduleSetting: {
                                InventorySettings: {
                                  bools: {
                                    ProjectedQtySetting: {
                                      MaxQty: checked,
                                    }
                                  },
                                } as any,
                              },
                            },
                          });
                          actions.setFormDirtyStatus(true);
                        }}
                      />

                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          justifyItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>Send maximum quantity of</span>
                        <Form.Item noStyle name={[...baseName, 'ProjectedQtySetting', 'MaxQty']}>
                          <Input
                            style={{ width: 80, marginLeft: 8, marginRight: 8 }}
                            type="number"
                            min={0}
                            step={1}
                            disabled={projectedQtyDisabled || !projectedMaxQtyEnabled}
                          />
                        </Form.Item>
                        <span>units</span>
                      </Row>
                    </Space>
                  </Form.Item>
                </InventoryExtraSection>
              )
            }
          </Card>
          <Card title="Warehouse Settings" style={{ marginTop: 10 }}>
            <Typography.Title style={{ fontSize: 14 }}>
              <Row justify="space-between" align="middle">
                <Col>Assigned Warehouse</Col>
                <Col>
                  <Button disabled={disabled || !checkboxChecked} onClick={() => setModalVisible(true)}>
                    <Map />
                    Change Warehouse Setting
                  </Button>
                </Col>
              </Row>
            </Typography.Title>
            <WarehouseSelectionModalContent
              onApply={onWarehouseApply}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              distributionCenters={distributionCenters}
              setDistributionCenters={setDistributionCenters}
              loading={fetching}
              inventoryWarehouseTableColumns={inventoryWarehouseTableColumns}
              onlyTable={true}
            />
          </Card>
        </InventoryExtraSection>
      </SchedulingSection >
    </>
  );
};

const SchedulingTab: React.FC = () => {
  const isActivated = useSelector((state: any) => state.integrations.isActivated);

  const { channelAccountNum } = useParams<{ channelNum: string; channelAccountNum: string }>();

  const getPayloadInfo = useCallback(async (): Promise<{
    mappedProductTemplates: ItemType[];
    mappedInventoryTemplates: ItemType[];
  }> => {
    if (!channelAccountNum) {
      return { mappedProductTemplates: [], mappedInventoryTemplates: [] };
    }
    const res = await getChannelExportViewInfo(Number(channelAccountNum));
    return res || { mappedProductTemplates: [], mappedInventoryTemplates: [] };
  }, [channelAccountNum]);

  const [data, , setReload] = useExecutePromise(getPayloadInfo, {
    mappedProductTemplates: [],
    mappedInventoryTemplates: [],
  });

  if (!isActivated) {
    // No UI for non-activated channel accounts
    return <h1>Not activated</h1>;
  }

  return (
    <div>
      {Object.keys(sections).map((section) => {
        let Section: React.FC<SchedulingSectionProps>;
        const props: SchedulingSectionProps & { key: string } = {
          enableText: sections[section],
          sectionKey: section as IntegrationScheduleSettingsSection,
          key: section,
          templates: data,
          onReloadTemplate: () => setReload(true),
        };

        switch (section) {
          case 'InventorySettings':
            Section = InventorySchedulingSection;
            break;
          default:
            Section = SchedulingSection;
            break;
        }

        // eslint-disable-next-line react/jsx-props-no-spreading,react/jsx-key
        return <Section {...props} />;
      })}
    </div>
  );
};

export default SchedulingTab;
