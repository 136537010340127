import React from 'react';
import { Input, Typography, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DEFAULT_SUCCESS_MSG_DISPLAY_DURATION } from '../../../constants/config';
import { EditorWrapper } from '../styledComponents';

type Props = {
  className?: string;
  value: any;
};

const ReadonlyCell = (props: Props) => {
  const attrReadonlyField = () => {
    return (
      <span className="cell-value visual-readonly-ctn">
        <Typography.Text ellipsis={{tooltip: props.value}}>
          {props.value}
          {/*attrReadonlyView()*/}
        </Typography.Text>
      </span>
    );
  };

  const copyValue = () => {
    return props.value === null || props.value === undefined ? '' : `${props.value}`.trim();
  };

  const notifyCopyMsg = () => {
    copyValue() ?
      notification.success({message: 'Attribute value has been copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION}) :
      notification.info({message: 'No value can be copied', duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION})
  };

  return (<>
    <EditorWrapper className={props.className || ''}>
      <Input.Group compact>
        {attrReadonlyField()}
        <CopyToClipboard
          text={copyValue()}
          onCopy={() => notifyCopyMsg()}
        >
          <span
            className="icon-ctn-disabled"
            title="Copy value to clipboard"
          >
            <CopyOutlined />
          </span>
        </CopyToClipboard>
      </Input.Group>
    </EditorWrapper>
  </>);
};

export default ReadonlyCell;
