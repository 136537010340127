import React from 'react';

type Props = {
  row?: StringKAnyVPair;
  url: string;
};

const LinkCell = (props: Props) => {
  return (<>
    <a href={props.url} rel="noreferrer" target="_blank">{props.url}</a>
  </>);
};

export default LinkCell;
