import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import { MaskLayer } from './styledComponents';

type Props = {
  clearOnClick?: boolean;
  height?: number | string;
  onClick?: Function;
  visible?: boolean;
  width?: number | string;
  x?: number;
  y?: number;
  zIndex?: number;
};

const MaskOverlay = (props: Props) => {
  const maskId = React.useRef<string>(uuid());
  const maskRef = React.useRef<any>(null);

  // eslint-disable-next-line
  const clearBottomMask = () => {
    /*var elem = document.getElementById("konnichiwa_sekai");
    if(elem) {
      document.body.removeChild(elem);
    }*/
    if (maskRef.current/*&& maskRef.current.parentNode*/) {
      document.body.removeChild(maskRef.current.parentNode);
      maskRef.current = null;
    }

    if (props.clearOnClick) {
      let wrp = document.getElementById(maskId.current);

      if (wrp) {
        console.log('clear props3', maskId, maskRef);
        document.body.removeChild(wrp);
      }
    }
  };

  // eslint-disable-next-line
  const coverBottomMask = () => {
    /*var elem = document.getElementById("konnichiwa_sekai");
    if(elem) {
      return
    }*/
    if (maskRef.current) return;

    if (maskId.current && document.getElementById(maskId.current)) return;

    const ctn = document.createElement('div');
    ctn.setAttribute('id', maskId.current);
    const mask = (
      <MaskLayer
        onClick={handleClick}
        ref={maskRef}
        style={{
          left: props.x,
          top: props.y,
          height: props.height,
          width: props.width,
          zIndex: props.zIndex,
        }}
      />
    );

    document.body.appendChild(ctn);
    ReactDOM.render(mask, ctn);
  };

  const handleClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };

  React.useEffect(() => {
    if (props.visible) {
      coverBottomMask();
    } else {
      clearBottomMask();
    }

    return () => clearBottomMask();
  }, [clearBottomMask, coverBottomMask, props.visible]);

  return (<>
  </>);
};

export default MaskOverlay;
