import React, { useState } from 'react';
import {Button, Form, Modal, Input} from 'antd';
import styled from 'styled-components';
import { isEmail, leftTrim } from '../../util';

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

function AddUserModal (props: any) {
  const [formValues, setFormValues ] = useState({
    UserName: '',
    Email: ''
  });
  const isDisabled = !formValues.UserName || !formValues.Email || !isEmail(formValues.Email.trim());
  
  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: leftTrim(e.target.value)
    });

  };

  const handleClear = () => {
    setFormValues({
      UserName: '',
      Email: '',
    });
    props.handleCancel();
  };

  return (
    <Modal
      visible={props.visible}
      style={{ top: 30 }}
      title={
        <TitleHeader id="users_add_user_modal_title">Add New User</TitleHeader>
      }
      onCancel={props.handleCancel}
      width={600}
      footer={[
        <Button id="users_add_user_modal_cancel_button" key="back" onClick={handleClear}>
          Cancel
        </Button>,
        <Button id="users_add_user_modal_save_invitation_button" disabled={isDisabled} key="submit" type="primary" onClick={() => { props.addUser(formValues); handleClear() }}>
          Send Invitation
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ size: 'middle' }}
        size={'large'}
      >
        <Form.Item label="Name">
          <Input
            id="users_add_user_modal_form_name_input"
            onChange={handleChange}
            value={formValues.UserName}
            name="UserName"
            placeholder="Type name"
          />
        </Form.Item>
        <Form.Item
          label="Email Address"
          validateStatus={!isEmail(formValues.Email.trim()) && formValues.Email.length ? 'error' : undefined}
          help={!isEmail(formValues.Email.trim()) && formValues.Email.length ? 'Invalid email' : undefined}>
          <Input
            id="users_add_user_modal_form_email_input"
            onChange={handleChange}
            value={formValues.Email}
            placeholder="Type email"
            name="Email"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddUserModal;