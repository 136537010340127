import React from 'react';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import Heading from "../components/common/Heading";

function WarehouseMenu() {
  return (
    <ContentLayout>
      <SiteContent>
        <Heading title="Warehouse" />
      </SiteContent>
    </ContentLayout>
  );
}

export default WarehouseMenu;
