import { FormInstance } from 'antd/es/form';
import React, { createContext } from 'react';
import { editChannelControlFlags } from '../../services/products';

export interface IChannelControlFlagScreenState {
  loading: boolean;
  editingKey: string;
  form: FormInstance,
  addModalVisible: boolean;
  confirmationModalVisible: boolean;
  selectedFlag?: any;
  data: Array<Entities.ChannelControlFlag>;
  formValues?: Entities.ChannelControlFlag;
  searchDisabled: boolean;
  isSaving: boolean;
}

export enum StateActions {
  SET_ADD_MODAL,
  SET_LOADING,
  SET_CONFIRMATION_MODAL,
  SET_SELECTED_FLAG,
  SET_EDITING_KEY,
  SET_DATA,
  SET_FORM_VALUES,
  RESET_FORM,
  REPLACE_INLINE_EDIT,
  SET_SEARCH_DISABLED,
  SET_IS_SAVING,
}

export interface DispatchAction {
  type: StateActions;
  params?: any;
}

export const initialFormState: Entities.ChannelControlFlag = {
  ProductCHNLCtrlFlagId: '',
  CHNLCtrlFlag: '',
  CHNLCtrlFlagDesc: '',
};

export const initialState = (form: FormInstance): IChannelControlFlagScreenState => ({
  data: [],
  editingKey: '',
  form,
  loading: false,
  confirmationModalVisible: false,
  addModalVisible: false,
  formValues: initialFormState,
  isSaving: false,
  searchDisabled: false,
});

const replaceRow = (state: IChannelControlFlagScreenState, value: Entities.ChannelControlFlag, rowIndex: number) => {
  const data = [...state.data];
  data.splice(rowIndex, 1, value);
  return {
    ...state,
    data,
  };
};

export function channelControlFlagsReducer(state: IChannelControlFlagScreenState, action: Utils.DispatchAction<StateActions>): IChannelControlFlagScreenState {
  switch (action.type) {
    case StateActions.SET_ADD_MODAL:
      return { ...state, addModalVisible: action.params.value };
    case StateActions.SET_LOADING:
      return { ...state, loading: action.params.value };
    case StateActions.SET_CONFIRMATION_MODAL:
      return { ...state, confirmationModalVisible: action.params.value };
    case StateActions.SET_EDITING_KEY:
      return { ...state, editingKey: action.params.value };
    case StateActions.SET_DATA:
      return { ...state, data: action.params.value };
    case StateActions.SET_SELECTED_FLAG:
      return { ...state, selectedFlag: action.params.value };
    case StateActions.SET_FORM_VALUES:
      return { ...state, formValues: action.params.value };
    case StateActions.RESET_FORM:
      return { ...state };
    case StateActions.REPLACE_INLINE_EDIT:
      return replaceRow(state, action.params.value, action.params.rowIndex);
    case StateActions.SET_SEARCH_DISABLED:
      return { ...state, searchDisabled: action.params };
    case StateActions.SET_IS_SAVING:
      return { ...state, isSaving: action.params };
    default:
      throw new Error(`[ChannelControlFlagsReducer] Unknown action: ${action.type}`);
  }
}

export type Dispatcher = React.Dispatch<Utils.DispatchAction<StateActions>>;
type ContextType = [IChannelControlFlagScreenState, Dispatcher] | [undefined, undefined];

export const ChannelControlFlagsScreenContext = createContext<ContextType>([undefined, undefined]);


export const save = async (dispatch: Dispatcher, value: { CHNLCrtlFlag: string, CHNLCrtlFlagDesc: string }, id: string) => {
  dispatch({ type: StateActions.SET_IS_SAVING, params: true });

  try {
    await editChannelControlFlags(value, id);
    return await Promise.resolve();
  } catch (e) {
    return await Promise.reject(e);
  } finally {
    dispatch({ type: StateActions.SET_IS_SAVING, params: false });
  }
};
