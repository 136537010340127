import React, {useEffect, useState} from 'react';// eslint-disable-line
import { Tabs, Collapse} from 'antd';
import styled from 'styled-components';
import ContentLayout from '../components/ContentLayout';
import SiteContent from '../components/SiteContent';
import Info from '../assets/icons/info';
import '../assets/styles/methods.less';
import MethodsSection from '../components/Methods/MethodsSection';
import { fetchCarrierAndServices } from '../services/shipping';
import Heading from '../components/common/Heading';
import Spacer from '../components/common/Spacer';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
`;


const ToggleWrapper = styled.div`
  margin-botom: 10px;
`;
const TitleWrapper = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.57;
  color: #2d3f5d;
  display: flex;
`;
const Description = styled.div`
  margin-top: 20px;
  border-left: solid 2px #e09624;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 11px;
`;
const ItemWrapper = styled.div`
  margin-top: 10px;
`;
const DescriptionContent = styled.p`
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  color: #2d3f5d;
  margin: 0;
`;
const DescriptionTitle = styled(DescriptionContent)`
  font-weight: bold;
`;
const IconWrapper = styled.div`
  margin-top: 3px;
  margin-left: 4px;
  cursor: pointer;
`;
const channels = [
  {
    name: 'Amazon',
    value: 10001,
  },
  {
    name: 'Walmart',
    value: 10002
  },
  {
    name: 'Facebook',
    value: 10003
  },
];

function Methods() {
  const [carrierServices, setCarrierServices] = useState<Array<any>>([]);
  const [toggle, setToggle] = useState('none');
  const handleToggle = () => {
    setToggle(toggle === 'none' ? '' : 'none');
  };


  const fetchData = async () => {
    const servicesCarriers = await fetchCarrierAndServices();
    setCarrierServices(servicesCarriers);
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData();
    }, []);
  };

  useFetch();

  return (
    <ContentLayout>
      <SiteContent>
        <Heading title="Mapping" />
      </SiteContent>
      <Spacer />
      <SiteContent>
        <HeaderContainer>
          <ToggleWrapper>
            <TitleWrapper>
              Order Import Shipping Method Mappings 
              {' '}
              <IconWrapper onClick={handleToggle}><Info /></IconWrapper>
            </TitleWrapper>
            <Description style={{display: toggle}}>
              <ItemWrapper>
                <DescriptionTitle>• Channel specific carrie and services (Shipping method).</DescriptionTitle>
                <DescriptionContent>
                  When an order is marked as shipped on the channel, each channel has it’s supported carrier and services list.
                  If your shipping carrier is not in their support list, you need to map it.
                  For example, FedEx Smartpost may not support by some channels, you may need to map it to USPS standard.
                </DescriptionContent>
                <DescriptionContent>
                  Also, channels don’t use the same name to call carriers and services or they don’t have exact shipping service available.
                  For example, USPS Priority Mail is simple named as USPS Priority in some channels.
                  If you upload tracking number standard commerce central carriers and service, FedEx, USP and USPS, they are mapped automatically and you cannot change it.
                  If you use non-standard shipping carrier, for example, DHL global mail, you need to map it manually.
                  Each channel needs it own mapping.
                  This feature does not apply to Amazon and Rakuten.com.
                </DescriptionContent>
              </ItemWrapper>
            </Description>
          </ToggleWrapper>
        </HeaderContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="OUTBOUND" key="1">
            <Collapse defaultActiveKey={['0', '1', '2']}>
              {channels.map((channel: any) => (
                <Panel header={`${channel.name}  Marketplace Shipping Mappings`} key={channel.value}>
                  <MethodsSection carrierServices={carrierServices} ChannelNum={channel.value} key={channel.value} />
                </Panel>
              ))}
            </Collapse>
          </TabPane>
        </Tabs>
      </SiteContent>
    </ContentLayout>
  );
}

export default (Methods);