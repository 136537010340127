import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, Button, Card, Space, Empty, Typography, Modal } from 'antd';
import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import styled from 'styled-components';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import Heading from '../../components/common/Heading';
import Spacer from '../../components/common/Spacer';
// import message from '../../components/common/message';
import { fetchTemplates, downloadCopywritingTemplate } from '../../services/template';
import { useExecutePromise } from '../../util/hooks';
import UploadModal from '../Templates/UploadModal';

const { Text } = Typography;

// TODO: streamline links here and sidebar
const Templates: React.FC = () => {
  const [data, loading, setReload] = useExecutePromise(fetchTemplates, {
    channelTemplates: [],
    globalTemplates: [],
    copywritingTemplate: undefined,
  });
  const [current, setCurrent] = useState<any>();
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  const filenameFromHeader = (header: StringKAnyVPair) => {
    const str = header['content-disposition'];

    if (str) {
      const fields = str.split('=');

      return fields[fields.length - 1];
    }
  };

  const exportCopywritingTemplate = async (name: string) => {
    try {
      const res = await downloadCopywritingTemplate();
      // todo: save to file
      // console.log('res ->', res);
      const downloadName = filenameFromHeader(res.headers) || `Content-Resources-Template-${name}.xlsx`;
      const content = res.data;
      const url = window.URL.createObjectURL(new Blob([content]));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', `Copytriting-Template-${name}.xlsx`);
      link.setAttribute('download', downloadName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  const handleClickImportNew = (key: number, channelNum: number, title?: string) => {
    setCurrent({
      templateType: key,
      channelNum,
      title: `Upload ${
        key === 1 ? 'Channel Catalog' : key === 2 ? 'Channel Inventory' : title ? title : 'Custom'
      } Mapping template`,
    });
    setUploadModalVisible(true);
  };

  return (
    <ContentLayout>
      <Heading title="Predefined Operation Sets" />
      <Spacer />
      <SiteContent style={{ padding: '24px' }}>
        <Spin spinning={loading}>
          <Card
            title={
              <Text strong style={{ fontSize: 16 }}>
                Content Resources
              </Text>
            }
            type="inner"
          >
            {data.copywritingTemplate ? (
              <Space direction="vertical">
                <Button
                  type="link"
                  onClick={() =>
                    exportCopywritingTemplate(data.copywritingTemplate.productMappingName)
                  }
                >
                  Download Standard Template
                </Button>
                {/* <CopywritingTemplateDownloader channelAccountNum={0} channelName={''} channelNum={0} /> */}
                <Button
                  type="link"
                  onClick={() =>
                    Modal.confirm({
                      title: 'Are you sure to process ?',
                      icon: <ExclamationCircleOutlined />,
                      content:
                        'The current content resources operation set will be overwrited after upload the template.',
                      onOk: () => {
                        handleClickImportNew(
                          8,
                          data.copywritingTemplate?.channelNum || 0,
                          'Content Resources',
                        );
                      },
                      onCancel() {},
                    })
                  }
                >
                  Upload Content Resources Template
                </Button>
              </Space>
            ) : (
              <Empty />
            )}
          </Card>
        </Spin>
      </SiteContent>
      {uploadModalVisible && (
        <UploadModal
          {...current}
          onCancel={() => setUploadModalVisible(false)}
          onOk={() => {
            setUploadModalVisible(false);
            setReload(true);
          }}
        />
      )}
    </ContentLayout>
  );
};

export default Templates;
