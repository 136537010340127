import { Row, Button, Modal, Typography, Space } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const { Text, Title } = Typography;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

type ExportItemType = {
  productMappingNum: number;
  productMappingName: string;
  mappingContentId: string;
  isMapped: boolean;
  type: string;
};

const CardSpace = styled(Space)`
  width: 450px;
`;

const SecondaryText = styled(Text)`
  font-size: 16px;
`;

const ResultDialog: React.FC<{
  account: ChannelAccount;
  exportItem: ExportItemType;
  onClose: () => void;
}> = ({ account, exportItem, onClose }) => {
  return (
    <Modal
      visible
      width={500}
      closable={false}
      title={
        <Row justify="center">
          <Title level={4}>{exportItem.type === 'product' ? 'Channel Product Export' : 'Channel Inventory Export'}</Title>
        </Row>
      }
      onCancel={() => onClose()}
      footer={false}
    >
      <CardSpace direction="vertical">
        <SecondaryText strong>
          Channel Account: <Text>{account.ChannelAccountName}</Text>
        </SecondaryText>
        <Text strong>{`${exportItem.productMappingName} is scheduled to export. It may take some time.`}</Text>
        <Link to="/file-export">
          <Button>Check Out Export Result</Button>
        </Link>
        <Row justify="end">
          <Button type="primary" onClick={() => onClose()}>
            Close
          </Button>
        </Row>
      </CardSpace>
    </Modal>
  );
};

export default ResultDialog;
