export enum ProfilesAction {
  SET_PROFILES = 'profiles/setProfiles',
  SET_PERMISSIONS = 'profiles/setPermissions',
  SET_SELECTED_INDEX = 'profiles/setSelectedIndex',
  SET_IS_PROFILE_SELECTED = 'profiles/setIsProfileSelected',
}

interface SetProfilesAction {
  type: typeof ProfilesAction.SET_PROFILES;
  profiles: Entities.LoginProfile[];
}

interface SetPermissionsAction {
  type: typeof ProfilesAction.SET_PERMISSIONS;
  permissions: Map<number, Entities.ProfileUserPermission>;
}

interface SetSelectedIndexAction {
  type: typeof ProfilesAction.SET_SELECTED_INDEX;
  selectedIndex: number;
}

interface SetIsProfileSelectedAction {
  type: typeof ProfilesAction.SET_IS_PROFILE_SELECTED;
  isProfileSelected: boolean;
}

export type ProfilesActionType =
  SetProfilesAction
  | SetPermissionsAction
  | SetSelectedIndexAction
  | SetIsProfileSelectedAction;
