import React, { useCallback, useEffect } from 'react';
import { Button, Col, Row, Select, notification, Space, Switch, Modal } from 'antd';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';
import { EditorModal } from '../styledComponents';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1
} from '../../../constants/config';
import { getProfilesState } from '../../../redux/utils';
import { fetchProfileSettings } from '../../../services/channels';
import ScreenMask from '../../../components/common/ScreenMask';
import Loading from '../../../components/common/Loading';

type Props = {
  onClose: Function;
  onSave?: Function;
  options?: StringKAnyVPair[];
  title?: string;
  value?: any;
  visible: boolean;
  productId: string;
  productType: number;
};

let profileSettings: StringKAnyVPair[] = [];

const SimpleSelectorModal = (props: Props) => {
  const { useState } = React;
  const [currentValue, setCurrentValue] = useState<any>(props.value === undefined ? undefined : props.value);
  const [overwrite, setOverwrite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1) {
      try {
        setIsLoading(true);
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwrite(!!allowOverWrite);
    }
  },[]);

  const modalTitle = () => {
    const title = props.title || 'Edit';

    return (<Row
      align="middle"
      justify="space-between"
      style={{marginRight: 30}}
    >
      {title}
    </Row>);
  };

  const getOptions = (opts: StringKAnyVPair[]) => {
    const ret: any[] = [];

    opts.forEach(opt => {
      if ('value' in opt) {
        ret.push({
          label: opt.label || opt.value,
          value: opt.value,
        });
      }
    });

    return ret;
  };

  const onChange = (val: any) => {
    setCurrentValue(val === undefined ? '' : val);
  };

  const onClear = () => {
    onChange('');
  };

  const onCloseDialog = () => {
    props.onClose();
  };

  const saveData = () => {
    if (typeof props.onSave === 'function') {
      props.onSave(currentValue, props.productType !== 1 ? overwrite : false);
    }

    onCloseDialog();
  };

  useEffect(()=>{
    loadProfileSetting();
  },[loadProfileSetting])

  return (<>
    <EditorModal
      centered
      className="fullscreen-modal"
      footer={null}
      //okText="Save"
      onCancel={onCloseDialog}
      //onOk={onSaveImage}
      maskClosable={false}
      style={{ paddingBottom: 0 }}
      title={modalTitle()}
      visible={props.visible}
      width={400}
    >
        {props.productId && props.productType !== 1 && (
          <Space style={{marginTop: 10}}>
          <span style={{marginRight: 4}}>Overwrite children's value</span>
          <Switch
            checkedChildren="Yes"
            //defaultChecked={overwrite}
            onChange={onChange}
            unCheckedChildren="No"
            checked={overwrite}
          />
        </Space>
        )}
      <Col className="modal-body-wrapper2">
        <Select
          allowClear
          showSearch
          // placement="topRight"
          dropdownMatchSelectWidth={false}
          filterOption={(inputValue, option) => {
            const search = inputValue.trim().toLowerCase();
            let label = '';

            if (option && 'object' == typeof option) {
              if ('string' === typeof option.label) {
                label = option.label.toLowerCase();
              }
            }

            return !!label && label.indexOf(search) === 0;
          }}
          onChange={onChange}
          options={getOptions(props.options || [])}
          style={{width: '100%'}}
          value={currentValue}
        />
        <Row justify="space-between" style={{marginTop: 18}}>
          <Button
            disabled={currentValue === undefined ? !props.value : !currentValue}
            onClick={onClear}
          >
            <ClearOutlined />
            Clear Value
          </Button>
          <Button
            disabled={currentValue === props.value}
            onClick={()=>{
              if(!currentValue.trim()){
                Modal.confirm({
                  title:'Are you sure you want to save it as null?',
                  onOk:()=>{
                    saveData()
                  },
                  onCancel:()=>{}
                })
              } else {
                saveData()
              }
            }}
            type="primary"
          >
            <SaveOutlined />
            Save
          </Button>
        </Row>
      </Col>
    </EditorModal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default SimpleSelectorModal;
