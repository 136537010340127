import moment from 'moment-timezone';
import { getProfileSettingValueSync } from '.';

let TIME_ZONE_MAP: StringKAnyVPair;

/**
 * Get system's IANA time zone.
 * This function needs modern browsers to support.
 */
export const getSysTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDefaultTimeZone = () => {
  const profileTime = getProfileSettingValueSync('ProfileTime');
  if (profileTime === 'SpecifyTimeZone') {
    if (!TIME_ZONE_MAP) timezoneAbbrNameMap();

    const timezone = getProfileSettingValueSync('TimeZone');
    if (timezone) {
      return TIME_ZONE_MAP[timezone];
    }
  }

  if (profileTime === 'SystemTime') {
    return getSysTimeZone();
  }

  return '';
};

/**
* Convert UTC time to the time of a special time zone.
*
* @string time - the time need to convert, if it is an empty string, the function will return an empty string, the time can not be a timestamp number, because the library is based on the local time zone
* @string tz - the time zone, if it is empty, it will use current time zone
* @string inputFmt -  the date format of the input time
* @string fmt - the format of the output time, if it is empty, it will use default time format
* @return string
*/
export const convertTimeByUtc = (
  time: string,
  tz = '',
  inputFmt = '',
  fmt = '',
): string => {
  if (!time) return '';

  // eslint-disable-next-line
  tz = tz || getDefaultTimeZone() || getSysTimeZone();
  // eslint-disable-next-line
  fmt = fmt || 'MM/DD/YYYY hh:mm:ss A';
  //console.log('tz ->', tz, time);
  //console.log('2021-05-27T02:01:21', moment.utc('2021-05-27T02:01:21').tz('America/Los_Angeles').zoneAbbr(), moment.utc('2021-05-27T02:01:21').tz('America/Los_Angeles').format(ftm));
  //console.log('2021-01-27T02:01:21', moment.utc('2021-01-27T02:01:21').tz('America/Los_Angeles').zoneAbbr(), moment.utc('2021-01-27T02:01:21').tz('America/Los_Angeles').format(fmt));

  return moment.utc(time, inputFmt).tz(tz).format(fmt);
};

/**
 * Convert a time to UTC time.
 *
 * @string time - the time to convert, if it is empty, the function will return an empty string
 * @string tz - the time zone of the input time
 * @string inputFmt - the fomat of the input time
 * @string fmt - the format of the target time
 * @return string
 */
export const convertToUtcTime = (
  time: string,
  tz = '',
  inputFmt = '',
  fmt = '',
): string => {
  if (!time) return '';

  // eslint-disable-next-line
  tz = tz || getDefaultTimeZone() || getSysTimeZone();
  //console.log('time = ', time);
  //console.log('tz == ', tz);
  // eslint-disable-next-line
  fmt = fmt || 'YYYY-MM-DDTHH:mm:ss';

  return moment.tz(time, inputFmt, tz).utc().format(fmt);
};

/**
 * Get the map of time zone abbreviation and time zone name.
 */
export const timezoneAbbrNameMap = () => {
  const map: StringKAnyVPair = {};

  (Intl as any).supportedValuesOf('timeZone').forEach((timeZone: string) => {
    //const offset=new Intl.DateTimeFormat('en',{timeZone:timeZone, timeZoneName:'shortOffset'}).formatToParts().find(part => part.type==='timeZoneName').value
    // eslint-disable-next-line
    const timeZoneAbbrivation = (new Intl.DateTimeFormat('en', { timeZone: timeZone, timeZoneName: 'long' })).formatToParts().find(part => part.type === 'timeZoneName')?.value;

    //return `${timeZone} - ${timeZoneAbbrivation}(${offset})`
    if (timeZoneAbbrivation) {
      map[timeZoneAbbrivation] = timeZone;
    }
  });

  TIME_ZONE_MAP = map;

  return map;
};
