import React from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { persistStore } from 'redux-persist';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import Loading from '../components/common/Loading';
import ScreenMask from '../components/common/ScreenMask';
import { LOADING_ICON_SIZE1 } from '../constants/config';
import _profileActions from '../redux/actions/profiles';
import { persistor } from '../redux/store';
import { logout } from '../util';
import { fetchProfiles } from '../services/userManagement';

const WelcomeText = styled.p`
  font-family: Lato;
  font-size: 28px;
  font-weight: normal;
  color: #2d3f5d;
`;

const WelcomeWrapper = styled.div`
  width: 100%;
  background-color: #f5f6fa;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ResetApp = () => {
  const { useState } = React;
  //const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const actions = bindActionCreators(_profileActions, dispatch);

  // eslint-disable-next-line
  const checkProfile = async () => {
    setIsLoading(true);

    try {
      const response = await fetchProfiles();
      console.log('response', response);
      actions.setProfiles(response);
    } catch (e) {
      console.log(`Fetch profile error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resetApp = async () => {
    const { localStorage, location } = window;

    try {
      setIsLoading(true);
      localStorage.clear();
      await persistor.purge();
      await logout();
    } finally {
      //localStorage.clear();
      //console.log('cache cleared');
      setTimeout(() => {
        setIsLoading(false);
        location.href = '/';
      }, 500);
    }
  };

  /*React.useEffect(() => {
    if (!inited) {
      checkProfile();
      setInited(true);
    }
  }, [checkProfile, inited]);*/

  return (<>
    <Row align="middle" style={{height: '100%'}}>
      <Col span={12}>
        <WelcomeWrapper>
          <a href="/">
            <img style={{ marginBottom: '36px' }} className="logo" alt="Company Logo" src="/Digitbridge-LOGO-185X185.png" />
          </a>
          <WelcomeText>Welcome to DigitBridge</WelcomeText>
        </WelcomeWrapper>
      </Col>
      <Col span={12}>
        <Row
          justify="center"
        >
          <Button
            onClick={resetApp}
            size="large"
            style={{ width: 300 }}
            type="primary"
          >
            Reset
          </Button>
        </Row>
      </Col>
    </Row>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default ResetApp;
