import { digitBridgeApi, digitBridgeSimpleApi } from './http';

export const fetchOutputTemplates = async (): Promise<any> => {
  const response = await digitBridgeApi.get('/v1/channels/profile/outputTemplateInitial');
  return response;
};

export const fetchTemplates = async (): Promise<any> => {
  const response = await digitBridgeApi.get('/v1/channels/templateMapping');
  return response;
};

export const deleteTemplate = async (productMappingNum: number): Promise<any> => {
  const response = await digitBridgeApi.post(`/v1/channels/templateMapping/${productMappingNum}/delete`);
  return response;
};

export const downloadTemplate = async (productMappingNum: number): Promise<any> => {
  const response = await digitBridgeSimpleApi.get(`/v1/channels/templateMapping/${productMappingNum}/export`, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response;
};

export const downloadOrignalTemplate = async (productMappingNum: number): Promise<any> => {
  const response = await digitBridgeSimpleApi.get(`/v1/channels/originalTemplate/${productMappingNum}/export`, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response;
};

export const downloadCopywritingTemplate = async (): Promise<any> => {
  const response = await digitBridgeSimpleApi.get('/v1/channels/templateMapping/copywriting/download', {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response;
};

export const templateMapping = async (productMappingNum: number): Promise<any> => {
  const response = await digitBridgeApi.get(`/v1/channels/templateMapping/${productMappingNum}`);
  return response;
};
