import React, { useState, useCallback, useEffect } from "react";
import { Row, Modal, Button, Space, Card, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { fetchRuleById } from "../../../../services/qtyOverwriteRule";
import { FormLabel } from "../../../../components/common/styledComponents";

interface CreateBrandProps {
  visible: boolean;
  ruleId: number;
  channelAccountName: string;
  onHide: () => void;
}
const UserComponent = (props: CreateBrandProps) => {
  const { visible, onHide, ruleId, channelAccountName } = props;
  const [ruleInfo, setRuleInfo] = useState<any>();
  const [loadingInfo, setLoadingInfo] = useState(false);

  const getRuleInfo = useCallback(async (id: number) => {
    try {
      setLoadingInfo(true);
      const res = await fetchRuleById(id);
      setLoadingInfo(false);
      if (res) {
        const { ruleType, ruleExpression, ruleName } = res;
        const {
          GreaterValue,
          LessValue,
          TreatZero: tz,
          UseOriginalValue,
          UserSetValue,
        } = JSON.parse(ruleExpression);
        setRuleInfo({
          ruleType,
          GreaterValue,
          LessValue,
          TreatZero: tz === 1 ? true : false,
          UseOriginalValue,
          UserSetValue,
          ruleName,
        });
      }
    } catch (error) {
      setLoadingInfo(false);
    }
  }, []);

  useEffect(() => {
    if (ruleId) {
      getRuleInfo(ruleId);
    }
  }, [ruleId, getRuleInfo]);

  return (
    <Modal
      title="Rule Info"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      bodyStyle={{ padding: 12 }}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" onClick={onHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <Card loading={loadingInfo} bordered={false}>
        <Form.Item
          style={{ marginBottom: 0 }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          label={<FormLabel>Rule Name</FormLabel>}
        >
          {ruleInfo?.ruleName || ""}
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          label={<FormLabel>Channel Account</FormLabel>}
        >
          {channelAccountName || ""}
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          label={<FormLabel>Rule Type</FormLabel>}
        >
          {
            ["", "Absolute Value", "At Least", "Lower to Zero"][
              ruleInfo?.ruleType || 0
            ]
          }
        </Form.Item>
        {/* <p>{`Rule Name: ${ruleInfo?.ruleName}`}</p>
        <p>{`Channel Account: ${channelAccountName || ""}`}</p>
        <p>{`Rule Type:  ${
          ["", "Absolute Value", "At Least", "Lower to Zero"][
            ruleInfo?.ruleType || 0
          ]
        }`}</p> */}
        <Card type="inner" size="small">
          {[1, 2].includes(ruleInfo?.ruleType) && (
            <p>{`Treat no quantity as zero: ${
              ruleInfo?.TreatZero ? "Yes" : "No"
            }`}</p>
          )}
          {ruleInfo?.ruleType === 1 && (
            <p>{`Always use ${ruleInfo?.UserSetValue}`}</p>
          )}
          {[2, 3].includes(ruleInfo?.ruleType) && (
            <>
              <p>{`If the quantity is less than ${ruleInfo?.LessValue}, ${ruleInfo?.ruleType === 2 ? 'increase the value to match.' : 'use zero.'}`}</p>
              <p>{`If the quantity is greater than ${ruleInfo?.GreaterValue}, use ${
                ruleInfo?.userOriginValue === 1
                  ? "original value"
                  : ruleInfo?.UserSetValue
              }`}</p>
            </>
          )}
        </Card>
      </Card>
    </Modal>
  );
};

export default UserComponent;
