import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Select,
} from 'antd';
import SiteContent from '../SiteContent';
import '../../assets/styles/form.less';

const Subtitle = styled.h2`
  height: 19px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #2d3f5d;
  margin-top: 12px;
  margin-bottom: 32px;
`;

const SectionName = styled.h2`
  height: 19px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: #2d3f5d;
  margin-top: 12px;
  margin-bottom: 32px;
`;

function PermissionsForm (props: any) {


  const hashPermissions = props.userPermissions.reduce((acum: any, current: any) => {
    if (acum.get(current.PermissionName)) {
      acum.set(current.PermissionName, acum.get(current.PermissionName));
    } else {
      acum.set(current.PermissionName, current);
    }

    return acum;
  }, new Map());

  const groupByScope =  props.permissions.reduce((acum: any, current: any) => {
    current.PermissionLevel = hashPermissions.get(current.PermissionName)?.PermissionLevel || 0;

    if (acum.get(current.Scope)) {
      acum.set(current.Scope, [...acum.get(current.Scope), current])
    } else {
      acum.set(current.Scope, [current])
    }

    return acum;
  }, new Map());

  const levels = [
    {
      name: 'None',
      value: 0,
    },
    {
      name: 'View',
      value: 1,
    },
    {
      name: 'View & Edit',
      value: 2,
    },
    {
      name: 'Admin',
      value: 4,
    }
  ];

  return (
    <SiteContent>
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 4 }}
        layout="horizontal"
      >
        <Subtitle>Add or edit permissions for this user.</Subtitle>
        {
          [...groupByScope].map(([scopeName, values]) => (
						<>
              <SectionName>{ scopeName }</SectionName>
              {
                values.map((v: any) => (
                  <Form.Item label={v.PermissionName} className="two-rows-label">
                    <Select defaultValue={v.PermissionLevel} onChange={(value) => props.handlePermissionsChange(v.PermissionLevel, v.PermissionNum, value)}>
                      {
                        levels.slice(0, v.MaxLevel + 1).map(l => (
                          <Select.Option value={l.value}>{l.name}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                ))
              }
            </>
          ))
        }
      </Form>
    </SiteContent>
  )
}

export default PermissionsForm;
